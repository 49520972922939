const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const mainGridHeader = [
    { field: 'id', headerName: '#', width: 70, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'inv', headerName: 'Numri i Fletës', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'client', headerName: 'Llogaria Identifikuese', width: 500, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'total', headerName: 'Vlera', type: 'string', width: 270, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

export const detailsData = [
    { field: 'id', headerName: '#', width: 40, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'article', headerName: 'Artikulli', width: 380, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'qty', headerName: 'Sasia', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'PNoVAT', headerName: 'Çmimi pa TVSH', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'PVAT', headerName: 'Çmimi me TVSH', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'amountNoVAT', headerName: 'Vlera pa TVSH', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'amountVAT', headerName: 'Vlera me TVSH', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]