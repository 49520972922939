import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './../../Style/SalesDetailStyle.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAlert, errorAlert, handleSuccess, updateAlert } from "../../Components/Alerts";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { AiFillAppstore } from "react-icons/ai";
import { FaEnvelope } from "react-icons/fa";
import { Paper, TextareaAutosize } from "@mui/material";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import PrintContent from "../../Print/PrintContent";
import { MdEdit } from "react-icons/md";
import Select from 'react-select';
import InsertClient from "../Clients/InsertClients";
import InsertArticle from "../Article/InsertArticle";
import InputAdornment from '@mui/material/InputAdornment';
import DataGridWithActions from "../../Components/DataGridWithActions";
import { mainGridHeader, detailsData } from "./GridHeader";
import Autocomplete from "@mui/material/Autocomplete";

export default function ProInvoices() {
    const TranscationData = [
        {
            id: 1,
            type: "Vendore"
        },
        {
            id: 2,
            type: "Eksport"
        },
        {
            id: 3,
            type: "Sherbime jashtë vendit"
        },
    ]
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const loginEmail = LoginUserDetailed();
    const headers = authHeader();
    const [showDetails, setShowDetails] = useState(true);


    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [detailLine, setDetailLine] = useState([]); //i mban rreshtat e te dhenave detale 
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        PayDate: "",
        ActionType: "",
        AccountIDSpare: "",
        AccountID: "",
        ClientID: "",
        Amount0: 0,
        Amount8: 0,
        Amount18: 0,
        VAT8: "",
        VAT18: "",
        Total: "",
        PaymentOptions: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: ""
    });

    const [formDetailData, setFormDetailData] = useState({
        HistoryID: "",
        ArticleID: "",
        QtyOutput: "",
        DiscountPercent: "",
        PriceBeforeDiscount: "",
        Total: "",
        PriceWithVAT: "",
        PriceWithoutVAT: "",
        ModifiedByUser: "",
        InvoiceNr: "",
        PaymentOptions: "",
    })


    //Per insertimin ne Article History
    const [articleID, setArticleID] = useState("");
    // const [qtyinput, setQtyInput] = useState("");
    const [qtyOutput, setQtyOutput] = useState(0);
    const [insertpriceWithoutVAT, setInsertPriceWithoutVAT] = useState(0);
    const [insertPriceWithVAT, setInsertPriceWithVAT] = useState(0);
    const [insertTotal, setInsertTotal] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [vatRates, setVATRates] = useState("");

    let number = 1;

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [clients, setClients] = useState([]);
    // const [expence, setExpence] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);


    const [companyName, setCompanyName] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyID, setcompanyID] = useState("");


    //Kodi per mbajtjen e totaleve pas llogaritjes
    const [vat8, setVat8] = useState(0);
    const [vat18, setVat18] = useState(0);
    const [total, setTotal] = useState(0);

    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const [valuedetails, setValueDetails] = useState([]);
    const [clientDetails, setClientDetails] = useState([]);


    // Te dhenat per printim
    const [headerData, setHeaderData] = useState([]);
    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");

    const [articlePrintDetails, setArticlePrintDetails] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo 
    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);

    const [discountAmount, setDiscountAmount] = useState(0);
    const [perPages, setPerPages] = useState(0);
    const [perqindjaZbritjes, setPerqindjaZbritjes] = useState(0);
    const [totalWithDiscountAmount, setTotalWithDiscountAmount] = useState(0);
    const [tempPriceWithVAT, setTempPriceWithVAT] = useState(0);


    const currentYear = new Date().getFullYear();

    // kodi per dergimin e emailit
    const [clientEmail, setClientEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [uInvoiceNr, setUInvoiceNr] = useState("");
    const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);

    useEffect(() => {
        if (TranscationData == 2 || TranscationData == 3) {
            setVATRates(0);
        }
    })

    useEffect(() => {
        if (formData.InvoiceNr) {
            setSubject(`Fatura me numër: ${formData.InvoiceNr}`);
        }
    }, [formData.InvoiceNr]);

    const [link, setLink] = useState("");

    const [sendingEmail, setSendingEmail] = useState(false);
    const sendEmail = (event) => {
        event.preventDefault();
        setSendingEmail(true); // Ndryshoni gjendjen e progresit në fillim të dërgimit të emailit

        if (clientEmail === "" || clientEmail == null) {
            errorAlert("Emaili nuk mund te dergohet, sepse klienti nuk ka email te regjistruar");
            setSendingEmail(false);
            return;
        }
        if (uInvoiceNr === "" || uInvoiceNr == null) {
            errorAlert("Fatura nuk mund te dergohet sepse, ende nuk e keni ruajtur faturen ne databaz");
            setSendingEmail(false);
            return;
        }

        const newInvoiceURL = encodeURI(`http://acctree.net/invoice?invoiceNR=${uInvoiceNr}`);
        setLink(newInvoiceURL);

        const emailBody = `Të nderuar,

        Dëshirojmë t'ju njoftojmë se fatura elektronike është krijuar me sukses dhe është gati për shkarkim. Për të marrë faturën tuaj, ju lutem klikoni në linkun e mëposhtëm: ${newInvoiceURL}.
    
        Me këtë ndryshim, synojmë jo vetëm të lehtësojmë procesin tuaj të pagesës, por gjithashtu të kemi një ndikim pozitiv në mjedis duke e bërë dërgimin e faturave më të shpejtë dhe efikas.
        
        Ju lutëm të kryeni pagesën e faturës në njëren nga llogaritë e ${companyName} që janë specifikuar në faturë.
        
        Për çdo pyetje apo ndihmë shtesë, mos ngurroni të na kontaktoni në adresen elektronike: ${companyEmail}
    
        Ju falenderojmë për bashkëpunimin tuaj.
        
        Me respekt,
        ${companyName}`;

        const data = {
            To: clientEmail,
            Subject: subject,
            Body: emailBody // Përdor emailBody në vend të body
        };

        axios.post(ApiLink.API_URL + 'Gmail', data, { headers })
            .then(() => {
                handleSuccess("Email sent Successfully");
                setShowEmailConfirmation(false);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }


    // ky kod i ndryshon gjendjet e formave

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
        setArticleID();
        setQtyOutput(0);
        setInsertPriceWithoutVAT(0);
        setInsertPriceWithVAT(0);
        setInsertTotal(0);
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true); // I fsheh formën e parë
    };

    const clearEdit = () => {
        setFormDetailData({
            HistoryID: "",
            ArticleID: "",
            QtyOutput: 0,
            DiscountPercent: 0,
            PriceBeforeDiscount: 0,
            PriceWithVAT: 0,
            PriceWithoutVAT: 0,
            setFormDetailData: 0
        });

        clearLine();
        handleOpenForm2();
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'InvoicesControllers', { headers, params: { actionType: 'PRI' } });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchDetailLineData = async (invoicenr) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'InvoicesControllers/invoicesDetails', { headers, params: { invNumber: invoicenr, actionType: 'PRI' } });
            setDetailLine(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article', { headers });
            setArticles(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchHeaderData = async (number) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'InvoicesControllers/GetPrintHeaderDetails', { headers, params: { invNumber: number, actionType: "PRI" } });
            setHeaderData(response.data);
            setUInvoiceNr(response.data[0].TransactionUID);
            setLink(encodeURI(`http://acctree.net/invoice?invoiceNR=${response.data[0].TransactionUID}`))
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchBanksAccount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/BankDetails', { headers });
            setBankAccounts(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setCompanyName(response.data[0].CompanyName);
            setCompanyEmail(response.data[0].Email);
            setcompanyID(response.data[0].CompanyID);
            setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticleDataPrint = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetArticleDetailsForPrint', { headers, params: { invNumber: formData.InvoiceNr, actionType: "PRI" } });
            setArticlePrintDetails(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchvaluedetails = async (number) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'InvoicesControllers/getvaluedetails', { headers, params: { invNumber: number, actionType: 'PRI' } });
            setValueDetails(response.data);
            setDiscountAmount(response.data[0].DiscountAmount !== null ? response.data[0].DiscountAmount : 0);
            setTotalWithDiscountAmount(response.data[0].Total + response.data[0].DiscountAmount);
            setPerPages(response.data[0].Total);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };
    // `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Invoice Number", "Date", "Client", "Amount", "VAT", "Total"]],
            body: filteredData.map((item, index) => [
                number + index,
                `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,
                formatDate(item.Date),
                item.AccountName,
                (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
                (Number(item.VAT18) + Number(item.VAT8)).toFixed(2),
                item.Total.toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    // const handleExportPDF = () => {
    //     const doc = new jsPDF({
    //         orientation: "landscape",
    //     });

    //     const tableMargin = 20;
    //     const lineY = 30 + tableMargin - 5;

    //     addLogo(doc);
    //     addSeparatorLine(doc, lineY);
    //     addLeftText(doc, "List of Sales In details", lineY);
    //     addCompanyDetails(doc, companiesData[0]);
    //     addTable(doc, filteredData, lineY);

    //     doc.save("SalesInDetails.pdf");
    // };


    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    // const handlePrintTable = () => {
    //     const table = tableRef.current;
    //     const tableClone = table.cloneNode(true);
    //     const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
    //     printWindow.document.write(tableClone.outerHTML);
    //     printWindow.document.close();
    //     printWindow.focus();
    //     printWindow.print();
    //     printWindow.close();
    // };


    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!formData.InvoiceNr) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        const stateDate = {
            InvoiceNr: formData.InvoiceNr,
            Date: formData.Date,
            ArticleID: articleID,
            QtyOutput: qtyOutput,
            DiscountPercent: discountPercent,
            PriceBeforeDiscount: tempPriceWithVAT,
            PriceWithoutVAT: insertpriceWithoutVAT,
            PriceWithVAT: insertPriceWithVAT,
            CreatedByUser: logedEmail,
            SupplierID: formData.AccountID
        };
        axios.post(ApiLink.API_URL + 'InvoicesControllers/createnewline', stateDate, { headers, params: { vatRates: vatRates, actionType: 'PRI' } })
            .then(() => {
                // handleSuccess("Detail  Added Successfully");
                setQtyOutput(0);
                setInsertPriceWithoutVAT(0);
                setInsertPriceWithVAT(0);
                setInsertTotal(0);
                setArticleID();
                fetchDetailLineData(formData.InvoiceNr);
                fetchData();
                fetchvaluedetails(formData.InvoiceNr);
                fetchHeaderData(formData.InvoiceNr);
                fetchArticleDataPrint();
                setArticlesName("");
                setDiscountPercent(0);
                setTempPriceWithVAT(0);
                setField(null);

            })
            .catch((error) => {
                // console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const clearLine = () => {
        setQtyOutput(0);
        setInsertPriceWithoutVAT(0);
        setInsertPriceWithVAT(0);
        setInsertTotal(0);
        setArticleID();
        fetchDetailLineData(formData.InvoiceNr);
        fetchData();
        fetchvaluedetails(formData.InvoiceNr);
        fetchHeaderData(formData.InvoiceNr);
        fetchArticleDataPrint();
        setArticlesName("");
    }


    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'InvoicesControllers' + `/${deleteItemId}`, { headers }
            );
            // setShowDeleteConfirmation(false);
            deleteAlert("Pro fatura u fshi me sukses");
            fetchData();
        } catch (error) {
            deleteAlert("Pro fatura nuk mund të fshihet");
        }
    };

    const handleDeleteDetailLine = async (deleteItemIdDetail) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'InvoicesControllers/oneline' + `/${deleteItemIdDetail}`, { headers, params: { email: logedEmail, invoiceNR: formData.InvoiceNr, actionType: 'PRI' } }
            );
            // setShowDeleteConfirmationDetail(false);
            deleteAlert("Pro fatura u fshi");
            fetchArticleDataPrint();
            fetchData();
            fetchDetailLineData(formData.InvoiceNr);
            fetchvaluedetails(formData.InvoiceNr);
            fetchHeaderData(formData.InvoiceNr);
            handleOpenForm2();

        } catch (error) {
            deleteAlert("Pro fatura nuk është fshir");
        }
    };


    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        fetchDetailLineData(item.InvoiceNr);
        fetchHeaderData(item.InvoiceNr);
        fetchvaluedetails(item.InvoiceNr);
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            PayDate: item.PayDate,
            ActionType: item.ActionType,
            // AccountIDSpare: item.AccountIDSpare,
            AccountID: item.AccountID,
            SupplierID: item.SupplierID,
            // Amount0: item.Amount0,
            // Amount8: item.Amount8,
            // Amount18: item.Amount18,
            // VAT8: item.VAT8,
            // VAT18: item.VAT18,
            // Total: item.Total,
            // PaymentOptions: item.PaymentOptions,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: logedEmail,

        });
        setShowEditModal(true);
        setShowDetails(false);
        handleOpenForm2();
    };

    const handleEditDetails = (item) => {
        handleOpenForm1();
        fetchArticlesSalesPrice(item.ArticleID, "getVat");
        setFormDetailData({
            HistoryID: item.HistoryID,
            ArticleID: item.ArticleID,
            QtyOutput: item.QtyOutput,
            DiscountPercent: item.DiscountPercent,
            PriceBeforeDiscount: item.PriceBeforeDiscount,
            Total: item.Total,
            PriceWithVAT: item.PriceWithVAT,
            PriceWithoutVAT: item.PriceWithoutVAT,
            ModifiedByUser: logedEmail,
            InvoiceNr: formData.InvoiceNr,
            PaymentOptions: formData.PaymentOptions,
        });
    };


    //Kodi per kalkulimin e VAT8
    useEffect(() => {
        const VAT8 = formData.Amount8 * 0.08; // llogarit produktin me 18% shumëzim
        setVat8(VAT8); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount8]);

    //Kodi per kalkulimin e VAT18
    useEffect(() => {
        const VAT18 = formData.Amount18 * 0.18; // llogarit produktin me 18% shumëzim
        setVat18(VAT18); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount18]);

    //Kodi per kalkulimin e Total
    useEffect(() => {
        const total = Number(formData.Amount0) + Number(formData.Amount8) + Number(formData.Amount18) + Number(vat8) + Number(vat18)
        // + amount18 + vat8 + vat18   
        setTotal(total); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount0, formData.Amount8, formData.Amount18, vat8, vat18]);



    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'InvoicesControllers', formData, { headers })
            .then((response) => {
                // console.log(response.data);
                // setShowEditModal(false); 
                // Close the edit modal
                fetchData();
                updateAlert("Pro fatura u editua me sukses");
                fetchArticleDataPrint();
                fetchHeaderData(formData.InvoiceNr);
            })
            .catch((error) => {
                // alert("Failed to update State");
                errorAlert("Pro fatura nuk u editua")
            });
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDetail = (event) => {
        const { name, value } = event.target;
        setFormDetailData({ ...formDetailData, [name]: value });
    };

    const handleSubmitDetailLine = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'InvoicesControllers/updatedetailsArticle', formDetailData, { headers, params: { vatRates: vatRates, actionType: 'PRI' } })
            .then((response) => {
                fetchDetailLineData(formData.InvoiceNr);
                fetchvaluedetails(formData.InvoiceNr);
                updateAlert("Të dhënat u edituan me sukses");
                fetchArticleDataPrint();
                fetchHeaderData(formData.InvoiceNr);
                fetchData();
                handleOpenForm2();
            })
            .catch((error) => {
                // console.log(error);
                errorAlert("Të dhënat nuk u edituan ")
            });
    }

    const filteredData = data.filter((item) => {
        return (
            item.AccountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const calculatePercent = (value) => {
        setPerqindjaZbritjes((value / totalWithDiscountAmount) * 100);
    }

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const fetchClientsDetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByID', { headers, params: { id: formData.AccountID } });
            setClientDetails(response.data);
            setClientEmail(response.data[0].Email);
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchClients = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
            setClients(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    // ky kod sherben per ta bere aplikimin e zbritjes ne qmimin e pergjithshem ne shum
    const constUpdateDiscountAmount = (event) => {
        event.preventDefault();
        axios.put(`${ApiLink.API_URL}InvoicesControllers/aplikoZbritjen?companyID=${companyID}&invoiceNr=${formData.InvoiceNr}&percent=${perqindjaZbritjes}&actionType=${'PRI'}`, {}, { headers })
            .then((response) => {
                fetchvaluedetails(formData.InvoiceNr);
                fetchArticleDataPrint();
                fetchHeaderData(formData.InvoiceNr);
                fetchData();
                fetchDetailLineData(formData.InvoiceNr)
            })
            .catch((error) => {
                // console.log(error);
                errorAlert(error)
            });
    }



    const fetchArticlesSalesPrice = async (articleID, form) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetSalesPrice', { headers, params: { articleID: articleID } });
            const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja

            if (form == "edit") {
                const calculatedValue = parseFloat(response.data.SalesPrice) * 100 / (100 + response.data.SalesPrice);
                // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
                setVATRates(response.data.VATRates);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: response.data.SalesPrice,
                    PriceWithoutVAT: calculatedValue,
                    DiscountPercent: 0,
                    QtyOutput: 1,
                    PriceBeforeDiscount: response.data.SalesPrice,
                    Total: 1 * response.data.SalesPrice
                }));
            }
            else if (form == "getVat") {
                setVATRates(response.data.VATRates);
            }
            else {
                setInsertPriceWithVAT(salesPriceData.SalesPrice);
                setTempPriceWithVAT(salesPriceData.SalesPrice);
                setVATRates(response.data.VATRates);
                setQtyOutput(1);
                setInsertTotal(1 * salesPriceData.SalesPrice)
                // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
                const calculatedValue = parseFloat(salesPriceData.SalesPrice) * 100 / (100 + salesPriceData.VATRates);
                setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
                setTotal(1 * salesPriceData.SalesPrice);
            }
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handleCalculatePriceWithVAT = async (type) => {
        try {
            if (isNaN(insertpriceWithoutVAT)) {
                throw new Error("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }
            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const vat = Number(formDetailData.PriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(formDetailData.PriceWithoutVAT) + vat;
                const total = Number(formDetailData.QtyOutput) * calculatedPriceWithVAT;

                setTempPriceWithVAT(Number(calculatedPriceWithVAT) / (1 - (Number(formDetailData.DiscountPercent) / 100)))


                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: calculatedPriceWithVAT,
                    Total: total,
                    PriceBeforeDiscount: tempPriceWithVAT

                }));

            } else {
                const vat = Number(insertpriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(insertpriceWithoutVAT) + vat;
                const total = Number(qtyOutput) * calculatedPriceWithVAT;
                // Veprimet specifike për insert të ri
                setInsertPriceWithVAT(calculatedPriceWithVAT);
                setInsertTotal(total);

                setTempPriceWithVAT(Number(calculatedPriceWithVAT) / (1 - (Number(discountPercent) / 100)))

            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };

    const handleCalculatePriceWithoutVAT = async (type) => {
        try {
            if (isNaN(insertPriceWithVAT)) {
                throw new Error("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const calculatedPriceWithoutVAT = Number(formDetailData.PriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(formDetailData.QtyOutput) * Number(formDetailData.PriceWithVAT);
                setTempPriceWithVAT(Number(formDetailData.PriceWithVAT) / (1 - (Number(formDetailData.DiscountPercent) / 100)))

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                    Total: total,
                    PriceBeforeDiscount: tempPriceWithVAT

                }));
            } else {
                setTempPriceWithVAT(Number(insertPriceWithVAT) / (1 - (Number(discountPercent) / 100)))

                // Veprimet specifike për shtim të ri
                const calculatedPriceWithoutVAT = Number(insertPriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(qtyOutput) * Number(insertPriceWithVAT);

                setInsertPriceWithoutVAT(calculatedPriceWithoutVAT);
                setInsertTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };

    const handleCalculatePrices = async (type) => {
        try {
            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }

            if (isNaN(insertTotal)) {
                throw new Error("Totali duhet të jetë një numër.");
            }
            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const priceWithVAT = Number(formDetailData.Total) / Number(formDetailData.QtyOutput);
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));
                // const calculatedPriceWithoutVAT = formDetailData.PriceWithVAT * 100 / (100 + Number(vatRates));
                const tempPriceWithVATEdit = Number(priceWithVAT) / (1 - (Number(formDetailData.DiscountPercent) / 100.00));
                // setTempPriceWithVAT(Number(formDetailData.PriceWithVAT) / (1 - (Number(formDetailData.DiscountPercent) / 100)))

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: priceWithVAT,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                    PriceBeforeDiscount: tempPriceWithVATEdit
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const priceWithVAT = Number(insertTotal) / Number(qtyOutput);
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));

                setTempPriceWithVAT(Number(insertPriceWithVAT) / (1 - (Number(discountPercent) / 100)))

                setInsertPriceWithoutVAT(calculatedPriceWithoutVAT);
                setInsertPriceWithVAT(priceWithVAT);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };

    const handleCalculatePriceWithRabat = async (type) => {
        try {
            if (isNaN(discountPercent)) {
                throw new Error("% e rabatit (Rabati) duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                setTempPriceWithVAT(formDetailData.PriceBeforeDiscount);


                const vleraZbritjes = tempPriceWithVAT * (formDetailData.DiscountPercent / 100);
                const priceWithVATRabat = Number(tempPriceWithVAT) - Number(vleraZbritjes);

                const calculatedPriceWithoutVAT = Number(priceWithVATRabat) * 100 / (100 + Number(vatRates));
                const total = Number(formDetailData.QtyOutput) * Number(priceWithVATRabat);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                    PriceWithVAT: priceWithVATRabat,
                    Total: total,
                    PriceBeforeDiscount: tempPriceWithVAT
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const vleraZbritjes = tempPriceWithVAT * (discountPercent / 100);
                const priceWithVATRabat = Number(tempPriceWithVAT) - Number(vleraZbritjes);

                const calculatedPriceWithoutVAT = Number(priceWithVATRabat) * 100 / (100 + Number(vatRates));
                const total = Number(qtyOutput) * Number(priceWithVATRabat);

                setInsertPriceWithoutVAT(calculatedPriceWithoutVAT);
                setInsertPriceWithVAT(priceWithVATRabat);
                setInsertTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };


    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredArticles(
            articles.filter((data) =>
                data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, articles]);


    const handleInput = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [articlesName, setArticlesName] = useState("");
    const handleFilterArticles = (selectedOption) => {
        if (selectedOption && selectedOption.ArticleID) {
            setArticleID(selectedOption.ArticleID);
            setArticlesName(selectedOption.label);
            fetchArticlesSalesPrice(selectedOption.ArticleID);
        } else {
            setArticleID("");
            setArticlesName("");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'ArticleID':
                    nextField = document.getElementById('QtyOutput');
                    break;
                case 'QtyOutput':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithVAT':
                    nextField = document.getElementById('Total');
                    break;
                case 'PriceWithoutVAT':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'DiscountPercent':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'Total':
                    if (showEditForm === true && showInserForm === false) {
                        handleSubmitDetailLine(e);
                    } else if (showInserForm === true && showEditForm === false) {
                        handleNewSubmit(e);
                    }
                    nextField = document.getElementById('ArticleID');
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select(); // Shtoni këtë rresht për të selektuar tekstin
            }
        }
    };
    const rows = data.map((item, index) => ({
        id: ++index,
        inv: (item.ActionType + "-" + item.FiscalYear + "-" + item.InvoiceNr),
        date: (item.Date.substring(0, 10)),
        client: item.AccountName,
        amount: (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
        vat: ((Number(item.VAT18) + Number(item.VAT8)).toFixed(2)),
        total: item.Total,
        deleteItemId: item.TransactionID, // Shtimi i një atributi për id e rreshtit për fshirje
        rowInfo: item
    }));

    const detailsRows = detailLine.map((item, index) => ({
        id: ++index,
        article: item.ArticleName,
        qty: item.QtyOutput,
        PBD: item.PriceWithoutVATBeforeDiscount,
        discount: item.DiscountPercent,
        PNoVAT: item.PriceWithoutVAT,
        PVAT: item.PriceWithVAT,
        amountNoVAT: item.Amount,
        vat: item.VAT,
        amountVAT: item.Total,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));

    //Select list for Clients
    const handleAutocompleteChangeClients = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };



    const defaultPropsClients = {
        options: clients,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                </li>
            </>
        ),
    };
    ////////////////////////////////

    ////Select list tek Artikulli per editim
    const handleAutocompleteChangeArticleEdit = (event, value) => {
        if (value) {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: value.ArticleID
            }));
            fetchArticlesSalesPrice(value.ArticleID, "edit");
        } else {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: ""
            }));
        }
    };

    const defaultPropsArticleEdit = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };


    //Pjesa per select input field Article
    const [field, setField] = useState(null);
    const handleAutocompleteChangeArticle = (event, value) => {
        if (value && value.ArticleID) {
            setArticleID(value.ArticleID);
            fetchArticlesSalesPrice(value.ArticleID);
            setField(value);
        } else {
            // Në rast se vlera është pastruar
            setArticleID(""); // Vendos vlerën e ArticleID në null
            setField(null);
        }
    };

    const defaultPropsArticle = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };


    useEffect(() => {
        fetchData();
        fetchClientsDetails();
        // fetchDetailLineData();
        fetchArticles();
        //fetchvaluedetails();
        fetchClients();
        fetchCompaniesData();
        fetchArticleDataPrint();
        fetchBanksAccount();
        fetchPaymentType();
        // fetchArticlesSalesPrice()
    }, [formData.AccountID, formData.InvoiceNr, articleID]);

    return (
        <div className="AccountingSheet__Container" style={{ paddingTop: "50px" }}>
            <Modal show={showEmailConfirmation} onHide={() => setShowEmailConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                {
                    sendingEmail ?
                        <Modal.Body>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                <CircularProgress />
                            </Box>
                        </Modal.Body>
                        :
                        <>
                            <Modal.Body>
                                <p>A dëshironi ta dergoni faturën në këtë Email:</p>
                                <TextField
                                    required
                                    style={{ width: "100%" }}
                                    id="clientEmail"
                                    label="Email-i i klientit"
                                    variant="outlined"
                                    type="text"
                                    name='clientEmail'
                                    value={clientEmail}
                                    onChange={event => setClientEmail(event.target.value)}
                                    autoComplete="off"
                                />
                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="secondary" onClick={() => setShowEmailConfirmation(false)}>
                                    Anulo
                                </Button>
                                <Button variant="danger" onClick={sendEmail}>
                                    Dergo
                                </Button>
                            </Modal.Footer>
                        </>
                }
            </Modal>

            {showEditModal && (
                <div className="SalesDetail__Container">
                    <div className="header_flex">
                        <div className="header_icon">
                            <MdEdit style={{ color: '#466F86', width: "40px" }} />
                            <h3>Ndryshimi i pro faturës në detale</h3>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="SalesDetail__Container__AllButtons">
                        <div style={{ display: "flex", }}>
                            <div>
                                <InsertClient fetchData={fetchClients} type="insert" />
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <InsertArticle fetchData={fetchArticles} type="insert" />
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "5px" }}>
                                <Link to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button className="button-15" variant="contained" onClick={() => { setShowEmailConfirmation(true); }}>
                                        <FaEnvelope /> Email
                                    </button>
                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <PrintContent details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyOutput" actiontype="true" link={link} />
                            </div>
                            {/* <div style={{ marginLeft: "5px" }}>
                                <Print80mm details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyOutput" actiontype="true" />
                            </div> */}
                            <div style={{ marginLeft: "5px" }}>
                                <Link to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button className="button-15" variant="contained" onClick={handleSubmit}>Ndrysho</button>

                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button className="button-15" variant="contained"
                                        onClick={() => {
                                            setShowDetails(true);
                                            setShowEditModal(false);
                                        }}
                                    >
                                        Mbyll
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='header_paper2'></div>
                    <Paper style={{ padding: "20px" }}>
                        <form className='header_paper'>
                            <Box
                                // component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className='SalesDetail__Container__Form'>
                                    <div className="SalesDetail__Container__Form__div">
                                        <div className="SalesDetail__Container__Form__div__1">
                                            <div className="SalesDetail__Container__Form__div__2">
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__1"
                                                    id="invoiceNR"
                                                    label="Numri i Fatures"
                                                    variant="outlined"
                                                    type="text"
                                                    name='InvoiceNr'
                                                    value={"PRI-" + currentYear + "-" + formData.InvoiceNr}
                                                    // value={formData.InvoiceNr}
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__1"
                                                    id="id"
                                                    select
                                                    name="TransactionType"
                                                    label="Select Transaction Type"
                                                    value={formData.TransactionType}
                                                    onChange={handleInputChange}
                                                >
                                                    {TranscationData.map((data) => (
                                                        <MenuItem key={data.id} value={data.id}>{data.type}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextareaAutosize style={{ height: "70px", marginLeft: "10px" }}
                                                    className="SalesDetail__Container__Form__TextField__1"
                                                    id="Coment"
                                                    label="Coment"
                                                    type="text"
                                                    name='Coment'
                                                    value={formData.Coment}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="SalesDetail__Container__Form__TextField__date__Payment">
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__date"
                                                    id="date"
                                                    type="date"
                                                    variant="outlined"
                                                    label="Data e Faturës"
                                                    name='Date'
                                                    value={formData.Date.substring(0, 10)}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__Payment"
                                                    id="AccountiD"
                                                    select
                                                    label="Përzgjedh Menyën e Pagesës"
                                                    name="PaymentOptions"
                                                    value={0}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                    disabled
                                                >
                                                    <MenuItem key="0" value="0">Pagesa Behet më vonë
                                                    </MenuItem>
                                                    {cashRegister.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {
                                                    formData.PaymentOptions != 0 ? (
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__date"
                                                            id="PayDate"
                                                            type="date"
                                                            variant="outlined"
                                                            label="Data e Pagesës"
                                                            name='PayDate'
                                                            value={formData.PayDate ? formData.PayDate.substring(0, 10) : ''}
                                                            onChange={handleInputChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )
                                                        :
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__date"
                                                            id="PayDate"
                                                            type="date"
                                                            variant="outlined"
                                                            label="Afati i pagesës"
                                                            name='PayDate'
                                                            value={formData.PayDate ? formData.PayDate.substring(0, 10) : ''}
                                                            onChange={handleInputChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="SalesDetail__Container__Form__div__4">
                                        <div>
                                            {/* <TextField className="SalesDetail__Container__Form__TextField__2"
                                                // style={{ width: "96%" }}
                                                id="AccountID"
                                                name="AccountID"
                                                select
                                                label="Klienti"
                                                autoComplete="off"
                                                value={formData.AccountID}
                                                onChange={handleInputChange}
                                            >
                                                {clients.map((data) => (
                                                    <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                            <Autocomplete
                                                fullWidth
                                                // sx={{ m: 2 }}
                                                {...defaultPropsClients}
                                                id="AccountID"
                                                includeInputInList
                                                onChange={handleAutocompleteChangeClients}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        label="Klienti"
                                                        // variant="filled"
                                                        className="SalesDetail__Container__Form__TextField__2"
                                                    />
                                                )}
                                                value={clients.find(option => option.AccountID === formData.AccountID) || null}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <TextField
                                                className="SalesDetail__Container__Form__TextField__IDNumber"
                                                // style={{ width: "49%" }}
                                                id="outlined-basic"
                                                label="Numri Identifikues"
                                                variant="outlined"
                                                name="IdentificationNumber"
                                                value={clientDetails.length > 0 ? clientDetails[0].IdentificationNumber : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                readOnly
                                                autoComplete="off"
                                            />
                                            <TextField
                                                className="SalesDetail__Container__Form__TextField__IDNumber"
                                                // style={{ width: "49%" }}
                                                id="outlined-basic"
                                                label="Numri i TVSH-së"
                                                variant="outlined"
                                                name="VatNumber"
                                                value={clientDetails.length > 0 ? clientDetails[0].VATNumber : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                readOnly
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                className="SalesDetail__Container__Form__TextField__2"
                                                // style={{ width: "96%" }}
                                                id="outlined-basic"
                                                label="Adresa"
                                                variant="outlined"
                                                name="Address"
                                                value={clientDetails.length > 0 ? clientDetails[0].Adress : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                readOnly
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </form>
                        <div style={{ backgroundColor: "gainsboro " }}>
                            <hr></hr>
                            {showEditForm && (
                                <div className="SalesDetail__Container">
                                    <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
                                        <Box
                                            // component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            {/* <p style={{ color: "red", fontSize: "16px", fontStyle: "italic", borderBottom: "1px dashed red" }}>Click on a line to edit the data</p> */}
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__Article">
                                                    {/* <TextField
                                                        className="SalesDetail__Container__Form__TextField__Article"
                                                        id="ArticleID"
                                                        select
                                                        label="Select Article"
                                                        name="ArticleID"
                                                        value={formDetailData.ArticleID}
                                                        autoComplete="off"
                                                        // onChange={handleInputChangeDetail}
                                                        onChange={(event) => {
                                                            handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
                                                            fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
                                                        }}
                                                    // onKeyUp={handleCalculatePriceWithoutVATEdit}
                                                    >
                                                        {articles.map((data) => (
                                                            <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        sx={{ m: 1.5 }}
                                                        {...defaultPropsArticleEdit}
                                                        id="ArticleID"
                                                        includeInputInList
                                                        onChange={handleAutocompleteChangeArticleEdit}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label="Artikulli"
                                                            // variant="filled" 
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                        value={articles.find(option => option.ArticleID === formDetailData.ArticleID) || null}
                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            type="text"
                                                            id="QtyOutput"
                                                            name="QtyOutput"
                                                            label="Sasia"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            value={formDetailData.QtyOutput}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                        // onKeyUp={handleCalculatePriceWithoutVATEdit}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="DiscountPercent"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Rabati %"
                                                            name='DiscountPercent'
                                                            value={typeof formDetailData.DiscountPercent === 'number' ? (formDetailData.DiscountPercent).toFixed(2) : formDetailData.DiscountPercent}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePriceWithRabat("edit")}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithoutVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price Without VAT"
                                                            name="PriceWithoutVAT"
                                                            autoComplete="off"
                                                            value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePriceWithVAT("edit")}
                                                        // disabled={form2IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price With VAT"
                                                            name="PriceWithVAT"
                                                            autoComplete="off"
                                                            value={typeof formDetailData.PriceWithVAT === 'number' ? formDetailData.PriceWithVAT.toFixed(4) : formDetailData.PriceWithVAT}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                        />
                                                    </div>
                                                    <div className='SalesDetail__Container__Second__Form__details'>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="Total"
                                                            label="Total"
                                                            variant="outlined"
                                                            // name='Amount'
                                                            name="Total"
                                                            autoComplete="off"
                                                            value={typeof formDetailData.Total === 'number' ? formDetailData.Total.toFixed(2) : formDetailData.Total}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePrices("edit")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="butonat">
                                                        <div>
                                                            <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-check-lg"></i>
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-x-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div className="responsive">
                                                        <div className="responsive__butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-check-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </form>
                                </div>
                            )}
                            {showInserForm && (
                                <div className="SalesDetail__Container">
                                    <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
                                        <Box
                                            // component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            {/* <p style={{ color: "blue", fontSize: "16px", fontWeight: "bold", borderTop: "1px dashed blue" }}>Please fill in all the required fields.</p> */}
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__Article">
                                                    {/* <span style={{ color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Artikullin</span>
                                                    <Select
                                                        className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                        inputId="ArticleID"
                                                        value={{ label: articlesName, value: articleID }}
                                                        options={filteredArticles.map((data) => ({
                                                            label: data.ArticleName,
                                                            value: data.ArticleID,
                                                            ArticleID: data.ArticleID
                                                        }))}
                                                        onInputChange={handleInput}
                                                        onChange={handleFilterArticles}
                                                        isClearable
                                                        placeholder="Select"
                                                        noOptionsMessage={() => 'No matching'}
                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                height: "55px",
                                                                marginLeft: "10px",
                                                                marginRight: "12px",
                                                                backgroundColor: "transparent",
                                                                border: "darkgray solid 1px"
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: "#fff",
                                                                zIndex: 9999,
                                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                borderRadius: "4px",
                                                            }),
                                                            menuList: (provided) => ({
                                                                ...provided,
                                                                maxHeight: "200px",
                                                                overflowY: "auto",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: "#000",
                                                            }),
                                                        }}
                                                    /> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        {...defaultPropsArticle}
                                                        id="ArticleID"
                                                        includeInputInList
                                                        value={field}
                                                        sx={{ m: 1.5 }}
                                                        onChange={handleAutocompleteChangeArticle}
                                                        isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Kerko Artikullin"
                                                            // variant="filled"
                                                            // onKeyPress={handleKeyPress}
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="QtyOutput"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Sasia Dalje"
                                                            name='QtyOutput'
                                                            autoComplete="off"
                                                            value={qtyOutput}
                                                            onChange={(e) => setQtyOutput(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="DiscountPercent"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Rabati %"
                                                            name='DiscountPercent'
                                                            value={typeof discountPercent === 'number' ? discountPercent.toFixed(2) : discountPercent}
                                                            onChange={(e) => setDiscountPercent(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithRabat}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithoutVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Cmimi pa TVSH"
                                                            name="PriceWithoutVAT"
                                                            autoComplete="off"
                                                            value={typeof insertpriceWithoutVAT === 'number' ? insertpriceWithoutVAT.toFixed(4) : insertpriceWithoutVAT}
                                                            onChange={(e) => setInsertPriceWithoutVAT(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithVAT}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Cmimi me TVSH"
                                                            name="PriceWithVAT"
                                                            autoComplete="off"
                                                            value={typeof insertPriceWithVAT === 'number' ? insertPriceWithVAT.toFixed(4) : insertPriceWithVAT}
                                                            onChange={(e) => setInsertPriceWithVAT(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                        />
                                                    </div>
                                                    <div className='SalesDetail__Container__Second__Form__details'>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="Total"
                                                            label="Total"
                                                            variant="outlined"
                                                            name='Total'
                                                            autoComplete="off"
                                                            value={typeof insertTotal === 'number' ? insertTotal.toFixed(2) : insertTotal}
                                                            onChange={event => setInsertTotal(event.target.value)}
                                                            onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />

                                                    </div>
                                                    <div className="butonat">
                                                        <div>
                                                            <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-plus-lg"></i>
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-x-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div className="responsive">
                                                        <div className="responsive__butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </form>
                                </div>
                            )}
                            <DataGridWithActions
                                columns={detailsData}
                                rows={detailsRows}
                                handleEdit={handleEditDetails}
                                handleDelete={handleDeleteDetailLine}
                                showPayment={false}
                            />
                            <hr></hr>
                        </div>
                        <br></br>
                        <div className='responsive__Tablet'>
                            <div className='SalesDetail__Container__Form'>
                                <div>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Amount18"
                                        // style={{ width: "100%" }}
                                        id="Amount18"
                                        label="Vlera 18"
                                        variant="outlined"
                                        type="text"
                                        name='Amount18'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount18).toFixed(2) : ''}
                                        disabled
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VAT18"
                                        // style={{ width: "100%" }}
                                        id="VAT18"
                                        label="TVSH 18"
                                        variant="outlined"
                                        type="text"
                                        name='VAT18'
                                        value={valuedetails.length > 0 ? (valuedetails[0].VAT18).toFixed(2) : ''}
                                        disabled
                                    />
                                </div>
                                <br></br>
                                <div>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Amount8"
                                        // style={{ width: "100%" }}
                                        id="Amount8"
                                        label="Vlera 8"
                                        variant="outlined"
                                        type="text"
                                        name='Amount8'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount8).toFixed(2) : ''}
                                        disabled
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VAT8"
                                        // style={{ width: "100%" }}
                                        id="VAT8"
                                        label="TVSH 8"
                                        variant="outlined"
                                        type="text"
                                        name='VAT8'
                                        value={valuedetails.length > 0 ? (valuedetails[0].VAT8).toFixed(2) : ''}
                                        disabled
                                    />
                                </div>
                                <br></br>
                                <div>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Amount0"
                                        // style={{ width: "100%" }}
                                        id="Amount0"
                                        label="Vlera 0"
                                        variant="outlined"
                                        type="text"
                                        name='Amount0'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount0).toFixed(2) : ''}
                                        disabled
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VAT0"
                                        // style={{ width: "100%" }}
                                        id="VAT0"
                                        label="TVSH 0"
                                        variant="outlined"
                                        type="text"
                                        name='VAT0'
                                        value={valuedetails.length > 0 ? 0 : ''}
                                        disabled
                                    />
                                </div>
                                <br></br>
                                <div>
                                    <TextField
                                        style={{ width: "81%" }}
                                        id="DiscountAmount"
                                        label="Zbritja në vlerë"
                                        variant="outlined"
                                        type="text"
                                        name="DiscountAmount"
                                        value={discountAmount}
                                        onChange={(e) => { setDiscountAmount(e.target.value); calculatePercent(e.target.value); }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip title="Apliko Zbritjen" placement="right">
                                                        <IconButton type="submit"
                                                            onClick={constUpdateDiscountAmount}
                                                        >
                                                            <i class="bi bi-check-lg" style={{ color: "green" }}></i>

                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__TotalAmount"
                                        id="TotalAmount"
                                        label="Total pa TVSH"
                                        variant="outlined"
                                        type="text"
                                        name='TotalAmount'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toFixed(2) : ''}
                                        readOnly
                                    />
                                </div>
                                <br></br>
                                <div>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VATTotal"
                                        // style={{ width: "100%" }}
                                        id="VATTotal"
                                        label="Total TVSH"
                                        variant="outlined"
                                        type="text"
                                        name='VATTotal'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
                                        readOnly
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Total"
                                        // style={{ width: "100%" }}
                                        id="Totali"
                                        label="Për pagesë"
                                        variant="outlined"
                                        type="text"
                                        name='Totali'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Total).toFixed(2) : ''}
                                        readOnly
                                    />
                                </div>
                                <br></br>
                            </div>
                        </div>
                    </Paper>
                </div>
            )}
            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Lista e Pro Faturave</h3>
                        </div>
                        <div>
                            <Link to={"/Sales/addProInvoice"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />   Krijo pro faturë
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e pro faturave: Menaxhoni, shtoni, editoni dhe fshini Fatura.</p>
                    </div>
                    <DataGridWithActions
                        columns={mainGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={false}
                        height={500}
                    />
                </div>
            )}
        </div>
    )
}