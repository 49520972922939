import "./featured.scss";
import React from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink } from "../../../APILink/ApiLink";
import { errorAlert } from "../../../Components/Alerts";
import authHeader from "../../../Auth/authHeader";

const Featured = () => {
  const [percentageOfDayPassed, setPercentageOfDayPassed] = useState(0);
  const [todaySales, setTodaySales] = useState(0);
  const [lastWeekSales, setLastWeekSales] = useState(0);
  const [lastMonthSales, setLastMonthSales] = useState(0);
  const [yesterdaySales, setYesterdaySales] = useState(0);



  const headers = authHeader();

  const fetchAllDetails = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + 'Dashboard/detailsTotal', { headers });
      setTodaySales(response.data[0].TotalSalesToday); // Update this line
      setLastWeekSales(response.data[0].TotalLastWeek);
      setLastMonthSales(response.data[0].TotalLastMonth);
      setYesterdaySales(response.data[0].TotalSalesLastDay);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const fetchPercent = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + 'Dashboard', { headers });
      setPercentageOfDayPassed(response.data[0].PercentageOfDayPassed); // Update this line
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  useEffect(() => {
    fetchAllDetails();
    fetchPercent();
  }, [])

  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Të hyrat në përiudha</h1>
        {/* <h1 className="title">Total Revenue</h1> */}
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={percentageOfDayPassed} text={percentageOfDayPassed + "%"} strokeWidth={5} />
        </div>
        <p className="title">Të hyrat ditore</p>
        {/* <p className="title">Total sales made today</p> */}
        {/* <p className="amount">{todaySales && todaySales} €</p> */}
        <p className="amount">€ {todaySales !== null && todaySales !== 0 ? todaySales.toLocaleString('en-US', { minimumFractionDigits: 0 }) : 0.00}</p>

        <p className="desc">
          {/* Previous transactions processing. Last payments may not be included. */}
          {/* <button onClick={fetchYesterdaySales}>click</button> */}
        </p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Dita e kaluar</div>
            {/* <div className="itemTitle">Yesterday</div> */}
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              {/* <div className="resultAmount">€ {yesterdaySales}</div> */}
              <div className="resultAmount">€ {yesterdaySales !== null && yesterdaySales !== 0 ? yesterdaySales.toLocaleString('en-US', { minimumFractionDigits: 0 }) : 0.00}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Java kaluar</div>
            {/* <div className="itemTitle">Last Week</div> */}
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              {/* <div className="resultAmount">€ {lastWeekSales}</div> */}
              <div className="resultAmount">€ {lastWeekSales !== null && lastWeekSales !== 0 ? lastWeekSales.toLocaleString('en-US', { minimumFractionDigits: 0 }) : 0.00}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Muaji kaluar</div>
            {/* <div className="itemTitle">Last Month</div> */}
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              {/* <div className="resultAmount">€ {lastMonthSales}</div> */}
              <div className="resultAmount">€ {lastMonthSales !== null && lastMonthSales !== 0 ? lastMonthSales.toLocaleString('en-US', { minimumFractionDigits: 0 }) : 0.00}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
