import { InsertInputTypes, Checked, EditInputs } from "./Input"
import React from 'react';

export function InsertForm(props) {
    return (
        <div >
            <form onSubmit={props.onSubmit}>
                {
                    props.datalink.map((data, index) => (
                        <InsertInputTypes
                            key={index}
                            name={data.name}
                            type={data.type}
                            value={data.value}
                            onChange={(event) => data.event(event.target.value)}
                        />
                    ))
                }
                <Checked
                    checked={props.Checked}
                    onChange={props.onChange}

                />
                <button type="submit"
                    className="btn btn-primary float-end"
                    style={{ background: "#7a95a6" }}
                >Save</button>
            </form>
        </div>
    )
}

export function EditForm(props) {
    return (
        <div >
            <form onSubmit={props.onSubmit}>

                {
                    props.datalink.map((data, index) => (
                        <EditInputs
                            key={index}
                            title={data.title}
                            type={data.type}
                            id={data.name}
                            name={data.name}
                            value={data.value}
                            onChange={data.onChange}
                        />
                    ))
                }

                <Checked
                    checked={props.Checked}
                    onChange={props.onChange}

                />

                <button type="submit"
                    className="btn btn-primary float-end"
                    style={{ background: "#7a95a6" }}
                >Save</button>
            </form>
        </div>
    )
}