import ExcelJS from 'exceljs';

export const TaxPurchaseExcelForm = (filteredData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Shtoni titullin e kolonave në rreshtin e parë
    worksheet.mergeCells('A1', 'A2');
    const cellA1A2 = worksheet.getCell('A1');

    cellA1A2.value = 'NR.';
    cellA1A2.width = 50; // Përcakton width në 100 piksela
    cellA1A2.height = 125; // Përcakton height në 50 piksela
    cellA1A2.border = {
        top: { style: 'thin', color: { argb: '00000000' }, bold: true },
        left: { style: 'thin', color: { argb: '00000000' }, bold: true },
        bottom: { style: 'thin', color: { argb: '00000000' }, bold: true },
        right: { style: 'thin', color: { argb: '00000000' }, bold: true }
    };
    cellA1A2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };


    worksheet.mergeCells('B1', 'C1');
    const cellC1 = worksheet.getCell('B1');
    cellC1.value = 'Fatura';
    cellC1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };
    cellC1.alignment = { vertical: 'middle', horizontal: 'center' };
    cellC1.width = 170; // Përcakton width në 100 piksela
    cellC1.height = 27; // Përcakton height në 50 piksela


    const cellC2 = worksheet.getCell('B2');
    cellC2.value = 'Data';
    cellC2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellC2.alignment = { vertical: 'middle', horizontal: 'center' };


    const cellD2 = worksheet.getCell('C2');
    cellD2.value = 'Numri Faturës';
    cellD2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellD2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    worksheet.mergeCells('D1', 'F1');
    const blersi = worksheet.getCell('D1');
    blersi.value = 'Shitësi';
    blersi.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    blersi.alignment = { vertical: 'middle', horizontal: 'center' };

    const emriBleresit = worksheet.getCell('D2');
    emriBleresit.value = 'Emri i shitësit';
    emriBleresit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    emriBleresit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const NRFBlersit = worksheet.getCell('E2');
    NRFBlersit.value = 'Numri Fiskal i shitësit';
    NRFBlersit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    NRFBlersit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const VATBlersit = worksheet.getCell('F2');
    VATBlersit.value = 'Numri i TVSH-së së shitësit';
    VATBlersit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    VATBlersit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    worksheet.mergeCells('A3', 'F3');
    const cellA3 = worksheet.getCell('A3');
    cellA3.value = 'Numri i kutisë në Deklaratën e TVSH-së';
    cellA3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellA3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    cellA3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };


    // Pjesa e "'Blerjet dhe Importet e liruara dhe me TVSH jo të zbritshme"-------------------------------------

    worksheet.mergeCells('G1', 'J1');
    const G1 = worksheet.getCell('G1');
    G1.value = 'Blerjet dhe Importet e liruara dhe me TVSH jo të zbritshme';
    G1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    G1.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    // G2 dhe G3
    const G2 = worksheet.getCell('G2');
    G2.value = 'Blerjet dhe importet pa TVSH';
    G2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    G2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const G3 = worksheet.getCell('G3');
    G3.value = '[31]';
    G3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    G3.alignment = { vertical: 'middle', horizontal: 'center' };
    G3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // H2 dhe H3
    const H2 = worksheet.getCell('H2');
    H2.value = 'Blerjet dhe importet investive pa TVSH';
    H2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    H2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const H3 = worksheet.getCell('H3');
    H3.value = '[32]';
    H3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    H3.alignment = { vertical: 'middle', horizontal: 'center' };
    H3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // I2 dhe I3
    const I2 = worksheet.getCell('I2');
    I2.value = 'Blerjet dhe importet me TVSH jo të zbritshme';
    I2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    I2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const I3 = worksheet.getCell('I3');
    I3.value = '[33]';
    I3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    I3.alignment = { vertical: 'middle', horizontal: 'center' };
    I3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // J2 dhe J3
    const J2 = worksheet.getCell('J2');
    J2.value = 'Blerjet dhe importet investive me TVSH jo të zbritshme';
    J2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    J2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const J3 = worksheet.getCell('J3');
    J3.value = '[34]';
    J3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    J3.alignment = { vertical: 'middle', horizontal: 'center' };
    J3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Pjesa e "'Blerjet dhe Importet e liruara dhe me TVSH jo të zbritshme"-------------------------------------

    worksheet.mergeCells('K1', 'S1');
    const K1 = worksheet.getCell('K1');
    K1.value = 'Blerjet dhe Importet e tatushme me 18%, si dhe rregullimet e zbritjeve';
    K1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    K1.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    // K2 dhe K3
    const K2 = worksheet.getCell('K2');
    K2.value = 'Importet';
    K2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    K2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const K3 = worksheet.getCell('K3');
    K3.value = '[35]';
    K3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    K3.alignment = { vertical: 'middle', horizontal: 'center' };
    K3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // L2 dhe L3
    const L2 = worksheet.getCell('L2');
    L2.value = 'Importet investive  ';
    L2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    L2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const L3 = worksheet.getCell('L3');
    L3.value = '[39]';
    L3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    L3.alignment = { vertical: 'middle', horizontal: 'center' };
    L3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // M2 dhe M3
    const M2 = worksheet.getCell('M2');
    M2.value = 'Blerjet vendore';
    M2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    M2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const M3 = worksheet.getCell('M3');
    M3.value = '[43]';
    M3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    M3.alignment = { vertical: 'middle', horizontal: 'center' };
    M3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // N2 dhe N3
    const N2 = worksheet.getCell('N2');
    N2.value = 'Blerjet investive vendore';
    N2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    N2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const N3 = worksheet.getCell('N3');
    N3.value = '[47]';
    N3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    N3.alignment = { vertical: 'middle', horizontal: 'center' };
    N3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // O2 dhe O3
    const O2 = worksheet.getCell('O2');
    O2.value = 'Nota debitore e pranuar, nota kreditore e lëshuar';
    O2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    O2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const O3 = worksheet.getCell('O3');
    O3.value = '[53]';
    O3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    O3.alignment = { vertical: 'middle', horizontal: 'center' };
    O3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // P2 dhe P3
    const P2 = worksheet.getCell('P2');
    P2.value = 'Fatura e borxhit të keq e lëshuar';
    P2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    P2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const P3 = worksheet.getCell('P3');
    P3.value = '[57]';
    P3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    P3.alignment = { vertical: 'middle', horizontal: 'center' };
    P3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Q2 dhe Q3
    const Q2 = worksheet.getCell('Q2');
    Q2.value = 'Rregullimet për të ulur TVSH-në për pagesë';
    Q2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Q2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const Q3 = worksheet.getCell('Q3');
    Q3.value = '[61]';
    Q3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Q3.alignment = { vertical: 'middle', horizontal: 'center' };
    Q3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // R2 dhe R3
    const R2 = worksheet.getCell('R2');
    R2.value = 'E drejta e kreditimit të TVSH-së në lidhje me Ngarkesën e Kundërt';
    R2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    R2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const R3 = worksheet.getCell('R3');
    R3.value = '[65]';
    R3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    R3.alignment = { vertical: 'middle', horizontal: 'center' };
    R3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // S2 dhe S3
    const S2 = worksheet.getCell('S2');
    S2.value = 'Totali i TVSH-së së  zbritshme me 18%';
    S2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    S2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const S3 = worksheet.getCell('S3');
    S3.value = '[K1]';
    S3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    S3.alignment = { vertical: 'middle', horizontal: 'center' };
    S3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Pjesa e "'Blerjet dhe Importet e tatushme me 8%, si dhe rregullimet e zbritjeve"-------------------------------------

    worksheet.mergeCells('T1', 'AB1');
    const T1 = worksheet.getCell('T1');
    T1.value = 'Blerjet dhe Importet e tatushme me 8%, si dhe rregullimet e zbritjeve';
    T1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    T1.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    // T2 dhe T3
    const T2 = worksheet.getCell('T2');
    T2.value = 'Importet';
    T2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    T2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const T3 = worksheet.getCell('T3');
    T3.value = '[37]';
    T3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    T3.alignment = { vertical: 'middle', horizontal: 'center' };
    T3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // U2 dhe U3
    const U2 = worksheet.getCell('U2');
    U2.value = 'Importet investive';
    U2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    U2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const U3 = worksheet.getCell('U3');
    U3.value = '[41]';
    U3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    U3.alignment = { vertical: 'middle', horizontal: 'center' };
    U3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // V2 dhe V3
    const V2 = worksheet.getCell('V2');
    V2.value = 'Blerjet vendore';
    V2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    V2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const V3 = worksheet.getCell('V3');
    V3.value = '[45]';
    V3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    V3.alignment = { vertical: 'middle', horizontal: 'center' };
    V3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // W2 dhe W3
    const W2 = worksheet.getCell('W2');
    W2.value = 'Blerjet investive vendore';
    W2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    W2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const W3 = worksheet.getCell('W3');
    W3.value = '[49]';
    W3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    W3.alignment = { vertical: 'middle', horizontal: 'center' };
    W3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // X2 dhe X3
    const X2 = worksheet.getCell('X2');
    X2.value = 'Blerjet nga fermerët (aplikimi i normës së sheshtë)';
    X2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    X2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const X3 = worksheet.getCell('X3');
    X3.value = '[51]';
    X3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    X3.alignment = { vertical: 'middle', horizontal: 'center' };
    X3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Y2 dhe Y3
    const Y2 = worksheet.getCell('Y2');
    Y2.value = 'Nota debitore e pranuar, nota kreditore e lëshuar';
    Y2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Y2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const Y3 = worksheet.getCell('Y3');
    Y3.value = '[55]';
    Y3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Y3.alignment = { vertical: 'middle', horizontal: 'center' };
    Y3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Z2 dhe Z3
    const Z2 = worksheet.getCell('Z2');
    Z2.value = 'Fatura e borxhit të keq e lëshuar';
    Z2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Z2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const Z3 = worksheet.getCell('Z3');
    Z3.value = '[59]';
    Z3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Z3.alignment = { vertical: 'middle', horizontal: 'center' };
    Z3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // AA2 dhe AA3
    const AA2 = worksheet.getCell('AA2');
    AA2.value = 'Rregullimet për të ulur TVSH-në për pagesë';
    AA2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    AA2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const AA3 = worksheet.getCell('AA3');
    AA3.value = '[63]';
    AA3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    AA3.alignment = { vertical: 'middle', horizontal: 'center' };
    AA3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // AB2 dhe AB3
    const AB2 = worksheet.getCell('AB2');
    AB2.value = 'Totali i TVSH-së së  zbritshme me 8%';
    AB2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    AB2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const AB3 = worksheet.getCell('AB3');
    AB3.value = '[K2]';
    AB3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    AB3.alignment = { vertical: 'middle', horizontal: 'center' };
    AB3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };



    // Pjesa e "Totali i TVSH-së së zbritshme me 18% dhe 8%"-------------------------------------

    worksheet.mergeCells('AC1', 'AC2');
    const X1X2 = worksheet.getCell('AC1');
    X1X2.value = 'Totali i TVSH-së së zbritshme me 18% dhe 8%';
    X1X2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    X1X2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    X1X2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    const AC3 = worksheet.getCell('AC3');
    AC3.value = '[67]';
    AC3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    AC3.alignment = { vertical: 'middle', horizontal: 'center' };
    AC3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Përdorni map për të shtuar rreshtat e të dhënave
    filteredData.map((item, index) => {
        const row = [];
        // Shtoni vlerat për secilën kolonë bazuar në të dhënat e furnizuara
        row.push(index + 1);
        row.push(item.DataFatures || '');
        // row.push(item.NumriFatures || '');
        row.push((item.ActionType == "IMP" ? item.ImpInvoiceNr : item.NumriFatures) || '')
        row.push(item.EmriShitesit || '');
        row.push(item.IdentificationNumber || '');
        row.push(item.VATNumber || '');
        row.push(item.BlerjetPaTVSH || '');
        row.push(item.Blerjet_dhe_Imp_Inv_PaTVSH || '');
        row.push(item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme || '');
        row.push(item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme1 || '');
        row.push(item.Importet18 || '');
        row.push(item.ImportetInvestive18 || '');
        row.push(item.BlerjetVendore18 || '');
        row.push(item.BlerjetInvestive18 || '');
        row.push(item.NotaKreditore18 || '');
        row.push(item.BorgjiKeq18 || '');
        row.push(item.Rregullimet18 || '');
        row.push(item.NgarkesaKundert18 || '');
        row.push(item.TotalTVSH18 || '');
        row.push(item.Importet8 || '');
        row.push(item.ImportetInvestive8 || '');
        row.push(item.BlerjetVendore8 || '');
        row.push(item.BlerjetInvestive8 || '');
        row.push(item.BlerjetNgaFermeret8 || '');
        row.push(item.NotaKreditore8 || '');
        row.push(item.BorgjiKeq8 || '');
        row.push(item.Rregullimet8 || '');
        row.push(item.TotalTVSH8 || '');
        row.push(item.Total_TVSH_8_dhe_18 || '');





        // Shtoni rreshtin në sheet
        worksheet.addRow(row);
    });

    // Kthej workbook-in për përdorim në komponentin tjetër
    return workbook;
};
