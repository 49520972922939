import React, { useState, useEffect } from "react";
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import {errorAlert, updateAlert } from "../../Components/Alerts";
import { Paper } from "@mui/material";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import Button from 'react-bootstrap/Button';

export default function EditPurchaseExpence(item) {

    const TranscationData = [
        {
            id: 1,
            type: "Vendor"
        },
        {
            id: 2,
            type: "Import"
        },
        {
            id: 3,
            type: "Import i Sherbimeve"
        },
    ]

    const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    const [supplier, setSupplier] = useState([]);
    const [expence, setExpence] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);

    //Kodi per mbajtjen e totaleve pas llogaritjes
    const [vat8, setVat8] = useState(0);
    const [vat18, setVat18] = useState(0);
    const [total, setTotal] = useState(0);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        ActionType: "",
        AccountID: "",
        SupplierID: "",
        Amount0: 0,
        Amount8: 0,
        Amount18: 0,
        VAT8: "",
        VAT18: "",
        Total: "",
        PaymentOptions: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: ""
    });

    const handleEdit = (item) => {
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            SupplierID: item.SupplierID,
            Amount0: item.Amount0,
            Amount8: item.Amount8,
            Amount18: item.Amount18,
            VAT8: item.VAT8,
            VAT18: item.VAT18,
            Total: item.Total,
            PaymentOptions: item.PaymentOptions,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: loginEmail,
        });
    };
    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'ExpensePurchases', formData, { headers })
            .then((response) => {
                console.log(response.data);
                // fetchData();
                updateAlert("Expense Purchases updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update Expense Purchases")
            });
    };


    //Kodi per kalkulimin e VAT8
    useEffect(() => {
        const VAT8 = formData.Amount8 * 0.08; // llogarit produktin me 18% shumëzim
        setVat8(VAT8); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount8]);

    //Kodi per kalkulimin e VAT18
    useEffect(() => {
        const VAT18 = formData.Amount18 * 0.18; // llogarit produktin me 18% shumëzim
        setVat18(VAT18); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount18]);

    //Kodi per kalkulimin e Total
    useEffect(() => {
        const total = Number(formData.Amount0) + Number(formData.Amount8) + Number(formData.Amount18) + Number(vat8) + Number(vat18)
        // + amount18 + vat8 + vat18   
        setTotal(total); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount0, formData.Amount8, formData.Amount18, vat8, vat18]);



    useEffect(() => {
        axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers, params: { email: logedEmail } })
            .then((response) => {
                setCashRegister(response.data);
            }).catch((error) => {
                // errorAlert(error);
            })
    }, [logedEmail])

    useEffect(() => {
        axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { email: logedEmail, ledger: 302 } })
            .then((response) => {
                setSupplier(response.data);
            }).catch((error) => {
                // errorAlert(error);
            })
    }, [logedEmail])

    return (
        <div>
            <div className='header_paper2'></div>
            <form onSubmit={handleSubmit} className='header_paper'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField style={{ width: "594px" }}
                                    id="invoiceNR"
                                    label="Invoice Number"
                                    variant="outlined"
                                    type="text"
                                    name='InvoiceNr'
                                    value={formData.InvoiceNr}
                                    onChange={handleInputChange}
                                    disabled
                                />

                                <div className='boxx1'>
                                    <TextField style={{ width: "230px" }}
                                        id="date"
                                        type="date"
                                        variant="outlined"
                                        name='Date'
                                        value={formData.Date.substring(0, 10)}
                                        onChange={handleInputChange}
                                    />
                                    <TextField style={{ width: "343px" }}
                                        id="id"
                                        select
                                        name="TransactionType"
                                        label="Select Transaction Type"
                                        value={formData.TransactionType}
                                        onChange={handleInputChange}
                                    >
                                        {TranscationData.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <div className="textfield_button">
                                    <div>
                                        <TextField style={{ width: "504px" }}
                                            id="AccountID"
                                            name="AccountID"
                                            select
                                            label="Select Suppler"
                                            value={formData.AccountID}
                                            onChange={handleInputChange}
                                        >
                                            {supplier.map((data) => (
                                                <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    {/* <div className="button_add" >

                                        <Button style={{ width: "40px" }} href="#outlined-buttons">

                                            <InsertData fetchData={fetchSuppliers} />


                                        </Button>
                                    </div> */}
                                </div>
                                <TextField style={{ width: "593px" }}
                                    id="AccountiD"
                                    select
                                    label="Select Payment Type"
                                    name="PaymentOptions"
                                    value={formData.PaymentOptions}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem key="0" value="0">Pagesa Behet më vonë
                                    </MenuItem>
                                    {cashRegister.map((data) => (
                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='box2'>
                                <div className='box3'>
                                    <TextField style={{ width: "150px" }}
                                        id="Amount18"
                                        label="Amount 18%"
                                        variant="outlined"
                                        name='Amount18'
                                        value={formData.Amount18}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField style={{ width: "150px" }}
                                        id="outlined-basic"
                                        label="VAT 18%"
                                        variant="outlined"
                                        name='VAT18'
                                        value={vat18}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3'>
                                    <TextField style={{ width: "150px" }}
                                        id="Amount8"
                                        label="Amount 8"
                                        name='Amount8'
                                        variant="outlined"
                                        value={formData.Amount8}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField style={{ width: "150px" }}
                                        id="outlined-basic"
                                        label="VAT 8"
                                        variant="outlined"
                                        name='VAT8'
                                        value={vat8}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>

                                <div className='box3'>
                                    <TextField style={{ width: "150px" }}
                                        id="Amount0"
                                        label="Amount 0"
                                        variant="outlined"
                                        name='Amount0'
                                        value={formData.Amount0}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />

                                    <TextField style={{ width: "150px" }}
                                        id="outlined-basic"
                                        label="VAT 0"
                                        variant="outlined"
                                        value={0}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </div>
                                <TextField style={{ width: "495px" }}
                                    type="text"
                                    id="Coment"
                                    name="Coment"
                                    label="Comment"
                                    variant="outlined"
                                    value={formData.Coment}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>


                    </Box>
                </Paper>

                <button type="submit"
                    className="btn btn-primary float-end"
                    style={{ background: "#7a95a6" }}
                >Save & New</button>

                <Link
                    className="btn btn-primary float-end"
                    style={{ background: "#7a95a6" }}
                    to={"/purchases/expensepurchases"}

                >EXIT</Link>
            </form>
        </div>
    )

}