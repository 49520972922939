import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import './../../Style/AccountingSheetStyle.scss'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAlert, errorAlert, updateAlert, handleSuccess } from "../../Components/Alerts";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { AiFillEdit, AiOutlinePrinter, AiOutlineFilePdf, AiFillAppstore } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import DataGridWithActions from "../../Components/DataGridWithActions";
import { AccountingSheetGridHeader, detailsData } from "./AccountingSheetGridHeader";
import Autocomplete from "@mui/material/Autocomplete";

export default function AccountingSheets(props) {
    const currentYear = new Date().getFullYear();
    const [showDetails, setShowDetails] = useState(true);


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        Coment: "",
        ModifiedByUser: ""
    });

    const [formTableData, setFormTableData] = useState({
        JournalID: "",
        InvoiceNr: "",
        LedgerID: "",
        AccountID: "",
        Comment: "",
        Debit: "",
        Credit: "",
        Total: "",
        ModifiedByUser: ""
    })

    // const [invoiceNR, setInvoiceNR] = useState("");
    const [actionType, setActionType] = useState("");
    const [ledgerID, setLedgerID] = useState("");
    const [accountID, setAccountID] = useState();
    const [amount0, setAmount0] = useState(0);

    // array per mbajtjen e te dhenave te tabeles qe shfaqet ne editim
    const [tableData, setTableData] = useState([]);
    const [ledgersID, setLedgersID] = useState([]);
    const [accountsID, setAccountsID] = useState([]);
    const [accountsID2, setAccountsID2] = useState([]);
    const [number, setNumber] = useState(1);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteConfirmationDetails, setShowDeleteConfirmationDetails] = useState(false);

    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteItemIdDetails, setDeleteItemIdDetails] = useState(null);

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);// Fillimisht është e shfaqur


    const [creditTotal, setCreditTotal] = useState([]);
    const [debitTotal, setDebitTotal] = useState([]);
    const [balance, setBalance] = useState(0);

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet', { headers });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    //Kodi per shfaqjen e tabeles ne PDF
    // const handleExportPDF = () => {
    //     const doc = new jsPDF();
    //     // Teksti i parë në fillim të dokumentit
    //     const text1 = "AccounTree";
    //     doc.text(text1, 10, 10); // 20 është pozicioni X, 10 është pozicioni Y

    //     // Teksti i dytë në fillim të dokumentit
    //     const text2 = "Credit Total";
    //     doc.text(text2, 10, 20); // 20 është pozicioni X, 20 është pozicioni Y

    //     // Teksti i tretë në fillim të dokumentit
    //     // const text3 = "Accounting is the process of recording financial transactions pertaining to a business.";
    //     // doc.text(text3, 10, 30); // 20 është pozicioni X, 30 është pozicioni Y
    //     // const paragraph = "Ky është një paragraf në fillim të tabelës.";
    //     // doc.text(paragraph, 20, 40); // 20 është pozicioni X, 40 është pozicioni Y
    //     doc.autoTable({
    //         head: [["Invoice Number", "Date", "Client", "Total % VAT", "VAT", "Total"]],
    //         body: filteredData.map((item) => [item.InvoiceNr, item.Date, item.AccountName, item.Total, item.VAT18, item.Total]),
    //         startY: 30
    //     });
    //     doc.save("AccountingSheets-List.pdf");
    // };

    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };
    // `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Invoice Number", "Date", "Description", "Total"]],
            body: filteredData.map((item, index) => [
                number + index,
                `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,
                formatDate(item.Date),
                item.Coment,
                item.Total.toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "List of accounting sheet", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("AccountingSheet.pdf");
    };

    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    //Kodi per pegination ne tabel
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true); // I fsheh formën e parë
    };

    const clearEdit = () => {
        setFormTableData({
            JournalID: "",
            InvoiceNr: "",
            LedgerID: "",
            AccountID: "",
            Comment: "",
            Debit: "",
            Credit: "",
            Total: "",
            ModifiedByUser: ""
        });
        handleOpenForm2();
    };

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'AccountingSheet' + `/${deleteItemId}`, { headers }
            );
            setShowDeleteConfirmation(false);
            deleteAlert("Accounting sheet deleted Successfully");
            fetchData();
        } catch (error) {
            deleteAlert("Accounting sheet can not be deleted");
        }
    };
    const handleDeleteDetail = async () => {
        try {
            const response = await axios.delete(
                ApiLink.API_URL + 'AccountingSheet/onelineEdit' + `/${deleteItemIdDetails}`, { headers, params: { email: logedEmail, invoiceNR: formData.InvoiceNr } }
            );

            if (response.status === 200) {
                setShowDeleteConfirmationDetails(false);
                deleteAlert("Accounting sheet deleted Successfully");
                fetchTableData();
                fetchDataCreditTotal();
                fetchDataDebitTotal();
                fetchData();
            } else {
                errorAlert("Accounting sheet can not be deleted");
                setShowDeleteConfirmationDetails(false);

            }
        } catch (error) {
            errorAlert("An error occurred while trying to delete the accounting sheet");
        }
    };


    // kodi qe i merr te dhenat detale per ti shfaqur ne tabelen e editimit
    // const fetchTableData = async () => {
    //     try {
    //         const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/journalaccountingsheets', { headers, params: { email: logedEmail, invNumber: formData.InvoiceNr } });
    //         setTableData(response.data);
    //         setLoading(false);

    //         // Kontrollo nëse nuk ka të dhëna të disponueshme
    //         if (response.data.length === 0) {
    //             // Shfaqi një mesazh për përdoruesin
    //             // errorAlert("No data available.");

    //         }
    //     } catch (error) {
    //         console.log(error);
    //         // Shfaqi një mesazh për përdoruesin për gabimin e tjera
    //         // setMessage("Error: Failed to fetch data.");
    //     }
    // };

    const fetchTableData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/journalaccountingsheets', { headers, params: { invNumber: formData.InvoiceNr } });
            setTableData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!formData.InvoiceNr) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        const stateDate = {
            Date: formData.Date,
            ActionType: actionType,
            InvoiceNr: formData.InvoiceNr,
            CreatedByUser: logedEmail,
            LedgerID: ledgerID,
            AccountID: accountID,
            Amount0: amount0
        };
        axios.post(ApiLink.API_URL + 'AccountingSheet/createnewline', stateDate, { headers })
            .then(() => {
                handleSuccess("Accounting Sheet Added Successfully");
                setAccountID();
                setActionType("");
                setLedgerID("");
                setAmount0(0);
                fetchData();
                fetchTableData();
                fetchDataCreditTotal();
                fetchDataDebitTotal();
                setfield(null);
                setAccountFields(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }


    const handleEditTableData = (item) => {
        handleOpenForm1();
        setFormTableData({
            JournalID: item.JournalID,
            InvoiceNr: item.InvoiceNr,
            LedgerID: item.LedgerID,
            AccountID: item.AccountID,
            Comment: item.Comment,
            Total: creditTotal,
            Credit: item.Credit,
            Debit: item.Debit,
            ModifiedByUser: loginEmail,

        })
    }
    const handleInputChangeTableData = (event) => {
        const { name, value } = event.target;
        setFormTableData({ ...formTableData, [name]: value });
    };

    const handleSubmitTableData = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'AccountingSheet/AccountingSheetDetailsUpdate', formTableData, { headers })
            .then((response) => {
                // console.log(response.data);
                fetchData();
                fetchTableData();
                fetchDataCreditTotal();
                fetchDataDebitTotal();
                updateAlert("Detail updated successfully");
                handleOpenForm2();

            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update details")
            });
    }


    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            Coment: item.Coment,
            ModifiedByUser: loginEmail,
        });
        setShowEditModal(true);
        setShowDetails(false);
        handleOpenForm2();

    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'AccountingSheet/AccountingSheetTransaction', formData, { headers })
            .then((response) => {
                // console.log(response.data);
                // setShowEditModal(false); 
                updateAlert("Accounting sheet updated successfully");
                fetchData();
                fetchTableData();
            })
            .catch((error) => {
                // alert("Failed to update State");
                errorAlert("Failed to update Accounting sheet")
            });
    };

    const filteredData = data.filter((item) => {
        return (
            item.ActionType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    const fetchaccounts = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger/accountsbyledgerid', { headers, params: { email: logedEmail, ledgerID: formTableData.LedgerID } });
            setAccountsID(response.data);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorMessage) {
                console.log("Gabim në marrjen e të dhënave:", error.response.data.errorMessage);
            } else {
                // errorAlert("Gabim në marrjen e të dhënave!");
            }
        }
    };

    const fetchaccounts2 = async (ledger) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger/accountsbyledgerid', { headers, params: { email: logedEmail, ledgerID: ledger } });
            setAccountsID2(response.data);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorMessage) {
                console.log("Gabim në marrjen e të dhënave:", error.response.data.errorMessage);
            } else {
                // errorAlert("Gabim në marrjen e të dhënave!");
            }
        }
    };


    useEffect(() => {
        axios.get(ApiLink.API_URL + 'ledger', { headers })
            .then((response) => {
                setLedgersID(response.data);
            }).catch(() => {
                // errorAlert(error);
            })
    }, [])

    const fetchDataCreditTotal = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/CreditTOT', { headers, params: { email: logedEmail, invNumber: formData.InvoiceNr } });
            setCreditTotal(response.data);
            setLoading(false);

            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (response.data.length === 0) {
                // Shfaqi një mesazh për përdoruesin
                // errorAlert("No data available.");

            }
        } catch (error) {
            console.log(error);
            // Shfaqi një mesazh për përdoruesin për gabimin e tjera
            // setMessage("Error: Failed to fetch data.");
        }
    };

    const fetchDataDebitTotal = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/DebitTOT', { headers, params: { email: logedEmail, invNumber: formData.InvoiceNr } });
            setDebitTotal(response.data);
            setLoading(false);

            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (response.data.length === 0) {
                // Shfaqi një mesazh për përdoruesin
                // errorAlert("No data available.");

            }
        } catch (error) {
            console.log(error);
            // Shfaqi një mesazh për përdoruesin për gabimin e tjera
            // setMessage("Error: Failed to fetch data.");
        }
    };

    useEffect(() => {
        const calculatedBalance = creditTotal - debitTotal;
        setBalance(calculatedBalance);
    }, [creditTotal, debitTotal]);

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        inv: item.ActionType + "-" + item.FiscalYear + "-" + item.InvoiceNr,
        date: (item.Date.substring(0, 10)),
        description: item.Coment,
        total: item.Total,
        deleteItemId: item.TransactionID,
        rowInfo: item
    }));


    const detailsRows = tableData.map((item, index) => ({
        id: ++index,
        ledger: item.LedgerNameEN,
        account: item.AccountName,
        comment: item.Coment,
        debi: item.Debit,
        credi: item.Credit,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));

    //Pjesa per select input field Clients
    const [field, setfield] = useState(null);
    const handleAutocompleteChangeInsertLedgers = (event, value) => {
        if (value && value.LedgerID) {
            setLedgerID(value.LedgerID);
            setfield(value); // Përditëso `selectedArticle` me vlerën e zgjedhur
            fetchaccounts2(value.LedgerID);

        } else {
            // Në rast se vlera është pastruar
            setLedgerID(0); // Vendos vlerën e ArticleID në null
            setfield(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsInsertLedgers = {
        options: ledgersID,
        getOptionLabel: (option) =>
            option.LedgerNameEN || ""
    };
    //////


    //Select list for Clients
    const handleAutocompleteChangeLedgers = (event, value) => {
        if (value) {
            setFormTableData(prevState => ({
                ...prevState,
                LedgerID: value.LedgerID
            }));
            fetchaccounts();

        } else {
            setFormTableData(prevState => ({
                ...prevState,
                LedgerID: ""
            }));
        }
    };

    const defaultPropsLedgers = {
        options: ledgersID,
        getOptionLabel: (option) =>
            option.LedgerNameEN || ""
    };

    //Kodi i seelect list per insetim te accounts
    const [accountsField, setAccountFields] = useState(null);
    const handleAutocompleteChangeInsertAccounts = (event, value) => {
        if (value && value.AccountID) {
            setAccountID(value.AccountID);
            setAccountFields(value); // Përditëso `selectedArticle` me vlerën e zgjedhur

        } else {
            // Në rast se vlera është pastruar
            setAccountID(""); // Vendos vlerën e ArticleID në null
            setAccountFields(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsInsertAccounts = {
        options: accountsID2,
        getOptionLabel: (option) =>
            option.AccountName || ""
    };


    //Kodi i seelect list per editim te accounts
    const handleAutocompleteChangeAccounts = (event, value) => {
        if (value) {
            setFormTableData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));

        } else {
            setFormTableData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    const defaultPropsAccounts = {
        options: accountsID,
        getOptionLabel: (option) =>
            option.AccountName || ""
    };



    useEffect(() => {
        fetchData();
        fetchTableData();
        fetchaccounts();
        // fetchaccounts2();
        fetchDataCreditTotal();
        fetchDataDebitTotal();
        fetchCompaniesData();
    }, [logedEmail, formData.InvoiceNr, formTableData.LedgerID, ledgerID]);

    return (
        <div className="AccountingSheet__Container">
            {/* Modal per konfirmin nese deshiron me fshi nje element */}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmationDetails} onHide={() => setShowDeleteConfirmationDetails(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation 2</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationDetails(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDeleteDetail}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <br></br>
            <br></br>
            {showEditModal && (
                <Paper>
                    <form onSubmit={handleSubmit} className='header_paper'>
                        <Box
                            // component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div className='AccountingSheet__Container__Form'>
                                <div >
                                    <TextField
                                        className="AccountingSheet__Container__Form__TextField__1"
                                        id="InvoiceNr"
                                        label="Invoice Number"
                                        variant="outlined"
                                        type="text"
                                        name='InvoiceNr'
                                        value={"ACS-" + currentYear + "-" + formData.InvoiceNr}
                                        onChange={handleInputChange}
                                        disabled
                                    />
                                </div>
                                <div >
                                    <TextField
                                        className="AccountingSheet__Container__Form__TextField__2"
                                        id="Date"
                                        type="date"
                                        variant="outlined"
                                        name='Date'
                                        value={formData.Date.substring(0, 10)}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <div>
                                        <TextField
                                            className="AccountingSheet__Container__Form__TextField__3"
                                            id="Coment"
                                            select
                                            label="Komenti"
                                            name='Coment'
                                            // defaultValue=""
                                            value={formData.Coment}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="Open Accounts">Hapje e Llogarive</MenuItem>
                                            <MenuItem value="Balance Accounts">Barazim i Llogarive</MenuItem>
                                            <MenuItem value="Closed Accounts">Mbyllje e Llogarive</MenuItem>
                                        </TextField>
                                    </div>
                                </div>
                                <button type="submit"
                                    className="btn btn-primary"
                                    style={{ background: "#7a95a6", width: "110px", marginLeft: "15px" }}
                                >Ndrysho</button>
                            </div>
                        </Box>
                    </form>
                    <br></br>
                    <br></br>
                    {showEditForm && (
                        <form onSubmit={handleSubmitTableData} className='header_paper'>
                            <Box
                                // component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className='AccountingSheet__Container__Form'>
                                    <div>
                                        {/* <TextField
                                            className="AccountingSheet__Container__Form__TextField__4"
                                            id="LedgerID"
                                            name="LedgerID"
                                            select
                                            label="Select Ledger"
                                            defaultValue=""
                                            // helperText="Please select expense"
                                            value={formTableData.LedgerID}
                                            onChange={(event) => {
                                                handleInputChangeTableData(event);
                                                fetchaccounts();
                                            }}
                                        >
                                            {ledgersID.map((data) => (
                                                <MenuItem key={data.LedgerID} value={data.LedgerID}>{data.LedgerNameEN}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        <Autocomplete
                                            fullWidth
                                            // sx={{ m: 2 }}
                                            {...defaultPropsLedgers}
                                            id="LedgerID"
                                            includeInputInList
                                            onChange={handleAutocompleteChangeLedgers}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    label="Lloji llogarisë"
                                                    // variant="filled"
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                />
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                },
                                            }}
                                            value={ledgersID.find(option => option.LedgerID === formTableData.LedgerID) || null}
                                        />
                                    </div>
                                    <div>
                                        {/* <TextField
                                            className="AccountingSheet__Container__Form__TextField__4"
                                            id="AccountID"
                                            name="AccountID"
                                            select
                                            label="Select Account"
                                            defaultValue=""
                                            // helperText="Please select expense"
                                            value={formTableData.AccountID}
                                            onChange={handleInputChangeTableData}
                                        >
                                            {accountsID.map((data) => (
                                                <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        <Autocomplete
                                            fullWidth
                                            // sx={{ m: 2 }}
                                            {...defaultPropsAccounts}
                                            id="AccountID"
                                            includeInputInList
                                            onChange={handleAutocompleteChangeAccounts}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    label="Llogaria"
                                                    // variant="filled"
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                />
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                },
                                            }}
                                            value={accountsID.find(option => option.AccountID === formTableData.AccountID) || null}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            className="AccountingSheet__Container__Form__TextField__5"
                                            id="Comment"
                                            name="Comment"
                                            select
                                            label="Debit/Credit"
                                            defaultValue=""
                                            // helperText="Please select expense"
                                            value={formTableData.Comment}
                                            onChange={handleInputChangeTableData}
                                        >
                                            <MenuItem value="Debit">Debit</MenuItem>
                                            <MenuItem value="Credi">Credit</MenuItem>
                                        </TextField>
                                    </div>
                                    {/* // Pasi të keni vendosur vlerat, shfaqeni ato në elementin 'TextField' */}
                                    <div className='box3'>
                                        <TextField
                                            className="AccountingSheet__Container__Form__TextField__5"
                                            id="Debit"
                                            label="Total"
                                            variant="outlined"
                                            name={formTableData.Comment === 'Debit' ? 'Debit' : 'Credit'}
                                            value={formTableData.Comment === 'Debit' ? formTableData.Debit : formTableData.Credit}
                                            onChange={handleInputChangeTableData}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="butonat">
                                        <button type="submit"
                                            className="btn btn-primary float-end"
                                            style={{ background: "#7a95a6", width: "110px", marginLeft: "15px" }}
                                        >Save</button>
                                    </div>
                                </div>
                                <br></br>
                            </Box>
                        </form>
                    )}

                    {showInserForm && (
                        <form onSubmit={handleNewSubmit} className='header_paper'>
                            <Box
                                // component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className='AccountingSheet__Container__Form'>
                                    <div>
                                        {/* <TextField
                                            className="AccountingSheet__Container__Form__TextField__4"
                                            id="LedgerID"
                                            select
                                            label="Select Ledger"
                                            defaultValue=""
                                            // helperText="Please select expense"
                                            value={ledgerID}
                                            // onChange={event => setLedgerID(event.target.value)}
                                            onChange={event => { setLedgerID(event.target.value); fetchaccounts2(); }}

                                        >
                                            {ledgersID.map((data) => (
                                                <MenuItem key={data.LedgerID} value={data.LedgerID}>{data.LedgerNameEN}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        <Autocomplete
                                            fullWidth
                                            {...defaultPropsInsertLedgers}
                                            id="include-input-in-list"
                                            includeInputInList
                                            value={field}  // Lidheni me state-in `selectedArticle`
                                            onChange={handleAutocompleteChangeInsertLedgers}
                                            isOptionEqualToValue={(option, value) => option.LedgerID === value.LedgerID} // Shto funksionin isOptionEqualToValue
                                            renderInput={(params) => (
                                                <TextField
                                                    // style={{ width: "91%" }}
                                                    className="AddPurchaseTotal__Container__Format__SameInput"

                                                    {...params}
                                                    label="Përzgjedh llogarinë Kontabel"
                                                // variant="filled"
                                                //   onKeyPress={handleKeyPress}
                                                />
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                },
                                            }}
                                        />
                                    </div>
                                    <div>
                                        {/* <TextField
                                            className="AccountingSheet__Container__Form__TextField__4"
                                            id="AccountID"
                                            select
                                            label="Select Account"
                                            defaultValue=""
                                            // helperText="Please select expense"
                                            value={accountID}
                                            onChange={event => setAccountID(event.target.value)}
                                        >
                                            {accountsID2.map((data) => (
                                                <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        <Autocomplete
                                            fullWidth
                                            {...defaultPropsInsertAccounts}
                                            id="include-input-in-list"
                                            includeInputInList
                                            value={accountsField}  // Lidheni me state-in `selectedArticle`
                                            onChange={handleAutocompleteChangeInsertAccounts}
                                            isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                            renderInput={(params) => (
                                                <TextField
                                                    // style={{ width: "91%" }}
                                                    className="AddPurchaseTotal__Container__Format__SameInput"

                                                    {...params}
                                                    label="Përzgjedh llogarinë"
                                                // variant="filled"
                                                //   onKeyPress={handleKeyPress}
                                                />
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                },
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            className="AccountingSheet__Container__Form__TextField__5"
                                            id="ActionType"
                                            select
                                            label="Debi/Kredi"
                                            defaultValue=""
                                            // helperText="Please select expense"
                                            value={actionType}
                                            onChange={event => setActionType(event.target.value)}
                                        >
                                            <MenuItem value="Debit">Debit</MenuItem>
                                            <MenuItem value="Credi">Credit</MenuItem>
                                        </TextField>
                                    </div>
                                    <div className='box3'>
                                        <TextField
                                            className="AccountingSheet__Container__Form__TextField__5"
                                            id="outlined-basic"
                                            label="Vlera"
                                            variant="outlined"
                                            name='Amount0'
                                            value={amount0}
                                            onChange={event => setAmount0(event.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="butonat">
                                        <button type="submit"
                                            className="btn btn-primary float-end"
                                            style={{ background: "#7a95a6", width: "110px", marginLeft: "15px" }}
                                        >Shto</button>
                                    </div>
                                </div>
                                <br></br>
                            </Box>
                        </form>
                    )}

                    <TableContainer sx={{ maxHeight: 500 }}>
                        <Table ref={tableRef} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={styles.tableHeader}><strong>Llogaria kontabel</strong></TableCell>
                                    <TableCell sx={styles.tableHeader}><strong>Llogaria</strong></TableCell>
                                    <TableCell sx={styles.tableHeader}><strong>Komenti</strong></TableCell>
                                    <TableCell sx={styles.tableHeader}><strong>Debi</strong></TableCell>
                                    <TableCell sx={styles.tableHeader}><strong>Kredi</strong></TableCell>
                                    <TableCell sx={styles.tableHeader}><strong>Veprimet</strong></TableCell>

                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <div className="spinner" style={{ marginLeft: "560px" }} ></div>
                            ) : (
                                <TableBody>
                                    {tableData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={item.TransactionID}>
                                                <TableCell sx={styles.tableCell}>{item.LedgerNameEN}</TableCell>
                                                <TableCell sx={styles.tableCell}>{item.AccountName}</TableCell>
                                                <TableCell sx={styles.tableCell}>{item.Coment}</TableCell>
                                                <TableCell sx={styles.tableCell}>{item.Debit}</TableCell>
                                                <TableCell sx={styles.tableCell}>{item.Credit}</TableCell>
                                                <TableCell sx={styles.tableCell}>
                                                    <Tooltip title="Edit" placement="left">
                                                        <IconButton>
                                                            <AiFillEdit style={{ color: '#466F86' }} onClick={() => { handleEditTableData(item); handleOpenForm1(); }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="right">
                                                        <IconButton>
                                                            <DeleteIcon style={{ color: '#962a38' }}
                                                                onClick={() => {
                                                                    setShowDeleteConfirmationDetails(true);
                                                                    setDeleteItemIdDetails(item.JournalID);
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    {/* <DataGridWithActions
                        columns={detailsData}
                        rows={detailsRows}
                        handleEdit={handleEditTableData}
                        handleDelete={handleDeleteDetail}
                        showPayment={false}
                    /> */}

                    <div className="AccountingSheet__Container__Form__totalet">
                        <TextField
                            className="AccountingSheet__Container__Form__TextField__6"
                            id="DebitTotal"
                            label="Debit Total"
                            variant="outlined"
                            type="text"
                            value={debitTotal}
                            disabled
                        />

                        <TextField
                            className="AccountingSheet__Container__Form__TextField__7"
                            id="CreditTotal"
                            label="Credit Total"
                            variant="outlined"
                            type="text"
                            value={creditTotal}
                            disabled
                        />
                        <TextField
                            className="AccountingSheet__Container__Form__TextField__8"
                            id="Balance"
                            label="Balance"
                            variant="outlined"
                            type="text"
                            value={balance}
                            disabled
                        />
                        <div className="butonat">
                            {balance != 0 ? (

                                <div className="alert alert-danger">
                                    The balance must be 0 to close the accounting sheet
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    style={{ background: "#7a95a6", width: "110px", marginLeft: "100px" }}
                                    onClick={() => {
                                        setShowEditModal(false);
                                        setShowDetails(true);

                                    }}
                                >
                                    Mbyll
                                </button>

                            )}
                        </div>
                    </div>
                    <br></br>

                </Paper>
            )
            }

            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Fletat kontabel</h3>
                        </div>
                        <div>
                            <Link to={"/accounting/addaccountingsheets"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />  Krijo Fletë kontabel
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e Fletave kontabel: Menaxhoni, shtoni, editoni dhe fshini Fleta kontabel.</p>
                    </div>


                    <DataGridWithActions
                        columns={AccountingSheetGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={false}
                        height={500}
                    />
                </div>
            )}
        </div >
    )
}