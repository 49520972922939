import React, { useState, useEffect } from "react";
import '../../Style/PasqyraTeArdhuraveStyle.scss';
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import { errorAlert } from "../../Components/Alerts";
import '../../Style/VatDeclaration.scss'

export default function IncomeStatements() {
    const vitAktual = new Date().getFullYear();
    const headers = authHeader();


    const [companyName, setCompanyName] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [address, setAdress] = useState("");
    const [phone, setPhone] = useState("");

    const [stokuTotal, setStokuTotal] = useState(0);

    const [txf4000, setTxf4000] = useState(0);
    const [txf4100, setTxf4100] = useState(0);
    const [txf4200, setTxf4200] = useState(0);
    const [txf5000, setTxf5000] = useState(0);
    const [txf5100, setTxf5100] = useState(0);
    const [txf5200, setTxf5200] = useState(0);
    const [txf5300, setTxf5300] = useState(0);
    const [txf5400, setTxf5400] = useState(0);
    const [txf5500, setTxf5500] = useState(0);


    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompanyName(response.data[0].CompanyName);
            setIdNumber(response.data[0].IdentificationNumber);
            setAdress(response.data[0].Address);
            setPhone(response.data[0].PhoneNumbers);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'FinanceStatement/PasqyraTeArdhurave', { headers });
            // Shëndërro vlerat null në 0 para se të ruhen në array
            const processedData = response.data.map(item => {
                Object.keys(item).forEach(key => {
                    if (item[key] === null) {
                        item[key] = 0;
                    }
                });
                return item;
            });
            setTxf4000(processedData[0]._4000_TeArdhurat);
            setTxf4100(processedData[0]._4100_TeArdhurat_Tjera);
            setTxf4200(processedData[0]._4200_TeArdhuratFinanciare);
            setTxf5000(processedData[0]._5000_Malli);
            setTxf5100(processedData[0]._5100_ShpenzimetShperndarjes);
            setTxf5200(processedData[0]._5200_ShpenzimetAdministrative);
            setTxf5300(processedData[0]._5300_ShpenzimetTjera);
            setTxf5400(processedData[0]._5400_ShpenzimetFinanciare);
            setTxf5500(processedData[0]._5500_ShpenzimetTatimitNeFitim);




            // console.log(processedData);
            // Ruaj array-in e përpunuar me vlerat null të zëvendësuar me 0
            // setData(processedData);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Kthehet nga serveri për mungesë qasje
            } else {
                errorAlert("Gabim në kërkesë:", error);

                if (error.response && error.response.data && error.response.data.errorMessage) {
                    errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
                } else if (error.message) {
                    errorAlert(`Gabim: ${error.message}`);
                } else {
                    errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
                }
            }
        }
    };

    const fetchTotalAmount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingBooks/TotaliStokut', { headers });
            setStokuTotal(response.data[0].StokuTotal);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    // const btn =() =>{
    //     alert(KMSH);
    // }


    // txf5000 =>malli
    let KMSH = txf5000 - stokuTotal;
    // txf4000 => teArdhurat
    let fitimiBruto = txf4000 - KMSH;
    let fitimiOperativ = (Number(fitimiBruto) + Number(txf4100)) - (Number(txf5100) + Number(txf5200) + Number(txf5300));
    let fitimiParaTatimit = (Number(fitimiOperativ) + Number(txf4200)) - Number(txf5400);
    let tatimiFitim;
    if (fitimiParaTatimit > 0) {
        tatimiFitim = (Number(fitimiParaTatimit) * 0.10)
    } else {
        tatimiFitim = 0;
    }
    let fitimneto = Number(fitimiParaTatimit) - Number(tatimiFitim);

    useEffect(() => {
        fetchCompaniesData();
        fetchData();
        fetchTotalAmount();

    }, []);

    return (
        <div style={{ paddingTop: "50px" }}>
            <div className='VatDeclaration__Container'>
                <div className='VatDeclaration__Container__HeaderData'>
                    <div>
                        <h5><b>Pasqyra e të ardhurave gjithëpërfshirëse për vitin që përfundon më 31 dhjetor</b></h5>
                    </div>
                    <hr></hr>
                    {/* <button onClick={btn}></button> */}
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[1]</b></h5>
                            <h5>Periudha tatimore:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{vitAktual}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[3]</b></h5>
                            <h5>Numri Fiskal:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{idNumber}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[4]</b></h5>
                            <h5>Emri i tatimpaguesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{companyName}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[5]</b></h5>
                            <h5>Adresa e tatimpaguesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{address}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[6]</b></h5>
                            <h5>Personi kontaktues:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{companyName}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[6a]</b></h5>
                            <h5>Kodi primar i veprimtarisë:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>0000</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[7]</b></h5>
                            <h5>Telefoni:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{phone}</h5>
                        </div>
                    </div>
                </div>
                <hr></hr>

                <div className='Pasqyra__Container__Table'>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>Nr. i zerit</h4>
                            <h5>Pershkrimi</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h5>Nr. i shenimit</h5>
                            <h5>Viti 2024</h5>
                            <h5>Viti 2023</h5>
                        </div>
                    </div>
                    <hr></hr>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>4000</h4>
                            <h6>Të hyrat</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{txf4000 !== undefined ? txf4000.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>5000</h4>
                            <h6>Kostoja e shitjes</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{KMSH !== undefined ? KMSH.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>H</h4>
                            <h6>Fitimi/(humbja)bruto</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{fitimiBruto !== undefined ? fitimiBruto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>0</h6>

                        </div>
                    </div>
                    <br></br>

                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>4100</h4>
                            <h6>Të ardhurat tjera</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{txf4100 !== undefined ? txf4100.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>5100</h4>
                            <h6>Shpenzimet e shpërndarjes</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{txf5100 !== undefined ? txf5100.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>5200</h4>
                            <h6>Shpenzimet administrative</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{txf5200 !== undefined ? txf5200.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>5300</h4>
                            <h6>Shpenzimet e tjera</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{txf5300 !== undefined ? txf5300.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>I</h4>
                            <h6>Fitimi/(humbja)operativ</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{fitimiOperativ !== undefined ? fitimiOperativ.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>0</h6>
                        </div>
                    </div>
                    <br></br>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>5400</h4>
                            <h6>Shpenzimet financiare</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{txf5400 !== undefined ? txf5400.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>4200</h4>
                            <h6>Te ardhurat financiare</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{txf4200 !== undefined ? txf4200.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>J</h4>
                            <h6>Fitimi/(humbja)para tatimit</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{fitimiParaTatimit !== undefined ? fitimiParaTatimit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>0</h6>
                        </div>
                    </div>
                    <br></br>

                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>5500</h4>
                            <h6>Shpenzimet e tatimit në tatim</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6 >{tatimiFitim !== undefined ? tatimiFitim.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>K</h4>
                            <h6>Fitimi/(humbja)i/e vitit</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{fitimneto !== undefined ? fitimneto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>0</h6>
                        </div>
                    </div>
                    <br></br>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>6000</h4>
                            <h6>Diferenca këmbimore nga përkthimi i pasqyrave të operacioneve të huaja, neto nga tatimi </h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>0</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h6>Të ardhurat tjera gjithëpërfshirëse të vitit </h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{fitimneto !== undefined ? fitimneto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>0</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>L</h4>
                            <h6>Gjithsej të ardhurat/(humbjet) gjithëpërfshirëse të vitit</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{fitimneto !== undefined ? fitimneto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>0</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}