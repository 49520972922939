import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import InsertData from "./InsertStates";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { SiHyperledger } from "react-icons/si";
import { AiFillEdit, AiOutlineDelete, AiOutlinePrinter, AiOutlineFilePdf } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


export default function States() {
    const [data, setData] = useState([]);

    const [role, setRole] = useState("");
    const headers = authHeader();

    const [formData, setFormData] = useState({
        StateID: "",
        StateName: "",
        IsActive: false,

    });

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [number, setNumber] = useState(1);
    // Kodi per Marrjen e informatave nga databaza
    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'states', { headers });
            setLoading(false);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    //Kodi per shfaqjen e tabeles ne PDF
    const handleExportPDF = () => {
        const doc = new jsPDF();
        // Teksti i parë në fillim të dokumentit
        const text1 = "AccounTree";
        doc.text(text1, 10, 10); // 20 është pozicioni X, 10 është pozicioni Y

        // Teksti i dytë në fillim të dokumentit
        const text2 = "States List";
        doc.text(text2, 10, 20); // 20 është pozicioni X, 20 është pozicioni Y

        // Teksti i tretë në fillim të dokumentit
        // const text3 = "Accounting is the process of recording financial transactions pertaining to a business.";
        // doc.text(text3, 10, 30); // 20 është pozicioni X, 30 është pozicioni Y

        // Paragrafi në fillim të dokumentit
        // const paragraph = "Ky është një paragraf në fillim të tabelës.";
        // doc.text(paragraph, 20, 40); // 20 është pozicioni X, 40 është pozicioni Y
        doc.autoTable({
            head: [["State ID", "States Name", "Active"]],
            body: filteredData.map((item) => [item.StateID, item.StateName, item.IsActive]),
            startY: 30
        });
        doc.save("States-List.pdf");
    };


    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    //Kodi per fshirjen e nje elementi ne databaz 
    // const handleDelete = async () => {
    //     try {
    //         const response = await axios.delete(
    //             ApiLink.API_URL + 'states' + `/${deleteItemId}`
    //         );
    //         setShowDeleteConfirmation(false);
    //         deleteAlert("State Deleted Successfully");
    //         fetchData();
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };


    const handleDelete = async () => {
        try {
            const headers = authHeader();
            const response = await axios.delete(
                ApiLink.API_URL + 'states' + `/${deleteItemId}`, { headers }
            );
            setShowDeleteConfirmation(false);
            deleteAlert("State Deleted Successfully");
            fetchData();
        } catch (error) {
            if (error.response.data) {
                deleteAlert("State can not be deleted!!!");
            } else {
                console.log(error);
            }
        }
    };

    //Kodi per pegination ne tabel
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    //Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        setFormData({
            StateID: item.StateID,
            StateName: item.StateName,
            IsActive: item.IsActive,
        });
        setShowEditModal(true);
    };

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    //Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        const headers = authHeader();
        axios
            .put(ApiLink.API_URL + 'states', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("State updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update State")
            });
    };

    // setTimeout(function() {
    //     fetchData()   
    // }, 30000); // 30 seconds in milliseconds

    const filteredData = data.filter((item) => {
        return (
            item.StateName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const fetchRole = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AuthProva/getrole', { headers });

            if (response.data.UserRole && response.data.UserRole !== undefined) {
                setRole(response.data.UserRole);
            } else {
                errorAlert("Undefined Role.");
            }
        } catch (error) {
            console.error("Error fetching role:", error);
            // ... (pjesa tjetër e kodit)
        }
    };

    //Ruhen tdhanat 
    useEffect(() => {
        fetchData();
        fetchRole();
    }, []);

    return (
        <div className="Crud__Container">
            {/* Modal per konfirmin nese deshiron me fshi nje element */}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div className="edit-modal">

                    <form onSubmit={handleSubmit}>
                        <h3>Edit State</h3>

                        <div className="input-group mb-3">
                            <span className="input-group-text col-3 col-sm-3">ID </span>
                            <input
                                className="form-control"
                                type="number"
                                id="StateID"
                                name="StateID"
                                value={formData.StateID}
                                onChange={handleInputChange}
                                disabled
                            />

                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-3 col-sm-3">State Name</span>
                            <input
                                className="form-control"
                                type="text"
                                id="StateName"
                                name="StateName"
                                value={formData.StateName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="IsActive">Active</label>
                            <input
                                type="checkbox"
                                id="IsActive"
                                name="IsActive"
                                checked={formData.IsActive}
                                onChange={(event) =>
                                    setFormData({ ...formData, IsActive: event.target.checked })
                                }
                            />
                        </div>
                        <button type="submit" className="btn btn-success">Update</button>

                        <button
                            className="btn btn-secondary"
                            onClick={() => setShowEditModal(false)}
                        >
                            Cancel
                        </button>
                    </form>
                </div>
            )}


            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>States List</h3>
                </div>

                {
                    role === "acctree" && (
                        <div>
                            <InsertData fetchData={fetchData} />
                        </div>
                    )
                }
            </div>
            <div className="first_paragraph">
                <p>Accounting is the process of recording financial transactions pertaining to a business.</p>
            </div>

            <div className="bordere">
                <div className="butonss">
                    {/* <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}
                </div>

                <div className="searchboxx">
                    <div className="flexbox">
                        <div class="search">
                            <div>
                                <input
                                    type="text"
                                    placeholder="Search States"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}

                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <hr></hr>


            {/* Tabela per shfaqjen e te dhenave  */}

            <Paper>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table ref={tableRef} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styles.tableHeader}><strong>#</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>State Name</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong></strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong></strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Active</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong></strong></TableCell>
                                {
                                    role === "acctree" && (
                                        <TableCell sx={styles.tableHeader}><strong>Actions</strong></TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <div className="spinner" style={{ marginLeft: "550px" }}></div>
                        ) : (
                            <TableBody>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.StateID}>
                                            <TableCell sx={styles.tableCell}>{number + index}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.StateName}</TableCell>
                                            <TableCell sx={styles.tableCell}></TableCell>
                                            <TableCell sx={styles.tableCell}></TableCell>
                                            <TableCell sx={styles.tableCell}>{item.IsActive ? "Yes" : "No"}</TableCell>
                                            <TableCell sx={styles.tableCell}></TableCell>
                                            {
                                                role === "acctree" && (
                                                    <TableCell sx={styles.tableCell}>
                                                        <Tooltip title="Edit" placement="left">
                                                            <IconButton>
                                                                <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" placement="right">
                                                            <IconButton>
                                                                <DeleteIcon style={{ color: '#962a38' }}
                                                                    onClick={() => {
                                                                        setShowDeleteConfirmation(true);
                                                                        setDeleteItemId(item.StateID);
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </div>
    );
}