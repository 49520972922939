import "./widget.scss";
import React from 'react';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import axios from "axios";
import { useState, useEffect } from "react";
import { ApiLink } from "../../../APILink/ApiLink";
import jwtDecode from "jwt-decode";
import authHeader from "../../../Auth/authHeader";
import { Link } from "react-router-dom";
import { errorAlert } from "../../../Components/Alerts";


const Widget = ({ type }) => {
  let data;

  //temporary
  const amount = 145;
  const diff = 35;

  const [salesTotal, setSalesTotal] = useState();
  const [purchaseTotal, setPurchaseTotal] = useState();
  const [investmentTotal, setInvestmentTotal] = useState();
  const [expenceTotal, setExpenceTotal] = useState();

  const headers = authHeader();

  const [logedEmail, setLogedEmail] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setLogedEmail(decodedToken.email);
    }
  }, []);

  const fetchSalesTotal = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + 'Dashboard/totalValues', { headers });
      setSalesTotal(response.data[0].TotalSales); // Update this line
      setPurchaseTotal(response.data[0].PurchaseOrders); // Update this line
      setInvestmentTotal(response.data[0].TotalInvestments); // Update this line
      setExpenceTotal(response.data[0].TotalExpenses); // Update this line

    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  useEffect(() => {
    fetchSalesTotal();
  }, [])


  switch (type) {
    case "user":
      data = {
        title: "Shitjet",
        // title: "Sales",
        isMoney: true,
        link: "Lista Shitjeve",
        // link: "View Sales",
        to: "/Sales/salesintotal",
        // value: salesTotal,
        value: ((salesTotal !== null && salesTotal !== undefined && salesTotal !== 0) ? salesTotal.toLocaleString('en-US', { minimumFractionDigits: 0 }) : '0.00'),
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              color: "green",
              backgroundColor: "rgba(0, 128, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "Blerjet",
        // title: "Purchase Orders",
        isMoney: true,
        link: "Lista Blerjeve",
        // link: "View Purchase Orders",
        to: "/purchases/purchaseintotal",
        // value: purchaseTotal,
        value: ((purchaseTotal !== null && purchaseTotal !== undefined && purchaseTotal !== 0) ? purchaseTotal.toLocaleString('en-US', { minimumFractionDigits: 0 }) : '0.00'),
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "Blerjet Investive",
        // title: "Investments",
        isMoney: true,
        link: "Lista Blerjeve Investive",
        // link: "View Investments",
        to: "/purchases/investmentpurchases",
        // value: investmentTotal,
        value: ((investmentTotal !== null && investmentTotal !== undefined && investmentTotal !== 0) ? investmentTotal.toLocaleString('en-US', { minimumFractionDigits: 0 }) : '0.00'),
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "Shpenzimet",
        // title: "Expenses",
        isMoney: true,
        link: "Lista Shpenzimeve",
        // link: "View Expenses",
        to: "/expences/expensepurchases",
        // value: expenceTotal,
        value: ((expenceTotal !== null && expenceTotal !== undefined && expenceTotal !== 0) ? expenceTotal.toLocaleString('en-US', { minimumFractionDigits: 0 }) : '0.00'),
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "€ "}
          {data.value}
          {/* {data.value !== null && data.value !== undefined ? data.value.toFixed(0) : 0} */}
        </span>
        <Link to={data.to} style={{ textDecoration: "none", color: "darkgray" }}>
          <span className="link">{data.link}</span>
        </Link>
      </div>
      <div className="right">
        <div className="percentage positive">
          {/* <KeyboardArrowUpIcon />
          {diff} % */}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
