import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiOutlinePrinter, AiOutlineFilePdf } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
import { SiHyperledger } from "react-icons/si";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import jwtDecode from "jwt-decode";
import { MonthlyExpenseClassReportHeader } from "./GridHeaders/MonthlyExpenseClassReportHeader";
import DataGridComponent from "../../Components/DataGridComponent";
import { errorAlert } from "../../Components/Alerts";


export default function MonthlyExpenseClassReport() {
    const [data, setData] = useState([]);
    const headers = authHeader();

    const [searchTerm, setSearchTerm] = useState("");
    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");
    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    //Kodi per shfaqjen e tabeles ne PDF

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            Business Number:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Muaji", "Klasifikimi i Shpenzimit", "Vlera 0%", "Vlera 8%", "Vlera 18%", "Total pa TVSH"]],
            body: data.map((item, index) => [
                ++index,
                item.KlasifikimiShpenzimit,
                item.Transaksione,
                (item.Vlera0).toFixed(2),
                (item.Vlera8).toFixed(2),
                (item.Vlera18).toFixed(2),
                (item.TotalPaTVSH).toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "RaportiMujorShitjeve", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, data, lineY);

        doc.save("RaportiKlasifikimitTeShpenzimeve.pdf");
    };

    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    // Kodi per Marrjen e informatave nga databaza

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'FinanceStatement/MonthlyExpenseClassReport', { headers });
            setData(response.data);
            setLoading(false);
            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    const filteredData = data.filter((item) => {
        // Verifikoni nëse Vlera8 është null ose string para se të përdorni includes()
        if (item.Vlera8 && typeof item.Vlera8 === 'string') {
            return item.Vlera8.includes(searchTerm);
        }
        return false; // Kthimi false në rast se Vlera8 është null ose nuk është string
    });
    
    const rows = data.map((item, index) => ({
        id: ++index,
        accname: item.KlasifikimiShpenzimit,
        trs: (item.Transaksione),
        amount0: (item.Vlera0),
        amount8: (item.Vlera8),
        amount18: (item.Vlera18),
        totalamount: (item.TotalPaTVSH),
    }));


    useEffect(() => {
        fetchData();
        fetchCompaniesData();
    }, []);

    return (
        <div className="Crud__Container">

            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>Raporti i klasifikimit të shpenzimeve</h3>
                </div>
            </div>
            <div className="first_paragraph">
                <p>Raporti mujor i klasifikimit të shpenzimeve është raport sintetik që bën klasifikimin e shpenzimeve në secilen klasë të vitit fiskal.</p>
            </div>
            <div className="bordere">
                <div className="butonss">
                    <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    {/* <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}
                </div>
            </div>
            <hr></hr>
            <DataGridComponent columns={MonthlyExpenseClassReportHeader} rows={rows} />
        </div>
    )
}