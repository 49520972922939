// CustomDataGrid.js
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { GridToolbar, } from '@mui/x-data-grid-premium';

// function CustomToolbar() {
//     return (
//         <GridToolbarContainer>
//             <GridToolbarColumnsButton />
//             <GridToolbarFilterButton />
//             <GridToolbarDensitySelector
//                 slotProps={{ tooltip: { title: 'Change density' } }}
//             />
//             <Box sx={{ flexGrow: 1 }} />
//             {/* <button>Hello</button> */}
//             <GridToolbarExport
//                 slotProps={{
//                     tooltip: { title: 'Export data' },
//                     button: { variant: 'outlined' },
//                 }}
//             />
//         </GridToolbarContainer>
//     );
// }

function DataGridComponent({ columns, rows }) {
    return (
        <>
            <Box
                sx={{
                    height: 500,
                    width: '100%',
                    border: "solid lightgray 1px",
                    '& .super-app-theme--header': {
                        backgroundColor: '#466F86', color: "white"
                    },
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={15}
                    // disableRowSelectionOnClick
                    getRowClassName={(params) => params.row.className}
                    initialState={{
                        density: 'compact'
                      }}
                    slots={{
                        toolbar: GridToolbar, // Përdorimi i CustomToolbar në vend të GridToolbar
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
                <style jsx>{`
                            .zero-balance-row {
                                background-color: lightgray;
                            }
                            .non-zero-balance-row {
                                /* Stili për rreshtat kur 'balance' nuk është 0 */
                            }
                `}</style>
            </Box>

        </>

    );
}

export default DataGridComponent;
