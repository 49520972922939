import ExcelJS from 'exceljs';

export const TaxSalesExcelFormNoVAT = (filteredData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Shtoni titullin e kolonave në rreshtin e parë
    worksheet.mergeCells('A1', 'A3');
    const cellA1A2 = worksheet.getCell('A1');

    cellA1A2.value = 'NR.';
    cellA1A2.width = 50; // Përcakton width në 100 piksela
    cellA1A2.height = 125; // Përcakton height në 50 piksela
    cellA1A2.border = {
        top: { style: 'thin', color: { argb: '00000000' }, bold: true },
        left: { style: 'thin', color: { argb: '00000000' }, bold: true },
        bottom: { style: 'thin', color: { argb: '00000000' }, bold: true },
        right: { style: 'thin', color: { argb: '00000000' }, bold: true }
    };
    cellA1A2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    cellA1A2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }


    worksheet.mergeCells('B1', 'C1');
    const cellC1 = worksheet.getCell('B1');
    cellC1.value = 'Fatura';
    cellC1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };
    cellC1.alignment = { vertical: 'middle', horizontal: 'center' };
    cellC1.width = 170; // Përcakton width në 100 piksela
    cellC1.height = 27; // Përcakton height në 50 piksela
    cellC1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    worksheet.mergeCells('B2', 'B3');
    const data = worksheet.getCell('B2');
    data.value = 'Data';
    data.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    data.alignment = { vertical: 'middle', horizontal: 'center' };
    data.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    worksheet.mergeCells('C2', 'C3');
    const nrFatures = worksheet.getCell('C2');
    nrFatures.value = 'Numri Faturës';
    nrFatures.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    nrFatures.alignment = { vertical: 'middle', horizontal: 'center' };
    nrFatures.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    worksheet.mergeCells('D1', 'E1');
    const bleresi = worksheet.getCell('D1');
    bleresi.value = 'Blerësi';
    bleresi.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    bleresi.alignment = { vertical: 'middle', horizontal: 'center' };
    bleresi.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    worksheet.mergeCells('D2', 'D3');
    const emriBleresit = worksheet.getCell('D2');
    emriBleresit.value = 'Emri i blerësit';
    emriBleresit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    emriBleresit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    emriBleresit.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    worksheet.mergeCells('E2', 'E3');
    const NRFBlersit = worksheet.getCell('E2');
    NRFBlersit.value = 'Numri Unik Identifikues / Numri Fiskal i blerësit';
    NRFBlersit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    NRFBlersit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    NRFBlersit.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    worksheet.mergeCells('F1', 'F2');
    const f1f2 = worksheet.getCell('F1');
    f1f2.value = 'Shitje e mallrave / shërbimeve brenda vendit';
    f1f2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    f1f2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    f1f2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }


    const cellF3 = worksheet.getCell('F3');
    cellF3.value = 'a';
    cellF3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellF3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    cellF3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A9D08E' }
    };

    worksheet.mergeCells('G1', 'G2');
    const g1g2 = worksheet.getCell('G1');
    g1g2.value = 'Shitje e shërbimeve jashtë vendit';
    g1g2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    g1g2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    g1g2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    const g3 = worksheet.getCell('G3');
    g3.value = 'b';
    g3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    g3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    g3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A9D08E' }
    };

    worksheet.mergeCells('H1', 'H2');
    const nr9 = worksheet.getCell('H1');
    nr9.value = 'Eksportet';
    nr9.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    nr9.alignment = { vertical: 'middle', horizontal: 'center' };
    nr9.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    };
    nr9.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    // H2 dhe H3
    const h3 = worksheet.getCell('H3');
    h3.value = 'c';
    h3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    h3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    h3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A9D08E' }
    };

    worksheet.mergeCells('I1', 'I2');
    const i1i2 = worksheet.getCell('I1');
    i1i2.value = 'Shitjet totale';
    i1i2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    i1i2.alignment = { vertical: 'middle', horizontal: 'center' };
    i1i2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' }
    }

    // I2 dhe I3
    const i3 = worksheet.getCell('I3');
    i3.value = 'd=a+b+c';
    i3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    i3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    i3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A9D08E' }
    };

    // Përdorni map për të shtuar rreshtat e të dhënave
    filteredData.map((item, index) => {
        const row = [];
        const total = (parseFloat(item.ShitjeVendore) || 0) + (parseFloat(item.EksporteSherbimeve) || 0) + (parseFloat(item.EksporteProdukteve) || 0);

        // Shtoni vlerat për secilën kolonë bazuar në të dhënat e furnizuara
        row.push(item.Nr);
        row.push(item.Date || '');
        // row.push(item.InvoiceNr || '');
        row.push(item.ActionType === 'IND' ? `IND-2024-${item.InvoiceNr}` : item.InvoiceNr || '')
        row.push(item.AccountName || '');
        row.push(item.IdentificationNumber || '');
        row.push(item.ShitjeVendore || '');
        row.push(item.EksporteSherbimeve || '');
        row.push(item.EksporteProdukteve || '');
        row.push(total.toString());


        // Shtoni rreshtin në sheet
        worksheet.addRow(row);
    });

    // Kthej workbook-in për përdorim në komponentin tjetër
    return workbook;
};
