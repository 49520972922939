import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import '../../Style/__button.scss'
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';

function InsertUsers(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const headers = authHeader();
        const stateData = {
            Email: email,
            Password: password,
        };
        // Validoni fushat e hyrjes dhe azhurnoni gjendjen e gabimit në qoftë se ka gabime
        if (!email.includes("@")) {
            setEmailError("Emaili duhet të jetë një adresë e saktë emaili.");
        } else {
            setEmailError("");
        }

        if (password.length < 8) {
            setPasswordError("Fjalëkalimi duhet të jetë të paktën 8 karaktere.");
        } else {
            setPasswordError("");
        }

        // Nëse nuk ka gabime, përdorni axios për të dërguar kërkesën post në server
        if (emailError === "" && passwordError === "") {
            axios.post(ApiLink.API_URL + 'AuthProva/create', stateData, { headers })
                .then((response) => {
                    console.log(response.data);
                    handleSuccess("User Added Successfully");
                    setEmail("");
                    setPassword("");
                    props.onHide();
                    props.refreshData();
                })
                .catch((error) => {
                    errorAlert(error);
                });
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Krijo Përdorues
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-3 col-sm-3">Email </span>
                            <input
                                type="text"
                                name='Email'
                                className="form-control"
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                required
                            />
                            <div className="error">{emailError}</div>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text col-3 col-sm-3">Fjalkalimi </span>
                            <input
                                type="password"
                                name='Password'
                                className="form-control"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                required
                            />
                            <div className="error">{passwordError}</div>
                        </div>
                        <button type="submit"
                            className="btn btn-primary float-end"
                            style={{ background: "#7a95a6" }}
                        >Save</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };

    return (
        <>
            <button className="button-68" onClick={() => setModalShow(true)}>
            <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} /> Krijo Përdorues
            </button>

            <InsertUsers
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}

