const currencyFormatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const TrialBalanceGridHeader = [
    { field: 'id', headerName: '#', width: 0, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true },
    // { field: 'ledgerID', headerName: 'ID e llogarisë', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'ledgerName', headerName: 'Emri i llogarisë', width: 420, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'debit', headerName: 'Debi', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'credit', headerName: 'Kredi', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'balance', headerName: 'Balanci', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    { field: 'bilanceSide', headerName: 'Bilanci Kontabel', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'financialStatement', headerName: 'Pasqyrimi financiar', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },

]

