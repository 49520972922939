import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiOutlineFilePdf, AiOutlineClose } from "react-icons/ai";
import { SiHyperledger } from "react-icons/si";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { errorAlert } from "../../Components/Alerts";
import DataGridShowDetails from "../../Components/DataGridShowDetails";
import DataGridComponent from "../../Components/DataGridComponent";
import { AccountsPayableDataGrid, AccountsPayableDetails } from "./AccountingReports_DataGrid/AccountsPayableDataGrid";
import { currencyFormatter } from "../../Components/CurrencyFormat";

export default function BanksReport() {
    const [data, setData] = useState([]);
    const [AccountsDetails, setAccountsDetails] = useState([]);

    const [showallAccounts, setShowAllAccounts] = useState(true);
    const [showDetailAccounts, setShowDetailsAccounts] = useState(false);

    const headers = authHeader();
    const [searchTerm, setSearchTerm] = useState("");

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");
    let number = 1;

    const showDetails = () => {
        setShowAllAccounts(false);
        setShowDetailsAccounts(true);
    }
    const showAllAccounts = () => {
        setShowAllAccounts(true);
        setShowDetailsAccounts(false);
        setAccountsDetails([]);
    }

    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            Business Number:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };

    const addTable = (doc, data, lineY) => {
        // Llogaritja e totaleve dhe përgatitja e të dhënave
        let totalDebit = 0;
        let totalCredit = 0;
        let totalBalance = 0;

        const bodyData = filteredData.map((item, index) => {
            totalDebit += item.Debit;
            totalCredit += item.Credit;
            totalBalance = totalDebit - totalCredit;

            return [
                item.Sequence_no,
                item.AccountName,
                item.Address,
                item.IdentificationNumber,
                currencyFormatter(item.Debit),
                currencyFormatter(item.Credit),
                currencyFormatter(item.Balance)
            ];
        });

        // Shtoni rreshtin e totalit në fund të tabelës
        bodyData.push([
            '', '', 'Totali:', '', currencyFormatter(totalDebit), currencyFormatter(totalCredit), currencyFormatter(totalBalance)
        ]);

        doc.autoTable({
            head: [["#", "Emri i Bankës", "Adresa", "Numri Identifikues", "Debi", "Kredi", "Bilanci"]],
            body: bodyData,
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "Paraja në Banka", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("Paraja_Banka.pdf");
    };

    const addSecondTable = (doc, data, lineY) => {
        // Llogaritja e totaleve dhe përgatitja e të dhënave
        let totalDebit = 0;
        let totalCredit = 0;
        let totalProgressive = 0;

        const bodyData = filteredDetails.map((item, index) => {
            totalDebit += item.Debit;
            totalCredit += item.Credit;
            totalProgressive = totalDebit - totalCredit;

            return [
                (number + index),
                item.ActionType,
                item.AccountName,
                item.IdentificationNumber,
                item.Comment,
                formatDate(item.Date),
                currencyFormatter(item.Debit),
                currencyFormatter(item.Credit),
                currencyFormatter(item.Progressive)
            ];
        });

        // Shtoni rreshtin e totalit në fund të tabelës
        bodyData.push([
            '', '', '', '', 'Totali:', '', currencyFormatter(totalDebit), currencyFormatter(totalCredit), currencyFormatter(totalProgressive)
        ]);

        doc.autoTable({
            head: [["#", "Tipi Veprimit", "Emri Bankës", "Numri Identifikues", "Komenti", "Data", "Debit", "Kredit", "Progresive"]],
            body: bodyData,
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Identification Number"
            },
            didDrawCell: (data) => {
                if (data.row.index === bodyData.length - 1) { // Kontrolloni nëse është rreshti i fundit (totali)
                    // Cakto ngjyrën e sfondit dhe të tekstit për rreshtin e totalit
                    doc.setFillColor(220, 220, 220); // Ngjyra e sfondit në hiri
                    doc.setTextColor(0, 0, 0); // Ngjyra e tekstit në të bardhë
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Vizato një drejtkëndësh me këtë ngjyrë

                    // Vizato tekstin manualisht
                    if (data.cell.text) {
                        doc.text(data.cell.text, data.cell.x + 2, data.cell.y + data.cell.height - 2);
                    }
                }
            }
        });
    };

    const handleExportSecondPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "Llogaria Bankare në Detale", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addSecondTable(doc, filteredDetails, lineY);

        doc.save("Llogaria_Bankare_Detale.pdf");
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Reports/accounts', { headers, params: { ledger: 102 } });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchAccountsDetails = async (accountID) => {
        showDetails();
        try {
            const response = await axios.get(ApiLink.API_URL + 'Reports/AccountsDetails', { headers, params: { accountID: accountID } });
            setAccountsDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const filteredData = data.filter((item) => {
        return (
            item.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const filteredDetails = AccountsDetails.filter((item) => {
        return (
            item.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const rows = data.map((item, index) => ({
        id: ++index,
        // accID: item.AccountID,
        accName: item.AccountName,
        add: item.Address,
        idNumber: item.IdentificationNumber,
        debit: item.Debit,
        credit: item.Credit,
        balance: item.Balance,
        showDetails: item.AccountID,
        rowInfo: item
    }));

    const detailsRows = AccountsDetails.map((item, index) => ({
        id: ++index,
        type: item.ActionType,
        name: item.AccountName,
        idNumber: item.IdentificationNumber,
        comment: item.Comment,
        date: item.Date,
        debit: item.Debit,
        credit: item.Credit,
        progressive: item.Progressive,

    }));

    useEffect(() => {
        fetchData();
        fetchCompaniesData();
    }, []);

    return (
        <div className="Crud__Container">
            {showallAccounts && (
                <>
                    <div className="header_flex">
                        <div className="header_icon">
                            <SiHyperledger style={{}} className="header_icons" />
                            <h3>Paraja në Banka</h3>
                        </div>
                    </div>
                    <div className="first_paragraph">
                        <p>Ky format i karteles është i përshtatur për shtypje dhe përdorim në dokumentacionin fizik.</p>
                    </div>


                    <div className="bordere">
                        <div className="butonss">
                            <div>
                                <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                            </div>
                            {/* <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}
                        </div>

                    </div>
                    <DataGridShowDetails
                        columns={AccountsPayableDataGrid}
                        rows={rows}
                        fetchAccountsDetails={fetchAccountsDetails}
                        height={500}
                    />
                </>
            )}
            {showDetailAccounts && (
                <>
                    <div className="header_flex">
                        <div className="header_icon">
                            <SiHyperledger style={{}} className="header_icons" />
                            <h3>Llogaria Bankare në Detale</h3>
                        </div>
                    </div>
                    <div className="bordere">
                        <div className="butonss">
                            <div>
                                <button className="button-15" onClick={handleExportSecondPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                            </div>
                            <div className="butons15">
                                <button className="button-15" variant="contained" onClick={showAllAccounts}><AiOutlineClose /> Mbyll</button>
                            </div>
                        </div>
                    </div>
                    <DataGridComponent columns={AccountsPayableDetails} rows={detailsRows} />
                </>
            )}
        </div>
    )
}