export const Documents = [
    {
        id: 1,
        name: "Dosja e Kompanisë"
    },
    {
        id: 2,
        name: "Dosja e Përsonelit"
    },
    {
        id: 3,
        name: "Kontratat dhe Marveshjet"
    },
    {
        id: 4,
        name: "Lejet dhe Licencat"
    },
    {
        id: 5,
        name: "Raportet e Kontabilistit"
    },
    {
        id: 6,
        name: "Raportet e Menaxhmentit"
    },
    {
        id: 7,
        name: "Pasqyrat Financiare dhe Shpalosjet"
    },
    {
        id: 8,
        name: "Bilanci Vertetues"
    },
    {
        id: 9,
        name: "Politikat Kontabel"
    },
    {
        id: 10,
        name: "Deklaratat Tatimore [Tremujorët]"
    },
    {
        id: 11,
        name: "Deklaratat e TVSH-së dhe Konfirmimet"
    },
    {
        id: 12,
        name: "Deklaratat e Punesuarve [CM-WM]"
    },
    {
        id: 13,
        name: "Deklaratat e Qirasë [WR]"
    },
    {
        id: 14,
        name: "Bilanci Tatimor"
    },
    {
        id: 15,
        name: "Raportet e Bankës"
    },
    {
        id: 16,
        name: "Raportet e Arkës"
    },
    {
        id: 17,
        name: "Gjendja e Stokut"
    },
    {
        id: 18,
        name: "Malli në Konsignacion"
    },
    {
        id: 19,
        name: "Llogaritë e Furnitoreve"
    },
    {
        id: 20,
        name: "Llogaritë e Klienteve"
    },
    {
        id: 21,
        name: "Librat e Shitjes"
    },
    {
        id: 22,
        name: "Librat e Blerjes"
    },
    {
        id: 23,
        name: "Librat e Shpenzimeve"
    },
    {
        id: 24,
        name: "Librat e Blerjeve Investive"
    },
    {
        id: 25,
        name: "Faturat e Shitjes"
    },
    {
        id: 26,
        name: "Z-Raportet"
    },
    {
        id: 27,
        name: "Faturat e Blerjes"
    },
    {
        id: 28,
        name: "Faturat e Shpenzimeve"
    },
    {
        id: 29,
        name: "Faturat e Blerjeve Investive"
    },
    {
        id: 30,
        name: "Notat Debitore"
    },
    {
        id: 31,
        name: "Notat Kreditore"
    },
    {
        id: 32,
        name: "Transaksionet e Arkës"
    },
    {
        id: 33,
        name: "Transaksionet e Bankës"
    },
    {
        id: 34,
        name: "Fletëprodhimet"
    },
    {
        id: 35,
        name: "Dokumentet e paregjistruara [arritjet pas deklarimit]"
    },
    {
        id: 36,
        name: "Dokumentet Tjera"
    },
]

export const Periudha = [
    {
        id: 1,
        name: "/ 01-Janar"
    },
    {
        id: 2,
        name: "/ 02-Shkurt"
    },
    {
        id: 3,
        name: "/ 03-Mars"
    },
    {
        id: 4,
        name: "/ 04-Prill"
    },
    {
        id: 5,
        name: "/ 05-Maj"
    },
    {
        id: 6,
        name: "/ 06-Qershor"
    },
    {
        id: 7,
        name: "/ 07-Korrik"
    },
    {
        id: 8,
        name: "/ 08-Gusht"
    },
    {
        id: 9,
        name: "/ 09-Shtator"
    },
    {
        id: 10,
        name: "/ 10-Tetor"
    },
    {
        id: 11,
        name: "/ 11-Nentor"
    },
    {
        id: 12,
        name: "/ 12-Dhjetor"
    },
    {
        id: 13,
        name: "/ TM1-Tremujori i Parë"
    },
    {
        id: 14,
        name: "/ TM2-Tremujori i Dytë"
    },
    {
        id: 15,
        name: "/ TM3-Tremujori i Tretë"
    },
    {
        id: 16,
        name: "/ TM4-Tremujori i Katërt"
    },
    {
        id: 17,
        name: "/ TM1 - TM2"
    },
    {
        id: 18,
        name: "/ TM3 - TM4"
    },
    {
        id: 19,
        name: "/ Viti Fiskal"
    },
    {
        id: 20,
        name: "/ Janar-Dhjetor"
    },
]