import React, { useState, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/InsertRent.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import { Month } from "../../OtherData/Month";
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
import InsertEmployees from "../Employees/InsertEmployees"
import Autocomplete from "@mui/material/Autocomplete";


function AddWages(props) {
    const TranscationData = [
        {
            id: 4,
            type: "Rezident"
        },
        {
            id: 5,
            type: "Jo Rezident"
        },
    ]

    const LlojiIPagës = [
        {
            id: 1,
            type: "Primare"
        },
        {
            id: 2,
            type: "Sekondare"
        },
    ]

    const headers = authHeader();
    const email = LoginUserDetailed();

    const [date, setDate] = useState('');
    const [payDate, setPayDate] = useState('');
    const [invoiceNR, setInvoiceNR] = useState("");
    const [employeeID, setEmployeeID] = useState("");
    // const [amount0, setAmount0] = useState(0);
    // const [amount8, setAmount8] = useState(0);
    const [pagaBruto, setPagaBruto] = useState(0);
    const [coment, setComent] = useState("");
    const [paymentOptions, setPaymentOptions] = useState("");
    const [transactionType, setTransactionType] = useState(4);
    const [llojipages, setLlojiPages] = useState(1);

    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);

    const [cashRegister, setCashRegister] = useState([]);

    const [pagaNeto, setPagaNeto] = useState(0);
    const [kontributiPunedhenesit, setKontributiPunedhenesit] = useState(0);
    const [kontributiPunetorit, setKontributiPunetorit] = useState(0);
    const [tatimiNePage, setTatimiNePage] = useState(0);

    const [brutoField, setBrutoField] = useState(false);
    const [netoField, setNetoField] = useState(false);

    const vitAktual = new Date().getFullYear();


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Please choose one month!");
            return;
        }
        if (!date) {
            errorAlert("Please select date!");
            return;
        }
        if (!payDate) {
            errorAlert("Pay date is required!");
            return;
        }
        if (!employeeID) {
            errorAlert("Please choose one employee!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Please choose payment option!");
            return;
        }
        // if (!amount0) {
        //     errorAlert("Please fill the Net Rent field !");
        //     return;
        // }
        const stateDate = {
            Date: date,
            PayDate: payDate,
            ActionType: "WGS",
            InvoiceNr: invoiceNR,
            // AccountID: 702,
            SupplierID: employeeID,
            // Amount0: amount0,
            // Amount8: amount8,
            Amount18: pagaBruto,
            PaymentOptions: paymentOptions,
            TransactionType: transactionType,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'Wages', stateDate, { headers, params: { llojiPages: llojipages } })
            .then(() => {
                handleSuccess("Wages Added Successfully");
                setDate("");
                setPayDate("");
                setInvoiceNR("");
                setEmployeeID("");
                setPagaBruto(0);
                setPagaNeto(0);
                setKontributiPunedhenesit(0);
                setKontributiPunetorit(0);
                setTatimiNePage(0);
                setPaymentOptions("");
                setTransactionType(4);
                setEmployeeName("");
                setComent("");
                setLlojiPages(1);
                setfield(null);

            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 310 } });
            setEmployees(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredEmployees(
            employees.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, employees]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [employeeName, setEmployeeName] = useState("");
    const handleFilterTenants = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setEmployeeID(selectedOption.AccountID);
            setEmployeeName(selectedOption.label);
        } else {
            setEmployeeID("");
            setEmployeeName("");
        }
    };

    const calculateFromBruto = async (llojiPagesParameter) => {
        try {
            const DataKrahasuese = "2024-08-01";

            const kontributiPunedhenes = Number(pagaBruto * 5) / 100;
            setKontributiPunedhenesit(kontributiPunedhenes);

            const kontributiPunetor = Number(pagaBruto * 5) / 100;
            setKontributiPunetorit(kontributiPunetor);

            let pagaPaKontribut = Number(pagaBruto) - kontributiPunedhenes;

            let Koeficienti1 = 0;
            let Koeficienti2 = 0;
            let Koeficienti3 = 0;

            if (llojiPagesParameter === 1) {
                if (pagaPaKontribut <= 80) {
                    Koeficienti1 = 0;
                    Koeficienti2 = 0;
                    Koeficienti3 = 0;
                } else if (pagaPaKontribut <= 250 && pagaPaKontribut > 80) {
                    if (date < DataKrahasuese) {
                        Koeficienti1 = (pagaPaKontribut - 80) * 4 / 100;
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                } else if (pagaPaKontribut <= 450 && pagaPaKontribut > 250) {
                    if (date < DataKrahasuese) {
                        Koeficienti1 = 6.8;
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                    Koeficienti2 = (pagaPaKontribut - 250) * 8 / 100;
                } else {
                    if (date < DataKrahasuese) {
                        Koeficienti1 = 6.8;
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                    Koeficienti2 = 16;
                    Koeficienti3 = (pagaPaKontribut - 450) * 10 / 100;
                }
            } else if (llojiPagesParameter === 2) {
                Koeficienti3 = pagaPaKontribut * 10 / 100;
            }

            const tatimi = Koeficienti1 + Koeficienti2 + Koeficienti3;
            setTatimiNePage(tatimi);
            setPagaNeto(Number(pagaBruto) - kontributiPunedhenes - tatimi);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };


    const calculateFromNeto = (llojiPagesParameter) => {
        const DataKrahasuese = "2024-08-01";

        let Deri80 = 80;
        let Deri250 = 0;
        let Deri450 = 0;
        let Mbi450 = 0;
        let Koeficienti1 = 0;
        let Koeficienti2 = 0;
        let Koeficienti3 = 0;
        let TatimiPagaNeto = 0;

        if (llojiPagesParameter === 1) {
            if (pagaNeto <= 80) {
                Deri80 = pagaNeto;
            } else {
                Deri80 = 80;
                if (((Number(pagaNeto) - Number(Deri80)) / Number(0.96)) <= 170) {
                    Deri250 = ((Number(pagaNeto) - Number(Deri80)) / Number(0.96));
                    if (date < DataKrahasuese) {
                        Koeficienti1 = (Number(Deri250) * Number(0.04));
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                } else {
                    Deri250 = 170;
                    if (date < DataKrahasuese) {
                        Koeficienti1 = (Number(Deri250) * Number(0.04));
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                    if (((Number(pagaNeto) + Number(Koeficienti1) - Number(250)) / Number(0.92)) <= 200) {
                        Deri450 = (Number(pagaNeto) + Number(Koeficienti1) - Number(250)) / Number(0.92);
                        Koeficienti2 = (Number(Deri450) * Number(0.08));
                    } else {
                        Deri450 = 200;
                        Koeficienti2 = (Number(Deri450) * Number(0.08));
                        if (Deri450 === 200) {
                            Mbi450 = (Number(pagaNeto) + Number(Koeficienti1) + Number(Koeficienti2) - Number(450)) / Number(0.9);
                            Koeficienti3 = (Number(Mbi450) * Number(0.1));
                        } else {
                            Mbi450 = 0;
                        }
                    }
                }
            }
            setTatimiNePage((Number(Koeficienti1) + Number(Koeficienti2) + Number(Koeficienti3)));
            TatimiPagaNeto = (Number(Koeficienti1) + Number(Koeficienti2) + Number(Koeficienti3) + Number(pagaNeto));

            let bruto = (Number(TatimiPagaNeto) / Number(0.95));
            setPagaBruto(bruto);
            let kontributet = ((Number(bruto) - Number(TatimiPagaNeto)) * Number(2))

            setKontributiPunedhenesit(Number(kontributet) / 2);
            setKontributiPunetorit(Number(kontributet) / Number(2));
        }

        if (llojiPagesParameter === 2) {
            Mbi450 = pagaNeto / 0.9;
            Koeficienti3 = (Number(Mbi450) * Number(0.1));
            setTatimiNePage(Koeficienti3);
            TatimiPagaNeto = (Number(Koeficienti3) + Number(pagaNeto));

            let bruto = (Number(TatimiPagaNeto) / Number(0.95));
            setPagaBruto(bruto);

            let kontributet = ((Number(bruto) - Number(TatimiPagaNeto)) * Number(2))
            setKontributiPunedhenesit(Number(kontributet) / 2);
            setKontributiPunetorit(Number(kontributet) / Number(2));
        }
    };

    // Funksioni për të gjetur datën e fundit të një muaji për një vit të caktuar
    // const getLastDayOfMonth = (month, year) => {
    //     return new Date(year, month, 0).getDate();
    // };

    //Pjesa per select input field suppliers
    const [field, setfield] = useState(null);
    const handleAutocompleteChange = (event, value) => {
        if (value && value.AccountID) {
            setEmployeeID(value.AccountID);
            setfield(value); // Përditëso `selectedArticle` me vlerën e zgjedhur


        } else {
            // Në rast se vlera është pastruar
            setEmployeeID(""); // Vendos vlerën e ArticleID në null
            setfield(null);  // Pastro `Autocomplete`

        }
    };

    const defaultProps = {
        options: employees,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                    {/* <button
                          onClick={(e) => {
                              e.stopPropagation();
                              // handleButtonClick(option);
                          }}
                          onMouseEnter={handleOpen}
                          onMouseLeave={handleClose}
                          style={{ marginLeft: 'auto' }}
                      >
                          Click
                      </button> */}
                </li>
            </>
        ),
    };
    //////

    const getLastDateOfMonth = (year, month) => {
        // Muaji është 0-indexed në JavaScript
        const lastDay = new Date(year, month, 0).getDate();
        
        // Sigurohemi që muaji dhe dita janë të dy 2-shifror
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = lastDay < 10 ? `0${lastDay}` : lastDay;
        // Formatojmë datën në 'yyyy-MM-dd'
        const newDate = `${year}-${formattedMonth}-${formattedDay}`;
                
        setDate(newDate);
        setPayDate(newDate);
      };
      
    useEffect(() => {
        if (invoiceNR) {
          const dashIndex = invoiceNR.indexOf('-');
          const selectedMonth = parseInt(invoiceNR.substring(dashIndex + 1, dashIndex + 3));
          getLastDateOfMonth(vitAktual, selectedMonth);
        }
      }, [invoiceNR, vitAktual]);

    useEffect(() => {
        // fetchrent();
        fetchEmployees();
        fetchPaymentType();
    }, [])

    return (
        <div className="Rent__Container">
            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Pagë e Re</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha te dhenat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className="Rent__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <InsertEmployees fetchData={fetchEmployees} type="insert" />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                        >
                            <button className="button-15"
                                onClick={handleSubmit}
                            >
                                Ruaj & Ri
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to={"/expences/wages"}
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbyll
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2'></div>
            <form className='Rent__Container__Format'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField
                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                    // style={{ width: "594px" }}                                    id="invoiceNR"
                                    select
                                    label="Muaji"
                                    defaultValue=""
                                    value={invoiceNR}
                                    onChange={event => {
                                        // const dashIndex = event.target.value.indexOf('-');
                                        // const selectedMonth = event.target.value.substring(dashIndex + 1, dashIndex + 3);
                                        setInvoiceNR(event.target.value);
                                        // getLastDateOfMonth(vitAktual, selectedMonth);
                                    }}
                                    required
                                >
                                    {Month.map((data) => (
                                        <MenuItem key={data.id} value={vitAktual + data.month}>{vitAktual}{data.month}
                                        </MenuItem>
                                    ))}
                                </TextField>


                                <div className='AddPurchaseTotal__Container__Format__Dates'>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="date"
                                        type="date"
                                        variant="outlined"
                                        name='Date'
                                        label="Data deklarimit"
                                        value={date}
                                        onChange={(e) => { setDate(e.target.value); setPayDate(e.target.value) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="PayDate"
                                        type="date"
                                        variant="outlined"
                                        name='PayDate'
                                        label="Data pagesës"
                                        value={payDate}
                                        onChange={(e) => setPayDate(e.target.value)}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="textfield_button">
                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        {/* <span style={{ color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh të punësuarin</span>
                                        <Select
                                            className="AddPurchaseTotal__Container__Format__SelectList"
                                            inputId="SupplierID"
                                            value={{ label: employeeName, value: employeeID }}
                                            options={filteredEmployees.map((data) => ({
                                                label: data.AccountName,
                                                value: data.AccountID,
                                                AccountID: data.AccountID
                                            }))}
                                            onInputChange={handleInputChange}
                                            onChange={handleFilterTenants}
                                            isClearable
                                            placeholder="Select"
                                            noOptionsMessage={() => 'No matching'}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    height: "55px",
                                                    marginLeft: "10px", marginRight: "10px"

                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "#fff",
                                                    zIndex: 9999,
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                    borderRadius: "4px",
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    maxHeight: "200px",
                                                    overflowY: "auto",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#000",
                                                }),
                                            }}
                                        /> */}
                                        <Autocomplete
                                            fullWidth
                                            {...defaultProps}
                                            id="include-input-in-list"
                                            includeInputInList
                                            value={field}  // Lidheni me state-in `selectedArticle`
                                            onChange={handleAutocompleteChange}
                                            isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                            renderInput={(params) => (
                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__SameInput"

                                                    {...params}
                                                    label="Përzgjedh të punësuarin"
                                                // variant="filled"
                                                //   onKeyPress={handleKeyPress}
                                                />
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="AddPurchaseTotal__Container__Format__Dates" style={{ paddingTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "593px" }}
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="AccountID"
                                        label="Klasa e shpenzimit"
                                        defaultValue=""
                                        value={"603-Shpenzimet e Pagave	"}
                                        // onChange={event => setAccountID(703)}
                                        disabled
                                    >
                                    </TextField>

                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="id"
                                        select
                                        label="Lloji punonjësit"
                                        defaultValue=""
                                        value={transactionType}
                                        onChange={event => setTransactionType(event.target.value)}
                                        required
                                    >
                                        {TranscationData.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <TextField
                                    // style={{ width: "593px" }}
                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                    id="PaymentType"
                                    select
                                    label="Përzgjedh tipin e pagesës"
                                    defaultValue=""
                                    // helperText="Please select expense"
                                    value={paymentOptions}
                                    onChange={event => setPaymentOptions(event.target.value)}
                                >
                                    <MenuItem key="0" value="0">Pagesa Behet më vonë
                                    </MenuItem>
                                    {cashRegister.map((data) => (
                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    // style={{ width: "495px" }}
                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                    id="Comment"
                                    label="Komenti"
                                    type="text"
                                    name='Comment'
                                    value={coment}
                                    onChange={event => setComent(event.target.value)}
                                    variant="outlined" />
                            </div>
                            <div className='box2'>
                                <div className='box3'>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="LlojiID"
                                        select
                                        label="Lloji i pagës"
                                        defaultValue=""
                                        value={llojipages}
                                        onChange={event => {
                                            setLlojiPages(event.target.value);

                                            if (netoField) {
                                                calculateFromNeto(event.target.value);
                                            } else {
                                                calculateFromBruto(event.target.value);
                                            }
                                        }}

                                        required
                                    >
                                        {LlojiIPagës.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Paga Neto"
                                        variant="outlined"
                                        name='PagaNeto'
                                        value={(pagaNeto).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        onChange={event => { setPagaNeto(event.target.value); setNetoField(true); setBrutoField(false) }}
                                        onKeyUp={event => calculateFromNeto(llojipages)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </div>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Kontributi i punëdhënësit"
                                        name='KontributiPunedhenesit'
                                        variant="outlined"
                                        value={(kontributiPunedhenesit).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        readOnly
                                        // onChange={event => setKontributiPunedhenesit(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3' style={{ paddingTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Kontributi i punëtorit"
                                        variant="outlined"
                                        name='KontributiPunetorit'
                                        value={(kontributiPunetorit).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        readOnly
                                        // onChange={event => setKontributiPunetorit(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3' style={{ paddingTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Tatimi në pagë"
                                        variant="outlined"
                                        name='TatimiNePage'
                                        value={(tatimiNePage).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        readOnly
                                        // onChange={event => setTatimiNePage(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3' style={{ paddingTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Paga Bruto"
                                        variant="outlined"
                                        name='PagaBruto'
                                        value={(pagaBruto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}
                                        // onChange={event => setPagaBruto(event.target.value)}
                                        onChange={event => { setPagaBruto(event.target.value); setNetoField(false); setBrutoField(true) }}

                                        onKeyUp={event => calculateFromBruto(llojipages)}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </Box>
                </Paper>
            </form>
            <div></div>
        </div>
    );
}

export default AddWages;