import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
import { SiHyperledger } from "react-icons/si";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import jwtDecode from "jwt-decode";
import { Month } from '../../OtherData/Month'
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { errorAlert } from "../../Components/Alerts";
import { TaxPurchaseExcelForm } from "../../Print/TaxPurchaseExcelForm";
import DataGridComponent from "../../Components/DataGridComponent";
import { TaxPurchaseExcelFormNoVAT } from "../../Print/TaxPurchaseExcelFormNoVAT";

const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export default function TaxPurchaseBook() {
    const [data, setData] = useState([]);
    const headers = authHeader();

    const [searchTerm, setSearchTerm] = useState("");

    const [logedEmail, setLogedEmail] = useState("");

    const [month, setMonth] = useState(0);
    const [ngaData, setNgaData] = useState("");
    const [deriData, setDeriData] = useState("");

    const [showMonthFilter, setShowMonthFilter] = useState(true);
    const [showDateFilter, setShowDateFilter] = useState(false);

    const openDateFilter = () => {
        setShowMonthFilter(false);
        setShowDateFilter(true);
        setMonth(0);
        setData([]);
    }
    const openMonthFilter = () => {
        setShowMonthFilter(true);
        setShowDateFilter(false);
        setNgaData("");
        setDeriData("");
        setData([]);
    }

    const allTM = [
        {
            id: 1,
            title: "TM1",
            firstDate: "01/01/2024",
            lastDate: "03/31/2024"
        },
        {
            id: 2,
            title: "TM2",
            firstDate: "04/01/2024",
            lastDate: "06/30/2024"
        },
        {
            id: 3,
            title: "TM3",
            firstDate: "07/01/2024",
            lastDate: "09/30/2024"
        },
        {
            id: 4,
            title: "TM4",
            firstDate: "10/01/2024",
            lastDate: "12/31/2024"
        }
    ]

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");
    const [vatDeclarant, setVatDeclarant] = useState(true);

    const [dataNoVAT, setDataNoVAT] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [tm, setTm] = useState("");
    const vitAktual = new Date().getFullYear();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);
    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["Nr", " Invoice Date", "InvoiceNr", " Sales Name", " Identification Number", "VATNumber", "Sale without credit", "Sales of services", "Reverse Charged Sales	", "Sales Released With Credit", "Total Sales Released With Credit", "Exports", "Sales 18", "Notes Debit18", "Bad debt18", "Adjustments 18", "Charge Against 18", "Total VAT 18", "Sales 8	", "Notes Receivable 8", "Bad Debt 8", "Adjustments 8", "Total VAT 8", "Total VAT 8 and 18"]],
            body: filteredData.map((item) => [
                item.Nr, item.DataFatures,
                // item.NumriFatures,
                item.ActionType =="IMP" ? item.ImpInvoiceNr : item.NumriFatures, 
                item.EmriShitesit,
                item.IdentificationNumber,
                item.VATNumber,
                item.Shitjet_Pa_Kreditim,
                item.Shitjet_Sherbimeve,
                item.Shitjet_Me_Ngarkese_Te_Kundert,
                item.Shitjet_Liruara_Me_Kreditim,
                item.Total_Shitjet_Liruara_Me_Kreditim,
                item.Eksportet, item.Shitjet18, item.NotaDebitore18,
                item.BorgjiKeq18, item.Rregullimet18,
                item.NgarkesaKundert18, item.TotalVAT18,
                item.Shitjet8, item.NotaDebitore8,
                item.BorgjiKeq8,
                item.Rregullimet8,
                item.TotalVAT8,
                item.Total_TVSH_8_dhe_18.toFixed(2)]),

            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
            format: "a2"
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "Tax sales Book", lineY);
        // addRightText(doc, `Month: ${vitAktual}-${month}`, lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("TaxSalesBook.pdf");
    };


    const handleDownloadExcel = () => {
        const workbook = TaxPurchaseExcelForm(data);
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            // Krijo një element për shkarkim
            const a = document.createElement('a');
            a.href = url;
            (month === 0 ?
                a.download = `LB_Periudha nga_${formatDate(ngaData)}_Deri_${formatDate(deriData)}.xlsx` :
                a.download = `LB_${month}_${vitAktual}.xlsx`
            )


            a.click();
            URL.revokeObjectURL(url);
        });
    };

    const handleDownloadExcelNoVAT = () => {
        const workbook = TaxPurchaseExcelFormNoVAT(dataNoVAT);
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            // Krijo një element për shkarkim
            const a = document.createElement('a');
            a.href = url;
            a.download = `LB_TM${tm}_${vitAktual}.xlsx`

            a.click();
            URL.revokeObjectURL(url);
        });
    };

    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    // const ledger = 101;
    // Kodi per Marrjen e informatave nga databaza
    const params = {};

    if (month && month >= 1 && month <= 12) {
        params.month = month;
    } else if (ngaData && deriData) {
        params.firstDate = ngaData;
        params.secondDate = deriData;
    }

    // const fetchData = async () => {
    //     try {
    //         const headers = authHeader();
    //         const response = await axios.get(ApiLink.API_URL + 'TaxReport/purchaseBook', { headers, params: { email: logedEmail, month } });
    //         setData(response.data);
    //         setLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    const [showDataSms, setShowDataSms] = useState("")
    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'TaxReport/purchaseBook', { headers, params });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Kthehet nga serveri për mungesë qasje
                setShowDataSms("Ju nuk keni qasje në këto të dhëna.");
            } else {
                errorAlert("Gabim në kërkesë:", error);

                if (error.response && error.response.data && error.response.data.errorMessage) {
                    errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
                } else if (error.message) {
                    errorAlert(`Gabim: ${error.message}`);
                } else {
                    errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
                }
            }
        }
    };

    const fetchdataNoVAT = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'TaxReport/purchasebooknovat', { headers, params: { firstDate: startDate, secondDate: endDate } });
            setDataNoVAT(response.data);
            console.log(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedTM = allTM.find((tm) => tm.id === selectedId);
        if (selectedTM) {
            setStartDate(selectedTM.firstDate);
            setEndDate(selectedTM.lastDate);
            // Thirr fetchdataNoVAT pasi të përditësohen datat për startDate dhe endDate
            fetchdataNoVAT();
        }

    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };


    const filteredData = data.filter((item) => {
        return (
            item.EmriShitesit.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    useEffect(() => {
        if (ngaData && ngaData !== "" && deriData && deriData !== "") {
            fetchData();
        }
    }, [ngaData, deriData]);

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
            setVatDeclarant(response.data[0].VATDeclarant);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const columns = [
        { field: 'id', headerName: '#', width: 110, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'date', headerName: 'Data Fatures', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'nr', headerName: 'Numri Faturës', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'blersi', headerName: 'Emri Shitesit', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'nui', headerName: 'Numri Identifikues', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'vat', headerName: 'Numri i TVSH-së', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        {
            field: 'BPT', headerName: 'Blerjet Pa TVSH', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },

        {
            field: 'BDIIPT', headerName: 'Blerjet dhe Imp Inv Pa TVSH', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true

        },
        {
            field: 'BIITJZ', headerName: 'Blerjet dhe Imp Inv me TVSH jo Te Zbritshme', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BIIMTJZ1', headerName: 'Blerjet dhe Imp Inv me TVSH jo Te Zbritshme 1', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'importet18', headerName: 'Importet 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'II18', headerName: 'Importet Investive 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BV18', headerName: 'Blerjet Vendore 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BI18', headerName: 'Blerjet Investive 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'NotaKreditore18', headerName: 'Nota Kreditore 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BK18', headerName: 'Borgji Keq 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'RR18', headerName: 'Rregullimet 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'NK18', headerName: 'Ngarkesa Kundert 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },
        {
            field: 'TV18', headerName: 'Total TVSH 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'Importet8', headerName: 'Importet 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'II8', headerName: 'Importet Investive 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BV8', headerName: 'Blerjet Vendore 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BI8', headerName: 'Blerjet Investive 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BF8', headerName: 'Blerjet Nga Fermeret 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'NK8', headerName: 'Nota Kreditore 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BK8', headerName: 'Borgji Keq 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'RR8', headerName: 'Rregullimet 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'TVAT8', headerName: 'Total TVSH 8', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'TVAT8_18', headerName: 'Total TVSH 8 dhe 18', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },
    ];

    const columnsNoVAT = [
        { field: 'id', headerName: '#', width: 110, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'date', headerName: 'Data Fatures', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'nr', headerName: 'Numri Faturës', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'invType', headerName: 'Lloji transaksionit', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'type', headerName: 'Import/Export', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'blersi', headerName: 'Emri Bleresit', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'nui', headerName: 'Numri Identifikues', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        {
            field: 'BMSH', headerName: 'Blerje e mallrave / shërbimeve', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },

        {
            field: 'BPSH', headerName: 'Blerje për shpenzime', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true

        },
        {
            field: 'BI', headerName: 'Blerje investive', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'IM', headerName: 'Importe të mallrave', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'II', headerName: 'Importe investive', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BSHJV', headerName: 'Blerje të shërbimeve jashtë vendit', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },

        {
            field: 'BT', headerName: 'Blerjet totale', type: 'string', width: 110, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
            hide: !vatDeclarant, // Fsheh kolonën nëse vatDeclarant nuk është true
        },
    ];

    const rows = data.map((item) => ({
        id: item.Nr,
        date: item.DataFatures || 0,
        nr:(item.ActionType =="IMP" ? item.ImpInvoiceNr : item.NumriFatures) || 0,
        // nr: item.NumriFatures || 0,
        blersi: item.EmriShitesit || 0,
        nui: item.IdentificationNumber || 0,
        vat: item.VATNumber || 0,
        BPT: item.BlerjetPaTVSH || 0,
        BDIIPT: item.Blerjet_dhe_Imp_Inv_PaTVSH || 0,
        BIITJZ: item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme || 0,
        BIIMTJZ1: item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme1 || 0,
        importet18: item.Importet18 || 0,
        II18: item.ImportetInvestive18 || 0,
        BV18: item.BlerjetVendore18 || 0,
        BI18: item.BlerjetInvestive18 || 0,
        NotaKreditore18: item.NotaKreditore18 || 0,
        BK18: item.BorgjiKeq18 || 0,
        RR18: item.Rregullimet18 || 0,
        NK18: item.NgarkesaKundert18 || 0,
        TV18: item.TotalTVSH18 || 0,
        Importet8: item.Importet8 || 0,
        II8: item.ImportetInvestive8 || 0,
        BV8: item.BlerjetVendore8 || 0,
        BI8: item.BlerjetInvestive8 || 0,
        BF8: item.BlerjetNgaFermeret8 || 0,
        NK8: item.NotaKreditore8 || 0,
        BK8: item.BorgjiKeq8 || 0,
        RR8: item.Rregullimet8 || 0,
        TVAT8: item.TotalTVSH8 || 0,
        TVAT8_18: item.Total_TVSH_8_dhe_18 || 0,

        // className: item.Balance === 0 ? 'zero-balance-row' : 'non-zero-balance-row', // Shto klasën CSS bazuar në vlerën e 'balance'
    }));

    const rowsNoVAT = dataNoVAT.map((item) => {
        // Llogarit totalin e vlerave për ShitjeVendore, EksporteSherbimeve dhe EksporteProdukteve
        const total = (parseFloat(item.BlerjeVendoreMallrave) || 0) + (parseFloat(item.BlerjeVendoreShpenzimeve) || 0) + (parseFloat(item.BlerjeInvestive) || 0) + (parseFloat(item.ImporteMallra) || 0) + (parseFloat(item.ImporteInvestive) || 0) + (parseFloat(item.ImporteSherbimeve) || 0);

        // Kthe objektin me të dhënat e përpunuara dhe totalin e shtuar
        return {
            id: item.Nr,
            date: item.Date || 0,
            nr:(item.ActionType =="IMP" ? item.ImpInvoiceNr : item.NumriFatures) || 0,
            // nr: item.InvoiceNr || 0,
            invType: item.ActionType || 0,
            type: item.TransactionType || 0,
            blersi: item.AccountName || 0,
            nui: item.IdentificationNumber || 0,
            BMSH: item.BlerjeVendoreMallrave || 0,
            BPSH: item.BlerjeVendoreShpenzimeve || 0,
            BI: item.BlerjeInvestive || 0,
            IM: item.ImporteMallra || 0,
            II: item.ImporteInvestive || 0,
            BSHJV: item.ImporteSherbimeve || 0,
            BT: total.toString(), // Vendos totalin në objekt
        };
    });

    useEffect(() => {
        fetchData();
        fetchCompaniesData();
        fetchdataNoVAT();
    }, [month, startDate, endDate]);

    return (
        <div className="TaxButtons__Container">

            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>Libri i Blerjës për ATK</h3>
                </div>
            </div>
            <div className="first_paragraph">
                <p>Ky format i librit të blerjeve është i përshtatur për ngarkim në sistemin e ATK-së.</p>
            </div>

            {/* <div style={{ display: "flex", justifyContent: "end" }}>
                <>
                    {
                        showMonthFilter ? (
                            <button className="button-15" onClick={openDateFilter}>Filtro sipas datave</button>
                        ) : (
                            <>
                                <button className="button-15" onClick={openMonthFilter}>Filtro sipas Muajit</button>
                            </>
                        )
                    }
                </>
                <>
                    {
                        showMonthFilter ? (
                            <TextField
                                style={{ width: "25%", marginLeft: "5px" }}
                                id=""
                                select
                                label="Përzgjedh muajin"
                                value={month}
                                onChange={event => {
                                    setMonth(event.target.value);
                                    fetchData(); 
                                }}
                                required
                            >
                                {Month.map((data) => (
                                    <MenuItem key={data.id} value={data.id}>
                                        {vitAktual}{data.month}
                                    </MenuItem>
                                ))}
                            </TextField>


                        ) : (
                            <div>
                                <TextField
                                    style={{ marginLeft: "5px" }}
                                    className="SalesDetail__Container__Form__TextField__date"
                                    id="date"
                                    type="date"
                                    variant="outlined"
                                    name='Date'
                                    label="Nga"
                                    value={ngaData}
                                    onChange={(e) => { setNgaData(e.target.value); }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    style={{ marginLeft: "5px" }}
                                    className="SalesDetail__Container__Form__TextField__date"
                                    id="date"
                                    type="date"
                                    variant="outlined"
                                    name='Date'
                                    label="Deri"
                                    value={deriData}
                                    onChange={(e) => { setDeriData(e.target.value); }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>

                        )
                    }
                </>
            </div>

            <div className="bordere">
                <div className="butonss">
                    <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    <div className="butons15">
                        <button className="button-15" onClick={handleDownloadExcel} variant="contained"><AiOutlineFileExcel />XLSX</button>
                    </div>
                </div>

               
            </div> */}
            <div className="TaxButtons__Container__Body">
                <div className="TaxButtons__Container__SearchSection">
                    <div className="TaxButtons__Container__SearchSection__PDF">
                        {
                            vatDeclarant ?
                                <>
                                    <div className="butons15">
                                        <button className="button-15" onClick={handleDownloadExcel} variant="contained"><AiOutlineFileExcel />XLSX</button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="butons15">
                                        <button className="button-15" onClick={handleDownloadExcelNoVAT} variant="contained"><AiOutlineFileExcel />XLSX</button>
                                    </div>
                                </>
                        }
                    </div>

                    <div className="TaxButtons__Container__SearchSection__Search">
                        {
                            vatDeclarant ?
                                <>
                                    <div className="TaxButtons__Container__SearchSection__Search__Btn">
                                        {
                                            showMonthFilter ? (
                                                <button className="button-15" onClick={openDateFilter}>Filtro sipas datave</button>
                                            ) : (
                                                <>
                                                    <button className="button-15" onClick={openMonthFilter}>Filtro sipas Muajit</button>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="TaxButtons__Container__SearchSection__Search__Input">
                                        {
                                            showMonthFilter ? (
                                                <TextField
                                                    className="TaxButtons__Container__SearchSection__Search__Input__Month"
                                                    id=""
                                                    select
                                                    label="Përzgjedh muajin"
                                                    value={month}
                                                    onChange={event => {
                                                        setMonth(event.target.value);
                                                        fetchData();
                                                    }}
                                                    required
                                                >
                                                    {Month.map((data) => (
                                                        <MenuItem key={data.id} value={data.id}>
                                                            {vitAktual}{data.month}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : (
                                                <div>
                                                    <TextField
                                                        style={{ marginLeft: "5px" }}
                                                        id="date"
                                                        type="date"
                                                        variant="outlined"
                                                        name='Date'
                                                        label="Nga"
                                                        value={ngaData}
                                                        onChange={(e) => { setNgaData(e.target.value); }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        style={{ marginLeft: "5px" }}
                                                        id="date"
                                                        type="date"
                                                        variant="outlined"
                                                        name='Date'
                                                        label="Deri"
                                                        value={deriData}
                                                        onChange={(e) => { setDeriData(e.target.value); }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </div>

                                            )
                                        }
                                    </div>
                                </> :
                                <div className="TaxButtons__Container__SearchSection__Search__Input">
                                    <TextField
                                        className="TaxButtons__Container__SearchSection__Search__Input__Month"
                                        id=""
                                        select
                                        label="Përzgjedh 3-Mujorin"
                                        value={tm}
                                        onChange={(e) => { handleChange(e); setTm(e.target.value) }}
                                        required
                                    >
                                        {allTM.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>
                                                {data.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <hr></hr>
            {
                vatDeclarant ?
                    <DataGridComponent columns={columns} rows={rows} />
                    :
                    <DataGridComponent columns={columnsNoVAT} rows={rowsNoVAT} />
            }
        </div>
    )
}


{/* <Paper>
<TableContainer sx={{ maxHeight: 500 }}>
    <Table ref={tableRef} stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow> */}
{/* <TableCell sx={styles.tableHeader}><strong>Nr</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Data Fatures</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Numri Fatures</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Emri Shitesit</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Identification Number</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>VAT Number</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet Pa TVSH</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet dhe Imp Inv Pa TVSH</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet dhe Imp Inv me TVSH jo Te Zbritshme</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet dhe Imp Inv me TVSH jo Te Zbritshme 1</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Importet 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Importet Investive 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet Vendore 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet Investive 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Nota Kreditore 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Borgji Keq 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Rregullimet 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Ngarkesa Kundert 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Total TVSH 18</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Importet 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Importet Investive 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet Vendore 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet Investive 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Blerjet Nga Fermeret 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Nota Kreditore 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Borgji Keq 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Rregullimet 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Total TVSH 8</strong></TableCell> */ }
{/* <TableCell sx={styles.tableHeader}><strong>Total TVSH 8 dhe 18</strong></TableCell> */ }



{/* <TableCell sx={styles.tableHeader}><strong>Actions</strong></TableCell> */ }
{/* </TableRow>
        </TableHead>
        {loading ? (
            <div className="spinner" style={{ marginLeft: "550px" }}></div>
        ) : (
            <TableBody>
                {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={item.Nr}>
                            <TableCell sx={styles.tableCell}>{item.Nr}</TableCell>
                            <TableCell sx={styles.tableCell}>{item.DataFatures}</TableCell>
                            <TableCell sx={styles.tableCell}>{item.NumriFatures}</TableCell>
                            <TableCell sx={styles.tableCell}>{item.EmriShitesit}</TableCell>
                            <TableCell sx={styles.tableCell}>{item.IdentificationNumber}</TableCell>
                            <TableCell sx={styles.tableCell}>{item.VATNumber}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BlerjetPaTVSH === 'number' ? (item.BlerjetPaTVSH === 0 ? '' : item.BlerjetPaTVSH.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Blerjet_dhe_Imp_Inv_PaTVSH === 'number' ? (item.Blerjet_dhe_Imp_Inv_PaTVSH === 0 ? '' : item.Blerjet_dhe_Imp_Inv_PaTVSH.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme === 'number' ? (item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme === 0 ? '' : item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme1 === 'number' ? (item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme1 === 0 ? '' : item.Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme1.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Importet18 === 'number' ? (item.Importet18 === 0 ? '' : item.Importet18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.ImportetInvestive18 === 'number' ? (item.ImportetInvestive18 === 0 ? '' : item.ImportetInvestive18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BlerjetVendore18 === 'number' ? (item.BlerjetVendore18 === 0 ? '' : item.BlerjetVendore18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BlerjetInvestive18 === 'number' ? (item.BlerjetInvestive18 === 0 ? '' : item.BlerjetInvestive18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.NotaKreditore18 === 'number' ? (item.NotaKreditore18 === 0 ? '' : item.NotaKreditore18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BorgjiKeq18 === 'number' ? (item.BorgjiKeq18 === 0 ? '' : item.BorgjiKeq18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Rregullimet18 === 'number' ? (item.Rregullimet18 === 0 ? '' : item.Rregullimet18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.NgarkesaKundert18 === 'number' ? (item.NgarkesaKundert18 === 0 ? '' : item.NgarkesaKundert18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.TotalTVSH18 === 'number' ? (item.TotalTVSH18 === 0 ? '' : item.TotalTVSH18.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Importet8 === 'number' ? (item.Importet8 === 0 ? '' : item.Importet8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.ImportetInvestive8 === 'number' ? (item.ImportetInvestive8 === 0 ? '' : item.ImportetInvestive8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BlerjetVendore8 === 'number' ? (item.BlerjetVendore8 === 0 ? '' : item.BlerjetVendore8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BlerjetInvestive8 === 'number' ? (item.BlerjetInvestive8 === 0 ? '' : item.BlerjetInvestive8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BlerjetNgaFermeret8 === 'number' ? (item.BlerjetNgaFermeret8 === 0 ? '' : item.BlerjetNgaFermeret8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.NotaKreditore8 === 'number' ? (item.NotaKreditore8 === 0 ? '' : item.NotaKreditore8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.BorgjiKeq8 === 'number' ? (item.BorgjiKeq8 === 0 ? '' : item.BorgjiKeq8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Rregullimet8 === 'number' ? (item.Rregullimet8 === 0 ? '' : item.Rregullimet8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.TotalTVSH8 === 'number' ? (item.TotalTVSH8 === 0 ? '' : item.TotalTVSH8.toFixed(2)) : ''}</TableCell>
                            <TableCell sx={styles.tableCell}>{typeof item.Total_TVSH_8_dhe_18 === 'number' ? (item.Total_TVSH_8_dhe_18 === 0 ? '' : item.Total_TVSH_8_dhe_18.toFixed(2)) : ''}</TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        )}
    </Table>
</TableContainer>
<TablePagination
    rowsPerPageOptions={[10, 25, 100]}
    component="div"
    count={filteredData.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
/>

</Paper> */}