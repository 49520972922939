import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiOutlineFilePdf, AiOutlineClose } from "react-icons/ai";
import { SiHyperledger } from "react-icons/si";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { errorAlert } from "../../Components/Alerts";
import DataGridShowDetails from "../../Components/DataGridShowDetails";
import DataGridComponent from "../../Components/DataGridComponent";
import { InventoryDataGrid, InventoryDetails } from "./AccountingReports_DataGrid/InventoryDataGrid";
import { currencyFormatter } from "../../Components/CurrencyFormat";

export default function Inventory() {
    const [data, setData] = useState([]);
    const [articleDetails, setArticleDetails] = useState([]);

    const [showallAccounts, setShowAllAccounts] = useState(true);
    const [showDetailAccounts, setShowDetailsAccounts] = useState(false);

    const headers = authHeader();

    const [searchTerm, setSearchTerm] = useState("");
    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");
    let number = 1;


    const showDetails = () => {
        setShowAllAccounts(false);
        setShowDetailsAccounts(true);
    }
    const showAllAccounts = () => {
        setShowAllAccounts(true);
        setShowDetailsAccounts(false);
        setArticleDetails([]);

    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            Business Number:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };

    const addTable = (doc, data, lineY) => {
        let totalQtyInput = 0;
        let totalQtyOutput = 0;
        let totalStok = 0;
        let totalAmount = 0;

        const bodyData = filteredData.map((item, index) => {
            totalQtyInput += item.TotalQtyInput;
            totalQtyOutput += item.TotalQtyOutput;
            totalStok += item.TotalQtyInStock;
            totalAmount += item.TotalAmount;

            return [
                (number + index),
                item.ArticleName,
                item.Barcode,
                item.ArticleGroupName,
                item.ArticleUnitName,
                item.TotalQtyInput !== null && item.TotalQtyInput !== 0 ? item.TotalQtyInput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                item.TotalQtyOutput !== null && item.TotalQtyOutput !== 0 ? item.TotalQtyOutput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                item.TotalQtyInStock !== null && item.TotalQtyInStock !== 0 ? item.TotalQtyInStock.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                item.CmimiMesatar !== null && item.CmimiMesatar !== 0 ? item.CmimiMesatar.toLocaleString('en-US', { minimumFractionDigits: 4 }) : '-',
                item.TotalAmount !== null && item.TotalAmount !== 0 ? item.TotalAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
            ];
        });

        // Shtoni rreshtin e totalit në fund të tabelës
        bodyData.push([
            '', '', '', '', 'Totali:',
            totalQtyInput !== null && totalQtyInput !== 0 ? totalQtyInput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
            (totalQtyOutput !== null && totalQtyOutput !== 0) ? totalQtyOutput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
            (totalStok !== null && totalStok !== 0) ? totalStok.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
            "",
            (totalAmount !== null && totalAmount !== 0) ? totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'
        ]);

        doc.autoTable({
            head: [["#", "Artikulli", "Barkodi", "Grupi artikullit ", "Njësia e artikullit", " Sasia blerjeve", "Sasia shitjeve", "Sasia në stok", "Cmimi Mesatar", "Vlera"]],
            body: bodyData,
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
            didDrawCell: (data) => {
                if (data.row.index === bodyData.length - 1) { // Kontrolloni nëse është rreshti i fundit (totali)
                    // Cakto ngjyrën e sfondit dhe të tekstit për rreshtin e totalit
                    doc.setFillColor(220, 220, 220); // Ngjyra e sfondit në hiri
                    doc.setTextColor(0, 0, 0); // Ngjyra e tekstit në të bardhë
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Vizato një drejtkëndësh me këtë ngjyrë

                    // Vizato tekstin manualisht
                    if (data.cell.text) {
                        doc.text(data.cell.text, data.cell.x + 2, data.cell.y + data.cell.height - 2);
                    }
                }
            }
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "Stoku", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("Gjendja_Stokut.pdf");
    };

    const addSecondTable = (doc, data, lineY) => {
        let totalQtyInput = 0;
        let totalQtyOutput = 0;
        let progresive = 0;

        const bodyData = filteredDetails.map((item, index) => {
            totalQtyInput += item.QTYInput;
            totalQtyOutput += item.QtyOutput;
            progresive += item.Progressive;

            return [
                (number + index),
                item.ActionType,
                item.Barcode,
                item.ArticleName,
                item.PartnerName,
                item.Date,
                item.PriceWithoutVAT !== null && item.PriceWithoutVAT !== 0 ? item.PriceWithoutVAT.toLocaleString('en-US', { minimumFractionDigits: 4 }) : '-',
                item.QTYInput !== null && item.QTYInput !== 0 ? item.QTYInput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                item.QtyOutput !== null && item.QtyOutput !== 0 ? item.QtyOutput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                item.Progressive !== null && item.Progressive !== 0 ? item.Progressive.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-'
            ];
        });

        // Shtoni rreshtin e totalit në fund të tabelës
        bodyData.push([
            '', '', '', '', '', "", 'Totali:',
            (totalQtyInput !== null && totalQtyInput !== 0) ? totalQtyInput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
            (totalQtyOutput !== null && totalQtyOutput !== 0) ? totalQtyOutput.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
            (progresive !== null && progresive !== 0) ? progresive.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
        ]);

        doc.autoTable({
            head: [["#", "Tipi Veprimit", "Barkodi", "Emri Artikullit", "Emri i Partnerit", "Data", "Cmimi Pa TVSH", "Sasia Hyrje", "Sasia Dalje", "Progresive"]],
            body: bodyData,
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' },
            },
            didDrawCell: (data) => {
                if (data.row.index === bodyData.length - 1) { // Kontrolloni nëse është rreshti i fundit (totali)
                    // Cakto ngjyrën e sfondit dhe të tekstit për rreshtin e totalit
                    doc.setFillColor(220, 220, 220); // Ngjyra e sfondit në hiri
                    doc.setTextColor(0, 0, 0); // Ngjyra e tekstit në të bardhë
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Vizato një drejtkëndësh me këtë ngjyrë

                    // Vizato tekstin manualisht
                    if (data.cell.text) {
                        doc.text(data.cell.text, data.cell.x + 2, data.cell.y + data.cell.height - 2);
                    }
                }
            }
        });
    };

    const handleExportSecondPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "Kartela Artikullit", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addSecondTable(doc, filteredDetails, lineY);

        doc.save("ArticleDetailsInventory.pdf");
    };
    // const ledger = 101;
    // Kodi per Marrjen e informatave nga databaza
    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingBooks/stoku', { headers });
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchArticleDetails = async (articleID) => {
        showDetails();
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingBooks/StokuDetale', { headers, params: { articleID: articleID } });
            setArticleDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const filteredData = data.filter((item) => {
        return (
            item.ArticleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Barcode.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const filteredDetails = articleDetails.filter((item) => {
        return (
            item.ArticleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Barcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.ActionType.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const rows = data.map((item, index) => ({
        id: ++index,
        // accID: item.AccountID,
        accName: item.ArticleName,
        barcode: item.Barcode,
        group: item.ArticleGroupName,
        unit: item.ArticleUnitName,
        qtyInput: item.TotalQtyInput,
        qtyOutput: item.TotalQtyOutput,
        qtyStok: item.TotalQtyInStock,
        price: item.CmimiMesatar,
        total: item.TotalAmount,
        showDetails: item.ArticleID,
        rowInfo: item
    }));

    const detailsRows = articleDetails.map((item, index) => ({
        id: ++index,
        type: item.ActionType,
        barcode: item.Barcode,
        articleName: item.ArticleName,
        accName: item.PartnerName,
        date: item.Date,
        priceNoVAT: item.PriceWithoutVAT,
        qtyInput: item.QTYInput,
        qtyOutput: item.QtyOutput,
        progresive: item.Progressive,
    }));

    useEffect(() => {
        fetchData();
        fetchCompaniesData();
    }, []);

    return (
        <div className="Crud__Container">
            {showallAccounts && (
                <>
                    <div className="header_flex">
                        <div className="header_icon">
                            <SiHyperledger style={{}} className="header_icons" />
                            <h3>Stoku</h3>
                        </div>
                    </div>
                    <div className="first_paragraph">
                        <p>Kjo formë është e përshtatur për shtypje dhe përdorim në dokumentacionin fizik.</p>
                    </div>


                    <div className="bordere">
                        <div className="butonss">
                            <div>
                                <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                            </div>
                            {/* <div className="butons15">
                                <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                            </div> */}
                        </div>
                    </div>
                    <DataGridShowDetails
                        columns={InventoryDataGrid}
                        rows={rows}
                        fetchAccountsDetails={fetchArticleDetails}
                        height={500}
                    />
                </>
            )}
            {showDetailAccounts && (
                <>
                    <div className="header_flex">
                        <div className="header_icon">
                            <SiHyperledger style={{}} className="header_icons" />
                            <h3>Kartela Artikullit</h3>
                        </div>
                    </div>
                    <div className="bordere">
                        <div className="butonss">
                            <div>
                                <button className="button-15" onClick={handleExportSecondPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                            </div>
                            <div className="butons15">
                                <button className="button-15" variant="contained" onClick={showAllAccounts}><AiOutlineClose /> Mbyll</button>
                            </div>
                        </div>
                    </div>
                    <DataGridComponent columns={InventoryDetails} rows={detailsRows} />
                </>
            )}
        </div>
    )
}