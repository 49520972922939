import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InsertData from "./InsertUsers";
import { handleSuccess, deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import Modal_bootstrap from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { AiFillEdit, AiOutlinePrinter, AiOutlineFilePdf, AiOutlineUsergroupAdd } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { LuCrown } from "react-icons/lu";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { HiOutlineUserAdd } from "react-icons/hi";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import { Typography, Modal } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MUI_Button from "@mui/material/Button";




export default function Users() {
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        Id: "",
        PhoneNumber: "",
        CompanyName: ""
    });


    // setTimeout(function () {
    //     fetchData()
    // }, 10000); // 10 seconds in milliseconds

    const [role, setRole] = useState("");


    // keto konstante sherbejn per ta shtuar dhe per ta bere remove nje rol
    const [selectedRole, setSelectedRole] = useState("");
    const [userID, setUserID] = useState("");
    const [showAddConfirmation, setShowAddConfirmation] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const [openAccModal, setOpenAccModal] = useState(false);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [company, setCompany] = useState([]);
    let number = 1;

    const [paymentsAccounts, setPaymentsAccounts] = useState([]);
    const [posAccountID, setPosAccountID] = useState('')
    const [userName, setUserName] = useState('')
    const handleAutocompleteChange = (event, value) => {
        if (value) {
            setPosAccountID(value.AccountID);
        } else {
            setPosAccountID("");
        }
    };

    const defaultProps = {
        options: paymentsAccounts,
        getOptionLabel: (option) => option.AccountName || ""
    };

    // Kodi per Marrjen e informatave nga databaza
    const headers = authHeader();


    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Users/getusers', { headers });
            setData(response.data);
            console.log(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchPaymentsAccount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 101 } });
            setPaymentsAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchRole = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AuthProva/getrole', { headers });

            if (response.data.UserRole && response.data.UserRole !== undefined) {
                setRole(response.data.UserRole);
            } else {
                errorAlert("Undefined Role.");
            }
        } catch (error) {
            console.error("Error fetching role:", error);
            // ... (pjesa tjetër e kodit)
        }
    };

    //Kodi per shfaqjen e tabeles ne PDF
    const handleExportPDF = () => {
        const doc = new jsPDF();
        // Teksti i parë në fillim të dokumentit
        const text1 = "AccounTree";
        doc.text(text1, 10, 10); // 20 është pozicioni X, 10 është pozicioni Y

        // Teksti i dytë në fillim të dokumentit
        const text2 = "Users List";
        doc.text(text2, 10, 20); // 20 është pozicioni X, 20 është pozicioni Y

        // Teksti i tretë në fillim të dokumentit
        // const text3 = "Accounting is the process of recording financial transactions pertaining to a business.";
        // doc.text(text3, 10, 30); // 20 është pozicioni X, 30 është pozicioni Y

        // Paragrafi në fillim të dokumentit
        // const paragraph = "Ky është një paragraf në fillim të tabelës.";
        // doc.text(paragraph, 20, 40); // 20 është pozicioni X, 40 është pozicioni Y
        doc.autoTable({
            head: [["Email", "Company"]],
            body: filteredData.map((item) => [item.Email, item.CompanyName]),
            startY: 30
        });
        doc.save("Users-List.pdf");
    };
    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    //Kodi per pegination ne tabel
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    //Kodi per shfaqjen e loadingut 

    const [loading, setLoading] = useState(true);


    const filteredData = data.filter((item) => {
        return (
            item.UserName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    const handleCreate = async () => {
        try {
            const response = await axios.post(
                ApiLink.API_URL + 'AuthProva/updateUserRole',
                { userId: userID, role: selectedRole },
                { headers }
            );
            // Handle success
            setShowAddConfirmation(false);
            handleSuccess("Role Added Successfully");
            fetchData();
            setSelectedRole("")
            setUserID("");
        } catch (error) {
            // Handle error
            if (error.response && error.response.data) {
                errorAlert("Server error response:", error.response.data);
            } else {
                errorAlert("General error:", error);
            }
        }
    };

    const handleRemoveUserRole = async () => {
        try {
            const response = await axios.post(
                ApiLink.API_URL + 'AuthProva/removeUserRole',
                { userId: userID, role: selectedRole },
                { headers }
            );
            // Handle success
            setShowRemoveConfirmation(false);
            handleSuccess("Role removed Successfully");
            fetchData();
            setSelectedRole("")
            setUserID("");
        } catch (error) {
            // Handle error
            if (error.response && error.response.data) {
                errorAlert("Server error response:", error.response.data);
            } else {
                errorAlert("General error:", error);
            }
        }
    };

    const deleteUser = async () => {
        try {
            const response = await axios.delete(
                ApiLink.API_URL + 'AuthProva/deleteUser',
                { userId: deleteItemId },
                { headers }  // Përdorimi i 'data' për të përcaktuar trupin e kërkesës
            );

            // Handle success
            setShowDeleteConfirmation(false);
            handleSuccess("User deleted successfully");
            setDeleteItemId("");
            fetchData("");
        } catch (error) {
            // Handle error
            if (error.response && error.response.data) {
                errorAlert("Server error response:", error.response.data);
            } else {
                errorAlert("General error:", error);
            }
        }
    };

    const addUsersPOS = (event) => {
        event.preventDefault();
        if (posAccountID == null || posAccountID == '') {
            errorAlert("Ju lutem zgjedhni arken");
            return;
        }

        const stateData = {
            AccountID: posAccountID,
            UserID: userID,
        };

        axios.post(ApiLink.API_URL + 'UserDetails', stateData, { headers })
            .then((response) => {
                handleSuccess("arka u regjistrua me sukses tek perdoruesi");
                setPosAccountID("");
                setUserID("");
                setOpenAccModal(false);
                fetchData();

            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    };







    const handleEdit = (item) => {
        setFormData({
            Id: item.Id,
            PhoneNumber: item.PhoneNumber,
        });
        setShowEditModal(true);
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'AuthProva/update', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("User updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update User")
            });
    };

    useEffect(() => {
        axios.get(ApiLink.API_URL + 'companies', { headers })
            .then((response) => {
                setCompany(response.data);

            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const getBackgroundColor = (claimValue) => {
        switch (claimValue) {
            case 'acctree':
                return <LuCrown style={{ width: "20px", height: "20px" }} />;
            case 'admin':
                return <MdOutlineAdminPanelSettings style={{ width: "20px", height: "20px" }} />;
            case 'staff':
                return <HiOutlineUserAdd style={{ width: "20px", height: "20px" }} />;
            default:
                return <FaRegUser style={{ width: "20px", height: "20px" }} />;
        }
    };

    useEffect(() => {
        fetchRole();
        fetchData();
        fetchPaymentsAccount();
    }, []);

    return (
        <div>
            <Modal_bootstrap show={showAddConfirmation} onHide={() => setShowAddConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal_bootstrap.Header>
                    <Modal_bootstrap.Title>Confirmation</Modal_bootstrap.Title>
                </Modal_bootstrap.Header>
                <Modal_bootstrap.Body>
                    <p>Are you sure you want to Add new {selectedRole}?</p>
                </Modal_bootstrap.Body>
                <Modal_bootstrap.Footer>
                    <Button variant="secondary" onClick={() => setShowAddConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleCreate}>
                        Yes
                    </Button>
                </Modal_bootstrap.Footer>
            </Modal_bootstrap>

            <Modal_bootstrap show={showRemoveConfirmation} onHide={() => setShowRemoveConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal_bootstrap.Header>
                    <Modal_bootstrap.Title>Confirmation</Modal_bootstrap.Title>
                </Modal_bootstrap.Header>
                <Modal_bootstrap.Body>
                    <p>Are you sure you want to remove {selectedRole} ?</p>
                </Modal_bootstrap.Body>
                <Modal_bootstrap.Footer>
                    <Button variant="secondary" onClick={() => setShowRemoveConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleRemoveUserRole}>
                        Yes
                    </Button>
                </Modal_bootstrap.Footer>
            </Modal_bootstrap>

            <Modal_bootstrap show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal_bootstrap.Header>
                    <Modal_bootstrap.Title>Confirmation</Modal_bootstrap.Title>
                </Modal_bootstrap.Header>
                <Modal_bootstrap.Body>
                    <p>Are you sure you want to Delete?</p>
                </Modal_bootstrap.Body>
                <Modal_bootstrap.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={deleteUser}>
                        Yes
                    </Button>
                </Modal_bootstrap.Footer>
            </Modal_bootstrap>

            <Modal
                open={openAccModal}
                onClose={() => setOpenAccModal(false)}
                aria-labelledby="insert-modal-title"
                aria-describedby="insert-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ width: "30%", bgcolor: 'background.paper', borderRadius: '4px', boxShadow: 24, p: 4 }}>
                    <FormControl sx={{ m: 1 }} variant="filled" style={{ width: "100%" }}>
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-read-only-input"
                            label="Perdoruesi"
                            value={userName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>
                    <Autocomplete fullWidth sx={{ m: 1 }}
                        {...defaultProps}
                        id="include-input-in-list"
                        includeInputInList
                        onChange={handleAutocompleteChange} // Shto funksionin onChange
                        renderInput={(params) => (
                            <TextField {...params} label="Zgjedh arken" variant="standard" />
                        )}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
                        <MUI_Button variant="contained" color="secondary" onClick={() => { setOpenAccModal(false); }} sx={{ width: "45%" }}>Mbyll</MUI_Button>
                        <MUI_Button variant="contained" type="submit" onClick={addUsersPOS} sx={{ width: "45%" }}>Ruaj</MUI_Button>
                    </Box>
                </Box>
            </Modal>


            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {
                showEditModal && (
                    <div className="edit-modal">
                        <form onSubmit={handleSubmit}>
                            <h3>Ndryshimi i Përdoruesit</h3>

                            <div className="input-group mb-3">
                                <span className="input-group-text col-3 col-sm-3">Numri Identifikues: </span>
                                <input
                                    className="form-control"
                                    type="string"
                                    id="Id"
                                    name="Id"
                                    value={formData.Id}
                                    onChange={handleInputChange}
                                    disabled
                                />

                            </div>
                            <div className="input-group mb-3">
                                <label className="input-group-text col-3 col-sm-3" htmlFor="PhoneNumber">Kompania: </label>
                                <select
                                    id="PhoneNumber"
                                    name="PhoneNumber"
                                    className="form-control"
                                    value={formData.PhoneNumber}
                                    onChange={handleInputChange}
                                >
                                    <option value="">-- Select Company --</option>
                                    {company.map((com) => (
                                        <option key={com.CompanyID} value={com.CompanyID}>
                                            {com.CompanyName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button type="submit" className="btn btn-success">Ndrysho</button>

                            <button
                                className="btn btn-secondary"
                                onClick={() => setShowEditModal(false)}
                            >
                                Anulo
                            </button>
                        </form>
                    </div>
                )
            }
            <div className="header_flexx">
                <div className="header_icon">
                    <AiOutlineUsergroupAdd style={{}} className="header_icons" />
                    <h3>Përdoruesit</h3>
                </div>
                {
                    role === "acctree" && (
                        <div>
                            <InsertData fetchData={fetchData} />
                        </div>
                    )
                }
            </div>
            <div className="header_paragraph">
                <p>Forma e Përdoruesve: Menaxhoni, shtoni, editoni dhe fshini Përdorues.</p>
            </div>

            <div className="bordere">
                {/* <div className="butonss">
                    <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div>
                </div> */}
                <div></div>

                <div className="searchboxx">
                    <div className="flexbox">
                        <div class="search">
                            <div>
                                <input
                                    type="text"
                                    placeholder="Search Users"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}

                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <hr></hr>

            <Paper>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table ref={tableRef} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styles.tableHeader}><strong>#</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Email</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Kompania</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>P.O.S</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Roli Aktual</strong></TableCell>

                                {role === "acctree" ?
                                    <>
                                        <TableCell sx={styles.tableHeader}><strong>Ndrysho Rolin</strong></TableCell>
                                        <TableCell sx={styles.tableHeader}><strong>Veprimet</strong></TableCell>
                                    </>
                                    :
                                    <></>
                                }
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <div className="spinner" style={{ marginLeft: "560px" }} ></div>
                        ) : (
                            <TableBody>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>
                                            <TableCell sx={styles.tableCell}>{number + index}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.UserName}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.CompanyName}</TableCell>
                                            <TableCell sx={styles.tableCell}>
                                                {
                                                    item.AccountName ?
                                                        <>
                                                            {item.AccountName}
                                                        </>
                                                        :
                                                        <Tooltip title="Shto P.O.S" placement="left" type="submit" onClick={() => setOpenAccModal(true)}>
                                                            <AddIcon type="submit" onClick={() => { setOpenAccModal(true); setUserName(item.UserName); setUserID(item.Id) }} />
                                                        </Tooltip>
                                                }
                                                {/* <Tooltip title="Shto P.O.S" placement="left" type="submit" onClick={() => setOpenAccModal(true)}>
                                                    <AddIcon type="submit" onClick={() => { setOpenAccModal(true); setUserName(item.UserName); setUserID(item.Id) }} />
                                                </Tooltip> */}
                                            </TableCell>
                                            <TableCell sx={styles.tableCell} >{getBackgroundColor(item.ClaimValue)}</TableCell>
                                            {role === "acctree" ?
                                                <>
                                                    <TableCell sx={styles.tableCell}>
                                                        {role === "acctree" && (
                                                            <>
                                                                <Tooltip placement="right">
                                                                    {item.ClaimValue === "admin" && (
                                                                        <Tooltip title="Remove Admin" placement="left">
                                                                            <IconButton>
                                                                                <GroupAddIcon style={{ color: '#ff0000' }} onClick={() => {
                                                                                    setShowRemoveConfirmation(true);
                                                                                    setUserID(item.Id);
                                                                                    setSelectedRole("admin");
                                                                                }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {item.ClaimValue === "adminunit" && (
                                                                        <Tooltip title="Remove Admin Unit" placement="left">
                                                                            <IconButton>
                                                                                <GroupAddIcon style={{ color: '#ff0000' }} onClick={() => {
                                                                                    setShowRemoveConfirmation(true);
                                                                                    setUserID(item.Id);
                                                                                    setSelectedRole("adminunit");
                                                                                }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {item.ClaimValue === "acctree" && (
                                                                        <Tooltip title="You are King" placement="left">
                                                                            <IconButton>
                                                                                <LuCrown style={{ width: "20px", height: "20px" }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}

                                                                    {item.ClaimValue === "staff" && (
                                                                        <Tooltip title="Remove Staff" placement="left">
                                                                            <IconButton>
                                                                                <GroupAddIcon style={{ color: '#ff0000' }} onClick={() => {
                                                                                    setShowRemoveConfirmation(true);
                                                                                    setUserID(item.Id);
                                                                                    setSelectedRole("staff");
                                                                                }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {item.ClaimValue === "NULL" && (
                                                                        <>
                                                                            <Tooltip title="Add Admin" placement="left">
                                                                                <IconButton>
                                                                                    <AdminPanelSettingsIcon style={{ color: '#3a7842' }} onClick={() => {
                                                                                        setShowAddConfirmation(true);
                                                                                        setUserID(item.Id);
                                                                                        setSelectedRole("admin");
                                                                                    }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Add Admin Unit" placement="right">
                                                                                <IconButton>
                                                                                    <GroupAddIcon style={{ color: '#3a7842' }} onClick={() => {
                                                                                        setShowAddConfirmation(true);
                                                                                        setUserID(item.Id);
                                                                                        setSelectedRole("adminunit");
                                                                                    }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Add staff" placement="right">
                                                                                <IconButton>
                                                                                    <GroupAddIcon style={{ color: '#3a7842' }} onClick={() => {
                                                                                        setShowAddConfirmation(true);
                                                                                        setUserID(item.Id);
                                                                                        setSelectedRole("staff");
                                                                                    }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                        {role === "admin" && (
                                                            <>
                                                                <Tooltip placement="right">
                                                                    {item.ClaimValue === "adminunit" && (
                                                                        <Tooltip title="Remove Admin Unit" placement="left">
                                                                            <IconButton>
                                                                                <GroupAddIcon style={{ color: '#ff0000' }} onClick={() => {
                                                                                    setShowAddConfirmation(true);
                                                                                    setUserID(item.Id);
                                                                                    setSelectedRole("adminunit");
                                                                                }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {item.ClaimValue === "staff" && (
                                                                        <Tooltip title="Remove Staff" placement="left">
                                                                            <IconButton>
                                                                                <GroupAddIcon style={{ color: '#ff0000' }} onClick={() => {
                                                                                    setShowAddConfirmation(true);
                                                                                    setUserID(item.Id);
                                                                                    setSelectedRole("staff");
                                                                                }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {item.ClaimValue === "NULL" && (
                                                                        <>
                                                                            <Tooltip title="Add Admin Unit" placement="right">
                                                                                <IconButton>
                                                                                    <GroupAddIcon style={{ color: '#3a7842' }} onClick={() => {
                                                                                        setShowAddConfirmation(true);
                                                                                        setUserID(item.Id);
                                                                                        setSelectedRole("adminunit");
                                                                                    }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Add staff" placement="right">
                                                                                <IconButton>
                                                                                    <GroupAddIcon style={{ color: '#3a7842' }} onClick={() => {
                                                                                        setShowAddConfirmation(true);
                                                                                        setUserID(item.Id);
                                                                                        setSelectedRole("staff");
                                                                                    }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                        {role === "adminunit" && (
                                                            <>
                                                                <Tooltip placement="right">
                                                                    {item.ClaimValue === "staff" && (
                                                                        <Tooltip title="Remove Staff" placement="left">
                                                                            <IconButton>
                                                                                <GroupAddIcon style={{ color: '#ff0000' }} onClick={() => {
                                                                                    setShowAddConfirmation(true);
                                                                                    setUserID(item.Id);
                                                                                    setSelectedRole("staff");
                                                                                }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {item.ClaimValue === "NULL" && (
                                                                        <>
                                                                            <Tooltip title="Add staff" placement="right">
                                                                                <IconButton>
                                                                                    <GroupAddIcon style={{ color: '#3a7842' }} onClick={() => {
                                                                                        setShowAddConfirmation(true);
                                                                                        setUserID(item.Id);
                                                                                        setSelectedRole("staff");
                                                                                    }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </TableCell>

                                                    <TableCell sx={styles.tableCell}>
                                                        {role === "acctree" && (
                                                            <Tooltip>
                                                                {item.ClaimValue === "admin" && (
                                                                    <>
                                                                        <Tooltip title="Edit" placement="left">
                                                                            <IconButton>
                                                                                <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete" placement="right">
                                                                            <IconButton>
                                                                                <DeleteIcon
                                                                                    style={{ color: '#962a38' }}
                                                                                    onClick={() => {
                                                                                        setShowDeleteConfirmation(true);
                                                                                        setDeleteItemId(item.Id);
                                                                                        alert(item.Id);
                                                                                        alert(deleteItemId);
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                                {item.ClaimValue === "adminunit" && (
                                                                    <>
                                                                        <Tooltip title="Edit" placement="left">
                                                                            <IconButton>
                                                                                <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete" placement="right">
                                                                            <IconButton>
                                                                                <DeleteIcon
                                                                                    style={{ color: '#962a38' }}
                                                                                    onClick={() => {
                                                                                        setShowDeleteConfirmation(true);
                                                                                        setDeleteItemId(item.Id);
                                                                                        alert(item.Id);
                                                                                        alert(deleteItemId);
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                                {item.ClaimValue === "staff" && (
                                                                    <>
                                                                        <Tooltip title="Edit" placement="left">
                                                                            <IconButton>
                                                                                <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete" placement="right">
                                                                            <IconButton>
                                                                                <DeleteIcon style={{ color: '#962a38' }}
                                                                                    onClick={() => {
                                                                                        setShowDeleteConfirmation(true);
                                                                                        setDeleteItemId(item.Id);
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                                {item.ClaimValue === "NULL" && (
                                                                    <>
                                                                        <Tooltip title="Edit" placement="left">
                                                                            <IconButton>
                                                                                <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete" placement="right">
                                                                            <IconButton>
                                                                                <DeleteIcon
                                                                                    style={{ color: '#962a38' }}
                                                                                    onClick={() => {
                                                                                        setShowDeleteConfirmation(true);
                                                                                        setDeleteItemId(item.Id);
                                                                                        alert(item.Id);
                                                                                        alert(deleteItemId);
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                            </Tooltip>
                                                        )}
                                                        {role === "admin" && (
                                                            <>
                                                                <Tooltip placement="right">
                                                                    {item.ClaimValue === "adminunit" && (
                                                                        <>
                                                                            <Tooltip title="Edit" placement="left">
                                                                                <IconButton>
                                                                                    <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Delete" placement="right">
                                                                                <IconButton>
                                                                                    <DeleteIcon style={{ color: '#962a38' }}
                                                                                        onClick={() => {
                                                                                            setShowDeleteConfirmation(true);
                                                                                            setDeleteItemId(item.Id);
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                    {item.ClaimValue === "staff" && (
                                                                        <>
                                                                            <Tooltip title="Edit" placement="left">
                                                                                <IconButton>
                                                                                    <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Delete" placement="right">
                                                                                <IconButton>
                                                                                    <DeleteIcon style={{ color: '#962a38' }}
                                                                                        onClick={() => {
                                                                                            setShowDeleteConfirmation(true);
                                                                                            setDeleteItemId(item.Id);
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </Tooltip>
                                                            </>
                                                        )}

                                                        {role === "adminunit" && (
                                                            <>
                                                                <Tooltip placement="right">
                                                                    {item.ClaimValue === "staff" && (
                                                                        <>
                                                                            <Tooltip title="Edit" placement="left">
                                                                                <IconButton>
                                                                                    <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Delete" placement="right">
                                                                                <IconButton>
                                                                                    <DeleteIcon style={{ color: '#962a38' }}
                                                                                        onClick={() => {
                                                                                            setShowDeleteConfirmation(true);
                                                                                            setDeleteItemId(item.Id);
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                        {role === "staff" && (
                                                            <>
                                                                <Tooltip placement="right">
                                                                    <><h1></h1></>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </TableCell>
                                                </>
                                                :
                                                <></>
                                            }
                                        </TableRow>
                                    ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <hr></hr>
        </div >
    )
}