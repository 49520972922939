import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import InsertData from "./InsertArticleGroups";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { deleteAlert, errorAlert, handleSuccess, updateAlert } from "../../Components/Alerts";
import authHeader from "../../Auth/authHeader";
import { ApiLink } from "../../APILink/ApiLink";
import { AiFillAppstore } from "react-icons/ai";
import "jspdf-autotable";
import LoginUserDetailed from "../../Auth/LoginUserDetailed";
import TextField from '@mui/material/TextField';
import DataGridWithActions from "../../Components/DataGridWithActions";


export default function ArticleGroups() {
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        GroupID: "",
        GroupName: "",
        IsActive: false,
    });
    const [showEditModal, setShowEditModal] = useState(false);

    const email = LoginUserDetailed(); // replace with your implementation of LoginUserDetailed()
    const headers = authHeader();

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'articlegroup', { headers });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    //Kodi per fshirjen e nje elementi ne databaz 
    // const handleDelete = async (deleteItemId) => {
    //     try {
    //         const response = await axios.delete(
    //             ApiLink.API_URL + 'articlegroup' + `/${deleteItemId}`, { headers }
    //         );
    //         deleteAlert("This Article Group Deleted Successfully");
    //         fetchData();
    //     } catch (error) {
    //         deleteAlert("You can not delete this Group");
    //     }
    // };

    const handleDelete = async (deleteItemId) => {
        try {
            const response = await axios.delete(
                ApiLink.API_URL + 'articlegroup' + `/${deleteItemId}`, { headers }
            );

            const result = response.data;
            if (result.success) {
                handleSuccess("Grupi është fshirë me sukses"); // Shfaq mesazhin e suksesit
                fetchData();
            } else {
                deleteAlert("Ky grup nuk mund të fshihet sepse ekzistojnë artikuj që i përkasin këtij grupi"); // Shfaq mesazhin e gabimit
            }
        } catch (error) {
            deleteAlert("Ka ndodhur një gabim gjatë fshirjes së grupit."); // Mesazh për gabime të tjera
        }
    };


    //Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        setFormData({
            GroupID: item.GroupID,
            GroupName: item.GroupName,
            ModifiedByUser: email,
            IsActive: item.IsActive,
        });
        setShowEditModal(true);
    };

    //Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'articlegroup', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("Article Group updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update Article Group");
                errorAlert("Failed to update Article Group")
            });
    };

    const ArticleGroupGridHeader = [
        { field: 'id', headerName: '#', width: 70, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'name', headerName: 'Emri i Grupit', width: 1000, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'active', headerName: 'Statusi', width: 100, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    ]

    const rows = data.map((item, index) => ({
        id: ++index,
        name: item.GroupName,
        active: (item.IsActive ? "Aktiv" : "Jo-Aktiv"),
        deleteItemId: item.GroupID,
        rowInfo: item
    }));

    //Ruhen tdhanat 
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="Crud__Container">
            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div class="edit-modal-wrapper" >
                    {/* <StyledModal > */}
                    <div style={{
                        width: '30%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                        ...(
                            window.innerWidth <= 500 ? {
                                width: '90%',
                                paddingTop: '30px',
                                paddingBottom: '30px',
                            } : {}
                        )
                    }}>
                        <form onSubmit={handleSubmit} style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                            <div style={{ width: "80%" }}>
                                <div>
                                    <div>
                                        <TextField
                                            style={{ width: "100%" }}
                                            required
                                            id="GroupName"
                                            label="Emri i Grupit"
                                            variant="outlined"
                                            type="text"
                                            name='GroupName'
                                            value={formData.GroupName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <br></br>
                                <div >
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="IsActive"
                                            name="IsActive"
                                            checked={formData.IsActive}
                                            onChange={(event) =>
                                                setFormData({ ...formData, IsActive: event.target.checked })
                                            }
                                        />
                                        <span></span>
                                        Aktiv
                                    </label>
                                </div>
                                <br></br>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <button
                                        type="submit"
                                        className="btn btn-success float-end"
                                        style={{ width: "40%" }}
                                    >
                                        Ruaj
                                    </button>
                                    <button
                                        style={{ width: "40%" }}
                                        className="btn btn-secondary"
                                        onClick={() => setShowEditModal(false)}
                                    >
                                        Anulo
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* </StyledModal > */}
                </div>
            )}

            {/* Tabela per shfaqjen e te dhenave  */}
            <div className="header_flex">
                <div className="header_icon">
                    <AiFillAppstore style={{}} className="header_icons" />
                    <h3>Grupet e Artikujve</h3>
                    {/* <h3>Article Groups</h3> */}
                </div>
                <div>
                    <InsertData fetchData={fetchData} type={"text"} />
                </div>
            </div>
            <div className="header_paragraph">
                <p>Forma e Grupeve të Artikujve: Menaxhoni, shtoni, editoni dhe fshini grupe të Artikujve.</p>
            </div>
            <DataGridWithActions
                columns={ArticleGroupGridHeader}
                rows={rows}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                showPayment={false}
                height={500}
            />
        </div>
    );
}
