import React, { useState, useEffect, useRef } from "react";
import { Paper, TextareaAutosize } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/SalesDetailStyle.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { deleteAlert, errorAlert, handleSuccess } from '../../Components/Alerts';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MenuItem from '@mui/material/MenuItem';
import PrintContent from "../../Print/PrintContent";
import { Link, useHistory } from "react-router-dom";
import { FaTimes, FaTrash } from "react-icons/fa";
import { AiFillFileAdd } from "react-icons/ai";
import Select from 'react-select';
import InsertSupplier from "../Suppliers/InsertSuppliers";
import InsertArticle from "../Article/InsertArticle";
import DataGridWithActions from "../../Components/DataGridWithActions";
import { detailsData } from "./FletHyrjeDataGrid";
import Autocomplete from "@mui/material/Autocomplete";


function InsertEntrySheet(props) {
    const history = useHistory();

    const TranscationData = [
        {
            id: 1,
            type: "Vendore"
        },
        {
            id: 2,
            type: "Import"
        },
    ]
    const headers = authHeader();
    const email = LoginUserDetailed();
    const [logedEmail, setLogedEmail] = useState("");

    //Per insertimin e Transaction
    const [date, setDate] = useState("");
    const [coment, setComent] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [supplierID, setSupplierID] = useState("");
    const [transactionsType, setTransactionType] = useState(1);
    const [priceWithVAT, setPriceWithVAT] = useState(0);


    //Per insertimin ne Article History
    const [articleID, setArticleID] = useState("");
    const [qtyinput, setQtyInput] = useState(0);
    const [priceWithoutVAT, setPriceWithoutVAT] = useState(0);
    const [total, setTotal] = useState(0);
    const [vatRates, setVATRates] = useState(0);

    const [showDeleteTransactionID, setShowDeleteTransactionID] = useState(false);

    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteTransactionID, setDeleteTransactionID] = useState("");

    const [openDetails, setOpenDetails] = useState(false);


    const [data, setData] = useState([]);

    const [suppliers, setSuppliers] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);

    const [suppliersDetails, setSuppliersDetails] = useState([]);

    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const [valuedetails, setValueDetails] = useState([]);

    // Te dhenat per printim
    const [headerData, setHeaderData] = useState([]);
    const [companiesData, setCompaniesData] = useState([]);
    const [articlePrintDetails, setArticlePrintDetails] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [form1IsDisabled, setForm1IsDisabled] = useState(false);
    const [form2IsDisabled, setForm2IsDisabled] = useState(true);

    const handleEnable = () => {
        setForm1IsDisabled(true);
        setForm2IsDisabled(false);
    }
    // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo 
    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);
    // ky kod i ndryshon gjendjet e formave

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
        setArticleID();
        setQtyInput(0);
        setPriceWithoutVAT(0);
        setPriceWithVAT(0);
        setTotal(0);
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true); // I fsheh formën e parë
    };

    const newInvoice = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        setForm1IsDisabled(false);
        setForm2IsDisabled(true);
        setOpenDetails(false);
        setArticleID();
        setQtyInput(0);
        setPriceWithoutVAT(0);
        setPriceWithVAT(0);
        setTotal(0);
        setInvoiceNR("");
        setDate(formattedDate);
        setComent("");
        setTransactionType(1);
        setSupplierID("");
        setSupplierName("");
        setSuppliersDetails([]);
        setData([]);
        setValueDetails([]);
        setField(null);
        setAccfield(null);
    }


    const handleEditDetails = (item) => {
        handleOpenForm1();
        fetchArticlesSalesPrice(item.ArticleID, "getVat");
        setFormDetailData({
            HistoryID: item.HistoryID,
            ArticleID: item.ArticleID,
            QtyInput: item.QtyInput,
            Total: item.Total,
            PriceWithVAT: item.PriceWithVAT,
            PriceWithoutVAT: item.PriceWithoutVAT,
            ModifiedByUser: logedEmail,
            InvoiceNr: invoiceNR
        });
    };

    const clearEdit = () => {
        setFormDetailData({
            HistoryID: "",
            ArticleID: "",
            QtyInput: 0,
            PriceWithVAT: 0,
            PriceWithoutVAT: 0,
            setFormDetailData: 0
        });

        clearLine();
        handleOpenForm2();
    };


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'EntrySheet/entrysheetsdetails', { headers, params: { invNumber: invoiceNR } });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    //Kodi sherben per ta marr id e transaksionit qe sherben me pas per ta bere delete
    const fetchTransactionID = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'EntrySheet/GetTransactionID', { headers, params: { email: logedEmail, invNumber: invoiceNR } });
            setDeleteTransactionID(response.data);

            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (response.data.length === 0) {
                // Shfaqi një mesazh për përdoruesin
                // errorAlert("No data available.");

            }
        } catch (error) {
            console.log(error);
            // Shfaqi një mesazh për përdoruesin për gabimin e tjera
            // setMessage("Error: Failed to fetch data.");
        }
    };

    // i merr te dhenat nga databaza per te ja shfaqur vlerat userit 
    const fetchvaluedetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'EntrySheet/getvaluedetails', { headers, params: { invNumber: invoiceNR } });
            setValueDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const fetchHeaderData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'SalesInDetails/GetPrintHeaderDetails', { headers, params: { invNumber: invoiceNR, actionType: "ENS" } });
            setHeaderData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const fetchArticleDataPrint = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetArticleDetailsForPrint', { headers, params: { invNumber: invoiceNR, actionType: "ENS" } });
            setArticlePrintDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const fetchBanksAccount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/BankDetails', { headers });
            setBankAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'EntrySheet/oneline' + `/${deleteItemId}`, { headers, params: { email: logedEmail, invoiceNR: invoiceNR } }
            );
            deleteAlert("Fletë hyrja u fshi me sukses");

            fetchData();
            fetchvaluedetails();
            fetchHeaderData();
            fetchArticleDataPrint();
        } catch (error) {
            errorAlert("Fletë hyrja nuk mund te fshihet");
        }
    };

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDeleteTransactionID = async () => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'EntrySheet' + `/${deleteTransactionID}`, { headers }
            );
            setShowDeleteTransactionID(false);
            deleteAlert("Fletë hyrja u fshi me sukses");
            setSupplierID();
            history.push('/accounting/entrysheet'); // Zëvendëso 'your-desired-path' me path-in e dëshiruar
        } catch (error) {
            errorAlert("Fletë hyrja nuk mund te fshihet");
        }
    };

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 302 } });
            setSuppliers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const fetchSuppliersDetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByID', { headers, params: { id: supplierID } });
            setSuppliersDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article', { headers });
            setArticles(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const [buttonContent, setButtonContent] = useState({
        text: "Hap Detalet e Faturës",
        backgroundColor: "#466F86",
        transcation: "background-color 0.3s ease",
        onMouseEnter: "#6c7f8f",
        onMouseLeave: "#7a95a6"
    });
    const [invNumberExist, setInvNumberExist] = useState();
    const checkInvoiceNumber = async (invoiceNR) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'EntrySheet/invoiceExists', { headers, params: { invNumber: invoiceNR } });
            setInvNumberExist(response.data[0].InvoiceCount);
            if ((response.data[0].InvoiceCount) > 0) {
                setButtonContent({
                    text: "Ky numër i faturës ekziston",
                    backgroundColor: "red",
                    transcation: "background-color 0.3s red",
                    onMouseEnter: "red",
                    onMouseLeave: "red"
                });
            }
            else {
                setButtonContent({
                    text: "Hap Detalet e Faturës",
                    backgroundColor: "#466F86",
                    transcation: "background-color 0.3s ease",
                    onMouseEnter: "#6c7f8f",
                    onMouseLeave: "#7a95a6"
                });
            }

        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };
    const fetchArticlesSalesPrice = async (articleID, form) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetSalesPrice', { headers, params: { articleID: articleID } });
            const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja

            if (form == "edit") {
                const calculatedValue = parseFloat(response.data.PurchasePrice) * 100 / (100 + response.data.PurchasePrice);
                // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: response.data.PurchasePrice,
                    PriceWithoutVAT: calculatedValue,
                    QtyInput: 1,
                    Total: 1 * response.data.PurchasePrice
                }));
            }
            else if (form == "getVat") {

                // alert("get vat" + vatRates)
            }
            else {
                setPriceWithVAT(salesPriceData.PurchasePrice);
                setQtyInput(1);
                // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
                const calculatedValue = parseFloat(salesPriceData.PurchasePrice) * 100 / (100 + salesPriceData.VATRates);
                setPriceWithoutVAT(calculatedValue.toFixed(2));
                setTotal(1 * salesPriceData.PurchasePrice);
            }
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Ju lutem plotësoni numrin e faturës");
            return;
        }
        if (!date) {
            errorAlert("Ju lutem plotësoni daten!");
            return;
        }
        if (!supplierID) {
            errorAlert("Ju lutem plotësoni furnitorin!");
            return;
        }
        const stateDate = {
            Date: date,
            InvoiceNr: invoiceNR,
            SupplierID: supplierID,
            TransactionType: transactionsType,
            ActionType: 'ENS',
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'EntrySheet/invoicedetail', stateDate, { headers })
            .then(() => {
                handleSuccess("Fletë hyrja u krijua me sukses");
                handleEnable();
                setOpenDetails(true);
                fetchHeaderData();
                fetchTransactionID();
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        if (articleID == "" || articleID == null) {
            errorAlert("Ju lutem plotësoni artikullin");
            return;
        }
        if (qtyinput == "" || qtyinput == null) {
            errorAlert("Ju lutem plotësoni sasinë");
            return;
        }
        // Shtoni kontrollet këtu
        if (isNaN(qtyinput)) {
            errorAlert("Sasia e produkteve (qty input) duhet të jetë një numër.");
            return;
        }
        if (isNaN(priceWithoutVAT)) {
            errorAlert("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            return;
        }
        if (isNaN(priceWithVAT)) {
            errorAlert("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            return;
        }
        if (isNaN(total)) {
            errorAlert("Totali duhet të jetë një numër.");
            return;
        }
        const stateDate = {
            InvoiceNr: invoiceNR,
            ArticleID: articleID,
            QtyInput: qtyinput,
            PriceWithoutVAT: priceWithoutVAT,
            PriceWithVAT: priceWithVAT,
            CreatedByUser: email,
            Date: date,
            SupplierID: supplierID
        };
        axios.post(ApiLink.API_URL + 'EntrySheet/createnewline', stateDate, { headers, params: { vatRates: vatRates } })
            .then(() => {
                handleSuccess("Linja u shtua me sukses");
                setArticleID();
                setQtyInput(0);
                setPriceWithoutVAT(0);
                setPriceWithVAT(0);
                setTotal(0);
                fetchData();
                fetchvaluedetails();
                fetchHeaderData();
                fetchArticleDataPrint();
                setArticlesName("");
                setField(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const clearLine = () => {
        setArticleID();
        setQtyInput(0);
        setPriceWithoutVAT(0);
        setPriceWithVAT(0);
        setTotal(0);
        fetchData();
        fetchvaluedetails();
        fetchHeaderData();
        fetchArticleDataPrint();
        setArticlesName("");
    }

    // const [detailLine, setDetailLine] = useState([]); //i mban rreshtat e te dhenave detale 
    const [formDetailData, setFormDetailData] = useState({
        HistoryID: "",
        ArticleID: "",
        QtyInput: "",
        Total: "",
        PriceWithVAT: "",
        PriceWithoutVAT: "",
        ModifiedByUser: "",
        InvoiceNr: "",
    });

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDetail = (event) => {
        const { name, value } = event.target;
        setFormDetailData({ ...formDetailData, [name]: value });
    };
    const handleSubmitDetailLine = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'EntrySheet/updatedetailsArticle', formDetailData, { headers, params: { vatRates: vatRates } })
            .then((response) => {
                // fetchDetailLineData(invoiceNR);
                fetchvaluedetails();
                // updateAlert("Detail updated successfully");
                fetchArticleDataPrint();
                fetchHeaderData();
                fetchData();
                handleOpenForm2();
            })
            .catch((error) => {
                // console.log(error);
                errorAlert("Failed to update details")
            });
    }

    const handleCalculatePriceWithVAT = async (type) => {
        try {
            if (isNaN(priceWithoutVAT)) {
                throw new Error("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyinput)) {
                throw new Error("Sasia e produkteve (qty input) duhet të jetë një numër.");
            }
            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const vat = Number(formDetailData.PriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(formDetailData.PriceWithoutVAT) + vat;
                const total = Number(formDetailData.QtyInput) * calculatedPriceWithVAT;


                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: calculatedPriceWithVAT,
                    Total: total,
                }));

            } else {
                const vat = Number(priceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(priceWithoutVAT) + vat;
                const total = Number(qtyinput) * calculatedPriceWithVAT;
                // Veprimet specifike për shtim të ri
                setPriceWithVAT(calculatedPriceWithVAT);
                setTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    const handleCalculatePriceWithoutVAT = async (type) => {
        try {
            if (isNaN(priceWithVAT)) {
                throw new Error("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyinput)) {
                throw new Error("Sasia e produkteve (qty input) duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const calculatedPriceWithoutVAT = Number(formDetailData.PriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(formDetailData.QtyInput) * Number(formDetailData.PriceWithVAT);
                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                    Total: total,
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const calculatedPriceWithoutVAT = Number(priceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(qtyinput) * Number(priceWithVAT);

                setPriceWithoutVAT(calculatedPriceWithoutVAT);
                setTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    const handleCalculatePrices = async (type) => {
        try {
            if (isNaN(qtyinput)) {
                throw new Error("Sasia e produkteve (qty input) duhet të jetë një numër.");
            }

            if (isNaN(total)) {
                throw new Error("Totali duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const priceWithVAT = Number(formDetailData.Total) / Number(formDetailData.QtyInput);

                // const calculatedPriceWithoutVAT = formDetailData.PriceWithVAT * 100 / (100 + Number(vatRates));
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));

                // setTempPriceWithVAT(Number(formDetailData.PriceWithVAT) / (1 - (Number(formDetailData.DiscountPercent) / 100)))

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: priceWithVAT,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const priceWithVAT = Number(total) / Number(qtyinput);
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));

                setPriceWithoutVAT(calculatedPriceWithoutVAT);
                setPriceWithVAT(priceWithVAT);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };

    useEffect(() => {
        // Kontrollo nqs supplierID është ndryshuar para se të kryhen veprimet
        if (supplierID !== "") {
            fetchSuppliersDetails();
        }
    }, [supplierID]);


    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredSuppliers(
            suppliers.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, suppliers]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredArticles(
            articles.filter((data) =>
                data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, articles]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [supplierName, setSupplierName] = useState("");
    const handleFilterSupplier = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setSupplierID(selectedOption.AccountID);
            setSupplierName(selectedOption.label);
        } else {
            setSupplierID("");
            setSupplierName("");
        }
    };

    const [articlesName, setArticlesName] = useState("");
    const handleFilterArticles = (selectedOption) => {
        if (selectedOption && selectedOption.ArticleID) {
            setArticleID(selectedOption.ArticleID);
            setArticlesName(selectedOption.label);
            fetchArticlesSalesPrice(selectedOption.ArticleID);
        } else {
            setArticleID("");
            setArticlesName("");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'ArticleID':
                    nextField = document.getElementById('QtyInput');
                    break;
                case 'QtyInput':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithoutVAT':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithVAT':
                    nextField = document.getElementById('Total');
                    break;
                case 'Total':
                    if (showEditForm === true && showInserForm === false) {
                        handleSubmitDetailLine(e);
                    } else if (showInserForm === true && showEditForm === false) {
                        handleNewSubmit(e);
                    }
                    nextField = document.getElementById('ArticleID');
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select(); // Shtoni këtë rresht për të selektuar tekstin
            }
        }
    };

    const detailsRows = data.map((item, index) => ({
        id: ++index,
        article: item.ArticleName,
        qty: item.QtyInput,
        PNoVAT: item.PriceWithoutVAT,
        PVAT: item.PriceWithVAT,
        amountNoVAT: item.Amount,
        amountVAT: item.Total,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));



    //Pjesa per select input field supplier
    const [accField, setAccfield] = useState(null)
    const handleAutocompleteChangeSuppliers = (event, value) => {
        if (value && value.AccountID) {
            setSupplierID(value.AccountID);
            setAccfield(value);
        } else {
            // Në rast se vlera është pastruar
            setSupplierID(""); // Vendos vlerën e ArticleID në null
        }
    };

    const defaultPropsSuppliers = {
        options: suppliers,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                </li>
            </>
        ),
    };

    const [field, setField] = useState(null);
    const handleAutocompleteChangeArticle = (event, value) => {
        if (value && value.ArticleID) {
            setArticleID(value.ArticleID);
            fetchArticlesSalesPrice(value.ArticleID);
            setField(value);
        } else {
            // Në rast se vlera është pastruar
            setArticleID(""); // Vendos vlerën e ArticleID në null
            setField(null);
        }
    };

    const defaultPropsArticle = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        onMouseEnter={handleOpen}
                        onMouseLeave={handleClose}
                        style={{ marginLeft: 'auto' }}
                    >
                        Click
                    </button> */}
                </li>
            </>
        ),
    };

    ////////////////////////

    const handleAutocompleteChangeArticleEdit = (event, value) => {
        if (value) {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: value.ArticleID
            }));
            fetchArticlesSalesPrice(value.ArticleID, "edit");
        } else {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: ""
            }));
        }
    };

    const defaultPropsArticleEdit = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };

    useEffect(() => {
        fetchData();
        fetchSuppliers();
        fetchArticles();
        fetchHeaderData();
        fetchCompaniesData();
        fetchBanksAccount();
        fetchTransactionID();
    }, [logedEmail, invoiceNR, supplierID, articleID, deleteItemId]);

    return (
        <div className="SalesDetail__Container" style={{ paddingTop: "50px" }}>
            <Modal show={showDeleteTransactionID} onHide={() => setShowDeleteTransactionID(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Konfirmim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>A dëshironi ta fshini këtë fletë hyrje?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteTransactionID(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDeleteTransactionID}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <br></br>
            <div className="header_flex">
                <div className="header_icon">
                    <AiFillFileAdd style={{}} className="header_icons" />
                    <h3>Fletë hyrje</h3>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha te dhenat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className="SalesDetail__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <div>
                        <InsertSupplier fetchData={fetchSuppliers} type="insert" />
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <InsertArticle fetchData={fetchArticles} type="insert" />
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained" onClick={() => { newInvoice(); }}>
                                Ruaj & i ri
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <PrintContent details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyInput" actiontype="false" />
                    </div>
                    {/* <div style={{ marginLeft: "5px" }}>
                        <PDF details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyInput" actiontype="false" />
                    </div> */}
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained"
                                onClick={() => {
                                    setShowDeleteTransactionID(true);
                                }}
                            >
                                <FaTrash /> Fshij
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to="/accounting/entrysheet"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbylle
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2' style={{ display: "flex", justifyContent: "flex-end" }}>
            </div>
            <Paper>
                <form onSubmit={handleSubmit} className='header_paper'>
                    <Box
                        // component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {/* <p>Please fill in these fields first to proceed to the fields below</p> */}
                        <div className='SalesDetail__Container__Form'>
                            <div className="SalesDetail__Container__Form__div">
                                <div className="SalesDetail__Container__Form__div__1" style={{ marginTop: "13px" }}>
                                    <div className="SalesDetail__Container__Form__div__2">
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__1"
                                            required
                                            id="invoiceNR"
                                            label="Numri i Faturës"
                                            variant="outlined"
                                            type="text"
                                            name='InvoiceNr'
                                            value={invoiceNR}
                                            onChange={event => { setInvoiceNR(event.target.value); checkInvoiceNumber(event.target.value); }}
                                            disabled={form1IsDisabled}
                                            autoComplete="off"
                                        />
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__1"
                                            style={{ marginTop: "22px" }}
                                            id="id"
                                            select
                                            label="Përzgjedh Tipin e Transaksionit"
                                            defaultValue=""
                                            value={transactionsType}
                                            onChange={event => setTransactionType(event.target.value)}
                                            required
                                            disabled={form1IsDisabled}
                                        >
                                            {TranscationData.map((data) => (
                                                <MenuItem key={data.id} value={data.id}>{data.type}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextareaAutosize style={{ height: "55px", marginLeft: "10px", marginTop: "12px" }}
                                            className="SalesDetail__Container__Form__TextField__1"
                                            id="Comment"
                                            label="Komenti"
                                            type="text"
                                            name='Comment'
                                            value={coment}
                                            onChange={event => setComent(event.target.value)}
                                            variant="outlined"
                                            disabled={form1IsDisabled}
                                            autoComplete="off"

                                        />
                                    </div>
                                    <div className="SalesDetail__Container__Form__TextField__date__Payment">
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__date"
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            label="Data e Faturës"
                                            name='Date'
                                            value={date}
                                            // onChange={(e) => setDate(e.target.value)}
                                            onChange={event => { setDate(event.target.value); checkInvoiceNumber(invoiceNR); }}
                                            disabled={form1IsDisabled}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="SalesDetail__Container__Form__div__4">
                                <div style={{ paddingTop: "10px" }}>
                                    {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Furnitorin</span>
                                    <Select
                                        inputId="SupplierID"
                                        className="SalesDetail__Container__Form__TextField__selectList"
                                        value={{ label: supplierName, value: supplierID }}
                                        options={filteredSuppliers.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleFilterSupplier}
                                        isClearable
                                        placeholder="Select"
                                        noOptionsMessage={() => 'No matching'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "55px",
                                                marginLeft: "10px", marginRight: "10px"

                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",
                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        {...defaultPropsSuppliers}
                                        id="include-input-in-list"
                                        includeInputInList
                                        onChange={handleAutocompleteChangeSuppliers}
                                        value={accField}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "95%" }}
                                                {...params}
                                                label="Kerko Furnitorin"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ display: "flex", paddingTop: "15px" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__IDNumber"
                                        id="outlined-basic"
                                        label="Numri Identifikues"
                                        variant="outlined"
                                        name="IdentificationNumber"
                                        value={suppliersDetails.length > 0 ? suppliersDetails[0].IdentificationNumber : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__IDNumber"
                                        id="outlined-basic"
                                        label="Numri i TVSH-së"
                                        variant="outlined"
                                        name="VatNumber"
                                        value={suppliersDetails.length > 0 ? suppliersDetails[0].VATNumber : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>
                                <div>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__2"
                                        id="outlined-basic"
                                        label="Adresa"
                                        variant="outlined"
                                        name="Address"
                                        value={suppliersDetails.length > 0 ? suppliersDetails[0].Adress : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
                {
                    openDetails ?
                        <div className="SalesDetail__Container">
                            <div style={{ backgroundColor: "gainsboro " }}>
                                <hr></hr>
                                {showInserForm && (
                                    <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
                                        <Box
                                            // component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__Article">
                                                    {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh artikullin</span>
                                                    <Select
                                                        className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                        inputId="ArticleID"
                                                        value={{ label: articlesName, value: articleID }}
                                                        options={filteredArticles.map((data) => ({
                                                            label: data.ArticleName,
                                                            value: data.ArticleID,
                                                            ArticleID: data.ArticleID
                                                        }))}
                                                        onInputChange={handleInputChange}
                                                        onChange={handleFilterArticles}
                                                        isClearable
                                                        placeholder="Select"
                                                        noOptionsMessage={() => 'No matching'}
                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                height: "55px",
                                                                marginLeft: "10px",
                                                                marginRight: "12px",
                                                                backgroundColor: "transparent",
                                                                border: "darkgray solid 1px"
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: "#fff",
                                                                zIndex: 9999,
                                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                borderRadius: "4px",
                                                            }),
                                                            menuList: (provided) => ({
                                                                ...provided,
                                                                maxHeight: "200px",
                                                                overflowY: "auto",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: "#000",
                                                            }),
                                                        }}
                                                    /> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        {...defaultPropsArticle}
                                                        id="ArticleID"
                                                        sx={{ m: 1.5 }}

                                                        includeInputInList
                                                        value={field}
                                                        onChange={handleAutocompleteChangeArticle}
                                                        isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Kerko Artikullin"
                                                            // variant="filled"
                                                            // onKeyPress={handleKeyPress}
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details" style={{ paddingTop: "14px" }}>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="QtyInput"
                                                            type="text"
                                                            variant="outlined"
                                                            label="QTY Input"
                                                            name='QtyInput'
                                                            value={typeof qtyinput === 'number' ? qtyinput.toFixed(4) : qtyinput}
                                                            onChange={(e) => setQtyInput(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                            disabled={form2IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details" style={{ paddingTop: "14px" }}>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithoutVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price Without VAT"
                                                            name="PriceWithoutVAT"
                                                            value={typeof priceWithoutVAT === 'number' ? priceWithoutVAT.toFixed(4) : priceWithoutVAT}
                                                            onKeyUp={handleCalculatePriceWithVAT}
                                                            onChange={(e) => setPriceWithoutVAT(e.target.value)}
                                                            disabled={form2IsDisabled}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price With VAT"
                                                            name="PriceWithVAT"
                                                            value={typeof priceWithVAT === 'number' ? priceWithVAT.toFixed(4) : priceWithVAT}
                                                            onChange={(e) => setPriceWithVAT(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                            disabled={form2IsDisabled}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="Total"
                                                            label="Total"
                                                            variant="outlined"
                                                            name='Total'
                                                            value={typeof total === 'number' ? total.toFixed(2) : total}
                                                            onChange={event => setTotal(event.target.value)}
                                                            onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={form2IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="butonat">
                                                        <div>
                                                            <Button type="submit" variant="success" disabled={form2IsDisabled} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-plus-lg"></i>
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-x-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="responsive">
                                                        <div className="responsive__butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" disabled={form2IsDisabled} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="responsive">
                                                <button type="submit"
                                                    className="btn btn-primary float-end"
                                                    disabled={form2IsDisabled}
                                                >ADD</button>
                                            </div> */}
                                            </div>
                                        </Box>
                                    </form>
                                )}
                                {showEditForm && (
                                    <div className="SalesDetail__Container">
                                        <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                {/* <p style={{ color: "red", fontSize: "16px", fontStyle: "italic", borderBottom: "1px dashed red" }}>Click on a line to edit the data</p> */}
                                                <div className="SalesDetail__Container__Second__Form">
                                                    <div className="SalesDetail__Container__Second__Form__Article">
                                                        {/* <TextField
                                                            className="SalesDetail__Container__Form__TextField__Article"
                                                            id="ArticleID"
                                                            select
                                                            label="Select Article"
                                                            name="ArticleID"
                                                            value={formDetailData.ArticleID}
                                                            autoComplete="off"
                                                            // onChange={handleInputChangeDetail}
                                                            onChange={(event) => {
                                                                handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
                                                                fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
                                                            }}
                                                        // onKeyUp={handleCalculatePriceWithoutVATEdit}
                                                        >
                                                            {articles.map((data) => (
                                                                <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField> */}
                                                        <Autocomplete
                                                            fullWidth
                                                            sx={{ m: 1.5 }}
                                                            {...defaultPropsArticleEdit}
                                                            id="ArticleID"
                                                            includeInputInList
                                                            onChange={handleAutocompleteChangeArticleEdit}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    label="Artikulli"
                                                                // variant="filled" 
                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                style: {
                                                                    maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                                },
                                                            }}
                                                            value={articles.find(option => option.ArticleID === formDetailData.ArticleID) || null}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                type="text"
                                                                id="QtyInput"
                                                                name="QtyInput"
                                                                label="Sasia"
                                                                variant="outlined"
                                                                autoComplete="off"
                                                                value={formDetailData.QtyInput}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithoutVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Çmimi pa TVSH"
                                                                name="PriceWithoutVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithVAT("edit")}
                                                            // disabled={form2IsDisabled}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Çmimi me TVSH"
                                                                name="PriceWithVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithVAT === 'number' ? formDetailData.PriceWithVAT.toFixed(4) : formDetailData.PriceWithVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="Total"
                                                                label="Total"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="Total"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.Total === 'number' ? formDetailData.Total.toFixed(2) : formDetailData.Total}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePrices("edit")}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-check-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="responsive">
                                                            <div className="responsive__butonat">
                                                                <div>
                                                                    <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-check-lg"></i>
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-x-lg"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </form>
                                    </div>
                                )}
                                <DataGridWithActions
                                    columns={detailsData}
                                    rows={detailsRows}
                                    handleEdit={handleEditDetails}
                                    handleDelete={handleDelete}
                                    showPayment={false}
                                />
                            </div>
                            <br></br>
                            <div className="responsive__Tablet">
                                <div className='SalesDetail__Container__Form'>
                                    <div>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount18"
                                            // style={{ width: "100%" }}
                                            id="Amount18"
                                            label="Vlera 18%"
                                            variant="outlined"
                                            type="text"
                                            name='Amount18'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount18).toFixed(2) : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT18"
                                            // style={{ width: "100%" }}
                                            id="VAT18"
                                            label="TVSH 18%"
                                            variant="outlined"
                                            type="text"
                                            name='VAT18'
                                            value={valuedetails.length > 0 ? (valuedetails[0].VAT18).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount8"
                                            // style={{ width: "100%" }}
                                            id="Amount8"
                                            label="Vlera 8%"
                                            variant="outlined"
                                            type="text"
                                            name='Amount8'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount8).toFixed(2) : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT8"
                                            // style={{ width: "100%" }}
                                            id="VAT8"
                                            label="TVSH 8%"
                                            variant="outlined"
                                            type="text"
                                            name='VAT8'
                                            value={valuedetails.length > 0 ? (valuedetails[0].VAT8).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount0"
                                            // style={{ width: "100%" }}
                                            id="Amount0"
                                            label="Vlera 0%"
                                            variant="outlined"
                                            type="text"
                                            name='Amount0'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount0).toFixed() : ''}
                                            readOnly
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT0"
                                            // style={{ width: "100%" }}
                                            id="VAT0"
                                            label="Vlera para zbritjes"
                                            variant="outlined"
                                            type="text"
                                            name='VAT0'
                                            value={valuedetails.length > 0 ? (valuedetails[0].DiscountAmount + (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
                                            readOnly
                                        />
                                    </div>
                                    <br></br>
                                    {/* <div >
                                        <TextField
                                            style={{ width: "81%" }}
                                            id="DiscountAmount"
                                            label="Zbritja në vlerë"
                                            variant="outlined"
                                            type="text"
                                            name="DiscountAmount"
                                            value={discountAmount}
                                            onChange={(e) => { setDiscountAmount(e.target.value); calculatePercent(e.target.value); }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip title="Apliko Zbritjen" placement="right">
                                                            <IconButton type="submit"
                                                                onClick={constUpdateDiscountAmount}
                                                            >
                                                                <i class="bi bi-check-lg" style={{ color: "green" }}></i>

                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__TotalAmount"
                                            id="TotalAmount"
                                            label="Total pa TVSH"
                                            variant="outlined"
                                            type="text"
                                            name='TotalAmount'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toFixed(2) : ''}
                                            readOnly
                                        />
                                    </div> */}
                                    <br></br>
                                    <div >
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VATTotal"
                                            // style={{ width: "100%" }}
                                            id="VATTotal"
                                            label="Total TVSH"
                                            variant="outlined"
                                            type="text"
                                            name='VATTotal'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
                                            readOnly
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Total"
                                            // style={{ width: "100%" }}
                                            id="Totali"
                                            label="Për pagesë"
                                            variant="outlined"
                                            type="text"
                                            name='Totali'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Total).toFixed(2) : ''}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }} onHide>
                                <button
                                    className="button-15"
                                    variant="contained"
                                    type="submit"
                                    style={{
                                        background: buttonContent.backgroundColor,
                                        width: "95%",
                                        height: "55px",
                                        alignContent: "center",
                                        transition: buttonContent.transcation,
                                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                        color: "white",
                                        border: "none"
                                    }}
                                    disabled={form1IsDisabled}
                                    // onClick={handleSubmit}
                                    onClick={invNumberExist > 0 ? null : handleSubmit}
                                    onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = buttonContent.onMouseEnter;
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = buttonContent.onMouseLeave;
                                    }}
                                >
                                    {buttonContent.text}
                                </button>
                            </div>
                        </>

                }
                <br></br>
            </Paper>
        </div>
    );
}

export default InsertEntrySheet;