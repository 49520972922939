import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import InsertData from "./InsertArticle";
import { deleteAlert, errorAlert, handleSuccess, updateAlert } from "../../Components/Alerts";
import authHeader from "../../Auth/authHeader";
import { ApiLink } from "../../APILink/ApiLink";
import { AiOutlineBarcode } from "react-icons/ai";
import "jspdf-autotable";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ArticleGridHeader } from "./ArticleGridHeader";
import DataGridWithActions from "../../Components/DataGridWithActions";


export default function Article() {
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        ArticleID: "",
        Barcode: "",
        ArticleName: "",
        Description: "",
        GroupID: "",
        UnitID: "",
        LedgerID: "",
        VAT: "",
        PurchasePrice: "",
        SalesPrice: "",
        IsActive: false,
        TouchScreen: false
    });

    const [showEditModal, setShowEditModal] = useState(false);

    const [group, setGroup] = useState([]);
    const [unit, setUnit] = useState([]);
    const [ledger, setLedger] = useState([]);
    const [vat, setVat] = useState([]);

    const [barcodeExist, setBarcodeExsit] = useState("");
    const [msgBarcode, setMsgBarcode] = useState("");
    const [articleExist, setArticleExist] = useState("");
    const [msgArticle, setMsgArticle] = useState("");

    const [purchaseNoVAT, setPurchaseNoVAT] = useState(0);
    const [salesNoVAT, setSalesNoVAT] = useState(0);
    const [valueProfit, setValueProfit] = useState(0);
    const [percentProfit, setPercentProfit] = useState(0);
    const [vatPercent, setVatPercent] = useState(0);

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'article', { headers });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const headers = authHeader();
    const email = LoginUserDetailed(); // replace with your implementation of LoginUserDetailed()
    // const encodedEmail = encodeURIComponent(email);


    // //Kodi per fshirjen e nje elementi ne databaz 
    // const handleDelete = async (deleteItemId) => {
    //     try {
    //         const response = await axios.delete(
    //             ApiLink.API_URL + 'article' + `/${deleteItemId}`, { headers }
    //         );
    //         setShowDeleteConfirmation(false);
    //         deleteAlert("This Article Deleted Successfully");
    //         fetchData();
    //     } catch (error) {
    //         deleteAlert("You can not delete this article");
    //     }
    // };

    const handleDelete = async (deleteItemId) => {
        try {
            const response = await axios.delete(
                ApiLink.API_URL + 'article' + `/${deleteItemId}`, { headers }
            );

            const result = response.data;
            if (result.success) {
                handleSuccess("Artikulli është fshirë me sukses"); // Shfaq mesazhin e suksesit
                fetchData();
            } else {
                deleteAlert("Ky artikull nuk mund të fshihet sepse është përdorur në transaksione"); // Shfaq mesazhin e gabimit
            }
        } catch (error) {
            deleteAlert("An error occurred while deleting the article."); // Mesazh për gabime të tjera
        }
    };


    //Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        const selectedVAT = vat.find(v => v.VATID === item.VAT); // Gjej VAT ku VATID barazohet me VAT në item

        const vatPercent = selectedVAT ? selectedVAT.VATPercent : null; // Merr VATPercent nëse ekziston
        setVatPercent(vatPercent)
        setFormData({
            ArticleID: item.ArticleID,
            Barcode: item.Barcode,
            ArticleName: item.ArticleName,
            Description: item.Description,
            GroupID: item.GroupID,
            UnitID: item.UnitID,
            LedgerID: item.LedgerID,
            VAT: item.VAT,
            PurchasePrice: item.PurchasePrice,
            SalesPrice: item.SalesPrice,
            IsActive: item.IsActive,
            TouchScreen: item.TouchScreen,
            ModifiedByUser: email
        });

        setShowEditModal(true);
        calcPriceFromPriceWithVAT(item.PurchasePrice, item.SalesPrice, vatPercent)
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (barcodeExist > 0) {
            setMsgBarcode("This barcode is used");
            return;
        }
        else {
            setMsgBarcode("");
        }
        if (articleExist > 0) {
            setMsgArticle("This article name is used")
            return;
        }
        else {
            setMsgArticle("");
        }
        axios
            .put(ApiLink.API_URL + 'article', formData, { headers })
            .then((response) => {
                // console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("Article updated successfully");
            })
            .catch((error) => {
                // console.log(error);
                errorAlert("Failed to update article")
            });
    };

    const fetchArticleGroups = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'articlegroup', { headers });
            setGroup(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const fetchArticleUnits = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'articleunits', { headers });
            setUnit(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    //Kodi per shfaqjen e Ledgers ne Select List 

    useEffect(() => {
        axios.get(ApiLink.API_URL + 'ledger', { headers })
            .then((response) => {
                setLedger(response.data);
            })
            .catch((error) => {
                errorAlert.error(error);
            });
    }, []);

    const fetchVatRates = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'vatrates', { headers });
            setVat(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const checkBarcode = async (barcode) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/checkBarcode', { headers, params: { barcode: barcode } });
            setBarcodeExsit(response.data[0].Barcode)
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const checkArticleName = async (articleName) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/chectArticleName', { headers, params: { articleName: articleName } });
            setArticleExist(response.data[0].ArticleName)
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const calcPriceWith = (price, vatPercent, hasVAT) => {
        if (hasVAT === "withVAT") {
            return (Number(price) * Number(100)) / (Number(100) + Number(vatPercent));
        }
        else if (hasVAT === "noVAT") {
            return (Number(price) * (Number(1) + (Number(vatPercent) / Number(100))));
        }
    }

    const calcPriceFromPriceWithVAT = async (purchaseWithVAT, salesWithVAT, vatPercent) => {
        try {
            //Gjeje qmimin e blerjes pa tvsh
            const purchaseNoVAT = calcPriceWith(purchaseWithVAT, vatPercent, "withVAT")
            setPurchaseNoVAT(purchaseNoVAT);

            //Gjej qmimin e shitjes pa tvsh
            const salesNoVAT = calcPriceWith(salesWithVAT, vatPercent, "withVAT");
            setSalesNoVAT(salesNoVAT);

            //gjeje profitin ne vler
            const profitiNeVlerPaTVSH = Number(salesNoVAT) - Number(purchaseNoVAT)
            setValueProfit(profitiNeVlerPaTVSH);

            //gjeje profitin ne perqindje
            const profitiVlerMeTVSH = Number(salesWithVAT) / Number(purchaseWithVAT);
            setPercentProfit((Number(profitiVlerMeTVSH) - Number(1)) * Number(100));

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    //Kalkuloj qmimet, nese useri e shenon qmimin e blerjes pa tvsh, ose nese useri
    // e shenon qmimin pa tvsh
    const calcPriceFromPriceWithoutVAT = async (price, vat, priceType) => {
        try {
            //Keto dy let e mban qmimin blerjes me tvsh
            if (priceType === "purchaseWithoutVAT") {
                const priceWithVAT = (calcPriceWith(price, vat, "noVAT"));
                setFormData(prevState => ({
                    ...prevState,
                    PurchasePrice: priceWithVAT
                }));
            }
            else if (priceType === "salesWithoutVAT") {
                const salesWithVAT = calcPriceWith(price, vat, "noVAT");
                setFormData(prevState => ({
                    ...prevState,
                    SalesPrice: salesWithVAT
                }));

                const profitiNeVlerPaTVSH = Number(price) - Number(purchaseNoVAT)
                setValueProfit(profitiNeVlerPaTVSH);

                const profitiVlerMeTVSH = Number(salesWithVAT) / Number(formData.PurchasePrice);
                setPercentProfit((Number(profitiVlerMeTVSH) - Number(1)) * Number(100));

            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const KalkulimiCmimitNgaProfitiNgaVlera = (valueProfit, priceWithoutVAT, vatPercent) => {
        // Calculate the selling price without VAT
        const cmimiShitjesPaTVSH = Number(priceWithoutVAT) + Number(valueProfit);
        setSalesNoVAT(cmimiShitjesPaTVSH)

        // Calculate the profit percentage
        const profitiNePerqindje = (Number(valueProfit) / Number(priceWithoutVAT)) * Number(100);
        setPercentProfit(profitiNePerqindje);

        // Calculate VAT and the selling price with VAT
        const TVSH = Number(cmimiShitjesPaTVSH) * (Number(vatPercent) / Number(100));
        const cmimiShitjesMeTVSH = Number(cmimiShitjesPaTVSH) + Number(TVSH);
        setFormData(prevState => ({
            ...prevState,
            SalesPrice: cmimiShitjesMeTVSH
        }));
    }

    function KalkulimiCmimitNgaProfitiNgaPerqindja(profitPercent, purchasePriceWithoutVAT, vatPercent) {
        // Kalkulo fitimin në përqindje
        const fitimiPerqindje = Number(purchasePriceWithoutVAT) * (Number(profitPercent) / Number(100));
        const cmimiShitjesPaTVSH = Number(purchasePriceWithoutVAT) + Number(fitimiPerqindje);
        setSalesNoVAT(cmimiShitjesPaTVSH);

        // Kalkulo TVSH-në dhe çmimin e shitjes me TVSH
        const TVSH = Number(cmimiShitjesPaTVSH) * (Number(vatPercent) / Number(100));
        const cmimiShitjesMeTVSH = Number(cmimiShitjesPaTVSH) + Number(TVSH);
        setFormData(prevState => ({
            ...prevState,
            SalesPrice: cmimiShitjesMeTVSH
        }));
        // Kalkulo fitimin në vlerë
        const profitiNeVlere = Number(cmimiShitjesPaTVSH) - Number(purchasePriceWithoutVAT);
        setValueProfit(profitiNeVlere);
    }

    const rows = data.map((item, index) => ({
        id: ++index,
        barcode: item.Barcode,
        name: item.ArticleName,
        purchasePrice: item.PurchasePrice,
        salesPrice: item.SalesPrice,
        active: item.IsActive,
        pos: item.TouchScreen,
        deleteItemId: item.ArticleID,
        rowInfo: item
    }));

    useEffect(() => {
        fetchData();
        fetchArticleGroups();
        fetchArticleUnits();
        fetchVatRates();
    }, []);

    return (
        <div className="Crud__Container">
            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div class="edit-modal-wrapper">
                    <div className="edit-modal">
                        <form onSubmit={handleSubmit} className="EditModal__Container__Form" >
                            <div className='EditModal__Container__Form__Container2'>
                                <div className='EditModal__Container__Form__Container2__firstInput'>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField
                                            className="EditModal__Container__Form__Container2__field__InputField"
                                            required
                                            id="Barcode"
                                            label="Barkodi"
                                            variant="outlined"
                                            type="text"
                                            name='Barcode'
                                            value={formData.Barcode}
                                            onChange={(event) => { handleInputChange(event); checkBarcode(event.target.value); }}
                                        />
                                        <span style={{ color: "red" }}>{msgBarcode && `${msgBarcode}`}</span>
                                    </div>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField
                                            className="EditModal__Container__Form__Container2__field__InputField"
                                            required
                                            id="ArticleName"
                                            label="Emri i Artikullit"
                                            variant="outlined"
                                            type="text"
                                            name='ArticleName'
                                            value={formData.ArticleName}
                                            onChange={(event) => { handleInputChange(event); checkArticleName(event.target.value); }}
                                        />
                                        <span style={{ color: "red" }}>{msgArticle && `${msgArticle}`}</span>
                                    </div>
                                </div>
                                <br></br>
                                <div className='EditModal__Container__Form__Container2__field__description'>
                                    <TextareaAutosize style={{ height: "100px" }}
                                        className="EditModal__Container__Form__Container2__field__descriptionArea"
                                        id="Description"
                                        label="Përshkrimi"
                                        variant="outlined"
                                        placeholder='Description'
                                        type="text"
                                        name='Description'
                                        value={formData.Description}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='EditModal__Container__Form__Container2__selects'>
                                    <div className="EditModal__Container__Form__Container2__selects__textfield_button">
                                        <div className="EditModal__Container__Form__Container2__selects__textfield_button__txt">
                                            <TextField
                                                className="EditModal__Container__Form__Container2__selects__textfield_button__InsertField"
                                                id="GroupID"
                                                name="GroupID"
                                                select
                                                label="Përzgjedh Grupin"
                                                defaultValue=""
                                                value={formData.GroupID}
                                                onChange={handleInputChange}
                                            >
                                                {group && group.map((data) => (
                                                    <MenuItem key={data.GroupID} value={data.GroupID}>{data.GroupName}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        {/* <div className="EditModal__Container__Form__Container2__selects__textfield_button__btn">
                                            <Button href="#outlined-buttons" className="EditModal__Container__Form__Container2__selects__textfield_button__btn__btn">
                                                <InsertArticleGroup fetchData={fetchArticleGroups} type={"btn"} />
                                            </Button>
                                        </div> */}
                                    </div>

                                    <div className="EditModal__Container__Form__Container2__selects__textfield_button">
                                        <div className="EditModal__Container__Form__Container2__selects__textfield_button__txt">
                                            <TextField
                                                className="EditModal__Container__Form__Container2__selects__textfield_button__InsertField"
                                                id="UnitID"
                                                name="UnitID"
                                                select
                                                label="Përzgjedh Njesinë Matëse"
                                                defaultValue=""
                                                // helperText="Please select expense"
                                                value={formData.UnitID}
                                                onChange={handleInputChange}
                                            >
                                                {unit && unit.map((data) => (
                                                    <MenuItem key={data.UnitID} value={data.UnitID}>{data.UnitName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        {/* <div className="EditModal__Container__Form__Container2__selects__textfield_button__btn" >
                                            <Button href="#outlined-buttons" className="EditModal__Container__Form__Container2__selects__textfield_button__btn__btn">
                                                <InsertArticleUnit fetchData={fetchArticleUnits} type={"btn"} />
                                            </Button>
                                        </div> */}
                                    </div>
                                </div>

                                <div className='EditModal__Container__Form__Container2__selects'>
                                    <div className="EditModal__Container__Form__Container2__selects__other">
                                        <div className="EditModal__Container__Form__Container2__selects__other__data">
                                            <TextField
                                                className="EditModal__Container__Form__Container2__selects__other__data__Ledger"
                                                id="LedgerID"
                                                name="LedgerID"
                                                select
                                                label="Përzgjedh Llogarinë kontabël"
                                                defaultValue=""
                                                // helperText="Please select expense"
                                                value={formData.LedgerID}
                                                onChange={handleInputChange}
                                            >
                                                {ledger && ledger.map((ledgers) => (
                                                    <MenuItem key={ledgers.LedgerID} value={ledgers.LedgerID}>{ledgers.LedgerNameEN}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className="EditModal__Container__Form__Container2__selects__other__data">
                                            <TextField
                                                className="EditModal__Container__Form__Container2__selects__other__data__Ledger"
                                                id="VATID"
                                                name="VAT"
                                                select
                                                label="Përzgjedh normën e TVSH-së"
                                                defaultValue=""
                                                // helperText="Please select expense"
                                                value={formData.VAT}
                                                onChange={handleInputChange}
                                            >
                                                {vat.map((vat) => (
                                                    <MenuItem key={vat.VATID} value={vat.VATID}>{vat.VATPercent}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                </div>

                                <div className='EditModal__Container__Form__Container2__firstInput'>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField style={{ width: "100%" }}
                                            id="PurchasePriceNoVAT"
                                            label="Cmimi i Blerjes pa TVSH"
                                            variant="outlined"
                                            name='PurchasePriceNoVAT'
                                            value={purchaseNoVAT !== null ? purchaseNoVAT.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={event => setPurchaseNoVAT(event.target.value)}
                                            onKeyUp={event => calcPriceFromPriceWithoutVAT(event.target.value, vatPercent, "purchaseWithoutVAT")}

                                        />
                                    </div>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField style={{ width: "100%" }}
                                            id="PurchasePrice"
                                            label="Cmimi i Blerjes me TVSH"
                                            variant="outlined"
                                            name='PurchasePrice'
                                            value={formData.PurchasePrice !== null && formData.PurchasePrice !== 0 ? (formData.PurchasePrice).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            // value={formData.PurchasePrice}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <br></br>
                                <div className='EditModal__Container__Form__Container2__firstInput'>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField style={{ width: "100%" }}
                                            id="SalesPriceMoVAT"
                                            label="Cmimi i shitjës Pa TVSH"
                                            variant="outlined"
                                            name='SalesPriceMoVAT'
                                            value={salesNoVAT !== null && salesNoVAT !== 0 ? salesNoVAT.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={event => setSalesNoVAT(event.target.value)}
                                            onKeyUp={event => calcPriceFromPriceWithoutVAT(event.target.value, vatPercent, "salesWithoutVAT")}
                                        />
                                    </div>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField style={{ width: "100%" }}
                                            id="SalesPrice"
                                            label="Cmimi i Shitjes me TVSH"
                                            variant="outlined"
                                            name='SalesPrice'
                                            value={formData.SalesPrice !== null && formData.SalesPrice !== 0 ? (formData.SalesPrice).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            // value={formData.SalesPrice}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <br></br>
                                <div className='EditModal__Container__Form__Container2__firstInput'>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField style={{ width: "100%" }}
                                            id="ValueProfit"
                                            label="Profiti në vlerë"
                                            variant="outlined"
                                            name='ValueProfit'
                                            value={valueProfit !== null && valueProfit !== 0 ? valueProfit.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={event => setValueProfit(event.target.value)}
                                            onKeyUp={event => KalkulimiCmimitNgaProfitiNgaVlera(event.target.value, purchaseNoVAT, vatPercent)}

                                        />
                                    </div>
                                    <div className='EditModal__Container__Form__Container2__field'>
                                        <TextField style={{ width: "100%" }}
                                            id="PercentProfit"
                                            label="Profiti në perqindje"
                                            variant="outlined"
                                            name='PercentProfit'
                                            value={percentProfit !== null && percentProfit !== 0 ? percentProfit.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={event => setPercentProfit(event.target.value)}
                                            onKeyUp={event => KalkulimiCmimitNgaProfitiNgaPerqindja(event.target.value, purchaseNoVAT, vatPercent)}

                                        />
                                    </div>
                                </div>
                                <div className='EditModal__Container__Form__Container2__buttons'>
                                    <label className='EditModal__Container__Form__Container2__checkbox-label'>
                                        <input className='EditModal__Container__Form__Container2__checkbox-input'
                                            type="checkbox"
                                            id="IsActive"
                                            name="IsActive"
                                            checked={formData.IsActive}
                                            onChange={(event) =>
                                                setFormData({ ...formData, IsActive: event.target.checked })
                                            }
                                        />
                                        <span className='EditModal__Container__Form__Container2__checkbox-custom'></span>
                                        Artikull Aktiv 
                                    </label>
                                    <label className='EditModal__Container__Form__Container2__checkbox-label'>
                                        <input className='EditModal__Container__Form__Container2__checkbox-input'
                                            type="checkbox"
                                            id="TouchScreen"
                                            name="TouchScreen"
                                            checked={formData.TouchScreen}
                                            onChange={(event) =>
                                                setFormData({ ...formData, TouchScreen: event.target.checked })
                                            }
                                        />
                                        <span className='EditModal__Container__Form__Container2__checkbox-custom'></span>
                                        Shfaq ne POS
                                    </label>
                                    <div className='EditModal__Container__Form__Container2__save-button'>
                                        <button
                                            type="submit"
                                            className="btn btn-success float-end"
                                            style={{ width: "120px" }}
                                        >
                                            Ruaj
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => setShowEditModal(false)}
                                        >
                                            Anulo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div >
                </div>
            )
            }

            {/* Tabela per shfaqjen e te dhenave  */}
            <div className="header_flex">
                <div className="header_icon">
                    <AiOutlineBarcode style={{}} className="header_icons" />
                    <h3>Lista e Artikujve</h3>
                    {/* <h3>Article List</h3> */}
                </div>
                <div>
                    <InsertData fetchData={fetchData} type={"text"} />
                </div>
            </div>
            <div className="header_paragraph">
                <p>Forma e Artikujve: Menaxhoni, shtoni, editoni dhe fshini artikuj.</p>
            </div>

            <DataGridWithActions
                columns={ArticleGridHeader}
                rows={rows}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                showPayment={false}
                height={500}
            />
        </div >
    );
}