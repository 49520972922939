import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { InsertForm } from '../../Components/Forms';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/__button.scss'

function InsertVat(props) {
  const [vName, setVName] = useState("");
  const [vPercent, setVPercent] = useState("");
  const [isActive, setIsActive] = useState(true);

  //Ketu deklarphen te gjitha atributet qe duhet te shtohen ne Input 
  //formen per tu insertuar nje element ne databaz
  const VatRatesAtt = [
    {
      id: 1,
      name: 'VATName',
      type: 'text',
      value: vName,
      event: setVName
    },
    {
      id: 2,
      name: 'VATPercent',
      type: 'number',
      value: vPercent,
      event: setVPercent
    },
  ]

  const email= LoginUserDetailed();
  const headers = authHeader(); // thirrja e funksionit authHeader()



  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const vatData = {
  //     VATName: vName,
  //     VATPercent: vPercent,
  //     CompanyID: 123456789,
  //     IsActive: isActive,
  //   };
  //   axios.post(ApiLink.API_URL + 'vatrates', vatData)
  //     .then((response) => {
  //       console.log(response.data);
  //       handleSuccess("Vat Rates Added Successfully");
  //       setVName("");
  //       setVPercent("");
  //       setIsActive(true);
  //       props.onHide();
  //       props.refreshData(); // Thirrja e funksionit per refresh te dhenave

  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       if (error.response && error.response.status === 409) {
  //         errorAlert(error.response.data);
  //       } else {
  //         errorAlert("An unknown error occurred while processing the request.");
  //       }
  //     });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const vatData = {
      VATName: vName,
      VATPercent: vPercent,
      CreatedByUser:email,
      IsActive: isActive,
    };
    axios.post(ApiLink.API_URL + 'vatrates', vatData, { headers }) // shtimi i headers në kërkesën axios.post()
      .then((response) => {
        console.log(response.data);
        handleSuccess("Vat Rates Added Successfully");
        setVName("");
        setVPercent("");
        setIsActive(true);
        props.onHide();
        props.refreshData(); // Thirrja e funksionit per refresh te dhenave

      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 409) {
          errorAlert(error.response.data);
        } else {
          errorAlert("An unknown error occurred while processing the request.");
        }
      });
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
          Insert Vat Rates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div >

          {/* datalink=>konstantja e krijuar me lart me te dhenat e nevojshme
          per input formen
          onSubmit=>Dergohet funksioni i krijuar me lart per insertimin e te dhenave
          Checked dhe Onchange=>Duhet te jene gjithmon te njejta */}
          <InsertForm
            datalink={VatRatesAtt}
            onSubmit={handleSubmit}
            checked={isActive}
            onChange={(event) => setIsActive(event.target.checked)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function InsertVAT(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const handleRefreshData = () => {
    props.fetchData(); // Thirrja e funksionit per refresh te dhenave
  };

  return (
    <>
      <button className="button-68" onClick={() => setModalShow(true)}>
      <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Add New Vat Rates
      </button>

      <InsertVat
        show={modalShow}
        onHide={() => setModalShow(false)}
        refreshData={handleRefreshData}
      />
    </>
  );
}
