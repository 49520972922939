const currencyFormatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const MonthlyExpenseClassReportHeader = [
    { field: 'id', headerName: '#', width: 0, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true},
    { field: 'accname', headerName: 'Klasifikimi i Shpenzimit', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'trs', headerName: 'Transaksione', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    // { field: 'client', headerName: 'Furnitori', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'amount0', headerName: 'Vlera me 0%', type: 'string', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'amount8', headerName: 'Vlera me 8%', type: 'string', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'amount18', headerName: 'Vlera me 18%', type: 'string', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'totalamount', headerName: 'Vlera Pa TVSH', type: 'string', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

