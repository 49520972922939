import React, { useEffect, useState } from "react";
import logo from "../Images/only_logo.png";
import text from "../Images/only_slogan.png";
import MenuItem from "./MenuItem";
import { AiOutlineLeftCircle, AiOutlineRightCircle, AiOutlineBarChart } from "react-icons/ai"
import { MdOutlineFormatIndentDecrease, MdOutlineFormatIndentIncrease, MdOutlineMenuOpen } from "react-icons/md"
// import { claim } from "../Auth/auth.model.d";
import jwtDecode from "jwt-decode";
import * as RiIcons from 'react-icons/ri';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import { getToken } from "../Auth/HandleJWT";
import { Link } from "react-router-dom";





export const menuItems = [

  // name: "emri qe do te shfaqet ne sidemenu",
  // exact: true or false ,
  // to: "/linku ",
  // iconClassName: "ikona",



  {
    name: "Paneli",
    exact: true,
    to: "#",
    to: "/dashboard",
    role: "admin",
    iconClassName: "bi bi-speedometer",
  },
]
export const menuItemss = [
  {
    // name: "Articles",
    name: "Artikujt",
    exact: true,
    to: "#",
    role: 'admin',
    iconClassName: "bi bi-upc-scan",
    subMenus: [
      {
        name: "Lista Artikujve",
        to: "/articles/articleslist",
        role: "admin",
      },
      {
        // name: "Groups",
        name: "Grupet",
        to: "/articles/articlegroups",
        role: "staf",

      },
      {
        // name: "Units",
        name: "Njësitë",
        to: "/articles/articleunits",
        role: "staf",

      },
    ],
  },


  {
    // name: "Accounts",
    name: "Llogaritë",
    exact: true,
    to: "#",
    role: 'admin',
    iconClassName: "bi bi-person-lines-fill",
    subMenus: [
      {
        // name: "Clients",
        name: "Klientët",
        to: "/accounts/clients"
      },

      {
        // name: "Suppliers",
        name: "Furnitorët",
        to: "/accounts/suppliers"
      },
      {
        // name: "Cash Registers",
        name: "Arkat",
        to: "/accounts/cashregisters"
      },
      {
        // name: "Banks",
        name: "Bankat",
        to: "/accounts/banks"
      },
      {
        // name: "Owners",
        name: "Pronarët",
        to: "/accounts/owners"
      },
      {
        // name: "Employees",
        name: "Punonjësit",
        to: "/accounts/employees"
      },
      {
        // name: "The tenants",
        name: "Qiradhënësit",
        to: "/accounts/thetenants"
      },
      {
        // name: "Expences Category",
        name: "Klasifikimi Shpënzimeve",
        to: "/accounts/expences"
      },
      {
        // name: "Long Term Assets",
        name: "Asetet",
        to: "/accounts/longtermassets"
      },
      {
        // name: "Others",
        name: "Llogari Tjera",
        to: "/accounts/others"
      },
    ],
  },
  {
    name: "Blerjet",
    exact: true,
    to: "#",
    role: 'user',
    iconClassName: "bi bi-bag-check-fill",
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subMenus: [
      {
        // name: "Purchase Detaile",
        name: "Blerjet Detale",
        to: "/purchases/detailpurchase"
      },
      {
        // name: "Purchase In Total",
        name: "Blerjet Totale",
        to: "/purchases/purchaseintotal"
      },
      {
        // name: "Investment Purchases",
        name: "Blerjet Investive",
        to: "/purchases/investmentpurchases"
      },
      {
        name: "Importet",
        to: "/purchases/import"
      },
    ]
  },

  {
    // name: "Expences",
    name: "Shpenzimet",
    exact: true,
    to: "#",
    role: 'user',
    iconClassName: "bi bi-subtract  ",
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subMenus: [
      {
        // name: "Expense Purchases",
        name: "Shpenzimet",
        to: "/expences/expensepurchases"
      },
      {
        // name: "Rent",
        name: "Qiraja",
        to: "/expences/rent"
      },
      {
        // name: "Wages",
        name: "Pagat",
        to: "/expences/wages"
      },
    ]
  },
  ///////////////////////////////////////////////////////////////////
  {
    name: "Shitjet",
    exact: true,
    to: "#",
    role: 'user',
    iconClassName: "bi bi-cart-check-fill",
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subMenus: [
      {
        // name: "Sales in details",
        name: "Shitjet Detale",
        to: "/Sales/salesindetails"
      },
      {
        name: "P.O.S.",
        to: "/Sales/pos"
      },
      {
        // name: "Sales in total",
        name: "Shitjet Totale",
        to: "/Sales/salesintotal"
      },
      {
        // name: "Advance",
        name: "Avancet",
        to: "/Sales/advance"
      },
      {
        // name: "Sale with Z-Raport",
        name: "Shitjet me Z-Raport",
        to: "/Sales/Z-salesdetail"
      },
      {
        name: "Pro Faturat",
        to: "/Sales/proInvoice"
      },
      {
        name: "Ofertat",
        to: "/Sales/offer"
      },
    ]
  },

  ///////////////////////////////////////////////////////
  {
    name: "Debit & Kredit Nota",
    exact: true,
    to: "#",
    role: 'user',
    iconClassName: "bi bi-stickies-fill",
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subMenus: [
      {
        name: "Notat Debitore",
        to: "/Sales/debitnote"
      },
      {
        name: "Notat Kreditore",
        to: "/Sales/creditnote"
      },
    ]
  },
  //////////////////////////////////////////////////////////////////
  {
    name: "Kontabiliteti",
    exact: true,
    to: "#",
    iconClassName: "bi bi-stack",
    subMenus: [
      {
        name: "Fletat Kontabël",
        to: "/accounting/accountingsheets"
      },
      {
        name: "Fletë Hyrjet",
        to: "/accounting/entrysheet"
      },
      {
        name: "Fletë Daljet",
        to: "/accounting/exitsheet"
      },
      {
        name: "Kopërtinat",
        to: "/configuration/coverpage"
      },
    ]
  },
  ////////////////////////////////////////////////////////////////////
  {
    // name: "Money",
    name: "Paraja",
    exact: true,
    to: "#",
    iconClassName: "bi bi-cash-coin",
    subMenus: [
      {
        // name: "Receivables",
        name: "Arkëtimet",
        to: "/money/receivables"
      },
      {
        // name: "Payments",
        name: "Pagesat",
        to: "/money/payments"
      },
      {
        // name: "Withdrawal",
        name: "Tërheqjet",
        to: "/money/withdrawal"
      },
      {
        // name: "Transfers",
        name: "Transferet/Urdhëresat",
        to: "/money/transfers"
      },
    ]
  },

  ////////////////////////////////////////////////////////////////////
  {
    // name: "Accounting Books",
    name: "Librat Kontabël",
    exact: true,
    to: "#",
    iconClassName: "bi bi-book-half",
    subMenus: [
      {
        // name: "Sales Book",
        name: "Librat e Shitjës",
        to: "/accountingbooks/salesbook"
      },
      {
        // name: "Purchase Book",
        name: "Librat e Blerjës",
        to: "/accounting/purchasebook"
      },
      {
        // name: "Expences Book",
        name: "Librat e Shpënzimeve",
        to: "/accounting/expencebook"
      },
      {
        // name: "Investments Book",
        name: "Librat e Bl. Investive",
        to: "/accounting/investmentbook"
      },
    ]
  },
  {
    // name: "Tax Reports",
    name: "Raportet e Tatimeve",
    exact: true,
    to: "#",
    iconClassName: "bi bi-circle-half",
    subMenus: [
      {
        // name: "Tax Sales Book",
        name: "Librat e Shitjeve - ATK",
        to: "/tax/salesbook"
      },
      {
        // name: "Tax Purchase Book",
        name: "Librat e Blerjeve - ATK",
        to: "/tax/purchasebook"
      },
      {
        // name: "VAT Declaration",
        name: "Deklarata e TVSH-së",
        to: "/tax/vatdeclaration"
      },
      {
        // name: "Annual Declaration",
        name: "Deklarata Vjetore - ATK",
        to: "/tax/annualdeclaration"
      },
    ]
  },

  {
    // name: "Accounting Reports",
    name: "Raportet Kontabel",
    exact: true,
    to: "#",
    role: 'user',
    iconClassName: "bi bi-bar-chart-line-fill",
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subMenus: [
      //Journal History
      {
        // name: "Journal History",
        name: "Ditari i Transaksioneve",
        to: "/accounting/journalhistory"
      },
      //furnitori
      {
        // name: "Accounts Payable",
        name: "Llogaritë e Pagueshme",
        to: "/Reports/accountspayable"
      },
      //klienti
      {
        // name: "Accounts Receivable",
        name: "Llogaritë e Arkëtueshme",
        to: "/Reports/accountsreceivable"
      },
      {
        // name: "Accounts Partners AR/AP",
        name: "Llogaritë e Partnerëve",
        to: "/Reports/accountspartners"
      },
      //BANKA
      {
        // name: "Money In Banks",
        name: "Paraja në Bankë",
        to: "/Reports/moneybank"
      },
      //arka
      {
        // name: "Money In CASH",
        name: "Paraja në Arkë",
        to: "/Reports/cashmoney"
      },
      {
        // name: "Inventory",
        name: "Stoku në detale",
        to: "/accounting/inventory"
      },
    ]
  },
  {
    // name: "Finace Statements",
    name: "Pasqyrat Financiare",
    exact: true,
    to: "#",
    iconClassName: "bi bi-currency-exchange",
    subMenus: [
      {
        // name: "Balance Sheet",
        name: "Bilanci i Gjendjës",
        to: "/financestatements/balancesheet"
      },
      {
        // name: "Income Statements",
        name: "Pasqyra e të Ardhurave",
        to: "/financestatements/incomestatements"
      },
      {
        // name: "Trial Balance",
        name: "Bilanci Vërtetues",
        to: "/financestatements/TrialBalance"
      },
      {
        // name: "Income Statements",
        name: "Raporti i shitjeve mujore",
        to: "/financestatements/MonthlySalesReport"
      },
      {
        // name: "Income Statements",
        name: "Raporti i blerjeve mujore",
        to: "/financestatements/MonthlyPurchaseReport"
      },
      {
        // name: "Income Statements",
        name: "Raporti i shpenzimeve mujore",
        to: "/financestatements/MonthlyExpenseReport"
      },
      {
        // name: "Income Statements",
        name: "Raporti mujor i pagave",
        to: "/financestatements/MonthlyWagesReport"
      },
      {
        // name: "Income Statements",
        name: "Raporti mujor i qirasë",
        to: "/financestatements/MonthlyRentReport"
      },
      {
        // name: "Income Statements",
        name: "Raporti i klasifikimit të Shpenzimeve",
        to: "/financestatements/MonthlyExpenseClassReport"
      },
    ]
  },
];
export const menuItemsss = [
  {
    // name: "Configuration",
    name: "Konfigurimet",
    exact: true,
    to: "#",
    iconClassName: "bi bi-gear-wide-connected",
    subMenus: [
      {
        // name: "Users",
        name: "Përdoruesit",
        to: "/configuration/users",
        role: "admin",
      },
      {
        // name: "Company",
        name: "Kompania",
        to: "/configuration/company"
      },
      {
        // name: "Company Units",
        name: "Njësitë Organizative",
        to: "/configuration/companyunits"
      },
      {
        // name: "Licenced Banks",
        name: "Bankat e Licencuara",
        to: "/configuration/licencedbanks"
      },

      {
        // name: "States",
        name: "Shtetet",
        to: "/configuration/states"
      },
      {
        // name: "Cities",
        name: "Qytet",
        to: "/configuration/cities"
      },
      {
        // name: "Ledgers",
        name: "Llogaritë Kontabël",
        to: "/configuration/ledgers"
      },
      {
        // name: "VAT Rates",
        name: "Normat e TVSH-së",
        to: "/configuration/vatrates",
        role: "admin",
      },
      // {
      //   // name: "Generate Cover",
      //   name: "Inventory",
      //   to: "/configuration/coverpage"
      // },
    ]
  },
]


const SideMenu = (props) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setEmail(decodedToken.email);
    }
  }, []);

  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }
    props.onCollapse(inactive);
  }, [inactive]);

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");

    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;

        // Përdorimi i shprehjes kushtore për të kontrolluar nëse 'next' është i inicializuar
        if (next && typeof next.classList !== 'undefined') {
          removeActiveClassFromSubMenu();
          menuItems.forEach((el) => el.classList.remove("active"));
          el.classList.toggle("active");

          console.log(next);
          next.classList.toggle("active");
        }
      });
    });
  }, []);


  // const [claims, setClaims] = useState([
  //   { name: 'email', value: 'Alban' },
  //   { name: 'role', value: 'user' }

  // ]);

  // function isAdmin() {
  //   return claims.findIndex(claim => claim.name === 'role' && claim.value === 'admin') > -1;
  // }

  // const token = getToken();
  // if (token) {
  //   const decoded = jwtDecode(token);
  //   var role = decoded.role;
  //   console.log(role);
  //   // use role here
  // }

  const token = localStorage.getItem('token');
  const tokenrole = jwtDecode(token).role;

  // console.log(tokenrole);


  return (
    <>
      <div className={`side-menu ${inactive ? "inactive" : ""}`} style={{ width: inactive ? "" : "250px" }}>
        <div className="top-section">
          <div className="side-menu-header" style={{ display: "flex", flexDirection: "row" }}>
            <div className="logo">
              <Link to="/dashboard">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="header-font ">
              <Link to="/dashboard">
                <img src={text} alt="text" style={{ width: "60%", marginLeft: "40px", marginTop: "-10px" }} />
              </Link>
            </div>
          </div>
          <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn" style={{ marginTop: "10%" }}>
            {inactive ? (
              <MdOutlineFormatIndentIncrease style={{ marginTop: "50%" }} />
            ) : (
              <MdOutlineFormatIndentDecrease style={{ marginTop: "50%" }} />
            )}
          </div>
        </div>
        {/* <div className="search-controller">
          <button className="search-btn">
            <i className="bi bi-search"></i>
          </button>
          <input type="text" placeholder="search" />
        </div> */}
        <div className="divider"></div>
        <div className="main-menu">
          <ul>
            {menuItems && menuItems.map((menuItem, index) => (
              <MenuItem style={{ color: "red" }}
                key={index}
                name={menuItem.name}
                exact={menuItem.exact}
                to={menuItem.to}
                subMenus={menuItem.subMenus || []}
                iconClassName={menuItem.iconClassName}
                onClick={(e) => {
                  if (inactive) {
                    setInactive(true);
                  }
                }}
              >
              </MenuItem>
            ))}

            <div className="divider"></div>

            {menuItemss && menuItemss.map((menuItem, index) => (

              <MenuItem style={{ color: "red" }}
                key={index}
                name={menuItem.name}
                exact={menuItem.exact}
                to={menuItem.to}
                subMenus={menuItem.subMenus || []}
                iconClassName={menuItem.iconClassName}
                onClick={(e) => {
                  if (inactive) {
                    setInactive(false);
                  }
                }}
              />
            ))}

            {/* <div className="divider"></div> */}
            {menuItemsss && menuItemsss.map((menuItem, index) => (
              <MenuItem style={{ color: "red" }}
                key={index}
                name={menuItem.name}
                exact={menuItem.exact}
                to={menuItem.to}
                subMenus={menuItem.subMenus || []}
                iconClassName={menuItem.iconClassName}
                onClick={(e) => {
                  if (inactive) {
                    setInactive(false);
                  }
                }}
              />
            ))}
          </ul>
        </div>
        <div className="side-menu-footer">
          {/* <div className="avatar">
            <img src={user} alt="user" />
              </div>*/}
          <div className="user-info">
            <p className="email_infoo">Copyright © 2024 acctree</p>
          </div>

        </div>
      </div>
    </>
  );
};

export default SideMenu;
