import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './../../Style/InsertRent.scss'
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { AiFillAppstore } from "react-icons/ai";
import { Paper } from "@mui/material";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import './../../Style/TableStyle.scss'
import { Month } from "../../OtherData/Month";
import { MdEdit } from "react-icons/md";
import DataGridWithActions from "../../Components/DataGridWithActions";
import InsertEmployees from "../Employees/InsertEmployees"
import Autocomplete from "@mui/material/Autocomplete";


const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export default function Wages() {
    const TranscationData = [
        {
            id: 4,
            type: "Rezident"
        },
        {
            id: 5,
            type: "Jo Rezident"
        },
    ]

    const LlojiIPagës = [
        {
            id: 1,
            type: "Primare"
        },
        {
            id: 2,
            type: "Sekondare"
        },
    ]

    const [pagaNeto, setPagaNeto] = useState(0);
    const [kontributiPunedhenesit, setKontributiPunedhenesit] = useState(0);
    const [kontributiPunetorit, setKontributiPunetorit] = useState(0);
    const [tatimiNePage, setTatimiNePage] = useState(0);
    const [netoField, setNetoField] = useState(false);
    const [brutoField, setBrutoField] = useState(false);


    const vitAktual = new Date().getFullYear();
    const [showDetails, setShowDetails] = useState(true);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    // const handleToggle = () => {
    //     setOpen(!open);
    // };
    const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        PayDate: "",
        ActionType: "",
        AccountID: "",
        AccountIDSpare: "",
        Amount18: 0,
        PaymentOptions: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: ""
    });

    // const [deleteItemId, setDeleteItemId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [employees, setEmployees] = useState([]);
    // const [expence, setExpence] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");

    let number = 1;
    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Wages', { headers });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    // const addRightText = (doc, text, lineY) => {
    //     doc.setFontSize(10);
    //     doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    // };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };
    // `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Rent", "Date", "Tenants", "Neto", "Tax 9%", "Total"]],
            body: filteredData.map((item, index) => [
                number + index,
                item.InvoiceNr,
                formatDate(item.Date),
                item.AccountName,
                item.Amount8.toFixed(2),
                item.Amount18.toFixed(2),
                item.Total.toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    // const handleExportPDF = () => {
    //     const doc = new jsPDF({
    //         orientation: "landscape",
    //     });

    //     const tableMargin = 20;
    //     const lineY = 30 + tableMargin - 5;

    //     addLogo(doc);
    //     addSeparatorLine(doc, lineY);
    //     addLeftText(doc, "List of Rent", lineY);
    //     addCompanyDetails(doc, companiesData[0]);
    //     addTable(doc, filteredData, lineY);

    //     doc.save("Rent.pdf");
    // };

    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Wages' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Transaksioni i pagës u fshi me sukses");
            fetchData();
        } catch (error) {
            deleteAlert("Transaksioni i pagës nuk mund të fshihet");
        }
    };

    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 


    const handleEdit = (item) => {
        calculateFromBruto(item.AccountIDSpare, item.Amount18, item.Date);
        setBrutoField(true);
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            PayDate: item.PayDate,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            AccountIDSpare: item.AccountIDSpare,
            Amount18: item.Amount18,
            PaymentOptions: item.PaymentOptions,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: loginEmail,

        });
        setShowEditModal(true);
        setShowDetails(false);
    };



    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'Wages', formData, { headers, params: { llojiPages: formData.AccountIDSpare } })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                setShowDetails(true);
                updateAlert("Wages updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update Rent")
            });
    };

    const filteredData = data.filter((item) => {
        return (
            item.ActionType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    useEffect(() => {
        axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers, params: { email: logedEmail } })
            .then((response) => {
                setCashRegister(response.data);
            }).catch((error) => {
                // errorAlert(error);
            })
    }, [logedEmail])

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 310 } });
            setEmployees(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const calculateFromBruto = async (llojiPagesParameter, pagaBruto, dataReale) => {
        try {
            const DataKrahasuese = "2024-08-01";

            const kontributiPunedhenes = Number(pagaBruto * 5) / 100;
            setKontributiPunedhenesit(kontributiPunedhenes);

            const kontributiPunetor = Number(pagaBruto * 5) / 100;
            setKontributiPunetorit(kontributiPunetor);

            let pagaPaKontribut = Number(pagaBruto) - kontributiPunedhenes;

            let Koeficienti1 = 0;
            let Koeficienti2 = 0;
            let Koeficienti3 = 0;

            if (llojiPagesParameter === 1) {
                if (pagaPaKontribut <= 80) {
                    Koeficienti1 = 0;
                    Koeficienti2 = 0;
                    Koeficienti3 = 0;
                } else if (pagaPaKontribut <= 250 && pagaPaKontribut > 80) {
                    if (dataReale < DataKrahasuese) {
                        Koeficienti1 = (pagaPaKontribut - 80) * 4 / 100;
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                } else if (pagaPaKontribut <= 450 && pagaPaKontribut > 250) {
                    if (dataReale < DataKrahasuese) {
                        Koeficienti1 = 6.8;
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                    Koeficienti2 = (pagaPaKontribut - 250) * 8 / 100;
                } else {
                    if (dataReale < DataKrahasuese) {
                        Koeficienti1 = 6.8;
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                    Koeficienti2 = 16;
                    Koeficienti3 = (pagaPaKontribut - 450) * 10 / 100;
                }
            } else if (llojiPagesParameter === 2) {
                Koeficienti3 = pagaPaKontribut * 10 / 100;
            }

            const tatimi = Koeficienti1 + Koeficienti2 + Koeficienti3;
            setTatimiNePage(tatimi);
            setPagaNeto(Number(pagaBruto) - kontributiPunedhenes - tatimi);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateFromNeto = (llojiPagesParameter) => {
        const DataKrahasuese = "2024-08-01";

        let Deri80 = 80;
        let Deri250 = 0;
        let Deri450 = 0;
        let Mbi450 = 0;
        let Koeficienti1 = 0;
        let Koeficienti2 = 0;
        let Koeficienti3 = 0;
        let TatimiPagaNeto = 0;

        if (llojiPagesParameter === 1) {
            if (pagaNeto <= 80) {
                Deri80 = pagaNeto;
            } else {
                Deri80 = 80;
                if (((Number(pagaNeto) - Number(Deri80)) / Number(0.96)) <= 170) {
                    Deri250 = ((Number(pagaNeto) - Number(Deri80)) / Number(0.96));
                    if (formData.Date < DataKrahasuese) {
                        Koeficienti1 = (Number(Deri250) * Number(0.04));
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                } else {
                    Deri250 = 170;
                    if (formData.Date < DataKrahasuese) {
                        Koeficienti1 = (Number(Deri250) * Number(0.04));
                    }
                    else {
                        Koeficienti1 = 0;
                    }
                    if (((Number(pagaNeto) + Number(Koeficienti1) - Number(250)) / Number(0.92)) <= 200) {
                        Deri450 = (Number(pagaNeto) + Number(Koeficienti1) - Number(250)) / Number(0.92);
                        Koeficienti2 = (Number(Deri450) * Number(0.08));
                    } else {
                        Deri450 = 200;
                        Koeficienti2 = (Number(Deri450) * Number(0.08));
                        if (Deri450 === 200) {
                            Mbi450 = (Number(pagaNeto) + Number(Koeficienti1) + Number(Koeficienti2) - Number(450)) / Number(0.9);
                            Koeficienti3 = (Number(Mbi450) * Number(0.1));
                        } else {
                            Mbi450 = 0;
                        }
                    }
                }
            }
            setTatimiNePage((Number(Koeficienti1) + Number(Koeficienti2) + Number(Koeficienti3)));
            TatimiPagaNeto = (Number(Koeficienti1) + Number(Koeficienti2) + Number(Koeficienti3) + Number(pagaNeto));

            let bruto = (Number(TatimiPagaNeto) / Number(0.95));
            // setPagaBruto(bruto);
            setFormData(prevState => ({
                ...prevState,
                Amount18: bruto,
            }));
            let kontributet = ((Number(bruto) - Number(TatimiPagaNeto)) * Number(2))

            setKontributiPunedhenesit(Number(kontributet) / 2);
            setKontributiPunetorit(Number(kontributet) / Number(2));
        }

        if (llojiPagesParameter === 2) {
            Mbi450 = pagaNeto / 0.9;
            Koeficienti3 = (Number(Mbi450) * Number(0.1));
            setTatimiNePage(Koeficienti3);
            TatimiPagaNeto = (Number(Koeficienti3) + Number(pagaNeto));

            let bruto = (Number(TatimiPagaNeto) / Number(0.95));
            // setPagaBruto(bruto);

            setFormData(prevState => ({
                ...prevState,
                Amount18: bruto,
            }));



            let kontributet = ((Number(bruto) - Number(TatimiPagaNeto)) * Number(2))
            setKontributiPunedhenesit(Number(kontributet) / 2);
            setKontributiPunetorit(Number(kontributet) / Number(2));
        }
    };



    const columns = [
        { field: 'id', headerName: '#', width: 90, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'month', headerName: 'Muaji', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'date', headerName: 'Data', type: 'string', width: 180, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'employee', headerName: 'Punonjësi', type: 'string', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        {
            field: 'neto', headerName: 'Paga Neto', type: 'string', width: 140, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },
        {
            field: 'kontributet', headerName: 'Kontributet', type: 'string', width: 140, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },
        {
            field: 'bruto', headerName: 'Paga Bruto', type: 'string', width: 140, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },
    ];

    const rows = data.map((item, index) => ({
        id: ++index,
        month: item.InvoiceNr,
        date: item.Date.substring(0, 10),
        employee: item.AccountName,
        neto: (item.Amount0).toFixed(2),
        kontributet: (item.VAT8).toFixed(2),
        bruto: (item.Amount18).toFixed(2),
        rowInfo: item, // Shtimi i një atributi të ri për të dhënë të dhënat e rreshtit
        deleteItemId: item.TransactionID // Shtimi i një atributi për id e rreshtit për fshirje

    }));

    //Select list for Supplier
    const handleAutocompleteChange = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    const defaultProps = {
        options: employees,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                    {/* <button
                    onClick={(e) => {
                        e.stopPropagation();
                        // handleButtonClick(option);
                    }}
                    onMouseEnter={handleOpen}
                    onMouseLeave={handleClose}
                    style={{ marginLeft: 'auto' }}
                >
                    Click
                </button> */}
                </li>
            </>
        ),
    };
    ////

    useEffect(() => {
        fetchData();
        fetchCompaniesData();
        fetchEmployees();
    }, [logedEmail]);

    return (
        <div className="Rent__Container">
            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div className="edit">
                    <div className="header_flex">
                        <div className="header_icon">
                            <MdEdit style={{ color: '#466F86', width: "40px" }} />
                            <h3>Ndyshimi Pagës</h3>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="Rent__Container__AllButtons">
                        <div style={{ display: "flex", }}>
                            <InsertEmployees fetchData={fetchEmployees} type="insert" />
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "5px" }}>
                                <Link to="#"
                                    style={{ width: "110px", textDecoration: "none", color: "green" }}
                                >
                                    <button type="submit" className="button-15" variant="contained" onClick={handleSubmit}>Ndrysho</button>

                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button
                                        className="button-15" variant="contained"
                                        onClick={() => {
                                            setShowDetails(true);
                                            setShowEditModal(false);
                                        }}
                                    >
                                        Anulo
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='header_paper2'></div>
                    <form className='Rent__Container__Format'>
                        <Paper>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className='Forma1'>
                                    <div className='Boxx'>
                                        <TextField
                                            className="AddPurchaseTotal__Container__Format__SameInput"
                                            // style={{ width: "594px" }}
                                            id="InvoiceNr"
                                            select
                                            name="InvoiceNr"
                                            label="Muaji"
                                            value={formData.InvoiceNr}
                                            onChange={handleInputChange}
                                        >
                                            {Month.map((data) => (
                                                <MenuItem key={data.id} value={vitAktual + data.month}>{vitAktual}{data.month}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <div className='AddPurchaseTotal__Container__Format__Dates'>
                                            <TextField
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="Date"
                                                type="date"
                                                variant="outlined"
                                                name='Date'
                                                label='Data deklarimit'
                                                value={formData.Date && formData.Date.substring(0, 10)}
                                                onChange={handleInputChange}
                                            />
                                            <TextField
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="PayDate"
                                                type="date"
                                                variant="outlined"
                                                name='PayDate'
                                                label="Data Pagesës"
                                                value={formData.PayDate && formData.PayDate.substring(0, 10)}
                                                onChange={handleInputChange}
                                            />
                                            {/* <TextField
                                                // style={{ width: "343px" }}
                                                className="AddPurchaseTotal__Container__Format__Transaction"
                                                id="id"
                                                select
                                                name="TransactionType"
                                                label="Select Transaction Type"
                                                value={formData.TransactionType}
                                                onChange={handleInputChange}
                                            >
                                                {TranscationData.map((data) => (
                                                    <MenuItem key={data.id} value={data.id}>{data.type}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                        </div>

                                        <div className="textfield_button">
                                            {/* <TextField
                                                // style={{ width: "504px" }}
                                                className="AddPurchaseTotal__Container__Format__SameInput"
                                                id="AccountID"
                                                name="AccountID"
                                                select
                                                label="Përzgjedh të punësuarin"
                                                value={formData.AccountID}
                                                onChange={handleInputChange}
                                            >
                                                {employees.map((data) => (
                                                    <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                            <Autocomplete
                                                fullWidth
                                                // sx={{ m: 2 }}
                                                {...defaultProps}
                                                id="AccountID"
                                                includeInputInList
                                                onChange={handleAutocompleteChange}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        label="I Punësuari"
                                                        // variant="filled"
                                                        className="AddPurchaseTotal__Container__Format__SameInput"
                                                    />
                                                )}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                        overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                    },
                                                }}
                                                value={employees.find(option => option.AccountID === formData.AccountID) || null}
                                            />
                                        </div>
                                        <div className="AddPurchaseTotal__Container__Format__Dates" style={{ paddingTop: "10px" }}>
                                            <TextField
                                                // style={{ width: "593px" }}
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="AccountID"
                                                label="Klasa e shpenzimit"
                                                defaultValue=""
                                                value={"603-Shpenzimet e Pagave	"}
                                                // onChange={event => setAccountID(703)}
                                                disabled
                                            >
                                            </TextField>

                                            <TextField
                                                // style={{ width: "343px" }}
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="id"
                                                select
                                                name="TransactionType"
                                                label="Lloji punonjësit"
                                                value={formData.TransactionType}
                                                onChange={handleInputChange}
                                            >
                                                {TranscationData.map((data) => (
                                                    <MenuItem key={data.id} value={data.id}>{data.type}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <TextField
                                            // style={{ width: "593px" }}
                                            className="AddPurchaseTotal__Container__Format__SameInput"
                                            id="id"
                                            select
                                            label="Përzgjedh llojin e pagesës"
                                            name="PaymentOptions"
                                            value={formData.PaymentOptions}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem key="0" value="0">Pagesa Behet më vonë
                                            </MenuItem>
                                            {cashRegister.map((data) => (
                                                <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <TextField
                                            // style={{ width: "495px" }}
                                            className="AddPurchaseTotal__Container__Format__SameInput"
                                            type="text"
                                            id="Coment"
                                            name="Coment"
                                            label="Komenti"
                                            variant="outlined"
                                            value={formData.Coment}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className='box2'>
                                        <TextField
                                            // style={{ width: "343px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="AccountIDSpare"
                                            select
                                            name="AccountIDSpare"
                                            label="Përzgjedh llojin e pagës"
                                            value={formData.AccountIDSpare}
                                            onChange={(event) => {
                                                handleInputChange(event);
                                                if (netoField) {
                                                    calculateFromNeto(event.target.value);
                                                } else {
                                                    calculateFromBruto(event.target.value, formData.Amount18, formData.Date);
                                                }
                                            }}

                                        >
                                            {LlojiIPagës.map((data) => (
                                                <MenuItem key={data.id} value={data.id}>{data.type}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="PagaNeto"
                                            label="Paga Neto"
                                            variant="outlined"
                                            name='Paga Neto'
                                            value={pagaNeto !== null && pagaNeto !== 0 ? pagaNeto.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={event => { setPagaNeto(event.target.value); setNetoField(true); setBrutoField(false); }}
                                            onKeyUp={event => calculateFromNeto(formData.AccountIDSpare)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="KontributiPunedhenesit"
                                            label="Kontributi i punëdhënësit"
                                            name='KontributiPunedhenesit'
                                            variant="outlined"
                                            value={(kontributiPunedhenesit).toFixed(2)}
                                            readOnly
                                            // onChange={handleInputChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="KontributiPunetorit"
                                            label="Kontributi i punëtorit"
                                            variant="outlined"
                                            name='KontributiPunetorit'
                                            value={(kontributiPunetorit).toFixed(2)}
                                            readOnly
                                            // onChange={handleInputChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />

                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="TatimiNePage"
                                            label="Tatimi në pagë"
                                            variant="outlined"
                                            name='TatimiNePage'
                                            value={(tatimiNePage).toFixed(2)}
                                            readOnly
                                            // onChange={handleInputChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />

                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="Amount18"
                                            label="Paga Bruto"
                                            variant="outlined"
                                            name='Amount18'
                                            value={formData.Amount18 !== null && formData.Amount18 !== 0 ? formData.Amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={handleInputChange}
                                            onKeyUp={event => calculateFromBruto(formData.AccountIDSpare, formData.Amount18, formData.Date)}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />

                                    </div>
                                </div>
                            </Box>
                        </Paper>
                    </form>
                </div>
            )}
            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Pagat</h3>
                        </div>
                        <div>
                            <Link to={"/expences/AddWages"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />   Krijo një pagë
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e Pagave: Menaxhoni, shtoni, editoni dhe fshini Transaksione të Pagave.</p>
                    </div>
                    <DataGridWithActions
                        columns={columns}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={true}
                        height={500}
                    />
                </div>
            )}
        </div>
    )
}