import React from "react";
import { createRoot } from "react-dom/client"; // Përdorimi i "createRoot" nga "react-dom/client"
import App from "./App";
import reportWebVitals from "./reportWebVitals";


const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();




// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";


// ReactDOM.render(
//   <React.StrictMode>
//     <App/>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// reportWebVitals();