import React, { useState, useEffect, useRef } from "react";
import { Paper, TextareaAutosize } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/SalesDetailStyle.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { deleteAlert, errorAlert } from '../../Components/Alerts';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MenuItem from '@mui/material/MenuItem';
import PrintContent from "../../Print/PrintContent";
import { Link, useHistory } from "react-router-dom";
import { FaTimes, FaTrash, FaFileInvoice } from "react-icons/fa";
import Select from 'react-select';
import InsertClient from "../Clients/InsertClients";
import InsertArticle from "../Article/InsertArticle";
import DataGridWithActions from "../../Components/DataGridWithActions";
import { detailsData } from "./ZSalesGridHeader";
import Autocomplete from "@mui/material/Autocomplete";

function Insert_ZSalesDetail(props) {

    const TranscationData = [
        {
            id: 1,
            type: "National"
        },
        {
            id: 2,
            type: "International"
        },
    ]
    const headers = authHeader();
    const email = LoginUserDetailed();
    const [logedEmail, setLogedEmail] = useState("");

    //Per insertimin e Transaction
    const [date, setDate] = useState("");
    const [payDate, setPayDate] = useState("");
    const [coment, setComent] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [supplierID, setSupplierID] = useState(0);
    const [transactionsType, setTransactionType] = useState(1);
    const [paymentOptions, setPaymentOptions] = useState("");
    const [priceWithVAT, setPriceWithVAT] = useState(0);


    //Per insertimin ne Article History
    const [articleID, setArticleID] = useState("");
    // const [qtyinput, setQtyInput] = useState("");
    const [qtyOutput, setQtyOutput] = useState(0);
    const [priceWithoutVAT, setPriceWithoutVAT] = useState("");
    const [total, setTotal] = useState("");
    const [vatRates, setVATRates] = useState("");

    const [showDeleteTransactionID, setShowDeleteTransactionID] = useState(false);

    const [deleteTransactionID, setDeleteTransactionID] = useState("");

    const [openDetails, setOpenDetails] = useState(false);


    const [data, setData] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);

    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [clientsDetails, setClientsDetails] = useState([]);

    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const [valuedetails, setValueDetails] = useState([]);
    // Te dhenat per printim
    const [headerData, setHeaderData] = useState([]);
    const [companiesData, setCompaniesData] = useState([]);
    const [articlePrintDetails, setArticlePrintDetails] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [form1IsDisabled, setForm1IsDisabled] = useState(false);
    const [form2IsDisabled, setForm2IsDisabled] = useState(true);

    const handleEnable = () => {
        setForm1IsDisabled(true);
        setForm2IsDisabled(false);
    }


    const newInvoice = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        setForm1IsDisabled(false);
        setForm2IsDisabled(true);
        setOpenDetails(false);
        setArticleID();
        setQtyOutput(0);
        setPriceWithoutVAT(0);
        setPriceWithVAT(0);
        setTotal(0);
        fetchNextInvociceNumber();
        setDate(formattedDate);
        setPayDate(formattedDate);
        setComent("");
        setTransactionType(1);
        setPaymentOptions("")
        setSupplierID("");
        setClientName("");
        setClientsDetails([]);
        setData([]);
        setValueDetails([]);
        setfield(null);
        setAccfield(null);
    }

    // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo 
    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);
    // ky kod i ndryshon gjendjet e formave

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
        setArticleID();
        setQtyOutput(0);
        setPriceWithoutVAT(0);
        setPriceWithVAT(0);
        setTotal(0);
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true); // I fsheh formën e parë
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/Z_salesDetails', { headers, params: { invNumber: invoiceNR } });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchTransactionID = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/GetTransactionID', { headers, params: { email: logedEmail, invNumber: invoiceNR } });
            setDeleteTransactionID(response.data);
            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (response.data.length === 0) {
                // Shfaqi një mesazh për përdoruesin
                // errorAlert("No data available.");

            }
        } catch (error) {
            console.log(error);
            // Shfaqi një mesazh për përdoruesin për gabimin e tjera
            // setMessage("Error: Failed to fetch data.");
        }
    };

    const fetchHeaderData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/GetPrintHeaderDetails', { headers, params: { invNumber: invoiceNR } });
            setHeaderData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticleDataPrint = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetArticleDetailsForPrint', { headers, params: { invNumber: invoiceNR, actionType: "ZRP" } });
            setArticlePrintDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchBanksAccount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/BankDetails', { headers });
            setBankAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchvaluedetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/getZ_Value', { headers, params: { invNumber: invoiceNR } });
            setValueDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchClients = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
            setClients(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchClientsDetails = async (supplierid) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByID', { headers, params: { id: supplierid } });
            setClientsDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article', { headers });
            setArticles(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchNextInvociceNumber = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/GetNextInvoiceNR', { headers });
            setInvoiceNR(response.data.NextInvoiceNR);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };
    const fetchArticlesSalesPrice = async (articleID, form) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetSalesPrice', { headers, params: { articleID: articleID } });
            const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja

            if (form == "edit") {
                const calculatedValue = parseFloat(response.data.SalesPrice) * 100 / (100 + response.data.PurchasePrice);
                // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
                setVATRates(response.data.VATRates);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: response.data.SalesPrice,
                    PriceWithoutVAT: calculatedValue,
                    Total: formDetailData.QtyOutput * response.data.SalesPrice
                }));
            }
            else if (form == "getVat") {
                setVATRates(response.data.VATRates);
                // alert("get vat" + vatRates)
            }
            else {
                setPriceWithVAT(salesPriceData.SalesPrice);
                setVATRates(salesPriceData.VATRates);
                setQtyOutput(1);
                // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
                const calculatedValue = parseFloat(salesPriceData.SalesPrice) * 100 / (100 + salesPriceData.VATRates);
                setPriceWithoutVAT(calculatedValue.toFixed(2));
                setTotal(1 * salesPriceData.SalesPrice);
            }
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Z_RaportSales/oneline' + `/${deleteItemId}`, { headers, params: { email: logedEmail, invoiceNR: invoiceNR } }
            );
            deleteAlert("Purchase detail deleted Successfully");

            fetchData();
            fetchvaluedetails();
            fetchHeaderData();
            fetchArticleDataPrint();
        } catch (error) {
            deleteAlert("Purchase detail can not be deleted");
        }
    };

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDeleteTransactionID = async () => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Z_RaportSales' + `/${deleteTransactionID}`, { headers }
            );
            setShowDeleteTransactionID(false);
            deleteAlert("Z-Sales Invoice deleted Successfully");
            setPaymentOptions("");
            setSupplierID();
            fetchvaluedetails();
            setClientName("");
            fetchNextInvociceNumber();
        } catch (error) {
            deleteAlert("Z-Sales Invoice can not be deleted");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        if (!date) {
            errorAlert("Ju lutem plotësoni Daten!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Ju lutem plotësoni mënyren e pagesës!");
            return;
        }
        if (!supplierID) {
            errorAlert("Ju lutem plotësoni klientin!");
            return;
        }
        if (paymentOptions != 0 && payDate == "") {
            errorAlert("Please Fill pay date")
            return;
        }

        const stateDate = {
            Date: date,
            PayDate: paymentOptions !== 0 ? payDate : null,
            InvoiceNr: invoiceNR,
            SupplierID: supplierID,
            TransactionType: transactionsType,
            ActionType: 'ZRP',
            PaymentOptions: paymentOptions,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'Z_RaportSales/invoicedetail', stateDate, { headers })
            .then(() => {
                // handleSuccess("Sales Invoice Added Successfully");
                handleEnable();
                setOpenDetails(true);
                fetchHeaderData();
                fetchTransactionID();
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        // Shtoni kontrollet këtu
        if (isNaN(qtyOutput)) {
            errorAlert("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            return;
        }
        if (isNaN(priceWithoutVAT)) {
            errorAlert("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            return;
        }
        if (isNaN(priceWithVAT)) {
            errorAlert("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            return;
        }
        if (isNaN(total)) {
            errorAlert("Totali duhet të jetë një numër.");
            return;
        }
        const stateDate = {
            InvoiceNr: invoiceNR,
            ArticleID: articleID,
            QtyOutput: qtyOutput,
            PriceWithoutVAT: priceWithoutVAT,
            PriceWithVAT: priceWithVAT,
            CreatedByUser: email,
            Date: date,
            SupplierID: supplierID

        };
        axios.post(ApiLink.API_URL + 'Z_RaportSales/createnewline', stateDate, { headers, params: { vatRates: vatRates } })
            .then(() => {
                // handleSuccess("Detail  Added Successfully");
                setArticleID();
                setQtyOutput(0);
                setPriceWithoutVAT(0);
                setPriceWithVAT(0);
                setTotal(0);
                fetchData();
                fetchvaluedetails();
                fetchHeaderData();
                fetchArticleDataPrint();
                setArticlesName("");
                setfield(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }
    const [formDetailData, setFormDetailData] = useState({
        HistoryID: "",
        ArticleID: "",
        QtyOutput: "",
        Total: "",
        PriceWithVAT: "",
        PriceWithoutVAT: "",
        ModifiedByUser: "",
        InvoiceNr: "",
        PaymentOptions: "",
    });

    const clearEdit = () => {
        setFormDetailData({
            HistoryID: "",
            ArticleID: "",
            QtyOutput: 0,
            PriceWithVAT: 0,
            PriceWithoutVAT: 0,
            setFormDetailData: 0
        });

        clearLine();
        handleOpenForm2();
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDetail = (event) => {
        const { name, value } = event.target;
        setFormDetailData({ ...formDetailData, [name]: value });
    };

    const handleSubmitDetailLine = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'Z_RaportSales/updatedetailsArticle', formDetailData, { headers, params: { vatRates: vatRates } })
            .then((response) => {
                // fetchDetailLineData(invoiceNR);
                fetchvaluedetails();
                // updateAlert("Detail updated successfully");
                fetchArticleDataPrint();
                fetchHeaderData();
                fetchData();
                handleOpenForm2();
            })
            .catch((error) => {
                // console.log(error);
                errorAlert("Failed to update details")
            });
    }

    const handleEditDetails = (item) => {
        handleOpenForm1();
        fetchArticlesSalesPrice(item.ArticleID, "getVat")
        setFormDetailData({
            HistoryID: item.HistoryID,
            ArticleID: item.ArticleID,
            QtyOutput: item.QtyOutput,
            Total: item.Total,
            PriceWithVAT: item.PriceWithVAT,
            PriceWithoutVAT: item.PriceWithoutVAT,
            ModifiedByUser: logedEmail,
            InvoiceNr: invoiceNR,
            PaymentOptions: paymentOptions,
        });
    };


    useEffect(() => {
        // Kontrollo nqs supplierID është ndryshuar para se të kryhen veprimet
        if (supplierID !== "") {
            fetchClientsDetails(supplierID);
        }
    }, [supplierID]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleTextFieldWidth = () => {
        return screenWidth <= 450 ? "100%" : "41ch";
    };

    const clearLine = () => {
        setArticleID();
        setQtyOutput(0);
        setPriceWithoutVAT(0);
        setPriceWithVAT(0);
        setTotal(0);
        fetchData();
        fetchvaluedetails();
        fetchHeaderData();
        fetchArticleDataPrint();
        setArticlesName("");
    }

    //Ky kod e kalkulon PriceWithVAT duke u bazuar ne qmimin pa tvsh dhe tvsh-n
    const handleCalculatePriceWithVAT = async (type) => {
        try {
            if (isNaN(priceWithoutVAT)) {
                throw new Error("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }
            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const vat = Number(formDetailData.PriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(formDetailData.PriceWithoutVAT) + vat;
                const total = Number(formDetailData.QtyOutput) * calculatedPriceWithVAT;

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: calculatedPriceWithVAT,
                    Total: total
                }));

            } else {
                const vat = Number(priceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(priceWithoutVAT) + vat;
                const total = Number(qtyOutput) * calculatedPriceWithVAT;
                // Veprimet specifike për shtim të ri
                setPriceWithVAT(calculatedPriceWithVAT);
                setTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    //Ky kod e kalkulon PricewWithout VAT duke u bazuar ne qmimin me tvsh dhe tvsh-n
    const handleCalculatePriceWithoutVAT = async (type) => {
        try {
            if (isNaN(priceWithVAT)) {
                throw new Error("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const calculatedPriceWithoutVAT = Number(formDetailData.PriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(formDetailData.QtyOutput) * Number(formDetailData.PriceWithVAT);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                    Total: total
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const calculatedPriceWithoutVAT = Number(priceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(qtyOutput) * Number(priceWithVAT);

                setPriceWithoutVAT(calculatedPriceWithoutVAT);
                setTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    //Ky kod e kalkulon PricewWithout VAT duke u bazuar ne qmimin me tvsh dhe tvsh-n
    const handleCalculatePrices = async (type) => {
        try {
            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }

            if (isNaN(total)) {
                throw new Error("Totali duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const priceWithVAT = Number(formDetailData.Total) / Number(formDetailData.QtyOutput);
                const calculatedPriceWithoutVAT = formDetailData.PriceWithVAT * 100 / (100 + Number(vatRates));

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: priceWithVAT,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const priceWithVAT = Number(total) / Number(qtyOutput);
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));

                setPriceWithoutVAT(calculatedPriceWithoutVAT);
                setPriceWithVAT(priceWithVAT);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    const [buttonContent, setButtonContent] = useState({
        text: "Open Invoice Details",
        backgroundColor: "#466F86",
        transcation: "background-color 0.3s ease",
        onMouseEnter: "#6c7f8f",
        onMouseLeave: "#7a95a6"
    });
    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        setDate(formattedDate);
        setPayDate(formattedDate);
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredArticles(
            articles.filter((data) =>
                data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, articles]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredClients(
            clients.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, clients]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };


    const [articlesName, setArticlesName] = useState("");
    const handleFilterArticles = (selectedOption) => {
        if (selectedOption && selectedOption.ArticleID) {
            setArticleID(selectedOption.ArticleID);
            setArticlesName(selectedOption.label);
            fetchArticlesSalesPrice(selectedOption.ArticleID);
        } else {
            setArticleID("");
            setArticlesName("");
        }
    };

    const [clientName, setClientName] = useState("");
    const handleFilterClient = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setSupplierID(selectedOption.AccountID);
            setClientName(selectedOption.label);
        } else {
            setSupplierID("");
            setClientName("");
        }
    };

    useEffect(() => {
        fetchNextInvociceNumber();
    }, [])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'ArticleID':
                    nextField = document.getElementById('QtyOutput');
                    break;
                case 'QtyOutput':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithoutVAT':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithVAT':
                    nextField = document.getElementById('Total');
                    break;
                case 'Total':
                    if (showEditForm === true && showInserForm === false) {
                        handleSubmitDetailLine(e);
                    } else if (showInserForm === true && showEditForm === false) {
                        handleNewSubmit(e);
                    }
                    nextField = document.getElementById('ArticleID');
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select(); // Shtoni këtë rresht për të selektuar tekstin
            }
        }
    };

    const detailsRows = data.map((item, index) => ({
        id: ++index,
        article: item.ArticleName,
        qty: item.QtyOutput,
        PNoVAT: item.PriceWithoutVAT,
        PVAT: item.PriceWithVAT,
        amountNoVAT: item.Amount,
        vat: item.VAT,
        amountVAT: item.Total,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));

    //Pjesa per select input field Clients
    const [accField, setAccfield] = useState(null);
    const handleAutocompleteChangeClient = (event, value) => {
        if (value && value.AccountID) {
            setSupplierID(value.AccountID);
            setAccfield(value);
        } else {
            // Në rast se vlera është pastruar
            setSupplierID(""); // Vendos vlerën e ArticleID në null
        }
    };


    const defaultPropsClients = {
        options: clients,
        getOptionLabel: (option) => option.AccountName || "",
    };

    //////

    //Pjesa per select input field Article
    const [field, setfield] = useState(null);
    const handleAutocompleteChangeArticle = (event, value) => {
        if (value && value.ArticleID) {
            setArticleID(value.ArticleID);
            fetchArticlesSalesPrice(value.ArticleID);
            setfield(value)
        } else {
            // Në rast se vlera është pastruar
            setArticleID(""); // Vendos vlerën e ArticleID në null
            setfield(null);
        }
    };

    const defaultPropsArticle = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        onMouseEnter={handleOpen}
                        onMouseLeave={handleClose}
                        style={{ marginLeft: 'auto' }}
                    >
                        Click
                    </button> */}
                </li>
            </>
        ),
    };

    ///////////////////////////////////////

    const handleAutocompleteChangeArticleEdit = (event, value) => {
        if (value) {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: value.ArticleID
            }));
            fetchArticlesSalesPrice(value.ArticleID, "edit");
        } else {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: ""
            }));
        }
    };

    const defaultPropsArticleEdit = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };



    useEffect(() => {
        fetchData();
        fetchClients();
        fetchArticles();
        fetchHeaderData();
        fetchCompaniesData();
        fetchBanksAccount();
        fetchTransactionID();
        fetchPaymentType();
    }, [logedEmail, invoiceNR, supplierID, articleID]);
    return (
        <div className="SalesDetail__Container" style={{ paddingTop: "50px" }}>
            <Modal show={showDeleteTransactionID} onHide={() => setShowDeleteTransactionID(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this invoice?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteTransactionID(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDeleteTransactionID}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <br></br>
            <div className="header_flex">
                <div className="header_icon">
                    <FaFileInvoice style={{}} className="header_icons" />
                    <h3>New Sale with Z-raport</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Please note that all dates are automatically saved in our secure database for your convenience.</p>
            </div>
            <hr></hr>
            <div className="SalesDetail__Container__AllButtons">
                {/* <div>
                    <PrinterFislal/>
                </div> */}
                <div style={{ display: "flex", }}>
                    <div>
                        <InsertClient fetchData={fetchClients} type="insert" />
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <InsertArticle fetchData={fetchArticles} type="insert" />
                    </div>
                </div>
                <div style={{ display: "flex", }}>
                    {/* <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaEnvelope /> Email
                            </button>
                        </Link>
                    </div> */}
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained" onClick={() => { newInvoice(); }}>
                                Ruaj & i ri
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <PrintContent details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyOutput" actiontype="true" />
                    </div>
                    {/* <div style={{ marginLeft: "5px" }}>
                        <PDF details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyOutput" actiontype="true" />
                    </div> */}
                    {/* <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTicketAlt /> Fiscal
                            </button>
                        </Link>
                    </div> */}
                    <div style={{ marginLeft: "5px" }}>
                        {/* <Link to="/Sales/Z-salesdetail"
                            style={{ width: "110px", textDecoration: "none" }}
                        > */}
                        <button className="button-15" variant="contained"
                            onClick={() => {
                                setShowDeleteTransactionID(true);
                            }}
                        >
                            <FaTrash /> Delete
                        </button>
                        {/* </Link> */}
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to="/Sales/Z-salesdetail"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Close
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='header_paper2' style={{ display: "flex", justifyContent: "flex-end" }}></div>
            <Paper>
                <form onSubmit={handleSubmit} className='header_paper'>
                    <Box
                        // component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1.3, width: handleTextFieldWidth },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {/* <p>Please fill in these fields first to proceed to the fields below</p> */}
                        <div className='SalesDetail__Container__Form'>
                            <div className="SalesDetail__Container__Form__div">
                                <div className="SalesDetail__Container__Form__div__1">
                                    <div className="SalesDetail__Container__Form__div__2">
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__1"
                                            required
                                            id="invoiceNR"
                                            label="Z-Raport Number Ex:ZRP-1000"
                                            variant="outlined"
                                            type="text"
                                            name='InvoiceNr'
                                            value={invoiceNR}
                                            // onChange={event => setInvoiceNR(event.target.value)}
                                            onChange={event => { setInvoiceNR(event.target.value) }}

                                            disabled={form1IsDisabled}
                                            autoComplete="off"
                                        />

                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__1"
                                            id="id"
                                            select
                                            label="Select Transaction Type"
                                            defaultValue=""
                                            value={transactionsType}
                                            onChange={event => setTransactionType(event.target.value)}
                                            required
                                            disabled={form1IsDisabled}

                                        >
                                            {TranscationData.map((data) => (
                                                <MenuItem key={data.id} value={data.id}>{data.type}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextareaAutosize style={{ height: "70px", marginLeft: "10px" }}
                                            className="SalesDetail__Container__Form__TextField__1"
                                            id="Comment"
                                            label="Comment"
                                            type="text"
                                            name='Comment'
                                            value={coment}
                                            onChange={event => setComent(event.target.value)}
                                            variant="outlined"
                                            disabled={form1IsDisabled}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="SalesDetail__Container__Form__TextField__date__Payment">
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__date"
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            name='Date'
                                            label="Invoice date"
                                            value={date}
                                            onChange={(e) => { setDate(e.target.value); setPayDate(e.target.value); }}
                                            disabled={form1IsDisabled}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                        />
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Payment"
                                            id="PaymentType"
                                            select
                                            label="Select Payment Type"
                                            // defaultValue=""
                                            // helperText="Please select expense"
                                            value={paymentOptions}
                                            onChange={event => setPaymentOptions(event.target.value)}
                                        // disabled={form1IsDisabled}

                                        >
                                            <MenuItem key="0" value="0">Pagesa Behet më vonë
                                            </MenuItem>
                                            {cashRegister.map((data) => (
                                                <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {
                                            paymentOptions != 0 ? (
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__date"
                                                    id="PayDate"
                                                    type="date"
                                                    variant="outlined"
                                                    label="Payment date"
                                                    name='PayDate'
                                                    value={payDate}
                                                    // onChange={(e) => setDate(e.target.value)}
                                                    onChange={event => { setPayDate(event.target.value); }}
                                                    disabled={form1IsDisabled}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="SalesDetail__Container__Form__div__4">
                                <div>
                                    {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Klientin</span> */}
                                    {/* <Select
                                        inputId="AccountID"
                                        className="SalesDetail__Container__Form__TextField__selectList"
                                        value={{ label: clientName, value: supplierID }}
                                        options={filteredClients.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleFilterClient}
                                        isClearable
                                        placeholder="Select"
                                        noOptionsMessage={() => 'No matching'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "55px",
                                                marginLeft: "10px", marginRight: "10px"

                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",
                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <div >
                                        <Autocomplete
                                            {...defaultPropsClients}
                                            id="include-input-in-list"
                                            includeInputInList
                                            value={accField}
                                            onChange={handleAutocompleteChangeClient}
                                            isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                            renderInput={(params) => (
                                                <TextField
                                                    style={{ width: "95%" }}
                                                    {...params}
                                                    label="Kerko Klientin"
                                                // variant="filled"
                                                //   onKeyPress={handleKeyPress}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__IDNumber"
                                        id="outlined-basic"
                                        label="Identification Number"
                                        variant="outlined"
                                        name="IdentificationNumber"
                                        value={clientsDetails.length > 0 ? clientsDetails[0].IdentificationNumber : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__IDNumber"
                                        id="outlined-basic"
                                        label="VAT Number"
                                        variant="outlined"
                                        name="VatNumber"
                                        value={clientsDetails.length > 0 ? clientsDetails[0].VATNumber : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>
                                <div>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__2"
                                        id="outlined-basic"
                                        label="Address"
                                        variant="outlined"
                                        name="Address"
                                        value={clientsDetails.length > 0 ? clientsDetails[0].Adress : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
                {
                    openDetails ?
                        <div className="SalesDetail__Container">
                            <div style={{ backgroundColor: "gainsboro " }}>
                                <hr></hr>
                                {showInserForm && (
                                    <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
                                        <Box
                                            // component="form"
                                            sx={{
                                                "& .MuiTextField-root": { m: 1.3, width: handleTextFieldWidth },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__Article">
                                                    {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Select Article</span>
                                                    <Select
                                                        className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                        inputId="ArticleID"
                                                        value={{ label: articlesName, value: articleID }}
                                                        options={filteredArticles.map((data) => ({
                                                            label: data.ArticleName,
                                                            value: data.ArticleID,
                                                            ArticleID: data.ArticleID
                                                        }))}
                                                        onInputChange={handleInputChange}
                                                        onChange={handleFilterArticles}
                                                        isClearable
                                                        placeholder="Select"
                                                        noOptionsMessage={() => 'No matching'}
                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                height: "55px",
                                                                marginLeft: "10px",
                                                                marginRight: "12px",
                                                                backgroundColor: "transparent",
                                                                border: "darkgray solid 1px"
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: "#fff",
                                                                zIndex: 9999,
                                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                borderRadius: "4px",
                                                            }),
                                                            menuList: (provided) => ({
                                                                ...provided,
                                                                maxHeight: "200px",
                                                                overflowY: "auto",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: "#000",
                                                            }),
                                                        }}
                                                    /> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        style={{ paddingTop: "10px" }}
                                                        {...defaultPropsArticle}
                                                        id="ArticleID"
                                                        includeInputInList
                                                        value={field}
                                                        onChange={handleAutocompleteChangeArticle}
                                                        isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Kerko Artikullin"
                                                                style={{ width: "90%" }}
                                                            // variant="filled"
                                                            // onKeyPress={handleKeyPress}
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                    />

                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="QtyOutput"
                                                            type="text"
                                                            variant="outlined"
                                                            label="QTY Output"
                                                            name='QtyOutput'
                                                            value={qtyOutput}
                                                            onChange={(e) => setQtyOutput(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                            disabled={form2IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithoutVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price Without VAT"
                                                            name="PriceWithoutVAT"
                                                            value={typeof priceWithoutVAT === 'number' ? priceWithoutVAT.toFixed(4) : priceWithoutVAT}
                                                            onChange={(e) => setPriceWithoutVAT(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithVAT}
                                                            disabled={form2IsDisabled}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price With VAT"
                                                            name="PriceWithVAT"
                                                            value={typeof priceWithVAT === 'number' ? priceWithVAT.toFixed(4) : priceWithVAT}
                                                            onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                            onChange={(e) => setPriceWithVAT(e.target.value)}
                                                            disabled={form2IsDisabled}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='SalesDetail__Container__Second__Form__details'>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="Total"
                                                            label="Total"
                                                            variant="outlined"
                                                            name='Total'
                                                            value={typeof total === 'number' ? total.toFixed(2) : total}
                                                            onChange={event => setTotal(event.target.value)}
                                                            onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={form2IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="butonat">
                                                        <div>
                                                            <Button type="submit" variant="success" disabled={form2IsDisabled} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-plus-lg"></i>
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-x-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="responsive">
                                                        <div className="responsive__butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" disabled={form2IsDisabled} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="responsive">
                                                <button type="submit"
                                                    className="btn btn-primary float-end"
                                                    disabled={form2IsDisabled}
                                                >ADD</button>
                                            </div>
                                        </Box>
                                    </form>
                                )}
                                {showEditForm && (
                                    <div className="SalesDetail__Container">
                                        <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                {/* <p style={{ color: "red", fontSize: "16px", fontStyle: "italic", borderBottom: "1px dashed red" }}>Click on a line to edit the data</p> */}
                                                <div className="SalesDetail__Container__Second__Form">
                                                    <div className="SalesDetail__Container__Second__Form__Article" style={{ paddingTop: "12px" }}>
                                                        {/* <TextField
                                                            className="SalesDetail__Container__Form__TextField__Article"
                                                            id="ArticleID"
                                                            select
                                                            label="Select Article"
                                                            name="ArticleID"
                                                            value={formDetailData.ArticleID}
                                                            autoComplete="off"
                                                            // onChange={handleInputChangeDetail}
                                                            onChange={(event) => {
                                                                handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
                                                                fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
                                                            }}
                                                        // onKeyUp={handleCalculatePriceWithoutVATEdit}
                                                        >
                                                            {articles.map((data) => (
                                                                <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField> */}
                                                        <Autocomplete
                                                            fullWidth
                                                            {...defaultPropsArticleEdit}
                                                            id="ArticleID"
                                                            includeInputInList
                                                            onChange={handleAutocompleteChangeArticleEdit}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    label="Artikulli"
                                                                    className="SalesDetail__Container__Form__TextField__Article"

                                                                // variant="filled" 
                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                style: {
                                                                    maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                                },
                                                            }}
                                                            value={articles.find(option => option.ArticleID === formDetailData.ArticleID) || null}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                type="text"
                                                                id="QtyOutput"
                                                                name="QtyOutput"
                                                                label="Qty Output"
                                                                variant="outlined"
                                                                autoComplete="off"
                                                                value={formDetailData.QtyOutput}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithoutVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Price Without VAT"
                                                                name="PriceWithoutVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithVAT("edit")}
                                                            // disabled={form2IsDisabled}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Price With VAT"
                                                                name="PriceWithVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithVAT === 'number' ? formDetailData.PriceWithVAT.toFixed(4) : formDetailData.PriceWithVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="Total"
                                                                label="Total"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="Total"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.Total === 'number' ? formDetailData.Total.toFixed(2) : formDetailData.Total}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePrices("edit")}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-check-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="responsive">
                                                            <div className="responsive__butonat">
                                                                <div>
                                                                    <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-check-lg"></i>
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-x-lg"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </form>
                                    </div>
                                )}
                                <DataGridWithActions
                                    columns={detailsData}
                                    rows={detailsRows}
                                    handleEdit={handleEditDetails}
                                    handleDelete={handleDelete}
                                    showPayment={false}
                                />
                            </div>
                            <br></br>
                            <div className="responsive__Tablet">
                                <div className='SalesDetail__Container__Form'>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount18"
                                            // style={{ width: "100%" }}
                                            id="Amount18"
                                            label="Amount 18"
                                            variant="outlined"
                                            type="text"
                                            name='Amount18'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount18).toFixed(2) : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT18"
                                            // style={{ width: "100%" }}
                                            id="VAT18"
                                            label="VAT 18"
                                            variant="outlined"
                                            type="text"
                                            name='VAT18'
                                            value={valuedetails.length > 0 ? (valuedetails[0].VAT18).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount8"
                                            // style={{ width: "100%" }}
                                            id="Amount8"
                                            label="Amount 8"
                                            variant="outlined"
                                            type="text"
                                            name='Amount8'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount8).toFixed(2) : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT8"
                                            // style={{ width: "100%" }}
                                            id="VAT8"
                                            label="VAT 8"
                                            variant="outlined"
                                            type="text"
                                            name='VAT8'
                                            value={valuedetails.length > 0 ? (valuedetails[0].VAT8).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount0"
                                            // style={{ width: "100%" }}
                                            id="Amount0"
                                            label="Amount 0"
                                            variant="outlined"
                                            type="text"
                                            name='Amount0'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount0).toFixed() : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT0"
                                            // style={{ width: "100%" }}
                                            id="VAT0"
                                            label="VAT 0"
                                            variant="outlined"
                                            type="text"
                                            name='VAT0'
                                            value={valuedetails.length > 0 ? 0 : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__TotalAmount"
                                            // style={{ width: "100%" }}
                                            id="TotalAmount"
                                            label="Total WithOut VAT"
                                            variant="outlined"
                                            type="text"
                                            name='TotalAmount'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toFixed(2) : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VATTotal"
                                            // style={{ width: "100%" }}
                                            id="VATTotal"
                                            label="VAT Total"
                                            variant="outlined"
                                            type="text"
                                            name='VATTotal'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Total"
                                            // style={{ width: "100%" }}
                                            id="Totali"
                                            label="Total With VAT"
                                            variant="outlined"
                                            type="text"
                                            name='Totali'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Total).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                            <button
                                className="button-15"
                                variant="contained"
                                type="submit"
                                style={{
                                    background: buttonContent.backgroundColor,
                                    width: "95%",
                                    height: "55px",
                                    alignContent: "center",
                                    transition: buttonContent.transcation,
                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                    color: "white",
                                    border: "none"
                                }}
                                disabled={form1IsDisabled}
                                // onClick={handleSubmit}
                                onClick={invoiceNR > 0 ? null : handleSubmit}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = buttonContent.onMouseEnter;
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = buttonContent.onMouseLeave;
                                }}
                            >
                                {buttonContent.text}
                            </button>

                            {/* <FaTable /> */}
                        </div>
                }
                <br></br>
            </Paper>
        </div>
    );
}

export default Insert_ZSalesDetail;