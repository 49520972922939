import { useHistory } from "react-router-dom";
import React from "react";
import '../Style/__button.scss'
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';


export default function LogoutButton() {
  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('token-expiration');
    history.push("/");
    window.location.reload();
  }

  return (

    <MenuItem onClick={() => {handleClose(); logout();}}>
      <ListItemIcon>

        <LogoutIcon
          onClick={logout} />
      </ListItemIcon>
      Log Out
    </MenuItem>


  );
}
