// currencyFormatter.js
export const currencyFormatter = (value) => {
    if (value === null || value === undefined) {
        return '0.00';
    }
    return new Intl.NumberFormat('en-US', {
        // style: 'currency',
        // currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};
