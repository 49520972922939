import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { SiHyperledger } from "react-icons/si";
import "jspdf-autotable";
import jwtDecode from "jwt-decode";
import DataGridComponent from '../../Components/DataGridComponent';
import { TrialBalanceGridHeader } from "./GridHeaders/TrialBalanceGridHeader";

export default function TrialBalance() {
    const [data, setData] = useState([]);
    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    // Kodi per Marrjen e informatave nga databaza
    const fetchData = async () => {
        try {
            const headers = authHeader();
            const response = await axios.get(ApiLink.API_URL + 'Reports', { headers, params: { email: logedEmail } });
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        // ledgerID: item.LedgerID,
        ledgerName: item.LedgerName,
        debit: item.Debit,
        credit: item.Credit,
        balance: item.Balance,
        bilanceSide: item.BilanceSide,
        financialStatement: item.FinancialStatements,
        rowInfo: item
    }));

    useEffect(() => {
        fetchData();
    }, [logedEmail]);

    return (
        <div className="Crud__Container">

            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>Bilanci Vërtetues</h3>
                </div>
            </div>
            <div className="first_paragraph">
                <p>Kjo formë është zhvilluar për të vërifikuar bilancin e llogarive kontabel.</p>
            </div>
            <DataGridComponent
                columns={TrialBalanceGridHeader}
                rows={rows} />

        </div>
    )
}