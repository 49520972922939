import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const currencyFormatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export const ArticleGridHeader = [
    { field: 'id', headerName: '#', width: 70, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'barcode', headerName: 'Barkodi', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'name', headerName: 'Emri i Artikullit', width: 530, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'purchasePrice', headerName: 'Cmimi Blerjës', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'salesPrice', headerName: 'Cmimi i Shitjes', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    // { field: 'active', headerName: 'Statusi', width: 100, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'active',
        headerName: 'Statusi',
        width: 70,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
            params.value ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : ""
        )
    },
    {
        field: 'pos',
        headerName: 'POS',
        width: 70,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
            params.value ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : ""
        )
    },
]