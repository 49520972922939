import React, { useState, useEffect } from "react";
import '../../Style/PasqyraTeArdhuraveStyle.scss';
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import { errorAlert } from "../../Components/Alerts";
import '../../Style/VatDeclaration.scss'

export default function BalanceSheet() {
    const headers = authHeader();
    const [data, setData] = useState([]);
    const [lastYearData, setLastYearData] = useState([]);


    const [stokuTotal, setStokuTotal] = useState(0);
    const [teArdhurat, setTeArdhurat] = useState(0);
    const [shpenzimetOperative, setShpenzimetOperative] = useState(0);
    const [malli, setMalli] = useState(0);
    const [txf1000, setTxf1000] = useState(0);
    const [txf1050, setTxf1050] = useState(0);
    const [txf1100, setTxf1100] = useState(0);
    const [txf1150, setTxf1150] = useState(0);
    const [txf1200, setTxf1200] = useState(0);

    const [txf1300, setTxf1300] = useState(0);
    const [txf1350, setTxf1350] = useState(0);
    const [txf1400, setTxf1400] = useState(0);
    const [txf1450, setTxf1450] = useState(0);
    const [txf1500, setTxf1500] = useState(0);
    const [txf1550, setTxf1550] = useState(0);
    const [txf1600, setTxf1600] = useState(0);
    const [txf1650, setTxf1650] = useState(0);
    const [txf1700, setTxf1700] = useState(0);

    const [txf2000, setTxf2000] = useState(0);
    const [txf2050, setTxf2050] = useState(0);
    const [txf2100, setTxf2100] = useState(0);
    const [txf2150, setTxf2150] = useState(0);
    const [txf2200, setTxf2200] = useState(0);
    const [txf2250, setTxf2250] = useState(0);
    const [txf2300, setTxf2300] = useState(0);
    const [txf2350, setTxf2350] = useState(0);

    const [txf2400, setTxf2400] = useState(0);
    const [txf2450, setTxf2450] = useState(0);
    const [txf2500, setTxf2500] = useState(0);
    const [txf2550, setTxf2550] = useState(0);
    const [txf2600, setTxf2600] = useState(0);

    const [txf3000, setTxf3000] = useState(0);
    const [txf3100, setTxf3100] = useState(0);
    const [txf3200, setTxf3200] = useState(0);



    const [companyName, setCompanyName] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [address, setAdress] = useState("");
    const [phone, setPhone] = useState("");

    const [loading, setLoading] = useState(true);
    const vitAktual = new Date().getFullYear();
    const vitiKaluar = new Date().getFullYear() - 1;

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompanyName(response.data[0].CompanyName);
            setIdNumber(response.data[0].IdentificationNumber);
            setAdress(response.data[0].Address);
            setPhone(response.data[0].PhoneNumbers);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };



    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'FinanceStatement/BalanceSheet', { headers, params: { year: vitAktual } });
            // Shëndërro vlerat null në 0 para se të ruhen në array
            const processedData = response.data.map(item => {
                Object.keys(item).forEach(key => {
                    if (item[key] === null) {
                        item[key] = 0;
                    }
                });
                return item;
            });
            setTeArdhurat(response.data[0]._TeArdhurat);
            setShpenzimetOperative(response.data[0]._ShpenzimetOprative);
            setMalli(response.data[0]._1150_Malli);
            setTxf1000(response.data[0]._1000_Paraja);
            setTxf1050(response.data[0]._1050_InvestimetFinanciareAfatshkurtera);
            setTxf1100(response.data[0]._1100_LlogariteArketueshme);
            setTxf1150(response.data[0]._1150_Malli);
            setTxf1200(response.data[0]._1200_PasuriteTjeraAfatshkurtera);

            setTxf1300(response.data[0]._1300_InvestimetPjesmarrje);
            setTxf1350(response.data[0]._1350_InvestimetFiliale);
            setTxf1400(response.data[0]._1400_InvestimetFinanciareAfatgjata);
            setTxf1450(response.data[0]._1450_PronaPaisjetImpiantet);
            setTxf1500(response.data[0]._1500_PronaInvestuese);
            setTxf1550(response.data[0]._1550_InvestimetNeVijim);
            setTxf1600(response.data[0]._1600_PasuritePaprekshme);
            setTxf1650(response.data[0]._1650_PasuriteShtyraTatimore);
            setTxf1700(response.data[0]._1300_InvestimetPjesmarrje);

            setTxf2000(response.data[0]._2000_MbiterheqjaBankare);
            setTxf2050(response.data[0]._2050_LlogaritePagueshmeTjera);
            setTxf2100(response.data[0]._2100_KrediteHuateAfatshkurter);
            setTxf2150(response.data[0]._2150_InteresiPagueshme);
            setTxf2200(response.data[0]._2200_TatimiFitimPagueshem);
            setTxf2250(response.data[0]._2250_ProvizionetAfatshkurta);
            setTxf2300(response.data[0]._2300_DetyrimetLizingutAfatshkurter);
            setTxf2350(response.data[0]._2350_DetyrimetTjeraAfatshkurtera);

            setTxf2400(response.data[0]._2400_KrediteHuateAfatgjata);
            setTxf2450(response.data[0]._2450_ProvizionetAfatgjata);
            setTxf2500(response.data[0]._2500_DetyrimetLizingutAfatgjate);
            setTxf2550(response.data[0]._2550_DetyrimetShtyraTatimore);
            setTxf2600(response.data[0]._2600_DetyrimetTjeraAfatgjata);

            setTxf3000(response.data[0]._3000_KapitaliPronarit);
            setTxf3100(response.data[0]._3100_FitimiBartur);
            setTxf3200(response.data[0]._3200_RezervatTjera);
        
            // Ruaj array-in e përpunuar me vlerat null të zëvendësuar me 0
            setData(processedData);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Kthehet nga serveri për mungesë qasje
            } else {
                errorAlert("Gabim në kërkesë:", error);

                if (error.response && error.response.data && error.response.data.errorMessage) {
                    errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
                } else if (error.message) {
                    errorAlert(`Gabim: ${error.message}`);
                } else {
                    errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
                }
            }
        }
    };

    const fetchTotalAmount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingBooks/TotaliStokut', { headers });
            setStokuTotal(response.data[0].StokuTotal);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchLastYearData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'FinanceStatement/BalanceSheet', { headers, params: { year: vitiKaluar } });
            // Shëndërro vlerat null në 0 para se të ruhen në array
            const processedData = response.data.map(item => {
                Object.keys(item).forEach(key => {
                    if (item[key] === null) {
                        item[key] = 0;
                    }
                });
                return item;
            });

            // Ruaj array-in e përpunuar me vlerat null të zëvendësuar me 0
            setLastYearData(processedData); setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Kthehet nga serveri për mungesë qasje
            } else {
                errorAlert("Gabim në kërkesë:", error);

                if (error.response && error.response.data && error.response.data.errorMessage) {
                    errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
                } else if (error.message) {
                    errorAlert(`Gabim: ${error.message}`);
                } else {
                    errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
                }
            }
        }
    };


    let KMSH = malli - stokuTotal;
    let fitimiBruto = teArdhurat - KMSH;
    let fitimiParaTatimit = fitimiBruto - shpenzimetOperative;
    let tatimiFitim = (fitimiParaTatimit < 0 ) ?   0 : (fitimiParaTatimit * (10 / 100));
    let fitimiNeto = fitimiParaTatimit - tatimiFitim;
    let stoku = malli - KMSH;


    let pasuritAfatshkurtera = Number(txf1000) + Number(txf1050) + Number(txf1100) + Number(stoku) + Number(txf1200);
    let pasuritAfatgjate = Number(txf1300) + Number(txf1350) + Number(txf1400) + Number(txf1450) + Number(txf1500) + Number(txf1550) + Number(txf1600) + Number(txf1650) + Number(txf1700);

    let gjithsejPasurit = Number(pasuritAfatshkurtera) + Number(pasuritAfatgjate);

    let detyrimetAfatshkurtera = Number(txf2000) + Number(txf2200) + Number(txf2050) + Number(txf2100) + Number(txf2150) + Number(txf2250) + Number(txf2300) + Number(txf2350) + Number(tatimiFitim);
    let detyrimetAfatgjate = Number(txf2400) + Number(txf2450) + Number(txf2500) + Number(txf2550) + Number(txf2600);

    let gjithsejDetyrimet = Number(detyrimetAfatshkurtera) + Number(detyrimetAfatgjate);

    let ekuiteti = Number(txf3000) + Number(fitimiNeto)+ Number(txf3100) + Number(txf3200);
    let totaliEkuitet_Detyrime = Number(gjithsejDetyrimet) + Number(ekuiteti);

    let tatimiFitimTotal = Number(tatimiFitim) + Number(txf2200);




    useEffect(() => {
        fetchCompaniesData();
        fetchData();
        fetchLastYearData();
        fetchTotalAmount();
    }, []);
    return (
        <div style={{ paddingTop: "50px" }}>
            <div className='VatDeclaration__Container'>
                <div className='VatDeclaration__Container__HeaderData'>
                    <div>
                        <h5><b>Pasqyra e pozitës financiare më 31 dhjetor </b></h5>
                    </div>
                    <hr></hr>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[1]</b></h5>
                            <h5>Periudha tatimore:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{vitAktual}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[3]</b></h5>
                            <h5>Numri Fiskal:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{idNumber}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[4]</b></h5>
                            <h5>Emri i tatimpaguesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{companyName}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[5]</b></h5>
                            <h5>Adresa e tatimpaguesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{address}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[6]</b></h5>
                            <h5>Personi kontaktues:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{companyName}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[6a]</b></h5>
                            <h5>Kodi primar i veprimtarisë:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>0000</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[7]</b></h5>
                            <h5>Telefoni:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{phone}</h5>
                        </div>
                    </div>
                </div>
                <hr></hr>

                <div className='Pasqyra__Container__Table'>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>Nr. i zerit</h4>
                            <h5>Pershkrimi</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2024</h5>
                            <h5>Më 31.12.2023</h5>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5></h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h5></h5>
                            <h5>€</h5>
                            <h5>€</h5>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5 style={{ fontWeight: "bold" }}>PASURITE</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5 style={{ fontWeight: "bold" }}>Pasurite afatshkurtra</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1000</h4>
                            <h6>Paraja dhe ekuivalentët e parasë</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1000_Paraja.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1000_Paraja.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>

                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1050</h4>
                            <h6>Investimet financiare afatshkurtra</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1050_InvestimetFinanciareAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1050_InvestimetFinanciareAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1100</h4>
                            <h6>Llogaritë e arkëtueshme tregtare dhe të tjera</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1100_LlogariteArketueshme.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1100_LlogariteArketueshme.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1150</h4>
                            <h6>Stoqet</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>
                                {stoku !== undefined ? stoku.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
                            </h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1150_Malli.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1200</h4>
                            <h6>Pasuritë e tjera afatshkurtra</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1200_PasuriteTjeraAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1200_PasuriteTjeraAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4 style={{ fontWeight: "bold" }}>A</h4>
                            <h6 style={{ fontWeight: "bold" }}>Gjithsej Pasuritë afatshkurtra</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{pasuritAfatshkurtera !== undefined ? pasuritAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._100_PasuriteAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <br></br>

                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5>Pasurite afatgjata</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1300</h4>
                            <h6>Investimet në pjesëmarrje</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1300_InvestimetPjesmarrje.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1300_InvestimetPjesmarrje.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1350</h4>
                            <h6>Investimet në filiale</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1350_InvestimetFiliale.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1350_InvestimetFiliale.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1400</h4>
                            <h6>Investimet financiare afatgjata</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1400_InvestimetFinanciareAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1400_InvestimetFinanciareAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1450</h4>
                            <h6>Prona, pajisjet dhe impiantet</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1450_PronaPaisjetImpiantet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1450_PronaPaisjetImpiantet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1500</h4>
                            <h6>Prona investuese</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1500_PronaInvestuese.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1500_PronaInvestuese.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1550</h4>
                            <h6>Investimet në vijim</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1550_InvestimetNeVijim.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1550_InvestimetNeVijim.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1600</h4>
                            <h6>Pasuritë e paprekshme</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1600_PasuritePaprekshme.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1600_PasuritePaprekshme.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1650</h4>
                            <h6>Pasuritë e shtyra tatimore</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1650_PasuriteShtyraTatimore.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1650_PasuriteShtyraTatimore.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>1700</h4>
                            <h6>Pasuritë e tjera afatgjata</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._1700_PasuriteTjeraAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._1700_PasuriteTjeraAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>B</h4>
                            <h6>Gjithsej Pasuritë afatgjata</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{pasuritAfatgjate !== undefined ? pasuritAfatgjate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._200_PasuriteAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <br></br>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4 style={{ fontWeight: "bold" }}>C = A + B</h4>
                            <h6 style={{ fontWeight: "bold" }}>Gjithsej Pasuritë</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{gjithsejPasurit !== undefined ? gjithsejPasurit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._100_200_GjithsejPasurite.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <br></br>

                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5 style={{ fontWeight: "bold" }}>DETYRIMI DHE EKUITETI</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5 style={{ fontWeight: "bold" }}>DETYRIMET</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5>Detyrimet afatshkurtra</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2000</h4>
                            <h6>Mbitërheqje bankare</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2000_MbiterheqjaBankare.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2000_MbiterheqjaBankare.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2050</h4>
                            <h6>Llogarit e pagueshme tregtare dhe të tjera</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2050_LlogaritePagueshmeTjera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2050_LlogaritePagueshmeTjera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2100</h4>
                            <h6>Kreditë dhe huatë, pjesa afatshkurtër</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2100_KrediteHuateAfatshkurter.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2100_KrediteHuateAfatshkurter.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2150</h4>
                            <h6>Interesi i pagueshëm</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2150_InteresiPagueshme.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2150_InteresiPagueshme.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2200</h4>
                            <h6>Tatimi në fitim i pagueshëm</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>
                                {tatimiFitimTotal !== undefined ? tatimiFitimTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
                            </h6>

                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2200_TatimiFitimPagueshem.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2250</h4>
                            <h6>Provizionet afatshkurtra</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2250_ProvizionetAfatshkurta.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2250_ProvizionetAfatshkurta.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2300</h4>
                            <h6>Detyrimet ndaj lizingut, pjesa afatshkurtër</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2300_DetyrimetLizingutAfatshkurter.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2300_DetyrimetLizingutAfatshkurter.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2350</h4>
                            <h6>Detyrimet e tjera afatshkurtra</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2350_DetyrimetTjeraAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2350_DetyrimetTjeraAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4 style={{ fontWeight: "bold" }}>D</h4>
                            <h6 style={{ fontWeight: "bold" }}>Gjithsej detyrimet afatshkurtra</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{detyrimetAfatshkurtera !== undefined ? detyrimetAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._300_DetyrimetAfatshkurtera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <br></br>

                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5>Detyrimet afatgjata</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2400</h4>
                            <h6>Kreditë dhe huatë, pjesa afatgjatë</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2400_KrediteHuateAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2400_KrediteHuateAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2450</h4>
                            <h6>Provizionet afatgjate</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2450_ProvizionetAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2450_ProvizionetAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2500</h4>
                            <h6>Detyrimet ndaj lizingut, pjesa afatgjate</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2500_DetyrimetLizingutAfatgjate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2500_DetyrimetLizingutAfatgjate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2550</h4>
                            <h6>Detyrimet e shtyra tatimore</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2550_DetyrimetShtyraTatimore.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2550_DetyrimetShtyraTatimore.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>2600</h4>
                            <h6>Detyrimet e tjera afatgjate</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._2600_DetyrimetTjeraAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._2600_DetyrimetTjeraAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4 style={{ fontWeight: "bold" }}>E</h4>
                            <h6 style={{ fontWeight: "bold" }}>Gjithsej detyrimet afatgjate</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{detyrimetAfatgjate !== undefined ? detyrimetAfatgjate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._400_DetyrimetAfatgjata.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <br></br>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4 style={{ fontWeight: "bold" }}></h4>
                            <h6 style={{ fontWeight: "bold" }}>Gjithsej detyrimet</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{gjithsejDetyrimet !== undefined ? gjithsejDetyrimet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._300_400_GjithsejDetyrimet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <br></br>
                    <div className='Pasqyra__Container__Table__Row__Pershkrimi'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4></h4>
                            <h5>EKUITETI</h5>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            {/* <h5>Nr. i shenimit</h5>
                            <h5>Më 31.12.2023</h5>
                            <h5>Më 31.12.2022</h5> */}
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>3000</h4>
                            <h6>Kapitali aksionar/Kapitali i pronarit</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._3000_KapitaliPronarit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._3000_KapitaliPronarit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>3100</h4>
                            <h6>Fitimet e mbajtura</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>
                                {(((data && data.length > 0 ? data[0]._3100_FitimiBartur : 0) + parseFloat(fitimiNeto)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0)}
                            </h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._3100_FitimiBartur.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4>3200</h4>
                            <h6>Rezervat e tjera</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6>0</h6>
                            <h6>{((data && data.length > 0 ? data[0]._3200_RezervatTjera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                            <h6 style={{ backgroundColor: "lightgray" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._3200_RezervatTjera.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4 style={{ fontWeight: "bold" }}>F</h4>
                            <h6 style={{ fontWeight: "bold" }}>Gjithsej Ekuiteti</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{ekuiteti !== undefined ? ekuiteti.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._500_Ekuiteti.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                    <br></br>
                    <div className='Pasqyra__Container__Table__Row'>
                        <div className='Pasqyra__Container__Table__Row__Left'>
                            <h4 style={{ fontWeight: "bold" }}>G = D+E+F</h4>
                            <h6 style={{ fontWeight: "bold" }}>Gjithsej ekuiteti dhe detyrimet</h6>
                        </div>
                        <div className='Pasqyra__Container__Table__Row__Right'>
                            <h6 className='Pasqyra__Container__Table__Row__Right__Hide'></h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{totaliEkuitet_Detyrime !== undefined ? totaliEkuitet_Detyrime.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h6>
                            <h6 style={{ backgroundColor: "yellow" }}>{((lastYearData && lastYearData.length > 0 ? lastYearData[0]._300_500_EkuitetiDetyrimet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0) || 0)}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}