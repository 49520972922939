import '../Style/__login.scss';
import React from 'react';
// import '../Form/LoginDesign.css'
import foto1 from '../Form/img/photo.svg'
import logo from "../Images/accountreelogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import AuthForm from './AuthForm';
import { saveToken } from './HandleJWT';
import { useHistory } from 'react-router-dom';
import { errorAlert } from '../Components/Alerts';
import { ApiLink } from "../APILink/ApiLink";
import '../Style/Button.css';
export default function Login() {
  // const [errors, setErrors] = useState([]);
  // const { update } = useContext(AuthenticationContext);
  const history = useHistory();
  const login = async (credentials, setLoading) => {
    try {
      const res = await axios.post(ApiLink.API_URL + 'AuthProva/login', credentials);
      saveToken(res.data);
      history.push('/dashboard');
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        errorAlert('Incorrect Email or Password');
      } else {
        errorAlert(error);
      }
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // const onSubmitHandler = (data) => {
  //   login(data)
  // }

  const onSubmitHandler = (data, setLoading) => {
    setLoading(true);
    login(data, setLoading);
  }
  return (
    <>
      <div className="Login_container">
        <div className="Login_forms-container">
          <div className="Login_signin-signup">
            <div className="sign-in-form">
              <div className="logo">
                <img src={logo} alt="webscript" />
              </div>
              <h1 className="Login_title">Log in</h1>
              {/* <DisplayErrors errors={errors} /> */}
              <AuthForm model={{ email: '', password: '' }}
                onSubmit={onSubmitHandler}
              />
            </div>
          </div>
        </div>

        <div className="panels-container">
          <div className="panel left-panel">
            <div className="Login_content">
              <div className='Login_h3'>
                <h3 >Thjeshtoni kontabilitetin, përforconi suksesin tuaj.</h3>
                {/* <h3 >Simplify your accounting, amplify your success.</h3> */}
              </div>
              {/* <p>
                Accountree is designed to simplify the accounting process for businesses of all sizes. With features like real-time reporting and automatic bank feeds, our software streamlines financial management so you can focus on growing your business!
              </p> */}
              <p>
                Accountree është zhvilluar për të bërë më të lehtë procesin e kontabilitetit për bizneset e çdo kategorie. Me karakteristika si raportim në kohë reale nga çdo lloj paisjesh, aplikacioni ynë bën më të lehtë menaxhimin financiar, duke u përqëndruar në çdo lëvizje të biznesit tuaj!
              </p>
              {/* <button className="Login_btn transparent" id="sign-up-btn">
                Learn more
              </button> */}
            </div>
            <img src={foto1} className="image" alt="" />
          </div>
        </div>
      </div>
    </>
  )
}