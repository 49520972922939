import React, { useEffect, useState } from "react";
import '../../../Style/VATFields.scss'
import axios from "axios";
import { ApiLink } from "../../../APILink/ApiLink";
import authHeader from "../../../Auth/authHeader";
import { errorAlert } from "../../../Components/Alerts";

export default function FirstTable(props) {
    const [data, setData] = useState([]);
    const [txf71, setTxf71] = useState(0);
    const [txf72, setTxf72] = useState(0);
    const [txf68, setTxf68] = useState(0);
    const [txf30, setTxf30] = useState(0);

    // let txf68 = vatYearBalance;
    // let txf30 = data && data.length > 0 ? (data[0].Total_TVSH_8_dhe_18 !== null ? data[0].Total_TVSH_8_dhe_18 : 0) : 0;
    let txf67 = data && data.length > 0 ? (data[0]._67_Total_TVSH_8_dhe_18 !== null ? data[0]._67_Total_TVSH_8_dhe_18 : 0) : 0;

    const [vatYearBalance, setVATYearBalance] = useState(0);
    const [vat01, setVat01] = useState(0);
    const [vat02, setVat02] = useState(0);
    const [vat03, setVat03] = useState(0);
    const [vat04, setVat04] = useState(0);
    const [vat05, setVat05] = useState(0);
    const [vat06, setVat06] = useState(0);
    const [vat07, setVat07] = useState(0);
    const [vat08, setVat08] = useState(0);
    const [vat09, setVat09] = useState(0);
    const [vat10, setVat10] = useState(0);
    const [vat11, setVat11] = useState(0);
    const [vat12, setVat12] = useState(0);


    const headers = authHeader();

    // Përditëso txf30 kur props.month ndryshon
    useEffect(() => {
        if (props.month !== 0) {
            try {
                const fetchData = async () => {
                    const headers = authHeader();
                    const response = await axios.get(ApiLink.API_URL + 'TaxReport/vatDeclaration', { headers, params: { month: props.month } });
                    setData(response.data);
                    setTxf30(response.data[0]?.Total_TVSH_8_dhe_18 || 0);
                };
                fetchData();
            } catch (error) {
                console.log(error);
            }
        }
    }, [props.month]);


    const fetchVATYearBalance = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'TaxReport/VATYearBalance', { headers });
            setVATYearBalance(response.data[0]?.Debit || 0);

        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchVATMonthBalance = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'TaxReport/VATMonthBalance', { headers });
            setVat01(response.data[0]?.Difference || 0);
            setVat02(response.data[1]?.Difference || 0);
            setVat03(response.data[2]?.Difference || 0);
            setVat04(response.data[3]?.Difference || 0);
            setVat05(response.data[4]?.Difference || 0);
            setVat06(response.data[5]?.Difference || 0);
            setVat07(response.data[6]?.Difference || 0);
            setVat08(response.data[7]?.Difference || 0);
            setVat09(response.data[8]?.Difference || 0);
            setVat10(response.data[9]?.Difference || 0);
            setVat11(response.data[10]?.Difference || 0);
            setVat12(response.data[11]?.Difference || 0);

        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [janari68, setJanari68] = useState(0);

    const [shkurti68, setShkurti68] = useState(0);

    const [marsi68, setMarsi68] = useState(0);

    const [prilli68, setPrilli68] = useState(0);

    const [maj68, setMaj68] = useState(0);

    const [qershor68, setQershor68] = useState(0);

    const [korrik68, setKorrik68] = useState(0);

    const [gusht68, setGusht68] = useState(0);

    const [shtator68, setShtator68] = useState(0);

    const [tetor68, setTetor68] = useState(0);

    const [nentor68, setNentor68] = useState(0);

    const [dhjetor68, setDhjetor68] = useState(0);





    useEffect(() => {

        let jan68 = vatYearBalance;
        setJanari68(jan68);
        let jan71 = jan68 >= 0 ? Number(vatYearBalance) + Number(vat01) : 0

        let shk68 = jan71 >= 0 ? jan71 : 0;
        setShkurti68(shk68);
        let shk71 = shk68 >= 0 ? Number(shk68) + Number(vat02) : vat02;

        let mar68 = shk71 >= 0 ? shk71 : 0;
        setMarsi68(mar68);
        let mar71 = mar68 >= 0 ? Number(mar68) + Number(vat03) : vat03;

        let pr68 = mar71 >= 0 ? mar71 : 0;
        setPrilli68(pr68);
        let pr71 = pr68 >= 0 ? Number(pr68) + Number(vat04) : vat04;

        let ma68 = pr71 >= 0 ? pr71 : 0;
        setMaj68(ma68);
        let ma71 = ma68 >= 0 ? Number(ma68) + Number(vat05) : vat05;

        // let qe68 = ma71 >= 0 ? pr71 : 0;
        let qe68 = ma71 >= 0 ? ma71 : 0;
        setQershor68(qe68);
        let qe71 = qe68 >= 0 ? Number(qe68) + Number(vat06) : vat06;

        let ko68 = qe71 >= 0 ? qe71 : 0;
        setKorrik68(ko68);
        // let ko71 = ko68 >= 0 ? Number(qe68) + Number(vat07) : vat07;
        let ko71 = ko68 >= 0 ? Number(ko68) + Number(vat07) : vat07;

        let gu68 = ko71 >= 0 ? ko71 : 0;
        setGusht68(gu68);
        let gu71 = gu68 >= 0 ? Number(gu68) + Number(vat08) : vat08;

        let sht68 = gu71 >= 0 ? gu71 : 0;
        setShtator68(sht68);
        let sh71 = sht68 >= 0 ? Number(sht68) + Number(vat09) : vat09;

        let te68 = sh71 >= 0 ? sh71 : 0;
        setTetor68(te68);
        let te71 = te68 >= 0 ? Number(te68) + Number(vat10) : vat10;

        let ne68 = te71 >= 0 ? te71 : 0;
        setNentor68(ne68);
        let ne71 = ne68 >= 0 ? Number(ne68) + Number(vat11) : vat11;

        let dh68 = ne71 >= 0 ? ne71 : 0;
        setDhjetor68(dh68);
        let dh71 = dh68 >= 0 ? Number(dh68) + Number(vat12) : vat12;

    }, [vatYearBalance, vat01, vat02, vat03, vat04, vat05, vat06, vat07, vat08, vat09, vat10, vat11, vat12])

    useEffect(() => {
        const monthValues = {
            1: janari68,
            2: shkurti68,
            3: marsi68,
            4: prilli68,
            5: maj68,
            6: qershor68,
            7: korrik68,
            8: gusht68,
            9: shtator68,
            10: tetor68,
            11: nentor68,
            12: dhjetor68
        };

        const monthValue = monthValues[props.month] || 0;

        setTxf68(monthValue);

        const Txf71_72 = (monthValue + txf67) - txf30;
        if (Txf71_72 < 0) {
            setTxf72(Math.abs(Txf71_72));
            setTxf71(0);
        } else {
            setTxf71(Txf71_72);
            setTxf72(0);
        }
    }, [props.month, txf67, txf30, janari68, shkurti68, marsi68, prilli68, maj68, qershor68, korrik68, gusht68, shtator68, tetor68, nentor68, dhjetor68]);



    useEffect(() => {
        // fetchData(props.month);
        fetchVATYearBalance();
        fetchVATMonthBalance();
    }, []);

    return (
        <>
            <div className="VATFields__Container">
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                        </div>
                        <div className='VATFields__Container__fields__border' style={{ backgroundColor: "red", display: "flex", justifyContent: "center" }}>
                            <p>Furnizimet</p>
                        </div>
                    </div>
                    <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        <p>{props.shuma}</p>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[9]</b></p>
                            <p>Shitjet e liruara pa të drejtë kreditimi</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[9]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._9_Shitjet_Pa_Kreditim : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                    <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        <p>{props.shuma}</p>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[10]</b></p>
                            <p> Shitjet e liruara me të drejtë kreditimi</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[10]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._10_Shitjet_Liruara_Me_Kreditim : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._10_Shitjet_Liruara_Me_Kreditim : 0) || 0).toFixed(2)}</p>

                        </div> */}
                    </div>
                    <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        <p>{props.shuma}</p>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[11]</b></p>
                            <p>Eksportet</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[11]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._11_Eksportet : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._11_Eksportet : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div className='VATFields__Container__fields__New__Text' style={{}}>
                        <p>TVSH E llogaritur</p>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[12]</b></p>
                            <p>Shitjet e tatueshme me normën 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[12]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._12_Shitjet18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._12_Shitjet18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <p>[13]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._12_Shitjet18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[14]</b></p>
                            <p>Shitjet e tatueshme me normën 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[14]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._14_Shitjet8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._14_Shitjet8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[15]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._14_Shitjet8 : 0) || 0) * 0.08).toFixed(2)}</p>

                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[16]</b></p>
                            <p>Nota debitore e lëshuar,nota kreditore e pranuar me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[16]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._16_NotaDebitore18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._16_NotaDebitore18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[17]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{((data && data.length > 0 ? data[0]._16_NotaDebitore18 : 0) || 0) * 0.18.toFixed(2)}</p>

                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[18]</b></p>
                            <p>Nota debitore e lëshuar,nota kreditore e pranuar me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[18]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._18_NotaDebitore8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._18_NotaDebitore8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[19]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._18_NotaDebitore8 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[20]</b></p>
                            <p> Fatura e borxhit të keq e pranuar, inkasimi I B.K. me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[20]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._20_BorgjiKeq18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._20_BorgjiKeq18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[21]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._20_BorgjiKeq18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[22]</b></p>
                            <p> Fatura e borxhit të keq e pranuar, inkasimi I B.K. me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[22]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._22_BorgjiKeq8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._22_BorgjiKeq8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[23]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._22_BorgjiKeq8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[24]</b></p>
                            <p> Rregullimet për të rritur TVSH-në me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[24]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._24_Rregullimet18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._24_Rregullimet18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[25]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._24_Rregullimet18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[26]</b></p>
                            <p>Rregullimet për të rritur TVSH-në / Ngarkesa e kundërt për Energjinë me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[26]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._26_Rregullimet8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._26_Rregullimet8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[27]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._26_Rregullimet8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[28]</b></p>
                            <p> Blerjet që i nënshtrohen ngarkeses së kundërt</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[28]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._28_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._28_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[29]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._28_NgarkesaKundert18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields' style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[30]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ backgroundColor: "lightGray" }}>
                            <p>{txf30.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <div className="VATFields__Container">
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                        </div>
                        <div className='VATFields__Container__fields__border' style={{ backgroundColor: "red", display: "flex", justifyContent: "center" }}>
                            <p>Blerjet</p>
                        </div>
                    </div>
                    <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        <p>{props.shuma}</p>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[31]</b></p>
                            <p>Blerjet dhe importet pa TVSH</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[31]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._31_BlerjetPaTVSH : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._31_BlerjetPaTVSH : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p></p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                            <p>{((data && data.length > 0 ? data[0]._31_BlerjetPaTVSH : 0) || 0).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[32]</b></p>
                            <p> Blerjet dhe importet investive pa TVSH</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[32]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._32_Blerjet_dhe_Imp_Inv_PaTVSH : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._32_Blerjet_dhe_Imp_Inv_PaTVSH : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p></p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                            <p>{((data && data.length > 0 ? data[0]._32_Blerjet_dhe_Imp_Inv_PaTVSH : 0) || 0).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[33]</b></p>
                            <p>Blerjet dhe importet me TVSH jo të zbritshme</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[33]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._33_Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._33_Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p></p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                            <p>{((data && data.length > 0 ? data[0]._33_Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme : 0) || 0).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[34]</b></p>
                            <p> Blerjet dhe importet investive me TVSH jo të zbritshme</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[34]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._34_Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._34_Blerjet_dhe_Imp_Inv_me_TVSH_jo_TeZbritshme : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p></p>
                        </div>
                        <div className='VATFields__Container__fields__New__Text'>
                            <p>TVSH E zbritshme</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[35]</b></p>
                            <p> Importet me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[35]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._35_Importet18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._35_Importet18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[36]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._35_Importet18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[37]</b></p>
                            <p> Importet me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[37]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._37_Importet8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._37_Importet8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[38]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._37_Importet8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[39]</b></p>
                            <p>Importet investive me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[39]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._39_ImportetInvestive18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div 
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._39_ImportetInvestive18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[40]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._39_ImportetInvestive18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[41]</b></p>
                            <p>Importet investive me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[41]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._41_ImportetInvestive8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._41_ImportetInvestive8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[42]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._41_ImportetInvestive8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[43]</b></p>
                            <p>Blerjet vendore me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[43]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._43_BlerjetVendore18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._43_BlerjetVendore18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[44]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._43_BlerjetVendore18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[45]</b></p>
                            <p>Blerjet vendore me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[45]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._45_BlerjetVendore8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._45_BlerjetVendore8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[46]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._45_BlerjetVendore8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[47]</b></p>
                            <p>Blerjet investive vendore me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[47]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._47_BlerjetInvestive18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._47_BlerjetInvestive18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[48]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._47_BlerjetInvestive18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[49]</b></p>
                            <p>Blerjet investive vendore me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[49]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._49_BlerjetInvestive8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._49_BlerjetInvestive8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[50]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._49_BlerjetInvestive8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[51]</b></p>
                            <p>Blerjet nga fermeret vendore me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[51]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._51_BlerjetNgaFermeret8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._51_BlerjetNgaFermeret8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[52]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._51_BlerjetNgaFermeret8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[53]</b></p>
                            <p>Nota debitore e pranuar, nota kreditore e lëshuar me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[53]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._53_NotaKreditore18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._53_NotaKreditore18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[54]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._53_NotaKreditore18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[55]</b></p>
                            <p>Nota debitore e pranuar, nota kreditore e lëshuar me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[55]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._55_NotaKreditore8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._55_NotaKreditore8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[56]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._55_NotaKreditore8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[57]</b></p>
                            <p>Fatura e borxhit të keq e lëshuar, pagesa e borxhit të keq me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[57]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._57_BorgjiKeq18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._57_BorgjiKeq18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[58]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._57_BorgjiKeq18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[59]</b></p>
                            <p>Fatura e borxhit të keq e lëshuar, pagesa e borxhit të keq me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[59]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._59_BorgjiKeq8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._59_BorgjiKeq8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[60]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._59_BorgjiKeq8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[61]</b></p>
                            <p>Rregullimet për të ulur TVSH-në me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[61]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._61_Rregullimet18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._61_Rregullimet18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[62]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._61_Rregullimet18 : 0) || 0) * 0.18).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[63]</b></p>
                            <p> Rregullimet për të ulur TVSH-në / Ngarkesa e kundërt për Energjinë me normë 8%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[63]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._63_Rregullimet8 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._63_Rregullimet8 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[64]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._63_Rregullimet8 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[65]</b></p>
                            <p>E drejta e kreditimit të TVSH-së në lidhje me ngarkesën e kundërt me normë 18%</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[65]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[66]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "block" }}>
                            <p>{(((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0) * 0.08).toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields' style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ display: "flex" }}>
                        <div >
                            <p>[67]</p>
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ backgroundColor: "lightGray" }}>
                            <p>{txf67.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <div className="VATFields__Container">
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                        </div>
                        <div className='VATFields__Container__fields__border' style={{ backgroundColor: "red", display: "flex", justifyContent: "center" }}>
                            <p>Kalkulimi</p>
                        </div>
                    </div>
                    <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        <p>{props.shuma}</p>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[68]</b></p>
                            <p>Teprica a TVSH-së e bartur nga muaji i kaluar</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[68]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{txf68.toFixed(2)}</p>
                                {/* <p>{txf68.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p> */}
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[69]</b></p>
                            <p>Balanca kreditore e mbetur 69 = ((67+68)-30){">"}0</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[69]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[70]</b></p>
                            <p>Kërkesa për rimbursim</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[70]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[71]</b></p>
                            <p>Kreditimi i tvsh-së per muajin pasues 71 =(69-70)</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[71]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{txf71.toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border'>
                            <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        </div>
                    </div>
                </div>
                <div className='VATFields__Container__fields'>
                    <div className="VATFields__Container__fields__StaticData">
                        <div style={{ display: "flex" }}>
                            <p><b>[72]</b></p>
                            <p>TVSH që duhet të paguhet 72 =(30-67-68){">"}0</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <p>[72]</p>
                            </div>
                            <div className='VATFields__Container__fields__border'>
                                <p>{txf72.toFixed(2)}</p>
                            </div>
                        </div>
                        {/* <div className='VATFields__Container__fields__border' style={{ backgroundColor: "lightgray" }}>
                            <p>{((data && data.length > 0 ? data[0]._65_NgarkesaKundert18 : 0) || 0).toFixed(2)}</p>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div >
                        </div>
                        <div className='VATFields__Container__fields__New__border' style={{ display: "none" }}>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}