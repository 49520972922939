import React from 'react';

export const EditInputs = (props) => {
  return (

    <div className="input-group mb-3">
      <span className="input-group-text col-3 col-sm-3">{props.title} </span>
      <input
        className="form-control"
        type={props.type}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </div>
  )
}
export const InsertInputTypes = (props) => {
  return (
    <div className="input-group mb-3">
      <span className="input-group-text col-3 col-sm-3">{props.name} </span>
      <input
        type={props.type}
        className="form-control"
        value={props.value}
        onChange={props.onChange}
        required
      />
    </div>
  )
}
export const Checked = (props) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={props.Checked}
        onChange={props.onChange}
      />
      Active
    </label>
  )
}
