import React, { useState, useEffect } from "react";
// import { logout } from "../Auth/HandleJWT";
import LogoutButton from "../Auth/Logout";
import "../Style/__header.scss";
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import { styled, alpha } from '@mui/material/styles';
import jwtDecode from "jwt-decode";
// import AppBar from '@mui/material/AppBar';
// import Playground from '../Components/Playground'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
// import InputBase from '@mui/material/InputBase';
// import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Tooltip from '@mui/material/Tooltip';
// import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
// import Logout from '@mui/icons-material/Logout';
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
// import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
import { errorAlert } from "../Components/Alerts";
import authHeader from "../Auth/authHeader";
import { ApiLink } from "../APILink/ApiLink";
import axios from "axios";
// import StarBorder from '@mui/icons-material/StarBorder';
// import logout from '../../src/Auth/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import * as RiIcons from 'react-icons/ri';
import InsertSupplier from "../CRUD/Suppliers/InsertSuppliers"





const Header = () => {
    const [email, setEmail] = useState("");
    const headers = authHeader();
    const [role, setRole] = useState("");
    // const [companyInfo, setCompanyInfo] = useState([]);
    const [logo, setLogo] = useState("");
    const [companyID, setCompanyID] = useState("");
    const [companyName, setCompanyName] = useState("");
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setEmail(decodedToken.email);
            setRole(decodedToken.role);
        }
    }, []);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [forms, setForms] = React.useState(null);
    const openForms = Boolean(forms);
    const handleClickForms = (event) => {
        setForms(event.currentTarget);
    };
    const handleCloseForms = () => {
        setForms(null);
    };

    const fetchCompanyInfo = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getLogo', { headers });
            setCompanyID(response.data[0].CompanyID);
            setLogo(response.data[0].CompanyLogo)
            setCompanyName(response.data[0].CompanyName)

        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    function notificationsLabel(count) {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 99) {
            return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }

    useEffect(() => {
        fetchCompanyInfo();
    }, [])
    return (
        <div className="hedaer_line">
            <div className="header_containers">
                {/* <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search> */}
                {/* <div className="header_container">
                    <AccountCircleIcon sx={{ color: '#0b7bb7', fontSize: 40 }} />
                </div>
                <div className="header_accounts">
                    <span>{email.split("@accountree.net")}</span>
                    <p className="Profilee">{role}</p>
                </div> */}
                {/* <Box sx={{ flexGrow: 1 }} /> */}
                <IconButton>
                    <i class="bi bi-upc-scan" style={{ color: "black" }}></i>
                </IconButton>

                <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                    <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                    >
                        {/* <Badge badgeContent={17} color="error"> */}
                        <i class="bi bi-app-indicator"></i>
                        {/* </Badge> */}
                    </IconButton>
                </Box>
                {/* <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>

                </Box> */}
                <React.Fragment>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}> */}
                    {/* <ListItemButton onClick={handleClickForms} > */}
                    <IconButton onClick={handleClickForms}>
                        <i class="bi bi-grid-3x3-gap-fill" style={{ color: "black" }} ></i>
                    </IconButton>
                    {/* </ListItemButton> */}
                    {/* <Collapse in={openForms} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            </List>
                        </Collapse> */}
                    {/* </Box> */}
                    <Menu
                        anchorEl={forms}
                        id="account-menu"
                        open={openForms}
                        onClose={handleCloseForms}
                        onClick={handleCloseForms}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", width: "150px" }}>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                                <Link to="/Sales/Addsalesindetails" style={{ textDecoration: "none", color: "black" }}>
                                    <MenuItem onClick={handleClose} >
                                        <i class="bi bi-cart-check-fill" ></i>
                                    </MenuItem>
                                </Link>
                                <Link to="/purchases/addpurchaseinvoice" style={{ textDecoration: "none", color: "black" }}>
                                    <MenuItem onClick={handleClose}>
                                        <i class="bi bi-bag-check-fill"></i>
                                    </MenuItem>
                                </Link>
                            </div>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                                <Link to="/expences/addexpensepurchases" style={{ textDecoration: "none", color: "black" }}>
                                    <MenuItem onClick={handleClose}>
                                        <i class="bi bi-subtract"></i>
                                    </MenuItem>
                                </Link>
                                <Link to="/articles/articleslist" style={{ textDecoration: "none", color: "black" }}>
                                    <MenuItem onClick={handleClose}>
                                        {/* <Avatar> */}
                                        <i class="bi bi-upc-scan" style={{ color: "black" }}></i>
                                        {/* </Avatar> */}
                                    </MenuItem>
                                </Link>
                            </div>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                                <Link to="/accounts/clients" style={{ textDecoration: "none", color: "black" }}>
                                    <MenuItem onClick={handleClose}>
                                        <i class="bi bi-person-plus-fill"></i>
                                    </MenuItem>
                                </Link>
                                <Link to="/accounts/suppliers" style={{ textDecoration: "none", color: "black" }}>
                                    <MenuItem onClick={handleClose}>
                                        <i class="bi bi-person-dash-fill"></i>
                                    </MenuItem>
                                </Link>
                                {/* <InsertSupplier fetchData={fetchSuppliers} type="insert" /> */}
                            </div>
                        </div>
                    </Menu>
                </React.Fragment>
                {/* <IconButton aria-label={notificationsLabel(12)}>
                    <Badge badgeContent={12} color="secondary">
                        <MailIcon sx={{ color: '#0b7bb7' }} />
                    </Badge>
                </IconButton> */}
                <React.Fragment>
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
                        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
                        {/* <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 33 }}>M</Avatar>

                            </IconButton>
                        </Tooltip> */}
                        <ListItemButton onClick={handleClick} style={{ width: "60px" }}>
                            <ListItemIcon style={{ paddingRight: "10px" }}>
                                {/* <AccountCircleIcon sx={{ color: '#2b323a', fontSize: 35 }}></AccountCircleIcon> */}
                                <img src={ApiLink.PHOTO_URL + logo} style={{ width: "40px", height: "40px", borderRadius: "50%", border: "solid black 1px" }}></img>
                                {/* <div style={{paddingTop:"9px"}} >
                                    {open ?
                                        <ExpandLess /> : <ExpandMore />
                                    }
                                </div> */}
                            </ListItemIcon>
                            {/* <ListItemText /> */}

                            {/* <div className="emailsplit_" style={{ marginLeft: "-15px" }}>
                                <span>{email.split("@accountree.net") && email.split("@acctree.net")}</span>
                            </div> */}
                            {/* {open ?
                                <ExpandLess /> : <ExpandMore />
                            } */}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            </List>
                        </Collapse>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <Link to="/configuration/profile" style={{ textDecoration: "none", color: "black" }}>
                            <MenuItem onClick={handleClose}>
                                <Avatar>
                                    <img src={ApiLink.PHOTO_URL + logo} style={{ width: "30px", height: "30px", borderRadius: "50%", border: "solid black 1px" }}></img>
                                </Avatar>
                                <span >{companyName}</span>
                            </MenuItem>
                        </Link>
                        <Link to="/configuration/users" style={{ textDecoration: "none", color: "black" }}>
                            <MenuItem onClick={handleClose}>
                                <Avatar /> <span>{email.split("@acctree.net")}</span>
                            </MenuItem>
                        </Link>

                        {/* <MenuItem onClick={handleClose}>
                            <Avatar /> My account
                        </MenuItem> */}
                        <Divider />
                        {/* <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <PersonAdd fontSize="small" />
                            </ListItemIcon>
                            Add another account
                        </MenuItem> */}
                        {/* <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem> */}

                        <LogoutButton />
                    </Menu>
                </React.Fragment>
                {/* <button onClick={handleLogout}>Logout</button> */}
            </div>
        </div>
    );
};
export default Header;
