import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiLink } from '../APILink/ApiLink';
import authHeader from '../Auth/authHeader';
import { errorAlert } from '../Components/Alerts';
import JsBarcode from 'jsbarcode';
import { FaPrint } from "react-icons/fa";
import QRCode from 'react-qr-code';

export default function LinkInvoice(props) {
    const location = useLocation();
    const headers = authHeader();
    const barcodeRef = useRef(null);
    const tableRef = useRef();

    const getInvoiceNrFromURL = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('invoiceNR');
    }
    const uInvoiceNr = getInvoiceNrFromURL();

    let link =encodeURI(`http://acctree.net/invoice?invoiceNR=${uInvoiceNr}`);


    const [companiedDetails, setCompaniesDetails] = useState([]);
    const [transactiondetails, setTransactionDetails] = useState([]);
    const [articleDetails, setArticleDetails] = useState([]);
    const [banksdetails, setBankDetails] = useState([]);

    // const totalAmount0 = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Amount0 : 0).toFixed(2);
    // const totalAmount8 = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Amount8 : 0).toFixed(2);
    // const totalAmount18 = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Amount18 : 0).toFixed(2);

    const totalAmount0 = ((transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Amount0 : null) || 0).toFixed(2);
    const totalAmount8 = ((transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Amount8 : null) || 0).toFixed(2);
    const totalAmount18 = ((transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Amount18 : null) || 0).toFixed(2);


    // const discountAmount = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].DiscountAmount : 0);
    // const totali = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Total : 0);
    const discountAmount = ((transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].DiscountAmount : null) || 0);
    const totali = ((transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].Total : null) || 0);


    // const totalAmount = (Number(totalAmount0) + Number(totalAmount8) + Number(totalAmount18));
    // const amountPercent = (((Number(discountAmount) / (Number(discountAmount) + Number(totali)))))

    const totalAmount = (Number(totalAmount0) + Number(totalAmount8) + Number(totalAmount18));
    const amountPercent = (Number(totalAmount) * ((Number(discountAmount) / (Number(totali)))))

    // const vat8 = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].VAT8 : 0).toFixed(2);
    // const vat18 = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].VAT18 : 0).toFixed(2);
    const vat8 = ((transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].VAT8 : null) || 0).toFixed(2);
    const vat18 = ((transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].VAT18 : null) || 0).toFixed(2);



    const invoiceNr = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].InvoiceNr : 0);
    const fiscalYear = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].FiscalYear : 0);
    const actionType = (transactiondetails && transactiondetails.length > 0 ? transactiondetails[0].ActionType : '');

    const inv = "IND-" + fiscalYear + "-" + invoiceNr

    const fetchInvoiceDetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'LinkInvoice', { headers, params: { uInvoiceNr: uInvoiceNr } });
            setCompaniesDetails(response.data.CompanyDetails);
            setTransactionDetails(response.data.TransactionDetails);
            setArticleDetails(response.data.ArticleHistory);
            setBankDetails(response.data.BankDetails);

            // console.log(response.data.CompanyDetails);
            // console.log(response.data.TransactionDetails);
            console.log(response.data.CompanyDetails);
            // console.log(response.data.BankDetails);

        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handlePrint = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();


        // Shtoni një event listener për beforeunload që do të shkaktohet kur modaliteti i printimit mbyllet
        window.addEventListener('beforeunload', function () {
            // Bëni refresh të modalitetit të printimit në background
            const newPrintWindow = window.open('', 'PrintWindow', 'height=400,width=600');
            newPrintWindow.document.close();
            newPrintWindow.focus();
            newPrintWindow.print();
            newPrintWindow.close();
        });
    };

    useEffect(() => {
        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, inv, {
                format: "CODE128",
                height: 30, // Lartësia në pikselë
                width: 1.5,   // Gjerësia e shiritave
                text: actionType === "IND" ? inv : invoiceNr,
                fontSize: 12
            });
        }
    }, [inv]);

    useEffect(() => {
        fetchInvoiceDetails();
    }, [])

    return (
        <div>
            <button className="button-15" variant="contained" onClick={handlePrint}><FaPrint /> Print</button>
            <div style={{ display: "block", width: "60%", height: "auto", alignItems: "center", display: "flex", justifyContent: "center" }}>
                <div className='PrintContent__Container' ref={tableRef} style={{ width: "100%", height: "auto", display: "flex", flexDirection: "column" }}>
                    <div className='PrintContent__Container__div2' style={{ display: "flex", justifyContent: "space-between", paddingLeft: "15px", paddingRight: "15px", height: "auto" }}>
                        <div className='PrintContent__Container__div2__logo' style={{ width: "300px", height: "150px" }}>
                            {(companiedDetails && companiedDetails.length > 0 && companiedDetails[0]?.CompanyLogo) ? (
                                <img src={ApiLink.PHOTO_URL + companiedDetails[0].CompanyLogo} style={{ width: "200px", height: "120px" }} className="printLogo" />
                            ) : (
                                <span>No logo available</span>
                            )}
                        </div>

                        <div className='PrintContent__Container__div2__data' style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", padding: "5px" }}>
                            <label style={{ fontSize: "12px" }}><b>Shitësi: {companiedDetails && companiedDetails.length > 0 ? (companiedDetails[0].CompanyName) : ''}</b></label>
                            <label style={{ fontSize: "12px" }}>NUI: {companiedDetails && companiedDetails.length > 0 ? (companiedDetails[0].IdentificationNumber) : ''}</label>
                            {
                                companiedDetails && companiedDetails.length > 0 && companiedDetails[0].VATDeclarant != 0 ?
                                    (
                                        <label style={{ fontSize: "12px" }}>Numri i TVSH-së: {companiedDetails[0].VATNumber}</label>
                                    )
                                    :
                                    null
                            }
                            <label style={{ fontSize: "12px" }}>Adresa:{companiedDetails && companiedDetails.length > 0 ? (companiedDetails[0].Address) : ''}</label>
                            <label style={{ fontSize: "12px" }}>tel:{companiedDetails && companiedDetails.length > 0 ? (companiedDetails[0].PhoneNumbers) : ''}</label>
                            <label style={{ fontSize: "12px" }}>Email:{companiedDetails && companiedDetails.length > 0 ? (companiedDetails[0].Email) : ''}</label>
                        </div>
                    </div>
                    <hr style={{ margin: "10px" }}></hr>

                    <div className='PrintContent__Container__div3' style={{ display: "flex", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "10px", height: "auto" }}>
                        <div className='PrintContent__Container__div3__bleresi' style={{ display: "flex", flexDirection: "column", padding: "10px" }} >
                            <label style={{ fontSize: "12px" }}><b>Blerësi:{transactiondetails && transactiondetails.length > 0 ? (transactiondetails[0].ClientName) : ''}</b></label>
                            <label style={{ fontSize: "12px" }}>Numri Unik Identifikues: {transactiondetails && transactiondetails.length > 0 ? (transactiondetails[0].IdentificationNumber) : ''}</label>
                            <label style={{ fontSize: "12px" }}>Numri i TVSH-së: {transactiondetails && transactiondetails.length > 0 ? (transactiondetails[0].VATNumber) : ''}</label>
                            <label style={{ fontSize: "12px" }}>Qyteti:{transactiondetails && transactiondetails.length > 0 ? (transactiondetails[0].Adress) : ''}</label>
                            <label style={{ fontSize: "12px" }}>Adresa:{transactiondetails && transactiondetails.length > 0 ? (transactiondetails[0].Adress) : ''}</label>
                            <label style={{ fontSize: "12px" }}>tel:{transactiondetails && transactiondetails.length > 0 ? (transactiondetails[0].PhoneNumbers) : ''}</label>
                        </div>
                        <div className='PrintContent__Container__div3__dokumenti' style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", padding: "10px" }} >
                            <label style={{ fontSize: "12px" }}><b>Dokumenti:
                                {transactiondetails.length > 0 ? (
                                    transactiondetails[0].ActionType === "IND"
                                        ? "Faturë Shitje"
                                        : "Faturë Blerje"
                                ) : ''}</b>
                            </label>
                            <label style={{ fontSize: "12px" }}> <b>Numri : </b><label style={{ fontSize: "12px" }}><b>
                                {transactiondetails && transactiondetails.length > 0 ?
                                    (transactiondetails[0].ActionType ? (transactiondetails[0].ActionType + "-" + transactiondetails[0].FiscalYear + "-" + transactiondetails[0].InvoiceNr) : transactiondetails[0].InvoiceNr)
                                    : ''}
                            </b></label>
                            </label>
                            <label style={{ fontSize: "12px" }}>
                                <b>Data Faturës:
                                    {transactiondetails && transactiondetails.length > 0
                                        ? transactiondetails[0].Date
                                        : ''}
                                </b>
                            </label>
                            <label style={{ fontSize: "12px" }}>
                                Afati Pageses:{" "}
                                {transactiondetails && transactiondetails.length > 0
                                    ? transactiondetails[0].PayDate
                                    : ''}
                            </label>
                            <label style={{ fontSize: "12px" }}>Përdoruesi:    {transactiondetails && transactiondetails.length > 0 && transactiondetails[0].CreatedByUser ? transactiondetails[0].CreatedByUser.split('@')[0] : ''}</label>
                            <label style={{ fontSize: "12px" }}>Njesia Organizative:01</label>
                        </div>
                    </div>
                    <hr style={{ margin: "10px" }}></hr>

                    <div className='PrintContent__Container__div4' style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            {link && ( // Kontrolli nëse props.link nuk është null ose undefined
                                <QRCode
                                    value={link}
                                    style={{ width: "50px", height: "50px" }}
                                />
                            )}
                        </div>

                        {/* Ketu duhet te vendoset barkodi */}
                        <div>
                            <svg ref={barcodeRef}></svg>
                        </div>
                    </div>
                    <hr style={{ margin: "10px" }}></hr>

                    <div className='PrintContent__Container__div5' style={{ margin: "10px", padding: "10px", height: "30px" }}>
                        <div className='PrintContent__Container__div5__MainTable'>
                            <table className='PrintTable' style={{ width: "100%", borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr style={{ borderBottom: "1px solid black " }}>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "120px" }}>Barkodi</th>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "300px" }}>Emertimi</th>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Sasia</th>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Njesia</th>
                                        {
                                            companiedDetails && companiedDetails.length > 0 && companiedDetails[0].VATDeclarant != 0 ?
                                                (
                                                    <>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Cmimi pa TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Vlera pa TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Norma</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Rabati %</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Cmimi Me TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Vlera Me TVSH</th>
                                                    </>
                                                )
                                                :
                                                <>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Rabati %</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Cmimi</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Vlera</th>
                                                </>
                                        }
                                    </tr>
                                    {articleDetails &&
                                        articleDetails.map((data, index) => (
                                            <tr style={{ borderBottom: "1px solid black " }} key={index}>
                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px", width: "120px" }}>{data.Barcode}</td>
                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px", width: "300px" }}>{data.ArticleName}</td>
                                                {
                                                    transactiondetails && transactiondetails.length > 0 && transactiondetails[0].ActionType !== "IND" ?
                                                        <>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{data.QtyInput}</td>
                                                        </> :
                                                        <>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{data.QtyOutput}</td>
                                                        </>
                                                }
                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{data.UnitName}</td>
                                                {
                                                    companiedDetails && companiedDetails.length > 0 && companiedDetails[0].VATDeclarant != 0 ?
                                                        (
                                                            <>
                                                                <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.PriceWithoutVAT != null ? data.PriceWithoutVAT.toFixed(2) : ''}</td>
                                                                <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.Amount != null ? data.Amount.toFixed(2) : ''}</td>
                                                                <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.VATRates != null ? data.VATRates.toFixed(2) : ''}</td>
                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{(data.DiscountPercent).toFixed(2)}</td>
                                                                <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.VAT != null ? data.VAT.toFixed(2) : ''}</td>
                                                                <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.PriceWithVAT != null ? data.PriceWithVAT.toFixed(2) : ''}</td>
                                                                <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.Total != null ? data.Total.toFixed(2) : ''}</td>
                                                            </>
                                                        )
                                                        :
                                                        <>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{(data.DiscountPercent).toFixed(2)}</td>
                                                            <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.PriceWithVAT != null ? data.PriceWithVAT.toFixed(2) : ''}</td>
                                                            <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px", width: "90px" }}>{data.Total != null ? data.Total.toFixed(2) : ''}</td>
                                                        </>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        {
                            companiedDetails && companiedDetails.length > 0 && companiedDetails[0].VATDeclarant != 0 ?
                                (
                                    <div className='PrintContent__Container__div5__SecondTable' style={{ display: "flex", justifyContent: "space-between" }}>
                                        <table className='PrintTable' style={{ width: "40%", borderCollapse: "collapse" }}>
                                            <tbody>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>Norma Tatimore</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>Baza</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>TVSH</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>Total</th>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>TVSH e llogaritur (A) 0%:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{totalAmount0} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(0).toFixed(2)} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount0) + 0).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px", width: "150px" }}>TVSH e llogaritur (D) 8%:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{totalAmount8} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{vat8} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount8) + Number(vat8)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>TVSH e llogaritur (E) 18%:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{totalAmount18} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{vat18} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount18) + Number(vat18)).toFixed(2)} €</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='PrintTable' style={{ width: "40%", borderCollapse: "collapse" }}>
                                            <tbody>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Vlera pa rabat:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount) + Number(amountPercent)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Rabati:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(amountPercent)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Total pa TVSH:</td>
                                                    <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px" }}>
                                                        {((Number(totalAmount0) || 0) + (Number(totalAmount8) || 0) + (Number(totalAmount18) || 0)).toFixed(2)} €
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Total TVSH:</td>
                                                    <td style={{ padding: "3px", textAlign: "right", border: "1px solid black", fontSize: "10px" }}>
                                                        {((Number(vat8) || 0) + (Number(vat18) || 0)).toFixed(2)} €
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px" }}>Për pagesë:</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "right", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px" }}>
                                                        {((transactiondetails && transactiondetails.length > 0 && transactiondetails[0].Total != null) ? transactiondetails[0].Total : 0).toFixed(2)} €
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ) :
                                <table className='PrintTable' style={{ display: "flex", justifyContent: "flex-end", borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr style={{ borderBottom: "1px solid black " }}>
                                            <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px" }}>Për pagesë:</th>
                                            <th className='TableHeader' style={{ padding: "8px", textAlign: "right", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px" }}>
                                                {((transactiondetails && transactiondetails.length > 0 && transactiondetails[0].Total != null) ? transactiondetails[0].Total : 0).toFixed(2)} €
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                        }
                        <div className='PrintContent__Container__div6' style={{ marginTop: "20px" }}>
                            {
                                transactiondetails && transactiondetails.length > 0 ?
                                    (transactiondetails[0].PaymentOptions === 0 ?
                                        <div>
                                            <p style={{ fontSize: "12px" }}>Pagesa duhet të bëhet deri në afatin e pagesës së caktuar</p>
                                            {
                                                transactiondetails[0].Comment && transactiondetails[0].Comment != null ? (
                                                    <p style={{ fontSize: "12px" }}>Komenti: {transactiondetails[0].Comment && transactiondetails[0].Comment} </p>
                                                )
                                                    :
                                                    null
                                            }
                                        </div> :
                                        <div>
                                            <p style={{ fontSize: "12px" }}>Ju faleminderit për bashkëpunimin tuaj!</p>
                                            {
                                                transactiondetails[0].Comment && transactiondetails[0].Comment != null ? (
                                                    <p style={{ fontSize: "12px" }}>Komenti: {transactiondetails[0].Comment && transactiondetails[0].Comment} </p>
                                                )
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                            <div className='PrintContent__Container__div7' style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ border: "solid 1px blue", display: "flex", flexDirection: "column", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}>
                                        <label style={{ color: "blue", fontSize: "11px" }}>{companiedDetails && companiedDetails.length > 0 ? (companiedDetails[0].CompanyName) : ''}</label>
                                        <label style={{ color: "green", fontSize: "11px" }}>KONFIRMUAR</label>
                                        <label style={{ color: "blue", fontSize: "11px" }}>VULA ELEKTRONIKE</label>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <label style={{ color: "blue", marginBottom: "-15px", fontSize: "12px" }}>
                                            {transactiondetails && transactiondetails.length > 0 && transactiondetails[0].CreatedByUser
                                                ? transactiondetails[0].CreatedByUser.split('@')[0]
                                                : ''}
                                        </label>
                                        <span>_________________________</span>
                                        <label style={{ fontSize: "10px" }}>Faturoi:</label>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <br></br>
                                    <br></br>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <label style={{ color: "blue", marginBottom: "-15px", fontSize: "12px" }}>
                                            {transactiondetails && transactiondetails.length > 0 && transactiondetails[0].CreatedByUser
                                                ? transactiondetails[0].CreatedByUser.split('@')[0]
                                                : ''}
                                        </label>
                                        <span>_________________________</span>
                                        <label style={{ fontSize: "10px" }}>Dergoi:</label>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <br></br>
                                    <br></br>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <span>_________________________</span>
                                        <label style={{ fontSize: "10px" }}>Pranoi:</label>
                                    </div>

                                </div>
                            </div>

                            <div className='PrintContent__Container__div8' style={{ margin: "10px", padding: "10px" }}>
                                <label style={{ width: "100%", fontSize: "10px" }}>Ju lutem, pagesen tuaj per shumen e pergjithshme ta beni ne njeren nga llogarit bankare vijuese te kompanis </label>
                                <br></br>
                                <table className='PrintTable' style={{ width: "100%", borderCollapse: "collapse", borderTop: "1px solid black" }}>
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid black ", backgroundColor: "lightgray" }}>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Banka</th>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Emri Llogaris</th>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Numri Llogaris</th>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Swift Kodi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {banksdetails &&
                                            banksdetails.map((data, index) => (
                                                <tr style={{ borderBottom: "1px solid black ", fontSize: "10px" }} key={index}>
                                                    <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>{data.AccountName}</td>
                                                    <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>{companiedDetails && companiedDetails.length > 0 && companiedDetails[0].CompanyName ? companiedDetails[0].CompanyName : ''}</td>
                                                    <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>
                                                        {data.IBAN && data.IdentificationNumber ? `${data.IBAN} ${data.IdentificationNumber}` : ''}
                                                    </td>
                                                    <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>
                                                        {data.SWIFT || ''}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
