import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './../../Style/AddPurchaseTotal.scss'
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { AiFillAppstore } from "react-icons/ai";
import { Paper } from "@mui/material";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import './../../Style/TableStyle.scss'
import { MdEdit } from "react-icons/md";
import InsertOwner from "../Owners/InsertOwners";
import DataGridWithActions from '../../Components/DataGridWithActions';
import { WithdrawalGridHeader } from "./WithdrawalGridHeader";
import Autocomplete from "@mui/material/Autocomplete";

export default function Withdrawal() {
    const currentYear = new Date().getFullYear();

    const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [showDetails, setShowDetails] = useState(true);

    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        ActionType: "",
        LedgerID: "",
        AccountID: "",
        SupplierID: "",
        Total: "",
        PaymentOptions: "",
        Coment: "",
        ModifiedByUser: ""
    });


    const [showEditModal, setShowEditModal] = useState(false);
    const [owners, setOwners] = useState([]);
    const [ledgers, setLedgers] = useState([]);
    const [accounts, setAccounts] = useState([]);

    // Kodi per Marrjen e informatave nga databaza

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Withdrawal', { headers });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Withdrawal' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Withdrawal  Deleted Successfully");
            fetchData();
        } catch (error) {
            deleteAlert("Withdrawal can not be deleted");
        }
    };

    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            SupplierID: item.SupplierID,
            Total: item.Total,
            PaymentOptions: item.PaymentOptions,
            LedgerID: item.LedgerID,
            Coment: item.Coment,
            ModifiedByUser: loginEmail,
        });
        setShowEditModal(true);
        setShowDetails(false);
        fetchAccounts(item.LedgerID);
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData.Date == null) {
            errorAlert("Date is required");
            return;
        }
        if (formData.LedgerID == null) {
            errorAlert("Ledger is required");
            return;
        }
        if (formData.PaymentOptions == null) {
            errorAlert("Payment is required");
            return;
        }
        if (formData.Coment == null) {
            errorAlert("Comment is required");
            return;
        }
        if (formData.Total == null) {
            errorAlert("Amount is required");
            return;
        }
        if (formData.AccountID == null) {
            errorAlert("Supplier is required");
            return;
        }
        axios
            .put(ApiLink.API_URL + 'Withdrawal', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                setShowDetails(true);
                fetchData();
                updateAlert("Withdrawal updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update Withdrawal")
            });
    };

    const fetchOwner = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 501 } });
            setOwners(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchLedger = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger/Get_101_102_Ledgers', { headers });
            setLedgers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchAccounts = async (ledgerID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: ledgerID } });
            setAccounts(response.data);
            // alert(formData.PaymentOptions)
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        inv: ("WDR-" + currentYear + "-" + item.InvoiceNr),
        date: (item.Date.substring(0, 10)),
        client: item.AccountName_AccountID,
        account: item.AccountName_PaymentOptions,
        amount: item.Total,
        deleteItemId: item.TransactionID,
        rowInfo: item
    }));

    //Select list for Clients
    const handleAutocompleteChangeAccounts = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    const defaultPropsAccounts = {
        options: owners,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>

                </li>
            </>
        ),
    };

    const handleAutocompleteChangePayOptions = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                PaymentOptions: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                PaymentOptions: ""
            }));
        }
    };

    const defaultPropsPayOptions = {
        options: accounts,
        getOptionLabel: (option) =>
            option.AccountName || ""
    };

    useEffect(() => {
        fetchData();
        fetchOwner();
        fetchLedger();
    }, []);

    return (
        <div className="Crud__Container">
            <div className="shfaqeEditin">
                {/* Shfaqja  e modalit per editimin e te dhenave  */}
                {showEditModal && (
                    <div className="AddPurchaseTotal__Container">
                        <div className="header_flex">
                            <div className="header_icon">
                                <MdEdit style={{ color: '#466F86', width: "40px" }} />
                                <h3>Ndryshimi Terheqjes</h3>
                            </div>
                        </div>
                        <hr></hr>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <InsertOwner fetchData={fetchOwner} type="insert" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginLeft: "5px" }}>
                                    <Link to="#"
                                        style={{ width: "110px", textDecoration: "none" }}
                                    >
                                        <button type="submit" className="button-15" onClick={handleSubmit}>Ndrysho</button>
                                    </Link>
                                </div>
                                <div style={{ marginLeft: "5px" }}>
                                    <Link
                                        to="#"
                                        style={{ width: "110px", textDecoration: "none" }}
                                    >
                                        <button
                                            className="button-15"
                                            onClick={() => {
                                                setShowDetails(true);
                                                setShowEditModal(false);
                                            }}
                                        >
                                            Anulo
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='header_paper2'></div>
                        <div className="edit">
                            <form className='AddPurchaseTotal__Container__Format'>
                                <Paper>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div className='Forma1'>
                                            <div className='Boxx'>
                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                    // style={{ width: "594px" }}
                                                    id="InvoiceNr"
                                                    label="Numri Terheqjes"
                                                    variant="outlined"
                                                    type="text"
                                                    name='InvoiceNr'
                                                    value={"WDR-" + currentYear + "-" + formData.InvoiceNr}
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                                <TextField
                                                    // style={{ width: "230px" }}
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                    id="Date"
                                                    type="date"
                                                    variant="outlined"
                                                    name='Date'
                                                    value={formData.Date.substring(0, 10)}
                                                    onChange={handleInputChange}
                                                />
                                                <hr style={{ marginLeft: "15px" }}></hr>

                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                    id="outlined-basic"
                                                    label="Llogaria kontabel Debitore"
                                                    variant="outlined"
                                                    // name='Total'
                                                    value="501-Kapitali pronarit"
                                                    disabled
                                                />
                                                {/* <TextField
                                                    // style={{ width: "504px" }}
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                    id="AccountID"
                                                    name="AccountID"
                                                    select
                                                    label="Select Owner"
                                                    value={formData.AccountID}
                                                    onChange={handleInputChange}
                                                >
                                                    {owners.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    fullWidth
                                                    // sx={{ m: 2 }}
                                                    {...defaultPropsAccounts}
                                                    id="AccountID"
                                                    includeInputInList
                                                    onChange={handleAutocompleteChangeAccounts}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            label="Pronari"
                                                            // variant="filled"
                                                            className="AddPurchaseTotal__Container__Format__SameInput"
                                                        />
                                                    )}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                            overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                        },
                                                    }}
                                                    value={owners.find(option => option.AccountID === formData.AccountID) || null}
                                                />
                                            </div>

                                            <div className='box2'>
                                                <div className='box3'>
                                                    <TextField
                                                        // style={{ width: "495px" }}
                                                        className="AddPurchaseTotal__Container__Format__Comment"
                                                        type="text"
                                                        id="Coment"
                                                        name="Coment"
                                                        label="Komenti"
                                                        variant="outlined"
                                                        value={formData.Coment}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='box3'>
                                                    <TextField
                                                        // style={{ width: "495px" }}
                                                        className="AddPurchaseTotal__Container__Format__Comment"
                                                        type="text"
                                                        id="Total"
                                                        name="Total"
                                                        label="Vlera"
                                                        variant="outlined"
                                                        value={formData.Total}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <hr style={{ marginRight: "65px" }}></hr>
                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__Comment"
                                                    id="LedgerID"
                                                    name="LedgerID"
                                                    select
                                                    label="Llogaria kontabel Kreditore"
                                                    defaultValue=""
                                                    value={formData.LedgerID}
                                                    onChange={(e) => { handleInputChange(e); fetchAccounts(e.target.value); }}
                                                >
                                                    {ledgers.map((data) => (
                                                        <MenuItem key={data.LedgerID} value={data.LedgerID}>
                                                            {data.LedgerNameEN}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {/* <TextField
                                                    // style={{ width: "593px" }}
                                                    className="AddPurchaseTotal__Container__Format__Comment"
                                                    id="PaymentOptions"
                                                    select
                                                    label="Select Payment Type"
                                                    name="PaymentOptions"
                                                    value={formData.PaymentOptions}
                                                    onChange={handleInputChange}
                                                >
                                                    {accounts.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    fullWidth
                                                    // sx={{ m: 2 }}
                                                    {...defaultPropsPayOptions}
                                                    id="PaymentOptions"
                                                    includeInputInList
                                                    onChange={handleAutocompleteChangePayOptions}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            label="Llogaria Kreditore"
                                                            // variant="filled"
                                                            className="AddPurchaseTotal__Container__Format__Comment"
                                                        />
                                                    )}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                            overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                        },
                                                    }}
                                                    value={accounts.find(option => option.AccountID === formData.PaymentOptions) || null}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                </Paper>

                            </form>
                        </div>
                    </div>
                )}
            </div>

            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Terheqjet</h3>
                        </div>
                        <div>
                            <Link to={"/money/addwithdrawal"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Krijo Terheqje
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e Terheqjeve: Menaxhoni, shtoni, editoni dhe fshini Terheqje.</p>
                    </div>

                    <DataGridWithActions
                        columns={WithdrawalGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={false}
                        height={500}
                    />

                </div>
            )}
        </div>
    )
}