import ExcelJS from 'exceljs';

export const TaxSalesExcelForm = (filteredData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Shtoni titullin e kolonave në rreshtin e parë
    worksheet.mergeCells('A1', 'A2');
    const cellA1A2 = worksheet.getCell('A1');

    cellA1A2.value = 'NR.';
    cellA1A2.width = 50; // Përcakton width në 100 piksela
    cellA1A2.height = 125; // Përcakton height në 50 piksela
    cellA1A2.border = {
        top: { style: 'thin', color: { argb: '00000000' }, bold: true },
        left: { style: 'thin', color: { argb: '00000000' }, bold: true },
        bottom: { style: 'thin', color: { argb: '00000000' }, bold: true },
        right: { style: 'thin', color: { argb: '00000000' }, bold: true }
    };
    cellA1A2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };


    worksheet.mergeCells('B1', 'C1');
    const cellC1 = worksheet.getCell('B1');
    cellC1.value = 'Fatura';
    cellC1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };
    cellC1.alignment = { vertical: 'middle', horizontal: 'center' };
    cellC1.width = 170; // Përcakton width në 100 piksela
    cellC1.height = 27; // Përcakton height në 50 piksela

    const cellC2 = worksheet.getCell('B2');
    cellC2.value = 'Data';
    cellC2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellC2.alignment = { vertical: 'middle', horizontal: 'center' };

    const cellD2 = worksheet.getCell('C2');
    cellD2.value = 'Numri Faturës';
    cellD2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellD2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    worksheet.mergeCells('D1', 'F1');
    const blersi = worksheet.getCell('D1');
    blersi.value = 'Blerësi';
    blersi.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    blersi.alignment = { vertical: 'middle', horizontal: 'center' };

    const emriBleresit = worksheet.getCell('D2');
    emriBleresit.value = 'Emri i blerësit';
    emriBleresit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    emriBleresit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const NRFBlersit = worksheet.getCell('E2');
    NRFBlersit.value = 'Numri Fiskal i blerësit';
    NRFBlersit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    NRFBlersit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const VATBlersit = worksheet.getCell('F2');
    VATBlersit.value = 'Numri i TVSH-së së blerësit';
    VATBlersit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    VATBlersit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    worksheet.mergeCells('A3', 'F3');
    const cellA3 = worksheet.getCell('A3');
    cellA3.value = 'Numri i kutisë në Deklaratën e TVSH-së';
    cellA3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellA3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    cellA3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };


    // Pjesa e "Shitjet e liruara nga TVSH"-------------------------------------

    worksheet.mergeCells('G1', 'L1');
    const shitjetELiruaraVAT = worksheet.getCell('G1');
    shitjetELiruaraVAT.value = 'Shitjet e liruara nga TVSH';
    shitjetELiruaraVAT.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    shitjetELiruaraVAT.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    // G2 dhe G3
    const paKreditim = worksheet.getCell('G2');
    paKreditim.value = 'Shitjet e liruara pa të drejtë kreditimi';
    paKreditim.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    paKreditim.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const nr9 = worksheet.getCell('G3');
    nr9.value = '[9]';
    nr9.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    nr9.alignment = { vertical: 'middle', horizontal: 'center' };
    nr9.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // H2 dhe H3
    const jashtVendit = worksheet.getCell('H2');
    jashtVendit.value = 'Shitjet e shërbimeve jashtë vendit';
    jashtVendit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    jashtVendit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const nr10a = worksheet.getCell('H3');
    nr10a.value = '[10a]';
    nr10a.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    nr10a.alignment = { vertical: 'middle', horizontal: 'center' };
    nr10a.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // I2 dhe I3
    const brendaVendit = worksheet.getCell('I2');
    brendaVendit.value = 'Shitjet brenda vendit me ngarkesë të kundërt  të TVSH-së';
    brendaVendit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    brendaVendit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const nr10b = worksheet.getCell('I3');
    nr10b.value = '[10b]';
    nr10b.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    nr10b.alignment = { vertical: 'middle', horizontal: 'center' };
    nr10b.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // J2 dhe J3
    const metedrejtkreditimi = worksheet.getCell('J2');
    metedrejtkreditimi.value = 'Shitjet tjera të liruara me të drejtë kreditimi';
    metedrejtkreditimi.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    metedrejtkreditimi.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const nr10c = worksheet.getCell('J3');
    nr10c.value = '[10c]';
    nr10c.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    nr10c.alignment = { vertical: 'middle', horizontal: 'center' };
    nr10c.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // K2 dhe K3
    const K2 = worksheet.getCell('K2');
    K2.value = 'Totali i shitjeve të liruara me të drejtë kreditimi';
    K2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    K2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const K3 = worksheet.getCell('K3');
    K3.value = '[10] = [10a]+[10b]+[10c]';
    K3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    K3.alignment = { vertical: 'middle', horizontal: 'center' };
    K3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // L2 dhe L3
    const L2 = worksheet.getCell('L2');
    L2.value = 'Eksportet';
    L2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    L2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const L3 = worksheet.getCell('L3');
    L3.value = '[11]';
    L3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    L3.alignment = { vertical: 'middle', horizontal: 'center' };
    L3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Pjesa e "Shitjet e tatueshme me normën 18%"-------------------------------------

    worksheet.mergeCells('M1', 'R1');
    const shitjetETatueshme18 = worksheet.getCell('M1');
    shitjetETatueshme18.value = 'Shitjet e tatueshme me normën 18%';
    shitjetETatueshme18.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    shitjetETatueshme18.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    // M2 dhe M3
    const M2 = worksheet.getCell('M2');
    M2.value = 'Shitjet e tatueshme ';
    M2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    M2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const M3 = worksheet.getCell('M3');
    M3.value = '[12]';
    M3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    M3.alignment = { vertical: 'middle', horizontal: 'center' };
    M3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // N2 dhe N3
    const N2 = worksheet.getCell('N2');
    N2.value = 'Nota debitore e lëshuar, nota kreditore e pranuar  ';
    N2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    N2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const N3 = worksheet.getCell('N3');
    N3.value = '[16]';
    N3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    N3.alignment = { vertical: 'middle', horizontal: 'center' };
    N3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // O2 dhe O3
    const O2 = worksheet.getCell('O2');
    O2.value = 'Fatura e borxhit të keq e pranuar';
    O2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    O2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const O3 = worksheet.getCell('O3');
    O3.value = '[20]';
    O3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    O3.alignment = { vertical: 'middle', horizontal: 'center' };
    O3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // P2 dhe P3
    const P2 = worksheet.getCell('P2');
    P2.value = 'Rregullimet për të rritur TVSH-në ';
    P2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    P2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const P3 = worksheet.getCell('P3');
    P3.value = '[24]';
    P3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    P3.alignment = { vertical: 'middle', horizontal: 'center' };
    P3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Q2 dhe Q3
    const Q2 = worksheet.getCell('Q2');
    Q2.value = 'Blerjet që i nënshtrohen ngarkesës së kundërt ';
    Q2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Q2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const Q3 = worksheet.getCell('Q3');
    Q3.value = '[28]';
    Q3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    Q3.alignment = { vertical: 'middle', horizontal: 'center' };
    Q3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // R2 dhe R3
    const R2 = worksheet.getCell('R2');
    R2.value = 'Totali i TVSH-së së llogaritur me normën 18%';
    R2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    R2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const R3 = worksheet.getCell('R3');
    R3.value = '[K1]';
    R3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    R3.alignment = { vertical: 'middle', horizontal: 'center' };
    R3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Pjesa e "Shitjet e tatueshme me normën 8%"-------------------------------------

    worksheet.mergeCells('S1', 'W1');
    const shitjetETatueshme8 = worksheet.getCell('S1');
    shitjetETatueshme8.value = 'Shitjet e tatueshme me normën 8%';
    shitjetETatueshme8.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    shitjetETatueshme8.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    // S2 dhe S3
    const S2 = worksheet.getCell('S2');
    S2.value = 'Shitjet e tatueshme';
    S2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    S2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const S3 = worksheet.getCell('S3');
    S3.value = '[14]';
    S3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    S3.alignment = { vertical: 'middle', horizontal: 'center' };
    S3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // T2 dhe T3
    const T2 = worksheet.getCell('T2');
    T2.value = 'Nota debitore e lëshuar, nota kreditore e pranuar';
    T2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    T2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const T3 = worksheet.getCell('T3');
    T3.value = '[18]';
    T3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    T3.alignment = { vertical: 'middle', horizontal: 'center' };
    T3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // U2 dhe U3
    const U2 = worksheet.getCell('U2');
    U2.value = 'Fatura e borxhit të keq e pranuar';
    U2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    U2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const U3 = worksheet.getCell('U3');
    U3.value = '[22]';
    U3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    U3.alignment = { vertical: 'middle', horizontal: 'center' };
    U3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // V2 dhe V3
    const V2 = worksheet.getCell('V2');
    V2.value = 'Rregullimet për të rritur TVSH-në / Ngarkesa e kundërt për Energjinë me normë 8%';
    V2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    V2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const V3 = worksheet.getCell('V3');
    V3.value = '[26]';
    V3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    V3.alignment = { vertical: 'middle', horizontal: 'center' };
    V3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // W2 dhe W3
    const W2 = worksheet.getCell('W2');
    W2.value = 'Totali i TVSH-së së llogaritur me normën 8%';
    W2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    W2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };

    const W3 = worksheet.getCell('W3');
    W3.value = '[K2]';
    W3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    W3.alignment = { vertical: 'middle', horizontal: 'center' };
    W3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    // Pjesa e "Totali i TVSH-së së llogaritur me   18% dhe 8%"-------------------------------------

    worksheet.mergeCells('X1', 'X2');
    const X1X2 = worksheet.getCell('X1');
    X1X2.value = 'Totali i TVSH-së së llogaritur me   18% dhe 8%';
    X1X2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    X1X2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    X1X2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };

    const X3 = worksheet.getCell('X3');
    X3.value = '[30]';
    X3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    X3.alignment = { vertical: 'middle', horizontal: 'center' };
    X3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECE9D8' } // Ngjyra e hirit
    };


    // Përdorni map për të shtuar rreshtat e të dhënave
    filteredData.map((item, index) => {
        const row = [];
        // Shtoni vlerat për secilën kolonë bazuar në të dhënat e furnizuara
        row.push(index + 1);
        row.push(item.DataFatures || '');
        // row.push(item.NumriFatures || '');
        row.push(item.ActionType === 'IND' ? `IND-2024-${item.NumriFatures}` : item.NumriFatures || '')
        row.push(item.EmriBleresit || '');
        row.push(item.IdentificationNumber || '');
        row.push(item.VATNumber || '');
        row.push(item.Shitjet_Pa_Kreditim || '');
        row.push(item.Shitjet_Sherbimeve || '');
        row.push(item.Shitjet_Me_Ngarkese_Te_Kundert || '');
        row.push(item.Shitjet_Liruara_Me_Kreditim || '');
        row.push(item.Total_Shitjet_Liruara_Me_Kreditim || '');
        row.push(item.Eksportet || '');
        row.push(item.Shitjet18 || '');
        row.push(item.NotaDebitore18 || '');
        row.push(item.BorgjiKeq18 || '');
        row.push(item.Rregullimet18 || '');
        row.push(item.Rregullimet18 || '');
        row.push(item.TotalVAT18 || '');
        row.push(item.Shitjet8 || '');
        row.push(item.NotaDebitore8 || '');
        row.push(item.BorgjiKeq8 || '');
        row.push(item.Rregullimet8 || '');
        row.push(item.TotalVAT8 || '');
        row.push(item.Total_TVSH_8_dhe_18 || '');




        // Shtoni rreshtin në sheet
        worksheet.addRow(row);
    });

    // Kthej workbook-in për përdorim në komponentin tjetër
    return workbook;
};
