import "./chart.scss";
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { useState, useEffect } from "react";
import { ApiLink } from "../../../APILink/ApiLink";
import { errorAlert } from "../../../Components/Alerts";
import authHeader from "../../../Auth/authHeader";

const Chart = ({ aspect, title }) => {

  const [data, setData] = useState([]);
  const headers = authHeader();


  const fetchData = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + 'Dashboard/salesMonth', { headers });
      setData(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);

      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="chart">
      <div className="title">{title}</div>
      <div className="chart-scroll">
        <ResponsiveContainer width="100%" aspect={aspect}>
          <AreaChart
            width={730}
            height={250}
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" stroke="gray" />
            <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="Total"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#total)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div >
  );
};

export default Chart;
