import React, { useState, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/AddPurchaseTotal.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
// import AddBoxIcon from '@mui/icons-material/AddBox';
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
import InsertSupplier from "../Suppliers/InsertSuppliers";
import Autocomplete from "@mui/material/Autocomplete";

function AddPurchaseInTotal(props) {
    const TranscationData = [
        {
            id: 1,
            type: "Vendor"
        },
        {
            id: 2,
            type: "Import"
        },
    ]

    const headers = authHeader();
    const email = LoginUserDetailed();

    const [date, setDate] = useState("");
    const [payDate, setPayDate] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [supplierID, setSupplierID] = useState("");
    const [total, setTotal] = useState("");
    const [amount0, setAmount0] = useState(0);
    const [amount8, setAmount8] = useState(0);
    const [amount18, setAmount18] = useState(0);
    const [vat8, setVat8] = useState(0);
    const [vat18, setVat18] = useState(0);
    const [coment, setComent] = useState("");
    const [paymentOptions, setPaymentOptions] = useState("");
    const [transactionType, setTransactionType] = useState(1);

    const [expence, setExpence] = useState([]);

    const [suppliers, setSuppliers] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);

    const [cashRegister, setCashRegister] = useState([]);
    const [filteredCashRegister, setFilteredCashRegister] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Invoice number is required!");
            return;
        }
        if (!date) {
            errorAlert("Date is required!");
            return;
        }
        if (!payDate) {
            errorAlert("Pay date is required!");
            return;
        }
        if (!supplierID) {
            errorAlert("Please select supplier!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Please choose payment option!");
            return;
        }
        const stateDate = {
            Date: date,
            PayDate: payDate,
            ActionType: "POT",
            InvoiceNr: invoiceNR,
            SupplierID: supplierID,
            Amount0: amount0,
            Amount8: amount8,
            Amount18: amount18,
            PaymentOptions: paymentOptions,
            TransactionType: transactionType,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'PurchaseInTotal', stateDate, { headers })
            .then(() => {
                handleSuccess("Purchase in total Added Successfully");
                setDate("");
                setPayDate("");
                setInvoiceNR("");
                setSupplierID("");
                setComent("");
                setPaymentOptions("");
                setAmount0(0);
                setAmount8(0);
                setAmount18(0);
                setTransactionType(1);
                setSupplierName("");
                setValueWithVAT0(0);
                setValueWithVAT8(0);
                setValueWithVAT18(0);
                setVat8(0);
                setVat18(0);
                setField(null);

            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    // useEffect(() => {
    //     const VAT18 = amount18 * 0.18; // llogarit produktin me 18% shumëzim
    //     setVat18(VAT18); // vendos vlerën e produktit në inputin e dytë
    // }, [amount18]);

    // useEffect(() => {
    //     const VAT8 = amount8 * 0.08; // llogarit produktin me 18% shumëzim
    //     setVat8(VAT8); // vendos vlerën e produktit në inputin e dytë
    // }, [amount8]);


    useEffect(() => {
        const total = Number(amount0) + Number(amount8) + Number(amount18) + Number(vat8) + Number(vat18)
        // + amount18 + vat8 + vat18   
        setTotal(total); // vendos vlerën e produktit në inputin e dytë
    }, [amount0, amount8, amount18, vat8, vat18]);

    const fetchExpence = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 609 } });
            setExpence(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 302 } });
            setSuppliers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    // ky kod sherben per ta kontrolluar nese nje numer i fatures ekzison, dhe e vendos butoni se qfar pamje do te merr
    const [buttonContent, setButtonContent] = useState({
        text: "Ruaj & i ri",
        backgroundColor: "white",
    });
    const [invNumberExist, setInvNumberExist] = useState();
    const checkInvoiceNumber = async (invoiceNR) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'PurchaseInTotal/invoiceExists', { headers, params: { invNumber: invoiceNR } });
            setInvNumberExist(response.data[0].InvoiceCount);
            if ((response.data[0].InvoiceCount) > 0) {
                setButtonContent({
                    text: "Please choose another invoice number",
                    backgroundColor: "red",
                });
            }
            else {
                setButtonContent({
                    text: "Ruaj & i ri",
                    backgroundColor: "white",
                });
            }
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredSuppliers(
            suppliers.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, suppliers]);


    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setSearchTerm(inputValue);
    };

    const [supplierName, setSupplierName] = useState("");
    const handleFilterSupplier = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setSupplierID(selectedOption.AccountID);
            setSupplierName(selectedOption.label);
        } else {
            setSupplierID("");
            setSupplierName("");
        }
    };

    const [valueWithVAT0, setValueWithVAT0] = useState(0)
    const [valueWithVAT8, setValueWithVAT8] = useState(0)
    const [valueWithVAT18, setValueWithVAT18] = useState(0)

    const calculateWithoutVAT = async (withVAT, vat) => {
        try {
            const noVAT = (Number(withVAT) * Number(100)) / (Number(100) + Number(vat));
            const vatTotal = Number(withVAT) - Number(noVAT);

            if (vat == 0) {
                setAmount0(noVAT);

            } else if (vat == 8) {
                setAmount8(noVAT);
                setVat8(vatTotal)
            }
            else if (vat == 18) {
                setAmount18(noVAT);
                setVat18(vatTotal)
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateWithVAT = async (withoutVAT, vat) => {
        try {
            const vatTotal = Number(withoutVAT) * (Number(vat) / Number(100));
            const withVAT = Number(withoutVAT) + Number(vatTotal);


            if (vat == 0) {
                setValueWithVAT0(withVAT);

            } else if (vat == 8) {
                setValueWithVAT8(withVAT);
                setVat8(vatTotal)
            }
            else if (vat == 18) {
                setValueWithVAT18(withVAT);
                setVat18(vatTotal)
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateFromVAT = async (vatValue, vat) => {
        try {
            const withoutVAT = Number(vatValue) / (Number(vat) / Number(100));
            const totalAmount = Number(withoutVAT) + Number(vatValue);


            if (vat == 0) {
                setValueWithVAT0(totalAmount);
                setAmount0(withoutVAT);

            } else if (vat == 8) {
                setValueWithVAT8(totalAmount);
                setAmount8(withoutVAT);
            }
            else if (vat == 18) {
                setValueWithVAT18(totalAmount);
                setAmount18(withoutVAT);
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };
    //Pjesa per select input field Clients
    const [field, setField] = useState(null);
    const handleAutocompleteChangeSuppliers = (event, value) => {
        if (value && value.AccountID) {
            setSupplierID(value.AccountID);
            setField(value);

        } else {
            // Në rast se vlera është pastruar
            setSupplierID(""); // Vendos vlerën e ArticleID në null
        }
    };

    const defaultPropsSuppliers = {
        options: suppliers,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                </li>
            </>
        ),
    };

    useEffect(() => {
        fetchSuppliers();
        fetchPaymentType();
        fetchExpence()
    }, [])

    return (
        <div className="AddPurchaseTotal__Container" style={{ paddingTop: "50px" }}>
            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Blerje e re në total</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha te dhenat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className="AddPurchaseTotal__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <InsertSupplier fetchData={fetchSuppliers} type="insert" />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                        >
                            <button className="button-15" style={{ color: buttonContent.backgroundColor }}
                                onClick={invNumberExist > 0 ? null : handleSubmit}
                            >
                                {buttonContent.text}
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to="/purchases/purchaseintotal"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbyll
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2'></div>
            <form className='AddPurchaseTotal__Container__Format'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField
                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                    // style={{ width: "594px" }}
                                    required
                                    id="invoiceNR"
                                    label="Numri Fatures"
                                    variant="outlined"
                                    type="text"
                                    name='InvoiceNr'
                                    value={invoiceNR}
                                    // onChange={event => setInvoiceNR(event.target.value)}
                                    onChange={event => { setInvoiceNR(event.target.value); checkInvoiceNumber(event.target.value); }}

                                />

                                <div className='AddPurchaseTotal__Container__Format__Dates'>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="date"
                                        type="date"
                                        variant="outlined"
                                        name='Date'
                                        label="Data Faturës"
                                        value={date}
                                        onChange={(e) => { setDate(e.target.value); setPayDate(e.target.value); }}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="PayDate"
                                        type="date"
                                        variant="outlined"
                                        name='PayDate'
                                        label="Data pagesës"
                                        value={payDate}
                                        onChange={(e) => setPayDate(e.target.value)}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="textfield_button">
                                    <div style={{ width: "100%" }} >
                                        {/* <span style={{ color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Furnitorin</span>
                                        <Select
                                            className="AddPurchaseTotal__Container__Format__SelectList"
                                            inputId="SupplierID"
                                            value={{ label: supplierName, value: supplierID }}
                                            options={filteredSuppliers.map((data) => ({
                                                label: data.AccountName,
                                                value: data.AccountID,
                                                AccountID: data.AccountID
                                            }))}
                                            onInputChange={handleInputChange}
                                            onChange={handleFilterSupplier}
                                            isClearable
                                            placeholder="Select"
                                            noOptionsMessage={() => 'No matching'}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    height: "55px",
                                                    marginLeft: "10px", marginRight: "10px"

                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "#fff",
                                                    zIndex: 9999,
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                    borderRadius: "4px",
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    maxHeight: "200px",
                                                    overflowY: "auto",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#000",
                                                }),
                                            }}
                                        /> */}
                                        <Autocomplete
                                            {...defaultPropsSuppliers}
                                            id="include-input-in-list"
                                            includeInputInList
                                            onChange={handleAutocompleteChangeSuppliers}
                                            value={field}
                                            isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Kerko Furnitorin"
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                // variant="filled"
                                                //   onKeyPress={handleKeyPress}
                                                />
                                            )}
                                        />
                                    </div>
                                </div >
                                <div className="AddPurchaseTotal__Container__Format__Dates" style={{ paddingTop: "10px" }}>
                                    <TextField
                                        style={{ paddingRight: "5px" }}
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="AccountID"
                                        label="Llogaria Kontabël"
                                        defaultValue=""
                                        value={"107-Malli"}
                                        // onChange={event => setAccountID(703)}
                                        disabled
                                    >
                                    </TextField>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="id"
                                        select
                                        label="Lloji Blerjes"
                                        defaultValue=""
                                        value={transactionType}
                                        onChange={event => setTransactionType(event.target.value)}
                                        required
                                    >
                                        {TranscationData.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__SameInput" id="PaymentType"
                                        select
                                        label="Përzgjedh menyrën e pagesës"
                                        // defaultValue=""
                                        // helperText="Please select expense"
                                        value={paymentOptions}
                                        onChange={event => setPaymentOptions(event.target.value)}
                                    >
                                        <MenuItem key="0" value="0">Pagesa Behet më vonë
                                        </MenuItem>
                                        {cashRegister.map((data) => (
                                            <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Vlera 18%"
                                        variant="outlined"
                                        name='Amount18'
                                        value={amount18 !== null && amount18 !== 0 ? amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount18(event.target.value)}
                                        onKeyUp={event => calculateWithVAT(event.target.value, 18)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="TVSH 18%"
                                        variant="outlined"
                                        name='VAT18'
                                        // value={vat18}
                                        value={vat18 !== null && vat18 !== 0 ? vat18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setVat18(e.target.value)}
                                        onKeyUp={event => calculateFromVAT(event.target.value, 18)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total 18"
                                        type="text"
                                        // value={Number(valueWithVAT18).toFixed(2)}
                                        value={valueWithVAT18 !== null && valueWithVAT18 !== 0 ? valueWithVAT18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setValueWithVAT18(e.target.value)}
                                        onKeyUp={event => calculateWithoutVAT(event.target.value, 18)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Vlera 8%"
                                        name='Amount8'
                                        variant="outlined"
                                        value={amount8 !== null && amount8 !== 0 ? amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount8(event.target.value)}
                                        onKeyUp={event => calculateWithVAT(event.target.value, 8)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="TVSH 8"
                                        variant="outlined"
                                        name='VAT8'
                                        value={vat8 !== null && vat8 !== 0 ? vat8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setVat8(event.target.value)}
                                        onKeyUp={event => calculateFromVAT(event.target.value, 8)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total 8"
                                        type="text"
                                        // value={valueWithVAT8}
                                        value={valueWithVAT8 !== null && valueWithVAT8 !== 0 ? valueWithVAT8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setValueWithVAT8(e.target.value)}
                                        onKeyUp={event => calculateWithoutVAT(event.target.value, 8)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3' style={{ marginTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Vlera 0%"
                                        variant="outlined"
                                        name='Amount0'
                                        value={amount0 !== null && amount0 !== 0 ? amount0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount0(event.target.value)}
                                        onKeyUp={event => calculateWithVAT(event.target.value, 0)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="TVSH 0"
                                        variant="outlined"
                                        value={(0).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total 0"
                                        type="text"
                                        // value={valueWithVAT0.toFixed(2)}
                                        value={valueWithVAT0 !== null && valueWithVAT0 !== 0 ? valueWithVAT0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setValueWithVAT0(e.target.value)}
                                        onKeyUp={event => calculateWithoutVAT(event.target.value, 0)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>

                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-number"
                                        label="Total pa TVSH"
                                        type="number"
                                        value={(Number(amount8) + Number(amount18) + Number(amount0)).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled

                                    />
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-number"
                                        label="Total TVSH"
                                        type="number"
                                        value={(Number(vat8) + Number(vat18)).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled

                                    />
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-number"
                                        label="Total me TVSH"
                                        type="number"
                                        value={(Number(amount0) + Number(amount8) + Number(amount18) + Number(vat8) + Number(vat18)).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                    />
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Comment"
                                        id="Comment"
                                        label="Komenti"
                                        type="text"
                                        name='Comment'
                                        value={coment}
                                        onChange={event => setComent(event.target.value)}
                                        variant="outlined" />
                                </div>

                            </div>
                        </div>


                    </Box>
                </Paper>

                {/* <div className="butonat">
                    <button type="submit"
                        className="btn btn-primary float-end"
                        style={{ background: "#7a95a6", width: "130px" }}
                    >Save & New</button>
                    <Link
                        className="btn btn-primary float-end"
                        style={{ background: "#7a95a6", width: "130px" }}
                        to={"/purchases/purchaseintotal"}
                    >EXIT</Link>
                </div> */}
            </form>
            <div></div>
        </div>
    );
}

export default AddPurchaseInTotal;