import React, { useState, useEffect } from "react";
import '../../Style/PasqyraTeArdhuraveStyle.scss';
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import { errorAlert } from "../../Components/Alerts";
import '../../Style/VatDeclaration.scss'

export default function AnnualDeclaration() {
    const vitAktual = new Date().getFullYear();
    const headers = authHeader();

    const [companyName, setCompanyName] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [address, setAdress] = useState("");
    const [phone, setPhone] = useState("");

    const [stokuTotal, setStokuTotal] = useState(0);

    const [txf60, setTxf60] = useState(0);
    const [txf62, setTxf62] = useState(0);
    const [txf67, setTxf67] = useState(0);
    const [txf68, setTxf68] = useState(0);
    const [txf70, setTxf70] = useState(0);
    const [txf75, setTxf75] = useState(0);
    const [txf76, setTxf76] = useState(0);



    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompanyName(response.data[0].CompanyName);
            setIdNumber(response.data[0].IdentificationNumber);
            setAdress(response.data[0].Address);
            setPhone(response.data[0].PhoneNumbers);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'TaxReport/DeklarataVjetore', { headers });
            // Shëndërro vlerat null në 0 para se të ruhen në array
            const processedData = response.data.map(item => {
                Object.keys(item).forEach(key => {
                    if (item[key] === null) {
                        item[key] = 0;
                    }
                });
                return item;
            });
            setTxf60(processedData[0]._60_TeArdhurat);
            setTxf62(processedData[0]._62_Malli);
            setTxf67(processedData[0]._67_Pagat);
            setTxf68(processedData[0]._68_ShpenzimetZhvleresimit);
            setTxf70(processedData[0]._70_ShpenzimetAdministrative);
            setTxf75(processedData[0]._75_TeArdhurat_Tjera);
            setTxf76(processedData[0]._76_ShpenzimetFinanciare);
            // console.log(processedData);
            // Ruaj array-in e përpunuar me vlerat null të zëvendësuar me 0
            // setData(processedData);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Kthehet nga serveri për mungesë qasje
            } else {
                errorAlert("Gabim në kërkesë:", error);

                if (error.response && error.response.data && error.response.data.errorMessage) {
                    errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
                } else if (error.message) {
                    errorAlert(`Gabim: ${error.message}`);
                } else {
                    errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
                }
            }
        }
    };

    const fetchTotalAmount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingBooks/TotaliStokut', { headers });
            setStokuTotal(response.data[0].StokuTotal);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    let KMSH = Number(txf62) - Number(stokuTotal);
    let brutoFitimi = Number(txf60) - Number(KMSH);
    let shpenzimetOperative = Number(txf67) + Number(txf68) + Number(txf70);
    let fitimiAktivitetetOperative = Number(brutoFitimi) - Number(shpenzimetOperative);
    let aktivitetetJoOperative = Number(txf75) - Number(txf76);
    let teArdhuratNeto = Number(fitimiAktivitetetOperative) + Number(aktivitetetJoOperative)
    useEffect(() => {
        fetchCompaniesData();
        fetchData();
        fetchTotalAmount();
    }, []);
    return (
        <div style={{ paddingTop: "50px" }}>
            <div className='VatDeclaration__Container'>
                <div className='VatDeclaration__Container__HeaderData'>
                    <div>
                        <h5><b>FORMULARI I DEKLARIMIT VJETOR DHE PAGESES PER TATIMIN NË TË ARDHURAT E KORPORATAVE</b></h5>
                    </div>
                    <hr></hr>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[1]</b></h5>
                            <h5>Periudha tatimore:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{vitAktual}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[2]</b></h5>
                            <h5>Uniref</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>HSJHSG1234567</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[3]</b></h5>
                            <h5>Numri Fiskal:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{idNumber}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[4]</b></h5>
                            <h5>Emri i tatimpaguesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{companyName}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[5]</b></h5>
                            <h5>Adresa e tatimpaguesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{address}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[6]</b></h5>
                            <h5>Personi kontaktues:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{companyName}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[6a]</b></h5>
                            <h5>Kodi primar i veprimtarisë:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>0000</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[7]</b></h5>
                            <h5>Telefoni:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{phone}</h5>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="DeklarataVjetore__Table">
                    <div style={{ backgroundColor: "gray", fontWeight: "bold", color: "white", borderRadius: "5px", paddingLeft: "10px" }}>
                        <h5>Të ardhurat bruto lidhur me aktivitetet operative</h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[60] Te ardhurat bruto</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" >{txf60 !== undefined ? txf60.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(txf60 !== undefined && txf60 !== null) ? txf60.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>


                    </div>
                    <br></br>
                    <div className="DeklarataVjetore__Table__rows__Text__50">
                        <h5>Shpenzimet e lidhura me aktivitetet operative</h5>
                    </div>

                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[61] Stoku ne fillim</h5>
                        <h5 className="DeklarataVjetore__Table__rows__border">{(0).toFixed(2)}</h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[62] Blerjet apo Kostoja e prodhimit</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" >{txf62 !== undefined ? txf62.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(txf62 !== undefined && txf62 !== null) ? txf62.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>

                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[63] Total ([61]+[62])</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>{txf62 !== undefined ? txf62.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>
                            {(txf62 !== undefined && txf62 !== null) ? txf62.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[64] Stoku ne fund</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border">{stokuTotal !== undefined ? stokuTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(stokuTotal !== undefined && stokuTotal !== null) ? stokuTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[65] Kostoja e mallrave të shitura ([63]-[64])</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>{KMSH !== undefined ? KMSH.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>
                            {(KMSH !== undefined && KMSH !== null) ? KMSH.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[66] Bruto fitimi ([60]-[65])</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>{brutoFitimi !== undefined ? brutoFitimi.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>
                            {(brutoFitimi !== undefined && brutoFitimi !== null) ? brutoFitimi.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[67] Pagat bruto</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" >{txf67 !== undefined ? txf67.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(txf67 !== undefined && txf67 !== null) ? txf67.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[68] Shpenzimet për zhvlerësim dhe amortizim (nuk përfshihen në koston e mallrave)</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" >{txf68 !== undefined ? txf68.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(txf68 !== undefined && txf68 !== null) ? txf68.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[69] Shpenzimet e shitjes</h5>
                        <h5 className="DeklarataVjetore__Table__rows__border">{(0).toFixed(2)}</h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[70] Shpenzimet e pergjithsh. dhe administrative (nuk përfshihen në koston e mallrave) </h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" >{txf70 !== undefined ? txf70.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(txf70 !== undefined && txf70 !== null) ? txf70.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[71] Shpenzimet për hulumtime dhe zhvillim </h5>
                        <h5 className="DeklarataVjetore__Table__rows__border">{(0).toFixed(2)}</h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[72] Shpenzimet tjera operative </h5>
                        <h5 className="DeklarataVjetore__Table__rows__border">{(0).toFixed(2)}</h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[73] Totali i shpenzimeve operative ([67] deri [72])</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>{shpenzimetOperative !== undefined ? shpenzimetOperative.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>
                            {(shpenzimetOperative !== undefined && shpenzimetOperative !== null) ? shpenzimetOperative.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[74] FITIMI (HUMBJA) NGA AKTIVITETET OPERATIVE ([66]-[73])</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>{fitimiAktivitetetOperative !== undefined ? fitimiAktivitetetOperative.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>
                            {(fitimiAktivitetetOperative !== undefined && fitimiAktivitetetOperative !== null) ? fitimiAktivitetetOperative.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <br></br>
                    <div className="DeklarataVjetore__Table__rows__Text__50">
                        <h5>Aktivitetet jo-operative</h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[75] Të ardhurat tjera </h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" >{txf75 !== undefined ? txf75.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(txf75 !== undefined && txf75 !== null) ? txf75.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[76] Shpenzimet tjera</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" >{txf76 !== undefined ? txf76.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border">
                            {(txf76 !== undefined && txf76 !== null) ? txf76.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[77] FITIMI (HUMBJA) NGA AKTIVITETET JO-OPERATIVE ([75]-[76])</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>{aktivitetetJoOperative !== undefined ? aktivitetetJoOperative.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>
                            {(aktivitetetJoOperative !== undefined && aktivitetetJoOperative !== null) ? aktivitetetJoOperative.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                    <div className="DeklarataVjetore__Table__rows">
                        <h5>[78] Të ardhurat neto ose humbja ([74]+[77])</h5>
                        {/* <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>{teArdhuratNeto !== undefined ? teArdhuratNeto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</h5> */}
                        <h5 className="DeklarataVjetore__Table__rows__border" style={{ backgroundColor: "yellow" }}>
                            {(teArdhuratNeto !== undefined && teArdhuratNeto !== null) ? teArdhuratNeto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}