import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/Transfers.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
// import InsertData from "../Suppliers/InsertSuppliers";
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
import InsertAccounts from "../Accounts/InsertAccounts"
import Autocomplete from "@mui/material/Autocomplete";


function AddTransfer(props) {
    const headers = authHeader();
    const email = LoginUserDetailed();

    const [date, setDate] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");

    // Keto 2 const i mbajn ID re dy llogarive njera qe debiton dhe tjetra qe krediton
    const [debitAccountID, setDebitAccountID] = useState("");
    const [creditAccountID, setCreditAccountID] = useState("");

    // keto 2 const i mbajn ledgerid te llogarive qe duhet te zgjedhen 
    const [debitLedgerID, setDebitLedgerID] = useState("");
    const [creditLedgerID, setCreditLedgerID] = useState("");

    const [total, setTotal] = useState("");
    const [coment, setComent] = useState("");



    // keto 2 vargje i mbajn llogarit qe thirren duke u bazuar nga ledgerid qe i dergohet si parameter, njera eshte per debitim ndersa tjetra per kreditim
    const [debitAccounts, setDebitAccounts] = useState([]);
    const [filteredDebitAccounts, setFilteredDebitAccounts] = useState([]);

    const [creditAccounts, setCreditAccounts] = useState([]);
    const [filteredCreditAccounts, setFilteredCreditAccounts] = useState([]);

    const [ledgers, setLedgers] = useState([]);
    const [filteredLedgers, setFilteredLedgers] = useState([]);

    const currentYear = new Date().getFullYear();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!date) {
            errorAlert("Date is required!");
            return;
        }

        if (!coment) {
            errorAlert("Please fill Comment field!");
            return;
        }
        if (!total) {
            errorAlert("Please fill Total Field!");
            return;
        }

        const stateDate = {
            Date: date,
            ActionType: "TRS",
            InvoiceNr: invoiceNR,
            AccountID: debitAccountID,
            Total: total,
            LedgerID: debitLedgerID,
            PaymentOptions: creditAccountID,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'Transfers', stateDate, { headers })
            .then(() => {
                handleSuccess("Transfers Added Successfully");
                setDate("");
                fetchNextInvoiceNumber();
                setDebitLedgerID("");
                setCreditLedgerID("");
                setDebitAccountID("");
                setCreditAccountID("");
                setComent("");
                setTotal("");
                setFilteredDebitAccounts([]);
                setFilteredCreditAccounts([]);
                setDebitAccountName("");
                setCreditAccountName("");
                setDebitNameLedger("");
                setCreditNameLedger("");
                setDebitAccountField(null);
                setDebitField(null);
                setCreditField(null);
                setCreditAccountField(null);
                setCreditAccounts([]);
                setDebitAccounts([]);


            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const fetchDebitAccounts = async (debitLedgerID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: debitLedgerID } });
            setDebitAccounts(response.data);
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchCreditAccounts = async (creditLedgerID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: creditLedgerID } });
            setCreditAccounts(response.data);
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchLedger = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger', { headers });
            setLedgers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchNextInvoiceNumber = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Transfers/InvoiceNr', { headers });
            setInvoiceNR(response.data.NextInvoiceNr);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredLedgers(
            ledgers.filter((data) =>
                data.LedgerNameEN.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, ledgers]);

    // i filtron Debit Accounts
    useEffect(() => {
        setFilteredDebitAccounts(
            debitAccounts.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, debitAccounts]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredCreditAccounts(
            creditAccounts.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, creditAccounts]);



    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    // i run te dhenat per Debit Ledger
    const [debitNameLedger, setDebitNameLedger] = useState("");
    // const handleSelectChange = (selectedOption) => {
    //     if (selectedOption && selectedOption.LedgerID) {
    //         setDebitLedgerID(selectedOption.LedgerID);
    //         fetchDebitAccounts(selectedOption.LedgerID);
    //         setDebitNameLedger(selectedOption.label);
    //         // alert(selectedOption.label);
    //     } else {
    //         setDebitLedgerID("");
    //         setDebitNameLedger("");
    //     }
    // };

    // i run te dhenat per credit Ledger
    const [creditNameLedger, setCreditNameLedger] = useState("");
    const handleSelectChangeCredit = (selectedOption) => {
        if (selectedOption && selectedOption.LedgerID) {
            setCreditLedgerID(selectedOption.LedgerID);
            fetchCreditAccounts(selectedOption.LedgerID);
            setCreditNameLedger(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setCreditLedgerID("");
            setCreditNameLedger("");
        }
    };

    // i run te dhenat per Debit Account
    const [debitAccountName, setDebitAccountName] = useState("");
    const handleselectDebitAcc = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setDebitAccountID(selectedOption.AccountID);
            setDebitAccountName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setDebitAccountID("");
            setDebitAccountName("");
        }
    };
    // i run te dhenat per credit Account
    const [creditAccountName, setCreditAccountName] = useState("");
    const handleselectCreditAcc = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setCreditAccountID(selectedOption.AccountID);
            setCreditAccountName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setCreditAccountID("");
            setCreditAccountName("");
        }
    };




    ///////////////////////
    const [debitField, setDebitField] = useState(null);
    const handleAutocompleteChangeDebitLedger = (event, value) => {
        if (value && value.LedgerID) {
            setDebitLedgerID(value.LedgerID);
            setDebitField(value);
            fetchDebitAccounts(value.LedgerID)

        } else {
            // Në rast se vlera është pastruar
            setDebitLedgerID("");
            setDebitField(null);

        }
    };

    const defaultPropsDebitLedger = {
        options: ledgers,
        getOptionLabel: (option) =>
            option.LedgerNameEN || ""
    };
    //////

    const [creditField, setCreditField] = useState(null);
    const handleAutocompleteChangeCreditLedger = (event, value) => {
        if (value && value.LedgerID) {
            setCreditLedgerID(value.LedgerID);
            setCreditField(value); // Përditëso `selectedArticle` me vlerën e zgjedhur
            fetchCreditAccounts(value.LedgerID)

        } else {
            // Në rast se vlera është pastruar
            setCreditLedgerID("");
            setCreditField(null);
        }
    };

    const defaultPropsCreditLedger = {
        options: ledgers,
        getOptionLabel: (option) =>
            option.LedgerNameEN || ""
    };
    //////

    //Pjesa per select input field 
    const [debitAccountField, setDebitAccountField] = useState(null);
    const handleAutocompleteChangeDebitAccount = (event, value) => {
        if (value && value.AccountID) {
            setDebitAccountID(value.AccountID);
            setDebitAccountField(value);

        } else {
            setDebitAccountID("");
            setDebitAccountField(null);

        }
    };

    const defaultPropsDebitAccount = {
        options: debitAccounts,
        getOptionLabel: (option) =>
            option.AccountName || ""
    };
    //////

    //Pjesa per select input field Clients
    const [creditAccountField, setCreditAccountField] = useState(null);
    const handleAutocompleteChangeCreditAccount = (event, value) => {
        setCreditAccountID("");

        if (value && value.AccountID) {
            setCreditAccountID(value.AccountID);
            setCreditAccountField(value);


        } else {
            setCreditAccountID("");
            setCreditAccountField(null);

        }
    };

    const defaultPropsCreditAccount = {
        options: creditAccounts,
        getOptionLabel: (option) =>
            option.AccountName || ""
    };
    //////

    const [isModalClosed, setIsModalClosed] = useState(true); // Flag për të kontrolluar mbylljen e modalit

    const fetchDataAfterModalClose = () => {
        if (debitLedgerID === "") {
            fetchCreditAccounts(creditLedgerID);
        } else {
            fetchDebitAccounts(debitLedgerID);
        }
    };

    useEffect(() => {
        if (isModalClosed) {
            fetchDataAfterModalClose();
        }
    }, [debitLedgerID, creditLedgerID, isModalClosed]);

    const handleModalClose = () => {
        setIsModalClosed(true);
    };

    const handleModalOpen = () => {
        setIsModalClosed(false);
    };

    useEffect(() => {
        fetchLedger();
        fetchNextInvoiceNumber();
        // fetchPaymentType();
        // fetchExpence()
    }, [])

    return (
        <div className="Transfers__Container" style={{ paddingTop: "50px" }}>
            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Transfer i ri</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha të dhënat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", }}>
                    {/* <InsertAccounts fetchData={debitLedgerID == "" ? fetchCreditAccounts(creditLedgerID) : fetchDebitAccounts(debitLedgerID)} type="insert" /> */}
                    <InsertAccounts
                        fetchData={fetchDataAfterModalClose}
                        type="insert"
                        onModalClose={handleModalClose}
                        onModalOpen={handleModalOpen}
                    />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                        >
                            <button className="button-15"
                                onClick={handleSubmit}
                            >
                                Ruaj & Ri
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to="/money/transfers"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbyll
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2'></div>
            <form className='Transfers__Container__Format'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField
                                    className="Transfers__Container__Format__SameInput"
                                    id="invoiceNR"
                                    label="Numri Transferit"
                                    variant="outlined"
                                    type="text"
                                    name='InvoiceNr'
                                    value={"TRS-" + currentYear + "-" + invoiceNR}
                                    onChange={event => { setInvoiceNR(event.target.value) }}
                                    disabled

                                />
                                <TextField
                                    required
                                    className="Transfers__Container__Format__SameInput"
                                    id="date"
                                    type="date"
                                    variant="outlined"
                                    name='Date'
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                                <hr style={{ marginLeft: "15px" }}></hr>
                                <div className="Debit__Credit__Form">
                                    <span>Llogaria Debitore</span>
                                    {/* <Select
                                        inputId="LedgerID"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: debitNameLedger, value: debitLedgerID }}
                                        options={filteredLedgers.map((data) => ({
                                            label: data.LedgerNameEN,
                                            value: data.LedgerID,
                                            LedgerID: data.LedgerID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectChange}
                                        isClearable
                                        placeholder="Select Ledger"
                                        noOptionsMessage={() => 'No matching ledgers'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",

                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsDebitLedger}
                                        id="include-input-in-list"
                                        includeInputInList
                                        onChange={handleAutocompleteChangeDebitLedger}
                                        value={debitField}
                                        isOptionEqualToValue={(option, value) => option.LedgerID === value.LedgerID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh llogarinë kontabel Debitore"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                            },
                                        }}
                                    />
                                    {/* <Select
                                        inputId="AccountID"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: debitAccountName, value: debitAccountID }}
                                        options={filteredDebitAccounts.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleselectDebitAcc}
                                        isClearable
                                        placeholder="Select Account"
                                        noOptionsMessage={() => 'No matching Accounts'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px",
                                                marginTop: "10px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",

                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsDebitAccount}
                                        // sx={{ m: 2 }}
                                        id="include-input-in-list"
                                        includeInputInList
                                        value={debitAccountField}  // Lidheni me state-in `selectedArticle`
                                        onChange={handleAutocompleteChangeDebitAccount}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh llogarinë Kreditore"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='Boxx'>
                                <TextField
                                    className="Transfers__Container__Format__SameInput"
                                    required
                                    autoComplete="off"
                                    id="outlined-basic"
                                    label="Komenti"
                                    variant="outlined"
                                    name='Comment'
                                    value={coment}
                                    onChange={event => setComent(event.target.value)}
                                />
                                <TextField
                                    required
                                    autoComplete="off"
                                    className="Transfers__Container__Format__SameInput"
                                    id="outlined-basic"
                                    label="Vlera"
                                    variant="outlined"
                                    name='Total'
                                    value={total}
                                    onChange={event => setTotal(event.target.value)}
                                />
                                <hr style={{ marginRight: "65px" }}></hr>
                                <div className="Debit__Credit__Form">
                                    <span>Llogaria Kreditore</span>
                                    {/* <Select
                                        inputId="LedgerID"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: creditNameLedger, value: creditLedgerID }}
                                        options={filteredLedgers.map((data) => ({
                                            label: data.LedgerNameEN,
                                            value: data.LedgerID,
                                            LedgerID: data.LedgerID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectChangeCredit}
                                        isClearable
                                        noOptionsMessage={() => 'No matching ledgers'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                height: "50px",
                                                position: "relative",

                                                border: state.isFocused ? "1px solid #000" : "1px solid #ccc", // Ndryshoni ngjyrën e kufirit kur fokusohet
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",

                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsCreditLedger}
                                        id="include-input-in-list"
                                        includeInputInList
                                        onChange={handleAutocompleteChangeCreditLedger}
                                        value={creditField}
                                        isOptionEqualToValue={(option, value) => option.LedgerID === value.LedgerID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh llogarinë kontabel Kreditore"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                            },
                                        }}
                                    />
                                    {/* <Select
                                        inputId="PaymentType"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: creditAccountName, value: creditAccountID }}
                                        options={filteredCreditAccounts.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleselectCreditAcc}
                                        isClearable
                                        placeholder="Select Account"
                                        noOptionsMessage={() => 'No matching Accounts'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px",
                                                marginTop: "10px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",

                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsCreditAccount}
                                        // sx={{ m: 2 }}
                                        id="include-input-in-list"
                                        includeInputInList
                                        value={creditAccountField}  // Lidheni me state-in `selectedArticle`
                                        onChange={handleAutocompleteChangeCreditAccount}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh llogarinë Kreditore"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </form>
            <div></div>
        </div >
    );
}

export default AddTransfer;