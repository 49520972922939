import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './../../Style/SalesDetailStyle.scss'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import jwtDecode from "jwt-decode";
import { AiOutlineFilePdf, AiFillAppstore } from "react-icons/ai";
import { Paper, TextareaAutosize } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import PrintContent from "../../Print/PrintContent";
import { MdEdit } from "react-icons/md";
import Select from 'react-select';
import InsertClient from "../Clients/InsertClients";
import InsertArticle from "../Article/InsertArticle";
import DataGridWithActions from "../../Components/DataGridWithActions";
import { detailsData, mainGridHeader } from "./ZSalesGridHeader";
import Autocomplete from "@mui/material/Autocomplete";

export default function Z_SalesDeail() {
    const TranscationData = [
        {
            id: 1,
            type: "National"
        },
        {
            id: 2,
            type: "International"
        },
    ]
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const headers = authHeader();
    const [showDetails, setShowDetails] = useState(true);


    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [detailLine, setDetailLine] = useState([]); //i mban rreshtat e te dhenave detale 
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        PayDate: "",
        ActionType: "",
        AccountIDSpare: "",
        AccountID: "",
        ClientID: "",
        Amount0: 0,
        Amount8: 0,
        Amount18: 0,
        VAT8: "",
        VAT18: "",
        Total: "",
        PaymentOptions: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: ""
    });

    const [formDetailData, setFormDetailData] = useState({
        HistoryID: "",
        ArticleID: "",
        QtyOutput: "",
        PriceWithVAT: "",
        PriceWithoutVAT: "",
        ModifiedByUser: "",
        InvoiceNr: "",
        PaymentOptions: "",
    })


    const [articleID, setArticleID] = useState("");
    const [qtyOutput, setQtyOutput] = useState(0);
    const [insertpriceWithoutVAT, setInsertPriceWithoutVAT] = useState(0);
    const [insertPriceWithVAT, setInsertPriceWithVAT] = useState(0);
    const [insertTotal, setInsertTotal] = useState(0);
    const [vatRates, setVATRates] = useState("");
    let number = 1;

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [clients, setClients] = useState([]);
    // const [expence, setExpence] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);


    //Kodi per mbajtjen e totaleve pas llogaritjes
    const [vat8, setVat8] = useState(0);
    const [vat18, setVat18] = useState(0);
    const [total, setTotal] = useState(0);

    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const [valuedetails, setValueDetails] = useState([]);
    const [clientDetails, setClientDetails] = useState([]);


    // Te dhenat per printim
    const [headerData, setHeaderData] = useState([]);
    const [articlePrintDetails, setArticlePrintDetails] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo 
    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");

    // ky kod i ndryshon gjendjet e formave

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
        setArticleID();
        setQtyOutput(0);
        setInsertPriceWithoutVAT("");
        setInsertPriceWithVAT("");
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true); // I fsheh formën e parë
    };

    const clearEdit = () => {
        setFormDetailData({
            HistoryID: "",
            ArticleID: "",
            QtyOutput: "",
            PriceWithVAT: "",
            PriceWithoutVAT: "",
        });
        handleOpenForm2();
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales', { headers });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchDetailLineData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/Z_salesDetails', { headers, params: { invNumber: formData.InvoiceNr } });
            setDetailLine(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article', { headers });
            setArticles(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchHeaderData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/GetPrintHeaderDetails', { headers, params: { invNumber: formData.InvoiceNr } });
            setHeaderData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchBanksAccount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/BankDetails', { headers });
            setBankAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticleDataPrint = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetArticleDetailsForPrint', { headers, params: { invNumber: formData.InvoiceNr } });
            setArticlePrintDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchvaluedetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Z_RaportSales/getZ_Value', { headers, params: { invNumber: formData.InvoiceNr } });
            setValueDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    // const addRightText = (doc, text, lineY) => {
    //     doc.setFontSize(10);
    //     doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    // };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };
    // `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Invoice Number", "Date", "Client", "Amount", "VAT", "Total"]],
            body: filteredData.map((item, index) => [
                number + index,
                item.InvoiceNr,
                formatDate(item.Date),
                item.AccountName,
                (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
                (Number(item.VAT18) + Number(item.VAT8)).toFixed(2),
                item.Total.toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "List of Z-Sales In details", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("ZSalesInDetails.pdf");
    };

    //Kodi per shfaqjen e tabeles ne Print
    // const tableRef = useRef();

    // const handlePrintTable = () => {
    //     const table = tableRef.current;
    //     const tableClone = table.cloneNode(true);
    //     const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
    //     printWindow.document.write(tableClone.outerHTML);
    //     printWindow.document.close();
    //     printWindow.focus();
    //     printWindow.print();
    //     printWindow.close();
    // };


    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!formData.InvoiceNr) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        const stateDate = {
            InvoiceNr: formData.InvoiceNr,
            Date: formData.Date,
            ArticleID: articleID,
            QtyOutput: qtyOutput,
            PriceWithoutVAT: insertpriceWithoutVAT,
            PriceWithVAT: insertPriceWithVAT,
            CreatedByUser: logedEmail,
            SupplierID: formData.AccountID

        };
        axios.post(ApiLink.API_URL + 'Z_RaportSales/createnewline', stateDate, { headers, params: { vatRates: vatRates } })
            .then(() => {
                // handleSuccess("Detail  Added Successfully");
                setQtyOutput(1);
                setInsertPriceWithoutVAT(0);
                setInsertPriceWithVAT(0);
                setArticleID()
                fetchDetailLineData();
                fetchData();
                fetchvaluedetails();
                fetchHeaderData();
                fetchArticleDataPrint();
                setTotal();
                setArticlesName("");
                setInsertTotal(0);
                setField(null);
            })
            .catch((error) => {
                // console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }


    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Z_RaportSales' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Sales with Z-Raport deleted Successfully");
            fetchData();
        } catch (error) {
            deleteAlert("Sales with Z-Raport Invoice can not be deleted");
        }
    };

    const handleDeleteDetailLine = async (deleteItemIdDetail) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Z_RaportSales/oneline' + `/${deleteItemIdDetail}`, { headers, params: { email: logedEmail, invoiceNR: formData.InvoiceNr } }
            );
            deleteAlert("Sales detail line deleted Successfully");
            fetchArticleDataPrint();
            fetchData();
            fetchDetailLineData();
            fetchvaluedetails();
            fetchHeaderData();
            handleOpenForm2();

        } catch (error) {
            deleteAlert("Sales detail Line can not be deleted");
        }
    };


    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        fetchDetailLineData(item.InvoiceNr);
        fetchHeaderData(item.InvoiceNr);
        fetchvaluedetails(item.InvoiceNr);
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            PayDate: item.PaymentOptions !== 0 ? item.PayDate : null,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            SupplierID: item.SupplierID,
            PaymentOptions: item.PaymentOptions,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: logedEmail,

        });
        setShowEditModal(true);
        setShowDetails(false);
        handleOpenForm2();
    };

    const handleEditDetails = (item) => {
        handleOpenForm1();
        fetchArticlesSalesPrice(item.ArticleID, "getVat");
        setFormDetailData({
            HistoryID: item.HistoryID,
            ArticleID: item.ArticleID,
            QtyOutput: item.QtyOutput,
            PriceWithVAT: item.PriceWithVAT,
            Total: item.Total,
            PriceWithoutVAT: item.PriceWithoutVAT,
            ModifiedByUser: logedEmail,
            InvoiceNr: formData.InvoiceNr,
            PaymentOptions: formData.PaymentOptions,
        });
    };

    const clearLine = () => {
        setQtyOutput(0);
        setInsertPriceWithoutVAT(0);
        setInsertPriceWithVAT(0);
        setInsertTotal(0);
        setArticleID();
        fetchDetailLineData(formData.InvoiceNr);
        fetchData();
        fetchvaluedetails();
        fetchHeaderData();
        fetchArticleDataPrint();
        setArticlesName("");
    }


    //Kodi per kalkulimin e VAT8
    useEffect(() => {
        const VAT8 = formData.Amount8 * 0.08; // llogarit produktin me 18% shumëzim
        setVat8(VAT8); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount8]);

    //Kodi per kalkulimin e VAT18
    useEffect(() => {
        const VAT18 = formData.Amount18 * 0.18; // llogarit produktin me 18% shumëzim
        setVat18(VAT18); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount18]);

    //Kodi per kalkulimin e Total
    useEffect(() => {
        const total = Number(formData.Amount0) + Number(formData.Amount8) + Number(formData.Amount18) + Number(vat8) + Number(vat18)
        // + amount18 + vat8 + vat18   
        setTotal(total); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount0, formData.Amount8, formData.Amount18, vat8, vat18]);



    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData.PaymentOptions != 0 && (formData.PayDate == "" || formData.PayDate == null)) {
            errorAlert("Please fill payment date");
            return;
        }
        axios
            .put(ApiLink.API_URL + 'Z_RaportSales', formData, { headers })
            .then((response) => {
                // console.log(response.data);
                // setShowEditModal(false); 
                // Close the edit modal
                fetchData();
                updateAlert("Sales with Z-Raport updated successfully");
                fetchArticleDataPrint();
                fetchHeaderData();
            })
            .catch((error) => {
                // alert("Failed to update State");
                errorAlert("Failed to update Sales with Z-Raport")
            });
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDetail = (event) => {
        const { name, value } = event.target;
        setFormDetailData({ ...formDetailData, [name]: value });
    };

    const handleSubmitDetailLine = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'Z_RaportSales/updatedetailsArticle', formDetailData, { headers, params: { vatRates: vatRates } })
            .then((response) => {
                fetchDetailLineData();
                fetchvaluedetails();
                updateAlert("Detail updated successfully");
                fetchArticleDataPrint();
                fetchHeaderData();
                fetchData();
                handleOpenForm2();
            })
            .catch((error) => {
                // console.log(error);
                errorAlert("Failed to update details")
            });
    }

    const filteredData = data.filter((item) => {
        return (
            item.AccountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const fetchClientsDetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByID', { headers, params: { id: formData.AccountID } });
            setClientDetails(response.data);
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchClients = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
            setClients(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchArticlesSalesPrice = async (articleID, form) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetSalesPrice', { headers, params: { articleID: articleID } });
            const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja

            if (form == "edit") {
                const calculatedValue = parseFloat(response.data.PurchasePrice) * 100 / (100 + response.data.PurchasePrice);
                // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
                setVATRates(response.data.VATRates);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: response.data.PurchasePrice,
                    PriceWithoutVAT: calculatedValue,
                    Total: formDetailData.QtyOutput * response.data.PurchasePrice
                }));
            }
            else if (form == "getVat") {
                setVATRates(response.data.VATRates);
            }
            else {
                setInsertPriceWithVAT(salesPriceData.SalesPrice);
                setVATRates(salesPriceData.VATRates);
                setQtyOutput(1);
                setInsertTotal(1 * salesPriceData.SalesPrice)
                // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
                const calculatedValue = parseFloat(salesPriceData.SalesPrice) * 100 / (100 + salesPriceData.VATRates);
                setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
            }
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    useEffect(() => {
        // Përditëso vlerën e insertPriceWithoutVAT kur ndryshon vatRates
        const calculatedValue = parseFloat(insertPriceWithVAT) * 100 / (100 + vatRates);
        setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
    }, [vatRates, insertPriceWithVAT]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredArticles(
            articles.filter((data) =>
                data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, articles]);

    const handleInput = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [articlesName, setArticlesName] = useState("");
    const handleFilterArticles = (selectedOption) => {
        if (selectedOption && selectedOption.ArticleID) {
            setArticleID(selectedOption.ArticleID);
            setArticlesName(selectedOption.label);
            fetchArticlesSalesPrice(selectedOption.ArticleID);
        } else {
            setArticleID("");
            setArticlesName("");
        }
    };
    const handleCalculatePriceWithVAT = async (type) => {
        try {
            if (isNaN(insertpriceWithoutVAT)) {
                throw new Error("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }
            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const vat = Number(formDetailData.PriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(formDetailData.PriceWithoutVAT) + vat;
                const total = Number(formDetailData.QtyOutput) * calculatedPriceWithVAT;

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: calculatedPriceWithVAT,
                    Total: total
                }));

            } else {
                const vat = Number(insertpriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(insertpriceWithoutVAT) + vat;
                const total = Number(qtyOutput) * calculatedPriceWithVAT;
                // Veprimet specifike për insert të ri
                setInsertPriceWithVAT(calculatedPriceWithVAT);
                setInsertTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    const handleCalculatePriceWithoutVAT = async (type) => {
        try {
            if (isNaN(insertPriceWithVAT)) {
                throw new Error("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const calculatedPriceWithoutVAT = Number(formDetailData.PriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(formDetailData.QtyOutput) * Number(formDetailData.PriceWithVAT);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                    Total: total
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const calculatedPriceWithoutVAT = Number(insertPriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(qtyOutput) * Number(insertPriceWithVAT);

                setInsertPriceWithoutVAT(calculatedPriceWithoutVAT);
                setInsertTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    const handleCalculatePrices = async (type) => {
        try {
            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
            }

            if (isNaN(insertTotal)) {
                throw new Error("Totali duhet të jetë një numër.");
            }
            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const priceWithVAT = Number(formDetailData.Total) / Number(formDetailData.QtyOutput);
                const calculatedPriceWithoutVAT = formDetailData.PriceWithVAT * 100 / (100 + Number(vatRates));

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: priceWithVAT,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const priceWithVAT = Number(insertTotal) / Number(qtyOutput);
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));

                setInsertPriceWithoutVAT(calculatedPriceWithoutVAT);
                setInsertPriceWithVAT(priceWithVAT);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'ArticleID':
                    nextField = document.getElementById('QtyOutput');
                    break;
                case 'QtyOutput':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithVAT':
                    nextField = document.getElementById('Total');
                    break;
                case 'PriceWithoutVAT':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'Total':
                    if (showEditForm === true && showInserForm === false) {
                        handleSubmitDetailLine(e);
                    } else if (showInserForm === true && showEditForm === false) {
                        handleNewSubmit(e);
                    }
                    nextField = document.getElementById('ArticleID');
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select(); // Shtoni këtë rresht për të selektuar tekstin
            }
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        inv: item.InvoiceNr,
        date: (item.Date.substring(0, 10)),
        client: item.AccountName,
        amount: (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
        vat: ((Number(item.VAT18) + Number(item.VAT8)).toFixed(2)),
        total: item.Total,
        deleteItemId: item.TransactionID, // Shtimi i një atributi për id e rreshtit për fshirje
        rowInfo: item
    }));

    const detailsRows = detailLine.map((item, index) => ({
        id: ++index,
        article: item.ArticleName,
        qty: item.QtyOutput,
        PNoVAT: item.PriceWithoutVAT,
        PVAT: item.PriceWithVAT,
        amountNoVAT: item.Amount,
        vat: item.VAT,
        amountVAT: item.Total,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));

    //Select list for Clients
    const handleAutocompleteChangeClients = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    const defaultPropsClients = {
        options: clients,
        getOptionLabel: (option) => option.AccountName || "",
    };

    ////////////////////////////////

    ////Select list tek Artikulli per editim
    const handleAutocompleteChangeArticleEdit = (event, value) => {
        if (value) {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: value.ArticleID
            }));
            fetchArticlesSalesPrice(value.ArticleID, "edit");
        } else {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: ""
            }));
        }
    };

    const defaultPropsArticleEdit = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };

    //Pjesa per select input field Article
    const [field, setField] = useState(null);
    const handleAutocompleteChangeArticle = (event, value) => {
        if (value && value.ArticleID) {
            setArticleID(value.ArticleID);
            fetchArticlesSalesPrice(value.ArticleID);
            setField(value);

        } else {
            // Në rast se vlera është pastruar
            setArticleID(""); // Vendos vlerën e ArticleID në null
            setField(null);

        }
    };

    const defaultPropsArticle = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };


    useEffect(() => {
        fetchData();
        fetchClientsDetails();
        fetchDetailLineData();
        fetchArticles();
        fetchvaluedetails();
        fetchClients();
        fetchHeaderData();
        fetchCompaniesData();
        fetchArticleDataPrint();
        fetchBanksAccount();
        fetchPaymentType();
        // fetchArticlesSalesPrice()
    }, [formData.AccountID, formData.InvoiceNr, articleID]);

    return (
        <div className="AccountingSheet__Container" style={{ paddingTop: "50px" }}>
            {showEditModal && (
                <div className="SalesDetail__Container">
                    <div className="header_flex">
                        <div className="header_icon">
                            <MdEdit style={{ color: '#466F86', width: "40px" }} />
                            <h3>Edit sales with Z-Raport</h3>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="SalesDetail__Container__AllButtons">
                        <div style={{ display: "flex", }}>
                            <div>
                                <InsertClient fetchData={fetchClients} type="insert" />
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <InsertArticle fetchData={fetchArticles} type="insert" />
                            </div>
                        </div>
                        <div style={{ display: "flex", }}>
                            <div style={{ marginLeft: "5px" }}>
                                <PrintContent details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyOutput" actiontype="true" />
                            </div>

                            <div style={{ marginLeft: "5px" }}>
                                <Link to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button className="button-15" variant="contained" onClick={handleSubmit}>Update</button>

                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button className="button-15" variant="contained"
                                        onClick={() => {
                                            setShowDetails(true);
                                            setShowEditModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='header_paper2'></div>
                    <Paper style={{ padding: "20px" }}>
                        <form className='header_paper'>
                            <Box
                                // component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className='SalesDetail__Container__Form'>
                                    <div className="SalesDetail__Container__Form__div">
                                        <div className="SalesDetail__Container__Form__div__1">
                                            <div className="SalesDetail__Container__Form__div__2">
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__1"
                                                    id="invoiceNR"
                                                    label="Invoice Number"
                                                    variant="outlined"
                                                    type="text"
                                                    name='InvoiceNr'
                                                    value={formData.InvoiceNr}
                                                    // value={formData.InvoiceNr}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__1"
                                                    id="id"
                                                    select
                                                    name="TransactionType"
                                                    label="Select Transaction Type"
                                                    value={formData.TransactionType}
                                                    onChange={handleInputChange}
                                                >
                                                    {TranscationData.map((data) => (
                                                        <MenuItem key={data.id} value={data.id}>{data.type}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextareaAutosize style={{ height: "70px", marginLeft: "10px" }}
                                                    className="SalesDetail__Container__Form__TextField__1"
                                                    id="Coment"
                                                    label="Coment"
                                                    type="text"
                                                    name='Coment'
                                                    value={formData.Coment}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div className="SalesDetail__Container__Form__TextField__date__Payment">
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__date"
                                                    id="date"
                                                    type="date"
                                                    variant="outlined"
                                                    name='Date'
                                                    value={formData.Date.substring(0, 10)}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__Payment"
                                                    id="AccountiD"
                                                    select
                                                    label="Select Payment Type"
                                                    name="PaymentOptions"
                                                    value={formData.PaymentOptions}
                                                    onChange={handleInputChange}
                                                >
                                                    <MenuItem key="0" value="0">Pagesa Behet më vonë
                                                    </MenuItem>
                                                    {cashRegister.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {
                                                    formData.PaymentOptions != 0 ? (
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__date"
                                                            id="PayDate"
                                                            type="date"
                                                            variant="outlined"
                                                            label="Payment Date"
                                                            name='PayDate'
                                                            value={formData.PayDate ? formData.PayDate.substring(0, 10) : ''}
                                                            onChange={handleInputChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="SalesDetail__Container__Form__div__4">
                                        <div>
                                            {/* <TextField className="SalesDetail__Container__Form__TextField__2"
                                                // style={{ width: "96%" }}
                                                id="AccountID"
                                                name="AccountID"
                                                select
                                                label="Select Supplier"
                                                value={formData.AccountID}
                                                onChange={handleInputChange}
                                            >
                                                {clients.map((data) => (
                                                    <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                            <Autocomplete
                                                fullWidth
                                                // sx={{ m: 2 }}
                                                {...defaultPropsClients}
                                                id="AccountID"
                                                includeInputInList
                                                onChange={handleAutocompleteChangeClients}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        label="Klienti"
                                                        // variant="filled"
                                                        className="SalesDetail__Container__Form__TextField__2"
                                                    />
                                                )}
                                                value={clients.find(option => option.AccountID === formData.AccountID) || null}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <TextField
                                                className="SalesDetail__Container__Form__TextField__IDNumber"
                                                // style={{ width: "49%" }}
                                                id="outlined-basic"
                                                label="Identification Number"
                                                variant="outlined"
                                                name="IdentificationNumber"
                                                value={clientDetails.length > 0 ? clientDetails[0].IdentificationNumber : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                readOnly
                                                autoComplete="off"
                                            />
                                            <TextField
                                                className="SalesDetail__Container__Form__TextField__IDNumber"
                                                // style={{ width: "49%" }}
                                                id="outlined-basic"
                                                label="VAT Number"
                                                variant="outlined"
                                                name="VatNumber"
                                                value={clientDetails.length > 0 ? clientDetails[0].VATNumber : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                readOnly
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                className="SalesDetail__Container__Form__TextField__2"
                                                // style={{ width: "96%" }}
                                                id="outlined-basic"
                                                label="Address"
                                                variant="outlined"
                                                name="Address"
                                                value={clientDetails.length > 0 ? clientDetails[0].Adress : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                readOnly
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            {/* <button type="submit" className="btn btn-success">Update</button>

                            <button
                                className="btn btn-secondary"
                                onClick={() => setShowEditModal(false)}
                            >
                                Cancel
                            </button> */}
                        </form>
                        <div style={{ backgroundColor: "gainsboro " }}>
                            <hr></hr>

                            {showEditForm && (
                                <div className="SalesDetail__Container">
                                    <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
                                        <Box
                                            // component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            {/* <p style={{ color: "red", fontSize: "16px", fontStyle: "italic", borderBottom: "1px dashed red" }}>Click on a line to edit the data</p> */}
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__Article" style={{ paddingTop: "12px" }}>
                                                    {/* <TextField
                                                        className="SalesDetail__Container__Form__TextField__Article"
                                                        id="ArticleID"
                                                        select
                                                        label="Select Article"
                                                        name="ArticleID"
                                                        value={formDetailData.ArticleID}
                                                        onChange={(event) => {
                                                            handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
                                                            fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
                                                        }}                                                    >
                                                        {articles.map((data) => (
                                                            <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        // sx={{ m: 2 }}
                                                        {...defaultPropsArticleEdit}
                                                        id="ArticleID"
                                                        includeInputInList
                                                        onChange={handleAutocompleteChangeArticleEdit}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label="Artikulli"
                                                                className="SalesDetail__Container__Form__TextField__Article"
                                                            // variant="filled" 
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                        value={articles.find(option => option.ArticleID === formDetailData.ArticleID) || null}
                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            type="text"
                                                            id="QtyOutput"
                                                            name="QtyOutput"
                                                            label="Qty Output"
                                                            variant="outlined"
                                                            value={formDetailData.QtyOutput}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}

                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithoutVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price Without VAT"
                                                            name="PriceWithoutVAT"
                                                            // value={formDetailData.PriceWithoutVAT}
                                                            value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePriceWithVAT("edit")}
                                                        />
                                                    </div>

                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price With VAT"
                                                            name="PriceWithVAT"
                                                            // value={formDetailData.PriceWithVAT}
                                                            value={typeof formDetailData.PriceWithVAT === 'number' ? formDetailData.PriceWithVAT.toFixed(4) : formDetailData.PriceWithVAT}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                        />
                                                    </div>
                                                    <div className='SalesDetail__Container__Second__Form__details'>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="Total"
                                                            label="Total"
                                                            variant="outlined"
                                                            name='Total'
                                                            value={typeof formDetailData.Total === 'number' ? formDetailData.Total.toFixed(2) : formDetailData.Total}
                                                            onChange={handleInputChangeDetail}
                                                            onKeyUp={() => handleCalculatePrices("edit")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="butonat">
                                                        <div>
                                                            <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-check-lg"></i>
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-x-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="responsive">
                                                        <div className="responsive__butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-check-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </form>
                                </div>
                            )}
                            {showInserForm && (
                                <div className="SalesDetail__Container">
                                    <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
                                        <Box
                                            // component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            {/* <p style={{ color: "blue", fontSize: "16px", fontWeight: "bold", borderTop: "1px dashed blue" }}>Please fill in all the required fields.</p> */}
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__Article">
                                                    {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Select Article</span>
                                                    <Select
                                                        className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                        inputId="ArticleID"
                                                        value={{ label: articlesName, value: articleID }}
                                                        options={filteredArticles.map((data) => ({
                                                            label: data.ArticleName,
                                                            value: data.ArticleID,
                                                            ArticleID: data.ArticleID
                                                        }))}
                                                        onInputChange={handleInput}
                                                        onChange={handleFilterArticles}
                                                        isClearable
                                                        placeholder="Select"
                                                        noOptionsMessage={() => 'No matching'}
                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                height: "55px",
                                                                marginLeft: "10px",
                                                                marginRight: "12px",
                                                                backgroundColor: "transparent",
                                                                border: "darkgray solid 1px"
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: "#fff",
                                                                zIndex: 9999,
                                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                borderRadius: "4px",
                                                            }),
                                                            menuList: (provided) => ({
                                                                ...provided,
                                                                maxHeight: "200px",
                                                                overflowY: "auto",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: "#000",
                                                            }),
                                                        }}
                                                    /> */}
                                                    <Autocomplete
                                                        style={{ paddingTop: "10px" }}
                                                        fullWidth
                                                        {...defaultPropsArticle}
                                                        id="ArticleID"
                                                        value={field}
                                                        includeInputInList
                                                        onChange={handleAutocompleteChangeArticle}
                                                        isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Kerko Artikullin"
                                                                className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                                style={{ width: "90%" }}
                                                            // variant="filled"
                                                            // onKeyPress={handleKeyPress}
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="QtyOutput"
                                                            type="text"
                                                            variant="outlined"
                                                            label="QTY Output"
                                                            name='QtyOutput'
                                                            value={qtyOutput}
                                                            onChange={(e) => setQtyOutput(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithoutVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price Without VAT"
                                                            name="PriceWithoutVAT"
                                                            value={typeof insertpriceWithoutVAT === 'number' ? insertpriceWithoutVAT.toFixed(4) : insertpriceWithoutVAT}
                                                            onChange={(e) => setInsertPriceWithoutVAT(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithVAT}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Price With VAT"
                                                            name="PriceWithVAT"
                                                            value={typeof insertPriceWithVAT === 'number' ? insertPriceWithVAT.toFixed(4) : insertPriceWithVAT}
                                                            onChange={(e) => setInsertPriceWithVAT(e.target.value)}
                                                            onKeyUp={handleCalculatePriceWithoutVAT}
                                                        />
                                                    </div>
                                                    <div className='SalesDetail__Container__Second__Form__details'>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="Total"
                                                            label="Total"
                                                            variant="outlined"
                                                            name='Amount0'
                                                            value={typeof insertTotal === 'number' ? insertTotal.toFixed(2) : insertTotal}
                                                            onChange={event => setInsertTotal(event.target.value)}
                                                            onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />

                                                    </div>
                                                    <div className="butonat">
                                                        <div>
                                                            <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-plus-lg"></i>
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-x-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="responsive">
                                                        <div className="responsive__butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </form>
                                </div>
                            )}
                            <DataGridWithActions
                                columns={detailsData}
                                rows={detailsRows}
                                handleEdit={handleEditDetails}
                                handleDelete={handleDeleteDetailLine}
                                showPayment={false}
                            />
                        </div>
                        <br></br>
                        <div className='responsive__Tablet'>
                            <div className='SalesDetail__Container__Form'>
                                <div style={{ marginLeft: "20px" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Amount18"
                                        // style={{ width: "100%" }}
                                        id="Amount18"
                                        label="Amount 18"
                                        variant="outlined"
                                        type="text"
                                        name='Amount18'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount18).toFixed(2) : ''}
                                        disabled
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VAT18"
                                        // style={{ width: "100%" }}
                                        id="VAT18"
                                        label="VAT 18"
                                        variant="outlined"
                                        type="text"
                                        name='VAT18'
                                        value={valuedetails.length > 0 ? (valuedetails[0].VAT18).toFixed(2) : ''}
                                        disabled
                                    />
                                </div>
                                <br></br>
                                <div style={{ marginLeft: "20px" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Amount8"
                                        // style={{ width: "100%" }}
                                        id="Amount8"
                                        label="Amount 8"
                                        variant="outlined"
                                        type="text"
                                        name='Amount8'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount8).toFixed(2) : ''}
                                        disabled
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VAT8"
                                        // style={{ width: "100%" }}
                                        id="VAT8"
                                        label="VAT 8"
                                        variant="outlined"
                                        type="text"
                                        name='VAT8'
                                        value={valuedetails.length > 0 ? (valuedetails[0].VAT8).toFixed(2) : ''}
                                        disabled
                                    />
                                </div>
                                <br></br>
                                <div style={{ marginLeft: "20px" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Amount0"
                                        // style={{ width: "100%" }}
                                        id="Amount0"
                                        label="Amount 0"
                                        variant="outlined"
                                        type="text"
                                        name='Amount0'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount0).toFixed() : ''}
                                        disabled
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VAT0"
                                        // style={{ width: "100%" }}
                                        id="VAT0"
                                        label="VAT 0"
                                        variant="outlined"
                                        type="text"
                                        name='VAT0'
                                        value={valuedetails.length > 0 ? 0 : ''}
                                        disabled
                                    />
                                </div>
                                <br></br>
                                <div style={{ marginLeft: "20px" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__TotalAmount"
                                        // style={{ width: "100%" }}
                                        id="TotalAmount"
                                        label="Total WithOut VAT"
                                        variant="outlined"
                                        type="text"
                                        name='TotalAmount'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toFixed(2) : ''}
                                        disabled
                                    />
                                    <br></br>
                                    <br></br>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__VATTotal"
                                        // style={{ width: "100%" }}
                                        id="VATTotal"
                                        label="VAT Total"
                                        variant="outlined"
                                        type="text"
                                        name='VATTotal'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
                                        disabled
                                    />
                                </div>
                                <br></br>
                                <div style={{ marginLeft: "20px" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__Total"
                                        // style={{ width: "100%" }}
                                        id="Totali"
                                        label="Total With VAT"
                                        variant="outlined"
                                        type="text"
                                        name='Totali'
                                        value={valuedetails.length > 0 ? (valuedetails[0].Total).toFixed(2) : ''}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
            )}
            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Z-Sales in details Invoice</h3>
                        </div>
                        <div>
                            <Link to={"/Sales/add-Z-sales"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />  Add New Z-Sales Invoice
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Accounting is the process of recording financial transactions pertaining to a business.</p>
                    </div>
                    <DataGridWithActions
                        columns={mainGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={true}
                        height={500}
                    />
                </div>
            )}
        </div>
    )
}