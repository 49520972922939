import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/__button.scss'

function InsertCompanyUnit(props) {
    const headers = authHeader();
    const loginEmail = LoginUserDetailed();


    const [unitName, setUnitName] = useState("");
    const [identificationNumber, setIdentificationNumber] = useState("");
    const [unitAdress, setUnitAddres] = useState("");
    const [cityID, setCityID] = useState("");
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [createdByUser, setCreatedByUser] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [city, setCity] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const stateData = {
            UnitName: unitName,
            IdentificationNumber: identificationNumber,
            UnitAdress: unitAdress,
            CityID: cityID,
            PhoneNumbers: phoneNumbers,
            Email: email,
            Comment: comment,
            CreatedByUser: loginEmail,
            IsActive: isActive,
        };
        axios.post(ApiLink.API_URL + 'CompanyUnits', stateData, { headers })
            .then((response) => {
                console.log(response.data);
                handleSuccess("Company Added Successfully");
                setUnitName("");
                setIdentificationNumber("");
                setUnitAddres("");
                setCityID("");
                setPhoneNumbers("");
                setEmail("");
                setComment("");
                setIsActive(true);
                props.onHide();
                props.refreshData(); // Thirrja e funksionit per refresh te dhenave

            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    };

    useEffect(() => {
        const headers = authHeader();
        axios.get(ApiLink.API_URL + 'cities', { headers })
            .then((response) => {
                setCity(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Insert New Company Unit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Unit Name </span>
                            <input
                                type="text"
                                className="form-control"
                                value={unitName}
                                onChange={event => setUnitName(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Identification Number </span>
                            <input
                                type="number"
                                className="form-control"
                                value={identificationNumber}
                                onChange={event => setIdentificationNumber(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Unit Adress</span>
                            <input
                                type="text"
                                className="form-control"
                                value={unitAdress}
                                onChange={event => setUnitAddres(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <label className="input-group-text col-5 col-sm-5" htmlFor="selectCity">City </label>
                            <select
                                id="selectCity"
                                className="form-control"
                                value={cityID}
                                onChange={event => setCityID(event.target.value)}
                                required
                            >
                                <option value="">Select State</option>
                                {city.map(cities =>
                                    <option key={cities.CityID} value={cities.CityID}>{cities.CityName}</option>
                                )}
                            </select>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Phone Number </span>
                            <input
                                type="text"
                                className="form-control"
                                value={phoneNumbers}
                                onChange={event => setPhoneNumbers(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Email </span>
                            <input
                                type="text"
                                className="form-control"
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Comment </span>
                            <textarea
                                className="form-control"
                                value={comment}
                                onChange={event => setComment(event.target.value)}
                            />
                        </div>
                        <button type="submit"
                            className="btn btn-primary float-end"
                            style={{ background: "#7a95a6" }}
                        >Save</button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };

    return (
        <>
            <button className="button-68" onClick={() => setModalShow(true)}>
                <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Add New Company Unit
            </button>

            <InsertCompanyUnit
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}