const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const PurchaseGridHeader = [
    { field: 'id', headerName: '#', width: 60, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'inv', headerName: 'Numri i Fatures', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'client', headerName: 'Furnitori', width: 260, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'amount', headerName: 'Vlera', type: 'string', width: 155, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'vat', headerName: 'TVSH', type: 'string', width: 155, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'total', headerName: 'Total', type: 'string', width: 155, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]