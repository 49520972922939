import React, { useState, useEffect } from "react";
import axios from "axios";
import './../../Style/SalesDetailStyle.scss'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import jwtDecode from "jwt-decode";
import { AiOutlineFilePdf, AiFillShopping } from "react-icons/ai";
import { Paper, TextareaAutosize } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { MdEdit } from "react-icons/md";
import Select from 'react-select';
import InsertSupplier from "../Suppliers/InsertSuppliers";
import InsertArticle from "../Article/InsertArticle";
import InputAdornment from '@mui/material/InputAdornment';
import DataGridWithActions from "../../Components/DataGridWithActions";
import { PurchaseGridHeader } from "../PurchaseInTotal/PurchaseGridHeader";
import Mui_Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import { importGridHeader } from "./ImportGridHeader";

export default function Import() {
    const TranscationData = [
        {
            id: 1,
            type: "Vendore"
        },
        {
            id: 2,
            type: "Import"
        },
    ]
    const [open, setOpen] = React.useState(false);

    const [showDetails, setShowDetails] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [detailLine, setDetailLine] = useState([]); //i mban rreshtat e te dhenave detale 
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        PayDate: "",
        SupplierID: "",
        PaymentOptions: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: "",
        AccountID: "",
        ActionType: "",
    });

    const [formDetailData, setFormDetailData] = useState({
        HistoryID: "",
        ArticleID: "",
        QtyInput: "",
        PriceWithoutVAT: "",
        ImportAmount: "",
        Transport: "",
        CustomsReassessment: "",
        ExciseTax: "",
        CustomsValue: "",
        CustomsCalc: "",
        Total: "",
        ModifiedByUser: "",
        InvoiceNr: "",
        PaymentOptions: "",
    })

    const [dudDetails, setDudDetails] = useState({
        TransactionID: "",
        AccountIDSpare: "",
        InvoiceNr: "",
        ImpInvoiceDate: "",
        ImpInvoiceNr: "",
        ImportAmountPrelim: "",
        TransportPrelim: "",
        CreatedByUser: "",
    })

    //Per insertimin ne Article History
    const [articleID, setArticleID] = useState("");
    const [qtyinput, setQtyInput] = useState(0);
    const [insertpriceWithoutVAT, setInsertPriceWithoutVAT] = useState(0);
    const [vatRates, setVATRates] = useState("");

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [supplier, setSupplier] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);
    const [supplierdetails, setSupplierDetails] = useState([]);

    const [transport, setTransport] = useState(0);
    const [importAmount, setImportAmount] = useState(0);
    const [customsReassessment, setCustomsReassessment] = useState(0);
    const [customsCalc, setCustomsCalc] = useState(0);
    // const [customsPercent, setCustomsPercent] = useState(0);
    const [customsValue, setCustomsValue] = useState(0);
    const [exciseTax, setExciseTax] = useState(0);


    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const [valuedetails, setValueDetails] = useState([]);


    let number = 1;

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");

    // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo 
    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);

    // ky kod i ndryshon gjendjet e formave

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
        setArticleID();
        setQtyInput(0);
        setInsertPriceWithoutVAT(0);
        setTransport(0);
        setImportAmount(0);
        setCustomsReassessment(0);
        setCustomsCalc(0);
        setCustomsValue(0);
        setExciseTax(0);
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true); // I fsheh formën e parë
    };

    const clearEdit = () => {
        setFormDetailData({
            HistoryID: "",
            ArticleID: "",
            QtyInput: 0,
            PriceWithVAT: 0,
            PriceWithoutVAT: 0,
            setFormDetailData: 0
        });
        clearLine();
        handleOpenForm2();
    };

    // ky kod sherben per select listen e menyres se pageses tek dudi
    const handleAutocompleteChange = (event, value) => {
        if (value) {
            setDudDetails(prevState => ({
                ...prevState,
                AccountIDSpare: value.AccountID
            }));
        } else {
            setDudDetails(prevState => ({
                ...prevState,
                AccountIDSpare: value.AccountID
            }));
        }
    };

    const defaultProps = {
        options: cashRegister,
        getOptionLabel: (option) => option.AccountName || ""
    };

    ////////////////////////////////////////////////////////////////

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Import', { headers });
            const processedData = response.data.map(item => {
                Object.keys(item).forEach(key => {
                    if (item[key] === null) {
                        item[key] = 0;
                    }
                });
                return item;
            });
            setData(processedData);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchDetailLineData = async (invoicenr) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Import/importDetail', { headers, params: { invNumber: invoicenr } });

            const processedData = response.data.map(item => {
                Object.keys(item).forEach(key => {
                    if (item[key] === null) {
                        item[key] = 0;
                    }
                });
                return item;
            });
            setDetailLine(processedData);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article', { headers });
            setArticles(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchvaluedetails = async (number) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Import/getvaluedetails', { headers, params: { invNumber: number } });
            setValueDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Invoice Number", "Date", "Client", "Amount", "VAT", "Total"]],
            body: filteredData.map((item, index) => [
                number + index,
                item.InvoiceNr,
                formatDate(item.Date),
                item.AccountName,
                (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
                (Number(item.VAT18) + Number(item.VAT8)).toFixed(2),
                item.Total.toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "List of Purchase In details", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("PurchaseInDetails.pdf");
    };


    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Import' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Importi është fshirë me sukses");
            fetchData();
        } catch (error) {
            deleteAlert("Importi nuk është fshirë");
        }
    };

    const handleDeleteDetailLine = async (deleteItemIdDetail) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Import/oneline' + `/${deleteItemIdDetail}`, { headers, params: { email: logedEmail, invoiceNR: formData.InvoiceNr } }
            );
            deleteAlert("Linja e detaleve është fshirë me sukses");
            fetchData();
            fetchDetailLineData(formData.InvoiceNr);
            fetchvaluedetails(formData.InvoiceNr);

        } catch (error) {
            deleteAlert("Linja e detaleve nuk është fshirë");
        }
    };

    const handleCancelAll = () => {
        setShowEditModal(false);
        setShowDetails(true);
        setArticlesName("");
        setArticleID("");
        setQtyInput(0);
        setInsertPriceWithoutVAT(0);
        setTransport(0);
        setImportAmount(0);
        setCustomsReassessment(0);
        setCustomsCalc(0);
        setCustomsValue(0);
        setExciseTax(0);
    }

    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        fetchDetailLineData(item.InvoiceNr);
        fetchvaluedetails(item.InvoiceNr);
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            PayDate: item.PayDate,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            SupplierID: item.SupplierID,
            PaymentOptions: item.PaymentOptions,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: logedEmail,
        });

        setDudDetails({
            TransactionID: item.TransactionID,
            AccountIDSpare: item.AccountIDSpare,
            InvoiceNr: item.InvoiceNr,
            ImpInvoiceDate: item.ImpInvoiceDate,
            ImpInvoiceNr: item.ImpInvoiceNr,
            ImportAmountPrelim: item.ImportAmountPrelim,
            TransportPrelim: item.TransportPrelim,
            CreatedByUser: logedEmail,
        });

        setShowEditModal(true);
        setShowDetails(false);
        handleOpenForm2();
    };

    const handleEditDetails = (item) => {
        handleOpenForm1();
        fetchArticlesSalesPrice(item.ArticleID, "getVat")
        setFormDetailData({
            HistoryID: item.HistoryID,
            ArticleID: item.ArticleID,
            QtyInput: item.QtyInput,
            PriceWithoutVAT: item.PriceWithoutVAT,
            ImportAmount: item.ImportAmount,
            Transport: item.Transport,
            CustomsReassessment: item.CustomsReassessment,
            ExciseTax: item.ExciseTax,
            CustomsValue: item.CustomsValue,
            CustomsCalc: item.CustomsCalc,
            Total: item.Total,
            ModifiedByUser: logedEmail,
            InvoiceNr: formData.InvoiceNr,
            PaymentOptions: formData.PaymentOptions,
        });
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData.PaymentOptions != 0 && (formData.PayDate == "" || formData.PayDate == null)) {
            errorAlert("Please fill payment date");
            return;
        }
        axios
            .put(ApiLink.API_URL + 'PurchaseDetail', formData, { headers })
            .then((response) => {
                console.log(response.data);
                // setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("Importi është edituar me sukses");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Importi nuk është edituar me sukses")
            });
    };

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!formData.InvoiceNr) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        const stateDate = {
            InvoiceNr: formData.InvoiceNr,
            Date: formData.Date,
            ArticleID: articleID,
            QtyInput: qtyinput,
            PriceWithoutVAT: insertpriceWithoutVAT,
            ImportAmount: importAmount,
            Transport: transport,
            CustomsReassessment: customsReassessment,
            ExciseTax: exciseTax,
            CustomsValue: customsValue,
            CustomsCalc: customsCalc,
            CreatedByUser: logedEmail,
            SupplierID: formData.AccountID

        };
        axios.post(ApiLink.API_URL + 'Import/createnewline', stateDate, { headers, params: { vatRates: vatRates } })
            .then(() => {
                // handleSuccess("Detail  Added Successfully");
                setArticleID("");
                setQtyInput(0);
                setInsertPriceWithoutVAT(0);
                fetchData();
                fetchvaluedetails(formData.InvoiceNr);
                setArticlesName();
                fetchDetailLineData(formData.InvoiceNr);
                setTransport(0);
                setImportAmount(0);
                setCustomsReassessment(0);
                setCustomsCalc(0);
                setCustomsValue(0);
                setExciseTax(0);
                setField(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDetail = (event) => {
        const { name, value } = event.target;
        setFormDetailData({ ...formDetailData, [name]: value });
    };
    const handleSubmitDetailLine = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'Import/updatedetailsArticle', formDetailData, { headers, params: { vatRates: vatRates } })
            .then((response) => {
                console.log(response.data);
                fetchDetailLineData(formData.InvoiceNr);
                fetchvaluedetails(formData.InvoiceNr);
                updateAlert("Linja detale është edituar me sukses");
                fetchData();
                handleOpenForm2();
            })
            .catch((error) => {
                errorAlert("Linja detale nuk është edituar me sukses")
            });
    }

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDudDetials = (event) => {
        const { name, value } = event.target;
        setDudDetails({ ...dudDetails, [name]: value });
    };
    const handleSubmitDudDetails = (event) => {
        event.preventDefault();
        axios.post(ApiLink.API_URL + 'Import/updateDudDetailTransaction', dudDetails, { headers })
            .then(() => {
            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }


    const clearLine = () => {
        setQtyInput(0);
        setInsertPriceWithoutVAT(0);
        setArticleID();
        fetchDetailLineData(formData.InvoiceNr);
        fetchData();
        fetchvaluedetails(formData.InvoiceNr);
        setArticlesName("");
    }


    const filteredData = data.filter((item) => {
        return (
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 302 } });
            setSupplier(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchSuppliersDetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByID', { headers, params: { id: formData.AccountID } });
            setSupplierDetails(response.data);
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchArticlesSalesPrice = async (articleID, form) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetSalesPrice', { headers, params: { articleID: articleID } });
            const salesPriceData = response.data;

            if (form == "edit") {
                setVATRates(response.data.VATRates);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: response.data.PurchasePrice,
                    QtyInput: 1,
                    Total: 1 * response.data.PurchasePrice

                }));
            }
            else if (form == "getVat") {
                setVATRates(response.data.VATRates);
            }
            else {
                setInsertPriceWithoutVAT(salesPriceData.PurchasePrice);
                setVATRates(response.data.VATRates);
                setQtyInput(1);
            }
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredArticles(
            articles.filter((data) =>
                data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, articles]);


    const handleInput = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setSearchTerm(inputValue);
    };

    const [articlesName, setArticlesName] = useState("");
    const handleFilterArticles = (selectedOption) => {
        if (selectedOption && selectedOption.ArticleID) {
            setArticleID(selectedOption.ArticleID);
            setArticlesName(selectedOption.label);
            fetchArticlesSalesPrice(selectedOption.ArticleID);
        } else {
            setArticleID("");
            setArticlesName("");
        }
    };
    const handleCalculateImportAmount = async (type) => {
        if (type === "edit") {
            if (formDetailData && formDetailData.QtyInput && formDetailData.PriceWithoutVAT) {
                let total = formDetailData.QtyInput * formDetailData.PriceWithoutVAT;
                let transportOneLine = (total / dudDetails.ImportAmountPrelim) * dudDetails.TransportPrelim;

                setFormDetailData(prevState => ({
                    ...prevState,
                    ImportAmount: total,
                    Transport: transportOneLine
                }));
            }
        } else {
            if (qtyinput && insertpriceWithoutVAT) {
                let total = qtyinput * insertpriceWithoutVAT;
                let transportOneLine = (total / dudDetails.ImportAmountPrelim) * dudDetails.TransportPrelim;

                setImportAmount(total);
                setTransport(transportOneLine);
            }
        }
    };

    useEffect(() => {
        let customsCalcTotal = (Number(importAmount) + Number(transport) + Number(customsReassessment))
        setCustomsCalc(customsCalcTotal);
    }, [importAmount, transport, customsReassessment])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'ArticleID':
                    nextField = document.getElementById('QtyInput');
                    break;
                case 'QtyInput':
                    nextField = document.getElementById('PriceWithoutVAT');
                    break;
                case 'PriceWithoutVAT':
                    nextField = document.getElementById('CustomsReassessment');
                    break;
                case 'Transport':
                    nextField = document.getElementById('CustomsReassessment');
                    break;
                case 'CustomsReassessment':
                    nextField = document.getElementById('CustomsValue');
                    break;
                case 'CustomsValue':
                    nextField = document.getElementById('ExciseTax');
                    break;
                case 'ExciseTax':
                    if (showEditForm === true && showInserForm === false) {
                        handleSubmitDetailLine(e);
                    } else if (showInserForm === true && showEditForm === false) {
                        handleNewSubmit(e);
                    }
                    nextField = document.getElementById('ArticleID');
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select(); // Shtoni këtë rresht për të selektuar tekstin
            }
        }
    };

    const handleKeyPressForTotals = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'numriDud':
                    nextField = document.getElementById('dudDate');
                    break;
                case 'dudDate':
                    nextField = document.getElementById('InvoiceTotal');
                    break;
                case 'invoiceTotal':
                    nextField = document.getElementById('InvoiceTransport');
                    break;
                case 'InvoiceTransport':
                    handleSubmitDudDetails(e);
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select();
            }
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        inv: item.InvoiceNr,
        date: (item.Date.substring(0, 10)),
        client: item.AccountName,
        amount: (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
        vat: ((Number(item.VAT18) + Number(item.VAT8)).toFixed(2)),
        total: item.Total,
        deleteItemId: item.TransactionID,
        rowInfo: item
    }));

    const detailsRow = detailLine.map((item, index) => ({
        id: ++index,
        article: item.ArticleName,
        qty: item.QtyInput,
        price: item.PriceWithoutVAT,
        imAmount: item.ImportAmount,
        transport: item.Transport,
        rivlersim: item.CustomsReassessment,
        bazaDoganore: item.CustomsCalc,
        vleraDoganes: item.CustomsValue,
        akciza: item.ExciseTax,
        amount: item.Amount,
        vatAmount: item.VAT,
        total: item.Total,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));

    //Select list for suppliers
    const handleAutocompleteChangeSuppliers = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    const defaultPropsSuppliers = {
        options: supplier,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                </li>
            </>
        ),
    };

    ////Select list tek Artikulli per editim
    const handleAutocompleteChangeArticleEdit = (event, value) => {
        if (value) {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: value.ArticleID
            }));
            fetchArticlesSalesPrice(value.ArticleID, "edit");
        } else {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: ""
            }));
        }
    };

    const defaultPropsArticleEdit = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };

    //Pjesa per select input field Article
    const [field, setField] = useState(null);
    const handleAutocompleteChangeArticle = (event, value) => {
        if (value && value.ArticleID) {
            setArticleID(value.ArticleID);
            fetchArticlesSalesPrice(value.ArticleID);
            setField(value);
        } else {
            // Në rast se vlera është pastruar
            setArticleID(""); // Vendos vlerën e ArticleID në null
            setField(null);
        }
    };

    const defaultPropsArticle = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                         onClick={(e) => {
                             e.stopPropagation();
                             // handleButtonClick(option);
                         }}
                         style={{ marginLeft: 'auto' }}
                     >
                         click me
                     </button> */}
                </li>
            </>
        ),
    };


    useEffect(() => {
        fetchData();
        fetchSuppliersDetails();
        fetchArticles();
        fetchCompaniesData();
        fetchPaymentType();
        fetchSuppliers();
    }, [logedEmail, formData.AccountID, formData.InvoiceNr]);

    return (
        // <div className="Crud__Container">
        <div className="AccountingSheet__Container" style={{ paddingTop: "50px" }}>
            {showEditModal && (
                <div className="SalesDetail__Container">
                    <div className="header_flex">
                        <div className="header_icon">
                            <MdEdit style={{ color: '#466F86', width: "40px" }} />
                            <h3>Ndryshimi i importit</h3>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="SalesDetail__Container__AllButtons">
                        <div style={{ display: "flex", }}>
                            <div>
                                <InsertSupplier fetchData={fetchSuppliers} type="insert" />
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <InsertArticle fetchData={fetchArticles} type="insert" />
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "5px" }}>
                                <Link to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button className="button-15" variant="contained" onClick={handleSubmit}>Ndrysho</button>

                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="/purchases/import"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    {/* <button className="button-15" variant="contained">
                                    <FaTimes /> Close
                                </button> */}
                                    <button className="button-15" variant="contained"
                                        onClick={() => handleCancelAll()}
                                    >
                                        Mbyll
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='header_paper2'></div>
                    <div className="edit">
                        <Paper style={{ padding: "20px" }}>
                            <form className='header_paper'>
                                <Box
                                    // component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className='SalesDetail__Container__Form'>
                                        <div className="SalesDetail__Container__Form__div">
                                            <div className="SalesDetail__Container__Form__div__1">
                                                <div className="SalesDetail__Container__Form__div__2">
                                                    <TextField
                                                        className="SalesDetail__Container__Form__TextField__1"
                                                        id="invoiceNR"
                                                        label="Numri i Fatures"
                                                        variant="outlined"
                                                        type="text"
                                                        name='InvoiceNr'
                                                        value={formData.InvoiceNr}
                                                        onChange={handleInputChange}
                                                    />
                                                    <TextField
                                                        className="SalesDetail__Container__Form__TextField__1"
                                                        id="id"
                                                        select
                                                        name="TransactionType"
                                                        label="Tipi i Transaksionit"
                                                        value={formData.TransactionType}
                                                        onChange={handleInputChange}
                                                    >
                                                        {TranscationData.map((data) => (
                                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <TextareaAutosize style={{ height: "70px", marginLeft: "10px" }}
                                                        className="SalesDetail__Container__Form__TextField__1"
                                                        id="Coment"
                                                        label="Komenti"
                                                        type="text"
                                                        name='Coment'
                                                        value={formData.Coment}
                                                        onChange={handleInputChange}
                                                        variant="outlined"

                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Form__TextField__date__Payment">
                                                    <TextField
                                                        className="SalesDetail__Container__Form__TextField__date"
                                                        id="date"
                                                        type="date"
                                                        variant="outlined"
                                                        label="Data Fatures"
                                                        name='Date'
                                                        value={formData.Date.substring(0, 10)}
                                                        onChange={handleInputChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        className="SalesDetail__Container__Form__TextField__Payment"
                                                        id="AccountiD"
                                                        select
                                                        label="Mënyra Pagesës"
                                                        name="PaymentOptions"
                                                        value={formData.PaymentOptions}
                                                        onChange={handleInputChange}
                                                    >
                                                        <MenuItem key="0" value="0">Pagesa Behet më vonë
                                                        </MenuItem>
                                                        {cashRegister.map((data) => (
                                                            <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    {
                                                        formData.PaymentOptions != 0 ? (
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__date"
                                                                id="PayDate"
                                                                type="date"
                                                                variant="outlined"
                                                                label="Data pagesës"
                                                                name='PayDate'
                                                                value={formData.PayDate ? formData.PayDate.substring(0, 10) : ''}
                                                                onChange={handleInputChange}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )
                                                            :
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__date"
                                                                id="PayDate"
                                                                type="date"
                                                                variant="outlined"
                                                                label="Afati Pagesës"
                                                                name='PayDate'
                                                                value={formData.PayDate ? formData.PayDate.substring(0, 10) : ''}
                                                                onChange={handleInputChange}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="SalesDetail__Container__Form__div__4">
                                            <div>
                                                {/* <TextField className="SalesDetail__Container__Form__TextField__2"
                                                    // style={{ width: "96%" }}
                                                    id="AccountID"
                                                    name="AccountID"
                                                    select
                                                    label="Furnitori"
                                                    value={formData.AccountID}
                                                    onChange={handleInputChange}
                                                >
                                                    {supplier.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    fullWidth
                                                    // sx={{ m: 2 }}
                                                    {...defaultPropsSuppliers}
                                                    id="AccountID"
                                                    includeInputInList
                                                    onChange={handleAutocompleteChangeSuppliers}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            label="Furnitori"
                                                            // variant="filled"
                                                            className="SalesDetail__Container__Form__TextField__2"
                                                        />
                                                    )}
                                                    value={supplier.find(option => option.AccountID === formData.AccountID) || null}
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__IDNumber"
                                                    // style={{ width: "49%" }}
                                                    id="outlined-basic"
                                                    label="Numri Identifikues"
                                                    variant="outlined"
                                                    name="IdentificationNumber"
                                                    value={supplierdetails.length > 0 ? supplierdetails[0].IdentificationNumber : ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    readOnly
                                                    autoComplete="off"
                                                />
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__IDNumber"
                                                    // style={{ width: "49%" }}
                                                    id="outlined-basic"
                                                    label="Numri i TVSH-së"
                                                    variant="outlined"
                                                    name="VatNumber"
                                                    value={supplierdetails.length > 0 ? supplierdetails[0].VATNumber : ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    readOnly
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__2"
                                                    // style={{ width: "96%" }}
                                                    id="outlined-basic"
                                                    label="Adresa"
                                                    variant="outlined"
                                                    name="Address"
                                                    value={supplierdetails.length > 0 ? supplierdetails[0].Adress : ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    readOnly
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </form>
                            <div className="SalesDetail__Container" onKeyPress={handleKeyPressForTotals}>
                                <form>
                                    <div style={{ backgroundColor: "gainsboro" }}>
                                        <Box
                                            sx={{
                                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <Autocomplete
                                                            fullWidth
                                                            sx={{ m: 2 }}
                                                            {...defaultProps}
                                                            id="include-input-in-list"
                                                            includeInputInList
                                                            onChange={handleAutocompleteChange}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Pagesa" variant="standard" />
                                                            )}
                                                            value={cashRegister.find(option => option.AccountID === dudDetails.AccountIDSpare) || null}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                            <InputLabel htmlFor="ImpInvoiceNr">Numri i DUD-it</InputLabel>
                                                            <FilledInput
                                                                id="ImpInvoiceNr"
                                                                name="ImpInvoiceNr"
                                                                value={dudDetails.ImpInvoiceNr}
                                                                onChange={handleInputChangeDudDetials}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                            <InputLabel htmlFor="ImpInvoiceDate" shrink>Data e DUD-it</InputLabel>
                                                            <FilledInput
                                                                id="ImpInvoiceDate"
                                                                name="ImpInvoiceDate"
                                                                value={dudDetails.ImpInvoiceDate.substring(0, 10)}
                                                                onChange={handleInputChangeDudDetials}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                            <InputLabel htmlFor="InvoiceTotal">Vlera totale e faturës</InputLabel>
                                                            <FilledInput
                                                                id="invoiceTotal"
                                                                value={dudDetails.ImportAmountPrelim !== null && dudDetails.ImportAmountPrelim !== 0 ? dudDetails.ImportAmountPrelim.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                                readonly
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                            <InputLabel htmlFor="InvoiceTransport">Transporti</InputLabel>
                                                            <FilledInput
                                                                id="InvoiceTransport"
                                                                value={dudDetails.TransportPrelim !== null && dudDetails.TransportPrelim !== 0 ? dudDetails.TransportPrelim.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                                readonly
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    {/* <div className="SalesDetail__Container__Second__Form__details"> */}
                                                    <Mui_Button
                                                        variant="contained"
                                                        color="success"
                                                        type="submit"
                                                        onClick={handleSubmitDudDetails}
                                                        sx={{ m: 2 }}

                                                    >
                                                        Ndrysho
                                                    </Mui_Button>

                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                </form>
                            </div>
                            <div style={{ backgroundColor: "gainsboro " }}>
                                <hr></hr>
                                {showInserForm && (
                                    <div className="SalesDetail__Container">
                                        <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                {/* <p style={{ color: "blue", fontSize: "16px", fontWeight: "bold", borderTop: "1px dashed blue" }}>Please fill in all the required fields.</p> */}
                                                <div className="SalesDetail__Container__Second__Form">
                                                    <div className="SalesDetail__Container__Second__Form__Article">
                                                        {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Artikullin</span>
                                                        <Select
                                                            className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                            inputId="ArticleID"
                                                            value={{ label: articlesName, value: articleID }}
                                                            options={filteredArticles.map((data) => ({
                                                                label: data.ArticleName,
                                                                value: data.ArticleID,
                                                                ArticleID: data.ArticleID
                                                            }))}
                                                            onInputChange={handleInput}
                                                            onChange={handleFilterArticles}
                                                            isClearable
                                                            placeholder="Select"
                                                            noOptionsMessage={() => 'No matching'}
                                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    height: "55px",
                                                                    marginLeft: "10px",
                                                                    marginRight: "12px",
                                                                    backgroundColor: "transparent",
                                                                    border: "darkgray solid 1px"
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: "#fff",
                                                                    zIndex: 9999,
                                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                    borderRadius: "4px",
                                                                }),
                                                                menuList: (provided) => ({
                                                                    ...provided,
                                                                    maxHeight: "200px",
                                                                    overflowY: "auto",
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: "#000",
                                                                }),
                                                            }}
                                                        /> */}
                                                        <Autocomplete
                                                            fullWidth
                                                            {...defaultPropsArticle}
                                                            id="ArticleID"
                                                            includeInputInList
                                                            value={field}
                                                            sx={{ m: 1.5 }}
                                                            onChange={handleAutocompleteChangeArticle}
                                                            isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Kerko Artikullin"
                                                                // variant="filled"
                                                                // onKeyPress={handleKeyPress}
                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                style: {
                                                                    maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="QtyInput"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Sasia Hyrje"
                                                                name='QtyInput'
                                                                value={qtyinput}
                                                                onChange={(e) => setQtyInput(e.target.value)}
                                                                onKeyUp={handleCalculateImportAmount}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithoutVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Çmimi"
                                                                name="PriceWithoutVAT"
                                                                value={insertpriceWithoutVAT !== null && insertpriceWithoutVAT !== 0 ? insertpriceWithoutVAT.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                // value={typeof priceWithoutVAT === 'number' ? priceWithoutVAT.toFixed(4) : priceWithoutVAT}
                                                                onKeyUp={handleCalculateImportAmount}
                                                                onChange={(e) => setInsertPriceWithoutVAT(e.target.value)}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="ImportAmount"
                                                                label="Vlera Importit"
                                                                variant="outlined"
                                                                name='ImportAmount'
                                                                // value={importAmount}
                                                                value={importAmount !== null && importAmount !== 0 ? importAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                onChange={event => setImportAmount(event.target.value)}
                                                                // onKeyUp={handleCalculatePrices}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="Transport"
                                                                label="Vlera Transportit"
                                                                variant="outlined"
                                                                name='Transport'
                                                                // value={transport}
                                                                value={transport !== null && transport !== 0 ? transport.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                onChange={event => setTransport(event.target.value)}
                                                                // onKeyUp={handleCalculatePrices}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="CustomsReassessment"
                                                                label="Rivlersimi"
                                                                variant="outlined"
                                                                name='CustomsReassessment'
                                                                // value={customsReassessment}
                                                                value={customsReassessment !== null && customsReassessment !== 0 ? customsReassessment.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                onChange={event => setCustomsReassessment(event.target.value)}
                                                                // onKeyUp={handleCalculatePrices}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="CustomsValue"
                                                                label="Dogana Ne Vlerë"
                                                                variant="outlined"
                                                                name='CustomsValue'
                                                                // value={customsValue}
                                                                value={customsValue !== null && customsValue !== 0 ? customsValue.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                onChange={event => setCustomsValue(event.target.value)}
                                                                // onKeyUp={handleCalculatePrices}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="ExciseTax"
                                                                label="Akciza Në Vlerë"
                                                                variant="outlined"
                                                                name='ExciseTax'
                                                                // value={exciseTax}
                                                                value={exciseTax !== null && exciseTax !== 0 ? exciseTax.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                                onChange={event => setExciseTax(event.target.value)}
                                                                // onKeyUp={handleCalculatePrices}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        <div className="responsive">
                                                            <div className="responsive__butonat">
                                                                <div>
                                                                    <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-plus-lg"></i>
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-x-lg"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </form>
                                    </div>
                                )}
                                {showEditForm && (
                                    <div className="SalesDetail__Container">
                                        <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="SalesDetail__Container__Second__Form">
                                                    <div className="SalesDetail__Container__Second__Form__Article">
                                                        {/* <TextField
                                                            className="SalesDetail__Container__Form__TextField__Article"
                                                            id="ArticleID"
                                                            select
                                                            label="Select Article"
                                                            name="ArticleID"
                                                            value={formDetailData.ArticleID}
                                                            autoComplete="off"
                                                            // onChange={handleInputChangeDetail}
                                                            onChange={(event) => {
                                                                handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
                                                                fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
                                                            }}
                                                        // onKeyUp={handleCalculatePriceWithoutVATEdit}
                                                        >
                                                            {articles.map((data) => (
                                                                <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField> */}
                                                        <Autocomplete
                                                            fullWidth
                                                            sx={{ m: 1.5 }}
                                                            {...defaultPropsArticleEdit}
                                                            id="ArticleID"
                                                            includeInputInList
                                                            onChange={handleAutocompleteChangeArticleEdit}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    label="Artikulli"
                                                                // variant="filled" 
                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                style: {
                                                                    maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                                },
                                                            }}
                                                            value={articles.find(option => option.ArticleID === formDetailData.ArticleID) || null}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                type="text"
                                                                id="QtyInput"
                                                                name="QtyInput"
                                                                label="Qty Input"
                                                                variant="outlined"
                                                                autoComplete="off"
                                                                value={formDetailData.QtyInput}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculateImportAmount("edit")}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithoutVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Price Without VAT"
                                                                name="PriceWithoutVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculateImportAmount("edit")}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="ImportAmount"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Vlera Importit"
                                                                name="ImportAmount"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.ImportAmount === 'number' ? formDetailData.ImportAmount.toFixed(4) : formDetailData.ImportAmount}
                                                                onChange={handleInputChangeDetail}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="Transport"
                                                                label="Vlera Transportit"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="Transport"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.Transport === 'number' ? formDetailData.Transport.toFixed(2) : formDetailData.Transport}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="CustomsReassessment"
                                                                label="Rivlersimi"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="CustomsReassessment"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.CustomsReassessment === 'number' ? formDetailData.CustomsReassessment.toFixed(2) : formDetailData.CustomsReassessment}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="CustomsValue"
                                                                label="Dogana Ne Vlerë"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="CustomsValue"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.CustomsValue === 'number' ? formDetailData.CustomsValue.toFixed(2) : formDetailData.CustomsValue}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="ExciseTax"
                                                                label="Akciza Në Vlerë"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="ExciseTax"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.ExciseTax === 'number' ? formDetailData.ExciseTax.toFixed(2) : formDetailData.ExciseTax}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-check-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        <div className="responsive">
                                                            <div className="responsive__butonat">
                                                                <div>
                                                                    <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-check-lg"></i>
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-x-lg"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </form>
                                    </div>
                                )}
                                <DataGridWithActions
                                    columns={importGridHeader}
                                    rows={detailsRow}
                                    handleEdit={handleEditDetails}
                                    handleDelete={handleDeleteDetailLine}
                                    showPayment={false}
                                />
                                <hr></hr>
                            </div>
                            <br></br>
                            <div className="responsive__Tablet">
                                <div className='SalesDetail__Container__Form'>
                                    <div style={{ marginLeft: "20px" }}>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="Amount18">Vlera 18%</InputLabel>
                                            <FilledInput
                                                id="Amount18"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount18 !== undefined && valuedetails[0].Amount18 !== 0
                                                    ? valuedetails[0].Amount18.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT18">TVSH 18%</InputLabel>
                                            <FilledInput
                                                id="VAT18"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].VAT18 !== undefined && valuedetails[0].VAT18 !== 0
                                                    ? valuedetails[0].VAT18.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="Amount8">Vlera 8%</InputLabel>
                                            <FilledInput
                                                id="Amount8"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount8 !== undefined && valuedetails[0].Amount8 !== 0
                                                    ? valuedetails[0].Amount8.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT8">TVSH 8%</InputLabel>
                                            <FilledInput
                                                id="VAT8"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].VAT8 !== undefined && valuedetails[0].VAT8 !== 0
                                                    ? valuedetails[0].VAT8.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT0">Vlera Faturës</InputLabel>
                                            <FilledInput
                                                id="VAT0"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].ImportAmount !== undefined && valuedetails[0].ImportAmount !== 0
                                                    ? valuedetails[0].ImportAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="Amount0">Vlera 0%</InputLabel>
                                            <FilledInput
                                                id="Amount0"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount0 !== undefined && valuedetails[0].Amount0 !== 0
                                                    ? valuedetails[0].Amount0.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT0">TVSH 0%</InputLabel>
                                            <FilledInput
                                                id="VAT0"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].VAT0 !== undefined && valuedetails[0].VAT0 !== 0
                                                    ? valuedetails[0].VAT0.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="transporti">Transporti</InputLabel>
                                            <FilledInput
                                                id="transporti"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Transport !== undefined && valuedetails[0].Transport !== 0
                                                    ? valuedetails[0].Transport.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                    <br></br>
                                    <div>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="CustomsValue">Vlera Doganës</InputLabel>
                                            <FilledInput
                                                id="CustomsValue"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].CustomsValue !== undefined && valuedetails[0].CustomsValue !== 0
                                                    ? valuedetails[0].CustomsValue.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="ExciseTax">Akciza</InputLabel>
                                            <FilledInput
                                                id="ExciseTax"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].ExciseTax !== undefined && valuedetails[0].ExciseTax !== 0
                                                    ? valuedetails[0].ExciseTax.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="CustomsReassessment">Rivlersimi</InputLabel>
                                            <FilledInput
                                                id="CustomsReassessment"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].CustomsReassessment !== undefined && valuedetails[0].CustomsReassessment !== 0
                                                    ? valuedetails[0].CustomsReassessment.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>

                                    <br></br>
                                    <div>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled" focused>
                                            <InputLabel htmlFor="TotalAmount">Total pa TVSH</InputLabel>
                                            <FilledInput
                                                id="TotalAmount"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount18 !== undefined && valuedetails[0].Amount8 !== undefined && valuedetails[0].Amount0 !== undefined
                                                    ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled" focused>
                                            <InputLabel htmlFor="VATTotal">Total TVSH</InputLabel>
                                            <FilledInput
                                                id="VATTotal"
                                                value={
                                                    valuedetails && valuedetails.length > 0 &&
                                                        valuedetails[0].Total !== undefined &&
                                                        valuedetails[0].Amount18 !== undefined &&
                                                        valuedetails[0].Amount8 !== undefined &&
                                                        valuedetails[0].Amount0 !== undefined
                                                        ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                        : '0.00'
                                                }
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled" focused>
                                            <InputLabel htmlFor="Totali">Totali</InputLabel>
                                            <FilledInput
                                                id="Totali"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Total !== null && valuedetails[0].Total !== 0
                                                    ? valuedetails[0].Total.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div >
            )}
            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillShopping style={{}} className="header_icons" />
                            <h3>Importi</h3>
                        </div>
                        <div>
                            <Link to={"/purchases/addImport"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Krijo import
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e importeve: Menaxhoni, shtoni, editoni dhe fshini importet.</p>
                    </div>
                    <DataGridWithActions
                        columns={PurchaseGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={true}
                        height={500}
                    />
                </div>
            )}
        </div >

    )

}