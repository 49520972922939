import jwtDecode from "jwt-decode";

const tokenKey = 'token';
const expirationKey = 'token-expiration';

//Sherben per ruajtjen e tokenit ne localstorage 
export function saveToken(authData) {
  console.log(jwtDecode(authData.Token));

  localStorage.setItem(tokenKey, authData.Token);
  localStorage.setItem(expirationKey, authData.Expiration);
}

export function getClaims() {
  const token = localStorage.getItem(tokenKey);

  if (!token) {
    return [];
  }

  const expiration = localStorage.getItem(expirationKey);
  const expirationDate = new Date(expiration);

  if (expirationDate <= new Date()) {
    // logout();
    return [];
  }

  const dataToken = JSON.parse(atob(token.split('.')[1]));
  const response = [];
  for (const property in dataToken) {
    response.push({ name: property, value: dataToken[property] });
  }

  return response;
}
export function getToken() {
  return localStorage.getItem(tokenKey);
  // console.log(localStorage.getItem(tokenKey));
}


