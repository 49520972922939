import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import InsertData from "./InsertCompanies";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiFillEdit, AiOutlineDelete, AiOutlineEdit, AiOutlinePrinter, AiOutlineFilePdf } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { MdAddBusiness } from "react-icons/md";

export default function Companies() {

    const headers = authHeader();
    const loginEmail = LoginUserDetailed();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        CompanyID: "",
        CompanyName: "",
        IdentificationNumber: "",
        VATNumber: "",
        Address: "",
        CityID: "",
        StateID: "",
        PhoneNumbers: "",
        EdiPass: "",
        ARBKPass: "",
        MailPass: "",
        SMTPClient: "",
        Email: "",
        Comment: "",
        ModifiedByUser: "",
        VATDeclarant: "",
        FiscalYear: "",
        IsActive: "",
        CompanyLogo: "anonymous.png",
        LogoPath: ApiLink.PHOTO_URL
    })

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [city, setCity] = useState([]);
    const [state, setState] = useState([]);
    let number = 1;
    const [role, setRole] = useState("");


    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies', { headers });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    //Kodi per shfaqjen e tabeles ne PDF
    const handleExportPDF = () => {
        const doc = new jsPDF();
        // Teksti i parë në fillim të dokumentit
        const text1 = "AccounTree";
        doc.text(text1, 10, 10); // 20 është pozicioni X, 10 është pozicioni Y

        // Teksti i dytë në fillim të dokumentit
        const text2 = "Lista e Kompanive";
        doc.text(text2, 10, 20); // 20 është pozicioni X, 20 është pozicioni Y

        // Teksti i tretë në fillim të dokumentit
        // const text3 = "Accounting is the process of recording financial transactions pertaining to a business.";
        // doc.text(text3, 10, 30); // 20 është pozicioni X, 30 është pozicioni Y
        // const paragraph = "Ky është një paragraf në fillim të tabelës.";
        // doc.text(paragraph, 20, 40); // 20 është pozicioni X, 40 është pozicioni Y
        doc.autoTable({
            head: [["Company Name", "Identification Number", "Vat Number", "Adress", "Fiscal Year"]],
            body: filteredData.map((item) => [item.CompanyName, item.IdentificationNumber, item.VATNumber, item.Address, item.FiscalYear]),
            startY: 30
        });
        doc.save("Companies-List.pdf");
    };
    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    //Kodi per pegination ne tabel
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    const handleDelete = async () => {
        try {
            const headers = authHeader();
            const response = await axios.delete(
                ApiLink.API_URL + 'Companies' + `/${deleteItemId}`, { headers }
            );
            setShowDeleteConfirmation(false);
            deleteAlert("Company Deleted Successfully");
            fetchData();
        } catch (error) {
            deleteAlert("Company can not be deleted");
        }
    };

    //Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        setFormData({
            CompanyID: item.CompanyID,
            CompanyName: item.CompanyName,
            IdentificationNumber: item.IdentificationNumber,
            VATNumber: item.VATNumber,
            Address: item.Address,
            CityID: item.CityID,
            StateID: item.StateID,
            PhoneNumbers: item.PhoneNumbers,
            EdiPass: item.EdiPass,
            ARBKPass: item.ARBKPass,
            MailPass: item.MailPass,
            SMTPClient: item.SMTPClient,
            Email: item.Email,
            Comment: item.Comment,
            ModifiedByUser: loginEmail,
            VATDeclarant: item.VATDeclarant,
            FiscalYear: item.FiscalYear,
            IsActive: item.IsActive,
            CompanyLogo: item.CompanyLogo
        });
        setShowEditModal(true);
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        const headers = authHeader();
        axios
            .put(ApiLink.API_URL + 'Companies', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("company updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update company")
            });
    };

    const imageUpload = (e) => {
        e.preventDefault();

        // Kontrollo nëse files janë të definuara dhe ka një element në pozitën '0'
        if (e.target.files && e.target.files.length > 0) {
            const fileData = new FormData();
            fileData.append("file", e.target.files[0], e.target.files[0].name);

            axios.post(ApiLink.API_URL + 'Companies/SaveFile', fileData, { headers })
                .then((response) => {
                    setFormData({
                        ...formData,
                        CompanyLogo: response.data,
                    });
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const fetchRole = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AuthProva/getrole', { headers });

            if (response.data.UserRole && response.data.UserRole !== undefined) {
                setRole(response.data.UserRole);
            } else {
                errorAlert("Undefined Role.");
            }
        } catch (error) {
            console.error("Error fetching role:", error);
            // ... (pjesa tjetër e kodit)
        }
    };


    const filteredData = data.filter((item) => {
        return (
            item.CompanyName.toLowerCase().includes(searchTerm.toLowerCase())


        );
    });

    const fetchCityByState = async (stateID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Cities/getcitybystate', { headers, params: { stateid: stateID } });
            setCity(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    useEffect(() => {
        const headers = authHeader();
        axios.get(ApiLink.API_URL + 'states', { headers })
            .then((response) => {
                setState(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        fetchData();
        fetchRole();
    }, [])

    return (
        <div>
            {/* Modal per konfirmin nese deshiron me fshi nje element */}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete Company?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div className="edit-modal" style={{padding:"50px"}}>
                    <form onSubmit={handleSubmit}>
                        <h3>Ndryshimi i Kompanisë</h3>

                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5"># </span>
                            <input
                                className="form-control"
                                type="number"
                                id="CompanyID"
                                name="CompanyID"
                                value={formData.CompanyID}
                                onChange={handleInputChange}
                                disabled
                                autoComplete="off"
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Emri i Kompanisë</span>
                            <input
                                className="form-control"
                                type="text"
                                id="CompanyName"
                                name="CompanyName"
                                value={formData.CompanyName}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Numri Identifikues</span>
                            <input
                                className="form-control"
                                type="number"
                                id="IdentificationNumber"
                                name="IdentificationNumber"
                                value={formData.IdentificationNumber}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Numri i TVSH-së</span>
                            <input
                                className="form-control"
                                type="number"
                                id="VATNumber"
                                name="VATNumber"
                                value={formData.VATNumber}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Adresa</span>
                            <input
                                className="form-control"
                                type="text"
                                id="Address"
                                name="Address"
                                value={formData.Address}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="input-group mb-3">
                            <label className="input-group-text col-5 col-sm-5" htmlFor="stateSelect">Shteti </label>
                            <select
                                id="StateID"
                                name="StateID"
                                className="form-control"
                                value={formData.StateID}
                                onChange={(e) => { handleInputChange(e); fetchCityByState(e.target.value); }}
                            >
                                <option value="">-- Select State --</option>
                                {state.map((state) => (
                                    <option key={state.StateID} value={state.StateID}>
                                        {state.StateName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <label className="input-group-text col-5 col-sm-5" htmlFor="stateSelect">Qyteti </label>
                            <select
                                id="CityID"
                                name="CityID"
                                className="form-control"
                                value={formData.CityID}
                                onChange={handleInputChange}
                            >
                                <option value="">-- Select City --</option>
                                {city.map((cities) => (
                                    <option key={cities.CityID} value={cities.CityID}>
                                        {cities.CityName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Numri i Telefonit</span>
                            <input
                                className="form-control"
                                type="text"
                                id="PhoneNumbers"
                                name="PhoneNumbers"
                                value={formData.PhoneNumbers}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Fjalkalimi i sistemit të ATK-së</span>
                            <input
                                className="form-control"
                                type="text"
                                id="EdiPass"
                                name="EdiPass"
                                value={formData.EdiPass}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Fjalkalimi i sistemit të ARBK-së</span>
                            <input
                                className="form-control"
                                type="text"
                                id="ARBKPass"
                                name="ARBKPass"
                                value={formData.ARBKPass}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Email</span>
                            <input
                                className="form-control"
                                type="text"
                                id="Email"
                                name="Email"
                                value={formData.Email}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">SMTP Client</span>
                            <input
                                className="form-control"
                                type="text"
                                id="SMTPClient"
                                name="SMTPClient"
                                value={formData.SMTPClient}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Fjalkalimi i Email-it</span>
                            <input
                                className="form-control"
                                type="password"
                                id="MailPass"
                                name="MailPass"
                                value={formData.MailPass}
                                onChange={handleInputChange}
                                autoComplete="off"

                            />
                        </div>
                        <div className="p-2 w-50 bd-highlight">
                            <img style={{ width: "50px", height: "50px" }}
                                src={ApiLink.PHOTO_URL + formData.CompanyLogo} />
                            <input className="m-2" type="file" onChange={imageUpload} />
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Komenti</span>
                            <textarea
                                className="form-control"
                                id="Comment"
                                name="Comment"
                                value={formData.Comment}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="IsActive">Deklarues i TVSH-së</label>
                            <input
                                type="checkbox"
                                id="VATDeclarant"
                                name="VATDeclarant"
                                checked={formData.VATDeclarant}
                                onChange={(event) =>
                                    setFormData({ ...formData, VATDeclarant: event.target.checked })
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="IsActive">Kompani Aktive</label>
                            <input
                                type="checkbox"
                                id="IsActive"
                                name="IsActive"
                                checked={formData.IsActive}
                                onChange={(event) =>
                                    setFormData({ ...formData, IsActive: event.target.checked })
                                }
                            />
                        </div>
                        <button type="submit" className="btn btn-success">Ndrysho</button>

                        <button
                            className="btn btn-secondary"
                            onClick={() => setShowEditModal(false)}
                        >
                            Anulo
                        </button>
                    </form>
                </div>
            )}
            <div className="header_flexx">
                <div className="header_icon">
                    <MdAddBusiness style={{}} className="header_icons" />
                    <h3>Lista e Kompanive</h3>
                </div>
                {
                    role === "acctree" && (
                        <div>
                            <InsertData fetchData={fetchData} />
                        </div>
                    )
                }
            </div>
            <div className="header_paragraph">
                <p>Forma e Kompanisë: Menaxhoni, editoni të dhënat e kompanisë sipas kërkeses.</p>
            </div>

            <div className="bordere">
                <div className="butonss">
                    {/* <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}
                </div>
                <div className="searchboxx">
                    <div className="flexbox">
                        <div class="search">
                            <div>
                                <input
                                    type="text"
                                    placeholder="Search Companies"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}

                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <hr></hr>
            <Paper>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table ref={tableRef} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styles.tableHeader}><strong>#</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Emri i Kompanisë</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Numri Identifikues</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Numri i TVSH-së</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Adresa</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Viti Fiskal</strong></TableCell>
                                {
                                   ( role === "acctree" || role === "admin") && (
                                        <div>
                                            <TableCell sx={styles.tableHeader}><strong>Veprimet</strong></TableCell>
                                        </div>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <div className="spinner" style={{ marginLeft: "560px" }} ></div>
                        ) : (
                            <TableBody>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.CompanyID}>
                                            <TableCell sx={styles.tableCell}>{number + index}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.CompanyName}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.IdentificationNumber}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.VATNumber}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.Address}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.FiscalYear}</TableCell>
                                            {
                                                role === "acctree" && (
                                                    <div>
                                                        <TableCell sx={styles.tableCell}>
                                                            <Tooltip title="Edit" placement="left">
                                                                <IconButton>
                                                                    <AiFillEdit style={{ color: '#466F86' }} onClick={() => { handleEdit(item); fetchCityByState(item.StateID); }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" placement="right">
                                                                <IconButton>
                                                                    <DeleteIcon style={{ color: '#962a38' }}
                                                                        onClick={() => {
                                                                            setShowDeleteConfirmation(true);
                                                                            setDeleteItemId(item.CompanyID);
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </div>
                                                )
                                            }

                                            {
                                                role === "admin" && (
                                                    <div>
                                                        <TableCell sx={styles.tableCell}>
                                                            <Tooltip title="Edit" placement="left">
                                                                <IconButton>
                                                                    <AiFillEdit style={{ color: '#466F86' }} onClick={() => { handleEdit(item); fetchCityByState(item.StateID); }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </div>
                                                )
                                            }
                                        </TableRow>
                                    ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}
