import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/__button.scss'
import AddBoxIcon from '@mui/icons-material/AddBox';
import '../../Style/InsertClient.scss';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import { TextareaAutosize } from '@mui/material';
import { Button } from "@mui/material";
import Select from 'react-select';

function InsertSuppliers(props) {
    const headers = authHeader();
    const email = LoginUserDetailed();
    const [data, setData] = useState([]);
    const [findIdentificationNr, setFindIdentificationNr] = useState("");
    const [idNumberExist, setIDNumberExist] = useState(false)

    const [AccName, setAccName] = useState("");
    // const [ledgerID, setLedgerID] = useState("");
    const [IdNumber, setIdNumber] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [adress, setAdress] = useState("");
    const [cityID, setCityID] = useState("");
    const [bankID, setBankID] = useState();
    const [bankAccount, setBankAccount] = useState("");
    const [accEmail, setAccEmail] = useState("");
    const [comment, setComment] = useState("");
    const [phone, setPhone] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [stateid, setStateID] = useState("");
    const [ledger, setLedger] = useState([]);

    const [states, setStates] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);

    const [city, setCity] = useState([]);
    const [filteredCity, setFilteredCity] = useState([]);

    const [bank, setBank] = useState([]);
    const [filteredBank, setFilteredBank] = useState([]);

    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [idNumberEror, setIdNumberError] = useState("");
    const [vatError, setVatError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        if (IdNumber == null || IdNumber == "") {
            setIdNumberError("Fill identification number field");
            return;
        } else {
            if (IdNumber.length > 15) {
                setIdNumberError("Identification number must be between 0-15 numbers");
                return;
            }
            else {
                setIdNumberError("");
            }
        }
        if (idNumberExist) {
            setIdNumberError("Klienti me kete numer identifikues ekziston");
            return;
        } else {
            setIdNumberError("");
        }
        if (vatNumber.length > 15) {
            setVatError("VAT number must be between 0-15 numbers");
            return;
        }
        else {
            setVatError("");
        }
        if (stateid == null || stateid == "") {
            setStateError("Fill state field");
            return;
        } else {
            setStateError("");
        }
        if (cityID == null || cityID == "") {
            setCityError("Fill city field");
            return;
        } else {
            setCityError("");
        }
        const stateDate = {
            AccountName: AccName,
            LedgerID: 302,
            IdentificationNumber: IdNumber,
            VATNumber: vatNumber,
            Adress: adress,
            CityID: cityID,
            StateID: stateid,
            BankID: bankID,
            BankAccount: bankAccount,
            Email: accEmail,
            Comment: comment,
            PhoneNumbers: phone,
            CreatedByUser: email,
            IsActive: isActive
        };
        axios.post(ApiLink.API_URL + 'Accounts', stateDate, { headers })
            .then((response) => {
                handleSuccess("Furnitori u shtua me sukses");
                setAccName("");
                setIdNumber("");
                setVatNumber("");
                setAdress("");
                setCityID("");
                setStateID("");
                setBankID();
                setBankAccount("");
                setAccEmail("");
                setComment("");
                setPhone("");
                setIsActive(true);
                setStateID("");
                setStateName("");
                setCityName("");
                setBankName("");
                props.onHide();
                props.refreshData();
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                }
            });
    }
    const handleClose = () => {
        // Ky funksion do të përdoret për të mbyllur modalin
        props.onHide();
        setAccName("");
        setIdNumber("");
        setVatNumber("");
        setAdress("");
        setCityID("");
        setStateID("");
        setBankID();
        setBankAccount("");
        setAccEmail("");
        setComment("");
        setPhone("");
        setStateID("");
        setIsActive(true);
        setStateID("")
        setStateName("");
        setCityName("");
        setBankName("");
    };
    useEffect(() => {
        axios.get(ApiLink.API_URL + 'ledger', { headers })
            .then((response) => {
                setLedger(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const headers = authHeader();
        axios.get(ApiLink.API_URL + 'states', { headers })
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const fetchCityByState = async (selectedStateID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Cities/getcitybystate', { headers, params: { stateid: selectedStateID } });
            setCity(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    useEffect(() => {
        axios.get(ApiLink.API_URL + 'LicencedBanks', { headers })
            .then((response) => {
                setBank(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredStates(
            states.filter((data) =>
                data.StateName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, states]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredCity(
            city.filter((data) =>
                data.CityName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, city]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredBank(
            bank.filter((data) =>
                data.BankName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, bank]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    // i run te dhenat per Debit Ledger
    const [stateName, setStateName] = useState("");
    const handleFilterState = (selectedOption) => {
        if (selectedOption && selectedOption.StateID) {
            setStateID(selectedOption.StateID);
            fetchCityByState(selectedOption.StateID);
            setStateName(selectedOption.label);
        } else {
            setStateID("");
            setStateName("");
        }
    };

    const [cityName, setCityName] = useState("");
    const handleFilterCity = (selectedOption) => {
        if (selectedOption && selectedOption.CityID) {
            setCityID(selectedOption.CityID);
            setCityName(selectedOption.label);
        } else {
            setCityID("");
            setCityName("");
        }
    };

    const [bankName, setBankName] = useState("");
    const handleFilterBank = (selectedOption) => {
        if (selectedOption && selectedOption.BankID) {
            setBankID(selectedOption.BankID);
            setBankName(selectedOption.label);
        } else {
            setBankID("");
            setBankName("");
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 302 } });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    // const checkIdNumber = () => {
    //     const filteredData = data.filter((item) => {
    //         return (
    //             (item.IdentificationNumber && item.IdentificationNumber.includes(findIdentificationNr))
    //         );
    //     });

    //     if (filteredData.some(item => item.IdentificationNumber === IdNumber)) {
    //         setIdNumberError("Klienti me kete numer identifikues ekziston");
    //         setIDNumberExist(true);
    //     } else {
    //         setIdNumberError("");
    //         setIDNumberExist(false);

    //     }
    // };

    const checkIdNumber = () => {
        const filteredData = data.filter((item) => {
            // Kontrollo për ekzistencën e numrit identifikues në të dhënat dhe përjashto numrat 1 dhe 8
            return (
                (item.IdentificationNumber && item.IdentificationNumber.includes(findIdentificationNr)) &&
                !["1", "8"].includes(findIdentificationNr)
            );
        });

        if (filteredData.some(item => item.IdentificationNumber === IdNumber)) {
            setIdNumberError("Klienti me kete numer identifikues ekziston");
            setIDNumberExist(true);
        } else {
            setIdNumberError("");
            setIDNumberExist(false);
        }
    };

    useEffect(() => {
        // Këtu mund të thërrisni funksionin që do ekzekutohet në ndryshim të IdNumber
        checkIdNumber();
    }, [IdNumber, findIdentificationNr, data]);

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Krijo Furnitor të ri
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} className="InsertClient__Container">
                    <div className='InsertClient__Container__firstInput' >
                        <TextField className='InsertClient__Container__firstInput__AccName'
                            required
                            id="AccountName"
                            label="Emri i Furnitorit"
                            variant="outlined"
                            type="text"
                            name='AccountName'
                            value={AccName}
                            onChange={event => setAccName(event.target.value)}
                            autoComplete="off"
                        />
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__SecondInputs'>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                id="IdentificationNumber"
                                label="Numri Identifikues"
                                variant="outlined"
                                type="text"
                                name='IdentificationNumber'
                                value={IdNumber}
                                onChange={event => { setIdNumber(event.target.value); setFindIdentificationNr(event.target.value); }}
                                autoComplete="off"
                            />
                            <span style={{ color: "red" }}>{idNumberEror && `${idNumberEror}`}</span>
                        </div>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                id="VATNumber"
                                label="Numri i TVSH-së"
                                variant="outlined"
                                type="text"
                                name='VATNumber'
                                value={vatNumber}
                                onChange={event => setVatNumber(event.target.value)}
                                autoComplete="off"
                            />
                            <span style={{ color: "red" }}>{vatError && `${vatError}`}</span>
                        </div>
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__firstInput' >
                        <TextField className='InsertClient__Container__firstInput__AccName'
                            required
                            id="Adress"
                            label="Adresa"
                            variant="outlined"
                            type="text"
                            name='Adresa'
                            value={adress}
                            onChange={event => setAdress(event.target.value)}
                            autoComplete="off"
                        />
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__SecondInputs'>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Shtetin</span>
                            <Select
                                inputId="StateID"
                                className="Transfers__Container__Format__SameInput"
                                value={{ label: stateName, value: stateid }}
                                options={filteredStates.map((data) => ({
                                    label: data.StateName,
                                    value: data.StateID,
                                    StateID: data.StateID
                                }))}
                                onInputChange={handleInputChange}
                                onChange={handleFilterState}
                                isClearable
                                placeholder="Select"
                                noOptionsMessage={() => 'No matching'}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: "50px"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff",

                                        zIndex: 9999,
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#000",
                                    }),
                                }}
                            />
                            <span style={{ color: "red" }}>{stateError && `${stateError}`}</span>
                        </div>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Qytetin</span>
                            <Select
                                required
                                inputId="CityID"
                                className="Transfers__Container__Format__SameInput"
                                value={{ label: cityName, value: cityID }}
                                options={filteredCity.map((data) => ({
                                    label: data.CityName,
                                    value: data.CityID,
                                    CityID: data.CityID
                                }))}
                                onInputChange={handleInputChange}
                                onChange={handleFilterCity}
                                isClearable
                                placeholder="Select"
                                noOptionsMessage={() => 'No matching'}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: "50px"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff",

                                        zIndex: 9999,
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#000",
                                    }),
                                }}
                            />
                            <span style={{ color: "red" }}>{cityError && `${cityError}`}</span>
                        </div>
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__SecondInputs'>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Bankën</span>
                            <Select
                                inputId="BankID"
                                className="Transfers__Container__Format__SameInput"
                                value={{ label: bankName, value: bankID }}
                                options={filteredBank.map((data) => ({
                                    label: data.BankName,
                                    value: data.BankID,
                                    BankID: data.BankID
                                }))}
                                onInputChange={handleInputChange}
                                onChange={handleFilterBank}
                                isClearable
                                placeholder="Select"
                                noOptionsMessage={() => 'No matching'}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: "55px"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff",

                                        zIndex: 9999,
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#000",
                                    }),
                                }}
                            />
                        </div>

                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Numri i Llogarisë</span>
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                id="BankAccount"
                                // label="Bank Account"
                                variant="outlined"
                                type="text"
                                name='BankAccount'
                                value={bankAccount}
                                onChange={event => setBankAccount(event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__SecondInputs'>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                id="Email"
                                label="Email"
                                variant="outlined"
                                type="text"
                                name='Email'
                                value={accEmail}
                                onChange={event => setAccEmail(event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                id="PhoneNumber"
                                label="Numri i Telefonit"
                                variant="outlined"
                                type="text"
                                name='PhoneNumber'
                                value={phone}
                                onChange={event => setPhone(event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__firstInput' >
                        <TextareaAutosize style={{ height: "100px" }}
                            className="InsertClient__Container__firstInput__AccName"
                            id="Comment"
                            label="Comment"
                            variant="outlined"
                            placeholder='Përshkrimi'
                            type="text"
                            name='Comment'
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                            autoComplete="off"
                        />
                    </div>
                    <div className='InsertClient__Container__butonat'>
                        <div></div>
                        {/* <label className='InsertModal__Container__Form__Container2__checkbox-label'>
                            <input
                                type="checkbox"
                                checked={isActive}
                                onChange={(event) => setIsActive(event.target.checked)}
                                className='InsertModal__Container__Form__Container2__checkbox-input'
                            />
                            <span className='InsertModal__Container__Form__Container2__checkbox-custom'></span>
                            Active
                        </label> */}
                        <div className='InsertClient__Container__butonat__btn'>
                            <div className='InsertModal__Container__Form__Container2__save-button'>
                                <button
                                    type="submit"
                                    className="btn btn-success float-end"
                                    style={{ width: "120px" }}
                                >
                                    Ruaj & Mbyll
                                </button>
                            </div>
                            <Button variant="secondary" onClick={handleClose} style={{ width: "120px" }}>
                                Mbyll
                            </Button>
                        </div>

                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}


export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit për refresh të të dhënave
    };

    return (
        <>
            {
                props.type === "text" ? (
                    <button className="button-68" onClick={() => setModalShow(true)}>
                        <i className="bi bi-plus-circle"></i> <span style={{ marginLeft: "5px" }} />Krijo Furnitor
                    </button>
                ) : props.type === "insert" ? (
                    <button className="button-15" variant="contained" onClick={() => setModalShow(true)}><i class="bi bi-person-dash-fill"></i>  Krijo Furnitor</button>
                ) : (
                    <AddBoxIcon style={{ height: "30px", color: "black" }} onClick={() => setModalShow(true)} />
                )
            }
            <InsertSuppliers
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}