import React, { useState, useEffect } from "react"
import { ApiLink } from "../../APILink/ApiLink";
import axios from "axios";
import authHeader from "../../Auth/authHeader";
import jwtDecode from "jwt-decode";
// import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { Row, Col } from "react-bootstrap";
import CompanyInfo from "../../ProfileConfiguration/CompanyInfo";
import BanksAcc from "../../ProfileConfiguration/BanksAcc";
import AllCompanyUnits from "../../ProfileConfiguration/AllCompanyUnits";
import InsertCompanyUnit from "../CompanyUnit/InsertCompanyUnit";
import InsertBanks from "../Banks/InsertBanks";
import styled from 'styled-components';

export default function Profile() {
    const [companyInfo, setCompanyInfo] = useState([]);
    const [banks, setBanks] = useState([]);
    const [companyUnits, setCompanyUnits] = useState([]);


    // const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    // Kodi per Marrjen e informatave nga databaza
    const fetchCompanyInfo = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'PersonalData', {
                headers,
                params: { email: logedEmail },
            });
            setCompanyInfo(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchBanks = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { email: logedEmail, ledger: 102 } });
            setBanks(response.data);
            // setLoading(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorMessage) {
                console.log("Gabim në marrjen e të dhënave:", error.response.data.errorMessage);
            } else {
                // errorAlert("Gabim në marrjen e të dhënave!");
            }
        }
    };
    const fetchCompanyUnits = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'CompanyUnits', { headers, params: { email: logedEmail } });
            setCompanyUnits(response.data);
            // setLoading(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorMessage) {
                console.log("Gabim në marrjen e të dhënave:", error.response.data.errorMessage);
            } else {
                // errorAlert("Gabim në marrjen e të dhënave!");
            }
        }
    };

    useEffect(() => {
        fetchBanks();
        fetchCompanyUnits();
        fetchCompanyInfo();
    }, [logedEmail]);


    const StyledRow = styled.div`
  display: flex;
  width:100%;
  align-items: center; /* Opcionale, për të qendruar elementet vertikalisht të qendruar në rresht */
`;
    const FlexEndDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70%;
`;
    const FlexStartDiv = styled.div`
display: flex;
justify-content: flex-start;
width: 70%;
`;
    return (
        <div>
            <div>
                {/* <h1>Company Profile</h1> */}
                <CompanyInfo />
            </div>
            <br></br>
            {/* <div>
                <StyledRow>
                    <FlexStartDiv>
                        <h1>Company Units</h1>
                    </FlexStartDiv>
                    <FlexEndDiv>
                        <InsertCompanyUnit fetchData={fetchCompanyUnits} />
                    </FlexEndDiv>
                    <br></br>
                </StyledRow>
                <Row>
                    {
                        companyUnits.map((data, index) => {
                            return (
                                <Col key={index} md={12} lg={6}>
                                    <AllCompanyUnits
                                        uName={data.UnitName}
                                        IdNr={data.IdentificationNumber}
                                        Add={data.UnitAdress}
                                        City={data.UnitAdress}
                                        Email={data.Email}
                                    />
                                    <br></br>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div> */}
            <br></br>
            {/* <hr></hr> */}
            {/* <div>
                <StyledRow>
                    <FlexStartDiv>
                        <h1>Company Banks Account</h1>
                    </FlexStartDiv>
                    <FlexEndDiv>
                        <InsertBanks fetchData={fetchBanks} />
                    </FlexEndDiv>
                    <br></br>
                </StyledRow>
                <Row>
                    {
                        banks.map((data, index) => {
                            return (
                                <Col key={index} md={12} lg={6}>
                                    <BanksAcc
                                        bank={data.AccountName}
                                        accname={data.AccountName}
                                        accNr={data.BankAccount}
                                        SwiftCode={data}
                                        IBAN={data}
                                    />
                                    <br></br>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div> */}
            {/* <br></br>
                <hr></hr>
                <div>
                    <h1>Llogarit bankare</h1>
                </div> */}
        </div>
    )
}