import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import '../../Style/__button.scss'
import '../../Style/InsertModal.scss'
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Button } from "@mui/material";
// import InsertArticleGroup from '../ArticleGroups/InsertArticleGroups';
// import InsertArticleUnit from '../ArticleUnits/InsertArticleUnits'
import AddBoxIcon from '@mui/icons-material/AddBox';
import Select from 'react-select';

function InsertArticle(props) {
    const [barcode, setBarcode] = useState("");
    const [articlename, setArticleName] = useState("");
    const [description, setDescription] = useState("");
    const [groupId, setGroupId] = useState("");
    const [unitId, setUnitId] = useState("");
    const [ledgerId, setLedgerId] = useState("");
    const [vatId, setVatId] = useState("");
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [salesPrice, setSalesPrice] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [showInPos, setShowInPos] = useState(false);

    const [purchaseNoVAT, setPurchaseNoVAT] = useState(0);
    const [salesNoVAT, setSalesNoVAT] = useState(0);
    const [valueProfit, setValueProfit] = useState(0);
    const [percentProfit, setPercentProfit] = useState(0);
    const [vatPercent, setVatPercent] = useState(0);

    const [group, setGroup] = useState([]);
    const [filteredGroup, setFilteredGroup] = useState([]);

    const [unit, setUnit] = useState([]);
    const [filteredUnit, setfilteredUnit] = useState([]);

    const [ledger, setLedger] = useState([]);
    const [filteredLedger, setFilteredLedger] = useState([]);

    const [vat, setVat] = useState([]);
    const [filteredVat, setFilteredVat] = useState([]);

    const email = LoginUserDetailed(); // ndryshoni me emailin e përdoruesit të kyqur
    const headers = authHeader();

    const [groupError, setGroupError] = useState("");
    const [unitError, setUnitError] = useState("");
    const [ledgerError, setLedgerError] = useState("");
    const [vatError, setVatError] = useState("");

    const [barcodeExist, setBarcodeExsit] = useState("");
    const [msgBarcode, setMsgBarcode] = useState("");
    const [articleExist, setArticleExist] = useState("");
    const [msgArticle, setMsgArticle] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();
        if (barcodeExist > 0) {
            setMsgBarcode("This barcode is used");
            return;
        }
        else {
            setMsgBarcode("");
        }
        if (articleExist > 0) {
            setMsgArticle("This article name is used")
            return;
        }
        else {
            setMsgArticle("");
        }
        if (groupId == null || groupId == "") {
            setGroupError("Please choose group");
            return;
        }
        else {
            setGroupError("");
        }
        if (unitId == null || unitId == "") {
            setUnitError("Please choose unit");
            return;
        }
        else {
            setUnitError("");
        }
        if (ledgerId == null || ledgerId == "") {
            setLedgerError("Please choose ledger");
            return;
        }
        else {
            setLedgerError("");
        }
        if (vatId == null || vatId == "") {
            setVatError("Please choose vat");
            return;
        }
        else {
            setVatError("");
        }
        const stateData = {
            Barcode: barcode,
            ArticleName: articlename,
            Description: description,
            GroupID: groupId,
            UnitID: unitId,
            LedgerID: ledgerId,
            VAT: vatId,
            PurchasePrice: purchasePrice,
            SalesPrice: salesPrice,
            IsActive: isActive,
            TouchScreen: showInPos,
            CreatedByUser: email
        };
        axios.post(ApiLink.API_URL + 'article', stateData, { headers })
            .then((response) => {
                // console.log(response.data);
                handleSuccess("Article Added Successfully");
                setBarcode("");
                setArticleName("");
                setDescription("");
                setGroupId("");
                setUnitId("");
                setLedgerId("");
                setVatId("");
                setPurchasePrice(0);
                setSalesPrice(0);
                setIsActive(true);
                setShowInPos(false);
                setGroupName("");
                setUnitName("");
                setLedgerName("");
                setVatName("");
                props.onHide();
                props.refreshData(); // Thirrja e funksionit per refresh te dhenave
                fetchNextBarcode();
                setPurchaseNoVAT(0);
                setSalesNoVAT(0);
                setValueProfit(0);
                setPercentProfit(0);
            })
            .catch((error) => {
                // console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    };

    const handleClose = () => {
        // Ky funksion do të përdoret për të mbyllur modalin
        props.onHide();
        setBarcode("");
        setArticleName("");
        setDescription("");
        setGroupId("");
        setUnitId("");
        setLedgerId("");
        setVatId("");
        setPurchasePrice(0);
        setSalesPrice(0);
        setIsActive(true);
        setShowInPos(false);
        setGroupName("");
        setUnitName("");
        setLedgerName("");
        setVatName("");
        fetchNextBarcode();
        setPurchaseNoVAT(0);
        setSalesNoVAT(0);
        setValueProfit(0);
        setPercentProfit(0);

    };

    const fetchArticleGroups = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'ArticleGroup', { headers });
            setGroup(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticleUnits = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'articleunits', { headers });
            setUnit(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    //Kodi per shfaqjen e Ledgers ne Select List 
    useEffect(() => {
        axios.get(ApiLink.API_URL + 'Ledger/gettwoledgers', { headers })
            .then((response) => {
                setLedger(response.data);
            })
            .catch((error) => {
                // console.error(error);
            });
    }, []);

    const fetchVatRates = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'vatrates', { headers });
            setVat(response.data);
            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchNextBarcode = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/Barcode', { headers });
            setBarcode(response.data.NextBarcode);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë.");
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredGroup(
            group.filter((data) =>
                data.GroupName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, group]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setfilteredUnit(
            unit.filter((data) =>
                data.UnitName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, unit]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredLedger(
            ledger.filter((data) =>
                data.LedgerNameEN.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, ledger]);

    useEffect(() => {
        setFilteredVat(
            vat.filter((data) =>
                data.VATName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, vat]);




    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [groupName, setGroupName] = useState("");
    const handleGroupFilter = (selectedOption) => {
        if (selectedOption && selectedOption.GroupID) {
            setGroupId(selectedOption.GroupID);
            setGroupName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setGroupId("");
            setGroupName("");
        }
    };
    const [unitName, setUnitName] = useState("");
    const handleUnitFilter = (selectedOption) => {
        if (selectedOption && selectedOption.UnitID) {
            setUnitId(selectedOption.UnitID);
            setUnitName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setUnitId("");
            setUnitName("");
        }
    };

    const [ledgerName, setLedgerName] = useState("");
    const handleLedgerFilter = (selectedOption) => {
        if (selectedOption && selectedOption.LedgerID) {
            setLedgerId(selectedOption.LedgerID);
            setLedgerName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setLedgerId("");
            setLedgerName("");
        }
    };

    const [vatName, setVatName] = useState("");
    const handleVatFilter = (selectedOption) => {
        if (selectedOption && selectedOption.VATID) {
            setVatId(selectedOption.VATID);
            setVatName(selectedOption.label);
            setVatPercent(selectedOption.VATPercent);
        } else {
            setVatId("");
            setVatName("");
            setVatPercent(0);
        }
    };

    const checkBarcode = async (barcode) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/checkBarcode', { headers, params: { barcode: barcode } });
            setBarcodeExsit(response.data[0].Barcode)

            if (response.data[0].Barcode > 0) {
                setMsgBarcode("barcode exist");
            }
            else {
                setMsgBarcode("");
            }
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const checkArticleName = async (articleName) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/chectArticleName', { headers, params: { articleName: articleName } });
            setArticleExist(response.data[0].ArticleName);
            if (response.data[0].ArticleName > 0) {
                setMsgArticle("Article exist");
            }
            else {
                setMsgArticle("");
            }

        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    //Ky funksion perdoret per ta kalkuluar qmimin pa tvsh, bazuar ne parametrat e derguar
    const calcPriceWith = (price, vatPercent, hasVAT) => {
        //gjeje qmimin pa tvsh bazuar ne qmimin me tvsh
        if (hasVAT === "withVAT") {
            return (Number(price) * Number(100)) / (Number(100) + Number(vatPercent));
        }
        else if (hasVAT === "noVAT") {
            return (Number(price) * (Number(1) + (Number(vatPercent) / Number(100))));
        }
    }

    const calcPriceFromPriceWithVAT = async (price, vat, priceType) => {
        try {

            if (priceType === "purchaseWithVAT") {
                // const noVAT = (Number(price) * Number(100)) / (Number(100) + Number(vat));
                setPurchaseNoVAT(calcPriceWith(price, vat, "withVAT"));
            }
            else if (priceType === "purchaseWithoutVAT") {
                setPurchasePrice(calcPriceWith(price, vat, "noVAT"));
            }
            else if (priceType === "salesWithVAT") {
                // const noVAT = (Number(price) * Number(100)) / (Number(100) + Number(vat));
                const noVAT = calcPriceWith(price, vat, "withVAT")
                setSalesNoVAT(noVAT);

                const profitiNeVlerPaTVSH = Number(noVAT) - Number(purchaseNoVAT)
                setValueProfit(profitiNeVlerPaTVSH);

                const profitiVlerMeTVSH = Number(price) / Number(purchasePrice);

                setPercentProfit((Number(profitiVlerMeTVSH) - Number(1)) * Number(100));
            }
            else if (priceType === "salesWithoutVAT") {
                const salesWithVAT = calcPriceWith(price, vat, "noVAT");
                setSalesPrice(salesWithVAT);

                const profitiNeVlerPaTVSH = Number(price) - Number(purchaseNoVAT)
                setValueProfit(profitiNeVlerPaTVSH);

                const profitiVlerMeTVSH = Number(salesWithVAT) / Number(purchasePrice);
                setPercentProfit((Number(profitiVlerMeTVSH) - Number(1)) * Number(100));

            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const KalkulimiCmimitNgaProfitiNgaVlera = (valueProfit, priceWithoutVAT, vatPercent) => {
        // Calculate the selling price without VAT
        const cmimiShitjesPaTVSH = Number(priceWithoutVAT) + Number(valueProfit);
        setSalesNoVAT(cmimiShitjesPaTVSH)

        // Calculate the profit percentage
        const profitiNePerqindje = (Number(valueProfit) / Number(priceWithoutVAT)) * Number(100);
        setPercentProfit(profitiNePerqindje);

        // Calculate VAT and the selling price with VAT
        const TVSH = Number(cmimiShitjesPaTVSH) * (Number(vatPercent) / Number(100));
        const cmimiShitjesMeTVSH = Number(cmimiShitjesPaTVSH) + Number(TVSH);
        setSalesPrice(cmimiShitjesMeTVSH);
    }


    function KalkulimiCmimitNgaProfitiNgaPerqindja(profitPercent, purchasePriceWithoutVAT, vatPercent) {
        // Kalkulo fitimin në përqindje
        const fitimiPerqindje = Number(purchasePriceWithoutVAT) * (Number(profitPercent) / Number(100));
        const cmimiShitjesPaTVSH = Number(purchasePriceWithoutVAT) + Number(fitimiPerqindje);
        setSalesNoVAT(cmimiShitjesPaTVSH);

        // Kalkulo TVSH-në dhe çmimin e shitjes me TVSH
        const TVSH = Number(cmimiShitjesPaTVSH) * (Number(vatPercent) / Number(100));
        const cmimiShitjesMeTVSH = Number(cmimiShitjesPaTVSH) + Number(TVSH);
        setSalesPrice(cmimiShitjesMeTVSH);

        // Kalkulo fitimin në vlerë
        const profitiNeVlere = Number(cmimiShitjesPaTVSH) - Number(purchasePriceWithoutVAT);
        setValueProfit(profitiNeVlere);
    }



    useEffect(() => {
        fetchArticleGroups();
        fetchArticleUnits();
        fetchVatRates();
        fetchNextBarcode();
    }, [])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter" >
                    Krijo Artikull të ri
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} className="InsertModal__Container__Form">
                    <div className='InsertModal__Container__Form__Container2'>
                        <div className='InsertModal__Container__Form__Container2__firstInput'>
                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField className='InsertModal__Container__Form__Container2__field__InsertField'
                                    id="Barcode"
                                    label="Barkodi, Psh:AC00000001"
                                    variant="outlined"
                                    type="text"
                                    name='Barcode'
                                    autoComplete='off'
                                    value={barcode}
                                    onChange={event => { setBarcode(event.target.value); checkBarcode(event.target.value); }}
                                />
                                <span style={{ color: "red" }}>{msgBarcode && `${msgBarcode}`}</span>
                            </div>
                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField className='InsertModal__Container__Form__Container2__field__InsertField'
                                    required
                                    id="ArticleName"
                                    label="Emri i Artikullit"
                                    variant="outlined"
                                    type="text"
                                    name='ArticleName'
                                    autoComplete='off'
                                    value={articlename}
                                    onChange={event => { setArticleName(event.target.value); setDescription(event.target.value); checkArticleName(event.target.value); }}
                                />
                                <span style={{ color: "red" }}>{msgArticle && `${msgArticle}`}</span>
                            </div>
                        </div>
                        <div className='InsertModal__Container__Form__Container2__field__description'>
                            <TextareaAutosize style={{ height: "100px" }}
                                className="InsertModal__Container__Form__Container2__field__descriptionArea"
                                id="Description"
                                label="Përshkrimi"
                                variant="outlined"
                                placeholder='Përshkrimi'
                                type="text"
                                autoComplete='off'
                                name='Description'
                                value={description}
                                onChange={event => setDescription(event.target.value)}
                            />
                        </div>
                        <div className='InsertModal__Container__Form__Container2__selects'>
                            <div className="InsertModal__Container__Form__Container2__selects__textfield_button">
                                <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Grupin</span>
                                <Select
                                    className="InsertModal__Container__Form__Container2__selects__textfield_button__selectList"
                                    inputId="GroupID"
                                    value={{ label: groupName, value: groupId }}
                                    options={filteredGroup.map((data) => ({
                                        label: data.GroupName,
                                        value: data.groupId,
                                        GroupID: data.GroupID
                                    }))}
                                    onInputChange={handleInputChange}
                                    onChange={handleGroupFilter}
                                    isClearable
                                    placeholder="Selekto Grupin"
                                    noOptionsMessage={() => 'No matching '}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            height: "50px",
                                            marginTop: "10px"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            backgroundColor: "#fff",

                                            zIndex: 9999,
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            borderRadius: "4px",
                                        }),
                                        menuList: (provided) => ({
                                            ...provided,
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#000",
                                        }),
                                    }}
                                />
                                <span style={{ color: "red" }}>{groupError && `${groupError}`}</span>
                            </div>
                            <div className="InsertModal__Container__Form__Container2__selects__textfield_button">
                                <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh njesinë matëse</span>
                                <Select
                                    className="InsertModal__Container__Form__Container2__selects__textfield_button__selectList"
                                    inputId="UnitID"
                                    value={{ label: unitName, value: unitId }}
                                    options={filteredUnit.map((data) => ({
                                        label: data.UnitName,
                                        value: data.UnitID,
                                        UnitID: data.UnitID
                                    }))}
                                    onInputChange={handleInputChange}
                                    onChange={handleUnitFilter}
                                    isClearable
                                    placeholder="Select Unit"
                                    noOptionsMessage={() => 'No matching '}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            height: "50px",
                                            marginTop: "10px"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            backgroundColor: "#fff",

                                            zIndex: 9999,
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            borderRadius: "4px",
                                        }),
                                        menuList: (provided) => ({
                                            ...provided,
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#000",
                                        }),
                                    }}
                                />
                                <span style={{ color: "red" }}>{unitError && `${unitError}`}</span>
                            </div>
                        </div>
                        <div className='InsertModal__Container__Form__Container2__selects'>
                            <div className='InsertModal__Container__Form__Container2__selects__2'>
                                <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Llogarinë kontabël</span>
                                <Select
                                    className="InsertModal__Container__Form__Container2__selects__textfield_button__selectList"
                                    inputId="LedgerID"
                                    value={{ label: ledgerName, value: ledgerId }}
                                    options={filteredLedger.map((data) => ({
                                        label: data.LedgerNameEN,
                                        value: data.LedgerID,
                                        LedgerID: data.LedgerID
                                    }))}
                                    onInputChange={handleInputChange}
                                    onChange={handleLedgerFilter}
                                    isClearable
                                    placeholder="Select Ledger"
                                    noOptionsMessage={() => 'No matching '}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            height: "50px",
                                            marginTop: "10px"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            backgroundColor: "#fff",

                                            zIndex: 9999,
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            borderRadius: "4px",
                                        }),
                                        menuList: (provided) => ({
                                            ...provided,
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#000",
                                        }),
                                    }}
                                />
                                <span style={{ color: "red" }}>{ledgerError && `${ledgerError}`}</span>

                            </div>

                            <div className='InsertModal__Container__Form__Container2__selects__2'>
                                <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh normën e TVSH-së</span>
                                <Select
                                    className="InsertModal__Container__Form__Container2__selects__textfield_button__selectList"
                                    inputId="VATID"
                                    value={{ label: vatName, value: vatId }}
                                    options={filteredVat.map((data) => ({
                                        label: data.VATName,
                                        value: data.VATID,
                                        VATID: data.VATID,
                                        VATPercent: data.VATPercent
                                    }))}
                                    onInputChange={handleInputChange}
                                    onChange={handleVatFilter}
                                    isClearable
                                    placeholder="Select VAT"
                                    noOptionsMessage={() => 'Write VAT to filter '}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            height: "50px",
                                            marginTop: "10px"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            backgroundColor: "#fff",

                                            zIndex: 9999,
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            borderRadius: "4px",
                                        }),
                                        menuList: (provided) => ({
                                            ...provided,
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#000",
                                        }),
                                    }}
                                />
                                <span style={{ color: "red" }}>{vatError && `${vatError}`}</span>

                            </div>
                        </div>
                        <div className='InsertModal__Container__Form__Container2__firstInput'>
                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="PurchasePriceNoVAT"
                                    label="Cmimi i blerjës pa TVSH"
                                    variant="outlined"
                                    name='PurchasePriceNoVAT'
                                    // value={purchaseNoVAT}
                                    value={purchaseNoVAT !== null ? purchaseNoVAT.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                    onChange={event => setPurchaseNoVAT(event.target.value)}
                                    onKeyUp={event => calcPriceFromPriceWithVAT(event.target.value, vatPercent, "purchaseWithoutVAT")}
                                />
                            </div>

                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="PurchasePrice"
                                    label="Cmimi i Blerjes me TVSH"
                                    variant="outlined"
                                    name='PurchasePrice'
                                    // value={purchasePrice}
                                    value={purchasePrice !== null && purchasePrice !== 0 ? purchasePrice.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                    onChange={event => setPurchasePrice(event.target.value)}
                                    onKeyUp={event => calcPriceFromPriceWithVAT(event.target.value, vatPercent, "purchaseWithVAT")}
                                />
                            </div>
                            {/* <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="SalesPrice"
                                    label="Cmimi i Shitjes me TVSH"
                                    variant="outlined"
                                    type="number"
                                    name='SalesPrice'
                                    value={salesPrice}
                                    onChange={event => setSalesPrice(event.target.value)}
                                />
                            </div> */}
                        </div>
                        <div className='InsertModal__Container__Form__Container2__firstInput'>
                            {/* <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="PurchasePrice"
                                    label="Cmimi i blerjës pa TVSH"
                                    variant="outlined"
                                    type="number"
                                    name='PurchasePrice'
                                    value={purchasePrice}
                                    onChange={event => setPurchasePrice(event.target.value)}
                                />
                            </div> */}

                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="SalesPrice"
                                    label="Cmimi i shitjës Pa TVSH"
                                    variant="outlined"
                                    name='SalesPrice'
                                    // value={salesNoVAT}
                                    value={salesNoVAT !== null && salesNoVAT !== 0 ? salesNoVAT.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                    onChange={event => setSalesNoVAT(event.target.value)}
                                    onKeyUp={event => calcPriceFromPriceWithVAT(event.target.value, vatPercent, "salesWithoutVAT")}

                                />
                            </div>
                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="SalesPrice"
                                    label="Cmimi i Shitjes me TVSH"
                                    variant="outlined"
                                    name='SalesPrice'
                                    // value={salesPrice}
                                    value={salesPrice !== null && salesPrice !== 0 ? salesPrice.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                    onChange={event => setSalesPrice(event.target.value)}
                                    onKeyUp={event => calcPriceFromPriceWithVAT(event.target.value, vatPercent, "salesWithVAT")}

                                />
                            </div>
                        </div>
                        <div className='InsertModal__Container__Form__Container2__firstInput'>
                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="ValueProfit"
                                    label="Profiti në vlerë"
                                    variant="outlined"
                                    name='ValueProfit'
                                    // value={valueProfit}
                                    value={valueProfit !== null && valueProfit !== 0 ? valueProfit.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                    onChange={event => setValueProfit(event.target.value)}
                                    onKeyUp={event => KalkulimiCmimitNgaProfitiNgaVlera(event.target.value, purchaseNoVAT, vatPercent)}
                                />
                            </div>

                            <div className='InsertModal__Container__Form__Container2__field'>
                                <TextField style={{ width: "100%" }}
                                    id="PercentProfit"
                                    label="Profiti në perqindje"
                                    variant="outlined"
                                    name='PercentProfit'
                                    // value={percentProfit}
                                    value={percentProfit !== null && percentProfit !== 0 ? percentProfit.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                    onChange={event => setPercentProfit(event.target.value)}
                                    onKeyUp={event => KalkulimiCmimitNgaProfitiNgaPerqindja(event.target.value, purchaseNoVAT, vatPercent)}

                                />
                            </div>
                        </div>
                        <div className='InsertModal__Container__Form__Container2__buttons'>
                            <div>
                                <label className='InsertModal__Container__Form__Container2__checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={isActive}
                                        onChange={(event) => setIsActive(event.target.checked)}
                                        className='InsertModal__Container__Form__Container2__checkbox-input'
                                    />
                                    <span className='InsertModal__Container__Form__Container2__checkbox-custom'></span>
                                    Active
                                </label>
                            </div>
                            <div>
                                <label className='InsertModal__Container__Form__Container2__checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={showInPos}
                                        onChange={(event) => setShowInPos(event.target.checked)}
                                        className='InsertModal__Container__Form__Container2__checkbox-input'
                                    />
                                    <span className='InsertModal__Container__Form__Container2__checkbox-custom'></span>
                                    Shfaq ne POS
                                </label>
                            </div>
                            <div className='InsertModal__Container__Form__Container2__save-button'>
                                <button
                                    type="submit"
                                    className="btn btn-success float-end"
                                    style={{ width: "120px" }}
                                >
                                    Ruaj & Mbyll
                                </button>
                            </div>
                            <Button variant="secondary" onClick={handleClose}>
                                Mbyll
                            </Button>
                        </div>


                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };
    return (
        <div>
            {
                props.type === "text" ? (
                    <button className="button-68" onClick={() => setModalShow(true)}>
                        <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Krijo Artikull
                    </button>
                ) : props.type === "insert" ? (
                    <button className="button-15" variant="contained" onClick={() => setModalShow(true)}><i class="bi bi-upc-scan"></i>  Krijo Artikull</button>
                ) : props.type === "menu" ? (
                    <i class="bi bi-upc-scan" onClick={() => setModalShow(true)}></i>
                ) : (
                    <AddBoxIcon style={{ height: "29px", color: "black" }} onClick={() => setModalShow(true)} />
                )
            }
            <InsertArticle
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </div>
    );
}
