const currencyFormatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const InventoryDataGrid = [
    { field: 'id', headerName: '#', width: 60, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true },
    // { field: 'accID', headerName: 'ID e Llogarisë', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'accName', headerName: 'Emri Artikullit', width: 180, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'barcode', headerName: 'Barkodi', width: 100, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'group', headerName: 'Grupi', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'unit', headerName: 'Njesia Matese', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'qtyInput', headerName: 'Sasia Total Hyrje', type: 'string', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'qtyOutput', headerName: 'Sasia Total Dalje', type: 'string', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'qtyStok', headerName: 'Sasia Total në Stok', type: 'string', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'price', headerName: 'Çmimi Mesatar', type: 'string', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    }, {
        field: 'total', headerName: 'Vlera Totale', type: 'string', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

export const InventoryDetails = [
    { field: 'id', headerName: '#', width: 60, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true },
    { field: 'type', headerName: 'Tipi', width: 70, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'barcode', headerName: 'Barkodi', width: 100, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'articleName', headerName: 'Emri Artikkullit', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'accName', headerName: 'Emri Partnerit', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'priceNoVAT', headerName: 'Cmimi pa TVSH', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'qtyInput', headerName: 'Sasia Hyrje', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'qtyOutput', headerName: 'Sasia Dalje', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'progresive', headerName: 'Progresive', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

