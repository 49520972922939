import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/__button.scss'
import Select from 'react-select';

function InsertCompanies(props) {
    const headers = authHeader();
    const loginEmail = LoginUserDetailed();
    const year = new Date().getFullYear();


    const [companyName, setCompanyName] = useState("");
    const [identificationNumber, setIdentificationNumber] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [adress, setAdress] = useState("");
    const [cityID, setCityID] = useState("");
    const [stateid, setStateID] = useState("");
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [ediPass, setEdiPass] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [vATDeclarant, setvATDeclarant] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [arbkPass, setArbkPass] = useState("");
    const [mailPass, setMailPass] = useState("");
    const [smtpClient, setSmtpClient] = useState("");

    const [city, setCity] = useState([]);
    const [filteredCity, setFilteredCity] = useState([]);

    const [states, setStates] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const stateData = {
            CompanyName: companyName,
            IdentificationNumber: identificationNumber,
            VATNumber: vatNumber,
            Address: adress,
            CityID: cityID,
            StateID: stateid,
            PhoneNumbers: phoneNumbers,
            EdiPass: ediPass,
            Email: email,
            Comment: comment,
            CreatedByUser: loginEmail,
            VATDeclarant: vATDeclarant,
            FiscalYear: year,
            IsActive: isActive,
            ARBKPass: arbkPass,
            MailPass: mailPass,
            SMTPClient: smtpClient
        };
        axios.post(ApiLink.API_URL + 'Companies', stateData, { headers })
            .then((response) => {
                console.log(response.data);
                handleSuccess("Company Added Successfully");
                setCompanyName("");
                setIdentificationNumber("");
                setVatNumber("");
                setAdress("");
                setCityID("");
                setStateID("");
                setPhoneNumbers("");
                setEdiPass("");
                setEmail("");
                setComment("");
                setArbkPass("");
                setMailPass("");
                setSmtpClient("");
                setvATDeclarant(true);
                setIsActive(true);
                props.onHide();
                props.refreshData(); // Thirrja e funksionit per refresh te dhenave

            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    };

    const fetchCityByState = async (selectedStateID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Cities/getcitybystate', { headers, params: { stateid: selectedStateID } });
            setCity(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    useEffect(() => {
        const headers = authHeader();
        axios.get(ApiLink.API_URL + 'states', { headers })
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredStates(
            states.filter((data) =>
                data.StateName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, states]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredCity(
            city.filter((data) =>
                data.CityName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, city]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    // i run te dhenat per Debit Ledger
    const [stateName, setStateName] = useState("");
    const handleFilterState = (selectedOption) => {
        if (selectedOption && selectedOption.StateID) {
            setStateID(selectedOption.StateID);
            fetchCityByState(selectedOption.StateID);
            setStateName(selectedOption.label);
        } else {
            setStateID("");
            setStateName("");
        }
    };

    const [cityName, setCityName] = useState("");
    const handleFilterCity = (selectedOption) => {
        if (selectedOption && selectedOption.CityID) {
            setCityID(selectedOption.CityID);
            setCityName(selectedOption.label);
        } else {
            setCityID("");
            setCityName("");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Insert new Company
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Emri i Kompanisë</span>
                            <input
                                type="text"
                                className="form-control"
                                value={companyName}
                                onChange={event => setCompanyName(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Numri Identifikues </span>
                            <input
                                type="number"
                                className="form-control"
                                value={identificationNumber}
                                onChange={event => setIdentificationNumber(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Numri i TVSH-së</span>
                            <input
                                type="number"
                                className="form-control"
                                value={vatNumber}
                                onChange={event => setVatNumber(event.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Adresa</span>
                            <input
                                type="text"
                                className="form-control"
                                value={adress}
                                onChange={event => setAdress(event.target.value)}
                                required
                            />
                        </div>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Shtetin</span>
                            <Select
                                inputId="StateID"
                                className="Transfers__Container__Format__SameInput"
                                value={{ label: stateName, value: stateid }}
                                options={filteredStates.map((data) => ({
                                    label: data.StateName,
                                    value: data.StateID,
                                    StateID: data.StateID
                                }))}
                                onInputChange={handleInputChange}
                                onChange={handleFilterState}
                                isClearable
                                placeholder="Select"
                                noOptionsMessage={() => 'No matching'}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: "50px"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff",

                                        zIndex: 9999,
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#000",
                                    }),
                                }}
                            />
                        </div>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Qytetin</span>
                            <Select
                                required
                                inputId="CityID"
                                className="Transfers__Container__Format__SameInput"
                                value={{ label: cityName, value: cityID }}
                                options={filteredCity.map((data) => ({
                                    label: data.CityName,
                                    value: data.CityID,
                                    CityID: data.CityID
                                }))}
                                onInputChange={handleInputChange}
                                onChange={handleFilterCity}
                                isClearable
                                placeholder="Select"
                                noOptionsMessage={() => 'No matching'}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: "50px"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff",

                                        zIndex: 9999,
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#000",
                                    }),
                                }}
                            />
                        </div>
                        <br></br>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Numri Telefonit </span>
                            <input
                                type="text"
                                className="form-control"
                                value={phoneNumbers}
                                onChange={event => setPhoneNumbers(event.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Fjalkalimi i sistemit të ATK-së</span>
                            <input
                                type="text"
                                className="form-control"
                                value={ediPass}
                                onChange={event => setEdiPass(event.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Email </span>
                            <input
                                type="text"
                                className="form-control"
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">SMTP Client </span>
                            <input
                                type="text"
                                className="form-control"
                                value={smtpClient}
                                id="SMTPClient"
                                name="SMTPClient"
                                onChange={event => setSmtpClient(event.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Fjalkalimi i sistemit të ARBK-së</span>
                            <input
                                type="text"
                                className="form-control"
                                value={arbkPass}
                                onChange={event => setArbkPass(event.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Fjalkalimi i Email-it </span>
                            <input
                                type="text"
                                className="form-control"
                                value={mailPass}
                                onChange={event => setMailPass(event.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Komenti</span>
                            <textarea
                                className="form-control"
                                value={comment}
                                onChange={event => setComment(event.target.value)}
                            />
                        </div>
                        <button type="submit"
                            className="btn btn-primary float-end"
                            style={{ background: "#7a95a6" }}
                        >Ruaj</button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };

    return (
        <>
            <button className="button-68" onClick={() => setModalShow(true)}>
                <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} /> Krijo Kompani
            </button>

            <InsertCompanies
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}