import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { InsertForm } from '../../Components/Forms';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import '../../Style/__button.scss'

function InsertStates(props) {
  const [sName, setsName] = useState("");
  const [isActive, setIsActive] = useState(true);

  //Ketu deklarphen te gjitha atributet qe duhet te shtohen ne Input 
  //formen per tu insertuar nje element ne databaz
  const State = [
    {
      id: 1,
      name: 'StateName',
      type: 'text',
      value: sName,
      event: setsName
    }
  ]

  const handleSubmit = (event) => {
    event.preventDefault();
    const headers = authHeader();
    const stateData = {
      StateName: sName,
      CompanyID: 123456789,
      IsActive: isActive,
    };
    axios.post(ApiLink.API_URL + 'states', stateData, {headers})
      .then((response) => {
        console.log(response.data);
        handleSuccess("State Added Successfully");
        setsName("");
        setIsActive(true);
        props.onHide();
        props.refreshData(); // Thirrja e funksionit per refresh te dhenave

      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 409) {
          errorAlert(error.response.data);
        } else {
          errorAlert("An unknown error occurred while processing the request.");
        }
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
          Insert State
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div >

          {/* datalink=>konstantja e krijuar me lart me te dhenat e nevojshme
          per input formen
          onSubmit=>Dergohet funksioni i krijuar me lart per insertimin e te dhenave
          Checked dhe Onchange=>Duhet te jene gjithmon te njejta */}
          <InsertForm
            datalink={State}
            onSubmit={handleSubmit}
            checked={isActive}
            onChange={(event) => setIsActive(event.target.checked)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function InsertData(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const handleRefreshData = () => {
    props.fetchData(); // Thirrja e funksionit per refresh te dhenave
  };

  return (
    <>
      <button className="button-68" onClick={() => setModalShow(true)}>
      <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} /> Add New State
      </button>

      <InsertStates
        show={modalShow}
        onHide={() => setModalShow(false)}
        refreshData={handleRefreshData}
      />
    </>
  );
}
