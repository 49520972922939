// TableActions.js
import React from 'react';
import { IconButton } from '@mui/material';
import { AiFillEdit, AiOutlineDelete } from 'react-icons/ai';
function TableActions({ handleEdit, handleDelete, rowData }) {
  return (
    <div>
      <IconButton onClick={() => handleEdit(rowData)}>
        <AiFillEdit style={{ color: '#466F86' }} />
      </IconButton>
      <IconButton onClick={() => handleDelete(rowData)}>
        <AiOutlineDelete style={{ color: '#962a38' }} />
      </IconButton>
    </div>
  );
}

export default TableActions;
