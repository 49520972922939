import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './../../Style/SalesDetailStyle.scss'
import { deleteAlert, errorAlert, handleSuccess, updateAlert } from "../../Components/Alerts";
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { AiFillShopping } from "react-icons/ai";
import { Paper, TextareaAutosize } from "@mui/material";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import PrintContent from "../../Print/PrintContent";
import { MdEdit } from "react-icons/md";
import Select from 'react-select';
import InsertClient from "../Clients/InsertClients";
import InsertArticle from "../Article/InsertArticle";
import DataGridWithActions from "../../Components/DataGridWithActions";
import { mainGridHeader, detailsData } from "../FletHyrja/FletHyrjeDataGrid";
import Autocomplete from "@mui/material/Autocomplete";


export default function FletDalja() {
    const TranscationData = [
        {
            id: 1,
            type: "National"
        },
        {
            id: 2,
            type: "International"
        },
    ]
    const [open, setOpen] = React.useState(false);

    const [showDetails, setShowDetails] = useState(true);

    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [detailLine, setDetailLine] = useState([]); //i mban rreshtat e te dhenave detale 
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        SupplierID: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: "",
        AccountID: "",
        ActionType: "",
        // AccountIDSpare:"",
        // Amount0: 0,
        // Amount8: 0,
        // Amount18: 0,
        // VAT8: "",
        // VAT18: "",
        // Total: "",
    });

    const [formDetailData, setFormDetailData] = useState({
        HistoryID: "",
        ArticleID: "",
        QtyOutput: "",
        Total: "",
        PriceWithVAT: "",
        PriceWithoutVAT: "",
        ModifiedByUser: "",
        InvoiceNr: "",
    })

    //Per insertimin ne Article History
    const [articleID, setArticleID] = useState("");
    const [qtyOutput, setQtyOutput] = useState(0);
    const [insertpriceWithoutVAT, setInsertPriceWithoutVAT] = useState(0);
    const [insertPriceWithVAT, setInsertPriceWithVAT] = useState(0);
    const [insertTotal, setInsertTotal] = useState(0);
    const [vatRates, setVATRates] = useState(0);

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [clients, setClients] = useState([]);
    const [supplierdetails, setSupplierDetails] = useState([]);

    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const [valuedetails, setValueDetails] = useState([]);

    // Te dhenat per printim
    const [headerData, setHeaderData] = useState([]);

    const [companiesData, setCompaniesData] = useState([]);
    const [articlePrintDetails, setArticlePrintDetails] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo 
    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);

    // ky kod i ndryshon gjendjet e formave

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
        setArticleID();
        setQtyOutput(0);
        setInsertPriceWithoutVAT(0);
        setInsertPriceWithVAT(0);
        setInsertTotal(0);
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true); // I fsheh formën e parë
    };

    const clearEdit = () => {
        setFormDetailData({
            HistoryID: "",
            ArticleID: "",
            QtyOutput: 0,
            PriceWithVAT: 0,
            PriceWithoutVAT: 0,
            Total: 0
        });
        clearLine();
        handleOpenForm2();
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'ExitSheet', { headers });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchDetailLineData = async (invoicenr) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'ExitSheet/exitsheetsdetails', { headers, params: { invNumber: invoicenr } });
            setDetailLine(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article', { headers });
            setArticles(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchvaluedetails = async (number) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'ExitSheet/getvaluedetails', { headers, params: { invNumber: number } });
            setValueDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchHeaderData = async (number) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'SalesInDetails/GetPrintHeaderDetails', { headers, params: { invNumber: number, actionType: "EXS" } });
            setHeaderData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchBanksAccount = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/BankDetails', { headers });
            setBankAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticleDataPrint = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetArticleDetailsForPrint', { headers, params: { invNumber: formData.InvoiceNr, actionType: "EXS" } });
            setArticlePrintDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'ExitSheet' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Fletë dalja u fshi me sukses");
            fetchData();
        } catch (error) {
            errorAlert("Fletë dalja nuk mund te fshihet");
        }
    };

    const handleDeleteDetailLine = async (deleteItemIdDetail) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'ExitSheet/oneline' + `/${deleteItemIdDetail}`, { headers, params: { email: logedEmail, invoiceNR: formData.InvoiceNr } }
            );
            deleteAlert("Linja u fshi me sukses");
            fetchArticleDataPrint();
            fetchData();
            fetchDetailLineData(formData.InvoiceNr);
            fetchvaluedetails(formData.InvoiceNr);
            fetchHeaderData(formData.InvoiceNr);


        } catch (error) {
            deleteAlert("Linja nuk mund te fshihet");
        }
    };

    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        fetchDetailLineData(item.InvoiceNr);
        fetchHeaderData(item.InvoiceNr);
        fetchvaluedetails(item.InvoiceNr);
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            ActionType: item.ActionType,
            // AccountIDSpare: item.AccountIDSpare,
            AccountID: item.AccountID,
            SupplierID: item.SupplierID,
            // Amount0: item.Amount0,
            // Amount8: item.Amount8,
            // Amount18: item.Amount18,
            // VAT8: item.VAT8,
            // VAT18: item.VAT18,
            // Total: item.Total,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: logedEmail,
        });
        setShowEditModal(true);
        setShowDetails(false);
        handleOpenForm2();
    };

    const handleEditDetails = (item) => {
        handleOpenForm1();
        fetchArticlesSalesPrice(item.ArticleID, "getVat");
        setFormDetailData({
            HistoryID: item.HistoryID,
            ArticleID: item.ArticleID,
            QtyOutput: item.QtyOutput,
            Total: item.Total,
            PriceWithVAT: item.PriceWithVAT,
            PriceWithoutVAT: item.PriceWithoutVAT,
            ModifiedByUser: logedEmail,
            InvoiceNr: formData.InvoiceNr,
        });
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        axios
            .put(ApiLink.API_URL + 'ExitSheet', formData, { headers })
            .then((response) => {
                console.log(response.data);
                // setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("Fletë dalja u editua me sukses");
                fetchArticleDataPrint();
                fetchHeaderData(formData.InvoiceNr);
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Dështoi: Fletë dalja nuk u editua")
            });
    };

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!formData.InvoiceNr) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        const stateDate = {
            InvoiceNr: formData.InvoiceNr,
            Date: formData.Date,
            ArticleID: articleID,
            QtyOutput: qtyOutput,
            PriceWithoutVAT: insertpriceWithoutVAT,
            PriceWithVAT: insertPriceWithVAT,
            CreatedByUser: logedEmail,
            SupplierID: formData.AccountID

        };
        axios.post(ApiLink.API_URL + 'ExitSheet/createnewline', stateDate, { headers, params: { vatRates: vatRates } })
            .then(() => {
                // handleSuccess("Detail  Added Successfully");
                setArticleID("");
                setQtyOutput(0);
                setInsertPriceWithoutVAT(0);
                setInsertPriceWithVAT(0);
                setInsertTotal(0);
                fetchData();
                fetchvaluedetails(formData.InvoiceNr);
                fetchHeaderData(formData.InvoiceNr);
                fetchArticleDataPrint();
                setArticlesName();
                fetchDetailLineData(formData.InvoiceNr);
                setField(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDetail = (event) => {
        const { name, value } = event.target;
        setFormDetailData({ ...formDetailData, [name]: value });
    };
    const handleSubmitDetailLine = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'ExitSheet/updatedetailsArticle', formDetailData, { headers, params: { vatRates: vatRates } })
            .then((response) => {
                console.log(response.data);
                fetchDetailLineData(formData.InvoiceNr);
                fetchvaluedetails(formData.InvoiceNr);
                updateAlert("Linja u editua me sukses");
                fetchArticleDataPrint();
                fetchHeaderData(formData.InvoiceNr);
                fetchData();
                handleOpenForm2();
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Dështoi: Linja nuk u editua")
            });
    }


    const clearLine = () => {
        setQtyOutput(0);
        setInsertPriceWithoutVAT(0);
        setInsertPriceWithVAT(0);
        setInsertTotal(0);
        setArticleID();
        fetchDetailLineData(formData.InvoiceNr);
        fetchData();
        fetchvaluedetails(formData.InvoiceNr);
        fetchHeaderData(formData.InvoiceNr);
        fetchArticleDataPrint();
        setArticlesName("");
    }


    const filteredData = data.filter((item) => {
        return (
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const fetchClients = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
            setClients(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchSuppliersDetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByID', { headers, params: { id: formData.AccountID } });
            setSupplierDetails(response.data);
        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchArticlesSalesPrice = async (articleID, form) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetSalesPrice', { headers, params: { articleID: articleID } });
            const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja

            if (form == "edit") {
                const calculatedValue = parseFloat(response.data.SalesPrice) * 100 / (100 + response.data.SalesPrice);
                // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
                // setVATRates(response.data.VATRates);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: response.data.SalesPrice,
                    PriceWithoutVAT: calculatedValue,
                    QtyOutput: 1,
                    Total: 1 * response.data.SalesPrice
                }));
            }
            else if (form == "getVat") {
                // setVATRates(response.data.VATRates);
            }
            else {
                setInsertPriceWithVAT(salesPriceData.SalesPrice);
                // setVATRates(response.data.VATRates);
                setQtyOutput(1);
                setInsertTotal(1 * salesPriceData.SalesPrice)
                // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
                const calculatedValue = parseFloat(salesPriceData.SalesPrice) * 100 / (100 + salesPriceData.VATRates);
                setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
            }
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handleCalculatePriceWithVAT = async (type) => {
        try {
            if (isNaN(insertpriceWithoutVAT)) {
                throw new Error("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qty input) duhet të jetë një numër.");
            }
            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const vat = Number(formDetailData.PriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(formDetailData.PriceWithoutVAT) + vat;
                const total = Number(formDetailData.QtyOutput) * calculatedPriceWithVAT;

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: calculatedPriceWithVAT,
                    Total: total,
                }));

            } else {
                const vat = Number(insertpriceWithoutVAT) * (Number(vatRates) / 100);
                const calculatedPriceWithVAT = Number(insertpriceWithoutVAT) + vat;
                const total = Number(qtyOutput) * calculatedPriceWithVAT;
                // Veprimet specifike për shtim të ri
                setInsertPriceWithVAT(calculatedPriceWithVAT);
                setInsertTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };

    const handleCalculatePriceWithoutVAT = async (type) => {
        try {
            if (isNaN(insertPriceWithVAT)) {
                throw new Error("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            }

            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve (qty input) duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const calculatedPriceWithoutVAT = Number(formDetailData.PriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(formDetailData.QtyOutput) * Number(formDetailData.PriceWithVAT);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                    Total: total,
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const calculatedPriceWithoutVAT = Number(insertPriceWithVAT) * 100 / (100 + Number(vatRates));
                const total = Number(qtyOutput) * Number(insertPriceWithVAT);

                setInsertPriceWithoutVAT(calculatedPriceWithoutVAT);
                setInsertTotal(total);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };
    const handleCalculatePrices = async (type) => {
        try {
            if (isNaN(qtyOutput)) {
                throw new Error("Sasia e produkteve duhet të jetë një numër.");
            }

            if (isNaN(insertTotal)) {
                throw new Error("Totali duhet të jetë një numër.");
            }

            if (type === "edit") {
                // Veprimet specifike për rastin e editimit
                const priceWithVAT = Number(formDetailData.Total) / Number(formDetailData.QtyOutput);
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));
                // const tempPriceWithVATEdit = Number(priceWithVAT) / (1 - (Number(formDetailData.DiscountPercent) / 100.00));

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithVAT: priceWithVAT,
                    PriceWithoutVAT: calculatedPriceWithoutVAT,
                }));
            } else {
                // Veprimet specifike për shtim të ri
                const priceWithVAT = Number(insertTotal) / Number(qtyOutput);
                const calculatedPriceWithoutVAT = priceWithVAT * 100 / (100 + Number(vatRates));

                setInsertPriceWithoutVAT(calculatedPriceWithoutVAT);
                setInsertPriceWithVAT(priceWithVAT);
            }
        } catch (error) {
            errorAlert('Gabim ne kalkulim: ' + error.message);
        }
    };

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredArticles(
            articles.filter((data) =>
                data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, articles]);


    const handleInput = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [articlesName, setArticlesName] = useState("");
    const handleFilterArticles = (selectedOption) => {
        if (selectedOption && selectedOption.ArticleID) {
            setArticleID(selectedOption.ArticleID);
            setArticlesName(selectedOption.label);
            fetchArticlesSalesPrice(selectedOption.ArticleID);
        } else {
            setArticleID("");
            setArticlesName("");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'ArticleID':
                    nextField = document.getElementById('QtyOutput');
                    break;
                case 'QtyOutput':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithoutVAT':
                    nextField = document.getElementById('PriceWithVAT');
                    break;
                case 'PriceWithVAT':
                    nextField = document.getElementById('Total');
                    break;
                case 'Total':
                    if (showEditForm === true && showInserForm === false) {
                        handleSubmitDetailLine(e);
                    } else if (showInserForm === true && showEditForm === false) {
                        handleNewSubmit(e);
                    }
                    nextField = document.getElementById('ArticleID');
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select(); // Shtoni këtë rresht për të selektuar tekstin
            }
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        inv: item.InvoiceNr,
        date: (item.Date.substring(0, 10)),
        client: item.AccountName,
        total: item.Total,
        deleteItemId: item.TransactionID, // Shtimi i një atributi për id e rreshtit për fshirje
        rowInfo: item
    }));


    const detailsRows = detailLine.map((item, index) => ({
        id: ++index,
        article: item.ArticleName,
        qty: item.QtyOutput,
        PNoVAT: item.PriceWithoutVAT,
        PVAT: item.PriceWithVAT,
        amountNoVAT: item.Amount,
        amountVAT: item.Total,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));

    //Select list for Clients
    const handleAutocompleteChangeClients = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };



    const defaultPropsClients = {
        options: clients,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                </li>
            </>
        ),
    };

    ////Select list tek Artikulli per editim
    const handleAutocompleteChangeArticleEdit = (event, value) => {
        if (value) {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: value.ArticleID
            }));
            fetchArticlesSalesPrice(value.ArticleID, "edit");
        } else {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: ""
            }));
        }
    };

    const defaultPropsArticleEdit = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };

    //Pjesa per select input field Article
    const [field, setField] = useState(null);
    const handleAutocompleteChangeArticle = (event, value) => {
        if (value && value.ArticleID) {
            setArticleID(value.ArticleID);
            fetchArticlesSalesPrice(value.ArticleID);
            setField(value);
        } else {
            // Në rast se vlera është pastruar
            setArticleID(""); // Vendos vlerën e ArticleID në null
            setField(null);
        }
    };

    const defaultPropsArticle = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        style={{ marginLeft: 'auto' }}
                    >
                        click me
                    </button> */}
                </li>
            </>
        ),
    };

    useEffect(() => {
        fetchData();
        fetchSuppliersDetails();
        // fetchDetailLineData();
        fetchArticles();
        //fetchvaluedetails();
        fetchHeaderData(formData.InvoiceNr);
        fetchCompaniesData();
        fetchArticleDataPrint();
        fetchBanksAccount();
        fetchClients();
    }, [logedEmail, formData.AccountID, formData.InvoiceNr]);

    return (
        // <div className="Crud__Container">
        <div className="AccountingSheet__Container" style={{ paddingTop: "50px" }}>
            {showEditModal && (
                <div className="SalesDetail__Container">
                    <div className="header_flex">
                        <div className="header_icon">
                            <MdEdit style={{ color: '#466F86', width: "40px" }} />
                            <h3>Edito fletë daljen</h3>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="SalesDetail__Container__AllButtons">
                        <div style={{ display: "flex", }}>
                            <div>
                                <InsertClient fetchData={fetchClients} type="insert" />
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <InsertArticle fetchData={fetchArticles} type="insert" />
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "5px" }}>
                                <PrintContent details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyInput" />
                            </div>

                            <div style={{ marginLeft: "5px" }}>
                                <Link to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button className="button-15" variant="contained" onClick={handleSubmit}>Edito</button>

                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="/accounting/exitsheet"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    {/* <button className="button-15" variant="contained">
                                    <FaTimes /> Close
                                </button> */}
                                    <button className="button-15" variant="contained"
                                        onClick={() => {
                                            setShowDetails(true);
                                            setShowEditModal(false);
                                        }}
                                    >
                                        Mbyll
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='header_paper2'></div>
                    <div className="edit">
                        <Paper style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <form className='header_paper'>
                                <Box
                                    // component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className='SalesDetail__Container__Form'>
                                        <div className="SalesDetail__Container__Form__div">
                                            <div className="SalesDetail__Container__Form__div__1">
                                                <div className="SalesDetail__Container__Form__div__2">
                                                    <TextField
                                                        className="SalesDetail__Container__Form__TextField__1"
                                                        id="invoiceNR"
                                                        label="Invoice Number"
                                                        variant="outlined"
                                                        type="text"
                                                        name='InvoiceNr'
                                                        value={formData.InvoiceNr}
                                                        onChange={handleInputChange}
                                                    />
                                                    <TextField
                                                        className="SalesDetail__Container__Form__TextField__1"
                                                        id="id"
                                                        select
                                                        name="TransactionType"
                                                        label="Select Transaction Type"
                                                        value={formData.TransactionType}
                                                        onChange={handleInputChange}
                                                    >
                                                        {TranscationData.map((data) => (
                                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <TextareaAutosize style={{ height: "70px", marginLeft: "10px" }}
                                                        className="SalesDetail__Container__Form__TextField__1"
                                                        id="Coment"
                                                        label="Coment"
                                                        type="text"
                                                        name='Coment'
                                                        value={formData.Coment}
                                                        onChange={handleInputChange}
                                                        variant="outlined"

                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Form__TextField__date__Payment">
                                                    <TextField
                                                        className="SalesDetail__Container__Form__TextField__date"
                                                        id="date"
                                                        type="date"
                                                        variant="outlined"
                                                        label="Invoice Date"
                                                        name='Date'
                                                        value={formData.Date.substring(0, 10)}
                                                        onChange={handleInputChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="SalesDetail__Container__Form__div__4">
                                            <div>
                                                {/* <TextField className="SalesDetail__Container__Form__TextField__2"
                                                    // style={{ width: "96%" }}
                                                    id="AccountID"
                                                    name="AccountID"
                                                    select
                                                    label="Select Client"
                                                    value={formData.AccountID}
                                                    onChange={handleInputChange}
                                                >
                                                    {clients.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    fullWidth
                                                    // sx={{ m: 2 }}
                                                    {...defaultPropsClients}
                                                    id="AccountID"
                                                    includeInputInList
                                                    onChange={handleAutocompleteChangeClients}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            label="Klienti"
                                                            // variant="filled"
                                                            className="SalesDetail__Container__Form__TextField__2"
                                                        />
                                                    )}
                                                    value={clients.find(option => option.AccountID === formData.AccountID) || null}
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__IDNumber"
                                                    // style={{ width: "49%" }}
                                                    id="outlined-basic"
                                                    label="Identification Number"
                                                    variant="outlined"
                                                    name="IdentificationNumber"
                                                    value={supplierdetails.length > 0 ? supplierdetails[0].IdentificationNumber : ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    readOnly
                                                    autoComplete="off"
                                                />
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__IDNumber"
                                                    // style={{ width: "49%" }}
                                                    id="outlined-basic"
                                                    label="VAT Number"
                                                    variant="outlined"
                                                    name="VatNumber"
                                                    value={supplierdetails.length > 0 ? supplierdetails[0].VATNumber : ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    readOnly
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__2"
                                                    // style={{ width: "96%" }}
                                                    id="outlined-basic"
                                                    label="Address"
                                                    variant="outlined"
                                                    name="Address"
                                                    value={supplierdetails.length > 0 ? supplierdetails[0].Adress : ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    readOnly
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </form>
                            <div style={{ backgroundColor: "gainsboro " }}>
                                <hr></hr>
                                {showInserForm && (
                                    <div className="SalesDetail__Container">
                                        <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                {/* <p style={{ color: "blue", fontSize: "16px", fontWeight: "bold", borderTop: "1px dashed blue" }}>Please fill in all the required fields.</p> */}
                                                <div className="SalesDetail__Container__Second__Form">
                                                    <div className="SalesDetail__Container__Second__Form__Article">
                                                        {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Select Article</span>
                                                        <Select
                                                            className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                            inputId="ArticleID"
                                                            value={{ label: articlesName, value: articleID }}
                                                            options={filteredArticles.map((data) => ({
                                                                label: data.ArticleName,
                                                                value: data.ArticleID,
                                                                ArticleID: data.ArticleID
                                                            }))}
                                                            onInputChange={handleInput}
                                                            onChange={handleFilterArticles}
                                                            isClearable
                                                            placeholder="Select"
                                                            noOptionsMessage={() => 'No matching'}
                                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    height: "55px",
                                                                    marginLeft: "10px",
                                                                    marginRight: "12px",
                                                                    backgroundColor: "transparent",
                                                                    border: "darkgray solid 1px"
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: "#fff",
                                                                    zIndex: 9999,
                                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                    borderRadius: "4px",
                                                                }),
                                                                menuList: (provided) => ({
                                                                    ...provided,
                                                                    maxHeight: "200px",
                                                                    overflowY: "auto",
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: "#000",
                                                                }),
                                                            }}
                                                        /> */}
                                                        <Autocomplete
                                                            fullWidth
                                                            {...defaultPropsArticle}
                                                            id="ArticleID"
                                                            sx={{ m: 1.5 }}
                                                            includeInputInList
                                                            value={field}
                                                            onChange={handleAutocompleteChangeArticle}
                                                            isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Kerko Artikullin"
                                                                // variant="filled"
                                                                // onKeyPress={handleKeyPress}
                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                style: {
                                                                    maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="QtyOutput"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Qty Output"
                                                                name='QtyOutput'
                                                                value={qtyOutput}
                                                                onChange={(e) => setQtyOutput(e.target.value)}
                                                                onKeyUp={handleCalculatePriceWithoutVAT}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Second__Form__Price"
                                                                id="PriceWithoutVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Price Without VAT"
                                                                name="PriceWithoutVAT"
                                                                value={typeof insertpriceWithoutVAT === 'number' ? insertpriceWithoutVAT.toFixed(4) : insertpriceWithoutVAT}
                                                                onChange={(e) => setInsertPriceWithoutVAT(e.target.value)}
                                                                onKeyUp={handleCalculatePriceWithVAT}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Second__Form__Price"
                                                                id="PriceWithVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Price With VAT"
                                                                name="PriceWithVAT"
                                                                autoComplete="off"
                                                                value={typeof insertPriceWithVAT === 'number' ? insertPriceWithVAT.toFixed(4) : insertPriceWithVAT}
                                                                onChange={(e) => setInsertPriceWithVAT(e.target.value)}
                                                                onKeyUp={handleCalculatePriceWithoutVAT}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Second__Form__Price"
                                                                id="Total"
                                                                label="Total"
                                                                variant="outlined"
                                                                name='Total'
                                                                value={typeof insertTotal === 'number' ? insertTotal.toFixed(2) : insertTotal}
                                                                onChange={event => setInsertTotal(event.target.value)}
                                                                onKeyUp={handleCalculatePrices}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        <div className="responsive">
                                                            <div className="responsive__butonat">
                                                                <div>
                                                                    <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-plus-lg"></i>
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-x-lg"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </form>
                                    </div>
                                )}
                                {showEditForm && (
                                    <div className="SalesDetail__Container">
                                        <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="SalesDetail__Container__Second__Form">
                                                    <div className="SalesDetail__Container__Second__Form__Article">
                                                        {/* <TextField
                                                            className="SalesDetail__Container__Form__TextField__Article"
                                                            id="ArticleID"
                                                            select
                                                            label="Select Article"
                                                            name="ArticleID"
                                                            value={formDetailData.ArticleID}
                                                            autoComplete="off"
                                                            // onChange={handleInputChangeDetail}
                                                            onChange={(event) => {
                                                                handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
                                                                fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
                                                            }}
                                                        // onKeyUp={handleCalculatePriceWithoutVATEdit}
                                                        >
                                                            {articles.map((data) => (
                                                                <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField> */}
                                                        <Autocomplete
                                                            fullWidth
                                                            sx={{ m: 1.5 }}
                                                            {...defaultPropsArticleEdit}
                                                            id="ArticleID"
                                                            includeInputInList
                                                            onChange={handleAutocompleteChangeArticleEdit}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    label="Artikulli"
                                                                // variant="filled" 
                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                style: {
                                                                    maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                                },
                                                            }}
                                                            value={articles.find(option => option.ArticleID === formDetailData.ArticleID) || null}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                type="text"
                                                                id="QtyOutput"
                                                                name="QtyOutput"
                                                                label="Qty Output"
                                                                variant="outlined"
                                                                autoComplete="off"
                                                                value={formDetailData.QtyOutput}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithoutVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Price Without VAT"
                                                                name="PriceWithoutVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithVAT("edit")}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Price With VAT"
                                                                name="PriceWithVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithVAT === 'number' ? formDetailData.PriceWithVAT.toFixed(4) : formDetailData.PriceWithVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="Total"
                                                                label="Total"
                                                                variant="outlined"
                                                                name='Total'
                                                                autoComplete="off"
                                                                value={typeof formDetailData.Total === 'number' ? formDetailData.Total.toFixed(2) : formDetailData.Total}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculatePrices("edit")}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-check-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        <div className="responsive">
                                                            <div className="responsive__butonat">
                                                                <div>
                                                                    <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-check-lg"></i>
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-x-lg"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </form>
                                    </div>
                                )}
                                <DataGridWithActions
                                    columns={detailsData}
                                    rows={detailsRows}
                                    handleEdit={handleEditDetails}
                                    handleDelete={handleDeleteDetailLine}
                                    showPayment={false}
                                />
                            </div>
                            <br></br>
                            <div className="responsive__Tablet">
                                <div className='SalesDetail__Container__Form'>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount18"
                                            // style={{ width: "100%" }}
                                            id="Amount18"
                                            label="Amount 18"
                                            variant="outlined"
                                            type="text"
                                            name='Amount18'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount18).toFixed(2) : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT18"
                                            // style={{ width: "100%" }}
                                            id="VAT18"
                                            label="VAT 18"
                                            variant="outlined"
                                            type="text"
                                            name='VAT18'
                                            value={valuedetails.length > 0 ? (valuedetails[0].VAT18).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount8"
                                            // style={{ width: "100%" }}
                                            id="Amount8"
                                            label="Amount 8"
                                            variant="outlined"
                                            type="text"
                                            name='Amount8'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount8).toFixed(2) : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT8"
                                            // style={{ width: "100%" }}
                                            id="VAT8"
                                            label="VAT 8"
                                            variant="outlined"
                                            type="text"
                                            name='VAT8'
                                            value={valuedetails.length > 0 ? (valuedetails[0].VAT8).toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    <div style={{ marginLeft: "20px" }}>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Amount0"
                                            // style={{ width: "100%" }}
                                            id="Amount0"
                                            label="Amount 0"
                                            variant="outlined"
                                            type="text"
                                            name='Amount0'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount0).toFixed() : ''}
                                            disabled
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VAT0"
                                            // style={{ width: "100%" }}
                                            id="VAT0"
                                            label="VAT 0"
                                            variant="outlined"
                                            type="text"
                                            name='VAT0'
                                            value={valuedetails.length > 0 ? 0 : ''}
                                            disabled
                                        />
                                    </div>
                                    <br></br>
                                    {/* <div>
                                        <TextField
                                            style={{ width: "81%" }}
                                            id="DiscountAmount"
                                            label="Zbritja në vlerë"
                                            variant="outlined"
                                            type="text"
                                            name="DiscountAmount"
                                            value={discountAmount}
                                            onChange={(e) => { setDiscountAmount(e.target.value); calculatePercent(e.target.value); }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip title="Apliko Zbritjen" placement="right">
                                                            <IconButton type="submit"
                                                                onClick={constUpdateDiscountAmount}
                                                            >
                                                                <i class="bi bi-check-lg" style={{ color: "green" }}></i>

                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__TotalAmount"
                                            id="TotalAmount"
                                            label="Total pa TVSH"
                                            variant="outlined"
                                            type="text"
                                            name='TotalAmount'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toFixed(2) : ''}
                                            readOnly
                                        />
                                    </div> */}

                                    <br></br>
                                    <div>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__VATTotal"
                                            // style={{ width: "100%" }}
                                            id="VATTotal"
                                            label="Total TVSH"
                                            variant="outlined"
                                            type="text"
                                            name='VATTotal'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
                                            readOnly
                                        />
                                        <br></br>
                                        <br></br>
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Total"
                                            // style={{ width: "100%" }}
                                            id="Totali"
                                            label="Për pagesë"
                                            variant="outlined"
                                            type="text"
                                            name='Totali'
                                            value={valuedetails.length > 0 ? (valuedetails[0].Total).toFixed(2) : ''}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div >
            )}
            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillShopping style={{}} className="header_icons" />
                            <h3>Fletë dalje</h3>
                        </div>
                        <div>
                            <Link to={"/accounting/insertexitsheet"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Krijo fletë daljen
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e fletë daljeve: Menaxhoni, shtoni, editoni dhe fshini fletë daljen.</p>
                    </div>
                    <DataGridWithActions
                        columns={mainGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={false}
                        height={500}
                    />
                </div>
            )}
        </div >

    )

}