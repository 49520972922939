import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import '../../Style/__button.scss'

function InsertCity(props) {
    const [cName, setCName] = useState("");
    const [sName, setSName] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [states, setStates] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const headers = authHeader();
        const stateData = {
            CityName: cName,
            StateID: sName,
            CompanyID: 123456789,
            IsActive: isActive,
        };
        axios.post(ApiLink.API_URL + 'cities', stateData, {headers})
            .then((response) => {
                console.log(response.data);
                handleSuccess("City Added Successfully");
                setCName("");
                setSName("");
                setIsActive(true);
                props.onHide();
                props.refreshData(); // Thirrja e funksionit per refresh te dhenave

            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    };

    useEffect(() => {
        const headers = authHeader();
        axios.get(ApiLink.API_URL + 'states', {headers})
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Insert new City
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-3 col-sm-3">City Name </span>
                            <input
                                type="text"
                                className="form-control"
                                value={cName}
                                onChange={event => setCName(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <label className="input-group-text col-3 col-sm-3" htmlFor="stateSelect">State Name </label>
                            <select
                                id="stateSelect"
                                className="form-control"
                                value={sName}
                                onChange={event => setSName(event.target.value)}
                                required
                            >
                                <option value="">Select State</option>
                                {states.map(state => 
                                    <option key={state.StateID} value={state.StateID}>{state.StateName}</option>
                                )}
                            </select>
                        </div>

                        <label>
                            <input
                                type="checkbox"
                                checked={isActive}
                                onChange={(event) => setIsActive(event.target.checked)}
                            />
                            Active
                        </label>
                        <button type="submit"
                            className="btn btn-primary float-end"
                            style={{ background: "#7a95a6" }}
                        >Save</button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };

    return (
        <>
            <button className="button-68" onClick={() => setModalShow(true)}>
            <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Add New City
            </button>

            <InsertCity
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}