const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const AccountingSheetGridHeader = [
    { field: 'id', headerName: '#', width: 60, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'inv', headerName: 'Numri i Fatures', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'description', headerName: 'Përshkrimi', width: 660, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'total', headerName: 'Totali', type: 'string', width: 160, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

export const detailsData = [
    { field: 'id', headerName: '#', width: 40, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'ledger', headerName: 'Ledger', width: 280, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'account', headerName: 'Llogaria', width: 280, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'comment', headerName: 'Koment', width: 280, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'debi', headerName: 'Debi', type: 'string', width: 145, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'credi', headerName: 'Kredi', type: 'string', width: 145, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]