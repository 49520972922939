import "./Style/__sidebar.scss";
import React from 'react';
import SideMenu from "../src/SidebarMenu/SideMenu";
import Header from "../src/SidebarMenu/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import VatRates from "./CRUD/VatRates/VatRates";
import Homee from "../src/dashboard/pages/home/Home";
import Login from './Auth/Login';
import States from "./CRUD/States/States";
import Cities from "./CRUD/Cities/Cities";
import ArticleGroups from "./CRUD/ArticleGroups/ArticleGroups";
import ArticleUnits from "./CRUD/ArticleUnits/ArticleUnits";
import Ledgers from "./CRUD/Ledgers/Ledgers";
import Article from "./CRUD/Article/Article";
import Users from "./CRUD/Users/Users";
import Accounts from "./CRUD/Accounts/Accounts";
import Clients from "./CRUD/Clients/Clients";
import Suppliers from "./CRUD/Suppliers/Suppliers";
import Banks from "./CRUD/Banks/Banks";
import CashRegister from "./CRUD/CashRegister/CashRegister";
import Owners from "./CRUD/Owners/Owners";
import Employees from "./CRUD/Employees/Employees";
import Tenants from "./CRUD/Tenants/Tenants";
import LicencedBanks from "./CRUD/LicencedBanks/LicencedBanks";
import Companies from "./CRUD/Companies/Companies";
import CompanyUnit from "./CRUD/CompanyUnit/CompanyUnit";
import Expence from "./CRUD/Expenses/Expences";
import LongTermAssets from "./CRUD/LongTermAssets/LongTermAssets";
import ExpensePurchases from "./CRUD/ExpensePurchases/ExpensePurchases";
import AccountReports from "./CRUD/Accounting Reports/AccountsReport";
import BanksReport from "./CRUD/Accounting Reports/BanksReport";
import AccountsReceivable from "./CRUD/Accounting Reports/AccountsReceivable";
import MoneyInCash from "./CRUD/Accounting Reports/MoneyInCash";
import AddExpensePurchases from "../src/CRUD/ExpensePurchases/CreateAddExpencePurchase";
import EditPurchaseExpence from "./CRUD/ExpensePurchases/EditPurchaseExpence";
import SalesInTotal from "./CRUD/SalesInTotal/SalesInTotal";
import AddSalesInTotal from "./CRUD/SalesInTotal/AddSalesInTotal";
import InvestmentPurchases from "./CRUD/InvestmentPurchases/InvestmentPurchases";
import AddInvestmentPurchase from "./CRUD/InvestmentPurchases/AddInvestmentPurchase";
import DebitNote from "./CRUD/DebitNote/DebitNote";
import InsertDebitNote from "./CRUD/DebitNote/InsertDebitNote";
import CreditNote from "./CRUD/CreditNote/CreditNote";
import InsertCreditNote from "./CRUD/CreditNote/InsertCreditNote";
import AccountsPartners from "./CRUD/Accounting Reports/AccountsPartners";
import PurchaseInTotal from "./CRUD/PurchaseInTotal/PurchaseInTotal";
import AddPurchaseInTotal from "./CRUD/PurchaseInTotal/AddPurchaseInTotal";
import Advance from "./CRUD/Advance/Advance";
import AddAdvance from "./CRUD/Advance/AddAdvance";
import Profile from "./CRUD/Profile/Profile";
import AccountingSheets from "./CRUD/AccountingSheet/AccountingSheets";
import AddAccountingSheet from "./CRUD/AccountingSheet/AddAccountingSheet";
import Rent from "./CRUD/Rent/Rent";
import AddRent from "./CRUD/Rent/AddRent";
import SalesBook from "./CRUD/AccountingBooks/SalesBook";
import ExpenceBook from "./CRUD/AccountingBooks/ExpenceBook";
import PurchaseBook from "./CRUD/AccountingBooks/PurchaseBook";
import InvestmentBook from "./CRUD/AccountingBooks/InvestmentBook";
import PurchaseDetail from "./CRUD/PurchaseDetail/PurchaseDetail";
import AddPurchaseDetail from "./CRUD/PurchaseDetail/AddPurchaseDetail";
import SalesInDetails from "./CRUD/SalesInDetails/SalesInDetails";
import AddSalesInDetails from "./CRUD/SalesInDetails/AddSalesInDetails";
import PrintContent from "./Print/PrintContent";
import TaxSalesBook from "./CRUD/Tax Reports/TaxSalesBook";
import TaxPurchaseBook from "./CRUD/Tax Reports/TaxPurchaseBook";
import VATDeclaration from "./CRUD/Tax Reports/VATDeclaration";
import TrialBalance from "./CRUD/Finance Statements/TrialBalance";
import BalanceSheet from "./CRUD/Finance Statements/BalanceSheet";
import IncomeStatements from "./CRUD/Finance Statements/IncomeStatements";
import JournalHistory from "./CRUD/Accounting Reports/JournalHistory";
import AnnualDeclaration from "./CRUD/Tax Reports/AnnualDeclaration";
import Inventory from "./CRUD/Accounting Reports/Inventory";
import CoverPage from "./Print/CoverPage";
import Z_SalesDeail from "./CRUD/Z_SalesDetail/Z_SalesDetail";
import Insert_ZSalesDetail from "./CRUD/Z_SalesDetail/InsertZ_SalesDetail";
import AddReceivables from "./CRUD/Receivables/AddReceivables";
import Receivables from "./CRUD/Receivables/Receivables";
import Payments from "./CRUD/Payments/Payments";
import AddPayments from "./CRUD/Payments/AddPayments";
import Withdrawal from "./CRUD/Withdrawal/Withdrawal";
import AddWithdrawal from "./CRUD/Withdrawal/AddWithdrawal";
import Transfers from "./CRUD/Transfers/Transfers";
import AddTransfer from "./CRUD/Transfers/AddTransfers";
import LinkInvoice from "./pages/LinkInvoice";
import FletHyrje from "./CRUD/FletHyrja/FletHyrje";
import InsertEntrySheet from "./CRUD/FletHyrja/InsertEntrySheet";
import FletDalja from "./CRUD/FletëDalja/FletDalja";
import InsertExitSheet from "./CRUD/FletëDalja/InsertExitSheet";
import Wages from "./CRUD/Wages/Wages";
import AddWages from "./CRUD/Wages/AddWages";
import AddProInvoices from "./CRUD/Pro Invoices/AddProInvoices";
import ProInvoices from "./CRUD/Pro Invoices/ProInvoices";
import Oferta from "./CRUD/Oferta/Oferta";
import AddOferta from "./CRUD/Oferta/AddOferta";
import Import from "./CRUD/Import/Import";
import AddImport from "./CRUD/Import/AddImport";
import MonthlySalesReport from "./CRUD/Finance Statements/MonthlySalesReport";
import MonthlyPurchaseReport from "./CRUD/Finance Statements/MonthlyPurchaseReport";
import MonthlyExpenseReport from "./CRUD/Finance Statements/MonthlyExpenseReport";
import MonthlyWagesReport from "./CRUD/Finance Statements/MonthlyWagesReport";
import MonthlyRentReport from "./CRUD/Finance Statements/MonthlyRentReport";
import MonthlyExpenseClassReport from "./CRUD/Finance Statements/MonthlyExpenseClassReport";
import POS from "./CRUD/POS/POS";
import AddPos from "./CRUD/POS/AddPos";



const Dashboard = () => <Homee />;

const ArticlesList = () => <Article />;
const ArticleGroupCruds = () => <ArticleGroups />;
const ArticleUnitCruds = () => <ArticleUnits />;
const VATRatesCruds = () => <VatRates />;
const StatesCrud = () => <States />;
const CitiesCrud = () => <Cities />

const AccountsList = () => <Accounts />;
const ClientsList = () => <Clients />;
const SuppliersList = () => <Suppliers />;
const BankList = () => <Banks />;
const CashRegisterList = () => <CashRegister />
const OwnerList = () => <Owners />
const EmployeesList = () => <Employees />
const TenantsList = () => <Tenants />
const LicencedBanksList = () => <LicencedBanks />
const CompaniesList = () => <Companies />;
const CompanyUnitLists = () => <CompanyUnit />;
const ExpenceList = () => <Expence />;
const LongTermAssetsList = () => <LongTermAssets />;
const ExpensePurchasesList = () => <ExpensePurchases />;
const PurchaseInTotalList = () => <PurchaseInTotal />;
const SalesInTotalList = () => <SalesInTotal />;
const InvestmentPurchasesList = () => <InvestmentPurchases />
const DebitNoteList = () => <DebitNote />
const CreditNoteList = () => <CreditNote />
const AdvanceList = () => <Advance />
const AccountingSheetList = () => <AccountingSheets />
const SalesBookList = () => <SalesBook />
const ExpenceBookList = () => <ExpenceBook />
const PurchaseBookList = () => <PurchaseBook />
const InvestmentBookList = () => <InvestmentBook />
const RentList = () => <Rent />
const PurchaseDetailList = () => <PurchaseDetail />
const SalesInDetailsList = () => <SalesInDetails />


//Raports
// const TrialBalanceList = () => <TrialBalance />;
const AccountReportsList = () => <AccountReports />
const BanksReportList = () => <BanksReport />
const AccountsReceivableList = () => <AccountsReceivable />
const MoneyInCashList = () => <MoneyInCash />

const UsersList = () => <Users />;

const Loggin = () => <Login />


function App() {
  const [inactive, setInactive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  useEffect(() => {
    if (!isLoggedIn) {
      setInactive(true);
    }
  }, [isLoggedIn]);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          {/* Rruga për Invoice e cila shfaqet pa Sidebar dhe Header */}
          <Route path={"/invoice"} render={() => <LinkInvoice />} />
          <Route path={"/Sales/addPos"} render={() => <AddPos />} />

          {/* Kontrolli i Logimit për të tjerat */}
          {!isLoggedIn ? (
            <Route path="/" component={() => <Loggin onLoginSuccess={handleLoginSuccess} />} />
          ) : (
            <div>
              <SideMenu onCollapse={(inactive) => setInactive(inactive)} />
              <Header />
              <div className={`container ${inactive ? "inactive" : ""}`}>
                {/* Vendosni këtu të gjitha rrugët tjera që duan Sidebar dhe Header */}
                <Route exact path={"/dashboard"} render={() => <Dashboard />} />

                {/* Article Menu------------------------------------------------------------------ */}
                {/* <Route path={"/articles/articleslist"} component={ArticlesList} /> */}
                <Route path={"/articles/articleslist"} render={() => <ArticlesList />} />

                {/* <Route path={"/articles/articlegroups"} component={ArticleGroupCruds} /> */}
                <Route path={"/articles/articlegroups"} render={() => <ArticleGroupCruds />} />

                <Route path={"/articles/articleunits"} component={ArticleUnits} />
                <Route path={"/articles/units"} component={ArticleUnitCruds} />

                {/* Accounts Menu------------------------------------------------------------------ */}
                <Route path={"/accounts/others"} component={AccountsList} />
                <Route path={"/accounts/clients"} component={ClientsList} />
                <Route path={"/accounts/suppliers"} component={SuppliersList} />
                <Route path={"/accounts/banks"} component={BankList} />
                <Route path={"/accounts/cashregisters"} component={CashRegisterList} />
                <Route path={"/accounts/owners"} component={OwnerList} />
                <Route path={"/accounts/employees"} component={EmployeesList} />
                <Route path={"/accounts/thetenants"} component={TenantsList} />
                <Route path={"/accounts/expences"} component={ExpenceList} />
                <Route path={"/accounts/longtermassets"} component={LongTermAssetsList} />

                {/* Purchase Menu------------------------------------------------------------------ */}
                <Route path={"/purchases/addexpensepurchases/edit"} component={EditPurchaseExpence} />
                <Route path={"/purchases/purchaseintotal"} component={PurchaseInTotalList} />
                <Route path={"/purchases/addpurchaseintotal"} component={AddPurchaseInTotal} />
                <Route path={"/purchases/investmentpurchases"} component={InvestmentPurchasesList} />
                <Route path={"/purchases/addInvestmentPurchase"} component={AddInvestmentPurchase} />

                <Route path={"/purchases/detailpurchase"} component={PurchaseDetailList} />
                <Route path={"/purchases/addpurchaseinvoice"} component={AddPurchaseDetail} />
                <Route path={"/purchases/import"} render={() => <Import />} />
                <Route path={"/purchases/addImport"} render={() => <AddImport />} />




                {/* Expence Menu------------------------------------------------------------------ */}
                <Route path={"/expences/expensepurchases"} component={ExpensePurchasesList} />
                <Route path={"/expences/addexpensepurchases"} component={AddExpensePurchases} />
                <Route path={"/expences/rent"} component={RentList} />
                <Route path={"/expences/AddRent"} component={AddRent} />
                <Route path={"/expences/wages"} render={() => <Wages />} />
                <Route path={"/expences/AddWages"} render={() => <AddWages />} />

                {/* Sales Menu------------------------------------------------------------------ */}
                <Route path={"/Sales/salesintotal"} component={SalesInTotalList} />
                <Route path={"/Sales/addsalesintotal"} component={AddSalesInTotal} />
                <Route path={"/Sales/advance"} component={AdvanceList} />
                <Route path={"/Sales/addadvance"} component={AddAdvance} />
                <Route path={"/Sales/debitnote"} component={DebitNoteList} />
                <Route path={"/Sales/adddebitnote"} component={InsertDebitNote} />
                <Route path={"/Sales/creditnote"} component={CreditNoteList} />
                <Route path={"/Sales/addcreditnote"} component={InsertCreditNote} />
                <Route path={"/Sales/salesindetails"} component={SalesInDetailsList} />
                <Route path={"/Sales/Addsalesindetails"} component={AddSalesInDetails} />
                {/* <Route path={"/Sales/Addsalesindetails"} component={AddSalesInDetails} /> */}
                <Route path={"/Sales/Z-salesdetail"} render={() => <Z_SalesDeail />} />
                <Route path={"/Sales/add-Z-sales"} render={() => <Insert_ZSalesDetail />} />
                <Route path={"/Sales/proInvoice"} render={() => <ProInvoices />} />
                <Route path={"/Sales/addProInvoice"} render={() => <AddProInvoices />} />
                <Route path={"/Sales/offer"} render={() => <Oferta />} />
                <Route path={"/Sales/Addoffer"} render={() => <AddOferta />} />
                <Route path={"/Sales/pos"} render={() => <POS />} />
                {/* <Route path={"/Sales/addPos"} render={() => <AddPos />} /> */}




                {/* Reports Menu------------------------------------------------------------------ */}
                <Route path={"/Reports/accountspayable"} component={AccountReportsList} />
                <Route path={"/Reports/accountsreceivable"} component={AccountsReceivableList} />
                <Route path={"/Reports/moneybank"} component={BanksReportList} />
                <Route path={"/Reports/cashmoney"} component={MoneyInCashList} />
                <Route path={"/Reports/accountspartners"} component={AccountsPartners} />

                {/* Accounting Menu------------------------------------------------------------------ */}
                <Route path={"/accounting/accountingsheets"} component={AccountingSheetList} />
                <Route path={"/accounting/addaccountingsheets"} component={AddAccountingSheet} />
                <Route path={"/accounting/entrysheet"} render={() => <FletHyrje />} />
                <Route path={"/accounting/insertentrysheet"} render={() => <InsertEntrySheet />} />
                <Route path={"/accounting/exitsheet"} render={() => <FletDalja />} />
                <Route path={"/accounting/insertexitsheet"} render={() => <InsertExitSheet />} />




                {/* Money--------------------------------------------------------------------------- */}
                <Route path={"/money/receivables"} render={() => <Receivables />} />
                <Route path={"/money/addreceivables"} render={() => <AddReceivables />} />
                <Route path={"/money/payments"} render={() => <Payments />} />
                <Route path={"/money/addpayments"} render={() => <AddPayments />} />
                <Route path={"/money/withdrawal"} render={() => <Withdrawal />} />
                <Route path={"/money/addwithdrawal"} render={() => <AddWithdrawal />} />
                <Route path={"/money/transfers"} render={() => <Transfers />} />
                <Route path={"/money/addtransfers"} render={() => <AddTransfer />} />


                {/* Configuration Menu--------------------------------------------------------------*/}
                <Route path={"/configuration/vatrates"} component={VATRatesCruds} />
                <Route path={"/configuration/states"} component={StatesCrud} />
                <Route path={"/configuration/cities"} component={CitiesCrud} />
                <Route path={"/configuration/ledgers"} component={Ledgers} />
                <Route path={"/configuration/licencedbanks"} component={LicencedBanksList} />
                <Route path={"/configuration/company"} component={CompaniesList} />
                <Route path={"/configuration/companyunits"} component={CompanyUnitLists} />
                <Route path={"/configuration/users"} component={UsersList} />
                <Route path={"/configuration/profile"} component={Profile} />
                <Route path={"/configuration/coverpage"} render={() => <CoverPage />} />


                {/* Accounting Books--------------------------------------------------------------*/}
                <Route path={"/accountingbooks/salesbook"} component={SalesBookList} />
                <Route path={"/accounting/expencebook"} component={ExpenceBookList} />
                <Route path={"/accounting/purchasebook"} component={PurchaseBookList} />
                <Route path={"/accounting/investmentbook"} component={InvestmentBookList} />
                <Route path={"/accounting/journalhistory"} render={() => <JournalHistory />} />
                <Route path={"/accounting/inventory"} render={() => <Inventory />} />



                {/* Tax Reports-----------------------------------------------------------------  */}
                <Route path={"/tax/salesbook"} render={() => <TaxSalesBook />} />
                <Route path={"/tax/purchasebook"} render={() => <TaxPurchaseBook />} />
                <Route path={"/tax/vatdeclaration"} render={() => <VATDeclaration />} />
                <Route path={"/tax/annualdeclaration"} render={() => <AnnualDeclaration />} />

                {/* Finance Statement------------------------------------------------------------  */}
                <Route path={"/financestatements/TrialBalance"} render={() => <TrialBalance />} />
                <Route path={"/financestatements/balancesheet"} render={() => <BalanceSheet />} />
                <Route path={"/financestatements/incomestatements"} render={() => <IncomeStatements />} />
                <Route path={"/financestatements/MonthlySalesReport"} render={() => <MonthlySalesReport />} />
                <Route path={"/financestatements/MonthlyPurchaseReport"} render={() => <MonthlyPurchaseReport />} />
                <Route path={"/financestatements/MonthlyExpenseReport"} render={() => <MonthlyExpenseReport />} />
                <Route path={"/financestatements/MonthlyWagesReport"} render={() => <MonthlyWagesReport />} />
                <Route path={"/financestatements/MonthlyRentReport"} render={() => <MonthlyRentReport />} />
                <Route path={"/financestatements/MonthlyExpenseClassReport"} render={() => <MonthlyExpenseClassReport />} />

                <Route path={"/print"} component={<PrintContent />} />
              </div>
            </div>
          )}
        </Switch>
      </Router>
    </div>
  );
}



export default App;
