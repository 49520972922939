import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { FaPrint } from "react-icons/fa";
import { errorAlert } from '../Components/Alerts';
import Logo from '../Images/CompanyLogos/18.jpg';
import { ApiLink } from '../APILink/ApiLink';
import authHeader from '../Auth/authHeader';
import { Documents, Periudha } from '../OtherData/CoverData';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export default function CoverPage(props) {
    const headers = authHeader();
    const [companyName, setCompanyName] = useState("");
    const [nui, setNUI] = useState("");
    const [vatNR, setVatNr] = useState("");
    const [adress, setAdress] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [logo, setLogo] = useState("");
    const [document, setDocument] = useState("");
    const [periudha, setPeriudha] = useState("");

    const vitAktual = new Date().getFullYear();

    const tableRef = useRef();

    useEffect(() => {
        fetchCompaniesData();
    }, []);

    const handleBeforePrint = () => {
        // Shtoni stilet e modalitetit të printimit me një vonesë
        setTimeout(() => {
            const styleTag = document.createElement('style');
            styleTag.innerHTML = '.printLogo { display: block !important; }';
            document.head.appendChild(styleTag);
        }, 100);
    };

    const handleAfterPrint = () => {
        // Hiqni stilet e modalitetit të printimit pasi të përfundojë printimi me një vonesë
        setTimeout(() => {
            const styleTag = document.querySelector('style');
            if (styleTag) {
                document.head.removeChild(styleTag);
            }
        }, 100);
    };

    // const handlePrint = () => {
    //     const table = tableRef.current;
    //     const tableClone = table.cloneNode(true);
    //     const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
    //     printWindow.document.write(tableClone.outerHTML);
    //     printWindow.document.close();
    //     printWindow.focus();
    //     printWindow.print();
    //     printWindow.close();
    // };

    const handlePrint = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();

        // Shtoni një event listener për beforeunload që do të shkaktohet kur modaliteti i printimit mbyllet
        window.addEventListener('beforeunload', function () {
            // Bëni refresh të modalitetit të printimit në background
            const newPrintWindow = window.open('', 'PrintWindow', 'height=400,width=600');
            newPrintWindow.document.close();
            newPrintWindow.focus();
            newPrintWindow.print();
            newPrintWindow.close();
        });
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompanyName(response.data[0].CompanyName)
            setNUI(response.data[0].IdentificationNumber)
            setVatNr(response.data[0].VATNumber)
            setAdress(response.data[0].Address)
            setTelephone(response.data[0].PhoneNumbers)
            setEmail(response.data[0].Email)
            setLogo(response.data[0].CompanyLogo)


        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    return (
        <div>
            <div style={{ display: "none" }}>
                {/* divi per header */}
                <div ref={tableRef} style={{ width: "90%", height: "auto", padding: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
                        <div>
                            {logo ? (
                                <img src={ApiLink.PHOTO_URL + logo} style={{ width: "150px", height: "150px" }} className="printLogo" />
                            ) : (
                                <span>No logo available</span>
                            )}
                        </div>

                        <div style={{ textAlign: "right" }} className="text-container">
                            <h4 style={{ marginBottom: "-20px" }}><b>{companyName} </b></h4>
                            <h6 style={{ marginBottom: "-20px" }}>NUI:{nui}</h6>
                            <h6 style={{ marginBottom: "-20px" }}>Vat Number:{vatNR}</h6>
                            <h6 style={{ marginBottom: "-20px" }}>Adress:{adress}</h6>
                            <h6 style={{ marginBottom: "-20px" }}>Phone:{telephone}</h6>
                            <h6 style={{ marginBottom: "-20px" }}>Email:{email}</h6>
                        </div>
                    </div>
                    <hr></hr>
                    {/* divi per document */}
                    <div style={{ backgroundColor: "gray", textAlign: "center", marginTop: "20%", padding: "3px" }}>
                        <h1>{document}</h1>
                    </div>
                    {/* divi per periudhen */}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <h3>{periudha}</h3>
                    </div>
                    {/* divi per footer */}

                    <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "lightgray", padding: "3px", marginTop: "40%" }}>
                        <p>Adress:{adress}</p>
                        <p>Phone: {telephone}</p>
                        <p>Email:{email}</p>
                    </div>

                    <div style={{display:"flex", justifyContent:"flex-end"}}>
                        <p>© Acctree | www.acctree.net</p>
                    </div>

                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "20%", paddingLeft: "40%", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", alignContent: "center", width: "100%" }}>
                    <TextField
                        style={{ width: "55%" }}
                        id=""
                        select
                        label="Zgjedh Dokumentin"
                        value={document}
                        onChange={event => { setDocument(event.target.value); }}
                    >
                        {Documents.map((data) => (
                            <MenuItem key={data.id} value={data.name}>
                                {data.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <br></br>
                    <TextField
                        style={{ width: "55%" }}
                        id=""
                        select
                        label="Zgjedh Periudhen"
                        value={periudha}
                        onChange={event => { setPeriudha(event.target.value); }}
                    >
                        {Periudha.map((data) => (
                            <MenuItem key={data.id} value={`${vitAktual} ${data.name}`}>
                                {`${vitAktual} ${data.name}`}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    <button className="button-15" variant="contained" style={{ width: "55%", height: "50px" }} onClick={handlePrint}><FaPrint /> Print</button>
                </div>
            </div>
        </div>
    );
};
