import React from "react";
import { IconButton } from '@mui/material';
import { FaEye } from 'react-icons/fa';

function ShowDetails({ fetchAccountsDetails,accountID }) {
    return (
        <div>
            <IconButton>
                <FaEye style={{ color: '#466F86' }} onClick={() => { fetchAccountsDetails(accountID); }}
                />
            </IconButton>
        </div>
    )
}

export default ShowDetails;