import React, { useRef, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import { ApiLink } from '../APILink/ApiLink';
import JsBarcode from 'jsbarcode';
import QRCode from 'react-qr-code';

const PDFGenerator = (props) => {
    const contentRef = useRef(null);

    const barcodeRef = useRef(null);

    // const totalAmount0 = (props.details && props.details.length > 0 ? props.details[0].Amount0 : 0).toFixed(2);
    // const totalAmount8 = (props.details && props.details.length > 0 ? props.details[0].Amount8 : 0).toFixed(2);
    // const totalAmount18 = (props.details && props.details.length > 0 ? props.details[0].Amount18 : 0).toFixed(2);

    const totalAmount0 = (props.details && props.details.length > 0 ? props.details[0].Amount0.toFixed(2) : '').toString();
    const totalAmount8 = (props.details && props.details.length > 0 ? props.details[0].Amount8.toFixed(2) : '').toString();
    const totalAmount18 = (props.details && props.details.length > 0 ? props.details[0].Amount18.toFixed(2) : '').toString();

    const discountAmount = (props.details && props.details.length > 0 && props.details[0].DiscountAmount !== undefined) ? props.details[0].DiscountAmount.toFixed(2) : 0;
    // const totali = (props.details && props.details.length > 0 ? props.details[0].Total : 0).toFixed(2);
    const totali = (props.details && props.details.length > 0 ? (props.details[0].Total || 0).toFixed(2) : '').toString();

    const totalAmount = (Number(totalAmount0) + Number(totalAmount8) + Number(totalAmount18));

    // const amountPercent = (((Number(discountAmount) / (Number(discountAmount) + Number(totali)))))
    const amountPercent = (Number(totalAmount) * ((Number(discountAmount) / (Number(totali)))))



    // const invoiceNr = (props.details && props.details.length > 0 ? props.details[0].InvoiceNr : 0);
    // const fiscalYear = (props.details && props.details.length > 0 ? props.details[0].FiscalYear : 0);
    // const actionType = (props.details && props.details.length > 0 ? props.details[0].ActionType : '');
    const invoiceNr = (props.details && props.details.length > 0 ? props.details[0].InvoiceNr || 0 : 0);
    const fiscalYear = (props.details && props.details.length > 0 ? props.details[0].FiscalYear || 0 : 0);
    const actionType = (props.details && props.details.length > 0 ? props.details[0].ActionType || '' : '');


    // const actionType = (props.articleDetails && props.articleDetails.length > 0 ? props.articleDetails[0].ActionType : '');

    const inv = "IND-" + fiscalYear + "-" + invoiceNr

    // const companyLogoUrl = props.companyDetails && props.companyDetails.length > 0 ? props.companyDetails[0].CompanyLogo : '';

    const printStyles = {
        printLogo: {
            display: 'block', // Siguro që logo shfaqet në modalitetin e printimit
        },
    };



    // const vat8 = (props.details.length > 0 ? props.details[0].VAT8 : 0).toFixed(2);
    // const vat18 = (props.details.length > 0 ? props.details[0].VAT18 : 0).toFixed(2);
    const vat8 = (props.details.length > 0 && props.details[0].VAT8 !== undefined ? props.details[0].VAT8.toFixed(2) : 0).toString();
    const vat18 = (props.details.length > 0 && props.details[0].VAT18 !== undefined ? props.details[0].VAT18.toFixed(2) : 0).toString();



    const downloadPDF = () => {
        const element = contentRef.current;
        html2pdf()
            .from(element)
            .save();
    }

    useEffect(() => {
        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, inv, {
                format: "CODE128",
                height: 30, // Lartësia në pikselë
                width: 1.5,   // Gjerësia e shiritave
                text: actionType === "IND" ? inv : invoiceNr,
                fontSize: 12
            });
        }
    }, [inv]);

    return (
        <div>
            <div style={{ display: "none" }}>
                <div className='PrintContent__Container' ref={contentRef} style={{ width: "100%", display: "flex", flexDirection: "column", margin: "5px" }}>
                    <div className='PrintContent__Container__div2' style={{ display: "flex", justifyContent: "space-between", paddingLeft: "15px", paddingRight: "15px", height: "auto" }}>
                        <div >
                            {(props.companyDetails && props.companyDetails.length > 0 && props.companyDetails[0]?.CompanyLogo) ? (
                                <img src={ApiLink.PHOTO_URL + props.companyDetails[0].CompanyLogo} style={{ width: "200px", height: "120px" }} />
                            ) : (
                                <span>No logo available</span>
                            )}
                        </div>

                        <div className='PrintContent__Container__div2__data' style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", padding: "5px" }}>
                            <label style={{ fontSize: "12px" }}><b>Shitësi: {props.companyDetails && props.companyDetails.length > 0 ? (props.companyDetails[0].CompanyName) : ''}</b></label>
                            <label style={{ fontSize: "12px" }}>NUI: {props.companyDetails && props.companyDetails.length > 0 ? (props.companyDetails[0].IdentificationNumber) : ''}</label>
                            {props.companyDetails && props.companyDetails.length > 0 && props.companyDetails[0]?.VATDeclarant !== 0 ? (
                                <label style={{ fontSize: "12px" }}>Numri i TVSH-së: {props.companyDetails[0]?.VATNumber}</label>
                            ) : null}

                            <label style={{ fontSize: "12px" }}>Adresa: {props.companyDetails && props.companyDetails.length > 0 ? (props.companyDetails[0].Address || '') : ''}</label>
                            <label style={{ fontSize: "12px" }}>tel: {props.companyDetails && props.companyDetails.length > 0 ? (props.companyDetails[0].PhoneNumbers || '') : ''}</label>
                            <label style={{ fontSize: "12px" }}>Email: {props.companyDetails && props.companyDetails.length > 0 ? (props.companyDetails[0].Email || '') : ''}</label>

                        </div>
                    </div>
                    <hr style={{ margin: "10px" }}></hr>

                    <div className='PrintContent__Container__div3' style={{ display: "flex", justifyContent: "space-between", height: "auto" }}>
                        <div className='PrintContent__Container__div3__bleresi' style={{ display: "flex", flexDirection: "column", padding: "10px" }} >
                            <label style={{ fontSize: "12px" }}><b>Blerësi: {props.details && props.details.length > 0 ? (props.details[0].AccountName || '') : ''}</b></label>
                            <label style={{ fontSize: "12px" }}>Numri Unik Identifikues: {props.details && props.details.length > 0 ? (props.details[0].IdentificationNumber || '') : ''}</label>
                            <label style={{ fontSize: "12px" }}>Numri i TVSH-së: {props.details && props.details.length > 0 ? (props.details[0].VATNumber || '') : ''}</label>
                            <label style={{ fontSize: "12px" }}>Qyteti: {props.details && props.details.length > 0 ? (props.details[0].CityName || '') : ''}</label>
                            <label style={{ fontSize: "12px" }}>Adresa: {props.details && props.details.length > 0 ? (props.details[0].Adress || '') : ''}</label>
                            <label style={{ fontSize: "12px" }}>tel: {props.details && props.details.length > 0 ? (props.details[0].PhoneNumbers || '') : ''}</label>

                        </div>
                        <div className='PrintContent__Container__div3__dokumenti' style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", padding: "10px" }} >
                            <label style={{ fontSize: "12px" }}>
                                <b>Dokumenti:
                                    {props.details && props.details.length > 0 ? (
                                        props.details[0].ActionType === "IND" ? "Faturë Shitje" : "Faturë Blerje"
                                    ) : ''}

                                </b>
                            </label>
                            <label style={{ fontSize: "12px" }}> <b>Numri : </b><label style={{ fontSize: "12px" }}><b>
                                {props.details && props.details.length > 0 ?
                                    (props.actiontype ? `${props.details[0].ActionType}-${props.details[0].FiscalYear}-${props.details[0].InvoiceNr}` : props.details[0].InvoiceNr)
                                    : ''}

                            </b></label>
                            </label>
                            <label style={{ fontSize: "12px" }}>
                                <b>Data Faturës:
                                    {props.details && props.details.length > 0
                                        ? new Date(props.details[0].Date).toLocaleDateString("en-GB")
                                        : ''}

                                </b>
                            </label>
                            <label style={{ fontSize: "12px" }}>
                                Afati Pageses:{" "}
                                {props.details && props.details.length > 0
                                    ? new Date(props.details[0].PayDate).toLocaleDateString("en-GB")
                                    : ''}

                            </label>
                            <label style={{ fontSize: "12px" }}>Përdoruesi: {props.details && props.details.length > 0 && props.details[0].CreatedByUser ? props.details[0].CreatedByUser.split('@')[0] : ''}</label>
                            <label style={{ fontSize: "12px" }}>Njesia Organizative:01</label>
                        </div>
                    </div>
                    <hr style={{ margin: "10px" }}></hr>

                    <div className='PrintContent__Container__div4' style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            {props.link && (
                                <QRCode
                                    value={props.link}
                                    style={{ width: "50px", height: "50px" }}
                                />
                            )}
                        </div>
                        {/* Ketu duhet te vendoset barkodi */}
                        <div>
                            <svg ref={barcodeRef}></svg>
                        </div>
                    </div>
                    <hr style={{ margin: "10px" }}></hr>

                    <div className='PrintContent__Container__div5' style={{ margin: "5px" }} >
                        <div className='PrintContent__Container__div5__MainTable'>
                            <table className='PrintTable' style={{ width: "100%", borderCollapse: "collapse" }}>
                                <tbody style={{ margin: "10px" }}>
                                    <tr style={{ borderBottom: "1px solid black" }}>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "120px" }}>Barkodi</th>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "300px" }}>Emertimi</th>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Sasia</th>
                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Njesia</th>
                                        {
                                            props.companyDetails && props.companyDetails.length > 0 && props.companyDetails[0].VATDeclarant != 0 ?
                                                (
                                                    <>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Cmimi pa TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Vlera pa TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Norma</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Rabati %</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Cmimi Me TVSH</th>
                                                        <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Vlera Me TVSH</th>
                                                    </>
                                                )
                                                :
                                                <>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Rabati %</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Cmimi</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", width: "90px" }}>Vlera</th>
                                                </>
                                        }
                                    </tr>
                                    {props.articleDetails &&
                                        props.articleDetails.map((data) => (
                                            <tr style={{ borderBottom: "1px solid black " }} key={data.HistoryID}>
                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px", width: "120px" }}>{data.Barcode}</td>
                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px", width: "300px" }}>{data.ArticleName}</td>
                                                {
                                                    props.sasia == "QtyInput" ?
                                                        <>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{data.QtyInput}</td>
                                                        </> :
                                                        <>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{data.QtyOutput}</td>
                                                        </>
                                                }
                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{data.UnitName}</td>
                                                {
                                                    props.companyDetails && props.companyDetails.length > 0 && props.companyDetails[0].VATDeclarant != 0 ?
                                                        (
                                                            <>
                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px", width: "90px" }}>{(data.PriceWithoutVAT !== undefined ? data.PriceWithoutVAT.toFixed(2) : '')}</td>
                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px", width: "90px" }}>{(data.Amount !== undefined ? data.Amount.toFixed(2) : '')}</td>
                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{data.VatPercent}</td>
                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{(data.DiscountPercent !== undefined ? data.DiscountPercent.toFixed(2) : '')}</td>
                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px", width: "90px" }}>{(data.HistoryVAT !== undefined ? data.HistoryVAT.toFixed(2) : '')}</td>
                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px", width: "90px" }}>{(data.PriceWithVAT !== undefined ? data.PriceWithVAT.toFixed(2) : '')}</td>

                                                                <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px", width: "90px" }}>
                                                                    {(data.Total !== null ? data.Total.toFixed(2) : 0)}
                                                                </td>
                                                            </>
                                                        )
                                                        :
                                                        <>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "center", fontSize: "10px", width: "90px" }}>{(data.DiscountPercent !== undefined ? data.DiscountPercent.toFixed(2) : '')}</td>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px", width: "90px" }}>{(data.PriceWithVAT !== undefined ? data.PriceWithVAT.toFixed(2) : '')}</td>
                                                            <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px", width: "90px" }}>{(data.Total !== undefined ? data.Total.toFixed(2) : '')}</td>

                                                        </>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        {
                            props.companyDetails && props.companyDetails.length > 0 && props.companyDetails[0].VATDeclarant !== undefined && props.companyDetails[0].VATDeclarant !== 0 ?

                                (
                                    <div className='PrintContent__Container__div5__SecondTable' style={{ display: "flex", justifyContent: "space-between" }}>
                                        <table className='PrintTable' style={{ width: "50%", borderCollapse: "collapse" }}>
                                            <tbody>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>Norma Tatimore</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>Baza</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>TVSH</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px", textAlign: "center" }}>Total</th>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>TVSH e llogaritur (A) 0%:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{totalAmount0} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(0).toFixed(2)} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount0) + 0).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px", width: "150px" }}>TVSH e llogaritur (D) 8%:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{totalAmount8} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{vat8} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount8) + Number(vat8)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>TVSH e llogaritur (E) 18%:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{totalAmount18} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{vat18} €</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount18) + Number(vat18)).toFixed(2)} €</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='PrintTable' style={{ width: "40%", borderCollapse: "collapse" }}>
                                            <tbody>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Vlera pa rabat:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount) + Number(amountPercent)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Rabati:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(amountPercent)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Total pa TVSH:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(totalAmount0) + Number(totalAmount8) + Number(totalAmount18)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", fontSize: "10px" }}>Total TVSH:</td>
                                                    <td style={{ padding: "3px", textAlign: "left", border: "1px solid black", textAlign: "right", fontSize: "10px" }}>{(Number(vat8) + Number(vat18)).toFixed(2)} €</td>
                                                </tr>
                                                <tr style={{ borderBottom: "1px solid black " }}>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px" }}>Për pagesë:</th>
                                                    <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", textAlign: "right", fontSize: "10px" }}>{(props.details && props.details.length > 0 ? (props.details[0].Total) : 0).toFixed(2)} €</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ) :
                                <table className='PrintTable' style={{ display: "flex", justifyContent: "flex-end", borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr style={{ borderBottom: "1px solid black " }}>
                                            <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", fontSize: "10px" }}>Për pagesë:</th>
                                            <th className='TableHeader' style={{ padding: "8px", textAlign: "left", border: "solid black 1px", backgroundColor: "lightgray", textAlign: "right", fontSize: "10px" }}>{(props.details && props.details.length > 0 ? (props.details[0].Total !== undefined ? props.details[0].Total.toFixed(2) : 0) : 0)} €</th>
                                        </tr>
                                    </tbody>
                                </table>
                        }
                        <div className='PrintContent__Container__div6' style={{ paddingTop: "20px" }}>
                            {
                                props.details && props.details.length > 0 ?
                                    (props.details[0].PaymentOptions === 0 ?
                                        <div>
                                            <p style={{ fontSize: "12px" }}>Pagesa duhet të bëhet deri në afatin e pagesës së caktuar</p>
                                            {
                                                props.details && props.details[0].Comment !== undefined && props.details[0].Comment !== null ? (
                                                    <p style={{ fontSize: "12px" }}>Komenti: {props.details[0].Comment}</p>
                                                ) : null
                                            }

                                        </div> :
                                        <div>
                                            <p style={{ fontSize: "12px" }}>Ju faleminderit për bashkëpunimin tuaj!</p>
                                            {
                                                props.details && props.details[0].Comment !== undefined && props.details[0].Comment !== null ? (
                                                    <p style={{ fontSize: "12px" }}>Komenti: {props.details[0].Comment}</p>
                                                ) : null
                                            }
                                        </div>
                                    )
                                    : null
                            }
                        </div>

                        <div>
                            <div className='PrintContent__Container__div7' style={{ display: "flex", justifyContent: "space-evenly", paddingTop: "10px" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span>_________________</span>
                                    <label style={{ fontSize: "10px" }}>Faturoi:</label>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span>_________________</span>
                                    <label style={{ fontSize: "10px" }}>Dergoi:</label>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span>_________________</span>
                                    <label style={{ fontSize: "10px" }}>Pranoi:</label>
                                </div>
                            </div>

                            <div className='PrintContent__Container__div8' style={{ paddingTop: "10px" }}>
                                <label style={{ width: "100%", fontSize: "10px" }}>Ju lutem, pagesen tuaj per shumen e pergjithshme ta beni ne njeren nga llogarit bankare vijuese te kompanis </label>
                                <br></br>
                                <table className='PrintTable' style={{ width: "100%", borderCollapse: "collapse", borderTop: "1px solid black" }}>
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid black ", backgroundColor: "lightgray" }}>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Banka</th>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Emri Llogaris</th>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Numri Llogaris</th>
                                            <th style={{ padding: "8px", textAlign: "left", backgroundColor: "lightgray", fontSize: "10px" }}>Swift Kodi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.bankaccounts && props.bankaccounts.map((data) => (
                                            <tr style={{ borderBottom: "1px solid black", fontSize: "10px" }} key={data.AccountID}>
                                                <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>{data.BankName}</td>
                                                <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>{props.companyDetails && props.companyDetails.length > 0 ? props.companyDetails[0].CompanyName : ''}</td>
                                                <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>{data.IBAN + " " + data.IdentificationNumber}</td>
                                                <td style={{ padding: "3px", textAlign: "left", fontSize: "10px" }}>{data.SWIFT}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="button-15" variant="contained" onClick={downloadPDF}> PDF</button>
        </div>
    );
}

export default PDFGenerator;
