const currencyFormatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const AccountsPayableDataGrid = [
    { field: 'id', headerName: '#', width: 60, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true },
    // { field: 'accID', headerName: 'ID e Llogarisë', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'accName', headerName: 'Emri Llogarisë', width: 300, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'add', headerName: 'Adresa', width: 300, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'idNumber', headerName: 'Numri Identifikues', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'debit', headerName: 'Debi', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'credit', headerName: 'Kredi', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'balance', headerName: 'Bilanci', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

export const AccountsPayableDetails = [
    { field: 'id', headerName: '#', width: 60, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true },
    { field: 'type', headerName: 'Lloji', width: 70, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'name', headerName: 'Emri Llogarisë', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'idNumber', headerName: 'Numri Identifikues', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'comment', headerName: 'Komenti', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'debit', headerName: 'Debi', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'credit', headerName: 'Kredi', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'progressive', headerName: 'Progresive', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

