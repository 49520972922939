import React, { useState, useEffect } from "react";
import axios from "axios";
import './../../Style/Transfers.scss'
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { AiFillAppstore } from "react-icons/ai";
import { Paper } from "@mui/material";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import './../../Style/TableStyle.scss'
import { MdEdit } from "react-icons/md";
import InsertAccounts from "../Accounts/InsertAccounts"
import DataGridWithActions from '../../Components/DataGridWithActions';
import { TransferGridHeader } from "./TransferGridHeader";
import Autocomplete from "@mui/material/Autocomplete";

export default function Transfers() {
    const currentYear = new Date().getFullYear();

    const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [showDetails, setShowDetails] = useState(true);

    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        ActionType: "",
        LedgerID: "",
        AccountID: "",
        SupplierID: "",
        Total: "",
        PaymentOptions: "",
        Coment: "",
        ModifiedByUser: "",
        Amount0: "",
        Amount8: ""
    });

    const [showEditModal, setShowEditModal] = useState(false);

    const [ledgers, setLedgers] = useState([]);

    // keto 2 vargje i mbajn llogarit qe thirren duke u bazuar nga ledgerid qe i dergohet si parameter, njera eshte per debitim ndersa tjetra per kreditim
    const [debitAccounts, setDebitAccounts] = useState([]);
    const [creditAccounts, setCreditAccounts] = useState([]);
    // Kodi per Marrjen e informatave nga databaza

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Transfers', { headers });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Transfers' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Transfers  Deleted Successfully");
            fetchData();
        } catch (error) {
            deleteAlert("Transfers can not be deleted");
        }
    };

    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            SupplierID: item.SupplierID,
            Total: item.Total,
            PaymentOptions: item.PaymentOptions,
            LedgerID: item.LedgerID,
            Coment: item.Coment,
            ModifiedByUser: loginEmail,
            Amount0: item.Amount0,
            Amount8: item.Amount8
        });
        setShowEditModal(true);
        setShowDetails(false);
        fetchDebitAccounts(item.Amount0);
        fetchCreditAccounts(item.Amount8);

    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData.Date == null) {
            errorAlert("Date is required");
            return;
        }
        if (formData.LedgerID == null) {
            errorAlert("Ledger is required");
            return;
        }
        if (formData.PaymentOptions == null) {
            errorAlert("Payment is required");
            return;
        }
        if (formData.Coment == null) {
            errorAlert("Comment is required");
            return;
        }
        if (formData.Total == null) {
            errorAlert("Amount is required");
            return;
        }
        axios
            .put(ApiLink.API_URL + 'Transfers', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                setShowDetails(true);
                fetchData();
                updateAlert("Transfers updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update Transfers")
            });
    };


    const fetchDebitAccounts = async (debitLedgerID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: debitLedgerID } });
            setDebitAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchCreditAccounts = async (creditLedgerID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: creditLedgerID } });
            setCreditAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchLedger = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger', { headers });
            setLedgers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const refreshData = () => {
        fetchCreditAccounts(formData.Amount8);
        fetchDebitAccounts(formData.Amount0);
    }

    const rows = data.map((item, index) => ({
        id: ++index,
        inv: ("TRS-" + currentYear + "-" + item.InvoiceNr),
        date: (item.Date.substring(0, 10)),
        client: item.AccountName_AccountID,
        account: item.AccountName_PaymentOptions,
        amount: item.Total,
        deleteItemId: item.TransactionID,
        rowInfo: item
    }));


    //Debit selects
    const handleAutocompleteChangeDebitLedger = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                Amount0: value.LedgerID
            }));
            fetchDebitAccounts(value.LedgerID)
        } else {
            setFormData(prevState => ({
                ...prevState,
                Amount0: ""
            }));
        }
    };

    const defaultPropsDebitLedger = {
        options: ledgers,
        getOptionLabel: (option) =>
            option.LedgerNameEN || "",
    };

    const handleAutocompleteChangeDebitAccount = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    const defaultPropsDebitAccount = {
        options: debitAccounts,
        getOptionLabel: (option) =>
            option.AccountName || "",
    };
    ////////////////////////////////////////////////////////////////////////

    //Creddit Selects
    const handleAutocompleteChangeCreditLedger = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                Amount8: value.LedgerID
            }));
            fetchCreditAccounts(value.LedgerID)
        } else {
            setFormData(prevState => ({
                ...prevState,
                Amount8: ""
            }));
        }
    };

    const defaultPropsCreditLedger = {
        options: ledgers,
        getOptionLabel: (option) =>
            option.LedgerNameEN || "",
    };

    const handleAutocompleteChangeCreditAccount = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                PaymentOptions: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                PaymentOptions: ""
            }));
        }
    };

    const defaultPropsCreditAccount = {
        options: creditAccounts,
        getOptionLabel: (option) =>
            option.AccountName || "",
    };


    useEffect(() => {
        fetchData();
        fetchLedger();
    }, []);

    return (
        <div className="Crud__Container">
            <div className="shfaqeEditin">
                {/* Shfaqja  e modalit per editimin e te dhenave  */}
                {showEditModal && (
                    <div className="Transfers__Container">
                        <div className="header_flex">
                            <div className="header_icon">
                                <MdEdit style={{ color: '#466F86', width: "40px" }} />
                                <h3>Ndryshimi Transferit</h3>
                            </div>
                        </div>
                        <hr></hr>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <InsertAccounts fetchData={refreshData} type="insert" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginLeft: "5px" }}>
                                    <Link to="#"
                                        style={{ width: "110px", textDecoration: "none" }}
                                    >
                                        <button type="submit" className="button-15" onClick={handleSubmit}>Ndrysho</button>

                                    </Link>
                                </div>
                                <div style={{ marginLeft: "5px" }}>
                                    <Link
                                        to="#"
                                        style={{ width: "110px", textDecoration: "none" }}
                                    >
                                        <button
                                            className="button-15"
                                            onClick={() => {
                                                setShowDetails(true);
                                                setShowEditModal(false);
                                            }}
                                        >
                                            Anulo
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='header_paper2'></div>
                        <div className="edit">
                            <form className='Transfers__Container__Format'>
                                <Paper>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div className='Forma1'>
                                            <div className='Boxx'>
                                                <TextField
                                                    className="Transfers__Container__Format__SameInput"
                                                    id="InvoiceNr"
                                                    label="Numri Transferit"
                                                    variant="outlined"
                                                    type="text"
                                                    name='InvoiceNr'
                                                    value={"TRS-" + currentYear + "-" + formData.InvoiceNr}
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                                <TextField
                                                    // style={{ width: "230px" }}
                                                    className="Transfers__Container__Format__SameInput"
                                                    id="Date"
                                                    type="date"
                                                    variant="outlined"
                                                    name='Date'
                                                    value={formData.Date.substring(0, 10)}
                                                    onChange={handleInputChange}
                                                />
                                                <hr style={{ marginLeft: "15px" }}></hr>
                                                <div className="Debit__Credit__Form">
                                                    {/* <TextField
                                                        className="Transfers__Container__Format__SameInput"
                                                        id="Amount0"
                                                        name="Amount0"
                                                        select
                                                        label="Select Ledger"
                                                        defaultValue=""
                                                        value={formData.Amount0}
                                                        onChange={(e) => { handleInputChange(e); fetchDebitAccounts(e.target.value); }}
                                                    >
                                                        {ledgers.map((data) => (
                                                            <MenuItem key={data.LedgerID} value={data.LedgerID}>
                                                                {data.LedgerNameEN}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        // sx={{ m: 2 }}
                                                        {...defaultPropsDebitLedger}
                                                        id="Amount0"
                                                        includeInputInList
                                                        onChange={handleAutocompleteChangeDebitLedger}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label="Llogaria kontabel Debitore"
                                                                // variant="filled"
                                                                className="Transfers__Container__Format__SameInput"
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                        value={ledgers.find(option => option.LedgerID === formData.Amount0) || null}
                                                    />
                                                    {/* <TextField
                                                        // style={{ width: "504px" }}
                                                        className="Transfers__Container__Format__SameInput"
                                                        id="AccountID"
                                                        name="AccountID"
                                                        select
                                                        label="Debit Account"
                                                        value={formData.AccountID}
                                                        onChange={handleInputChange}
                                                    >
                                                        {debitAccounts.map((data) => (
                                                            <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        // sx={{ m: 2 }}
                                                        {...defaultPropsDebitAccount}
                                                        id="AccountID"
                                                        includeInputInList
                                                        onChange={handleAutocompleteChangeDebitAccount}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label="Llogaria Debitore"
                                                                // variant="filled"
                                                                className="Transfers__Container__Format__SameInput"
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                        value={debitAccounts.find(option => option.AccountID === formData.AccountID) || null}
                                                    />

                                                </div>
                                            </div>
                                            <div className='Boxx'>
                                                <TextField
                                                    // style={{ width: "495px" }}
                                                    className="Transfers__Container__Format__SameInput"
                                                    type="text"
                                                    id="Coment"
                                                    name="Coment"
                                                    label="Komenti"
                                                    variant="outlined"
                                                    value={formData.Coment}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField
                                                    // style={{ width: "495px" }}
                                                    className="Transfers__Container__Format__SameInput"
                                                    type="text"
                                                    id="Total"
                                                    name="Total"
                                                    label="Vlera"
                                                    variant="outlined"
                                                    value={formData.Total}
                                                    onChange={handleInputChange}
                                                />
                                                <hr style={{ marginRight: "65px" }}></hr>
                                                <div className="Debit__Credit__Form">
                                                    {/* <TextField
                                                        className="Transfers__Container__Format__SameInput"
                                                        id="Amount8"
                                                        name="Amount8"
                                                        select
                                                        label="Select Ledger"
                                                        defaultValue=""
                                                        value={formData.Amount8}
                                                        onChange={(e) => { handleInputChange(e); fetchCreditAccounts(e.target.value); }}
                                                    >
                                                        {ledgers.map((data) => (
                                                            <MenuItem key={data.LedgerID} value={data.LedgerID}>
                                                                {data.LedgerNameEN}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        // sx={{ m: 2 }}
                                                        {...defaultPropsCreditLedger}
                                                        id="Amount8"
                                                        includeInputInList
                                                        onChange={handleAutocompleteChangeCreditLedger}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label="Llogaria kontabel Kreditore"
                                                                // variant="filled"
                                                                className="Transfers__Container__Format__SameInput"
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                        value={ledgers.find(option => option.LedgerID === formData.Amount8) || null}
                                                    />
                                                    {/* <TextField
                                                        // style={{ width: "593px" }}
                                                        className="Transfers__Container__Format__SameInput"
                                                        id="PaymentOptions"
                                                        select
                                                        label="Credit Account"
                                                        name="PaymentOptions"
                                                        value={formData.PaymentOptions}
                                                        onChange={handleInputChange}
                                                    >
                                                        {creditAccounts.map((data) => (
                                                            <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        // sx={{ m: 2 }}
                                                        {...defaultPropsCreditAccount}
                                                        id="PaymentOptions"
                                                        includeInputInList
                                                        onChange={handleAutocompleteChangeCreditAccount}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label="Llogaria Kreditore"
                                                                // variant="filled"
                                                                className="Transfers__Container__Format__SameInput"
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                        value={creditAccounts.find(option => option.AccountID === formData.PaymentOptions) || null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                </Paper>

                            </form>
                        </div>
                    </div>
                )}
            </div>

            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Transferet/Urdheresat</h3>
                        </div>
                        <div>
                            <Link to={"/money/addtransfers"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} /> Krijo Transfer
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e Transfereve: Menaxhoni, shtoni, editoni dhe fshini Transfere / Urdheresa Fiananciare.</p>
                    </div>
                    <DataGridWithActions
                        columns={TransferGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={false}
                        height={500}
                    />
                </div>
            )}
        </div>
    )
}