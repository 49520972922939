import React, { useState, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/TableStyle.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
// import AddBoxIcon from '@mui/icons-material/AddBox';
import InsertData from "../Clients/InsertClients";
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
import InsertClient from "../Clients/InsertClients";
import Autocomplete from "@mui/material/Autocomplete";


function AddAdvance(props) {
    const TranscationData = [
        {
            id: 1,
            type: "Vendor"
        },
        {
            id: 2,
            type: "Eksport"
        },
        {
            id: 3,
            type: "Sherbime jashtë vendit"
        },
    ]

    const headers = authHeader();
    const email = LoginUserDetailed();
    const currentYear = new Date().getFullYear();


    const [date, setDate] = useState("");
    const [payDate, setPayDate] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [accountID, setAccountID] = useState("");
    const [clientsID, setClientsID] = useState("");
    const [total, setTotal] = useState("");
    const [amount0, setAmount0] = useState(0);
    const [amount8, setAmount8] = useState(0);
    const [amount18, setAmount18] = useState(0);
    const [vat8, setVat8] = useState(0);
    const [vat18, setVat18] = useState(0);
    const [coment, setComent] = useState("");
    const [paymentOptions, setPaymentOptions] = useState("");
    const [transactionType, setTransactionType] = useState(1);

    const [expence, setExpence] = useState([]);
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);

    const [cashRegister, setCashRegister] = useState([]);




    const handleSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Invoice number is required!");
            return;
        }
        if (!date) {
            errorAlert("Date is required!");
            return;
        }
        if (!payDate) {
            errorAlert("Pay date is required!");
            return;
        }
        if (!clientsID) {
            errorAlert("Please select client!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Please choose payment option!");
            return;
        }
        const stateDate = {
            Date: date,
            PayDate: payDate,
            ActionType: "ADV",
            InvoiceNr: invoiceNR,
            AccountID: 702,
            SupplierID: clientsID,
            Amount0: amount0,
            Amount8: amount8,
            Amount18: amount18,
            PaymentOptions: paymentOptions,
            TransactionType: transactionType,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'Advance', stateDate, { headers })
            .then(() => {
                handleSuccess("Advace Added Successfully");
                setDate("");
                setPayDate("");
                setAccountID("");
                setInvoiceNR("");
                setClientsID("");
                setAmount0(0);
                setAmount8(0);
                setAmount18(0);
                setPaymentOptions("");
                setTransactionType(1);
                setComent("");
                setComent("");
                fetchNextInvoiceNumber();
                setClientName("");
                setValueWithVAT0(0);
                setValueWithVAT8(0);
                setValueWithVAT18(0);
                setVat8(0);
                setVat18(0);
                setfield(null)
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    // useEffect(() => {
    //     const VAT18 = amount18 * 0.18; // llogarit produktin me 18% shumëzim
    //     setVat18(VAT18); // vendos vlerën e produktit në inputin e dytë
    // }, [amount18]);

    // useEffect(() => {
    //     const VAT8 = amount8 * 0.08; // llogarit produktin me 18% shumëzim
    //     setVat8(VAT8); // vendos vlerën e produktit në inputin e dytë
    // }, [amount8]);

    useEffect(() => {
        const total = Number(amount0) + Number(amount8) + Number(amount18) + Number(vat8) + Number(vat18)
        // + amount18 + vat8 + vat18   
        setTotal(total); // vendos vlerën e produktit në inputin e dytë
    }, [amount0, amount8, amount18, vat8, vat18]);

    const fetchExpence = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 609 } });
            setExpence(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchClients = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
            setClients(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const fetchCashRegister = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchNextInvoiceNumber = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Advance/InvoiceNr', { headers });
            setInvoiceNR(response.data.NextInvoiceNr);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredClients(
            clients.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, clients]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };


    const [clientName, setClientName] = useState("");
    const handleFilterClient = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setClientsID(selectedOption.AccountID);
            setClientName(selectedOption.label);
        } else {
            setClientsID("");
            setClientName("");
        }
    };

    const [valueWithVAT0, setValueWithVAT0] = useState(0)
    const [valueWithVAT8, setValueWithVAT8] = useState(0)
    const [valueWithVAT18, setValueWithVAT18] = useState(0)

    const calculateWithoutVAT = async (withVAT, vat) => {
        try {
            const noVAT = (Number(withVAT) * Number(100)) / (Number(100) + Number(vat));
            const vatTotal = Number(withVAT) - Number(noVAT);

            if (vat == 0) {
                setAmount0(noVAT);

            } else if (vat == 8) {
                setAmount8(noVAT);
                setVat8(vatTotal)
            }
            else if (vat == 18) {
                setAmount18(noVAT);
                setVat18(vatTotal)
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateWithVAT = async (withoutVAT, vat) => {
        try {
            const vatTotal = Number(withoutVAT) * (Number(vat) / Number(100));
            const withVAT = Number(withoutVAT) + Number(vatTotal);


            if (vat == 0) {
                setValueWithVAT0(withVAT);

            } else if (vat == 8) {
                setValueWithVAT8(withVAT);
                setVat8(vatTotal)
            }
            else if (vat == 18) {
                setValueWithVAT18(withVAT);
                setVat18(vatTotal)
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateFromVAT = async (vatValue, vat) => {
        try {
            const withoutVAT = Number(vatValue) / (Number(vat) / Number(100));
            const totalAmount = Number(withoutVAT) + Number(vatValue);


            if (vat == 0) {
                setValueWithVAT0(totalAmount);
                setAmount0(withoutVAT);

            } else if (vat == 8) {
                setValueWithVAT8(totalAmount);
                setAmount8(withoutVAT);
            }
            else if (vat == 18) {
                setValueWithVAT18(totalAmount);
                setAmount18(withoutVAT);
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    //Pjesa per select input field Clients
    const [field, setfield] = useState(null);
    const handleAutocompleteChangeClient = (event, value) => {
        if (value && value.AccountID) {
            setClientsID(value.AccountID);
            setfield(value); // Përditëso `selectedArticle` me vlerën e zgjedhur


        } else {
            // Në rast se vlera është pastruar
            setClientsID(""); // Vendos vlerën e ArticleID në null
            setfield(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsClients = {
        options: clients,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                    {/* <button
                            onClick={(e) => {
                                e.stopPropagation();
                                // handleButtonClick(option);
                            }}
                            onMouseEnter={handleOpen}
                            onMouseLeave={handleClose}
                            style={{ marginLeft: 'auto' }}
                        >
                            Click
                        </button> */}
                </li>
            </>
        ),
    };
    //////

    useEffect(() => {
        fetchClients();
        fetchCashRegister();
        fetchExpence();
        fetchNextInvoiceNumber();
    }, [])

    return (
        <div className="AddPurchaseTotal__Container" style={{ paddingTop: "50px" }}>
            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Create New Advance</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Accounting is the process of recording financial transactions pertaining to a business.</p>
            </div>
            <hr></hr>
            <div className="AddPurchaseTotal__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <InsertClient fetchData={fetchClients} type="insert" />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                        >
                            <button className="button-15"
                                onClick={handleSubmit}
                            >
                                Save & New
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to={"/Sales/advance"}
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Close
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2'></div>
            <form className='AddPurchaseTotal__Container__Format'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField
                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                    // style={{ width: "594px" }}                                    required
                                    id="invoiceNR"
                                    label="Invoice Number"
                                    variant="outlined"
                                    type="text"
                                    name='InvoiceNr'
                                    value={"ADV-" + currentYear + "-" + invoiceNR}
                                    disabled
                                    onChange={event => setInvoiceNR(event.target.value)}
                                // onChange={event => { setInvoiceNR(event.target.value); checkInvoiceNumber(event.target.value); }}
                                />

                                <div className='AddPurchaseTotal__Container__Format__Dates'>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="date"
                                        type="date"
                                        variant="outlined"
                                        name='Date'
                                        label="Date"
                                        value={date}
                                        onChange={(e) => { setDate(e.target.value); setPayDate(e.target.value) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="PayDate"
                                        type="date"
                                        variant="outlined"
                                        name='PayDate'
                                        label="Data pagesës"
                                        value={payDate}
                                        onChange={(e) => setPayDate(e.target.value)}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="textfield_button">
                                    <div style={{ width: "100%" }} >
                                        {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Klientin</span>
                                        <Select
                                            inputId="AccountID"
                                            className="AddPurchaseTotal__Container__Format__SelectList"
                                            value={{ label: clientName, value: clientsID }}
                                            options={filteredClients.map((data) => ({
                                                label: data.AccountName,
                                                value: data.AccountID,
                                                AccountID: data.AccountID
                                            }))}
                                            onInputChange={handleInputChange}
                                            onChange={handleFilterClient}
                                            isClearable
                                            placeholder="Select"
                                            noOptionsMessage={() => 'No matching'}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    height: "55px",
                                                    marginLeft: "10px", marginRight: "10px"

                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "#fff",
                                                    zIndex: 9999,
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                    borderRadius: "4px",
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    maxHeight: "200px",
                                                    overflowY: "auto",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#000",
                                                }),
                                            }}
                                        /> */}
                                        <Autocomplete
                                            fullWidth
                                            {...defaultPropsClients}
                                            id="include-input-in-list"
                                            includeInputInList
                                            value={field}  // Lidheni me state-in `selectedArticle`
                                            onChange={handleAutocompleteChangeClient}
                                            isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                            renderInput={(params) => (
                                                <TextField
                                                    // style={{ width: "91%" }}
                                                    className="AddPurchaseTotal__Container__Format__SameInput"

                                                    {...params}
                                                    label="Kerko Klientin"
                                                // variant="filled"
                                                //   onKeyPress={handleKeyPress}
                                                />
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="AddPurchaseTotal__Container__Format__Dates" style={{ marginTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "593px" }}
                                        className="AddPurchaseTotal__Container__Format__Date" id="AccountID"
                                        label="Ledger"
                                        defaultValue=""
                                        value={"702-Te hyrat e Tjera"}
                                        // onChange={event => setAccountID(703)}
                                        disabled
                                    >
                                    </TextField>

                                    <TextField
                                        // style={{ width: "343px" }}
                                        className="AddPurchaseTotal__Container__Format__Date" id="id"
                                        select
                                        label="Select Transaction Type"
                                        defaultValue=""
                                        value={transactionType}
                                        onChange={event => setTransactionType(event.target.value)}
                                        required
                                    >
                                        {TranscationData.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <TextField
                                    // style={{ width: "593px" }}
                                    className="AddPurchaseTotal__Container__Format__SameInput" id="PaymentType"
                                    select
                                    label="Select Payment Type"
                                    defaultValue=""
                                    // helperText="Please select expense"
                                    value={paymentOptions}
                                    onChange={event => setPaymentOptions(event.target.value)}
                                >
                                    <MenuItem key="0" value="0">Pagesa Behet më vonë
                                    </MenuItem>
                                    {cashRegister.map((data) => (
                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className='box2'>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="Vlera 18%"
                                        variant="outlined"
                                        name='Amount18'
                                        value={amount18 !== null && amount18 !== 0 ? amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount18(event.target.value)}
                                        onKeyUp={event => calculateWithVAT(event.target.value, 18)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="TVSH 18%"
                                        variant="outlined"
                                        name='VAT18'
                                        // value={vat18}
                                        value={vat18 !== null && vat18 !== 0 ? vat18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setVat18(e.target.value)}
                                        onKeyUp={event => calculateFromVAT(event.target.value, 18)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total 18"
                                        type="text"
                                        // value={Number(valueWithVAT18).toFixed(2)}
                                        value={valueWithVAT18 !== null && valueWithVAT18 !== 0 ? valueWithVAT18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setValueWithVAT18(e.target.value)}
                                        onKeyUp={event => calculateWithoutVAT(event.target.value, 18)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="Vlera 8"
                                        name='Amount8'
                                        variant="outlined"
                                        value={amount8 !== null && amount8 !== 0 ? amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount8(event.target.value)}
                                        onKeyUp={event => calculateWithVAT(event.target.value, 8)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="TVSH 8"
                                        variant="outlined"
                                        name='VAT8'
                                        value={vat8 !== null && vat8 !== 0 ? vat8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setVat8(event.target.value)}
                                        onKeyUp={event => calculateFromVAT(event.target.value, 8)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total 8"
                                        type="text"
                                        // value={valueWithVAT8}
                                        value={valueWithVAT8 !== null && valueWithVAT8 !== 0 ? valueWithVAT8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setValueWithVAT8(e.target.value)}
                                        onKeyUp={event => calculateWithoutVAT(event.target.value, 8)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="Vlera 0"
                                        variant="outlined"
                                        name='Amount0'
                                        value={amount0 !== null && amount0 !== 0 ? amount0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount0(event.target.value)}
                                        onKeyUp={event => calculateWithVAT(event.target.value, 0)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                        label="TVSH 0"
                                        variant="outlined"
                                        value={(0).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                    />

                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total 0"
                                        type="text"
                                        // value={valueWithVAT0.toFixed(2)}
                                        value={valueWithVAT0 !== null && valueWithVAT0 !== 0 ? valueWithVAT0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={e => setValueWithVAT0(e.target.value)}
                                        onKeyUp={event => calculateWithoutVAT(event.target.value, 0)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>

                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total without VAT"
                                        type="number"
                                        value={(Number(amount8) + Number(amount18) + Number(amount0)).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled

                                    />
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="VAT Total"
                                        type="number"
                                        value={(Number(vat8) + Number(vat18)).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled

                                    />
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                        label="Total"
                                        type="number"
                                        value={(Number(amount0) + Number(amount8) + Number(amount18) + Number(vat8) + Number(vat18)).toFixed(2)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                    />
                                </div>
                                <TextField
                                    // style={{ width: "495px" }}
                                    className="AddPurchaseTotal__Container__Format__Comment" id="Comment"
                                    label="Comment"
                                    type="text"
                                    name='Comment'
                                    value={coment}
                                    onChange={event => setComent(event.target.value)}
                                    variant="outlined" />
                            </div>
                        </div>


                    </Box>
                </Paper>
            </form>
            <div></div>
        </div>
    );
}

export default AddAdvance;