import jwtDecode from "jwt-decode";
import React, { useState, useEffect } from "react";

export default function LoginUserDetailed() {
    const [email, setEmail] = useState("");
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setEmail(decodedToken.email);
        }
    }, []);

    return email;
}