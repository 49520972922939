import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './../../Style/AddPurchaseTotal.scss'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { AiOutlineFilePdf, AiFillAppstore } from "react-icons/ai";
import { Paper } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { MdEdit } from "react-icons/md";
import InsertClient from "../Clients/InsertClients";
import { SalesTotalGridHeader } from "../SalesInTotal/SalesTotalGridHeader";
import DataGridWithActions from "../../Components/DataGridWithActions";
import Autocomplete from "@mui/material/Autocomplete";

export default function Advance() {
    const TranscationData = [
        {
            id: 1,
            type: "Vendor"
        },
        {
            id: 2,
            type: "Eksport"
        },
        {
            id: 3,
            type: "Sherbime jashtë vendit"
        },
    ]
    const currentYear = new Date().getFullYear();

    const [showDetails, setShowDetails] = useState(true);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        PayDate: "",
        ActionType: "",
        AccountIDSpare: "",
        AccountID: "",
        ClientID: "",
        Amount0: 0,
        Amount8: 0,
        Amount18: 0,
        VAT8: "",
        VAT18: "",
        Total: "",
        PaymentOptions: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: ""
    });

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [clients, setClients] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);

    //Kodi per mbajtjen e totaleve pas llogaritjes
    const [vat8, setVat8] = useState(0);
    const [vat18, setVat18] = useState(0);
    const [total, setTotal] = useState(0);
    const [number, setNumber] = useState(1);

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Advance', { headers });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    // //Kodi per shfaqjen e tabeles ne PDF
    // const handleExportPDF = () => {
    //     const doc = new jsPDF();
    //     // Teksti i parë në fillim të dokumentit
    //     const text1 = "AccounTree";
    //     doc.text(text1, 10, 10); // 20 është pozicioni X, 10 është pozicioni Y

    //     // Teksti i dytë në fillim të dokumentit
    //     const text2 = "Advance";
    //     doc.text(text2, 10, 20); // 20 është pozicioni X, 20 është pozicioni Y

    //     // Teksti i tretë në fillim të dokumentit
    //     // const text3 = "Accounting is the process of recording financial transactions pertaining to a business.";
    //     // doc.text(text3, 10, 30); // 20 është pozicioni X, 30 është pozicioni Y
    //     // const paragraph = "Ky është një paragraf në fillim të tabelës.";
    //     // doc.text(paragraph, 20, 40); // 20 është pozicioni X, 40 është pozicioni Y
    //     doc.autoTable({
    //         head: [["Invoice Number", "Date", "Client", "Total % VAT", "VAT", "Total"]],
    //         body: filteredData.map((item) => [item.InvoiceNr, item.Date, item.AccountName, item.Total, item.VAT18, item.Total]),
    //         startY: 30
    //     });
    //     doc.save("Advance-List.pdf");
    // };
    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };
    // `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Invoice Number", "Date", "Client", "Amount", "VAT", "Total"]],
            body: filteredData.map((item, index) => [
                number + index,
                `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,
                formatDate(item.Date),
                item.AccountName,
                (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
                (Number(item.VAT18) + Number(item.VAT8)).toFixed(2),
                item.Total.toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "List of advance", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("Advance.pdf");
    };

    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };


    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Advance' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Avanci u fshi me sukses");
            fetchData();
        } catch (error) {
            deleteAlert("Avanci nuk është fshir");
        }
    };

    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        if (item.Amount0 > 0) {
            calculateWithVAT(item.Amount0, 0);
        }
        if (item.Amount8 > 0) {
            calculateWithVAT(item.Amount8, 8);
        }
        if (item.Amount18 > 0) {
            calculateWithVAT(item.Amount18, 18);
        }
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            PayDate: item.PayDate,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            AccountIDSpare: item.AccountIDSpare,
            SupplierID: item.ClientID,
            Amount0: item.Amount0,
            Amount8: item.Amount8,
            Amount18: item.Amount18,
            VAT8: item.VAT8,
            VAT18: item.VAT18,
            Total: item.Total,
            PaymentOptions: item.PaymentOptions,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: loginEmail,

        });
        setShowEditModal(true);
        setShowDetails(false);
    };


    //Kodi per kalkulimin e Total
    useEffect(() => {
        const total = Number(formData.Amount0) + Number(formData.Amount8) + Number(formData.Amount18) + Number(vat8) + Number(vat18)
        // + amount18 + vat8 + vat18   
        setTotal(total); // vendos vlerën e produktit në inputin e dytë
    }, [formData.Amount0, formData.Amount8, formData.Amount18, vat8, vat18]);



    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'Advance', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                setShowDetails(true);
                setVat8(0);
                setVat18(0);
                setValueWithVAT0(0);
                setValueWithVAT8(0);
                setValueWithVAT18(0);
                fetchData();
                setVat8(0);
                setVat18(0);
                setValueWithVAT0(0);
                setValueWithVAT8(0);
                setValueWithVAT18(0);
                updateAlert("Avanci u editua me sukses");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Avanci nuk u editua ")
            });
    };

    const filteredData = data.filter((item) => {
        return (
            item.ActionType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const fetchCashRegister = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchClients = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
            setClients(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [valueWithVAT0, setValueWithVAT0] = useState(0)
    const [valueWithVAT8, setValueWithVAT8] = useState(0)
    const [valueWithVAT18, setValueWithVAT18] = useState(0)

    const calculateWithoutVAT = async (withVAT, vat) => {
        try {
            const noVAT = (Number(withVAT) * Number(100)) / (Number(100) + Number(vat));
            const vatTotal = Number(withVAT) - Number(noVAT);

            if (vat == 0) {
                setFormData(prevState => ({
                    ...prevState,
                    Amount0: noVAT
                }));

            } else if (vat == 8) {
                setFormData(prevState => ({
                    ...prevState,
                    Amount8: noVAT
                }));
                setVat8(vatTotal)
            }
            else if (vat == 18) {
                setFormData(prevState => ({
                    ...prevState,
                    Amount18: noVAT
                }));
                setVat18(vatTotal)
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateWithVAT = async (withoutVAT, vat) => {
        try {
            const vatTotal = Number(withoutVAT) * (Number(vat) / Number(100));
            const withVAT = Number(withoutVAT) + Number(vatTotal);


            if (vat == 0) {
                setValueWithVAT0(withVAT);

            } else if (vat == 8) {
                setValueWithVAT8(withVAT);
                setVat8(vatTotal)
            }
            else if (vat == 18) {
                setValueWithVAT18(withVAT);
                setVat18(vatTotal)
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateFromVAT = async (vatValue, vat) => {
        try {
            const withoutVAT = Number(vatValue) / (Number(vat) / Number(100));
            const totalAmount = Number(withoutVAT) + Number(vatValue);


            if (vat == 0) {
                setValueWithVAT0(totalAmount);
                setFormData(prevState => ({
                    ...prevState,
                    Amount0: withoutVAT
                }));

            } else if (vat == 8) {
                setValueWithVAT8(totalAmount);
                setFormData(prevState => ({
                    ...prevState,
                    Amount8: withoutVAT
                }));
            }
            else if (vat == 18) {
                setValueWithVAT18(totalAmount);
                setFormData(prevState => ({
                    ...prevState,
                    Amount18: withoutVAT
                }));
            }

        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        inv: (item.ActionType + "-" + item.FiscalYear + "-" + item.InvoiceNr),
        date: (item.Date.substring(0, 10)),
        client: item.AccountName,
        amount: (Number(item.Total) - (Number(item.VAT18) + Number(item.VAT8))).toFixed(2),
        vat: ((Number(item.VAT18) + Number(item.VAT8)).toFixed(2)),
        total: item.Total,
        deleteItemId: item.TransactionID,
        rowInfo: item
    }));

    //Select list for Clients
    const handleAutocompleteChangeClients = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    // const defaultPropsClients = {
    //     options: clients,
    //     getOptionLabel: (option) => option.AccountName || "",
    // };

    const defaultPropsClients = {
        options: clients,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        onMouseEnter={handleOpen}
                        onMouseLeave={handleClose}
                        style={{ marginLeft: 'auto' }}
                    >
                        Click
                    </button> */}
                </li>
            </>
        ),
    };

    useEffect(() => {
        fetchData();
        fetchCashRegister();
        fetchClients();
        fetchCompaniesData();
    }, []);

    return (
        <div className="Crud__Container">
            <div className="shfaqeEditin">
                {showEditModal && (
                    <div className="AddPurchaseTotal__Container">
                        <div className="header_flex">
                            <div className="header_icon">
                                <MdEdit style={{ color: '#466F86', width: "40px" }} />
                                <h3>Edit Advance</h3>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="AddPurchaseTotal__Container__AllButtons">
                            <div style={{ display: "flex", }}>
                                <InsertClient fetchData={fetchClients} type="insert" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginLeft: "5px" }}>
                                    <Link to="#"
                                        style={{ width: "110px", textDecoration: "none" }}
                                    >
                                        <button type="submit" className="button-15" onClick={handleSubmit}>Update</button>

                                    </Link>
                                </div>
                                <div style={{ marginLeft: "5px" }}>
                                    <Link
                                        to="#"
                                        style={{ width: "110px", textDecoration: "none" }}
                                    >
                                        <button
                                            className="button-15"
                                            onClick={() => {
                                                setShowDetails(true);
                                                setShowEditModal(false);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='header_paper2'></div>
                        <form className='AddPurchaseTotal__Container__Format'>
                            <Paper>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className='Forma1'>
                                        <div className='Boxx'>
                                            <TextField
                                                className="AddPurchaseTotal__Container__Format__SameInput"
                                                // style={{ width: "594px" }}
                                                id="invoiceNR"
                                                label="Invoice Number"
                                                variant="outlined"
                                                type="text"
                                                name='InvoiceNr'
                                                disabled
                                                value={"ADV-" + currentYear + "-" + formData.InvoiceNr}

                                                onChange={handleInputChange}
                                            />

                                            <div className='AddPurchaseTotal__Container__Format__Dates'>
                                                <TextField
                                                    // style={{ width: "230px" }}
                                                    className="AddPurchaseTotal__Container__Format__Date"
                                                    id="date"
                                                    type="date"
                                                    variant="outlined"
                                                    name='Date'
                                                    label="Date"
                                                    value={formData.Date && formData.Date.substring(0, 10)}
                                                    onChange={handleInputChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <TextField
                                                    // style={{ width: "230px" }}
                                                    className="AddPurchaseTotal__Container__Format__Date"
                                                    id="PayDate"
                                                    type="date"
                                                    variant="outlined"
                                                    name='PayDate'
                                                    label="Pay Date"
                                                    value={formData.PayDate && formData.PayDate.substring(0, 10)}
                                                    onChange={handleInputChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>

                                            <div className="textfield_button">
                                                {/* <TextField
                                                    // style={{ width: "504px" }}
                                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                                    id="AccountID"
                                                    name="AccountID"
                                                    select
                                                    label="Select Client"
                                                    value={formData.AccountID}
                                                    onChange={handleInputChange}
                                                >
                                                    {clients.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    fullWidth
                                                    // sx={{ m: 2 }}
                                                    {...defaultPropsClients}
                                                    id="AccountID"
                                                    includeInputInList
                                                    onChange={handleAutocompleteChangeClients}
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            label="Klienti"
                                                            // variant="filled"
                                                            className="AddPurchaseTotal__Container__Format__SameInput"
                                                        />
                                                    )}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                            overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                        },
                                                    }}
                                                    value={clients.find(option => option.AccountID === formData.AccountID) || null}
                                                />
                                            </div>
                                            <div className='AddPurchaseTotal__Container__Format__Dates'>
                                                <TextField
                                                    // style={{ width: "504px" }}
                                                    className="AddPurchaseTotal__Container__Format__Date"
                                                    id="AccountIDSpare"
                                                    type="text"
                                                    variant="outlined"
                                                    name='AccountIDSpare'
                                                    value={"702-Te hyrat e Tjera"}
                                                    disabled
                                                // onChange={handleInputChange}
                                                />
                                                <TextField
                                                    // style={{ width: "343px" }}
                                                    className="AddPurchaseTotal__Container__Format__Date"
                                                    id="id"
                                                    select
                                                    name="TransactionType"
                                                    label="Select Transaction Type"
                                                    value={formData.TransactionType}
                                                    onChange={handleInputChange}
                                                >
                                                    {TranscationData.map((data) => (
                                                        <MenuItem key={data.id} value={data.id}>{data.type}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                            <TextField
                                                // style={{ width: "593px" }}
                                                className="AddPurchaseTotal__Container__Format__SameInput"
                                                id="AccountiD"
                                                select
                                                label="Select Payment Type"
                                                name="PaymentOptions"
                                                value={formData.PaymentOptions}
                                                onChange={handleInputChange}
                                            >
                                                <MenuItem key="0" value="0">Pagesa Behet më vonë
                                                </MenuItem>
                                                {cashRegister.map((data) => (
                                                    <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>

                                        <div className='box2'>
                                            <div className='box3'>
                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput"
                                                    id="Amount18"
                                                    label="Vlera 18%"
                                                    variant="outlined"
                                                    name='Amount18'
                                                    value={formData.Amount18 !== null && formData.Amount18 !== 0 ? formData.Amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={handleInputChange}
                                                    onKeyUp={event => calculateWithVAT(event.target.value, 18)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                                    label="TVSH 18%"
                                                    variant="outlined"
                                                    name='VAT18'
                                                    value={vat18 !== null && vat18 !== 0 ? vat18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={e => setVat18(e.target.value)}
                                                    onKeyUp={event => calculateFromVAT(event.target.value, 18)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                                    label="Total 18"
                                                    type="text"
                                                    // value={Number(valueWithVAT18).toFixed(2)}
                                                    value={valueWithVAT18 !== null && valueWithVAT18 !== 0 ? valueWithVAT18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={e => setValueWithVAT18(e.target.value)}
                                                    onKeyUp={event => calculateWithoutVAT(event.target.value, 18)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                            <div className='box3'>
                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput"
                                                    id="Amount8"
                                                    label="Amount 8"
                                                    name='Amount8'
                                                    variant="outlined"
                                                    value={formData.Amount8 !== null && formData.Amount8 !== 0 ? formData.Amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={handleInputChange}
                                                    onKeyUp={event => calculateWithVAT(event.target.value, 8)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-basic"
                                                    label="TVSH 8"
                                                    variant="outlined"
                                                    name='VAT8'
                                                    value={vat8 !== null && vat8 !== 0 ? vat8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={event => setVat8(event.target.value)}
                                                    onKeyUp={event => calculateFromVAT(event.target.value, 8)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />

                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                                    label="Total 8"
                                                    type="text"
                                                    // value={valueWithVAT8}
                                                    value={valueWithVAT8 !== null && valueWithVAT8 !== 0 ? valueWithVAT8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={e => setValueWithVAT8(e.target.value)}
                                                    onKeyUp={event => calculateWithoutVAT(event.target.value, 8)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>

                                            <div className='box3'>
                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput"
                                                    id="Amount0"
                                                    label="Vlera 0"
                                                    variant="outlined"
                                                    name='Amount0'
                                                    value={formData.Amount0 !== null && formData.Amount0 !== 0 ? formData.Amount0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={handleInputChange}
                                                    onKeyUp={event => calculateWithVAT(event.target.value, 0)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />

                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput"
                                                    id="outlined-basic"
                                                    label="TVSH 0"
                                                    variant="outlined"
                                                    value={0}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />
                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput" id="outlined-number"
                                                    label="Total 0"
                                                    type="text"
                                                    // value={valueWithVAT0.toFixed(2)}
                                                    value={valueWithVAT0 !== null && valueWithVAT0 !== 0 ? valueWithVAT0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                    onChange={e => setValueWithVAT0(e.target.value)}
                                                    onKeyUp={event => calculateWithoutVAT(event.target.value, 0)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                            <div className='box3'>
                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput"
                                                    id="outlined-number"
                                                    label="Total without VAT"
                                                    type="number"
                                                    value={(Number(formData.Amount0) + Number(formData.Amount8) + Number(formData.Amount18)).toFixed(2)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled

                                                />
                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput"
                                                    id="outlined-number"
                                                    label="VAT Total"
                                                    type="number"
                                                    value={(Number(vat8) + Number(vat18)).toFixed(2)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled

                                                />
                                                <TextField
                                                    // style={{ width: "150px" }}
                                                    className="AddPurchaseTotal__Container__Format__ValueInput"
                                                    id="outlined-number"
                                                    label="Total"
                                                    type="number"
                                                    value={(Number(formData.Amount0) + Number(formData.Amount8) + Number(formData.Amount18) + Number(vat8) + Number(vat18)).toFixed(2)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled
                                                />
                                            </div>

                                            <TextField
                                                // style={{ width: "495px" }}
                                                className="AddPurchaseTotal__Container__Format__Comment"
                                                type="text"
                                                id="Coment"
                                                name="Coment"
                                                label="Comment"
                                                variant="outlined"
                                                value={formData.Coment}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>


                                </Box>
                            </Paper>
                            {/* <button type="submit" className="btn btn-success">Update</button>

                        <button
                            className="btn btn-secondary"
                            onClick={() => setShowEditModal(false)}
                        >
                            Cancel
                        </button> */}
                        </form>
                    </div>
                )}
            </div>
            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Advance</h3>
                        </div>
                        <div>
                            <Link to={"/Sales/addadvance"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />   Add New Advance
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Accounting is the process of recording financial transactions pertaining to a business.</p>
                    </div>
                    {/* columns eshte perdorur file i cili gjendet ne folderin e sales in total sepse i kan emertimet e njejta */}
                    <DataGridWithActions
                        columns={SalesTotalGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={true}
                        height={500}
                    />
                </div>
            )}
        </div>
    )
}