import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/Transfers.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
// import InsertData from "../Suppliers/InsertSuppliers";
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
import InsertOwner from "../Owners/InsertOwners";
import Autocomplete from "@mui/material/Autocomplete";


function AddWithdrawal(props) {
    const headers = authHeader();
    const email = LoginUserDetailed();

    const [date, setDate] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [ownerID, setOwnerID] = useState("");
    const [ledgerID, setLedgerID] = useState("");
    const [total, setTotal] = useState("");
    const [coment, setComent] = useState("");
    const [paymentOptions, setPaymentOptions] = useState("");

    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);

    const [owners, setOwners] = useState([]);
    const [filteredOwners, setFilteredOwners] = useState([]);

    const [ledgers, setLedgers] = useState([]);
    const [filteredLedgers, setFilteredLedgers] = useState([]);

    const currentYear = new Date().getFullYear();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!date) {
            errorAlert("Date is required!");
            return;
        }
        if (!ledgerID) {
            errorAlert("Please choose Ledger!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Please choose payment option!");
            return;
        }
        if (!coment) {
            errorAlert("Please fill Comment field!");
            return;
        }
        if (!total) {
            errorAlert("Please fill Total Field!");
            return;
        }
        if (!ownerID) {
            errorAlert("Please select supplier!");
            return;
        }
        const stateDate = {
            Date: date,
            ActionType: "WDR",
            InvoiceNr: invoiceNR,
            // AccountID: accountID,
            Total: total,
            SupplierID: ownerID,
            LedgerID: ledgerID,
            PaymentOptions: paymentOptions,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'Withdrawal', stateDate, { headers })
            .then(() => {
                handleSuccess("Withdrawal Added Successfully");
                setDate("");
                fetchNextInvoiceNumber();
                setLedgerID("");
                setOwnerID("");
                setComent("");
                setPaymentOptions("");
                setTotal("");
                setAccountName("");
                setOwnerName("");
                setfield(null);
                setPayField(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const fetchAccounts = async (ledgerID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: ledgerID } });
            setAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchLedger = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger/Get_101_102_Ledgers', { headers });
            setLedgers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchOwner = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 501 } });
            setOwners(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchNextInvoiceNumber = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Withdrawal/InvoiceNr', { headers });
            setInvoiceNR(response.data.NextInvoiceNr);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredLedgers(
            ledgers.filter((data) =>
                data.LedgerNameEN.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, ledgers]);
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredAccounts(
            accounts.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, accounts]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredOwners(
            owners.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, owners]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };
    const [accountName, setAccountName] = useState("");
    const handleSelectChange = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setPaymentOptions(selectedOption.AccountID);
            setAccountName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setPaymentOptions("");
            setAccountName("");
        }
    };

    const [ownerName, setOwnerName] = useState("");
    const handleSelectOwner = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setOwnerID(selectedOption.AccountID);
            setOwnerName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setOwnerID("");
            setOwnerName("");
        }
    };

    // i run te dhenat per Debit Ledger
    const [creditNameLedger, setCreditNameLedger] = useState("");
    const handleSelectLedger = (selectedOption) => {
        if (selectedOption && selectedOption.LedgerID) {
            setLedgerID(selectedOption.LedgerID);
            fetchAccounts(selectedOption.LedgerID);
            setCreditNameLedger(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setLedgerID("");
            setCreditNameLedger("");
        }
    };

    //Pjesa per select input field accounts
    const [field, setfield] = useState(null);
    const handleAutocompleteChangeAccounts = (event, value) => {
        if (value && value.AccountID) {
            setOwnerID(value.AccountID);
            setfield(value); // Përditëso `selectedArticle` me vlerën e zgjedhur


        } else {
            // Në rast se vlera është pastruar
            setOwnerID(""); // Vendos vlerën e ArticleID në null
            setfield(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsAccounts = {
        options: owners,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>

                </li>
            </>
        ),
    };
    //////

    //Pjesa per select input field payment accounts
    const [payField, setPayField] = useState(null)
    const handleAutocompleteChangePayAccount = (event, value) => {
        if (value && value.AccountID) {
            setPaymentOptions(value.AccountID);
            setPayField(value);
        } else {
            // Në rast se vlera është pastruar
            setPaymentOptions(""); // Vendos vlerën e ArticleID në null
        }
    };

    const defaultPropsPayAccount = {
        options: accounts,
        getOptionLabel: (option) =>
            option.AccountName || "",
    };

    useEffect(() => {
        fetchOwner();
        fetchLedger();
        fetchNextInvoiceNumber();
        // fetchPaymentType();
        // fetchExpence()
    }, [])

    return (
        <div className="Transfers__Container" style={{ paddingTop: "50px" }}>
            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Terheqje e Re</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha të dhënat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className="Transfers__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <InsertOwner fetchData={fetchOwner} type="insert" />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                        >
                            <button className="button-15"
                                onClick={handleSubmit}
                            >
                                Ruaj & Ri
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to="/money/withdrawal"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbyll
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2'></div>
            <form className='Transfers__Container__Format'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField
                                    className="Transfers__Container__Format__SameInput"
                                    id="invoiceNR"
                                    label="Numri Terheqjes"
                                    variant="outlined"
                                    type="text"
                                    name='InvoiceNr'
                                    value={"WDR-" + currentYear + "-" + invoiceNR}
                                    onChange={event => { setInvoiceNR(event.target.value) }}
                                    disabled

                                />
                                <TextField
                                    required
                                    className="Transfers__Container__Format__SameInput"
                                    id="date"
                                    type="date"
                                    variant="outlined"
                                    name='Date'
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                                <hr style={{ marginLeft: "15px" }}></hr>
                                <div className="Debit__Credit__Form">
                                    <TextField
                                        className="Transfers__Container__Format__SameInput"
                                        id="outlined-basic"
                                        label="Përzgjedh llogarinë kontabel Debitore"
                                        variant="outlined"
                                        name='Total'
                                        value="501-Kapitali pronarit"
                                        disabled
                                    />
                                    {/* <TextField
                                        required
                                        autoComplete=""
                                        className="Transfers__Container__Format__SameInput"
                                        id="AccountID"
                                        select
                                        label="Select Owner"
                                        defaultValue=""
                                        value={ownerID}
                                        onChange={event => setOwnerID(event.target.value)}
                                    >
                                        {owners.map((data) => (
                                            <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                            </MenuItem>
                                        ))}
                                    </TextField> */}
                                    {/* <Select
                                        inputId="AccountID"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: ownerName, value: ownerID }}
                                        options={filteredOwners.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectOwner}
                                        isClearable
                                        placeholder="Select account"
                                        noOptionsMessage={() => 'No matching'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",
                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsAccounts}
                                        // sx={{ m: 2 }}
                                        id="include-input-in-list"
                                        includeInputInList
                                        value={field}  // Lidheni me state-in `selectedArticle`
                                        onChange={handleAutocompleteChangeAccounts}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh Pronarin"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='Boxx'>
                                <TextField
                                    className="Transfers__Container__Format__SameInput"
                                    required
                                    autoComplete="off"
                                    id="outlined-basic"
                                    label="Komenti"
                                    variant="outlined"
                                    name='Comment'
                                    value={coment}
                                    onChange={event => setComent(event.target.value)}
                                />
                                <TextField
                                    required
                                    autoComplete="off"
                                    className="Transfers__Container__Format__SameInput"
                                    id="outlined-basic"
                                    label="Vlera"
                                    variant="outlined"
                                    name='Total'
                                    value={total}
                                    onChange={event => setTotal(event.target.value)}
                                />
                                <hr style={{ marginRight: "65px" }}></hr>
                                <div className="Debit__Credit__Form">
                                    <TextField
                                        required
                                        autoComplete="off"
                                        className="Transfers__Container__Format__SameInput"
                                        id="LedgerID"
                                        select
                                        label="Përzgjedh llogarinë kontabel Kreditore"
                                        defaultValue=""
                                        value={ledgerID}
                                        onChange={event => { setLedgerID(event.target.value); fetchAccounts(event.target.value) }}
                                    >
                                        {ledgers.map((data) => (
                                            <MenuItem key={data.LedgerID} value={data.LedgerID}>{data.LedgerNameEN}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {/* <Select
                                        inputId="LedgerID"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: creditNameLedger, value: ledgerID }}
                                        options={filteredLedgers.map((data) => ({
                                            label: data.LedgerNameEN,
                                            value: data.LedgerID,
                                            LedgerID: data.LedgerID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectLedger}
                                        isClearable
                                        placeholder="Select Ledger"
                                        noOptionsMessage={() => 'No matching ledgers'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px", 
                                                marginBottom:"15px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",

                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    {/* <Select
                                        inputId="PaymentType"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: accountName, value: paymentOptions }}
                                        options={filteredAccounts.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectChange}
                                        isClearable
                                        placeholder="Select account"
                                        noOptionsMessage={() => 'No matching'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",
                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsPayAccount}
                                        id="include-input-in-list"
                                        includeInputInList
                                        onChange={handleAutocompleteChangePayAccount}
                                        value={payField}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh llogarinë Kreditore"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </form>
            <div></div>
        </div >
    );
}

export default AddWithdrawal;