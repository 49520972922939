import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/Transfers.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
// import InsertData from "../Suppliers/InsertSuppliers";
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
import InsertClient from "../Clients/InsertClients";
import Autocomplete from "@mui/material/Autocomplete";


function AddReceivables(props) {
    const headers = authHeader();
    const email = LoginUserDetailed();

    const [date, setDate] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [clientID, setClientID] = useState("");
    const [ledgerID, setLedgerID] = useState("");
    const [total, setTotal] = useState("");
    const [coment, setComent] = useState("");
    const [paymentOptions, setPaymentOptions] = useState("");

    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);

    const [client, setClient] = useState([]);
    const [filteredClient, setFilteredClient] = useState([]);

    const [ledgers, setLedgers] = useState([]);
    const currentYear = new Date().getFullYear();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!date) {
            errorAlert("Date is required!");
            return;
        }
        if (!ledgerID) {
            errorAlert("Please choose Ledger!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Please choose payment option!");
            return;
        }
        if (!coment) {
            errorAlert("Please fill Comment field!");
            return;
        }
        if (!total) {
            errorAlert("Please fill Total Field!");
            return;
        }
        if (!clientID) {
            errorAlert("Please select supplier!");
            return;
        }
        const stateDate = {
            Date: date,
            ActionType: "REC",
            InvoiceNr: invoiceNR,
            // AccountID: accountID,
            Total: total,
            SupplierID: clientID,
            LedgerID: ledgerID,
            PaymentOptions: paymentOptions,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'Arketimet', stateDate, { headers })
            .then(() => {
                handleSuccess("Receivables Added Successfully");
                setDate("");
                fetchNextInvoiceNumber();
                setLedgerID("");
                setClientID("");
                setComent("");
                setPaymentOptions("");
                setTotal("");
                setClientName("");
                setPaymentName("");
                setfield(null);
                setPayField(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const fetchAccounts = async (ledgerID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: ledgerID } });
            setAccounts(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchLedger = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger/Get_101_102_Ledgers', { headers });
            setLedgers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchClient = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
            setClient(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchNextInvoiceNumber = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Arketimet/InvoiceNr', { headers });
            setInvoiceNR(response.data.NextInvoiceNr);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredAccounts(
            accounts.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, accounts]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredClient(
            client.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, client]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [paymentName, setPaymentName] = useState("");
    const handleSelectChange = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setPaymentOptions(selectedOption.AccountID);
            setPaymentName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setPaymentOptions("");
            setPaymentName("");
        }
    };

    const [clientName, setClientName] = useState("");
    const handleSelectClient = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setClientID(selectedOption.AccountID);
            setClientName(selectedOption.label);
            // alert(selectedOption.label);
        } else {
            setClientID("");
            setClientName("");
        }
    };


    //Pjesa per select input field Clients
    const [field, setfield] = useState(null);
    const handleAutocompleteChangeClient = (event, value) => {
        if (value && value.AccountID) {
            setClientID(value.AccountID);
            setfield(value); // Përditëso `selectedArticle` me vlerën e zgjedhur


        } else {
            // Në rast se vlera është pastruar
            setClientID(""); // Vendos vlerën e ArticleID në null
            setfield(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsClients = {
        options: client,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>

                </li>
            </>
        ),
    };
    //////


    //Pjesa per select input field payment accounts
    const [payField, setPayField] = useState(null)
    const handleAutocompleteChangePayAccount = (event, value) => {
        if (value && value.AccountID) {
            setPaymentOptions(value.AccountID);
            setPayField(value);
        } else {
            // Në rast se vlera është pastruar
            setPaymentOptions(""); // Vendos vlerën e ArticleID në null
        }
    };

    const defaultPropsPayAccount = {
        options: accounts,
        getOptionLabel: (option) =>
            option.AccountName || "",
    };

    useEffect(() => {
        fetchClient();
        fetchLedger();
        fetchNextInvoiceNumber();
        // fetchPaymentType();
        // fetchExpence()
    }, [])

    return (
        <div className="Transfers__Container" style={{ paddingTop: "50px" }}>
            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Arkëtim i Ri</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha të dhënat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className="Transfers__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <InsertClient fetchData={fetchClient} type="insert" />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                        >
                            <button className="button-15"
                                onClick={handleSubmit}
                            >
                                Ruaj & Ri
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to="/money/receivables"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbyll
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
            <div className='header_paper2'></div>
            <form className='Transfers__Container__Format'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField
                                    className="Transfers__Container__Format__SameInput"
                                    id="invoiceNR"
                                    label="Numri i Arkëtimit"
                                    variant="outlined"
                                    type="text"
                                    name='InvoiceNr'
                                    value={"REC-" + currentYear + "-" + invoiceNR}
                                    onChange={event => { setInvoiceNR(event.target.value) }}
                                    disabled

                                />
                                <TextField
                                    required
                                    className="Transfers__Container__Format__SameInput"
                                    id="date"
                                    type="date"
                                    variant="outlined"
                                    name='Date'
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                                <hr style={{ marginLeft: "15px" }}></hr>
                                <div className="Debit__Credit__Form">
                                    <TextField
                                        required
                                        autoComplete="off"
                                        className="Transfers__Container__Format__SameInput"
                                        id="LedgerID"
                                        select
                                        label="Përzgjedh llogarinë kontabel Debitore"
                                        defaultValue=""
                                        value={ledgerID}
                                        onChange={event => { setLedgerID(event.target.value); fetchAccounts(event.target.value) }}
                                    >
                                        {ledgers.map((data) => (
                                            <MenuItem key={data.LedgerID} value={data.LedgerID}>{data.LedgerNameEN}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {/* <TextField
                                        required
                                        autoComplete="off"
                                        className="Transfers__Container__Format__SameInput"
                                        id="PaymentType"
                                        select
                                        label="Select Payment Type"
                                        // defaultValue=""
                                        // helperText="Please select expense"
                                        value={paymentOptions}
                                        onChange={event => setPaymentOptions(event.target.value)}
                                    >
                                        {accounts.map((data) => (
                                            <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                            </MenuItem>
                                        ))}
                                    </TextField> */}
                                    {/* <Select
                                        inputId="PaymentType"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: paymentName, value: paymentOptions }}
                                        options={filteredAccounts.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectChange}
                                        isClearable
                                        placeholder="Select account"
                                        noOptionsMessage={() => 'No matching'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",
                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsPayAccount}
                                        id="include-input-in-list"
                                        includeInputInList
                                        onChange={handleAutocompleteChangePayAccount}
                                        value={payField}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh llogarinë Debitore"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='Boxx'>
                                <TextField
                                    className="Transfers__Container__Format__SameInput"
                                    required
                                    autoComplete="off"
                                    id="outlined-basic"
                                    label="Komenti"
                                    variant="outlined"
                                    name='Comment'
                                    value={coment}
                                    onChange={event => setComent(event.target.value)}
                                />
                                <TextField
                                    required
                                    autoComplete="off"
                                    className="Transfers__Container__Format__SameInput"
                                    id="outlined-basic"
                                    label="Vlera"
                                    variant="outlined"
                                    name='Total'
                                    value={total}
                                    onChange={event => setTotal(event.target.value)}
                                />
                                <hr style={{ marginRight: "65px" }}></hr>
                                <div className="Debit__Credit__Form">
                                    <TextField
                                        className="Transfers__Container__Format__SameInput"
                                        id="outlined-basic"
                                        label="Llogaria kontabel Kreditore"
                                        variant="outlined"
                                        name='Total'
                                        value="105-Klientet / LLA"
                                        disabled
                                    />
                                    {/* <TextField
                                        required
                                        autoComplete=""
                                        className="Transfers__Container__Format__SameInput"
                                        id="AccountID"
                                        select
                                        label="Select Client"
                                        defaultValue=""
                                        value={clientID}
                                        onChange={event => setClientID(event.target.value)}
                                    >
                                        {client.map((data) => (
                                            <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                            </MenuItem>
                                        ))}
                                    </TextField> */}
                                    {/* <div className="button_add" >
                                            <Button style={{ width: "40px" }} href="#outlined-buttons">
                                                <InsertData fetchData={fetchClient} type={"btn"} />
                                            </Button>
                                        </div> */}
                                    {/* <Select
                                        inputId="AccountID"
                                        className="Transfers__Container__Format__SameInput"
                                        value={{ label: clientName, value: clientID }}
                                        options={filteredClient.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectClient}
                                        isClearable
                                        placeholder="Select account"
                                        noOptionsMessage={() => 'No matching'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "50px"
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",
                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        fullWidth
                                        {...defaultPropsClients}
                                        // sx={{ m: 2 }}
                                        id="include-input-in-list"
                                        includeInputInList
                                        value={field}  // Lidheni me state-in `selectedArticle`
                                        onChange={handleAutocompleteChangeClient}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "90%", marginLeft: "5%" }}
                                                {...params}
                                                label="Përzgjedh Klientin"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </form>
            <div></div>
        </div >
    );
}

export default AddReceivables;