const currencyFormatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const AccountBookGridHeader = [
    { field: 'id', headerName: '#', width: 40, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true },
    { field: 'type', headerName: 'Lloji', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'inv', headerName: 'Numri Faturës', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'tipi', headerName: 'Tipi', width: 100, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'supplier', headerName: 'Emri i Furnitorit', width: 200, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'idNumber', headerName: 'Numri Identifikues', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'vatNumber', headerName: 'Numri i TVSH-së', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'total', headerName: 'Total', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'amount0', headerName: 'Vlera me 0%', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'amount8', headerName: 'Vlera me 8%', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'vat8', headerName: 'TVSH 8%', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'amount18', headerName: 'Vlera me 18%', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'vat18', headerName: 'TVSH 18%', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

export const AccountBookGridHeaderNOVat = [
    { field: 'id', headerName: '#', width: 40, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true },
    { field: 'type', headerName: 'Lloji', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 120, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'inv', headerName: 'Numri Faturës', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'tipi', headerName: 'Tipi', width: 100, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'supplier', headerName: 'Emri i Furnitorit', width: 350, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'idNumber', headerName: 'Numri Identifikues', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'vatNumber', headerName: 'Numri i TVSH-së', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'total', headerName: 'Total', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

