import ExcelJS from 'exceljs';

export const TaxPurchaseExcelFormNoVAT = (filteredData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Shtoni titullin e kolonave në rreshtin e parë
    worksheet.mergeCells('A1', 'A3');
    const cellA1A2 = worksheet.getCell('A1');

    cellA1A2.value = 'NR.';
    cellA1A2.width = 50; // Përcakton width në 100 piksela
    cellA1A2.height = 125; // Përcakton height në 50 piksela
    cellA1A2.border = {
        top: { style: 'thin', color: { argb: '00000000' }, bold: true },
        left: { style: 'thin', color: { argb: '00000000' }, bold: true },
        bottom: { style: 'thin', color: { argb: '00000000' }, bold: true },
        right: { style: 'thin', color: { argb: '00000000' }, bold: true }
    };
    cellA1A2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    cellA1A2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }


    worksheet.mergeCells('B1', 'C1');
    const cellC1 = worksheet.getCell('B1');
    cellC1.value = 'Fatura';
    cellC1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };
    cellC1.alignment = { vertical: 'middle', horizontal: 'center' };
    cellC1.width = 170; // Përcakton width në 100 piksela
    cellC1.height = 27; // Përcakton height në 50 piksela
    cellC1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    worksheet.mergeCells('B2', 'B3');
    const data = worksheet.getCell('B2');
    data.value = 'Data';
    data.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    data.alignment = { vertical: 'middle', horizontal: 'center' };
    data.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    worksheet.mergeCells('C2', 'C3');
    const nrFatures = worksheet.getCell('C2');
    nrFatures.value = 'Numri Faturës';
    nrFatures.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    nrFatures.alignment = { vertical: 'middle', horizontal: 'center' };
    nrFatures.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    worksheet.mergeCells('D1', 'E1');
    const bleresi = worksheet.getCell('D1');
    bleresi.value = 'Shitësi';
    bleresi.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    bleresi.alignment = { vertical: 'middle', horizontal: 'center' };
    bleresi.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    worksheet.mergeCells('D2', 'D3');
    const emriBleresit = worksheet.getCell('D2');
    emriBleresit.value = 'Emri i shitësit';
    emriBleresit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    emriBleresit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    emriBleresit.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    worksheet.mergeCells('E2', 'E3');
    const NRFBlersit = worksheet.getCell('E2');
    NRFBlersit.value = 'Numri Unik Identifikues / Numri Fiskal / Numri Personal i shitësit';
    NRFBlersit.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    NRFBlersit.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    NRFBlersit.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    worksheet.mergeCells('F1', 'H1');
    const f1f2 = worksheet.getCell('F1');
    f1f2.value = 'Blerje vendore';
    f1f2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    f1f2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    f1f2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    const cellF2 = worksheet.getCell('F2');
    cellF2.value = 'Blerje e mallrave / shërbimeve';
    cellF2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellF2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    cellF2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    };

    const cellF3 = worksheet.getCell('F3');
    cellF3.value = 'a';
    cellF3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellF3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    cellF3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2C2' }
    };

    const g2 = worksheet.getCell('G2');
    g2.value = 'Blerje për shpenzime';
    g2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    g2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    g2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    };

    const g3 = worksheet.getCell('G3');
    g3.value = 'b';
    g3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    g3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    g3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2C2' }
    };

    const h2 = worksheet.getCell('H2');
    h2.value = 'Blerje investive';
    h2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    h2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    h2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    };

    // H2 dhe H3
    const h3 = worksheet.getCell('H3');
    h3.value = 'c';
    h3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    h3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true // Lejon tekstin të shpërfaqet në disa rreshta nëse është shumë i gjatë
    };
    h3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2C2' }
    };

    worksheet.mergeCells('I1', 'K1');
    const i1 = worksheet.getCell('I1');
    i1.value = 'Importe';
    i1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    i1.alignment = { vertical: 'middle', horizontal: 'center' };
    i1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    }

    // I2 dhe I3
    const i2 = worksheet.getCell('I2');
    i2.value = 'Importe të mallrave';
    i2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    i2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    i2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    };

    const i3 = worksheet.getCell('I3');
    i3.value = 'd';
    i3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    i3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    i3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2C2' }
    };

    const J2 = worksheet.getCell('J2');
    J2.value = 'Importe investive';
    J2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    J2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    J2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    };

    const J3 = worksheet.getCell('J3');
    J3.value = 'e';
    J3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    J3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    J3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2C2' }
    };

    const K2 = worksheet.getCell('K2');
    K2.value = 'Blerje të shërbimeve jashtë vendit';
    K2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    K2.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    K2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    };

    const K3 = worksheet.getCell('K3');
    K3.value = 'f';
    K3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    K3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    K3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2C2' }
    };

    worksheet.mergeCells('L1', 'L2');
    const L1 = worksheet.getCell('L1');
    L1.value = 'Blerjet totale';
    L1.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    L1.alignment = { vertical: 'middle', horizontal: 'center' };
    L1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' }
    };

    const L3 = worksheet.getCell('L3');
    L3.value = 'g=a+b+c+d+e+f';
    L3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    L3.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    };
    L3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2C2' }
    };

    // Përdorni map për të shtuar rreshtat e të dhënave
    filteredData.map((item, index) => {
        const row = [];
        const total = (parseFloat(item.BlerjeVendoreMallrave) || 0) + (parseFloat(item.BlerjeVendoreShpenzimeve) || 0) + (parseFloat(item.BlerjeInvestive) || 0) + (parseFloat(item.ImporteMallra) || 0) + (parseFloat(item.ImporteInvestive) || 0) + (parseFloat(item.ImporteSherbimeve) || 0);

        // Shtoni vlerat për secilën kolonë bazuar në të dhënat e furnizuara
        row.push(item.Nr);
        row.push(item.Date || '');
        row.push(item.InvoiceNr || '');
        row.push(item.AccountName || '');
        row.push(item.IdentificationNumber || '');
        row.push(item.BlerjeVendoreMallrave || '');
        row.push(item.BlerjeVendoreShpenzimeve || '');
        row.push(item.BlerjeInvestive || '');
        row.push(item.ImporteMallra || '');
        row.push(item.ImporteInvestive || '');
        row.push(item.ImporteSherbimeve || '');

        row.push(total.toString());


        // Shtoni rreshtin në sheet
        worksheet.addRow(row);
    });

    // Kthej workbook-in për përdorim në komponentin tjetër
    return workbook;
};
