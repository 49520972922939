export const Month = [
    {
        id:1,
        month:"-01-Jan"
    },
    {
        id:2,
        month:"-02-Feb"
    },
    {
        id:3,
        month:"-03-Mar"
    },
    {
        id:4,
        month:"-04-Apr"
    },
    {
        id:5,
        month:"-05-May"
    },
    {
        id:6,
        month:"-06-Jun"
    },
    {
        id:7,
        month:"-07-Jul"
    },
    {
        id:8,
        month:"-08-Aug"
    },
    {
        id:9,
        month:"-09-Sep"
    },
    {
        id:10,
        month:"-10-Oct"
    },
    {
        id:11,
        month:"-11-Nov"
    },
    {
        id:12,
        month:"-12-Dec"
    },

]