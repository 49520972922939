import React, { useState, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import './../../Style/InsertRent.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import { Month } from "../../OtherData/Month";
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
import InsertTenants from "../Tenants/InsertTenants";
import Autocomplete from "@mui/material/Autocomplete";


function AddRent(props) {
    const TranscationData = [
        {
            id: 1,
            type: "Vendor"
        },
        {
            id: 2,
            type: "Ndërkombëtar"
        },
    ]

    const headers = authHeader();
    const email = LoginUserDetailed();

    const [date, setDate] = useState("");
    const [payDate, setPayDate] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [tenantsID, setTenantsID] = useState("");
    const [amount8, setAmount8] = useState(0);
    const [amount18, setAmount18] = useState(0);
    const [total, setTotal] = useState(0);
    const [coment, setComent] = useState("");
    const [paymentOptions, setPaymentOptions] = useState("");
    const [transactionType, setTransactionType] = useState(1);

    const [tenants, setTenants] = useState([]);
    const [filteredTenants, setFilteredTenants] = useState([]);

    const [cashRegister, setCashRegister] = useState([]);

    const vitAktual = new Date().getFullYear();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Please choose one month!");
            return;
        }
        if (!date) {
            errorAlert("Please select date!");
            return;
        }
        if (!payDate) {
            errorAlert("Pay date is required!");
            return;
        }
        if (!tenantsID) {
            errorAlert("Please choose one tenant!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Please choose payment option!");
            return;
        }
        if (!amount8) {
            errorAlert("Please fill the Net Rent field !");
            return;
        }
        const stateDate = {
            Date: date,
            PayDate: payDate,
            ActionType: "RNT",
            InvoiceNr: invoiceNR,
            // AccountID: 702,
            SupplierID: tenantsID,
            // Amount0: amount0,
            Amount8: amount8,
            Amount18: amount18,
            Total: total,
            PaymentOptions: paymentOptions,
            TransactionType: transactionType,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'rent', stateDate, { headers })
            .then(() => {
                handleSuccess("Sales Added Successfully");
                setDate("");
                setPayDate("");
                setInvoiceNR("");
                setTenantsID("");
                // setAmount0(0);
                setAmount8(0);
                setAmount18(0);
                setTotal(0);
                setPaymentOptions("");
                setTransactionType(1);
                setTenantsName("");
                setComent("");
                setFilteredTenants(null);
                setfield(null);
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    // useEffect(() => {
    //     const vat9 = (amount0 / 0.91); // llogarit produktin me 18% shumëzim
    //     setAmount8(vat9); // vendos vlerën e produktit në inputin e dytë
    // }, [amount0]);

    // useEffect(() => {
    //     const zbritja = Number(amount8 - amount0); // llogarit produktin me 18% shumëzim
    //     setAmount18(zbritja); // vendos vlerën e produktit në inputin e dytë
    // }, [amount0, amount8]);

    const fetchTenants = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 308 } });
            setTenants(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredTenants(
            tenants.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, tenants]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [tenantsName, setTenantsName] = useState("");
    const handleFilterTenants = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setTenantsID(selectedOption.AccountID);
            setTenantsName(selectedOption.label);
        } else {
            setTenantsID("");
            setTenantsName("");
        }
    };

    const calculateNeto = async (brutoValue) => {
        try {
            const tatimiNeQira = Number(brutoValue) * (Number(9) / Number(100));
            const qirajaNeto = Number(brutoValue) - Number(tatimiNeQira);

            setAmount8(qirajaNeto);
            setAmount18(tatimiNeQira);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateBruto = async (netoValue) => {
        try {
            const brutoRent = Number(netoValue) / Number(0.91);
            const tatimiNeQira = Number(brutoRent) - Number(netoValue);

            setTotal(brutoRent);
            setAmount18(tatimiNeQira);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateFromTatimi = async (tatimivalue) => {
        try {
            const brutoRent = (Number(tatimivalue) / Number(9)) * Number(100);
            const qirajaNeto = Number(brutoRent) - Number(tatimivalue);

            setAmount8(qirajaNeto);
            setTotal(brutoRent);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    //Pjesa per select input field tenant
    const [field, setfield] = useState(null);
    const handleAutocompleteChangeTenants = (event, value) => {
        if (value && value.AccountID) {
            setTenantsID(value.AccountID);
            setfield(value); // Përditëso `selectedArticle` me vlerën e zgjedhur


        } else {
            // Në rast se vlera është pastruar
            setTenantsID(""); // Vendos vlerën e ArticleID në null
            setfield(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsTenants = {
        options: tenants,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                    {/* <button
                          onClick={(e) => {
                              e.stopPropagation();
                              // handleButtonClick(option);
                          }}
                          onMouseEnter={handleOpen}
                          onMouseLeave={handleClose}
                          style={{ marginLeft: 'auto' }}
                      >
                          Click
                      </button> */}
                </li>
            </>
        ),
    };
    //////
    const getLastDateOfMonth = (year, month) => {
        // Muaji është 0-indexed në JavaScript
        const lastDay = new Date(year, month, 0).getDate();
        
        // Sigurohemi që muaji dhe dita janë të dy 2-shifror
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = lastDay < 10 ? `0${lastDay}` : lastDay;
        // Formatojmë datën në 'yyyy-MM-dd'
        const newDate = `${year}-${formattedMonth}-${formattedDay}`;
                
        setDate(newDate);
        setPayDate(newDate);
      };

    useEffect(() => {
        if (invoiceNR) {
          const dashIndex = invoiceNR.indexOf('-');
          const selectedMonth = parseInt(invoiceNR.substring(dashIndex + 1, dashIndex + 3));
          getLastDateOfMonth(vitAktual, selectedMonth);
        }
      }, [invoiceNR, vitAktual]);


    useEffect(() => {
        // fetchrent();
        fetchTenants();
        fetchPaymentType();
    }, [])

    return (
        <div className="Rent__Container">
            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Qira e re</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha te dhenat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className="Rent__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <InsertTenants fetchData={fetchTenants} type="insert" />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                        >
                            <button className="button-15"
                                onClick={handleSubmit}
                            >
                                Ruaj & Ri
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to={"/expences/rent"}
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbyll
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2'></div>
            <form className='Rent__Container__Format'>
                <Paper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='Forma1'>
                            <div className='Boxx'>
                                <TextField
                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                    // style={{ width: "594px" }}                                    id="invoiceNR"
                                    select
                                    label="Muaji"
                                    defaultValue=""
                                    value={invoiceNR}
                                    onChange={event => setInvoiceNR(event.target.value)}
                                    required
                                >
                                    {Month.map((data) => (
                                        <MenuItem key={data.id} value={vitAktual + data.month}>{vitAktual}{data.month}
                                        </MenuItem>
                                    ))}
                                </TextField>


                                <div className='AddPurchaseTotal__Container__Format__Dates'>
                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="date"
                                        type="date"
                                        variant="outlined"
                                        name='Date'
                                        label="Data"
                                        value={date}
                                        onChange={(e) => { setDate(e.target.value); setPayDate(e.target.value) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="PayDate"
                                        type="date"
                                        variant="outlined"
                                        name='PayDate'
                                        label="Data pagesës"
                                        value={payDate}
                                        onChange={(e) => setPayDate(e.target.value)}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="textfield_button">
                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        {/* <span style={{ color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Qiradhënësin</span>
                                        <Select
                                            className="AddPurchaseTotal__Container__Format__SelectList"
                                            inputId="SupplierID"
                                            value={{ label: tenantsName, value: tenantsID }}
                                            options={filteredTenants.map((data) => ({
                                                label: data.AccountName,
                                                value: data.AccountID,
                                                AccountID: data.AccountID
                                            }))}
                                            onInputChange={handleInputChange}
                                            onChange={handleFilterTenants}
                                            isClearable
                                            placeholder="Select"
                                            noOptionsMessage={() => 'No matching'}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    height: "55px",
                                                    marginLeft: "10px", marginRight: "10px"

                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "#fff",
                                                    zIndex: 9999,
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                    borderRadius: "4px",
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    maxHeight: "200px",
                                                    overflowY: "auto",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#000",
                                                }),
                                            }}
                                        /> */}
                                        <Autocomplete
                                            fullWidth
                                            {...defaultPropsTenants}
                                            id="include-input-in-list"
                                            includeInputInList
                                            value={field}  // Lidheni me state-in `selectedArticle`
                                            onChange={handleAutocompleteChangeTenants}
                                            isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                            renderInput={(params) => (
                                                <TextField
                                                    className="AddPurchaseTotal__Container__Format__SameInput"

                                                    {...params}
                                                    label="Përzgjedh Qiradhënësin"
                                                // variant="filled"
                                                //   onKeyPress={handleKeyPress}
                                                />
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="AddPurchaseTotal__Container__Format__Dates" style={{ paddingTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "593px" }}
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="AccountID"
                                        label="Klasa e shpenzimit"
                                        defaultValue=""
                                        value={"308-Qiraja e Pagueshme"}
                                        // onChange={event => setAccountID(703)}
                                        disabled
                                    >
                                    </TextField>

                                    <TextField
                                        className="AddPurchaseTotal__Container__Format__Date"
                                        id="id"
                                        select
                                        label="Lloji qirasë"
                                        defaultValue=""
                                        value={transactionType}
                                        onChange={event => setTransactionType(event.target.value)}
                                        required
                                    >
                                        {TranscationData.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>{data.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <TextField
                                    // style={{ width: "593px" }}
                                    className="AddPurchaseTotal__Container__Format__SameInput"
                                    id="PaymentType"
                                    select
                                    label="Përzgjedh llojin e pagesës"
                                    defaultValue=""
                                    // helperText="Please select expense"
                                    value={paymentOptions}
                                    onChange={event => setPaymentOptions(event.target.value)}
                                >
                                    <MenuItem key="0" value="0">Pagesa Behet më vonë
                                    </MenuItem>
                                    {cashRegister.map((data) => (
                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className='box2'>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Qiraja Neto"
                                        variant="outlined"
                                        name='Amount8'
                                        value={amount8 !== null && amount8 !== 0 ? amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount8(event.target.value)}
                                        onKeyUp={event => calculateBruto(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </div>
                                <div className='box3'>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Tatimi në qira 9%"
                                        name='Amount18'
                                        variant="outlined"
                                        value={amount18 !== null && amount18 !== 0 ? amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setAmount18(event.target.value)}
                                        onKeyUp={event => calculateFromTatimi(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box3' style={{ paddingTop: "10px" }}>
                                    <TextField
                                        // style={{ width: "150px" }}
                                        className="AddPurchaseTotal__Container__Format__ValueInput"
                                        id="outlined-basic"
                                        label="Qiraja Bruto"
                                        variant="outlined"
                                        name='Total'
                                        value={total !== null && total !== 0 ? total.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                        onChange={event => setTotal(event.target.value)}
                                        onKeyUp={event => calculateNeto(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <TextField
                                    // style={{ width: "495px" }}
                                    className="AddPurchaseTotal__Container__Format__Comment"
                                    id="Comment"
                                    label="Komenti"
                                    type="text"
                                    name='Comment'
                                    value={coment}
                                    onChange={event => setComent(event.target.value)}
                                    variant="outlined" />
                            </div>
                        </div>

                    </Box>
                </Paper>
            </form>
            <div></div>
        </div>
    );
}

export default AddRent;