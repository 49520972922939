import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiOutlinePrinter, AiOutlineFilePdf } from "react-icons/ai";
import { SiHyperledger } from "react-icons/si";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import jwtDecode from "jwt-decode";
import { MonthlyRentReportHeader } from "./GridHeaders/MonthlyRentReportHeader";
import DataGridComponent from "../../Components/DataGridComponent";
import { errorAlert } from "../../Components/Alerts";


export default function MonthlyRentReport() {
    const [data, setData] = useState([]);
    const headers = authHeader();

    const [searchTerm, setSearchTerm] = useState("");

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    //Kodi per shfaqjen e tabeles ne PDF
    const handleExportPDF = () => {
        const doc = new jsPDF();
        // Teksti i parë në fillim të dokumentit
        const text1 = "AccounTree";
        doc.text(text1, 10, 10); // 20 është pozicioni X, 10 është pozicioni Y

        // Teksti i dytë në fillim të dokumentit
        const text2 = "Trial Balance";
        doc.text(text2, 10, 20); // 20 është pozicioni X, 20 është pozicioni Y

        //    // Teksti i tretë në fillim të dokumentit
        //     const text3 = "Accounting is the process of recording financial transactions pertaining to a business.";
        //     doc.text(text3, 10, 30); // 20 është pozicioni X, 30 është pozicioni Y

        //     //Paragrafi në fillim të dokumentit
        //     const paragraph = "Ky është një paragraf në fillim të tabelës.";
        //     doc.text(paragraph, 20, 40);
        // 20 është pozicioni X, 40 është pozicioni Y
        doc.autoTable({
            head: [["Ledger ID", " Ledger Name", "Bilanc Side", " Debit", " Credit", " Balance", " Financial Statements"]],
            body: filteredData.map((item) => [item.LedgerID, item.LedgerName, item.BilanceSide, item.Debit, item.Credit, item.Balance, item.FinancialStatements]),
            startY: 30
        });
        doc.save("Trialbalance-list.pdf");
    };

    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    // Kodi per Marrjen e informatave nga databaza


    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'FinanceStatement/MonthlyRentReport', { headers });
            setData(response.data);
            setLoading(false);
            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    const filteredData = data.filter((item) => {
        // Verifikoni nëse Vlera8 është null ose string para se të përdorni includes()
        if (item.Vlera8 && typeof item.Vlera8 === 'string') {
            return item.Vlera8.includes(searchTerm);
        }
        return false; // Kthimi false në rast se Vlera8 është null ose nuk është string
    });
    
    const rows = data.map((item, index) => ({
        id: ++index,
        muaji: item.Muaji,
        trs: (item.Transaksione),
        neto: (item.QirajaNeto),
        tax: (item.TatimiNeQira),
        bruto: (item.Gjithsej),
    }));


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="Crud__Container">

            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>Raporti mujor i pagave</h3>
                </div>
            </div>
            <div className="first_paragraph">
                <p>Raporti mujor i qirasë është raport sintetik që bën grumbullimin e qirasë në secilin muaj të vitit fiskal.</p>
            </div>
            <div className="bordere">
                <div className="butonss">
                    <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    {/* <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}
                </div>
            </div>
            <hr></hr>
            <DataGridComponent columns={MonthlyRentReportHeader} rows={rows} />
        </div>
    )
}