import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './../../Style/InsertRent.scss'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { AiOutlineFilePdf, AiFillAppstore } from "react-icons/ai";
import { Paper } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import './../../Style/TableStyle.scss'
import { Month } from "../../OtherData/Month";
import { MdEdit } from "react-icons/md";
import InsertTenants from "../Tenants/InsertTenants";
import { RentGridHeader } from "./RentGridHeader";
import DataGridWithActions from "../../Components/DataGridWithActions";
import Autocomplete from "@mui/material/Autocomplete";

export default function Rent() {
    const TranscationData = [
        {
            id: 1,
            type: "Vendor"
        },
        {
            id: 2,
            type: "Ndërkombëtar"
        },
    ]
    const vitAktual = new Date().getFullYear();
    const [showDetails, setShowDetails] = useState(true);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const loginEmail = LoginUserDetailed();
    const headers = authHeader();

    const [logedEmail, setLogedEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);


    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        TransactionID: "",
        InvoiceNr: "",
        Date: "",
        PayDate: "",
        ActionType: "",
        AccountID: "",
        // TenantsID: "",
        // Amount0: 0,
        Amount8: 0,
        // Amount18: 0,
        // Total: 0,
        // VAT8: "",
        // VAT18: "",
        // Total: "",
        PaymentOptions: "",
        TransactionType: "",
        Coment: "",
        ModifiedByUser: ""
    });

    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [tenants, setTenants] = useState([]);
    const [cashRegister, setCashRegister] = useState([]);

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");

    let number = 1;
    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Rent', { headers });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    function formatDate(date) {
        if (!date) return '';

        const d = (date instanceof Date) ? date : new Date(date);

        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            NUI/NF: ${companyData.IdentificationNumber}
            BN:${companyData.IdentificationNumber}
            Address: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };
    // `${item.ActionType}-${item.FiscalYear}-${item.InvoiceNr}`,

    const addTable = (doc, data, lineY) => {
        doc.autoTable({
            head: [["#", "Rent", "Date", "Tenants", "Neto", "Tax 9%", "Total"]],
            body: filteredData.map((item, index) => [
                number + index,
                item.InvoiceNr,
                formatDate(item.Date),
                item.AccountName,
                item.Amount8.toFixed(2),
                item.Amount18.toFixed(2),
                item.Total.toFixed(2)
            ]),
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "List of Rent", lineY);
        addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        doc.save("Rent.pdf");
    };

    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };


    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };
    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'rent' + `/${deleteItemId}`, { headers }
            );
            deleteAlert("Rent Deleted Successfully");
            fetchData();
        } catch (error) {
            deleteAlert("Rent can not be deleted");
        }
    };

    // Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        calculateBruto(item.Amount8);
        setFormData({
            TransactionID: item.TransactionID,
            InvoiceNr: item.InvoiceNr,
            Date: item.Date,
            PayDate: item.PayDate,
            ActionType: item.ActionType,
            AccountID: item.AccountID,
            // SupplierID: item.TenantsID,
            // Amount0: item.Amount0,
            Amount8: item.Amount8,
            // Amount18: item.Amount18,
            // Total: item.Total,
            // VAT8: item.VAT8,
            // VAT18: item.VAT18,
            PaymentOptions: item.PaymentOptions,
            TransactionType: item.TransactionType,
            Coment: item.Coment,
            ModifiedByUser: loginEmail,

        });
        setShowEditModal(true);
        setShowDetails(false);
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'rent', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                setShowDetails(true);
                updateAlert("Rent updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update State");
                errorAlert("Failed to update Rent")
            });
    };

    const filteredData = data.filter((item) => {
        return (
            item.ActionType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.InvoiceNr.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    useEffect(() => {
        axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers, params: { email: logedEmail } })
            .then((response) => {
                setCashRegister(response.data);
            }).catch((error) => {
                // errorAlert(error);
            })
    }, [logedEmail])

    const fetchTenants = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 308 } });
            setTenants(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [qirajaBruto, setQirajaBruto] = useState(0);
    const [tatimiNeQira, setTatimiNeQira] = useState(0);

    const calculateNeto = async (brutoValue) => {
        try {
            const tatimiNeQira = Number(brutoValue) * (Number(9) / Number(100));
            const qirajaNeto = Number(brutoValue) - Number(tatimiNeQira);

            setFormData(prevState => ({
                ...prevState,
                Amount8: qirajaNeto
            }));
            setTatimiNeQira(tatimiNeQira);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateBruto = async (netoValue) => {
        try {
            const brutoRent = Number(netoValue) / Number(0.91);
            const tatimiNeQira = Number(brutoRent) - Number(netoValue);

            setQirajaBruto(brutoRent);
            setTatimiNeQira(tatimiNeQira);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const calculateFromTatimi = async (tatimivalue) => {
        try {
            const brutoRent = (Number(tatimivalue) / Number(9)) * Number(100);
            const qirajaNeto = Number(brutoRent) - Number(tatimivalue);

            setFormData(prevState => ({
                ...prevState,
                Amount8: qirajaNeto
            }));
            setQirajaBruto(brutoRent);
        } catch (error) {
            console.error("Error during calculation:", error);
            // Perform any necessary error handling here
        }
    };

    const rows = data.map((item, index) => ({
        id: ++index,
        month: item.InvoiceNr,
        date: (item.Date.substring(0, 10)),
        accname: item.AccountName,
        neto: item.Amount8,
        tatimi: item.Amount18,
        total: item.Total,
        deleteItemId: item.TransactionID,
        rowInfo: item
    }));


    //Select list for tenant
    const handleAutocompleteChangeTenant = (event, value) => {
        if (value) {
            setFormData(prevState => ({
                ...prevState,
                AccountID: value.AccountID
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                AccountID: ""
            }));
        }
    };

    const defaultPropsTenant = {
        options: tenants,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                    {/* <button
                        onClick={(e) => {
                            e.stopPropagation();
                            // handleButtonClick(option);
                        }}
                        onMouseEnter={handleOpen}
                        onMouseLeave={handleClose}
                        style={{ marginLeft: 'auto' }}
                    >
                        Click
                    </button> */}
                </li>
            </>
        ),
    };
    ////

    useEffect(() => {
        fetchData();
        fetchCompaniesData();
        fetchTenants();
    }, [logedEmail]);

    return (
        <div className="Rent__Container">
            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div className="edit">
                    <div className="header_flex">
                        <div className="header_icon">
                            <MdEdit style={{ color: '#466F86', width: "40px" }} />
                            <h3>Ndryshimi Qirasë</h3>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="Rent__Container__AllButtons">
                        <div style={{ display: "flex", }}>
                            <InsertTenants fetchData={fetchTenants} type="insert" />
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "5px" }}>
                                <Link to="#"
                                    style={{ width: "110px", textDecoration: "none", color: "green" }}
                                >
                                    <button type="submit" className="button-15" variant="contained" onClick={handleSubmit}>Ndrysho</button>

                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button
                                        className="button-15" variant="contained"
                                        onClick={() => {
                                            setShowDetails(true);
                                            setShowEditModal(false);
                                        }}
                                    >
                                        Anulo
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='header_paper2'></div>
                    <form className='Rent__Container__Format'>
                        <Paper>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className='Forma1'>
                                    <div className='Boxx'>
                                        <TextField
                                            className="AddPurchaseTotal__Container__Format__SameInput"
                                            // style={{ width: "594px" }}
                                            id="InvoiceNr"
                                            select
                                            name="InvoiceNr"
                                            label="Muaji"
                                            value={formData.InvoiceNr}
                                            onChange={handleInputChange}
                                        >
                                            {Month.map((data) => (
                                                <MenuItem key={data.id} value={vitAktual + data.month}>{vitAktual}{data.month}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <div className='AddPurchaseTotal__Container__Format__Dates'>
                                            <TextField
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="Date"
                                                type="date"
                                                variant="outlined"
                                                name='Date'
                                                label='Data'
                                                value={formData.Date && formData.Date.substring(0, 10)}
                                                onChange={handleInputChange}
                                            />

                                            <TextField
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="PayDate"
                                                type="date"
                                                variant="outlined"
                                                name='PayDate'
                                                label="Data pagesës"
                                                value={formData.PayDate && formData.PayDate.substring(0, 10)}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div className="textfield_button">
                                            {/* <TextField
                                                // style={{ width: "504px" }}
                                                className="AddPurchaseTotal__Container__Format__SameInput"
                                                id="AccountID"
                                                name="AccountID"
                                                select
                                                label="Select tenants"
                                                value={formData.AccountID}
                                                onChange={handleInputChange}
                                            >
                                                {tenants.map((data) => (
                                                    <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                            <Autocomplete
                                                fullWidth
                                                // sx={{ m: 2 }}
                                                {...defaultPropsTenant}
                                                id="AccountID"
                                                includeInputInList
                                                onChange={handleAutocompleteChangeTenant}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        label="Qiradhënësi"
                                                        // variant="filled"
                                                        className="AddPurchaseTotal__Container__Format__SameInput"
                                                    />
                                                )}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                        overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                    },
                                                }}
                                                value={tenants.find(option => option.AccountID === formData.AccountID) || null}
                                            />
                                        </div>
                                        <div className="AddPurchaseTotal__Container__Format__Dates" style={{ paddingTop: "10px" }}>
                                            <TextField
                                                // style={{ width: "593px" }}
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="AccountID"
                                                label="Llogaria Kontabel"
                                                defaultValue=""
                                                value={"308-Qiraja e Pagueshme"}
                                                // onChange={event => setAccountID(703)}
                                                disabled
                                            >
                                            </TextField>

                                            <TextField
                                                // style={{ width: "343px" }}
                                                className="AddPurchaseTotal__Container__Format__Date"
                                                id="id"
                                                select
                                                name="TransactionType"
                                                label="Lloji qirasë"
                                                value={formData.TransactionType}
                                                onChange={handleInputChange}
                                            >
                                                {TranscationData.map((data) => (
                                                    <MenuItem key={data.id} value={data.id}>{data.type}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <TextField
                                            // style={{ width: "593px" }}
                                            className="AddPurchaseTotal__Container__Format__SameInput"
                                            id="id"
                                            select
                                            label="Mënyra pagesës"
                                            name="PaymentOptions"
                                            value={formData.PaymentOptions}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem key="0" value="0">Pagesa Behet më vonë
                                            </MenuItem>
                                            {cashRegister.map((data) => (
                                                <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                    <div className='box2'>
                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="Amount8"
                                            label="Qiraja Neto"
                                            variant="outlined"
                                            name='Amount8'
                                            value={formData.Amount8 !== null && formData.Amount8 !== 0 ? formData.Amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={handleInputChange}
                                            onKeyUp={event => calculateBruto(event.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="outlined-basic"
                                            label="Tatimi në qira 9%"
                                            name='Amount18'
                                            variant="outlined"
                                            value={tatimiNeQira !== null && tatimiNeQira !== 0 ? tatimiNeQira.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={event => setTatimiNeQira(event.target.value)}
                                            onKeyUp={event => calculateFromTatimi(event.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            // style={{ width: "150px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            id="outlined-basic"
                                            label="Qiraja Bruto"
                                            variant="outlined"
                                            name='Total'
                                            value={qirajaBruto !== null && qirajaBruto !== 0 ? qirajaBruto.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                            onChange={event => setQirajaBruto(event.target.value)}
                                            onKeyUp={event => calculateNeto(event.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            // style={{ width: "495px" }}
                                            className="AddPurchaseTotal__Container__Format__Comment"
                                            type="text"
                                            id="Coment"
                                            name="Coment"
                                            label="Komenti"
                                            variant="outlined"
                                            value={formData.Coment}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Paper>
                    </form>
                </div>
            )}
            {showDetails && (
                <div className="shfaqePermbajtjen">
                    <div className="header_flex">
                        <div className="header_icon">
                            <AiFillAppstore style={{}} className="header_icons" />
                            <h3>Lista e Qirasë</h3>
                        </div>
                        <div>
                            <Link to={"/expences/AddRent"}>
                                <button className="button-68">
                                    <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />   Krijo një Qira
                                </button>
                            </Link>
                            {/* <InsertData fetchData={fetchData} /> */}
                        </div>
                    </div>
                    <div className="header_paragraph">
                        <p>Forma e Qirasë: Menaxhoni, shtoni, editoni dhe fshini Transaksione të Qirasë.</p>
                    </div>
                    <DataGridWithActions
                        columns={RentGridHeader}
                        rows={rows}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        showPayment={true}
                        height={500}
                    />
                </div>
            )}
        </div>
    )
}