const currencyFormatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const MonthlyWagesReportHeader = [
    { field: 'id', headerName: '#', width: 0, headerClassName: 'super-app-theme--header', groupable: false, aggregable: true},
    { field: 'muaji', headerName: 'Muaji', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'trs', headerName: 'Punonjes', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    // { field: 'client', headerName: 'Furnitori', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'neto', headerName: 'Paga Neto', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'tax', headerName: 'Tatimi ne Paga', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'employeecontribut', headerName: 'Kontributi Punonjesit', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'employerscontribut', headerName: 'Kontributi Punedhënësit', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'bruto', headerName: 'Pagat Bruto', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'total', headerName: 'Gjithsej', type: 'string', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]

