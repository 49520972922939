import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiOutlineFilePdf } from "react-icons/ai";
import { SiHyperledger } from "react-icons/si";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Month } from '../../OtherData/Month'
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { errorAlert } from "../../Components/Alerts";
import { AccountBookGridHeader, AccountBookGridHeaderNOVat } from "./AccountBookGridHeader";
import DataGridComponent from "../../Components/DataGridComponent";

export default function InvestmentBook() {
    const [data, setData] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");

    const [month, setMonth] = useState(0);
    const [ngaData, setNgaData] = useState("");
    const [deriData, setDeriData] = useState("");

    let number = 1;
    const headers = authHeader();

    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");
    const [vatDeclarant, setVatDeclarant] = useState(true);

    const [showMonthFilter, setShowMonthFilter] = useState(true);


    const openDateFilter = () => {
        setShowMonthFilter(false);
        setMonth(0);
        setData([]);
    }
    const openMonthFilter = () => {
        setShowMonthFilter(true);
        setNgaData("");
        setDeriData("");
        setData([]);
    }

    const vitAktual = new Date().getFullYear();

    function formatDate(date) {
        if (!date) return '';

        // Parse the date string into a Date object if it's not already one
        const d = (date instanceof Date) ? date : new Date(date);

        // Format the date components
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const addLogo = (doc) => {
        const logoWidth = 30;
        const logoHeight = 30;
        const logoX = 10;
        const logoY = 5;
        doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    };

    const addSeparatorLine = (doc, lineY) => {
        const lineWidth = doc.internal.pageSize.getWidth() - 10;
        doc.line(10, lineY, 10 + lineWidth, lineY);
    };

    const addLeftText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, 10, lineY + 10);
    };

    const addRightText = (doc, text, lineY) => {
        doc.setFontSize(10);
        doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    };

    const addCompanyDetails = (doc, companyData) => {
        doc.setFontSize(10);
        const companyDetails = `
            ${companyData.CompanyName}
            Numri Identifikues: ${companyData.IdentificationNumber}
            Numri i TVSH-së:${companyData.VATNumber}
            Adresa: ${companyData.Address}
            Tel: ${companyData.PhoneNumbers}
            Email: ${companyData.Email}
        `;
        doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    };

    const addTable = (doc, data, lineY) => {
        let amount0 = 0;
        let amount8 = 0;
        let amount18 = 0;
        let vat8 = 0;
        let vat18 = 0;
        let total = 0;

        const bodyData = filteredData.map((item, index) => {
            amount0 += item.Amount0;
            amount8 += item.Amount8;
            amount18 += item.Amount18;
            vat8 += item.VAT8;
            vat18 += item.VAT18;
            total += item.Total;

            return [
                (number + index),
                item.TransactionType,
                item.Date,
                item.InvoiceNr,
                // item.ActionType,
                item.AccountName,
                item.IdentificationNumber,
                item.VATNumber,
                item.Total !== null && item.Total !== 0 ? item.Total.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                // item.Amount0 !== null && item.Amount0 !== 0 ? item.Amount0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                // item.Amount8 !== null && item.Amount8 !== 0 ? item.Amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                // item.VAT8 !== null && item.VAT8 !== 0 ? item.VAT8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                // item.Amount18 !== null && item.Amount18 !== 0 ? item.Amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-',
                // item.VAT18 !== null && item.VAT18 !== 0 ? item.VAT18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-'
                vatDeclarant ? (item.Amount0 !== null && item.Amount0 !== 0 ? item.Amount0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-') : "",
                vatDeclarant ? (item.Amount8 !== null && item.Amount8 !== 0 ? item.Amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-') : "",
                vatDeclarant ? (item.VAT8 !== null && item.VAT8 !== 0 ? item.VAT8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-') : "",
                vatDeclarant ? (item.Amount18 !== null && item.Amount18 !== 0 ? item.Amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-') : "",
                vatDeclarant ? (item.VAT18 !== null && item.VAT18 !== 0 ? item.VAT18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-') : "",
            ];
        });

        // Shtoni rreshtin e totalit në fund të tabelës
        vatDeclarant ?
            bodyData.push([
                '', '', '', '', '', '', 'Totali:',
                total !== null && total !== 0 ? total.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
                (amount0 !== null && amount0 !== 0) ? amount0.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
                (amount8 !== null && amount8 !== 0) ? amount8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
                (vat8 !== null && vat8 !== 0) ? vat8.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
                (amount18 !== null && amount18 !== 0) ? amount18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
                (vat18 !== null && vat18 !== 0) ? vat18.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'
            ]) :
            bodyData.push([
                '', '', '', '', '', '', 'Totali:',
                total !== null && total !== 0 ? total.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00',
            ]);

        doc.autoTable({
            head: vatDeclarant ?
                [
                    ["#", "Lloji", "Data", "Numri Faturës", "Emri i Furnitorit", "Numri Identifikues", "Numri i TVSH-së", "Total", "Vlera 0%", "Vlera 8%", "TVSH 8%", "Vlera 18%", "TVSH 18%"]
                ] :
                [
                    ["#", "Lloji", "Data", "Numri Faturës", "Emri i Furnitorit", "Numri Identifikues", "Numri i TVSH-së", "Total"]
                ],

            body: bodyData,
            startY: lineY + 30,
            columnStyles: {
                2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
            },
            didDrawCell: (data) => {
                if (data.row.index === bodyData.length - 1) { // Kontrolloni nëse është rreshti i fundit (totali)
                    // Cakto ngjyrën e sfondit dhe të tekstit për rreshtin e totalit
                    doc.setFillColor(220, 220, 220); // Ngjyra e sfondit në hiri
                    doc.setTextColor(0, 0, 0); // Ngjyra e tekstit në të bardhë
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Vizato një drejtkëndësh me këtë ngjyrë

                    // Vizato tekstin manualisht
                    if (data.cell.text) {
                        doc.text(data.cell.text, data.cell.x + 2, data.cell.y + data.cell.height - 2);
                    }
                }
            }
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
        });

        const tableMargin = 20;
        const lineY = 30 + tableMargin - 5;

        addLogo(doc);
        addSeparatorLine(doc, lineY);
        addLeftText(doc, "Libri i Blerjeve Investive", lineY);
        if (month !== 0) {
            addRightText(doc, `Muaji: ${vitAktual}-${month}`, lineY);
        } else {
            addRightText(doc, `Periudha: ${formatDate(ngaData)} deri ${formatDate(deriData)}`, lineY);
        } addCompanyDetails(doc, companiesData[0]);
        addTable(doc, filteredData, lineY);

        (month === 0 ?
            doc.save(`LBInvestive_Periudha: ${formatDate(ngaData)} deri ${formatDate(deriData)}.pdf`) :
            doc.save(`LBInvestive_Muaji: ${vitAktual}-${month}.pdf`)
        )
    };

    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const params = {};

    if (month && month >= 1 && month <= 12) {
        params.month = month;
    } else if (ngaData && deriData) {
        params.firstDate = ngaData;
        params.secondDate = deriData;
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingBooks/investmentbook', { headers, params });
            setData(response.data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Kthehet nga serveri për mungesë qasje
            } else {
                errorAlert("Gabim në kërkesë:", error);

                if (error.response && error.response.data && error.response.data.errorMessage) {
                    errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
                } else if (error.message) {
                    errorAlert(`Gabim: ${error.message}`);
                } else {
                    errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
                }
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);
            setVatDeclarant(response.data[0].VATDeclarant);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const filteredData = data.filter((item) => {
        return (
            item.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const rows = data.map((item, index) => {
        const row = {
            id: ++index,
            type: item.TransactionType,
            date: item.Date,
            inv: item.InvoiceNr,
            tipi: item.ActionType,
            supplier: item.AccountName,
            idNumber: item.IdentificationNumber,
            vatNumber: item.VATNumber,
            total: item.Total
        };

        if (vatDeclarant) {
            row.amount0 = item.Amount0 === 0 ? '' : item.Amount0;
            row.amount8 = item.Amount8 === 0 ? '' : item.Amount8;
            row.vat8 = item.VAT8 === 0 ? '' : item.VAT8;
            row.amount18 = item.Amount18 === 0 ? '' : item.Amount18;
            row.vat18 = item.VAT18 === 0 ? '' : item.VAT18;
        }

        return row;
    });


    useEffect(() => {
        if (ngaData && ngaData !== "" && deriData && deriData !== "") {
            fetchData();
        }
    }, [ngaData, deriData]);


    useEffect(() => {
        fetchData();
        fetchCompaniesData();
    }, [month]);

    return (
        <div className="AccountingBooks__Container">

            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>Libri i Blerjeve Investive</h3>
                </div>
            </div>
            <div className="first_paragraph">
                <p>Ky format i librit të blerjeve investive është i përshtatur për shtypje dhe përdorim në dokumentacionin fizik.</p>
            </div>

            <div className="AccountingBooks__Container__Body">
                <div className="AccountingBooks__Container__SearchSection">
                    <div className="AccountingBooks__Container__SearchSection__PDF">
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    {/* <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}

                    <div className="AccountingBooks__Container__SearchSection__Search">
                        <div className="AccountingBooks__Container__SearchSection__Search__Btn">
                            {
                                showMonthFilter ? (
                                    <button className="button-15" onClick={openDateFilter}>Filtro sipas datave</button>
                                ) : (
                                    <>
                                        <button className="button-15" onClick={openMonthFilter}>Filtro sipas Muajit</button>
                                        {/* <button className="button-15" onClick={fetchData}>Filter</button> */}
                                    </>
                                )
                            }
                        </div>

                        <div className="AccountingBooks__Container__SearchSection__Search__Input">
                            {
                                showMonthFilter ? (
                                    <TextField
                                        className="AccountingBooks__Container__SearchSection__Search__Input__Month"
                                        id=""
                                        select
                                        label="Përzgjedh Muajin"
                                        value={month}
                                        onChange={event => { setMonth(event.target.value); fetchData(event.target.value); }}
                                        required
                                    >
                                        {Month.map((data) => (
                                            <MenuItem key={data.id} value={data.id}>
                                                {vitAktual}{data.month}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <div>
                                        <TextField
                                            style={{ marginLeft: "5px" }}
                                            className="SalesDetail__Container__Form__TextField__date"
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            name='Date'
                                            label="Nga"
                                            value={ngaData}
                                            onChange={(e) => { setNgaData(e.target.value); }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            style={{ marginLeft: "5px" }}
                                            className="SalesDetail__Container__Form__TextField__date"
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            name='Date'
                                            label="Deri"
                                            value={deriData}
                                            onChange={(e) => { setDeriData(e.target.value); }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>

                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                vatDeclarant ?
                    <DataGridComponent columns={AccountBookGridHeader} rows={rows} />
                    :
                    <DataGridComponent columns={AccountBookGridHeaderNOVat} rows={rows} />
            }
        </div>
    )
}