import React, { useState, useEffect, useRef } from "react";
import { Paper, TextareaAutosize } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import './../../Style/TableStyle.scss'
import './../../Style/SalesDetailStyle.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { deleteAlert, errorAlert, handleSuccess } from '../../Components/Alerts';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Mui_Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link, useHistory } from "react-router-dom";
import { FaTimes, FaTrash } from "react-icons/fa";
import { AiFillFileAdd } from "react-icons/ai";
// import PDF from "../../Print/PDF";
import Select from 'react-select';
import InsertSupplier from "../Suppliers/InsertSuppliers";
import InsertArticle from "../Article/InsertArticle";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import { importGridHeader } from "./ImportGridHeader";
import DataGridWithActions from "../../Components/DataGridWithActions";
import Autocomplete from '@mui/material/Autocomplete';

function AddImport(props) {
    const history = useHistory();

    const TranscationData = [
        {
            id: 1,
            type: "Vendore"
        },
        {
            id: 2,
            type: "Import"
        },
    ]
    const headers = authHeader();
    const email = LoginUserDetailed();
    const [logedEmail, setLogedEmail] = useState("");
    const [loading, setLoading] = useState(true);


    //Per insertimin e Transaction
    const [date, setDate] = useState("");
    const [payDate, setPayDate] = useState("");
    const [coment, setComent] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [supplierID, setSupplierID] = useState("");
    const [transactionsType, setTransactionType] = useState(2);
    const [paymentOptions, setPaymentOptions] = useState("");
    const [priceWithVAT, setPriceWithVAT] = useState(0);

    const [accountIDSpare, setAccountIDSpare] = useState("");

    // vlerat e dudit
    const [invoiceTransport, setInvoiceTransport] = useState(0);
    const [invoiceTotal, setInvoiceTotal] = useState(0);

    const [transport, setTransport] = useState(0);
    const [importAmount, setImportAmount] = useState(0);
    const [customsReassessment, setCustomsReassessment] = useState(0);
    const [customsCalc, setCustomsCalc] = useState(0);
    // const [customsPercent, setCustomsPercent] = useState(0);
    const [customsValue, setCustomsValue] = useState(0);
    const [exciseTax, setExciseTax] = useState(0);
    const [numriDud, setNumriDud] = useState("");
    const [dudDate, setDudDate] = useState("");

    useEffect(() => {
        let customsCalcTotal = (Number(importAmount) + Number(transport) + Number(customsReassessment))
        setCustomsCalc(customsCalcTotal);
    }, [importAmount, transport, customsReassessment])




    //Per insertimin ne Article History
    const [articleID, setArticleID] = useState("");
    const [qtyinput, setQtyInput] = useState(0);
    const [priceWithoutVAT, setPriceWithoutVAT] = useState(0);
    const [total, setTotal] = useState(0);
    const [vatRates, setVATRates] = useState("");


    const [showDeleteTransactionID, setShowDeleteTransactionID] = useState(false);
    let deleteItemId = null
    const [deleteTransactionID, setDeleteTransactionID] = useState("");

    // sherben per me i hap totalin e fatures ddhe transportin
    const [openTotalInvoice, setOpenTotalInvoice] = useState(false);
    // sherben me i hap detalet e detalet e fatures
    const [openDetails, setOpenDetails] = useState(false);


    const [data, setData] = useState([]);

    const [cashRegister, setCashRegister] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);

    const [suppliersDetails, setSuppliersDetails] = useState([]);

    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const [valuedetails, setValueDetails] = useState([]);


    // ky sherben per detajet e fatures
    const [form1IsDisabled, setForm1IsDisabled] = useState(false);
    // Ky sherben per vleren e fatures dhe transportin
    const [form2IsDisabled, setForm2IsDisabled] = useState(true);
    // ky sherben per detajet e artikullit
    const [form3IsDisabled, setForm3IsDisabled] = useState(true);

    // kjo pjes sherben ne momentin qe klikohet butoni vazhdo me regjistriimin, e hap formen per plotesiimin e totalit te fatures dhe transportit
    const handleEnable = () => {
        setForm1IsDisabled(true);
        setForm2IsDisabled(false);
        setForm3IsDisabled(false);
    }

    const handleEnableInvoiceDetails = () => {
        setForm1IsDisabled(true);
        setForm2IsDisabled(true);
        setForm3IsDisabled(false);
        setOpenDetails(true);
    }
    // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo 
    const [showEditForm, setShowEditForm] = useState(false);
    const [showInserForm, setShowInsertForm] = useState(true);
    // ky kod i ndryshon gjendjet e formave

    const handleOpenForm2 = () => {
        setShowInsertForm(true);
        setShowEditForm(false); // I fsheh formën e parë
        setArticleID();
        setQtyInput(0);
        setTotal(0);
        setTransport(0);
        setImportAmount(0);
        setCustomsReassessment(0);
        setCustomsCalc(0);
        setCustomsValue(0);
        setExciseTax(0);
    };
    const handleOpenForm1 = () => {
        setShowInsertForm(false);
        setShowEditForm(true);
    };

    const newInvoice = () => {
        setForm1IsDisabled(false);
        setForm2IsDisabled(true);
        setForm3IsDisabled(true);
        setOpenDetails(false);
        setOpenTotalInvoice(false);
        setArticleID();
        setQtyInput(0);
        setPriceWithoutVAT(0);
        setTotal(0);
        setInvoiceNR("");
        setDate("");
        setPayDate("");
        setComent("");
        setTransactionType(2);
        setPaymentOptions("")
        setSupplierID("");
        setSupplierName("");
        setSuppliersDetails([]);
        setData([]);
        setValueDetails([]);
        setNumriDud("");
        setDudDate("");
        setInvoiceTotal(0);
        setInvoiceTotal(0);
    }


    const handleEditDetails = (item) => {
        handleOpenForm1();
        fetchArticlesSalesPrice(item.ArticleID, "getVat")
        setFormDetailData({
            HistoryID: item.HistoryID,
            ArticleID: item.ArticleID,
            QtyInput: item.QtyInput,
            PriceWithoutVAT: item.PriceWithoutVAT,
            ImportAmount: item.ImportAmount,
            Transport: item.Transport,
            CustomsReassessment: item.CustomsReassessment,
            ExciseTax: item.ExciseTax,
            CustomsValue: item.CustomsValue,
            CustomsCalc: item.CustomsCalc,
            Total: item.Total,
            ModifiedByUser: logedEmail,
            InvoiceNr: invoiceNR,
            PaymentOptions: paymentOptions,
        });
    };

    const clearEdit = () => {
        setFormDetailData({
            HistoryID: "",
            ArticleID: "",
            QtyInput: 0,
            PriceWithVAT: 0,
            PriceWithoutVAT: 0,
            setFormDetailData: 0
        });

        clearLine();
        handleOpenForm2();
    };


    // keta dy const sherbejn per select field te menyres se pageses se dudit

    const handleAutocompleteChange = (event, value) => {
        if (value) {
            setAccountIDSpare(value.AccountID);
        } else {
            setAccountIDSpare("");
        }
    };

    const defaultProps = {
        options: cashRegister,
        getOptionLabel: (option) => option.AccountName || ""
    };

    ///////////////////////////////////////////////////////////////
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Import/importDetail', { headers, params: { invNumber: invoiceNR } });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    //Kodi sherben per ta marr id e transaksionit qe sherben me pas per ta bere delete
    const fetchTransactionID = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Import/GetTransactionID', { headers, params: { email: logedEmail, invNumber: invoiceNR } });
            setDeleteTransactionID(response.data);
            setLoading(false);

            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (response.data.length === 0) {
                // Shfaqi një mesazh për përdoruesin
                // errorAlert("No data available.");

            }
        } catch (error) {
            console.log(error);
            // Shfaqi një mesazh për përdoruesin për gabimin e tjera
            // setMessage("Error: Failed to fetch data.");
        }
    };

    // i merr te dhenat nga databaza per te ja shfaqur vlerat userit 
    const fetchvaluedetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Import/getvaluedetails', { headers, params: { invNumber: invoiceNR } });
            setValueDetails(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchPaymentType = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/paymenttype', { headers });
            setCashRegister(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const handleDelete = async (deleteItemId) => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Import/oneline' + `/${deleteItemId}`, { headers, params: { email: logedEmail, invoiceNR: invoiceNR } }
            );
            deleteAlert("Importi është fshirë me sukses");

            fetchData();
            fetchvaluedetails();
        } catch (error) {
            deleteAlert("Importi nuk është fshirë");
        }
    };

    // Kodi per fshirjen e nje elementi ne databaz 
    const handleDeleteTransactionID = async () => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'Import' + `/${deleteTransactionID}`, { headers }
            );
            setShowDeleteTransactionID(false);
            deleteAlert("Importi është fshirë me sukses");
            setPaymentOptions("");
            setSupplierID();
            history.push('purchases/import'); // Zëvendëso 'your-desired-path' me path-in e dëshiruar
        } catch (error) {
            deleteAlert("Importi nuk është fshirë");
        }
    };

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 302 } });
            setSuppliers(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message} `);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchSuppliersDetails = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByID', { headers, params: { id: supplierID } });
            setSuppliersDetails(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article', { headers });
            setArticles(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const [buttonContent, setButtonContent] = useState({
        text: "Vazhdo regjistrimin e DUD-it",
        backgroundColor: "#466F86",
        transcation: "background-color 0.3s ease",
        onMouseEnter: "#6c7f8f",
        onMouseLeave: "#7a95a6"
    });
    const [invNumberExist, setInvNumberExist] = useState();
    const checkInvoiceNumber = async (invoiceNR) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Import/invoiceExists', { headers, params: { invNumber: invoiceNR } });
            setInvNumberExist(response.data[0].InvoiceCount);
            if ((response.data[0].InvoiceCount) > 0) {
                setButtonContent({
                    text: "Please choose another invoice number",
                    backgroundColor: "red",
                    transcation: "background-color 0.3s red",
                    onMouseEnter: "red",
                    onMouseLeave: "red"
                });
            }
            else {
                setButtonContent({
                    text: "Vazhdo regjistrimin e DUD-it",
                    backgroundColor: "#466F86",
                    transcation: "background-color 0.3s ease",
                    onMouseEnter: "#6c7f8f",
                    onMouseLeave: "#7a95a6"
                });
            }

        } catch (error) {
            // errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                // errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchArticlesSalesPrice = async (articleID, form) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Article/GetSalesPrice', { headers, params: { articleID: articleID } });
            const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja

            if (form == "edit") {
                // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
                setVATRates(response.data.VATRates);

                setFormDetailData(prevState => ({
                    ...prevState,
                    PriceWithoutVAT: response.data.PurchasePrice,
                    QtyInput: 1,
                    Total: 1 * response.data.PurchasePrice
                }));
            }
            else if (form == "getVat") {
                setVATRates(response.data.VATRates);
                // alert("get vat" + vatRates)
            }
            else {
                setPriceWithVAT(salesPriceData.PurchasePrice);
                setVATRates(salesPriceData.VATRates);
                setQtyInput(1);
                // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
                const calculatedValue = parseFloat(salesPriceData.PurchasePrice) * 100 / (100 + salesPriceData.VATRates);
                setPriceWithoutVAT(calculatedValue.toFixed(2));
                setTotal(1 * salesPriceData.PurchasePrice);
            }
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Please fill invoice number!");
            return;
        }
        if (!date) {
            errorAlert("Please select date!");
            return;
        }
        if (!paymentOptions) {
            errorAlert("Please choose payment option!");
            return;
        }
        if (!supplierID) {
            errorAlert("Please select Supplier!");
            return;
        }
        if (paymentOptions != 0 && payDate == "") {
            errorAlert("Please Fill pay date")
            return;
        }
        const stateDate = {
            Date: date,
            PayDate: paymentOptions !== 0 ? payDate : null,
            InvoiceNr: invoiceNR,
            SupplierID: supplierID,
            TransactionType: transactionsType,
            ActionType: 'IMP',
            PaymentOptions: paymentOptions,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'Import/invoicedetail', stateDate, { headers })
            .then(() => {
                handleSuccess("Importi është shtuar me sukses");
                handleEnable();
                // setOpenDetails(true);
                setOpenTotalInvoice(true)
                // fetchHeaderData();
                fetchTransactionID();
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        if (articleID == "" || articleID == null) {
            errorAlert("please select article");
            return;
        }
        if (qtyinput == "" || qtyinput == null) {
            errorAlert("please fill Qty Input field");
            return;
        }
        // Shtoni kontrollet këtu
        if (isNaN(qtyinput)) {
            errorAlert("Sasia e produkteve (qty input) duhet të jetë një numër.");
            return;
        }
        if (isNaN(priceWithoutVAT)) {
            errorAlert("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
            return;
        }
        if (isNaN(priceWithVAT)) {
            errorAlert("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
            return;
        }
        if (isNaN(total)) {
            errorAlert("Totali duhet të jetë një numër.");
            return;
        }
        const stateDate = {
            InvoiceNr: invoiceNR,
            ArticleID: articleID,
            QtyInput: qtyinput,
            PriceWithoutVAT: priceWithoutVAT,
            ImportAmount: importAmount,
            Transport: transport,
            CustomsReassessment: customsReassessment,
            ExciseTax: exciseTax,
            CustomsValue: customsValue,
            CustomsCalc: customsCalc,
            CreatedByUser: email,
            Date: date,
            SupplierID: supplierID
        };
        axios.post(ApiLink.API_URL + 'Import/createnewline', stateDate, { headers, params: { vatRates: vatRates } })
            .then(() => {
                // handleSuccess("Detail  Added Successfully");
                setArticleID();
                setQtyInput(0);
                setPriceWithoutVAT(0);
                setPriceWithVAT(0);
                setTotal(0);
                fetchData();
                fetchvaluedetails();
                setArticlesName("");
                setTransport(0);
                setImportAmount(0);
                setCustomsReassessment(0);
                setCustomsCalc(0);
                setCustomsValue(0);
                setExciseTax(0);
                setField(null);

            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const handleAddDUDINV = (event) => {
        event.preventDefault();
        if (!accountIDSpare) {
            errorAlert("Ju lutem plotësoni pagesën tek pjesa e informatave të DUD-it!");
            return;
        }
        if (!numriDud) {
            errorAlert("Ju lutem plotësoni numrin e DUD-it!");
            return;
        }
        if (!dudDate) {
            errorAlert("Ju lutem plotësoni datën e DUD-it!");
            return;
        }
        const stateDate = {
            AccountIDSpare: accountIDSpare,
            InvoiceNr: invoiceNR,
            ImpInvoiceDate: dudDate,
            ImpInvoiceNr: numriDud,
            ImportAmountPrelim: invoiceTotal,
            TransportPrelim: invoiceTransport,
            CreatedByUser: email
        };
        axios.post(ApiLink.API_URL + 'Import/updateDudDetailTransaction', stateDate, { headers })
            .then(() => {
                handleEnableInvoiceDetails();
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }


    const clearLine = () => {
        setArticleID();
        setQtyInput(0);
        setPriceWithoutVAT(0);
        setTotal(0);
        fetchData();
        fetchvaluedetails();
        setArticlesName("");
    }

    const [formDetailData, setFormDetailData] = useState({
        HistoryID: "",
        ArticleID: "",
        QtyInput: "",
        PriceWithoutVAT: "",
        ImportAmount: "",
        Transport: "",
        CustomsReassessment: "",
        ExciseTax: "",
        CustomsValue: "",
        CustomsCalc: "",
        Total: "",
        ModifiedByUser: "",
        InvoiceNr: "",
        PaymentOptions: "",
    });

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChangeDetail = (event) => {
        const { name, value } = event.target;
        setFormDetailData({ ...formDetailData, [name]: value });
    };
    const handleSubmitDetailLine = (event) => {
        event.preventDefault();
        axios
            .put(ApiLink.API_URL + 'Import/updatedetailsArticle', formDetailData, { headers, params: { vatRates: vatRates } })
            .then((response) => {
                fetchvaluedetails();
                // fetchArticleDataPrint();
                // fetchHeaderData();
                fetchData();
                handleOpenForm2();
            })
            .catch((error) => {
                // console.log(error);
                errorAlert("Failed to update details")
            });
    }

    const handleCalculateImportAmount = async (type) => {
        if (type === "edit") {
            if (formDetailData && formDetailData.QtyInput && formDetailData.PriceWithoutVAT) {
                let total = formDetailData.QtyInput * formDetailData.PriceWithoutVAT;
                let transportOneLine = (total / invoiceTotal) * invoiceTransport;

                setFormDetailData(prevState => ({
                    ...prevState,
                    ImportAmount: total,
                    Transport: transportOneLine
                }));
            }
        } else {
            if (qtyinput && priceWithoutVAT) {
                let total = qtyinput * priceWithoutVAT;
                let transportOneLine = (total / invoiceTotal) * invoiceTransport;

                setImportAmount(total);
                setTransport(transportOneLine);
            }
        }
    };

    useEffect(() => {
        // Kontrollo nqs supplierID është ndryshuar para se të kryhen veprimet
        if (supplierID !== "") {
            fetchSuppliersDetails();
        }
    }, [supplierID]);


    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredSuppliers(
            suppliers.filter((data) =>
                data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, suppliers]);

    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredArticles(
            articles.filter((data) =>
                data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, articles]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [supplierName, setSupplierName] = useState("");
    const handleFilterSupplier = (selectedOption) => {
        if (selectedOption && selectedOption.AccountID) {
            setSupplierID(selectedOption.AccountID);
            setSupplierName(selectedOption.label);
        } else {
            setSupplierID("");
            setSupplierName("");
        }
    };
    const [articlesName, setArticlesName] = useState("");
    const handleFilterArticles = (selectedOption) => {
        if (selectedOption && selectedOption.ArticleID) {
            setArticleID(selectedOption.ArticleID);
            setArticlesName(selectedOption.label);
            fetchArticlesSalesPrice(selectedOption.ArticleID);
        } else {
            setArticleID("");
            setArticlesName("");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'ArticleID':
                    nextField = document.getElementById('QtyInput');
                    break;
                case 'QtyInput':
                    nextField = document.getElementById('PriceWithoutVAT');
                    break;
                case 'PriceWithoutVAT':
                    nextField = document.getElementById('ImportAmount');
                    break;
                case 'ImportAmount':
                    nextField = document.getElementById('Transport');
                    break;
                case 'Transport':
                    nextField = document.getElementById('CustomsReassessment');
                    break;
                case 'CustomsReassessment':
                    nextField = document.getElementById('CustomsValue');
                    break;
                case 'CustomsValue':
                    nextField = document.getElementById('ExciseTax');
                    break;
                case 'ExciseTax':
                    if (showEditForm === true && showInserForm === false) {
                        handleSubmitDetailLine(e);
                    } else if (showInserForm === true && showEditForm === false) {
                        handleNewSubmit(e);
                    }
                    nextField = document.getElementById('ArticleID');
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select();
            }
        }
    };

    const handleKeyPressForTotals = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let nextField;
            switch (e.target.id) {
                case 'numriDud':
                    nextField = document.getElementById('dudDate');
                    break;
                case 'dudDate':
                    nextField = document.getElementById('InvoiceTotal');
                    break;
                case 'invoiceTotal':
                    nextField = document.getElementById('InvoiceTransport');
                    break;
                case 'InvoiceTransport':
                    handleAddDUDINV(e);
                    break;
                default:
                    return;
            }
            if (nextField && nextField.select) {
                nextField.focus();
                nextField.select();
            }
        }
    };


    const rows = data.map((item, index) => ({
        id: ++index,
        article: item.ArticleName,
        qty: item.QtyInput,
        price: item.PriceWithoutVAT,
        imAmount: item.ImportAmount,
        transport: item.Transport,
        rivlersim: item.CustomsReassessment,
        bazaDoganore: item.CustomsCalc,
        vleraDoganes: item.CustomsValue,
        akciza: item.ExciseTax,
        amount: item.Amount,
        vatAmount: item.VAT,
        total: item.Total,
        rowInfo: item,
        deleteItemId: item.HistoryID
    }));

    //Pjesa per select input field supplier
    const handleAutocompleteChangeSuppliers = (event, value) => {
        if (value && value.AccountID) {
            setSupplierID(value.AccountID);

        } else {
            // Në rast se vlera është pastruar
            setSupplierID(""); // Vendos vlerën e ArticleID në null
        }
    };

    const defaultPropsSuppliers = {
        options: suppliers,
        getOptionLabel: (option) =>
            `${option.IdentificationNumber} - ${option.AccountName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.IdentificationNumber}<br />
                        {option.AccountName}
                    </span>
                </li>
            </>
        ),
    };

    //////

    const [field, setField] = useState(null);
    const handleAutocompleteChangeArticle = (event, value) => {
        if (value && value.ArticleID) {
            setArticleID(value.ArticleID);
            fetchArticlesSalesPrice(value.ArticleID);
            setField(value);
        } else {
            // Në rast se vlera është pastruar
            setArticleID(""); // Vendos vlerën e ArticleID në null
            setField(null);
        }
    };

    const defaultPropsArticle = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                         onClick={(e) => {
                             e.stopPropagation();
                             // handleButtonClick(option);
                         }}
                         onMouseEnter={handleOpen}
                         onMouseLeave={handleClose}
                         style={{ marginLeft: 'auto' }}
                     >
                         Click
                     </button> */}
                </li>
            </>
        ),
    };

    ////////////////////////

    const handleAutocompleteChangeArticleEdit = (event, value) => {
        if (value) {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: value.ArticleID
            }));
            fetchArticlesSalesPrice(value.ArticleID, "edit");
        } else {
            setFormDetailData(prevState => ({
                ...prevState,
                ArticleID: ""
            }));
        }
    };

    const defaultPropsArticleEdit = {
        options: articles,
        getOptionLabel: (option) =>
            `${option.Barcode} - ${option.ArticleName}` || "",
        renderOption: (props, option) => (
            <>
                <li {...props} style={{ borderBottom: "1px solid lightgray" }}>
                    <span>
                        {option.Barcode}<br />
                        {option.ArticleName}
                    </span>
                    {/* <button
                         onClick={(e) => {
                             e.stopPropagation();
                             // handleButtonClick(option);
                         }}
                         style={{ marginLeft: 'auto' }}
                     >
                         click me
                     </button> */}
                </li>
            </>
        ),
    };


    useEffect(() => {
        fetchData();
        fetchSuppliers();
        fetchArticles();
        fetchTransactionID();
        fetchPaymentType();
    }, [logedEmail, invoiceNR, supplierID, articleID, deleteItemId]);

    return (
        <div className="SalesDetail__Container" style={{ paddingTop: "50px" }}>
            <Modal show={showDeleteTransactionID} onHide={() => setShowDeleteTransactionID(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this invoice?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteTransactionID(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDeleteTransactionID}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <br></br>
            <div className="header_flex">
                <div className="header_icon">
                    <AiFillFileAdd style={{}} className="header_icons" />
                    <h3>Import i ri </h3>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha te dhenat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className="SalesDetail__Container__AllButtons">
                <div style={{ display: "flex", }}>
                    <div>
                        <InsertSupplier fetchData={fetchSuppliers} type="insert" />
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <InsertArticle fetchData={fetchArticles} type="insert" />
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="#"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained" onClick={() => { newInvoice(); }}>
                                Ruaj & i ri
                            </button>
                        </Link>
                    </div>

                    {/* <div style={{ marginLeft: "5px" }}>
                        <PDF details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyInput" actiontype="false" />
                    </div> */}
                    <div style={{ marginLeft: "5px" }}>
                        <Link to="/purchases/addImport"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained"
                                onClick={() => {
                                    setShowDeleteTransactionID(true);
                                }}
                            >
                                <FaTrash /> Fshij
                            </button>
                        </Link>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                        <Link
                            to="/purchases/import"
                            style={{ width: "110px", textDecoration: "none" }}
                        >
                            <button className="button-15" variant="contained">
                                <FaTimes /> Mbyll
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='header_paper2' style={{ display: "flex", justifyContent: "flex-end" }}>
            </div>
            <Paper>
                <form onSubmit={handleSubmit} className='header_paper'>
                    <Box
                        // component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {/* <p>Please fill in these fields first to proceed to the fields below</p> */}
                        <div className='SalesDetail__Container__Form'>
                            <div className="SalesDetail__Container__Form__div">
                                <div className="SalesDetail__Container__Form__div__1" style={{ marginTop: "13px" }}>
                                    <div className="SalesDetail__Container__Form__div__2">
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__1"
                                            required
                                            id="invoiceNR"
                                            label="Numri i Faturës"
                                            variant="outlined"
                                            type="text"
                                            name='InvoiceNr'
                                            value={invoiceNR}
                                            onChange={event => { setInvoiceNR(event.target.value); checkInvoiceNumber(event.target.value); }}
                                            disabled={form1IsDisabled}
                                            autoComplete="off"
                                        />
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__1"
                                            style={{ marginTop: "22px" }}
                                            id="id"
                                            select
                                            label="Përzgjedh Tipin e Transaksionit"
                                            defaultValue=""
                                            value={transactionsType}
                                            onChange={event => setTransactionType(event.target.value)}
                                            required
                                            disabled={form1IsDisabled}
                                        >
                                            {TranscationData.map((data) => (
                                                <MenuItem key={data.id} value={data.id}>{data.type}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextareaAutosize style={{ height: "55px", marginLeft: "10px", marginTop: "12px" }}
                                            className="SalesDetail__Container__Form__TextField__1"
                                            id="Comment"
                                            label="Komenti"
                                            type="text"
                                            name='Comment'
                                            value={coment}
                                            onChange={event => setComent(event.target.value)}
                                            variant="outlined"
                                            disabled={form1IsDisabled}
                                            autoComplete="off"

                                        />
                                    </div>
                                    <div className="SalesDetail__Container__Form__TextField__date__Payment">
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__date"
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            label="Data e Faturës"
                                            name='Date'
                                            value={date}
                                            // onChange={(e) => setDate(e.target.value)}
                                            onChange={event => { setDate(event.target.value); setPayDate(event.target.value); checkInvoiceNumber(invoiceNR); }}
                                            disabled={form1IsDisabled}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            className="SalesDetail__Container__Form__TextField__Payment"
                                            style={{ marginTop: "22px" }}
                                            id="PaymentType"
                                            select
                                            label="Përzgjedh Mënyrën e Pagesës"
                                            // defaultValue=""
                                            // helperText="Please select expense"
                                            value={paymentOptions}
                                            disabled={form1IsDisabled}
                                            onChange={event => setPaymentOptions(event.target.value)}
                                        // disabled={form1IsDisabled}

                                        >
                                            <MenuItem key="0" value="0">Pagesa Behet më vonë
                                            </MenuItem>
                                            {cashRegister.map((data) => (
                                                <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {
                                            paymentOptions != 0 ? (
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__date"
                                                    id="PayDate"
                                                    type="date"
                                                    variant="outlined"
                                                    label="Data pagesës"
                                                    name='PayDate'
                                                    value={payDate}
                                                    // onChange={(e) => setDate(e.target.value)}
                                                    onChange={event => { setPayDate(event.target.value); checkInvoiceNumber(invoiceNR); }}
                                                    disabled={form1IsDisabled}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )
                                                :
                                                <TextField
                                                    className="SalesDetail__Container__Form__TextField__date"
                                                    id="PayDate"
                                                    type="date"
                                                    variant="outlined"
                                                    label="Afati pagesës"
                                                    name='PayDate'
                                                    value={payDate}
                                                    // onChange={(e) => setDate(e.target.value)}
                                                    onChange={event => { setPayDate(event.target.value); checkInvoiceNumber(invoiceNR); }}
                                                    disabled={form1IsDisabled}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="SalesDetail__Container__Form__div__4">
                                <div style={{ paddingTop: "10px" }}>
                                    {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Furnitorin</span>
                                    <Select
                                        inputId="SupplierID"
                                        className="SalesDetail__Container__Form__TextField__selectList"
                                        value={{ label: supplierName, value: supplierID }}
                                        options={filteredSuppliers.map((data) => ({
                                            label: data.AccountName,
                                            value: data.AccountID,
                                            AccountID: data.AccountID
                                        }))}
                                        onInputChange={handleInputChange}
                                        onChange={handleFilterSupplier}
                                        isClearable
                                        placeholder="Select"
                                        noOptionsMessage={() => 'No matching'}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                height: "55px",
                                                marginLeft: "10px", marginRight: "10px"

                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: "#fff",
                                                zIndex: 9999,
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "4px",
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "#000",
                                            }),
                                        }}
                                    /> */}
                                    <Autocomplete
                                        {...defaultPropsSuppliers}
                                        id="include-input-in-list"
                                        includeInputInList
                                        onChange={handleAutocompleteChangeSuppliers}
                                        isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: "95%" }}
                                                {...params}
                                                label="Kerko Furnitorin"
                                            // variant="filled"
                                            //   onKeyPress={handleKeyPress}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ display: "flex", paddingTop: "15px" }}>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__IDNumber"
                                        id="outlined-basic"
                                        label="Numri Identifikues"
                                        variant="outlined"
                                        name="IdentificationNumber"
                                        value={suppliersDetails.length > 0 ? suppliersDetails[0].IdentificationNumber : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__IDNumber"
                                        id="outlined-basic"
                                        label="Numri i TVSH-së"
                                        variant="outlined"
                                        name="VatNumber"
                                        value={suppliersDetails.length > 0 ? suppliersDetails[0].VATNumber : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>
                                <div>
                                    <TextField
                                        className="SalesDetail__Container__Form__TextField__2"
                                        id="outlined-basic"
                                        label="Adresa"
                                        variant="outlined"
                                        name="Address"
                                        value={suppliersDetails.length > 0 ? suppliersDetails[0].Adress : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
                {
                    openTotalInvoice ? (
                        <div className="SalesDetail__Container" onKeyPress={handleKeyPressForTotals}>
                            <form>
                                <div style={{ backgroundColor: "gainsboro" }}>
                                    <Box
                                        sx={{
                                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div className="SalesDetail__Container__Second__Form">
                                            <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                <div className="SalesDetail__Container__Second__Form__details">
                                                    <Autocomplete fullWidth sx={{ m: 2 }}
                                                        {...defaultProps}
                                                        id="include-input-in-list"
                                                        includeInputInList
                                                        onChange={handleAutocompleteChange} // Shto funksionin onChange
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Pagesa" variant="standard" />
                                                        )}
                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details">
                                                    <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                        <InputLabel htmlFor="numriDud">Numri i DUD-it</InputLabel>
                                                        <FilledInput
                                                            id="numriDud"
                                                            value={numriDud}
                                                            onChange={(e) => setNumriDud(e.target.value)}
                                                            // disabled={form2IsDisabled}
                                                            readOnly={form2IsDisabled}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details">
                                                    <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                        <InputLabel htmlFor="dudDate" shrink>Data e DUD-it</InputLabel>
                                                        <FilledInput
                                                            id="dudDate"
                                                            value={dudDate}
                                                            type="date"
                                                            onChange={(e) => setDudDate(e.target.value)}
                                                            readOnly={form2IsDisabled}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details">
                                                    <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                        <InputLabel htmlFor="InvoiceTotal">Vlera totale e faturës</InputLabel>
                                                        <FilledInput
                                                            id="invoiceTotal"
                                                            value={invoiceTotal !== null && invoiceTotal !== 0 ? invoiceTotal.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            onChange={(e) => setInvoiceTotal(e.target.value)}
                                                            // disabled={form2IsDisabled}
                                                            readOnly={form2IsDisabled}
                                                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details">
                                                    <FormControl fullWidth sx={{ m: 2 }} variant="filled">
                                                        <InputLabel htmlFor="InvoiceTransport">Transporti</InputLabel>
                                                        <FilledInput
                                                            id="InvoiceTransport"
                                                            value={invoiceTransport !== null && invoiceTransport !== 0 ? invoiceTransport.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            onChange={(e) => setInvoiceTransport(e.target.value)}
                                                            // disabled={form2IsDisabled}
                                                            readOnly={form2IsDisabled}

                                                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                        />
                                                    </FormControl>
                                                </div>
                                                {/* <div className="SalesDetail__Container__Second__Form__details"> */}
                                                <Mui_Button
                                                    variant="contained"
                                                    color="success"
                                                    type="submit"
                                                    onClick={handleAddDUDINV}
                                                    sx={{ m: 2 }}
                                                    disabled={form2IsDisabled}

                                                >
                                                    Vazhdo me detajet e faturës
                                                </Mui_Button>

                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }} onHide>
                            <button
                                className="button-15"
                                variant="contained"
                                type="submit"
                                style={{
                                    background: buttonContent.backgroundColor,
                                    width: "95%",
                                    height: "55px",
                                    alignContent: "center",
                                    transition: buttonContent.transcation,
                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                    color: "white",
                                    border: "none"
                                }}
                                disabled={form1IsDisabled}
                                onClick={invNumberExist > 0 ? null : handleSubmit}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = buttonContent.onMouseEnter;
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = buttonContent.onMouseLeave;
                                }}
                            >
                                {buttonContent.text}
                            </button>
                        </div>
                    )
                }



                {
                    openDetails ?
                        <div className="SalesDetail__Container">
                            <div style={{ backgroundColor: "gainsboro " }}>
                                <hr></hr>
                                {showInserForm && (
                                    <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
                                        <Box
                                            // component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div className="SalesDetail__Container__Second__Form">
                                                <div className="SalesDetail__Container__Second__Form__Article">
                                                    {/* <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh artikullin</span>
                                                    <Select
                                                        className="SalesDetail__Container__Form__TextField__Article__SelectList"
                                                        inputId="ArticleID"
                                                        value={{ label: articlesName, value: articleID }}
                                                        options={filteredArticles.map((data) => ({
                                                            label: data.ArticleName,
                                                            value: data.ArticleID,
                                                            ArticleID: data.ArticleID
                                                        }))}
                                                        onInputChange={handleInputChange}
                                                        onChange={handleFilterArticles}
                                                        isClearable
                                                        placeholder="Select"
                                                        noOptionsMessage={() => 'No matching'}
                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                height: "55px",
                                                                marginLeft: "10px",
                                                                marginRight: "12px",
                                                                backgroundColor: "transparent",
                                                                border: "darkgray solid 1px"
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: "#fff",
                                                                zIndex: 9999,
                                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                borderRadius: "4px",
                                                            }),
                                                            menuList: (provided) => ({
                                                                ...provided,
                                                                maxHeight: "200px",
                                                                overflowY: "auto",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: "#000",
                                                            }),
                                                        }}
                                                    /> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        {...defaultPropsArticle}
                                                        id="ArticleID"
                                                        sx={{ m: 1.5 }}

                                                        includeInputInList
                                                        value={field}
                                                        onChange={handleAutocompleteChangeArticle}
                                                        isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Kerko Artikullin"
                                                            // variant="filled"
                                                            // onKeyPress={handleKeyPress}
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            style: {
                                                                maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                    <div className="SalesDetail__Container__Second__Form__details" style={{ paddingTop: "14px" }}>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="QtyInput"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Sasia"
                                                            name='QtyInput'
                                                            value={typeof qtyinput === 'number' ? qtyinput.toFixed(4) : qtyinput}
                                                            onChange={(e) => setQtyInput(e.target.value)}
                                                            onKeyUp={handleCalculateImportAmount}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
                                                            disabled={form3IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details" style={{ paddingTop: "14px" }}>
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="PriceWithoutVAT"
                                                            type="text"
                                                            variant="outlined"
                                                            label="Çmimi"
                                                            name="PriceWithoutVAT"
                                                            value={priceWithoutVAT !== null && priceWithoutVAT !== 0 ? priceWithoutVAT.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            // value={typeof priceWithoutVAT === 'number' ? priceWithoutVAT.toFixed(4) : priceWithoutVAT}
                                                            onKeyUp={handleCalculateImportAmount}
                                                            onChange={(e) => setPriceWithoutVAT(e.target.value)}
                                                            disabled={form3IsDisabled}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="ImportAmount"
                                                            label="Vlera Importit"
                                                            variant="outlined"
                                                            name='ImportAmount'
                                                            // value={importAmount}
                                                            value={importAmount !== null && importAmount !== 0 ? importAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            onChange={event => setImportAmount(event.target.value)}
                                                            // onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={form3IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="Transport"
                                                            label="Vlera Transportit"
                                                            variant="outlined"
                                                            name='Transport'
                                                            // value={transport}
                                                            value={transport !== null && transport !== 0 ? transport.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            onChange={event => setTransport(event.target.value)}
                                                            // onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={form3IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="CustomsReassessment"
                                                            label="Rivlersimi"
                                                            variant="outlined"
                                                            name='CustomsReassessment'
                                                            // value={customsReassessment}
                                                            value={customsReassessment !== null && customsReassessment !== 0 ? customsReassessment.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            onChange={event => setCustomsReassessment(event.target.value)}
                                                            // onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={form3IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="CustomsValue"
                                                            label="Dogana Ne Vlerë"
                                                            variant="outlined"
                                                            name='CustomsValue'
                                                            // value={customsValue}
                                                            value={customsValue !== null && customsValue !== 0 ? customsValue.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            onChange={event => setCustomsValue(event.target.value)}
                                                            // onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={form3IsDisabled}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details">
                                                        <TextField
                                                            className="SalesDetail__Container__Form__TextField__Output"
                                                            id="ExciseTax"
                                                            label="Akciza Në Vlerë"
                                                            variant="outlined"
                                                            name='ExciseTax'
                                                            // value={exciseTax}
                                                            value={exciseTax !== null && exciseTax !== 0 ? exciseTax.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'}
                                                            onChange={event => setExciseTax(event.target.value)}
                                                            // onKeyUp={handleCalculatePrices}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={form3IsDisabled}
                                                        />
                                                    </div>

                                                    <div className="butonat">
                                                        <div>
                                                            <Button type="submit" variant="success" disabled={form3IsDisabled}
                                                                style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-plus-lg"></i>
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                <i class="bi bi-x-lg"></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="responsive">
                                                        <div className="responsive__butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" disabled={form3IsDisabled}
                                                                    style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="responsive">
                                                <button type="submit"
                                                    className="btn btn-primary float-end"
                                                    disabled={form2IsDisabled}
                                                >ADD</button>
                                            </div> */}
                                            </div>
                                        </Box>
                                    </form>
                                )}
                                {showEditForm && (
                                    <div className="SalesDetail__Container">
                                        <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                {/* <p style={{ color: "red", fontSize: "16px", fontStyle: "italic", borderBottom: "1px dashed red" }}>Click on a line to edit the data</p> */}
                                                <div className="SalesDetail__Container__Second__Form">
                                                    <div className="SalesDetail__Container__Second__Form__Article">
                                                        {/* <TextField
                                                            className="SalesDetail__Container__Form__TextField__Article"
                                                            id="ArticleID"
                                                            select
                                                            label="Select Article"
                                                            name="ArticleID"
                                                            value={formDetailData.ArticleID}
                                                            autoComplete="off"
                                                            // onChange={handleInputChangeDetail}
                                                            onChange={(event) => {
                                                                handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
                                                                fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
                                                            }}
                                                        // onKeyUp={handleCalculatePriceWithoutVATEdit}
                                                        >
                                                            {articles.map((data) => (
                                                                <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField> */}
                                                        <Autocomplete
                                                            fullWidth
                                                            sx={{ m: 1.5 }}
                                                            {...defaultPropsArticleEdit}
                                                            id="ArticleID"
                                                            includeInputInList
                                                            onChange={handleAutocompleteChangeArticleEdit}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    label="Artikulli"
                                                                // variant="filled" 
                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                style: {
                                                                    maxHeight: '250px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                                    overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                                },
                                                            }}
                                                            value={articles.find(option => option.ArticleID === formDetailData.ArticleID) || null}
                                                        />
                                                    </div>
                                                    <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                type="text"
                                                                id="QtyInput"
                                                                name="QtyInput"
                                                                label="Sasia"
                                                                variant="outlined"
                                                                autoComplete="off"
                                                                value={formDetailData.QtyInput}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculateImportAmount("edit")}
                                                            />
                                                        </div>

                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="PriceWithoutVAT"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Çmimi"
                                                                name="PriceWithoutVAT"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
                                                                onChange={handleInputChangeDetail}
                                                                onKeyUp={() => handleCalculateImportAmount("edit")}
                                                            // disabled={form2IsDisabled}
                                                            />
                                                        </div>
                                                        <div className="SalesDetail__Container__Second__Form__details">
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="ImportAmount"
                                                                type="text"
                                                                variant="outlined"
                                                                label="Vlera Importit"
                                                                name="ImportAmount"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.ImportAmount === 'number' ? formDetailData.ImportAmount.toFixed(4) : formDetailData.ImportAmount}
                                                                onChange={handleInputChangeDetail}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="Transport"
                                                                label="Vlera Transportit"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="Transport"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.Transport === 'number' ? formDetailData.Transport.toFixed(2) : formDetailData.Transport}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="CustomsReassessment"
                                                                label="Rivlersimi"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="CustomsReassessment"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.CustomsReassessment === 'number' ? formDetailData.CustomsReassessment.toFixed(2) : formDetailData.CustomsReassessment}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="CustomsValue"
                                                                label="Dogana Ne Vlerë"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="CustomsValue"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.CustomsValue === 'number' ? formDetailData.CustomsValue.toFixed(2) : formDetailData.CustomsValue}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='SalesDetail__Container__Second__Form__details'>
                                                            <TextField
                                                                className="SalesDetail__Container__Form__TextField__Output"
                                                                id="ExciseTax"
                                                                label="Akciza Në Vlerë"
                                                                variant="outlined"
                                                                // name='Amount'
                                                                name="ExciseTax"
                                                                autoComplete="off"
                                                                value={typeof formDetailData.ExciseTax === 'number' ? formDetailData.ExciseTax.toFixed(2) : formDetailData.ExciseTax}
                                                                onChange={handleInputChangeDetail}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="butonat">
                                                            <div>
                                                                <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-check-lg"></i>
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                    <i class="bi bi-x-lg"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="responsive">
                                                            <div className="responsive__butonat">
                                                                <div>
                                                                    <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-check-lg"></i>
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
                                                                        <i class="bi bi-x-lg"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </form>
                                    </div>
                                )}
                                <DataGridWithActions
                                    columns={importGridHeader}
                                    rows={rows}
                                    handleEdit={handleEditDetails}
                                    handleDelete={handleDelete}
                                    showPayment={false}
                                />
                                <hr></hr>
                            </div>
                            <br></br>
                            <div className="responsive__Tablet">
                                <div className='SalesDetail__Container__Form'>
                                    <div>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="Amount18">Vlera 18%</InputLabel>
                                            <FilledInput
                                                id="Amount18"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount18 !== undefined && valuedetails[0].Amount18 !== 0
                                                    ? valuedetails[0].Amount18.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT18">TVSH 18%</InputLabel>
                                            <FilledInput
                                                id="VAT18"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].VAT18 !== undefined && valuedetails[0].VAT18 !== 0
                                                    ? valuedetails[0].VAT18.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                    <br></br>
                                    <div>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="Amount8">Vlera 8%</InputLabel>
                                            <FilledInput
                                                id="Amount8"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount8 !== undefined && valuedetails[0].Amount8 !== 0
                                                    ? valuedetails[0].Amount8.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT8">TVSH 8%</InputLabel>
                                            <FilledInput
                                                id="VAT8"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].VAT8 !== undefined && valuedetails[0].VAT8 !== 0
                                                    ? valuedetails[0].VAT8.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT0">Vlera Faturës</InputLabel>
                                            <FilledInput
                                                id="VAT0"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].ImportAmount !== undefined && valuedetails[0].ImportAmount !== 0
                                                    ? valuedetails[0].ImportAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                    </div>
                                    <br></br>
                                    <div>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="Amount0">Vlera 0%</InputLabel>
                                            <FilledInput
                                                id="Amount0"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount0 !== undefined && valuedetails[0].Amount0 !== 0
                                                    ? valuedetails[0].Amount0.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="VAT0">TVSH 0%</InputLabel>
                                            <FilledInput
                                                id="VAT0"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].VAT0 !== undefined && valuedetails[0].VAT0 !== 0
                                                    ? valuedetails[0].VAT0.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="transporti">Transporti</InputLabel>
                                            <FilledInput
                                                id="transporti"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Transport !== undefined && valuedetails[0].Transport !== 0
                                                    ? valuedetails[0].Transport.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                    </div>
                                    <br></br>
                                    <div >
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="CustomsValue">Vlera Doganës</InputLabel>
                                            <FilledInput
                                                id="CustomsValue"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].CustomsValue !== undefined && valuedetails[0].CustomsValue !== 0
                                                    ? valuedetails[0].CustomsValue.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="ExciseTax">Akciza</InputLabel>
                                            <FilledInput
                                                id="ExciseTax"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].ExciseTax !== undefined && valuedetails[0].ExciseTax !== 0
                                                    ? valuedetails[0].ExciseTax.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled">
                                            <InputLabel htmlFor="CustomsReassessment">Rivlersimi</InputLabel>
                                            <FilledInput
                                                id="CustomsReassessment"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].CustomsReassessment !== undefined && valuedetails[0].CustomsReassessment !== 0
                                                    ? valuedetails[0].CustomsReassessment.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                    </div>
                                    <br></br>
                                    <div >
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled" focused>
                                            <InputLabel htmlFor="TotalAmount">Total pa TVSH</InputLabel>
                                            <FilledInput
                                                id="TotalAmount"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Amount18 !== undefined && valuedetails[0].Amount8 !== undefined && valuedetails[0].Amount0 !== undefined
                                                    ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled" focused>
                                            <InputLabel htmlFor="VATTotal">Total TVSH</InputLabel>
                                            <FilledInput
                                                id="VATTotal"
                                                value={
                                                    valuedetails && valuedetails.length > 0 &&
                                                        valuedetails[0].Total !== undefined &&
                                                        valuedetails[0].Amount18 !== undefined &&
                                                        valuedetails[0].Amount8 !== undefined &&
                                                        valuedetails[0].Amount0 !== undefined
                                                        ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                        : '0.00'
                                                }
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="filled" focused>
                                            <InputLabel htmlFor="Totali">Totali</InputLabel>
                                            <FilledInput
                                                id="Totali"
                                                value={valuedetails && valuedetails.length > 0 && valuedetails[0].Total !== null && valuedetails[0].Total !== 0
                                                    ? valuedetails[0].Total.toLocaleString('en-US', { minimumFractionDigits: 2 })
                                                    : '0.00'}
                                                readOnly
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />
                                        </FormControl>

                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                        </>
                }
                <br></br>
            </Paper>
        </div>
    );
}

export default AddImport;