import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import '../../Style/__button.scss'

function InsertStates(props) {
  const [eLedgerID, setID] = useState("");
  const [eName, seteName] = useState("");
  const [sName, setsName] = useState("");
  const [sClass, setsClass] = useState("");
  const [sBilanceSide, setsBilanceSide] = useState("");
  const [sFS, setsFS] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const headers = authHeader();
    const stateData = {
      LedgerID: eLedgerID,
      LedgerNameEN: eName,
      LedgerNameSQ: sName,
      LedgerClassID: sClass,
      BilanceSide: sBilanceSide,
      FinancialStatements: sFS,
    };
    axios.post(ApiLink.API_URL + 'ledger', stateData, { headers })
      .then((response) => {
        console.log(response.data);
        handleSuccess("Ledger Added Successfully");
        setID("");
        seteName("");
        setsName("");
        setsClass("");
        setsBilanceSide("");
        setsFS("");
        props.onHide();
        props.refreshData(); // Thirrja e funksionit per refresh te dhenave
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 409) {
          errorAlert(error.response.data);
        } else {
          errorAlert("An unknown error occurred while processing the request.");
        }
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
          Insert Ledger
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="input-group mb-3">
              <span className="input-group-text col-3 col-sm-3">Leger ID </span>
              <input
                type="number"
                name='LedgerID'
                className="form-control"
                value={eLedgerID}
                onChange={event => setID(event.target.value)}
                required
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text col-3 col-sm-3">Ledger Name EN </span>
              <input
                type="text"
                name='LedgerNameEN'
                className="form-control"
                value={eName}
                onChange={event => seteName(event.target.value)}
                required
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text col-3 col-sm-3">Ledger Name SQ </span>
              <input
                type="text"
                name='LedgerNameSQ'
                className="form-control"
                value={sName}
                onChange={event => setsName(event.target.value)}
                required
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text col-3 col-sm-3">Ledger Class </span>
              <input
                type="number"
                name='LedgerClassID'
                className="form-control"
                value={sClass}
                onChange={event => setsClass(event.target.value)}
                required
              />
            </div>


            <div className="input-group mb-3">
              <label className="input-group-text col-3 col-sm-3" htmlFor="sideSelect">Balance Side  </label>
              <select
                id="sideSelect"
                className="form-control"
                name='BilanceSide'
                value={sBilanceSide}
                onChange={event => setsBilanceSide(event.target.value)}
                required
              >
                <option value="">Select Bilance Side</option>
                <option >Debi</option>
                <option >Kredi</option>
              </select>
            </div>

            <div className="input-group mb-3">
              <label className="input-group-text col-3 col-sm-3" htmlFor="FSSelect">Financial Statements</label>
              <select
                id="FSSelect"
                className="form-control"
                name='FinancialStatements'
                value={sFS}
                onChange={event => setsFS(event.target.value)}
                required
              >
                <option value="">Select Financial Statements</option>
                <option >BG</option>
                <option >PA</option>
              </select>
            </div>


            <button type="submit"
              className="btn btn-primary float-end"
              style={{ background: "#7a95a6" }}
            >Save</button>
          </div>
        </form>

        <div >
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function InsertData(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const handleRefreshData = () => {
    props.fetchData(); // Thirrja e funksionit per refresh te dhenave
  };

  return (
    <>
      <button  className="button-68  " onClick={() => setModalShow(true)}>
      <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Add New Ledger
      </button>

      <InsertStates
        show={modalShow}
        onHide={() => setModalShow(false)}
        refreshData={handleRefreshData}
      />
    </>
  );
}
