import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import TextField from '../Components/TextField';
import '../Style/Button.css';
import { useState } from 'react';
import '../Form/LoginDesign.css'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
function AuthForm(props) {
  const [loading, setLoading] = React.useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  })

  const onInputChange = (event) => {
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value
    })
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    props.onSubmit(loginData, setLoading);
    setLoading(true);

  }

  return (
    <Formik
      initialValues={props.model
      }
      onSubmit={props.onSubmit}
      validationSchema={Yup.object({
        email: Yup.string().required('This field is required')
          .email('You have to insert a valid email'),
        password: Yup.string().required('This field is required')
      })}
    >
      {formikProps => (
        <Form onSubmit={onSubmitHandler} style={{ display: "flex", flexDirection: "column" }}>
          <div className='Login_input-field'>
            <div className='fab'>
              <FontAwesomeIcon icon={faUser} />
            </div>
            <TextField className='input' placeholder="Email" name="email" type="text" onChange={onInputChange} value={loginData.email} required />
          </div>
          <div className='Login_input-field'>
            <div className='fab'>
              <FontAwesomeIcon icon={faLock} />
            </div>
            <TextField className='input' placeholder="Password" name="password" type="password" onChange={onInputChange} value={loginData.password} required />
          </div>
          <div className='Login_input__Butoni__Login'>
            {/* <Button className='Login_btn' disabled={formikProps.isSubmitting} type="submit">LogIn</Button> */}
            <LoadingButton
              className='Login_btn'
              size="small"
              // onClick={handleClick}
              endIcon={<LoginIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              type="submit"
              disabled={formikProps.isSubmitting}
            >
              <span>Log in</span>
            </LoadingButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}

AuthForm.defaultProps = {
  model: {
    email: '',
    password: '',
  },
};


export default AuthForm;