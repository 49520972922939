import React from "react";

const TextField = (props) => {
  return (
    <div className="form-control-textfieldd">
      <label htmlFor={props.field}>{props.displayName}</label>
      <input
        {...props}
        
        className="form-control-textfieldd"
      />
      <div className="text-danger-textfieldd">
        {props.error}
      </div>
    </div>
  );
};

TextField.defaultProps = {
  type: "text",
};
export default TextField;
