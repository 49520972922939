import "./table.scss";
import React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useState, useEffect } from "react";
import { ApiLink } from "../../../APILink/ApiLink";
import authHeader from "../../../Auth/authHeader";
import { errorAlert } from "../../../Components/Alerts";


const List = () => {
  const [data, setData] = useState([]);

  const headers = authHeader();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + 'Reports/top5transactions', { headers });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);

      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <div className="tableWrapper">
        <Table sx={{ minWidth: 650 }} className="custom-table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell"><b>Tipi</b></TableCell>
              <TableCell className="tableCell"><b>Numri</b></TableCell>
              <TableCell className="tableCell"><b>Emri Llogarisë</b></TableCell>
              <TableCell className="tableCell"><b>Data</b></TableCell>
              <TableCell className="tableCell"><b>Total</b></TableCell>
              {/* <TableCell className="tableCell">Payment Method</TableCell>
            <TableCell className="tableCell">Status</TableCell> */}
            </TableRow>
          </TableHead>
          {loading ? (
            <div className="spinner" style={{ marginLeft: "560px" }} ></div>
          ) : (
            <TableBody>
              {data.map((data) => (
                <TableRow key={data.TransactionID}>
                  <TableCell className="tableCell">{data.ActionType}</TableCell>
                  <TableCell className="tableCell">
                    <div className="cellWrapper">
                      {/* <img src={row.img} alt="" className="image" /> */}
                      {
                        data.ActionType === 'IND' ?
                          <>
                            {data.ActionType + "-" + data.FiscalYear + "-" + data.InvoiceNr}
                          </>
                          :
                          <>
                            {data.InvoiceNr}
                          </>
                      }
                    </div>
                  </TableCell>
                  <TableCell className="tableCell">{data.AccountName}</TableCell>
                  <TableCell className="tableCell">{data.Date.substring(0, 10)}</TableCell>
                  {/* <TableCell className="tableCell">{(data.Total).toFixed(2)}€</TableCell> */}
                  <TableCell className="tableCell">€ {data.Total !== null && data.Total !== 0 ? data.Total.toLocaleString('en-US', { minimumFractionDigits: 2 }) : 0.00}</TableCell>
                  {/* <TableCell className="tableCell">{data.method}</TableCell> */}
                  {/* <TableCell className="tableCell">
                <span className={`status ${data.status}`}>{data.status}</span>
              </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
    </TableContainer>
  );
};

export default List;
