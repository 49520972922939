export default function authHeader() {
  const user = localStorage.getItem('token');

  // console.log(user);

  if (user !=null ) {
    return { Authorization: 'Bearer ' + user };
  } else {
    return {};
  }
}