import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import ShowDetails from './ShowDetails';
import {
  GridToolbar,
} from '@mui/x-data-grid-premium';

function DataGridShowDetails({ columns, rows, fetchAccountsDetails, height }) {
  const gridColumns = [];

  gridColumns.push({
    field: 'actions',
    headerName: 'Veprimet',
    renderCell: (params) => (
      <ShowDetails
        back
        accountID={params.row.showDetails}
        fetchAccountsDetails={fetchAccountsDetails}
      />
    ),
    headerClassName: 'actions-header',
  });

  return (
    <Box
      sx={{
        height: height,
        width: '100%',
        border: "solid lightgray 1px",
        '& .super-app-theme--header': {
          backgroundColor: '#466F86', color: "white"
        },
      }}
    >

      <DataGrid
        rows={rows}
        columns={[
          ...columns,
          ...gridColumns
        ]}
        pageSize={15}
        // disableRowSelectionOnClick
        getRowClassName={(params) => params.row.className}
        initialState={{
          density: 'compact'
        }}
        slots={{
          toolbar: GridToolbar, // Përdorimi i CustomToolbar në vend të GridToolbar
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
      />
      <style jsx>{`
.actions-header {
  background-color: #466F86; /* Ngjyra e sfondit për titullin e kolonës "Actions" */
  color: #FFFFFF; /* Ngjyra e shkronjave e bardhë */
   
}

  .actions-cell {
    background-color: #466F86; /* Ngjyra e sfondit për qelizën e kolonës "Actions" */
  }
  .zero-balance-row {
    background-color: lightgray;
  }
  .non-zero-balance-row {
    /* Stili për rreshtat kur 'balance' nuk është 0 */
  }
`}</style>
    </Box>
  );
}


export default DataGridShowDetails;
