import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import '../../Style/__button.scss'

function InsertBanks(props) {
    const headers = authHeader();


    const [bankName, setBankName] = useState("");
    const [stateID, setStateID] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [state, setState] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const stateData = {
            BankName: bankName,
            StateID: stateID,
            IsActive: isActive,
        };
        axios.post(ApiLink.API_URL + 'LicencedBanks', stateData, { headers })
            .then((response) => {
                console.log(response.data);
                handleSuccess("Licenced bank Added Successfully");
                setBankName("");
                setStateID("");
                props.onHide();
                props.refreshData(); // Thirrja e funksionit per refresh te dhenave
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    };

    useEffect(() => {
        axios.get(ApiLink.API_URL + 'states', { headers })
            .then((response) => {
                setState(response.data);
            }).catch((error) => {
                errorAlert("Can not load States")
            })
    }, [])

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Insert New Bank
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-3 col-sm-3">Bank Name </span>
                            <input
                                type="text"
                                name='BankName'
                                className="form-control"
                                value={bankName}
                                onChange={event => setBankName(event.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group mb-3">
                            <label className="input-group-text col-3 col-sm-3" htmlFor="stateSelect">State Name </label>
                            <select
                                id="stateSelect"
                                className="form-control"
                                value={stateID}
                                onChange={event => setStateID(event.target.value)}
                                required
                            >
                                <option value="">Select State</option>
                                {state.map(state =>
                                    <option key={state.StateID} value={state.StateID}>{state.StateName}</option>
                                )}
                            </select>
                        </div>
                        <button type="submit"
                            className="btn btn-primary float-end"
                            style={{ background: "#7a95a6" }}
                        >Save</button>
                    </div>
                </form>

                <div >
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };

    return (
        <>
            <button className="button-68" onClick={() => setModalShow(true)}>
            <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} /> Add New Bank
            </button>

            <InsertBanks
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}
