import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import { MdManageAccounts } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import './../../Style/TableStyle.scss'
import './../../Style/AccountingSheetStyle.scss'
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import jwtDecode from "jwt-decode";
import { deleteAlert, errorAlert, handleSuccess } from '../../Components/Alerts';
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@mui/icons-material/Delete'; import "jspdf-autotable";
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FaTicketAlt, FaTimes, FaFilePdf, FaTrash, FaEnvelope, FaTable, FaFileInvoice } from "react-icons/fa";
import Autocomplete from "@mui/material/Autocomplete";

function AddAccountingSheet() {
    const currentYear = new Date().getFullYear();
    const [openDetails, setOpenDetails] = useState(false);

    const handleEnable = () => {
        setForm1IsDisabled(true);
        setForm2IsDisabled(false);
    }

    const headers = authHeader();
    const email = LoginUserDetailed();
    const [logedEmail, setLogedEmail] = useState("");
    const [loading, setLoading] = useState(true);

    const [date, setDate] = useState("");
    const [coment, setComent] = useState("");
    const [invoiceNR, setInvoiceNR] = useState("");
    const [actionType, setActionType] = useState("");
    const [ledgerID, setLedgerID] = useState(0);
    const [accountID, setAccountID] = useState();
    const [amount0, setAmount0] = useState(0);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const [lastInvoiceNr, setLastInvoiceNr] = useState('');

    const [data, setData] = useState([]);
    const [ledgersID, setLedgersID] = useState([]);
    const [accountsID, setAccountsID] = useState([]);

    const [form1IsDisabled, setForm1IsDisabled] = useState(false);
    const [form2IsDisabled, setForm2IsDisabled] = useState(true);

    const [creditTotal, setCreditTotal] = useState([]);
    const [debitTotal, setDebitTotal] = useState([]);
    const [balance, setBalance] = useState(0);

    const click = () => {
        alert(invoiceNR);
    }

    useEffect(() => {
        const calculatedBalance = creditTotal - debitTotal;
        setBalance(calculatedBalance);
    }, [creditTotal, debitTotal]);

    const handlenewpage = () => {
        setData("");
        setComent("");
        setInvoiceNR("");
        setActionType("");
        setLedgerID(0);
        setAccountID("");
        setAmount0(0);
    }

    // const fetchLastInvoiceNr = async () => {
    //     try {
    //         const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/InvoiceNr', { headers, params: { email: logedEmail } });
    //         const invoiceNr = response.data.InvoiceNr;

    //         // Kontrollo nëse nuk ka të dhëna të disponueshme
    //         if (invoiceNr === null) {
    //             // Shfaq një mesazh për përdoruesin
    //             // errorAlert("No data available.");
    //         }

    //         setLastInvoiceNr(invoiceNr);
    //         updateInvoiceNr(invoiceNr);

    //     } catch (error) {
    //         console.log(error);
    //         // Shfaq një mesazh për përdoruesin për gabime të tjera
    //         // setMessage("Error: Failed to fetch data.");
    //     }
    // };

    const fetchLastInvoiceNr = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/InvoiceNr', { headers });
            const invoiceNr = response.data.LastInvoiceNr;

            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (invoiceNr === null) {
                // Shfaq një mesazh për përdoruesin
                // errorAlert("No data available.");
            }
            setLastInvoiceNr(invoiceNr);
            updateInvoiceNr(invoiceNr);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    const updateInvoiceNr = (lastInvoiceNr) => {
        if (lastInvoiceNr !== '') {
            setInvoiceNR(parseInt(lastInvoiceNr) + 1);
        }
    };



    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            setLogedEmail(decodedToken.email);
        }
    }, []);

    // const fetchData = async () => {
    //     try {
    //         const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/journalaccountingsheets', { headers, params: { email: logedEmail, invNumber: invoiceNR } });
    //         setData(response.data);
    //         setLoading(false);

    //         // Kontrollo nëse nuk ka të dhëna të disponueshme
    //         if (response.data.length === 0) {
    //             // Shfaqi një mesazh për përdoruesin
    //             // errorAlert("No data available.");

    //         }
    //     } catch (error) {
    //         console.log(error);
    //         // Shfaqi një mesazh për përdoruesin për gabimin e tjera
    //         // setMessage("Error: Failed to fetch data.");
    //     }
    // };

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/journalaccountingsheets', { headers, params: { invNumber: invoiceNR } });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchDataCreditTotal = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/CreditTOT', { headers, params: { email: logedEmail, invNumber: invoiceNR } });
            setCreditTotal(response.data);
            setLoading(false);

            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (response.data.length === 0) {
                // Shfaqi një mesazh për përdoruesin
                // errorAlert("No data available.");

            }
        } catch (error) {
            console.log(error);
            // Shfaqi një mesazh për përdoruesin për gabimin e tjera
            // setMessage("Error: Failed to fetch data.");
        }
    };

    const fetchDataDebitTotal = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AccountingSheet/DebitTOT', { headers, params: { email: logedEmail, invNumber: invoiceNR } });
            setDebitTotal(response.data);
            setLoading(false);

            // Kontrollo nëse nuk ka të dhëna të disponueshme
            if (response.data.length === 0) {
                // Shfaqi një mesazh për përdoruesin
                // errorAlert("No data available.");

            }
        } catch (error) {
            console.log(error);
            // Shfaqi një mesazh për përdoruesin për gabimin e tjera
            // setMessage("Error: Failed to fetch data.");
        }
    };
    // const fetchaccounts = async () => {
    //     try {
    //         const response = await axios.get(ApiLink.API_URL + 'Ledger/accountsbyledgerid', { headers, params: { email: logedEmail, ledgerID: ledgerID } });
    //         setAccountsID(response.data);
    //     } catch (error) {
    //         if (error.response && error.response.data && error.response.data.errorMessage) {
    //             console.log("Gabim në marrjen e të dhënave:", error.response.data.errorMessage);
    //         } else {
    //             // errorAlert("Gabim në marrjen e të dhënave!");
    //         }
    //     }
    // };

    const fetchaccounts = async (ledger) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Ledger/accountsbyledgerid', { headers, params: { ledgerID: ledger } });
            setAccountsID(response.data);
            setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };


    useEffect(() => {
        axios.get(ApiLink.API_URL + 'ledger', { headers })
            .then((response) => {
                setLedgersID(response.data);
            }).catch(() => {
                // errorAlert(error);
            })
    }, [])

    const handleDelete = async () => {
        try {
            // const response = 
            await axios.delete(
                ApiLink.API_URL + 'AccountingSheet/oneline' + `/${deleteItemId}`, { headers }
            );
            setShowDeleteConfirmation(false);
            deleteAlert("Accounting sheet deleted Successfully");
            fetchData();
            fetchDataCreditTotal();
            fetchDataDebitTotal();
        } catch (error) {
            deleteAlert("Accounting sheet can not be deleted");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!date) {
            errorAlert("Please select date!");
            return;
        }
        if (!coment) {
            errorAlert("Please choose comment!");
            return;
        }
        const stateDate = {
            Date: date,
            ActionType: "ACS",
            InvoiceNr: invoiceNR,
            Coment: coment,
            CreatedByUser: email,
        };
        axios.post(ApiLink.API_URL + 'AccountingSheet', stateDate, { headers })
            .then(() => {
                handleSuccess("Accounting Sheet Added Successfully");
                handleEnable();
                setOpenDetails(true);

            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const handleNewSubmit = (event) => {
        event.preventDefault();
        if (!invoiceNR) {
            errorAlert("Ju lutem plotësoni numrin e faturës!");
            return;
        }
        const stateDate = {
            Date: date,
            ActionType: actionType,
            InvoiceNr: invoiceNR,
            Coment: coment,
            CreatedByUser: email,
            LedgerID: ledgerID,
            AccountID: accountID,
            Amount0: amount0
        };
        axios.post(ApiLink.API_URL + 'AccountingSheet/createnewline', stateDate, { headers })
            .then(() => {
                handleSuccess("Accounting Sheet Added Successfully");
                setAccountID();
                setActionType("");
                setLedgerID(0);
                setAmount0(0);
                fetchData();
                fetchDataCreditTotal();
                fetchDataDebitTotal();
                setfield(null);
                setAccountFields(null);
                setAccountsID([])
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }
    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    //Kodi per pegination ne tabel
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };

    //Pjesa per select input field Clients
    const [field, setfield] = useState(null);
    const handleAutocompleteChangeLedgers = (event, value) => {
        if (value && value.LedgerID) {
            setLedgerID(value.LedgerID);
            setfield(value); // Përditëso `selectedArticle` me vlerën e zgjedhur
            fetchaccounts(value.LedgerID);

        } else {
            // Në rast se vlera është pastruar
            setLedgerID(0); // Vendos vlerën e ArticleID në null
            setfield(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsLedgers = {
        options: ledgersID,
        getOptionLabel: (option) =>
            option.LedgerNameEN || ""
    };
    //////

    //Pjesa per select input field accounts
    const [accountsField, setAccountFields] = useState(null);
    const handleAutocompleteChangeAccounts = (event, value) => {
        if (value && value.AccountID) {
            setAccountID(value.AccountID);
            setAccountFields(value); // Përditëso `selectedArticle` me vlerën e zgjedhur

        } else {
            // Në rast se vlera është pastruar
            setAccountID(""); // Vendos vlerën e ArticleID në null
            setAccountFields(null);  // Pastro `Autocomplete`

        }
    };

    const defaultPropsAccounts = {
        options: accountsID,
        getOptionLabel: (option) =>
            option.AccountName || ""
    };
    //////

    // ky kod e ekzekuton funksionin fetchaccounts, vetem nese ledgerid eshte e ndryshme nga null
    // useEffect(() => {
    //     // Kontrollo nqs supplierID është ndryshuar para se të kryhen veprimet
    //     if (ledgerID !== "") {
    //         fetchaccounts();
    //     }
    // }, [ledgerID]);

    useEffect(() => {
        fetchLastInvoiceNr();
    }, [])

    useEffect(() => {
        fetchData();
        fetchDataCreditTotal();
        fetchDataDebitTotal();
    }, [logedEmail, invoiceNR, ledgerID]);
    return (
        <div className="AccountingSheet__Container" style={{ paddingTop: "50px" }}>
            {/* Modal per konfirmin nese deshiron me fshi nje element */}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="header_flex">
                <div className="header_icon">
                    <MdManageAccounts style={{}} className="header_icons" />
                    <h3>Fletë kontabel e re</h3>
                </div>
                <div>
                </div>
            </div>
            <div className="header_paragraph">
                <p>Ju lutemi vini re se të gjitha te dhenat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
            </div>
            <hr></hr>
            <div className='header_paper2'></div>
            <Paper>
                <form className='header_paper'>
                    <Box
                        // component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {/* <p>Please fill in these fields first to proceed to the fields below</p> */}
                        <div className='AccountingSheet__Container__Form'>
                            <div>
                                <TextField
                                    className="AccountingSheet__Container__Form__TextField__1"
                                    required
                                    id="invoiceNR"
                                    label="Numri i Fletes kontabel"
                                    variant="outlined"
                                    type="text"
                                    name='InvoiceNr'
                                    value={"ACS-" + currentYear + "-" + invoiceNR}
                                    onChange={event => setInvoiceNR(event.target.value)}
                                    disabled={form1IsDisabled}
                                    autoComplete="off"
                                    aria-readonly
                                />
                            </div>
                            {/* style={{ width: "200px" }} */}
                            <div >
                                <TextField
                                    className="AccountingSheet__Container__Form__TextField__2"
                                    id="date"
                                    type="date"
                                    variant="outlined"
                                    name='Date'
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    disabled={form1IsDisabled}
                                />
                            </div>
                            <div >
                                <div>
                                    <TextField
                                        className="AccountingSheet__Container__Form__TextField__3"
                                        id="Comment"
                                        select
                                        label="Komenti"
                                        defaultValue=""
                                        value={coment}
                                        onChange={event => setComent(event.target.value)}
                                        disabled={form1IsDisabled}
                                    >
                                        <MenuItem value="Open Accounts">Hapje e Llogarive</MenuItem>
                                        <MenuItem value="Balance Accounts">Barazim i Llogarive</MenuItem>
                                        <MenuItem value="Closed Accounts">Mbyllje e Llogarive</MenuItem>
                                    </TextField>
                                </div>
                            </div>
                            {/* <div className="butonat">
                                <button type="submit"
                                    className="btn btn-primary"
                                    style={{ background: "#7a95a6", width: "110px", marginLeft: "15px" }}
                                    disabled={form1IsDisabled}
                                >Save</button>
                            </div> */}
                        </div>
                    </Box>
                </form>
                <br></br>
                <br></br>

                {
                    openDetails ?
                        <div className="SalesDetail__Container">
                            <div style={{ backgroundColor: "gainsboro " }}>
                                <form onSubmit={handleNewSubmit} className='header_paper'>
                                    <Box
                                        // component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1.3, width: '41ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div className='AccountingSheet__Container__Form'>
                                            <div>
                                                {/* <TextField
                                                    className="AccountingSheet__Container__Form__TextField__4"
                                                    id="LedgerID"
                                                    select
                                                    label="Select Ledger"
                                                    defaultValue=""
                                                    // helperText="Please select expense"
                                                    value={ledgerID}
                                                    // onChange={event => setLedgerID(event.target.value)}
                                                    onChange={event => { setLedgerID(event.target.value); fetchaccounts(event.target.value); }}
                                                    disabled={form2IsDisabled}

                                                >
                                                    {ledgersID.map((data) => (
                                                        <MenuItem key={data.LedgerID} value={data.LedgerID}>{data.LedgerNameEN}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    fullWidth
                                                    {...defaultPropsLedgers}
                                                    id="include-input-in-list"
                                                    includeInputInList
                                                    value={field}  // Lidheni me state-in `selectedArticle`
                                                    onChange={handleAutocompleteChangeLedgers}
                                                    isOptionEqualToValue={(option, value) => option.LedgerID === value.LedgerID} // Shto funksionin isOptionEqualToValue
                                                    renderInput={(params) => (
                                                        <TextField
                                                            // style={{ width: "91%" }}
                                                            className="AddPurchaseTotal__Container__Format__SameInput"

                                                            {...params}
                                                            label="Përzgjedh llogarinë Kontabel"
                                                        // variant="filled"
                                                        //   onKeyPress={handleKeyPress}
                                                        />
                                                    )}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                            overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                {/* <TextField
                                                    className="AccountingSheet__Container__Form__TextField__4"
                                                    id="AccountID"
                                                    select
                                                    label="Select Account"
                                                    defaultValue=""
                                                    // helperText="Please select expense"
                                                    value={accountID}
                                                    onChange={event => setAccountID(event.target.value)}
                                                    disabled={form2IsDisabled}
                                                >
                                                    {accountsID.map((data) => (
                                                        <MenuItem key={data.AccountID} value={data.AccountID}>{data.AccountName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    fullWidth
                                                    {...defaultPropsAccounts}
                                                    id="include-input-in-list"
                                                    includeInputInList
                                                    value={accountsField}  // Lidheni me state-in `selectedArticle`
                                                    onChange={handleAutocompleteChangeAccounts}
                                                    isOptionEqualToValue={(option, value) => option.AccountID === value.AccountID} // Shto funksionin isOptionEqualToValue
                                                    renderInput={(params) => (
                                                        <TextField
                                                            // style={{ width: "91%" }}
                                                            className="AddPurchaseTotal__Container__Format__SameInput"

                                                            {...params}
                                                            label="Përzgjedh Llogarinë"
                                                        // variant="filled"
                                                        //   onKeyPress={handleKeyPress}
                                                        />
                                                    )}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: '200px', // Caktoni një lartësi maksimale për të lejuar vetëm 4 elemente
                                                            overflow: 'auto',   // Aktivizoni scroll-in për pjesën tjetër
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    className="AccountingSheet__Container__Form__TextField__5"
                                                    id="ActionType"
                                                    select
                                                    label="Debit/Kredit"
                                                    defaultValue=""
                                                    // helperText="Please select expense"
                                                    value={actionType}
                                                    onChange={event => setActionType(event.target.value)}
                                                    disabled={form2IsDisabled}
                                                >
                                                    <MenuItem value="Debit">Debit</MenuItem>
                                                    <MenuItem value="Credi">Kredit</MenuItem>
                                                </TextField>
                                            </div>
                                            <div className='box3'>
                                                <TextField
                                                    className="AccountingSheet__Container__Form__TextField__5"
                                                    id="outlined-basic"
                                                    label="Vlera"
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    name='Amount0'
                                                    value={amount0}
                                                    onChange={event => setAmount0(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={form2IsDisabled}
                                                />
                                            </div>
                                            <div className="butonat">
                                                <button type="submit"
                                                    className="btn btn-primary float-end"
                                                    style={{ background: "#7a95a6", width: "110px", marginLeft: "15px" }}
                                                    disabled={form2IsDisabled}
                                                >Shto</button>
                                            </div>
                                        </div>
                                    </Box>
                                </form>
                                <TableContainer sx={{ maxHeight: 500 }}>
                                    <Table ref={tableRef} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={styles.tableHeader}><strong>Llogaria kontabel</strong></TableCell>
                                                <TableCell sx={styles.tableHeader}><strong>Llogaria</strong></TableCell>
                                                <TableCell sx={styles.tableHeader}><strong>Komenti</strong></TableCell>
                                                <TableCell sx={styles.tableHeader}><strong>Debi</strong></TableCell>
                                                <TableCell sx={styles.tableHeader}><strong>Kredi</strong></TableCell>
                                                <TableCell sx={styles.tableHeader}><strong>Veprimet</strong></TableCell>

                                            </TableRow>
                                        </TableHead>
                                        {loading ? (
                                            <div className="spinner" style={{ marginLeft: "560px" }} ></div>
                                        ) : (
                                            <TableBody>
                                                {data
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((item) => (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.TransactionID}>
                                                            <TableCell sx={styles.tableCell}>{item.LedgerNameEN}</TableCell>
                                                            <TableCell sx={styles.tableCell}>{item.AccountName}</TableCell>
                                                            <TableCell sx={styles.tableCell}>{item.Coment}</TableCell>
                                                            <TableCell sx={styles.tableCell}>{item.Debit}</TableCell>
                                                            <TableCell sx={styles.tableCell}>{item.Credit}</TableCell>
                                                            <TableCell sx={styles.tableCell}>
                                                                <Tooltip title="Delete" placement="right">
                                                                    <IconButton>
                                                                        <DeleteIcon style={{ color: '#962a38' }}
                                                                            onClick={() => {
                                                                                setShowDeleteConfirmation(true);
                                                                                setDeleteItemId(item.JournalID);
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                                <div className="AccountingSheet__Container__Form__totalet">
                                    <TextField
                                        className="AccountingSheet__Container__Form__TextField__6"
                                        id="DebitTotal"
                                        label="Debit Total"
                                        variant="outlined"
                                        type="text"
                                        value={debitTotal}
                                        disabled
                                    />

                                    <TextField
                                        className="AccountingSheet__Container__Form__TextField__7"
                                        id="CreditTotal"
                                        label="Credit Total"
                                        variant="outlined"
                                        type="text"
                                        value={creditTotal}
                                        disabled
                                    />
                                    <TextField
                                        className="AccountingSheet__Container__Form__TextField__8"
                                        id="Balance"
                                        label="Balance"
                                        variant="outlined"
                                        type="text"
                                        value={balance}
                                        disabled
                                    />
                                    <div className="butonat">
                                        {balance != 0 ? (

                                            <div className="alert alert-danger">
                                                The balance must be 0 to close the accounting sheet
                                            </div>
                                        ) : (
                                            <Link
                                                className="btn btn-primary"
                                                style={{ background: "#7a95a6", width: "110px", marginLeft: "100px" }}
                                                to={"/accounting/accountingsheets"}
                                            >
                                                Mbyll
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                            <button className="button-15" variant="contained"
                                type="submit"
                                style={{
                                    background: "#466F86",
                                    width: "95%",
                                    height: "55px",
                                    alignContent: "center",
                                    transition: "background-color 0.3s ease",
                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                    color: "white",
                                    border: "none"
                                }}
                                disabled={form1IsDisabled}
                                onClick={handleSubmit}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = "#6c7f8f";
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = "#7a95a6";
                                }}
                            >
                                <FaTable />  Hap detalet e Fletes kontabel
                            </button>
                        </div>
                }
                <br></br>
            </Paper>
        </div>
    );
}

export default AddAccountingSheet;