import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/__button.scss'
import AddBoxIcon from '@mui/icons-material/AddBox';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";

function InsertArticleGroup(props) {
  const [isActive, setIsActive] = useState(true);
  const [groupName, setGroupName] = useState("");

  const headers = authHeader();
  const email = LoginUserDetailed(); // replace with your implementation of LoginUserDetailed()


  const handleSubmit = (event) => {
    event.preventDefault();
    const stateData = {
      GroupName: groupName,
      IsActive: isActive,
      CreatedByUser: email

    };
    axios.post(ApiLink.API_URL + 'articlegroup', stateData, { headers })
      .then((response) => {
        // console.log(response.data);
        handleSuccess("Article Groups Added Successfully");
        setGroupName("");
        setIsActive(true);
        props.onHide();
        props.refreshData(); // Thirrja e funksionit per refresh te dhenave

      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 409) {
          errorAlert(error.response.data);
        } else {
          errorAlert("An unknown error occurred while processing the request.");
        }
      });
  };

  const handleClose = () => {
    // Ky funksion do të përdoret për të mbyllur modalin
    props.onHide();
  };

  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
          Krijo Grup të ri
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%"

            }} >
              <div >
                <TextField
                  style={{
                    width: "100%"
                  }}
                  required
                  id="GroupName"
                  label="Emri i Grupit"
                  variant="outlined"
                  type="text"
                  name='GroupName'
                  value={groupName}
                  onChange={event => setGroupName(event.target.value)}
                />
              </div>
              {/* <div style={{width:"100%"}}>
                <label >
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                  />
                  <span ></span>
                  Active
                </label>
              </div> */}
            </div>
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              justifyContent: "space-around"
            }} >
              <div >
                <label >
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                  />
                  <span ></span>
                  Active
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-success float-end"
                  style={{
                    width: "120px",
                  }}
                >
                  Ruaj & Mbyll
                </button>
              </div>
              <div>
                <Button variant="secondary" onClick={handleClose}>
                  Mbyll
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default function InsertData(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const handleRefreshData = () => {
    props.fetchData(); // Thirrja e funksionit per refresh te dhenave
  };

  return (
    <>
      {props.type === "text" ? (
        <button className="button-68" onClick={() => setModalShow(true)}>
         <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }}/> Krijo Grup të Artikujve
        </button>
      ) : (
        <AddBoxIcon style={{ height: "29px" }} onClick={() => setModalShow(true)} />
      )}
      <InsertArticleGroup
        show={modalShow}
        onHide={() => setModalShow(false)}
        refreshData={handleRefreshData}
      />
    </>
  );
}
