import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import InsertData from "./InsertCashRegister";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { deleteAlert, errorAlert, handleSuccess, updateAlert } from "../../Components/Alerts";
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import "jspdf-autotable";
import { FaCashRegister } from "react-icons/fa";
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/InsertClient.scss';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { TextareaAutosize } from '@mui/material';
import DataGridWithActions from "../../Components/DataGridWithActions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function CashRegister() {
    const headers = authHeader();
    const email = LoginUserDetailed();
    const [data, setData] = useState([]);
    const [city, setCity] = useState([]);
    const [state, setState] = useState([]);
    const [formData, setFormData] = useState({
        AccountID: "",
        AccountName: "",
        LedgerID: "",
        IdentificationNumber: "",
        VATNumber: "",
        Adress: "",
        CityID: "",
        StateID: "",
        BankAccount: "",
        Email: "",
        Comment: "",
        PhoneNumbers: "",
        ModifiedByUser: "",
        IsActive: "",
    })

    const [showEditModal, setShowEditModal] = useState(false);

    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 101 } });
            setData(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const handleEdit = (item) => {
        fetchCityByState(item.StateID);
        setFormData({
            AccountID: item.AccountID,
            AccountName: item.AccountName,
            LedgerID: item.LedgerID,
            IdentificationNumber: item.IdentificationNumber,
            VATNumber: item.VATNumber,
            Adress: item.Adress,
            CityID: item.CityID,
            StateID: item.StateID,
            BankID: item.BankID,
            BankAccount: item.BankAccount,
            Email: item.Email,
            Comment: item.Comment,
            PhoneNumbers: item.PhoneNumbers,
            ModifiedByUser: email,
            IsActive: item.IsActive
        });
        setShowEditModal(true);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(ApiLink.API_URL + 'Accounts', formData, { headers })
            .then((response) => {
                setShowEditModal(false);
                fetchData();
                updateAlert("Cash Register Updateed Successfully");
            }).catch((error) => {
                errorAlert(error);
                errorAlert("Failed To Update Cash Register");
            })
    }

    const handleDelete = async (deleteItemId) => {
        try {
            const response = await axios.delete(
                ApiLink.API_URL + 'Accounts/payment' + `/${deleteItemId}`, { headers }
            );

            const result = response.data;
            if (result.success) {
                handleSuccess("Arka është fshirë me sukses"); // Shfaq mesazhin e suksesit
                fetchData();
            } else {
                deleteAlert("Arka nuk mund të fshihet sepse është përdorur në transaksione"); // Shfaq mesazhin e gabimit
            }
        } catch (error) {
            deleteAlert("Ka ndodhur një gabim gjatë fshirjes së arkës."); // Mesazh për gabime të tjera
        }
    };

    useEffect(() => {
        const headers = authHeader();
        axios.get(ApiLink.API_URL + 'states', { headers })
            .then((response) => {
                setState(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const fetchCityByState = async (stateID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Cities/getcitybystate', { headers, params: { stateid: stateID } });
            setCity(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const cashRegisterGridHeader = [
        { field: 'id', headerName: '#', width: 50, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'name', headerName: 'Emri i Arkës', width: 760, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'idN', headerName: 'Numri Identifikues', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'adress', headerName: 'Adresa', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        {
            field: 'active',
            headerName: 'Aktiv',
            width: 70,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                params.value ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : ""
            )
        },
    ]

    const rows = data.map((item, index) => ({
        id: ++index,
        name: item.AccountName,
        idN: item.IdentificationNumber,
        adress: item.Adress,
        active: item.IsActive,
        deleteItemId: item.AccountID,
        rowInfo: item
    }));


    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="Crud__Container">
            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div class="edit-modal-wrapper">
                    <div className="edit-modal">
                        <form onSubmit={handleSubmit} className="InsertClient__Container">
                            <div>
                                <h3>Ndryshimi Arkës</h3>
                                <hr></hr>
                            </div>
                            <br></br>
                            <div className='InsertClient__Container__SecondInputs'>
                                <div className='InsertClient__Container__SecondInputs__SameDiv' >
                                    <TextField className='InsertClient__Container__firstInput__SameInput'
                                        required
                                        id="AccountName"
                                        label="Emri i Arkës"
                                        variant="outlined"
                                        type="text"
                                        name='AccountName'
                                        value={formData.AccountName}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="InsertClient__Container__SecondInputs__SameDiv">
                                    <TextField className='InsertClient__Container__firstInput__SameInput'
                                        required
                                        id="IdentificationNumber"
                                        label="Numri Identifikues: Psh: (01, 02)"
                                        variant="outlined"
                                        type="text"
                                        name='IdentificationNumber'
                                        value={formData.IdentificationNumber}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <br></br>
                            <div className='InsertClient__Container__firstInput' >
                                <TextField className='InsertClient__Container__firstInput__AccName'
                                    required
                                    id="Adress"
                                    label="Adresa"
                                    variant="outlined"
                                    type="text"
                                    name='Adress'
                                    value={formData.Adress}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                />
                            </div>
                            <br></br>
                            <div className='InsertClient__Container__SecondInputs'>
                                <div className='InsertClient__Container__SecondInputs__SameDiv' >
                                    <TextField className='InsertClient__Container__firstInput__SameInput'
                                        id="StateID"
                                        name="StateID"
                                        select
                                        label="Përzgjedh Shtetin"
                                        defaultValue=""
                                        // helperText="Please select expense"
                                        value={formData.StateID}
                                        onChange={(e) => { handleInputChange(e); fetchCityByState(e.target.value); }}
                                    >
                                        {state.map((state) => (
                                            <MenuItem key={state.StateID} value={state.StateID}>{state.StateName}</MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="InsertClient__Container__SecondInputs__SameDiv">
                                    <TextField className='InsertClient__Container__firstInput__SameInput'
                                        id="CityID"
                                        name="CityID"
                                        select
                                        label="Përzgjedh Qytetin"
                                        defaultValue=""
                                        // helperText="Please select expense"
                                        value={formData.CityID}
                                        onChange={handleInputChange}
                                    >
                                        {city.map((city) => (
                                            <MenuItem key={city.CityID} value={city.CityID}>{city.CityName}</MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <br></br>
                            <div className='InsertClient__Container__SecondInputs'>
                                <div className="InsertClient__Container__SecondInputs__SameDiv">
                                    <TextField className='InsertClient__Container__firstInput__SameInput'
                                        id="Email"
                                        label="Email"
                                        variant="outlined"
                                        type="text"
                                        name='Email'
                                        value={formData.Email}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="InsertClient__Container__SecondInputs__SameDiv">
                                    <TextField className='InsertClient__Container__firstInput__SameInput'
                                        id="PhoneNumbers"
                                        label="Numri i Telefonit"
                                        variant="outlined"
                                        type="text"
                                        name='PhoneNumbers'
                                        value={formData.PhoneNumbers}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <br></br>
                            <div className='InsertClient__Container__firstInput' >
                                <TextareaAutosize style={{ height: "100px" }}
                                    className="InsertClient__Container__firstInput__AccName"
                                    id="Comment"
                                    label="Komenti"
                                    variant="outlined"
                                    placeholder='Komenti'
                                    type="text"
                                    name='Comment'
                                    value={formData.Comment}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className='InsertClient__Container__butonat'>
                                <label className='InsertModal__Container__Form__Container2__checkbox-label'>
                                    <input
                                        type="checkbox"
                                        id="IsActive"
                                        name="IsActive"
                                        checked={formData.IsActive}
                                        onChange={(event) =>
                                            setFormData({ ...formData, IsActive: event.target.checked })
                                        }
                                        className='InsertModal__Container__Form__Container2__checkbox-input'
                                    />
                                    <span className='InsertModal__Container__Form__Container2__checkbox-custom'></span>
                                    Aktiv
                                </label>
                                <div className="InsertClient__Container__butonat__btn">
                                    <div className='InsertModal__Container__Form__Container2__save-button'>
                                        <button
                                            type="submit"
                                            className="btn btn-success float-end"
                                            style={{ width: "120px" }}
                                        >
                                            Ruaj
                                        </button>
                                    </div>
                                    <Button variant="secondary" onClick={() => setShowEditModal(false)}
                                        style={{ width: "120px" }}
                                    >
                                        Mbyll
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {/* Tabela per shfaqjen e te dhenave  */}
            <div className="header_flex">
                <div className="header_icon">
                    <FaCashRegister style={{}} className="header_icons" />
                    <h3>Lista e Arkave</h3>
                </div>
                <div>
                    <InsertData fetchData={fetchData} />
                </div>
            </div>
            <div className="header_paragraph">
                <p>Forma e Arkave: Menaxhoni, shtoni, editoni dhe fshini Arkat.</p>
            </div>
            <DataGridWithActions
                columns={cashRegisterGridHeader}
                rows={rows}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                showPayment={false}
                height={500}
            />
        </div>
    )
}