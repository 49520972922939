export const handleSuccess = (text) => {
  const successAlert = document.createElement("div");
  successAlert.className = "alert alert-success";
  successAlert.textContent = text;

  // Stilizimi
  successAlert.style.position = "fixed";
  successAlert.style.bottom = "20px";
  successAlert.style.left = "50%";
  successAlert.style.transform = "translateX(-50%)";
  successAlert.style.width = "90vw"; // Ndryshoni sipas nevojës
  successAlert.style.padding = "10px";
  successAlert.style.background = "green";
  successAlert.style.color = "white";
  successAlert.style.textAlign = "center";

  document.body.insertAdjacentElement('beforeend', successAlert);
  successAlert.scrollIntoView({ behavior: "smooth" });

  setTimeout(() => {
    successAlert.remove();
  }, 3000);
};



export const deleteAlert = (text) => {
  const deleteAlert = document.createElement("div");
  deleteAlert.className = "alert alert-danger";
  deleteAlert.textContent = text;

  //Stilizimi
  deleteAlert.style.position = "fixed";
  deleteAlert.style.bottom = "20px";
  deleteAlert.style.left = "50%";
  deleteAlert.style.transform = "translateX(-50%)";
  deleteAlert.style.width = "90vw"; // Ndryshoni sipas nevojës
  deleteAlert.style.padding = "10px";
  deleteAlert.style.background = "red";
  deleteAlert.style.color = "white";
  deleteAlert.style.textAlign = "center";

  document.body.insertAdjacentElement('beforeend', deleteAlert);
  deleteAlert.scrollIntoView({ behavior: "smooth" });

  setTimeout(() => {
    deleteAlert.remove();
  }, 3000);
};



export const errorAlert = (text) => {
  const errorAlert = document.createElement("div");
  errorAlert.className = "alert alert-danger";
  errorAlert.textContent = text;

  //Stilizimi
  errorAlert.style.position = "fixed";
  errorAlert.style.bottom = "20px";
  errorAlert.style.left = "50%";
  errorAlert.style.transform = "translateX(-50%)";
  errorAlert.style.width = "90vw"; // Ndryshoni sipas nevojës
  errorAlert.style.padding = "10px";
  errorAlert.style.background = "red";
  errorAlert.style.color = "white";
  errorAlert.style.textAlign = "center";

  document.body.insertAdjacentElement('beforeend', errorAlert);
  errorAlert.scrollIntoView({ behavior: "smooth" });

  setTimeout(() => {
    errorAlert.remove();
  }, 6000);
};




export const updateAlert = (text) => {
  const updateAlert = document.createElement("div");
  updateAlert.className = "alert alert-warning";
  updateAlert.textContent = text;

  // Stilizimi
  updateAlert.style.position = "fixed";
  updateAlert.style.bottom = "20px";
  updateAlert.style.left = "50%";
  updateAlert.style.transform = "translateX(-50%)";
  updateAlert.style.width = "90vw"; // Ndryshoni sipas nevojës
  updateAlert.style.padding = "10px";
  updateAlert.style.background = "orange";
  updateAlert.style.color = "white";
  updateAlert.style.textAlign = "center";

  document.body.insertAdjacentElement('beforeend', updateAlert);
  updateAlert.scrollIntoView({ behavior: "smooth" });

  setTimeout(() => {
    updateAlert.remove();
  }, 3000);
};

export const infoAlert = (text) => {
  const alertContainer = document.createElement("div");
  alertContainer.className = "alert-container";

  const alertMessage = document.createElement("div");
  alertMessage.className = "alert alert-info";
  alertMessage.textContent = text;

  // Stilizimi i container-it të alertit
  alertContainer.style.position = "fixed";
  alertContainer.style.bottom = "20px";
  alertContainer.style.left = "50%";
  alertContainer.style.transform = "translateX(-50%)";
  alertContainer.style.width = "30%"; // Ndryshoni sipas nevojës
  alertContainer.style.padding = "10px";
  // alertContainer.style.background = "orange";
  alertContainer.style.color = "white";
  alertContainer.style.textAlign = "center";
  alertContainer.style.zIndex = "9999"; // Sigurohuni që të jetë më i madh se elementët e tjerë në faqe

  // Shto alertin në container
  alertContainer.appendChild(alertMessage);

  // Shto container-in në fund të dokumentit
  document.body.appendChild(alertContainer);

  // Scroll në container për të siguruar që alerti të jetë i dukshëm
  alertContainer.scrollIntoView({ behavior: "smooth" });

  // Fshi alertin pas 3 sekondave (3000 ms)
  setTimeout(() => {
    alertContainer.remove();
  }, 7000);
};



