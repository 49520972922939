import React, { useState, useEffect, useRef, useCallback } from "react";
import { Paper, TextareaAutosize } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./../../Style/POS.scss";
import axios from "axios";
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import LoginUserDetailed from "../../Auth/LoginUserDetailed";
import jwtDecode from "jwt-decode";
import {
  deleteAlert,
  errorAlert,
  handleSuccess,
} from "../../Components/Alerts";
import Modal_bootstrap from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import Button_Bootstrap from 'react-bootstrap/Button';
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PrintContent from "../../Print/PrintContent";
import { Link, useHistory } from "react-router-dom";
import {
  FaTicketAlt,
  FaTimes,
  FaTrash,
  FaEnvelope,
  FaTable,
  FaFileInvoice,
} from "react-icons/fa";
import Select from "react-select";
import InsertClient from "../Clients/InsertClients";
import InsertArticle from "../Article/InsertArticle";
import PDFGenerator from "../../Print/PDFTest";
import CircularProgress from "@mui/material/CircularProgress";
import DataGridWithActions from "../../Components/DataGridWithActions";
import { detailsData } from "../Pro Invoices/GridHeader";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { GridToolbar } from "@mui/x-data-grid-premium";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import EuroIcon from "@mui/icons-material/Euro";
import PaymentIcon from "@mui/icons-material/Payment";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, Modal } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DateRangeIcon from '@mui/icons-material/DateRange';
import BusinessIcon from '@mui/icons-material/Business';
import { saveAs } from 'file-saver';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
function AddPos(props) {
  const history = useHistory();

  // const TranscationData = [
  //   {
  //     id: 1,
  //     type: "Vendore",
  //   },
  //   {
  //     id: 2,
  //     type: "Eksport",
  //   },
  //   {
  //     id: 3,
  //     type: "Sherbime jashtë vendit",
  //   },
  // ];
  const [isInactive, setIsInactive] = useState(false);
  // const [timer, setTimer] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);  // Kyçi për rifreskim të komponentëve
  const timerRef = useRef(null);


  const headers = authHeader();
  const email = LoginUserDetailed();
  const [logedEmail, setLogedEmail] = useState("");
  // const currentYear = new Date().getFullYear();
  const [openCashModal, setOpenCashModal] = useState(false);
  const [openBankModal, setOpenBankModal] = useState(false);
  const [openZraportConfirm, setOpenZraportConfirm] = useState(false);
  const [openXraportConfirm, setOpenXraportConfirm] = useState(false);
  const [openQtyAlert, setOpenQtyAlert] = useState(false);
  const [isArticleQtySelected, setIsArticleQtySelected] = useState(false);
  const [showClosePosAlert, setShowClosePosAlert] = useState(false);
  const [showPriceError, setShowPriceError] = useState(false);


  const [openQtyModal, setOpenQtyModal] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [tempQty, setTempQty] = useState(0);
  const [tempPrice, setTempPrice] = useState(0);
  const [tempArticle, setTempArticle] = useState([]);

  const pagesaFokus = useRef(null);

  //Per insertimin e Transaction
  const [date, setDate] = useState("");
  const [payDate, setPayDate] = useState("");
  const [coment, setComent] = useState("");
  const [invoiceNR, setInvoiceNR] = useState("");
  const [supplierID, setSupplierID] = useState(0);
  const [transactionsType, setTransactionType] = useState(1);
  const [paymentOptions, setPaymentOptions] = useState(0);
  const [priceWithVAT, setPriceWithVAT] = useState(0);
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [clientName, setClientName] = useState('');
  //Per insertimin ne Article History
  const [articleID, setArticleID] = useState("");
  const [articleName, setArticleName] = useState("");
  const [qtyOutput, setQtyOutput] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [priceWithoutVAT, setPriceWithoutVAT] = useState(0);
  const [total, setTotal] = useState(0);
  const [vatRates, setVATRates] = useState("");

  // const [discountAmount, setDiscountAmount] = useState(0);
  // const [perqindjaZbritjes, setPerqindjaZbritjes] = useState(0);

  // kjo konstante sherben per ta mbaajtur perkohesisht qmimin me tvsh te nje artikulli
  const [tempPriceWithVAT, setTempPriceWithVAT] = useState(0);
  const [showDeleteTransactionID, setShowDeleteTransactionID] = useState(false);

  const [deleteTransactionID, setDeleteTransactionID] = useState("");

  // const [openDetails, setOpenDetails] = useState(false);

  const [data, setData] = useState([]);
  const [cashRegister, setCashRegister] = useState([]);
  const [banks, setBanks] = useState([])

  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [clientsDetails, setClientsDetails] = useState([]);

  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);

  const [valuedetails, setValueDetails] = useState([]);
  const [totaliFatures, setTotaliFatures] = useState(0);
  // Te dhenat per printim
  const [headerData, setHeaderData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [articlePrintDetails, setArticlePrintDetails] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyID, setcompanyID] = useState("");

  const [form1IsDisabled, setForm1IsDisabled] = useState(false);
  const [form2IsDisabled, setForm2IsDisabled] = useState(true);

  // const [groupID, setGroupID] = useState(0);
  const [articleGroup, setArticleGroup] = useState([]);
  const [articleByGroup, setArticleByGroup] = useState([]);

  //Ketu ruhen te dhenat per user detils, dhe payments
  const [tempPaymentOption, setTempPaymentOptions] = useState(0);
  const [cashRegisterName, setCashRegisterName] = useState("");

  const barcodeRef = useRef(null);
  const articleList = useRef(null);
  const pagesaArke = useRef(null);
  const qtyInputField = useRef(null);
  const priceInputField = useRef(null);
  const pagesaBank = useRef(null);

  const [pagesa, setPagesa] = useState(0);
  const [kusuri, setKusuri] = useState(0);
  const [insertedTransaction, setInsertedTransaction] = useState(false);
  const [insertedArticle, setInsertedArticle] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [searchByBarcode, setSearchByBarcode] = useState(true);
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000); // rifresko kohën çdo 1 sekondë

    return () => clearInterval(intervalId); // ndal intervalin kur komponenti zhduket
  }, []);

  const formattedTime = time.toLocaleTimeString();
  const formattedDate = time.toLocaleDateString();

  const handleEnable = () => {
    setForm1IsDisabled(true);
    setForm2IsDisabled(false);
  };

  // keta dy const sherbejn per select field te menyres se pageses se dudit
  const handleAutocompleteChange = (event, value) => {
    if (value) {
      setSupplierID(value.AccountID);
    } else {
      setSupplierID(0);
    }
  };

  const defaultProps = {
    options: clients,
    getOptionLabel: (option) => option.AccountName || "",
  };

  const [selectArticleField, setSelectArticleField] = useState(null);
  const handleAutocompleteChangeArticle = (event, value) => {
    if (value && value.ArticleID) {
      setArticleID(value.ArticleID);
      fetchArticlesSalesPrice(value.ArticleID, 'insert');
      setSelectArticleField(value);
      // if (!insertedTransaction) {
      //   handleSubmit(event);
      // }
    } else {
      // Në rast se vlera është pastruar
      setArticleID(""); // Vendos vlerën e ArticleID në null
      setSelectArticleField(null);
    }
  };


  const defaultPropsArticle = {
    options: articles,
    getOptionLabel: (option) => option.ArticleName || "",
  };

  // const defaultPropsArticle = {
  //   options: articles,
  //   getOptionLabel: (option) =>
  //     `${option.Barcode} - ${option.ArticleName}` || "",
  //   renderOption: (props, option) => (
  //     <li {...props}>
  //       <span>
  //         {option.Barcode}<br />
  //         {option.ArticleName}
  //       </span>
  //       <button
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           // handleButtonClick(option);
  //         }}
  //         style={{ marginLeft: 'auto' }}
  //       >
  //         Click Me
  //       </button>
  //     </li>
  //   ),
  // };

  // const handleAutocompleteChangeCashRegister = (event, value) => {
  //   if (value) {
  //     setPaymentOptions(value.ArticleID);
  //   } else {
  //     setPaymentOptions("");
  //   }
  // };

  // const defaultPropsCashRegister = {
  //   options: cashRegister,
  //   getOptionLabel: (option) => option.AccountName || "",
  // };

  ////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (transactionsType == 2 || transactionsType == 3) {
      setVATRates(0);
    }
  });

  // const newInvoice = () => {
  //   const currentDate = new Date();
  //   const year = currentDate.getFullYear();
  //   const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(currentDate.getDate()).padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}`;

  //   setForm1IsDisabled(false);
  //   setForm2IsDisabled(true);
  //   setOpenDetails(false);
  //   setArticleID("");
  //   setQtyOutput(0);
  //   setDiscountPercent(0);
  //   setPriceWithoutVAT(0);
  //   setPriceWithVAT(0);
  //   setTotal(0);
  //   fetchLastInvoiceNr();
  //   setDate(formattedDate);
  //   setPayDate(formattedDate);
  //   setComent("");
  //   setTransactionType(1);
  //   setPaymentOptions(0);
  //   setSupplierID(0);
  //   setClientName("");
  //   setClientsDetails([]);
  //   setData([]);
  //   setValueDetails([]);
  // };

  // kodi per dergimin e emailit
  // const [clientEmail, setClientEmail] = useState("");
  // const [subject, setSubject] = useState("");
  // const [uInvoiceNr, setUInvoiceNr] = useState("");
  // const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);

  // useEffect(() => {
  //   if (invoiceNR) {
  //     setSubject(`Fatura me numër: ${invoiceNR}`);
  //   }
  // }, [invoiceNR]);

  // keto konstante sherbejn per ta mbajtur gjendjen e formave nese jane hide ose jo
  const [showEditForm, setShowEditForm] = useState(false);
  const [showInserForm, setShowInsertForm] = useState(true);
  // ky kod i ndryshon gjendjet e formave

  const handleOpenForm2 = () => {
    setShowInsertForm(true);
    setShowEditForm(false); // I fsheh formën e parë
    setArticleID("");
    setQtyOutput(0);
    setDiscountPercent(0);
    setPriceWithoutVAT(0);
    setPriceWithVAT(0);
    setTotal(0);
  };
  const handleOpenForm1 = () => {
    setShowInsertForm(false);
    setShowEditForm(true); // I fsheh formën e parë
  };

  // const handleEditDetails = (item) => {
  //   handleOpenForm1();
  //   fetchArticlesSalesPrice(item.ArticleID, "getVat");
  //   setFormDetailData({
  //     HistoryID: item.HistoryID,
  //     ArticleID: item.ArticleID,
  //     QtyOutput: item.QtyOutput,
  //     DiscountPercent: item.DiscountPercent,
  //     PriceBeforeDiscount: item.PriceBeforeDiscount,
  //     Total: item.Total,
  //     PriceWithVAT: item.PriceWithVAT,
  //     PriceWithoutVAT: item.PriceWithoutVAT,
  //     ModifiedByUser: logedEmail,
  //     InvoiceNr: invoiceNR,
  //     PaymentOptions: paymentOptions,
  //   });
  // };

  // const clearEdit = () => {
  //   setFormDetailData({
  //     HistoryID: "",
  //     ArticleID: "",
  //     QtyOutput: 0,
  //     PriceWithVAT: 0,
  //     PriceWithoutVAT: 0,
  //     setFormDetailData: 0,
  //   });

  //   clearLine();
  //   handleOpenForm2();
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setLogedEmail(decodedToken.email);
    }
  }, []);

  //E bart fokusin tek pagesa 
  useEffect(() => {
    if (openCashModal && pagesaFokus.current) {
      pagesaFokus.current.focus();
    }
  }, [openCashModal]);

  const fetchLastInvoiceNr = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + "POS/InvoiceNr", {
        headers,
      });
      setInvoiceNR(response.data.NextInvoiceNr);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  // e merr listen e aartikujve
  const fetchData = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + "POS/POSDetails", {
        headers,
        params: { invNumber: invoiceNR },
      });
      setData(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const fetchPOS = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + "POS/getPOS", {
        headers
      });
      setSupplierID(response.data[0].AccountID);
      setClientName(response.data[0].AccountName);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const fetchTransactionID = async () => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "POS/GetTransactionID",
        { headers, params: { email: logedEmail, invNumber: invoiceNR } }
      );
      setDeleteTransactionID(response.data);
      // Kontrollo nëse nuk ka të dhëna të disponueshme
      if (response.data.length === 0) {
        // Shfaqi një mesazh për përdoruesin
        // errorAlert("No data available.");
      }
    } catch (error) {
      // Shfaqi një mesazh për përdoruesin për gabimin e tjera
      // setMessage("Error: Failed to fetch data.");
    }
  };

  // const fetchHeaderData = async () => {
  //     try {
  //         const response = await axios.get(ApiLink.API_URL + 'POS/GetPrintHeaderDetails', { headers, params: { invNumber: invoiceNR, actionType: "POS" } });
  //         setHeaderData(response.data);
  //         setUInvoiceNr(response.data[0].TransactionUID);
  //         setLink(encodeURI(`http://acctree.net/invoice?invoiceNR=${response.data[0].TransactionUID}`))
  //     } catch (error) {
  //         errorAlert("Gabim në kërkesë:", error);
  //         if (error.response && error.response.data && error.response.data.errorMessage) {
  //             errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
  //         } else if (error.message) {
  //             errorAlert(`Gabim: ${error.message}`);
  //         } else {
  //             errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
  //         }
  //     }
  // };

  const fetchArticleGroup = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + "articlegroup", {
        headers,
      });
      setArticleGroup(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const fetchArticleByGroup = async (group) => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Article/ArticleByGroupAndTouchScreen",
        { headers, params: { group: group } }
      );
      setArticleByGroup(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  // const fetchArticleDataPrint = async () => {
  //   try {
  //     const response = await axios.get(
  //       ApiLink.API_URL + "Article/GetArticleDetailsForPrint",
  //       { headers, params: { invNumber: invoiceNR, actionType: "POS" } }
  //     );
  //     setArticlePrintDetails(response.data);
  //   } catch (error) {
  //     errorAlert("Gabim në kërkesë:", error);
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.errorMessage
  //     ) {
  //       errorAlert(
  //         `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
  //       );
  //     } else if (error.message) {
  //       errorAlert(`Gabim: ${error.message}`);
  //     } else {
  //       errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
  //     }
  //   }
  // };

  const fetchBanksAccount = async () => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Accounts/BankDetails",
        { headers }
      );
      setBankAccounts(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const fetchCompaniesData = async () => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Companies/getCompanyDetails",
        { headers }
      );
      setCompaniesData(response.data);
      setCompanyName(response.data[0].CompanyName);
      // setCompanyEmail(response.data[0].Email);
      setcompanyID(response.data[0].CompanyID);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const [totalWithDiscountAmount, setTotalWithDiscountAmount] = useState(0);

  const fetchvaluedetails = async () => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "POS/getvaluedetails",
        { headers, params: { invNumber: invoiceNR } }
      );
      setValueDetails(response.data);
      setTotaliFatures(response.data[0].Total)
      setPagesa(response.data[0].Total)
      // setDiscountAmount(response.data[0].DiscountAmount);
      setTotalWithDiscountAmount(
        response.data[0].Total + response.data[0].DiscountAmount
      );
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  // const calculatePercent = (value) => {
  //   setPerqindjaZbritjes((value / totalWithDiscountAmount) * 100);
  // };

  const fetchPaymentType = async () => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Accounts/paymenttype",
        { headers }
      );
      setCashRegister(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë");
      }
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + 'UserDetails/getPaymentDetailsForUsers', { headers });
      setTempPaymentOptions(response.data[0].AccountID ? response.data[0].AccountID : 0);
      setCashRegisterName(response.data[0].AccountName ? response.data[0].AccountName : '');

    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);

      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  // const fetchClients = async () => {
  //     try {
  //         const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 105 } });
  //         setClients(response.data);
  //     } catch (error) {
  //         errorAlert("Gabim në kërkesë:", error);
  //         if (error.response && error.response.data && error.response.data.errorMessage) {
  //             errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
  //         } else if (error.message) {
  //             errorAlert(`Gabim: ${error.message} `);
  //         } else {
  //             errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
  //         }
  //     }
  // };
  const fetchClients = async () => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Accounts/accountByLedger",
        { headers, params: { ledger: 105 } }
      );
      const clientsFromDatabase = response.data;

      // Shtoni opsionin e konsumatorit privat në fillim të listës
      const updatedClients = [
        { AccountID: 0, AccountName: "Konsumator privat" },
        ...clientsFromDatabase,
      ];

      setClients(updatedClients);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message} `);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const fetchClientsDetails = async (supplierid) => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Accounts/accountByID",
        { headers, params: { id: supplierid } }
      );
      setClientsDetails(response.data);
      // setClientEmail(response.data[0].Email);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + "Article", {
        headers,
      });
      setArticles(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };
  const fetchArticlesSalesPrice = async (articleID, form) => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Article/GetSalesPrice",
        { headers, params: { articleID: articleID } }
      );
      const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja
      console.log(response.data);
      if (form == "edit") {
        const calculatedValue =
          (parseFloat(response.data.SalesPrice) * 100) /
          (100 + response.data.SalesPrice);
        // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
        setVATRates(response.data.VATRates);
        // setTempPriceWithVAT(salesPriceData.SalesPrice);
        setFormDetailData((prevState) => ({
          ...prevState,
          PriceWithVAT: response.data.SalesPrice,
          PriceWithoutVAT: calculatedValue,
          DiscountPercent: 0,
          QtyOutput: 1,
          PriceBeforeDiscount: response.data.SalesPrice,
          Total: 1 * response.data.SalesPrice,
        }));
      } else if (form == "getVat") {
        setVATRates(response.data.VATRates);
        // alert("get vat" + vatRates)
      } else {
        setPriceWithVAT(salesPriceData.SalesPrice);
        setTempPriceWithVAT(salesPriceData.SalesPrice);
        setVATRates(salesPriceData.VATRates);
        setQtyOutput(1);
        setArticleName(salesPriceData.ArticleName);
        // console.log(salesPriceData);
        // console.log(articleID);

        // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
        const calculatedValue =
          (parseFloat(salesPriceData.SalesPrice) * 100) /
          (100 + salesPriceData.VATRates);
        setPriceWithoutVAT(calculatedValue.toFixed(2));
        setTotal(1 * salesPriceData.SalesPrice);

        //ky kushtt sherben per tu insertuar transaksioni nese klikohen butonat me touch, duhet te ploteohen te dy keto kushte pet tu insertuar
        if (!insertedTransaction && form === 'insert') {
          handleSubmit();
        }
        if (salesPriceData.PurchasePrice >= salesPriceData.SalesPrice) {
          setShowPriceError(true);
        }
        else {
          setShowPriceError(false);
          setArticleName("");
        }
      }
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };




  // const [articleExist, setArticleExist] = useState(false);
  const fetchArticlesSalesPriceByBarcode = async (barcode, form) => {
    try {
      const response = await axios.get(
        ApiLink.API_URL + "Article/GetSalesPriceByBarcode",
        { headers, params: { barcode: barcode } }
      );
      const salesPriceData = response.data; // Merr të dy vlerat e "SalesPrice" dhe "VATRates" nga përgjigja
      if (form === "edit") {
        const calculatedValue =
          (parseFloat(response.data.SalesPrice) * 100) /
          (100 + response.data.SalesPrice);
        // setInsertPriceWithoutVAT(calculatedValue.toFixed(2));
        setVATRates(response.data.VATRates);
        // setTempPriceWithVAT(salesPriceData.SalesPrice);
        setFormDetailData((prevState) => ({
          ...prevState,
          PriceWithVAT: response.data.SalesPrice,
          PriceWithoutVAT: calculatedValue,
          DiscountPercent: 0,
          QtyOutput: 1,
          PriceBeforeDiscount: response.data.SalesPrice,
          Total: 1 * response.data.SalesPrice,
        }));
      } else if (form === "getVat") {
        setVATRates(response.data.VATRates);
        // alert("get vat" + vatRates)
      } else {
        setArticleID(salesPriceData.ArticleID);
        setPriceWithVAT(salesPriceData.SalesPrice);
        setPurchasePrice(salesPriceData.PurchasePrice);
        setTempPriceWithVAT(salesPriceData.SalesPrice);
        setVATRates(salesPriceData.VATRates);
        setQtyOutput(1);
        // Kalkulimi i vlerës pa TVSH dhe vendosja e saj tek PriceWithoutVAT
        const calculatedValue =
          (parseFloat(salesPriceData.SalesPrice) * 100) /
          (100 + salesPriceData.VATRates);
        setPriceWithoutVAT(calculatedValue.toFixed(2));
        setTotal(1 * salesPriceData.SalesPrice);
        setArticleName(salesPriceData.ArticleName);

        // setTempArticleID(salesPriceData.ArticleID);
        // setTempArticleName(salesPriceData.ArticleName);
        // setTempQty(1);
        // setTempPrice(salesPriceData.SalesPrice)

        // setArticleExist(true);
        setInsertedArticle(true);

        if (!insertedTransaction) {
          handleSubmit();
        }

        if (salesPriceData.PurchasePrice >= salesPriceData.SalesPrice) {
          setShowPriceError(true);
        }
        else {
          setShowPriceError(false);
          setArticleName("");

        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data &&
        error.response.data.Message
      ) {
        // Kontrolloni nëse artikulli nuk ekziston
        // errorAlert(`Gabim: ${error.response.data.Message}`);
        // setArticleExist(false);
        setInsertedArticle(false);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorAlert(
          `Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`
        );
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  const handleDelete = async (deleteItemId) => {
    try {
      await axios.delete(ApiLink.API_URL + "POS/oneline" + `/${deleteItemId}`, {
        headers,
        params: { email: logedEmail, invoiceNR: invoiceNR },
      });
      fetchData();
      fetchvaluedetails();
      setShowPriceError(false);
      setArticleName("");
      // fetchHeaderData();
      // fetchArticleDataPrint();
    } catch (error) {
      deleteAlert("Purchase detail can not be deleted");
    }
  };

  // Kodi per fshirjen e nje elementi ne databaz
  const handleDeleteTransactionID = async () => {
    try {
      // const response =
      await axios.delete(ApiLink.API_URL + "POS" + `/${deleteTransactionID}`, {
        headers,
      });
      setShowDeleteTransactionID(false);
      fetchLastInvoiceNr();
      setData([]);
      fetchArticleGroup([]);
      setValueDetails([]);
      setTotaliFatures(0);
      setKusuri(0);
      setPagesa(0);
      setInsertedTransaction(false)
      deleteAlert("Fatura eshte fshire me sukses");
      setShowPriceError(false);

    } catch (error) {
      deleteAlert("Sales Invoice can not be deleted");
    }
  };

  //Funksioni per navigim ne listen e POS, dhe fshirjen e elementeve ekzistuese
  const navigateToPos = async () => {
    setData([]);
    fetchArticleGroup([]);
    setValueDetails([]);
    setTotaliFatures(0);
    setKusuri(0);
    setPagesa(0);
    setInsertedTransaction(false);
    history.push('/Sales/pos');

  };


  // const [link, setLink] = useState("");

  // const [sendingEmail, setSendingEmail] = useState(false);
  // const sendEmail = (event) => {
  //   event.preventDefault();
  //   setSendingEmail(true); // Ndryshoni gjendjen e progresit në fillim të dërgimit të emailit

  //   if (clientEmail === "" || clientEmail == null) {
  //     errorAlert(
  //       "Emaili nuk mund te dergohet, sepse klienti nuk ka email te regjistruar"
  //     );
  //     setSendingEmail(false);
  //     return;
  //   }
  //   if (uInvoiceNr === "" || uInvoiceNr == null) {
  //     errorAlert(
  //       "Fatura nuk mund te dergohet sepse, ende nuk e keni ruajtur faturen ne databaz"
  //     );
  //     setSendingEmail(false);
  //     return;
  //   }

  //   const newInvoiceURL = encodeURI(
  //     `http://acctree.net/invoice?invoiceNR=${uInvoiceNr}`
  //   );
  //   // setLink(newInvoiceURL);
  //   const emailBody = `Të nderuar,

  //       Dëshirojmë t'ju njoftojmë se fatura elektronike është krijuar me sukses dhe është gati për shkarkim. Për të marrë faturën tuaj, ju lutem klikoni në linkun e mëposhtëm: ${newInvoiceURL}.

  //       Me këtë ndryshim, synojmë jo vetëm të lehtësojmë procesin tuaj të pagesës, por gjithashtu të kemi një ndikim pozitiv në mjedis duke e bërë dërgimin e faturave më të shpejtë dhe efikas.

  //       Ju lutëm të kryeni pagesën e faturës në njëren nga llogaritë e ${companyName} që janë specifikuar në faturë.

  //       Për çdo pyetje apo ndihmë shtesë, mos ngurroni të na kontaktoni në adresen elektronike: ${companyEmail}

  //       Ju falenderojmë për bashkëpunimin tuaj.

  //       Me respekt,
  //       ${companyName}`;

  //   const data = {
  //     To: clientEmail,
  //     Subject: subject,
  //     Body: emailBody, // Përdor emailBody në vend të body
  //   };

  //   axios
  //     .post(ApiLink.API_URL + "Gmail", data, { headers })
  //     .then(() => {
  //       handleSuccess("Email sent Successfully");
  //       setShowEmailConfirmation(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       if (error.response && error.response.status === 409) {
  //         errorAlert(error.response.data);
  //       } else {
  //         errorAlert("An unknown error occurred while processing the request.");
  //         setSendingEmail(false);
  //       }
  //     })
  //     .finally(() => {
  //       setSendingEmail(false); // Përfundimi i dërgimit të emailit, ndryshoni gjendjen e progresit
  //     });
  // };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    if (!invoiceNR) {
      errorAlert("Ju lutem plotësoni numrin e faturës!");
      return;
    }
    if (!date) {
      errorAlert("Ju lutem plotësoni Daten!");
      return;
    }

    if (paymentOptions != 0 && payDate == "") {
      errorAlert("Please Fill pay date");
      return;
    }

    const stateDate = {
      Date: date,
      PayDate: payDate,
      InvoiceNr: invoiceNR,
      SupplierID: supplierID,
      TransactionType: transactionsType,
      ActionType: "POS",
      PaymentOptions: paymentOptions,
      Coment: coment,
      CreatedByUser: email,
    };
    axios
      .post(ApiLink.API_URL + "POS/invoicedetail", stateDate, { headers })
      .then(() => {
        // handleSuccess("Inserted");
        handleEnable();
        // fetchHeaderData();
        fetchTransactionID();
        setInsertedTransaction(true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 409) {
          errorAlert(error.response.data);
        } else {
          errorAlert("An unknown error occurred while processing the request.");
        }
      });
  };

  const handleNewSubmit = (event) => {
    if (event) event.preventDefault();
    if (!invoiceNR) {
      errorAlert("Ju lutem plotësoni numrin e faturës!");
      // console.log(`Invoice number tek new ${invoiceNR}`);
      return;
    }
    if (!articleID) {
      errorAlert("Ju lutem plotësoni artikullin");
      // alert(`article id tel new ${articleID}`);

      return;
    }
    if (isNaN(qtyOutput)) {
      errorAlert("Sasia e produkteve (qtyOutput) duhet të jetë një numër.");
      return;
    }
    if (isNaN(priceWithoutVAT)) {
      errorAlert("Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër.");
      return;
    }
    if (isNaN(priceWithVAT)) {
      errorAlert("Çmimi me TVSH (priceWithVAT) duhet të jetë një numër.");
      return;
    }
    if (isNaN(total)) {
      errorAlert("Totali duhet të jetë një numër.");
      return;
    }
    const stateDate = {
      InvoiceNr: invoiceNR,
      ArticleID: articleID,
      QtyOutput: qtyOutput,
      DiscountPercent: discountPercent,
      PriceBeforeDiscount: tempPriceWithVAT,
      PriceWithoutVAT: priceWithoutVAT,
      PriceWithVAT: priceWithVAT,
      CreatedByUser: email,
      Date: date,
      SupplierID: supplierID,
    };
    axios
      .post(ApiLink.API_URL + "POS/createnewline", stateDate, {
        headers,
        params: { vatRates: vatRates },
      })
      .then(() => {
        // handleSuccess("Detail  Added Successfully");
        setArticleID("");
        setQtyOutput(0);
        setDiscountPercent(0);
        setPriceWithoutVAT(0);
        setPriceWithVAT(0);
        setTotal(0);
        fetchvaluedetails();
        // fetchHeaderData();
        // fetchArticleDataPrint();
        // setArticlesName("");
        fetchData();
        setDiscountPercent(0);
        setTempPriceWithVAT(0);
        setBarcode("");
        setInsertedArticle(false);
        setSelectArticleField(null);
        // setShowPriceError(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 409) {
          console.log(error.response.data);
        } else {
          errorAlert("An unknown error occurred while processing the request.");
        }
      });
  };
  // const [detailLine, setDetailLine] = useState([]); //i mban rreshtat e te dhenave detale
  const [formDetailData, setFormDetailData] = useState({
    HistoryID: "",
    ArticleID: "",
    QtyOutput: "",
    DiscountPercent: "",
    PriceBeforeDiscount: "",
    Total: "",
    PriceWithVAT: "",
    PriceWithoutVAT: "",
    ModifiedByUser: "",
    InvoiceNr: "",
    PaymentOptions: "",
  });

  // Kodi per ruajtjen e te dhenave pas editimint
  // const handleInputChangeDetail = (event) => {
  //   const { name, value } = event.target;
  //   setFormDetailData({ ...formDetailData, [name]: value });
  // };

  const handleChangeQty = async (item, qty, operation) => {
    try {
      const detailsData = {
        HistoryID: item.HistoryID,
        ArticleID: item.ArticleID,
        QtyOutput: operation === '-'
          ? (Number(item.QtyOutput) > 1
            ? Number(item.QtyOutput) - Number(qty)
            : Number(item.QtyOutput))
          : Number(item.QtyOutput) + Number(qty),
        DiscountPercent: item.DiscountPercent,
        PriceBeforeDiscount: item.PriceBeforeDiscount,
        Total: item.Total,
        PriceWithVAT: item.PriceWithVAT,
        PriceWithoutVAT: item.PriceWithoutVAT,
        ModifiedByUser: logedEmail,
        InvoiceNr: invoiceNR,
        PaymentOptions: paymentOptions,
      };

      await axios.put(ApiLink.API_URL + "POS/updatedetailsArticle", detailsData, {
        headers,
        params: { vatRates: item.VATRates }
      });

      // Fetched data pas suksesit
      fetchvaluedetails();
      fetchData();
    } catch (error) {
      console.error(error);
      errorAlert("Failed to update details");
    }
  };

  const handleChangeQtyWithInput = async (item, value, type) => {
    try {

      let detailsData = {};
      if (type === "qty") {
        detailsData = {
          HistoryID: item.HistoryID,
          ArticleID: item.ArticleID,
          QtyOutput: value,
          DiscountPercent: item.DiscountPercent,
          PriceBeforeDiscount: item.PriceBeforeDiscount,
          Total: item.Total,
          PriceWithVAT: item.PriceWithVAT,
          PriceWithoutVAT: item.PriceWithoutVAT,
          ModifiedByUser: logedEmail,
          InvoiceNr: invoiceNR,
          PaymentOptions: paymentOptions,
        };
      }
      else {
        detailsData = {
          HistoryID: item.HistoryID,
          ArticleID: item.ArticleID,
          QtyOutput: item.QtyOutput,
          DiscountPercent: item.DiscountPercent,
          PriceBeforeDiscount: item.PriceBeforeDiscount,
          Total: item.Total,
          PriceWithVAT: value,
          PriceWithoutVAT: item.PriceWithoutVAT,
          ModifiedByUser: logedEmail,
          InvoiceNr: invoiceNR,
          PaymentOptions: paymentOptions,
        };
      }

      // Thirrja e API-së
      const response = await axios.put(`${ApiLink.API_URL}POS/updatedetailsArticle`, detailsData, {
        headers,
        params: { vatRates: item.VATRates },
      });

      // Logimi i përgjigjes nga serveri
      // console.log("Response from API:", response);

      // Kontrollo nëse përgjigjja nga serveri është pozitive
      if (response.status === 200 || response.status === 204) {
        // console.log("Update successful");

        // Mbyll modalin dhe fresko të dhënat
        if (type === "qty") {
          setOpenQtyModal(false);
          setTempArticle([]);
          setTempQty(0);
          setIsArticleQtySelected(false);
        }
        else {
          setOpenPriceModal(false);
          setTempArticle([]);
          setTempPrice(0);
          setShowPriceError(false);
          setIsArticleQtySelected(false);
        }


        // console.log("Modal closed and data reset. Fetching new data...");

        // Fresko të dhënat
        fetchvaluedetails();
        fetchData();
      } else {
        // console.error("Update failed with status code:", response.status);
        alert("Update failed. Please try again.");
      }
    } catch (error) {
      // Logimi i gabimit në console dhe njoftimi i përdoruesit
      console.error("Error during the update process:", error);
      // alert("Failed to update details");
    }
  };


  // const handleUpdatePayments = (event) => {
  //   event.preventDefault();
  //   const formDetailData = {
  //     ModifiedByUser: email,
  //     InvoiceNr: invoiceNR,
  //     PaymentOptions: paymentOptions
  //   };

  //   axios
  //     .put(ApiLink.API_URL + "POS/setPaymentOptions", formDetailData, {
  //       headers
  //     })
  //     .then((response) => {
  //       openCashModal ? setOpenCashModal(false) : openBankModal(false);
  //       openCashModal ? handleSuccess("Pagesa u perfundua ne ark") : handleSuccess("Pagesa u perfundua ne bank");

  //     })
  //     .catch((error) => {
  //       errorAlert("Nuk eshte paguar ende");
  //     });
  // };

  const handleUpdatePayments = (event) => {
    event.preventDefault();

    const formDetailData = {
      ModifiedByUser: email,
      InvoiceNr: invoiceNR,
      PaymentOptions: paymentOptions
    };

    axios
      .put(ApiLink.API_URL + "POS/setPaymentOptions", formDetailData, {
        headers
      })
      .then((response) => {
        if (openCashModal) {
          setOpenCashModal(false);
          handleSuccess("Pagesa u perfundua ne ark");
          fetchLastInvoiceNr();
          setData([]);
          fetchArticleGroup([]);
          setValueDetails([]);
          setTotaliFatures(0);
          setInsertedTransaction(false)
          setPagesa(0);
          setKusuri(0);
          fetchClients();
          setShowClosePosAlert(false);
          setPaymentOptions(0);
          setShowPriceError(false);

          if (data) {
            downloadReceiptFile(data)
          }
          else {
            errorAlert("Nuk keni te dhena per ta printuar kuponin fiskal")
          }
        } else {
          if (paymentOptions !== 0) {
            setOpenBankModal(false);
            handleSuccess("Pagesa u perfundua ne bank");
            fetchLastInvoiceNr();
            setData([]);
            fetchArticleGroup([]);
            setValueDetails([]);
            setTotaliFatures(0);
            setInsertedTransaction(false)
            setPagesa(0);
            setKusuri(0);
            fetchClients();
            setShowClosePosAlert(false);
            setPaymentOptions(0);
            setShowPriceError(false);

            if (data) {
              downloadReceiptFile(data)
            }
            else {
              errorAlert("Nuk keni te dhena per ta printuar kuponin fiskal")
            }
          }
          else {
            errorAlert("Ju lutem zgjedheni banken")
          }
        }
      })
      .catch((error) => {
        // Log për të parë detajet e error-it
        console.error("Error: ", error);
        setPaymentOptions(0)
        errorAlert("Nuk eshte paguar ende");
      });
  };


  // const handleUpdatePayments = async () => {
  //   try {
  //     const response = await axios.put(ApiLink.API_URL + "POS/setPaymentOptions", {
  //       InvoiceNr: invoiceNR,
  //       ModifiedByUser: logedEmail,
  //       PaymentOptions: paymentOptions
  //     });
  //     alert(invoiceNR, invoiceNR, paymentOptions);

  //     if (response.status === 200) {
  //       // Handle success (e.g., show a success message, reset form, etc.)
  //       setOpenCashModal(false);
  //     } else {
  //       console.error('Failed to update');
  //       // Handle failure (e.g., show an error message)
  //     }
  //   } catch (error) {
  //     console.error('An error occurred:', error);
  //     // Handle error (e.g., show an error message)
  //   }
  // };


  // const constUpdateDiscountAmount = (event) => {
  //   event.preventDefault();
  //   axios
  //     .put(
  //       `${ApiLink.API_URL}POS/aplikoZbritjen?companyID=${companyID}&invoiceNr=${invoiceNR}&percent=${perqindjaZbritjes}`,
  //       {},
  //       { headers }
  //     )
  //     .then((response) => {
  //       fetchvaluedetails();
  //       // fetchArticleDataPrint();
  //       // fetchHeaderData();
  //       fetchData();
  //     })
  //     .catch((error) => {
  //       errorAlert(error);
  //     });
  // };

  // const clearLine = () => {
  //   setArticleID("");
  //   setQtyOutput(0);
  //   setPriceWithoutVAT(0);
  //   setPriceWithVAT(0);
  //   setTotal(0);
  //   fetchData();
  //   fetchvaluedetails();
  //   // fetchHeaderData();
  //   // fetchArticleDataPrint();
  //   setArticlesName("");
  //   setDiscountPercent(0);
  //   setTempPriceWithVAT(0);
  // };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleTextFieldWidth = () => {
  //   return screenWidth <= 450 ? "100%" : "41ch";
  // };
  // const handleCalculatePriceWithVAT = async (type) => {
  //   try {
  //     if (isNaN(priceWithoutVAT)) {
  //       throw new Error(
  //         "Çmimi pa TVSH (priceWithoutVAT) duhet të jetë një numër."
  //       );
  //     }

  //     if (isNaN(qtyOutput)) {
  //       throw new Error(
  //         "Sasia e produkteve (qtyOutput) duhet të jetë një numër."
  //       );
  //     }
  //     if (type === "edit") {
  //       // Veprimet specifike për rastin e editimit
  //       const vat =
  //         Number(formDetailData.PriceWithoutVAT) * (Number(vatRates) / 100);
  //       const calculatedPriceWithVAT =
  //         Number(formDetailData.PriceWithoutVAT) + vat;
  //       const total = Number(formDetailData.QtyOutput) * calculatedPriceWithVAT;

  //       setTempPriceWithVAT(
  //         Number(calculatedPriceWithVAT) /
  //         (1 - Number(formDetailData.DiscountPercent) / 100)
  //       );

  //       setFormDetailData((prevState) => ({
  //         ...prevState,
  //         PriceWithVAT: calculatedPriceWithVAT,
  //         Total: total,
  //         PriceBeforeDiscount: tempPriceWithVAT,
  //       }));
  //     } else {
  //       const vat = Number(priceWithoutVAT) * (Number(vatRates) / 100);
  //       const calculatedPriceWithVAT = Number(priceWithoutVAT) + vat;
  //       const total = Number(qtyOutput) * calculatedPriceWithVAT;
  //       // Veprimet specifike për shtim të ri
  //       setPriceWithVAT(calculatedPriceWithVAT);
  //       setTotal(total);

  //       setTempPriceWithVAT(
  //         Number(calculatedPriceWithVAT) / (1 - Number(discountPercent) / 100)
  //       );
  //     }
  //   } catch (error) {
  //     errorAlert("Gabim ne kalkulim: " + error.message);
  //   }
  // };

  // const handleCalculatePriceWithoutVAT = async (type) => {
  //   try {
  //     if (isNaN(priceWithVAT)) {
  //       throw new Error(
  //         "Çmimi me TVSH (priceWithVAT) duhet të jetë një numër."
  //       );
  //     }

  //     if (isNaN(qtyOutput)) {
  //       throw new Error(
  //         "Sasia e produkteve (qtyOutput) duhet të jetë një numër."
  //       );
  //     }

  //     if (type === "edit") {
  //       // Veprimet specifike për rastin e editimit
  //       const calculatedPriceWithoutVAT =
  //         (Number(formDetailData.PriceWithVAT) * 100) /
  //         (100 + Number(vatRates));
  //       const total =
  //         Number(formDetailData.QtyOutput) *
  //         Number(formDetailData.PriceWithVAT);
  //       setTempPriceWithVAT(
  //         Number(formDetailData.PriceWithVAT) /
  //         (1 - Number(formDetailData.DiscountPercent) / 100)
  //       );

  //       setFormDetailData((prevState) => ({
  //         ...prevState,
  //         PriceWithoutVAT: calculatedPriceWithoutVAT,
  //         Total: total,
  //         PriceBeforeDiscount: tempPriceWithVAT,
  //       }));
  //     } else {
  //       setTempPriceWithVAT(
  //         Number(priceWithVAT) / (1 - Number(discountPercent) / 100)
  //       );

  //       // Veprimet specifike për shtim të ri
  //       const calculatedPriceWithoutVAT =
  //         (Number(priceWithVAT) * 100) / (100 + Number(vatRates));
  //       const total = Number(qtyOutput) * Number(priceWithVAT);

  //       setPriceWithoutVAT(calculatedPriceWithoutVAT);
  //       setTotal(total);
  //     }
  //   } catch (error) {
  //     errorAlert("Gabim ne kalkulim: " + error.message);
  //   }
  // };

  // const handleCalculatePrices = async (type) => {
  //   try {
  //     if (isNaN(qtyOutput)) {
  //       throw new Error(
  //         "Sasia e produkteve (qtyOutput) duhet të jetë një numër."
  //       );
  //     }

  //     if (isNaN(total)) {
  //       throw new Error("Totali duhet të jetë një numër.");
  //     }

  //     if (type === "edit") {
  //       // Veprimet specifike për rastin e editimit
  //       const priceWithVAT =
  //         Number(formDetailData.Total) / Number(formDetailData.QtyOutput);
  //       // const calculatedPriceWithoutVAT = formDetailData.PriceWithVAT * 100 / (100 + Number(vatRates));
  //       const calculatedPriceWithoutVAT =
  //         (priceWithVAT * 100) / (100 + Number(vatRates));
  //       // setTempPriceWithVAT(Number(formDetailData.PriceWithVAT) / (1 - (Number(formDetailData.DiscountPercent) / 100)))
  //       const tempPriceWithVATEdit =
  //         Number(priceWithVAT) /
  //         (1 - Number(formDetailData.DiscountPercent) / 100.0);

  //       setFormDetailData((prevState) => ({
  //         ...prevState,
  //         PriceWithVAT: priceWithVAT,
  //         PriceWithoutVAT: calculatedPriceWithoutVAT,
  //         PriceBeforeDiscount: tempPriceWithVATEdit,
  //       }));
  //     } else {
  //       // Veprimet specifike për shtim të ri
  //       const priceWithVAT = Number(total) / Number(qtyOutput);
  //       const calculatedPriceWithoutVAT =
  //         (priceWithVAT * 100) / (100 + Number(vatRates));

  //       setTempPriceWithVAT(
  //         Number(priceWithVAT) / (1 - Number(discountPercent) / 100)
  //       );

  //       setPriceWithoutVAT(calculatedPriceWithoutVAT);
  //       setPriceWithVAT(priceWithVAT);
  //     }
  //   } catch (error) {
  //     errorAlert("Gabim ne kalkulim: " + error.message);
  //   }
  // };

  // const handleCalculatePriceWithRabat = async (type) => {
  //   try {
  //     if (isNaN(discountPercent)) {
  //       throw new Error("% e rabatit (Rabati) duhet të jetë një numër.");
  //     }

  //     if (type === "edit") {
  //       // Veprimet specifike për rastin e editimit
  //       setTempPriceWithVAT(formDetailData.PriceBeforeDiscount);

  //       const vleraZbritjes =
  //         tempPriceWithVAT * (formDetailData.DiscountPercent / 100);
  //       const priceWithVATRabat =
  //         Number(tempPriceWithVAT) - Number(vleraZbritjes);

  //       const calculatedPriceWithoutVAT =
  //         (Number(priceWithVATRabat) * 100) / (100 + Number(vatRates));
  //       const total =
  //         Number(formDetailData.QtyOutput) * Number(priceWithVATRabat);

  //       setFormDetailData((prevState) => ({
  //         ...prevState,
  //         PriceWithoutVAT: calculatedPriceWithoutVAT,
  //         PriceWithVAT: priceWithVATRabat,
  //         Total: total,
  //         PriceBeforeDiscount: tempPriceWithVAT,
  //       }));
  //     } else {
  //       // Veprimet specifike për shtim të ri
  //       const vleraZbritjes = tempPriceWithVAT * (discountPercent / 100);
  //       const priceWithVATRabat =
  //         Number(tempPriceWithVAT) - Number(vleraZbritjes);

  //       const calculatedPriceWithoutVAT =
  //         (Number(priceWithVATRabat) * 100) / (100 + Number(vatRates));
  //       const total = Number(qtyOutput) * Number(priceWithVATRabat);

  //       setPriceWithoutVAT(calculatedPriceWithoutVAT);
  //       setPriceWithVAT(priceWithVATRabat);
  //       setTotal(total);
  //     }
  //   } catch (error) {
  //     errorAlert("Gabim ne kalkulim: " + error.message);
  //   }
  // };

  const [searchTerm, setSearchTerm] = useState("");

  // i filtron Ledgers
  useEffect(() => {
    // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
    setFilteredClients(
      clients.filter((data) =>
        data.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, clients]);

  useEffect(() => {
    // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
    setFilteredArticles(
      articles.filter((data) =>
        data.ArticleName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, articles]);

  // const handleInputChange = (newValue) => {
  //   const inputValue = newValue.replace(/\W/g, "");
  //   //setDebitLedgerID(inputValue);
  //   setSearchTerm(inputValue);
  // };

  // const [clientName, setClientName] = useState("");
  // const handleFilterClient = (selectedOption) => {
  //   if (selectedOption && selectedOption.AccountID) {
  //     setSupplierID(selectedOption.AccountID);
  //     setClientName(selectedOption.label);
  //     fetchLastInvoiceNr();
  //   } else {
  //     setSupplierID("");
  //     setClientName("");
  //   }
  // };

  // const [articlesName, setArticlesName] = useState("");
  // const handleFilterArticles = (selectedOption) => {
  //   if (selectedOption && selectedOption.ArticleID) {
  //     setArticleID(selectedOption.ArticleID);
  //     setArticlesName(selectedOption.label);
  //     fetchArticlesSalesPrice(selectedOption.ArticleID);
  //   } else {
  //     setArticleID("");
  //     setArticlesName("");
  //   }
  // };

  useEffect(() => {
    // Kontrollo nqs supplierID është ndryshuar para se të kryhen veprimet
    if (supplierID !== "") {
      fetchClientsDetails(supplierID);
    }
  }, [supplierID]);

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setDate(formattedDate);
    setPayDate(formattedDate);
  }, []);

  // const calculateAndSetPaymentDate = (pay) => {
  //     // Supozojmë që 'date' është një objekt i vlefshëm Date.
  //     // Nëse jo, duhet të krijohet si i tillë.
  //     let paymentDate = new Date(date); // Kjo siguron që paymentDate të jetë një objekt Date.

  //     if (pay === "0") {
  //         // Shtojmë 5 ditë nëse paymentOptions është "0"
  //         paymentDate.setDate(paymentDate.getDate() + 5); // Përdor getDate() për të marrë ditën dhe pastaj shto 5
  //         const formattedDate = paymentDate.toISOString().split('T')[0];
  //         setPayDate(formattedDate);
  //     } else {
  //         // Supozojmë që 'date' është formatuar siç duhet
  //         setPayDate(date);
  //     }
  // };

  // const barcodeFieldKeyPress = (event) => {
  //     if (event.key === 'Enter') {
  //         if (articleDoNotExist) {
  //             errorAlert("Arikulli me këtë barkod nuk ekziston");
  //         }
  //         else {
  //             if (insertedArticle) {
  //                 handleNewSubmit(event);
  //             }
  //         }

  //     }
  // };

  const insertArticlerOnClick = async (articleID) => {
    await fetchArticlesSalesPrice(articleID, 'insert');
    setArticleID(articleID);
    setInsertedArticle(true);
  };

  // const barcodeFieldKeyPress = async (event) => {
  //   if (searchByBarcode) {
  //     if (event.key === "Enter") {
  //       if (!barcode) {
  //         errorAlert("Ju lutem plotësoni barkodin");
  //       } else {
  //         fetchArticlesSalesPriceByBarcode(barcode);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (insertedArticle) {
      handleNewSubmit();
    }
  }, [insertedArticle]);


  const rows = data.map((item, index) => ({
    id: ++index,
    barcode: item.Barcode,
    name: item.ArticleName,
    sasia: item.QtyOutput,
    price: item.PriceWithVAT,
    amountNoVAT: item.Amount,
    total: item.Total,
    itemID: item.HistoryID,
    rowInfo: item,
  }));

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 10,
      headerClassName: "super-app-theme--header",
      groupable: false,
      aggregable: false,
    },
    {
      field: "barcode",
      headerName: "Barkodi",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
      groupable: false,
      aggregable: false,
    },
    {
      field: "name",
      headerName: "Emertimi",
      width: 320,
      editable: false,
      headerClassName: "super-app-theme--header",
      groupable: false,
      aggregable: false,
    },
    {
      field: "sasia",
      headerName: "Sasia",
      width: 100,
      editable: false,
      headerClassName: "super-app-theme--header",
      groupable: false,
      aggregable: false,
    },
    // {
    //   field: "sasia",
    //   headerName: "Sasia",
    //   width: 100,
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    //   groupable: false,
    //   aggregable: false,
    //   renderCell: (params) => (
    //     <div
    //       onClick={() => handleChangeQTYInput(params.row.rowInfo)}
    //       style={{ cursor: 'pointer' }} // Shto një cursor pointer për të treguar që është i klikueshëm
    //     >
    //       {params.value}
    //     </div>
    //   ),
    // },
    {
      field: "price",
      headerName: "Cmimi",
      type: "string",
      width: 100,
      editable: false,
      headerClassName: "super-app-theme--header",
      groupable: true,
      valueFormatter: (value) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: "total",
      headerName: "Total",
      type: "string",
      width: 100,
      editable: false,
      headerClassName: "super-app-theme--header",
      groupable: true,
      valueFormatter: (value) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
  ];


  // const [enterCount, setEnterCount] = useState(0);
  // const inputRef = useRef(null);

  // const focusPayment = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();

  //     // Increase the count and clear any existing timer
  //     setEnterCount((prevCount) => prevCount + 1);
  //     clearTimeout(timerRef.current);

  //     // Set a timer to reset the count after a short delay
  //     timerRef.current = setTimeout(() => {
  //       setEnterCount(0);
  //     }, 500); // 500ms delay to detect double press

  //     // Perform actions based on the enter count
  //     if (enterCount + 1 === 2) {
  //       if (inputRef.current && inputRef.current.select) {
  //         inputRef.current.focus();
  //         inputRef.current.select();
  //       }
  //       // Reset the enter count after action is performed
  //       setEnterCount(0);
  //     }
  //   }
  // };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const kalkuloKusur = async (paguar,) => {

    const calc = Number(paguar) - Number(totaliFatures);
    setKusuri(calc)
  }


  const fetchBanks = async () => {
    try {
      const response = await axios.get(ApiLink.API_URL + 'Accounts/accountByLedger', { headers, params: { ledger: 102 } });
      setBanks(response.data);
    } catch (error) {
      errorAlert("Gabim në kërkesë:", error);

      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
      } else if (error.message) {
        errorAlert(`Gabim: ${error.message}`);
      } else {
        errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
      }
    }
  };

  // const insertArticleWithEnter = (event) => {
  //   if (event.key === "Enter") {
  //     handleNewSubmit(event);
  //   }
  // };

  //Kjo pjes sherben per ekzekutimin e funksioneve permes tasteve
  // const handleKeyPress = (event) => {
  //   switch (event.key) {
  //     case 'F1':
  //       event.preventDefault();
  //       if (!searchByBarcode) {
  //         setSearchByBarcode(true);
  //         // Delay focus to ensure state change is processed
  //         setTimeout(() => {
  //           if (barcodeRef.current) {
  //             barcodeRef.current.focus();
  //           }
  //         }, 0);
  //       }
  //       break;
  //     case 'F2':
  //       event.preventDefault();
  //       if (searchByBarcode) {
  //         setSearchByBarcode(false);
  //         // Delay focus to ensure state change is processed
  //         setTimeout(() => {
  //           if (articleList.current) {
  //             articleList.current.focus();
  //           }
  //         }, 0);
  //       }
  //       break;
  //     case 'F5':
  //       event.preventDefault();
  //       if (!openQtyModal) {
  //         setOpenQtyModal(true);
  //         // setPagesa('')
  //         // Delay focus to ensure state change is processed
  //         setTimeout(() => {
  //           if (qtyInputField.current) {
  //             qtyInputField.current.select();
  //           }
  //         }, 0);
  //       }
  //       break;
  //     case 'F6':
  //       event.preventDefault();
  //       if (!openCashModal) {
  //         setOpenCashModal(true);
  //         // setPagesa('')
  //         // Delay focus to ensure state change is processed
  //         setTimeout(() => {
  //           if (pagesaArke.current) {
  //             pagesaArke.current.select();
  //           }
  //         }, 0);
  //       }
  //       break;
  //     case 'F7':
  //       event.preventDefault();
  //       if (!openBankModal) {
  //         setOpenBankModal(true);

  //       }
  //       break;
  //     case 'F10':
  //       event.preventDefault();
  //       // downloadXRaport();
  //       setOpenXraportConfirm(true);
  //       break;
  //     case 'F11':
  //       event.preventDefault();
  //       // downloadZraport();
  //       setOpenZraportConfirm(true);
  //       break;
  //     case 'F12':
  //       event.preventDefault();
  //       setShowDeleteTransactionID(true);
  //       break;
  //     case 'Enter':
  //       event.preventDefault();
  //       if (openCashModal || openBankModal) {
  //         if (totaliFatures == 0) {
  //           errorAlert("Nuk ka asnje produkt ne grid per ta printuar kuponin")
  //           setOpenCashModal(false);
  //         }
  //         else {
  //           handleUpdatePayments(event);
  //         }
  //       }
  //       else {
  //         if (searchByBarcode) {
  //           fetchArticlesSalesPriceByBarcode(barcode);

  //         } else {
  //           setTimeout(() => {
  //             handleNewSubmit();
  //           }, 100);  // 100 ms është zakonisht e mjaftueshme
  //         }
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const [actionTriggered, setActionTriggered] = useState(false); // Për të menaxhuar ekzekutimin e funksioneve

  const handleKeyPress = useCallback((event) => {
    switch (event.key) {
      case 'F1':
        event.preventDefault();
        if (!searchByBarcode) {
          setSearchByBarcode(true);
          setTimeout(() => barcodeRef.current?.focus(), 0);
        }
        break;
      case 'F2':
        event.preventDefault();
        if (searchByBarcode) {
          setSearchByBarcode(false);
          setTimeout(() => articleList.current?.focus(), 0);
        }
        break;
      // case 'F4':
      //   event.preventDefault();
      //   if (!openPriceModal) {
      //     setOpenPriceModal(true);
      //     setTimeout(() => priceInputField.current?.focus(), 0);
      //   }
      //   break;
      case 'F4':
        event.preventDefault();
        if (!isArticleQtySelected) {
          setOpenQtyAlert(true);  // Show alert if article is not selected
        } else {
          setOpenQtyAlert(false);  // Hide alert when modal opens
          if (!openPriceModal) {
            setOpenPriceModal(true);  // Open modal if article is selected
            setTimeout(() => priceInputField.current?.focus(), 0);  // Focus input
          }
        }
        break;

      // case 'F5':
      //   event.preventDefault();
      //   if (!openQtyModal) {
      //     setOpenQtyModal(true);
      //     setTimeout(() => qtyInputField.current?.focus(), 0);
      //   }
      //   break;
      case 'F5':
        event.preventDefault();
        if (!isArticleQtySelected) {
          setOpenQtyAlert(true);  // Show alert if article is not selected
        } else {
          setOpenQtyAlert(false);  // Hide alert when modal opens
          if (!openQtyModal) {
            setOpenQtyModal(true);  // Open modal if article is selected
            setTimeout(() => qtyInputField.current?.focus(), 0);  // Focus input
          }
        }
        break;
      case 'F6':
        event.preventDefault();
        if (!openCashModal) {
          setOpenCashModal(true);
          setPaymentOptions(tempPaymentOption);
          setTimeout(() => pagesaArke.current?.select(), 0);
        }
        break;
      case 'F7':
        event.preventDefault();
        if (!openBankModal) {
          setOpenBankModal(true);
        }
        break;
      case 'F10':
        event.preventDefault();
        setOpenXraportConfirm(true);
        break;
      case 'F11':
        event.preventDefault();
        setOpenZraportConfirm(true);
        break;
      case 'F12':
        event.preventDefault();
        setShowDeleteTransactionID(true);
        break;
      case 'Enter':
        event.preventDefault();
        if (openCashModal || openBankModal) {
          if (totaliFatures === 0) {
            errorAlert("Nuk ka asnje produkt ne grid per ta printuar kuponin");
            setOpenCashModal(false);
          } else {
            handleUpdatePayments(event);
          }
        } else {
          setActionTriggered(true);
        }
        break;
      default:
        break;
    }
  }, [searchByBarcode, openCashModal, openBankModal, openQtyModal, totaliFatures, isArticleQtySelected]);

  useEffect(() => {
    if (actionTriggered) {
      setActionTriggered(false); // Ri-inicializo gjendjen për veprime të ardhshme

      if (openCashModal || openBankModal) {
        if (totaliFatures === 0) {
          errorAlert("Nuk ka asnje produkt ne grid per ta printuar kuponin");
          setOpenCashModal(false);
        } else {
          handleUpdatePayments();
        }
      }
      else if (openQtyModal) {
        handleChangeQtyWithInput(tempArticle, tempQty, "qty")
      }
      else if (openPriceModal) {
        handleChangeQtyWithInput(tempArticle, tempPrice, "price")
      }
      else if (isInactive) {
        refreshComponent();
      }
      else {
        if (searchByBarcode) {
          fetchArticlesSalesPriceByBarcode(barcode);
        } else {
          handleNewSubmit();
        }
      }
    }
  }, [actionTriggered, searchByBarcode, barcode, openCashModal, openBankModal, totaliFatures, openQtyModal, tempArticle, tempQty, isInactive, openPriceModal, tempPrice]);


  // `useEffect` për të regjistruar dhe hequr event listener-in për tastierën
  useEffect(() => {
    const handleKeyDown = (event) => handleKeyPress(event);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyPress]);



  //E hap modalin e pageses me CASH
  const openModalAndSelectText = () => {
    setOpenCashModal(true);
    // Delay focus to ensure state change is processed
    setTimeout(() => {
      if (pagesaArke.current) {
        pagesaArke.current.select();
      }
    }, 0);
  };


  // Funksioni për të gjeneruar përmbajtjen e file-it .txt
  const generateReceiptContent = (data) => {
    let fileContent = "";
    fileContent += `Q,1,______,_,__;1;Fatura: POS-2024-${invoiceNR}\n`;
    fileContent += `Q,1,______,_,__;2;Arkatari:${logedEmail}\n`;

    data.map((item, index) => {
      fileContent += `S,1,______,_,__;${item.ArticleName};${item.PriceWithVAT};${item.QtyOutput};1;1;${(item.VATRates == 18) ? 5 : (item.VATRates == 8) ? 4 : 1};${item.Barcode};${item.Barcode};\n`;
    });

    fileContent += "T,1,______,_,__;\n";

    return fileContent;
  };

  // Funksioni për të krijuar dhe shkarkuar file-in .txt
  const downloadReceiptFile = (data) => {
    const fileContent = generateReceiptContent(data);
    const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `POS-2024-${invoiceNR}.inp`);
  };


  // Funksioni për të gjeneruar ZRaportin
  const generateZRaport = () => {
    let fileContent = "";
    fileContent += `E,1,______,_,__;Printo Z raportin;F-Link ks\n`;
    fileContent += `Z,1,______,_,__;\n`;
    fileContent += "E,1,______,_,__;F-Link ks Enternet\n";

    return fileContent;
  };

  // Funksioni për të shkarkuar ZRaportin
  const downloadZraport = () => {
    const fileContent = generateZRaport();
    const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `ZRaport.inp`);
    setOpenZraportConfirm(false);
  };

  // Funksioni për të shkarkuar XRaportin
  const generateXRaport = () => {
    let fileContent = "";
    fileContent += `E,1,______,_,__;Printo X raportin;F-Link ks\n`;
    fileContent += `X,1,______,_,__;\n`;
    fileContent += "E,1,______,_,__;F-Link ks Enternet\n";

    return fileContent;
  };

  // Funksioni për të shkarkuar XRaportin
  const downloadXRaport = () => {
    const fileContent = generateXRaport();
    const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `XRaport.inp`);
    setOpenXraportConfirm(false);
  };

  // const handleChangeQTYInput = (row) => {
  //   // Veprimet që dëshironi të kryeni kur klikohet sasia
  //   setOpenQtyModal(true);
  //   setTempArticleID(row.ArticleID);
  //   setTempArticleName(row.ArticleName);
  //   setTempQty(row.QtyOutput);
  //   setTempArticle(row);
  // };

  //ky kod lidhet me funksionet per ekzekutim me an te tasteve
  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyPress);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, [openCashModal, openBankModal]);

  // // Funksioni për të rinisur kohën e pasivitetit
  // const resetTimer = () => {
  //   if (timer) {
  //     clearTimeout(timer);
  //   }
  //   // Rinis timer për 2 minuta (120000 ms)
  //   setTimer(setTimeout(() => setIsInactive(true), 120000));
  // };

  // // Funksioni që e refresh faqen
  // const refreshPage = () => {
  //   window.location.reload(); // Rinis faqen aktuale
  // };

  // useEffect(() => {
  //   // Vendosim resetimin e timer-it për lëvizje të mausit dhe shtypje të tastit
  //   window.addEventListener("mousemove", resetTimer);
  //   window.addEventListener("keydown", resetTimer);

  //   // Rinis timer kur komponenti ngarkohet
  //   resetTimer();

  //   return () => {
  //     // Hiq eventet kur komponenti unmount
  //     window.removeEventListener("mousemove", resetTimer);
  //     window.removeEventListener("keydown", resetTimer);
  //   };
  // }, []);

  // const comparePricesAndShowAlert = () => {
  //   if (purchasePrice > priceWithVAT) {
  //     setShowPriceError(true);  // Vendos gjendjen në true nëse kushti është plotësuar
  //     return (
  //       <Alert severity="error">
  //         Kujdes!, Qmimi i shitjës është më i vogël se qmimi i blerjës
  //       </Alert>
  //     );
  //   } else {
  //     setShowPriceError(false);  // Vendos gjendjen në false nëse kushti nuk plotësohet
  //     return null;  // Kthen asgjë nëse kushti nuk plotësohet
  //   }
  // };

  // Funksioni për të rinisur kohën e pasivitetit
  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);  // Fshij timer-in ekzistues
    }
    // Rinis timer për 2 minuta (120000 ms)
    timerRef.current = setTimeout(() => setIsInactive(true), 120000);
  };

  // Funksioni që rifreskon komponentin
  const refreshComponent = () => {
    setIsInactive(false);  // Hiq overlay-in dhe e bën faqen aktive përsëri
    setRefreshKey(prevKey => prevKey + 1);  // Ndryshon refreshKey për të rifreskuar komponentët
    resetTimer();  // Rinis timer pas rifreskimit
    setShowDeleteTransactionID(false);
    fetchLastInvoiceNr();
    setData([]);
    fetchArticleGroup([]);
    setValueDetails([]);
    setTotaliFatures(0);
    setKusuri(0);
    setPagesa(0);
    setInsertedTransaction(false)
    setShowPriceError(false);
    setTimeout(() => barcodeRef.current?.focus(), 0);

  };

  useEffect(() => {
    // Vendosim resetimin e timer-it për lëvizje të mausit dhe shtypje të tastit
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    // Rinis timer kur komponenti ngarkohet
    resetTimer();

    return () => {
      // Hiq eventet kur komponenti unmount
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      if (timerRef.current) {
        clearTimeout(timerRef.current);  // Fshij timer-in kur komponenti largohet
      }
    };
  }, []);


  useEffect(() => {
    fetchLastInvoiceNr();
  }, []);

  useEffect(() => {
    fetchClients();
    fetchArticles();
    fetchCompaniesData();
    fetchBanksAccount();
    fetchTransactionID();
    fetchPaymentType();
    fetchArticleGroup();
    fetchBanks();
    fetchUserDetails();
    fetchPOS();
  }, [logedEmail, invoiceNR, supplierID, articleID]);
  return (
    <>
      {isInactive && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Stack sx={{ width: '50%' }} spacing={2}>
            <Alert severity="warning" style={{ fontWeight: "bold" }}>POS-i juaj është pasivizuar pas 5 minutave pauzë!</Alert>
          </Stack>
          {/* <button
            style={{
              padding: "20px",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={refreshComponent}
          >
            Rifillo Shitjen
          </button> */}
          <br></br>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="warning"
              style={{
                padding: "20px",
                fontSize: "20px",
                cursor: "pointer",
                fontWeight: "bold"
              }}
              onClick={refreshComponent}  >
              Rifillo Shitjen
            </Button>
          </Stack>
        </div>
      )}
      <div className="POS__Container" key={refreshKey}>
        <Modal_bootstrap show={showDeleteTransactionID} onHide={() => setShowDeleteTransactionID(false)} style={{ marginTop: "150px" }}>
          <Modal_bootstrap.Header>
            <Modal_bootstrap.Title>Konfirmim</Modal_bootstrap.Title>
          </Modal_bootstrap.Header>
          <Modal_bootstrap.Body>
            <p>A dëshironi ta anuloni faturën</p>
          </Modal_bootstrap.Body>
          <Modal_bootstrap.Footer>
            <Button_Bootstrap variant="secondary" onClick={() => setShowDeleteTransactionID(false)}>
              JO
            </Button_Bootstrap>
            <Button_Bootstrap variant="danger" onClick={handleDeleteTransactionID}>
              PO
            </Button_Bootstrap>
          </Modal_bootstrap.Footer>
        </Modal_bootstrap>

        <Modal_bootstrap show={openZraportConfirm} onHide={() => setOpenZraportConfirm(false)} style={{ marginTop: "150px" }}>
          <Modal_bootstrap.Header>
            <Modal_bootstrap.Title>Konfirmim</Modal_bootstrap.Title>
          </Modal_bootstrap.Header>
          <Modal_bootstrap.Body>
            <p>A dëshironi ta printoni Z-Raportin</p>
          </Modal_bootstrap.Body>
          <Modal_bootstrap.Footer>
            <Button_Bootstrap variant="secondary" onClick={() => setOpenZraportConfirm(false)}>
              JO
            </Button_Bootstrap>
            <Button_Bootstrap variant="danger" onClick={downloadZraport}>
              PO
            </Button_Bootstrap>
          </Modal_bootstrap.Footer>
        </Modal_bootstrap>

        <Modal_bootstrap show={openXraportConfirm} onHide={() => setOpenXraportConfirm(false)} style={{ marginTop: "150px" }}>
          <Modal_bootstrap.Header>
            <Modal_bootstrap.Title>Konfirmim</Modal_bootstrap.Title>
          </Modal_bootstrap.Header>
          <Modal_bootstrap.Body>
            <p>A dëshironi ta printoni X-Raportin</p>
          </Modal_bootstrap.Body>
          <Modal_bootstrap.Footer>
            <Button_Bootstrap variant="secondary" onClick={() => setOpenXraportConfirm(false)}>
              JO
            </Button_Bootstrap>
            <Button_Bootstrap variant="danger" onClick={downloadXRaport}>
              PO
            </Button_Bootstrap>
          </Modal_bootstrap.Footer>
        </Modal_bootstrap>

        <Modal_bootstrap show={openQtyModal} onHide={() => setOpenQtyModal(false)} style={{ marginTop: "150px" }}>
          <Modal_bootstrap.Header>
            <Modal_bootstrap.Title>Ndrysho sasin</Modal_bootstrap.Title>
          </Modal_bootstrap.Header>
          <Modal_bootstrap.Body style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              id="outlined-read-only-input"
              label="Emertimi artikullit"
              value={tempArticle.ArticleName}
              InputProps={{ readOnly: true }}
            />
            <br />
            <TextField
              id="outlined-read-only-input"
              label="Sasia"
              value={tempQty}
              autoComplete="off"
              onChange={(e) => setTempQty(e.target.value)}
              inputRef={qtyInputField}
            />
          </Modal_bootstrap.Body>
          <Modal_bootstrap.Footer>
            <Button_Bootstrap variant="secondary" onClick={() => setOpenQtyModal(false)}>
              Anulo
            </Button_Bootstrap>
            <Button_Bootstrap variant="danger" type="submit" onClick={() => { handleChangeQtyWithInput(tempArticle, tempQty, "qty") }}>
              Ruaj
            </Button_Bootstrap>
          </Modal_bootstrap.Footer>
        </Modal_bootstrap>

        <Modal_bootstrap show={openPriceModal} onHide={() => setOpenPriceModal(false)} style={{ marginTop: "150px" }}>
          <Modal_bootstrap.Header>
            <Modal_bootstrap.Title>Ndrysho Qmimin</Modal_bootstrap.Title>
          </Modal_bootstrap.Header>
          <Modal_bootstrap.Body style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              id="outlined-read-only-input"
              label="Emertimi artikullit"
              value={tempArticle.ArticleName}
              InputProps={{ readOnly: true }}
            />
            <br />
            <TextField
              id="outlined-read-only-input"
              label="Qmimi"
              value={tempPrice}
              autoComplete="off"
              onChange={(e) => setTempPrice(e.target.value)}
              inputRef={priceInputField}
            />
          </Modal_bootstrap.Body>
          <Modal_bootstrap.Footer>
            <Button_Bootstrap variant="secondary" onClick={() => setOpenPriceModal(false)}>
              Anulo
            </Button_Bootstrap>
            <Button_Bootstrap variant="danger" type="submit" onClick={() => { handleChangeQtyWithInput(tempArticle, tempQty, "price") }}>
              Ruaj
            </Button_Bootstrap>
          </Modal_bootstrap.Footer>
        </Modal_bootstrap>

        <Modal
          open={openCashModal}
          onClose={() => setOpenCashModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: "auto", bgcolor: 'background.paper', borderRadius: '4px', boxShadow: 24, p: 4 }}>
            <Typography sx={{ backgroundColor: "lightgreen", m: 1, height: "30px", color: "black", fontWeight: "bold", fontSize: "20px", textAlign: 'center' }}>{cashRegisterName}</Typography>
            <FormControl sx={{ m: 1 }} variant="filled" focused>
              <InputLabel htmlFor="Totali" style={{ color: "white" }}>
                Totali
              </InputLabel>
              <FilledInput
                id="Totali"
                style={{
                  height: "80",
                  fontSize: "40px",
                  backgroundColor: "black",
                  color: "white",
                }}
                value={totaliFatures > 0
                  ? totaliFatures.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                  : "0.00"
                }
                readOnly
              // startAdornment={<InputAdornment position="start" style={{fontSize:"40px" }}>€</InputAdornment>}
              />
            </FormControl>
            <br></br>
            <FormControl sx={{ m: 1 }} variant="filled" focused>
              <InputLabel htmlFor="Pagesa" style={{ color: "black" }}>
                Pagesa
              </InputLabel>
              <FilledInput
                id="Pagesa"
                inputRef={pagesaArke} // Këtu caktohet referenca
                style={{
                  height: "80px",
                  fontSize: "40px",
                  backgroundColor: "lightgreen",
                  color: "black",
                }}
                value={pagesa.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                onChange={(e) => setPagesa(e.target.value)}
                onKeyUp={(event) => kalkuloKusur(event.target.value)}
                autoComplete="off"
              />
            </FormControl>

            <br></br>
            <FormControl sx={{ m: 1 }} variant="filled" focused>
              <InputLabel htmlFor="Kusur" style={{ color: "white" }}>
                Kusur
              </InputLabel>
              <FilledInput
                id="Kusur"
                style={{
                  height: "80",
                  fontSize: "40px",
                  backgroundColor: "red",
                  color: "black",
                }}
                value={kusuri > 0
                  ? kusuri.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                  : "0.00"
                }
                readOnly
              // startAdornment={<InputAdornment position="start" style={{fontSize:"40px" }}>€</InputAdornment>}
              />
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
              <Button variant="contained" color="secondary" onClick={() => { setOpenCashModal(false); }} sx={{ width: "45%" }}>Mbyll</Button>
              <Button variant="contained" type="submit" onClick={handleUpdatePayments} sx={{ width: "45%" }}>Shtyp</Button>
              {/* <button onClick={() => downloadReceiptFile(purchaseInvoice)}>Generate Receipt File</button> */}

            </Box>
          </Box>
        </Modal>

        <Modal
          open={openBankModal}
          onClose={() => setOpenBankModal(false)}
          aria-labelledby="update-modal-title"
          aria-describedby="update-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "auto", bgcolor: 'background.paper', borderRadius: '4px', boxShadow: 24, p: 4 }}>
            <FormControl sx={{ m: 1 }} variant="filled" focused>
              <InputLabel htmlFor="Totali" style={{ color: "white" }}>
                Totali
              </InputLabel>
              <FilledInput id="Totali" style={{
                height: "80",
                fontSize: "40px",
                backgroundColor: "black",
                color: "white",
              }}
                value={totaliFatures > 0
                  ? totaliFatures.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                  : "0.00"
                }
                readOnly
              />
            </FormControl>
            {
              banks &&
              banks.map((bank, index) => (
                <>
                  <FormControl sx={{ alignItems: "center", m: 1 }} variant="filled" focused>
                    <Button variant="contained" color={(paymentOptions == bank.AccountID) ? "success" : "primary"} index={index} sx={{ width: "100%" }} type="submit" onClick={(e) => setPaymentOptions(bank.AccountID)}>{bank.AccountName}</Button>
                  </FormControl>
                </>

              ))
            }
            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
              <Button variant="contained" color="secondary" onClick={() => { setOpenBankModal(false); }} sx={{ width: "45%" }}>Mbyll</Button>
              <Button variant="contained" type="submit" onClick={handleUpdatePayments} sx={{ width: "45%" }}>Shtyp</Button>
            </Box>
          </Box>
        </Modal>

        <div className="POS__Container__Header">
          <div>
            <h5><AccountCircleIcon /> {logedEmail.split("@acctree.net")}</h5>
          </div>
          <div>
            <h5><AccessTimeFilledIcon /> Ora: {formattedTime}</h5>
          </div>
          <div>
            <h5><DateRangeIcon />Data: {formattedDate}</h5>
          </div>
          <div>
            <h5><BusinessIcon /> {companyName && companyName}</h5>
          </div>
        </div>
        <hr></hr>
        <div className="POS__Container__Body">
          <div className="POS__Container__LeftSide">
            <div className="POS__Container__LeftSide__ClientDetails">
              <div className="POS__Container__LeftSide__ClientDetails__left">
                <div className="POS__Container__LeftSide__Client">
                  {/* <Autocomplete
                  fullWidth
                  {...defaultProps}
                  id="include-input-in-list"
                  // includeInputInList
                  onChange={handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      variant="filled"
                      defaultValue={"Konsumator privat"}
                    />
                  )}
                /> */}
                  <TextField
                    style={{ width: "100%" }}
                    id="Client"
                    name="Client"
                    value={clientName}
                    // onKeyUp={(event) => fetchArticlesSalesPriceByBarcode(event.target.value)}
                    label="Klienti"
                    variant="filled"
                    readOnly
                  />
                </div>
                <br></br>
                <div className="POS__Container__LeftSide__Article">
                  <div className="POS__Container__LeftSide__Article__fields">
                    {searchByBarcode ? (
                      <>
                        <TextField
                          style={{ width: "100%" }}
                          id="barcode"
                          name="barcode"
                          value={barcode}
                          onChange={(event) => { setBarcode(event.target.value); setShowPriceError(false) }}
                          // onKeyUp={(event) => fetchArticlesSalesPriceByBarcode(event.target.value)}
                          label="Kerko me barkod"
                          variant="filled"
                          autoComplete="off"
                          onKeyPress={handleKeyPress}
                          inputRef={barcodeRef} // Përdorni inputRef për të referuar fushën

                        />
                      </>
                    ) : (
                      // <Autocomplete
                      //   fullWidth
                      //   {...defaultPropsArticle}
                      //   id="include-input-in-list"
                      //   includeInputInList
                      //   onChange={handleAutocompleteChangeArticle} // Shto funksionin onChange
                      //   renderInput={(params) => (
                      //     <TextField
                      //       {...params}
                      //       label="Kerko Artikullin"
                      //       variant="filled"
                      //       onKeyPress={handleKeyPress}
                      //       inputRef={articleList}
                      //     />
                      //   )}
                      // />
                      <Autocomplete
                        fullWidth
                        {...defaultPropsArticle}
                        id="include-input-in-list"
                        includeInputInList
                        onChange={handleAutocompleteChangeArticle}
                        value={selectArticleField}
                        isOptionEqualToValue={(option, value) => option.ArticleID === value.ArticleID} // Shto funksionin isOptionEqualToValue
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Kerko Artikullin"
                            variant="filled"
                            onKeyPress={handleKeyPress}
                            inputRef={articleList}
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className="POS__Container__LeftSide__Article__button">
                    {searchByBarcode ? (
                      <Button
                        variant="outlined"
                        style={{
                          borderRadius: "0px",
                          height: "56px",
                          width: "100%",
                        }}
                        startIcon={<KeyboardIcon />}
                        onClick={(e) => setSearchByBarcode(false)}
                      >
                        {/* Kerko me emertim */}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{ borderRadius: "0px", height: "56px" }}
                        startIcon={<QrCode2Icon />}
                        onClick={(e) => setSearchByBarcode(true)}
                      >
                        {/* Kerko me barkod */}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="POS__Container__LeftSide__ClientDetails__Right">
                <FormControl fullWidth sx={{ m: 0 }} variant="filled" focused>
                  <InputLabel htmlFor="Totali" style={{ color: "white" }}>
                    Totali
                  </InputLabel>
                  <FilledInput
                    id="Totali"
                    style={{
                      height: "140px",
                      fontSize: "40px",
                      backgroundColor: "black",
                      color: "white"
                    }}
                    value={
                      valuedetails &&
                        valuedetails.length > 0 &&
                        valuedetails[0].Total !== null &&
                        valuedetails[0].Total !== 0
                        ? valuedetails[0].Total.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })
                        : "0.00"
                    }
                    readOnly
                  // startAdornment={<InputAdornment position="start" style={{fontSize:"40px" }}>€</InputAdornment>}
                  />
                </FormControl>
              </div>
            </div>
            {openQtyAlert && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="warning">
                  Ju lutem selektoni artikullin që dëshironi ta editoni
                </Alert>
              </Stack>
            )}
            {
              showPriceError && showPriceError ?
                <Alert severity="error" variant="filled" >
                  Kujdes! Artikulli: {articleName} është duke u shitur pa profit
                </Alert>
                : null
            }
            <div className="POS__Container__LeftSide__ArticleList" >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  border: "solid lightgray 1px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#466F86",
                    color: "white",
                  },
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={[
                    ...columns,
                    {
                      field: "actions",
                      headerName: "Veprimet",
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      width: 200,
                      renderCell: (params) => (
                        <div>
                          <AddCircleOutlineIcon
                            style={{ color: "green", width: "35px", height: "35px" }}
                            type="submit"
                            onClick={() => handleChangeQty(params.row.rowInfo, 1, '+')}
                          />
                          <RemoveCircleOutlineRoundedIcon
                            style={{ color: "orange", width: "35px", height: "35px" }}
                            type="submit"
                            onClick={() => handleChangeQty(params.row.rowInfo, 1, '-')}
                          />
                          <HighlightOffSharpIcon
                            style={{ color: "red", width: "35px", height: "35px" }}
                            type="submit"
                            onClick={() => handleDelete(params.row.itemID)}
                          />
                        </div>
                      ),
                    },
                  ]}
                  pageSize={100}
                  getRowClassName={(params) =>
                    params.row.sasia === 0 ? "zero-balance-row" : "non-zero-balance-row"
                  }
                  initialState={{
                    density: 'compact'
                  }}
                  onRowClick={(params) => {
                    setTempArticle(params.row.rowInfo);
                    setTempQty(params.row.rowInfo.QtyOutput);
                    setTempPrice(params.row.rowInfo.PriceWithVAT);
                    setIsArticleQtySelected(true);
                    setOpenQtyAlert(false);

                    // Mund ta përdorni këtë të dhënë për të kryer veprime të tjera
                  }}
                />
                <style jsx>{`
                .zero-balance-row {
                  background-color: lightgray;
                }
                .non-zero-balance-row {
                  /* Stili për rreshtat kur 'balance' nuk është 0 */
                }
              `}</style>
              </Box>
            </div>
            <div className="POS__Container__LeftSide__Total">
              <div className="POS__Container__LeftSide__Total__Fields">
                <div className="POS__Container__LeftSide__Total__Fields__First">
                  <div className="POS__Container__LeftSide__Total__Fields__First__values">
                    <div >
                      <FormControl
                        fullWidth
                        sx={{ m: 0 }}
                        variant="filled"
                        focused
                      >
                        <InputLabel htmlFor="TotalAmount">
                          Total pa TVSH
                        </InputLabel>
                        <FilledInput
                          id="TotalAmount"
                          value={
                            valuedetails &&
                              valuedetails.length > 0 &&
                              valuedetails[0].Amount18 !== undefined &&
                              valuedetails[0].Amount8 !== undefined &&
                              valuedetails[0].Amount0 !== undefined
                              ? (
                                valuedetails[0].Amount18 +
                                valuedetails[0].Amount8 +
                                valuedetails[0].Amount0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })
                              : "0.00"
                          }
                          readOnly
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                    <div >
                      <FormControl
                        fullWidth
                        sx={{ m: 0 }}
                        variant="filled"
                        focused
                      >
                        <InputLabel htmlFor="VATTotal">Total TVSH</InputLabel>
                        <FilledInput
                          id="VATTotal"
                          value={
                            valuedetails &&
                              valuedetails.length > 0 &&
                              valuedetails[0].Total !== undefined &&
                              valuedetails[0].Amount18 !== undefined &&
                              valuedetails[0].Amount8 !== undefined &&
                              valuedetails[0].Amount0 !== undefined
                              ? (
                                valuedetails[0].Total -
                                (valuedetails[0].Amount18 +
                                  valuedetails[0].Amount8 +
                                  valuedetails[0].Amount0)
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })
                              : "0.00"
                          }
                          readOnly
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                    <div >
                      <FormControl
                        fullWidth
                        sx={{ m: 0 }}
                        variant="filled"
                        focused
                      >
                        <InputLabel htmlFor="VATTotal">Total</InputLabel>
                        <FilledInput
                          id="Total"
                          value={totaliFatures > 0
                            ? totaliFatures.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })
                            : "0.00"
                          }
                          readOnly
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="POS__Container__RightSide">
            <div className="POS__Container__RightSide__Top">
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ padding: "5px" }}
                >
                  {articleGroup &&
                    articleGroup.map((gr, index) => (
                      <Grid item xs={3} index={index}>
                        <Item
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            height: "60px",
                            alignContent: "center",
                          }}
                          type="submit"
                          onClick={() => fetchArticleByGroup(gr.GroupID)}
                        >
                          {gr.GroupName}
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </div>
            <div className="POS__Container__RightSide__Middle">
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ padding: "5px" }}
                >
                  {articleByGroup &&
                    articleByGroup.map((gr, index) => (
                      <Grid item xs={3} index={index}>
                        <Item
                          style={{
                            backgroundColor: "lightgreen",
                            color: "black",
                            height: "70px",
                            alignContent: "center",
                          }}
                          type="submit"
                          onClick={() => insertArticlerOnClick(gr.ArticleID)}
                        >
                          {gr.ArticleName}
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </div>
            <div className="POS__Container__RightSide__Bottom">
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ padding: "5px" }}
                >
                  <Grid item xs={4}>
                    <Item
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        height: "100px",
                        alignContent: "center",
                        fontSize: "25px",
                      }}
                      type="submit"
                      // onClick={() => { setOpenCashModal(true); setPaymentOptions(tempPaymentOption) }}
                      onClick={() => { setPaymentOptions(tempPaymentOption); openModalAndSelectText(); }}

                    >
                      Paguaj me Cash <EuroIcon />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        height: "100px",
                        alignContent: "center",
                        fontSize: "25px",
                      }}
                      type="submit"
                      onClick={() => setOpenBankModal(true)}
                    >
                      Paguaj me bankë <PaymentIcon />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        height: "100px",
                        alignContent: "center",
                        fontSize: "25px",
                      }}
                      type="submit"
                      onClick={() => {
                        (paymentOptions != 0 || totaliFatures != 0) ? setShowClosePosAlert(true) : navigateToPos()
                      }}
                    >
                      Mbyll <CancelIcon />
                    </Item>
                  </Grid>
                  {/* <Grid item xs={3}>
                                    <Item style={{ backgroundColor: "orange", color: "black", height: "70px", alignContent: "center", fontSize: "25px" }}>50€</Item>
                                </Grid> */}
                </Grid>
              </Box>
              {
                showClosePosAlert ?
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant="filled" severity="error">
                      Nuk mund ta mbyllni POS-in sepse nuk është paguar ende fatura
                    </Alert>
                  </Stack>
                  :
                  <></>
              }
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="POS__Container__Footer">
          <p>F1: Kërko me barkod</p>
          <p>F2: Kërko në listë</p>
          <p>F4: Cmimi</p>
          <p>F5: Sasia</p>
          <p>F6: Hap Arkën</p>
          <p>F7: Hap Bankën</p>
          <p>F10: X Raport</p>
          <p>F11: Z Raport</p>
          <p>F12: Anulo faturën</p>
        </div>
      </div >
    </>
    // <div className="POS__Container">
    //     <Modal show={showDeleteTransactionID} onHide={() => setShowDeleteTransactionID(false)} style={{ marginTop: "150px" }}>
    //         <Modal.Header>
    //             <Modal.Title>Confirmation</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //             <p>Are you sure you want to delete this invoice?</p>
    //         </Modal.Body>
    //         <Modal.Footer>
    //             <Button variant="secondary" onClick={() => setShowDeleteTransactionID(false)}>
    //                 No
    //             </Button>
    //             <Button variant="danger" onClick={handleDeleteTransactionID}>
    //                 Yes
    //             </Button>
    //         </Modal.Footer>
    //     </Modal>

    //     <Modal show={showEmailConfirmation} onHide={() => setShowEmailConfirmation(false)} style={{ marginTop: "150px" }}>

    //         {/* <ProgressBar show={sendingEmail} /> */}
    //         <Modal.Header>
    //             <Modal.Title>Confirmation</Modal.Title>
    //         </Modal.Header>
    //         {
    //             sendingEmail ?
    //                 <Modal.Body>
    //                     <Box sx={{ display: 'flex', justifyContent: "center" }}>
    //                         <CircularProgress />
    //                     </Box>
    //                 </Modal.Body>
    //                 :
    //                 <>
    //                     <Modal.Body>
    //                         <p>A dëshironi ta dergoni faturën në këtë Email:</p>
    //                         <TextField
    //                             required
    //                             style={{ width: "100%" }}
    //                             id="clientEmail"
    //                             label="Email-i i klientit"
    //                             variant="outlined"
    //                             type="text"
    //                             name='clientEmail'
    //                             value={clientEmail}
    //                             onChange={event => setClientEmail(event.target.value)}
    //                             autoComplete="off"
    //                         />
    //                     </Modal.Body>
    //                     <Modal.Footer>

    //                         <Button variant="secondary" onClick={() => setShowEmailConfirmation(false)}>
    //                             Anulo
    //                         </Button>
    //                         <Button variant="danger" onClick={sendEmail}>
    //                             Dergo
    //                         </Button>
    //                     </Modal.Footer>
    //                 </>
    //         }
    //     </Modal>
    //     <br></br>
    //     <div className="header_flex">
    //         <div className="header_icon">
    //             <FaFileInvoice style={{}} className="header_icons" />
    //             <h3>Shitje e re në detale</h3>
    //         </div>
    //         <div>
    //         </div>
    //     </div>
    //     <div className="header_paragraph">
    //         {/* <p>Please note that all dates are automatically saved in our secure database for your convenience.</p> */}
    //         <p>Ju lutemi vini re se të gjitha te dhenat ruhen automatikisht në bazën e të dhënave për të pasur siguri në cdo hap!</p>
    //     </div>
    //     <hr></hr>
    //     <div className="SalesDetail__Container__AllButtons">
    //         <div style={{ display: "flex", }}>
    //             <div>
    //                 <InsertClient fetchData={fetchClients} type="insert" />
    //             </div>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <InsertArticle fetchData={fetchArticles} type="insert" />
    //             </div>
    //         </div>
    //         <div style={{ display: "flex" }}>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <Link to="#"
    //                     style={{ width: "110px", textDecoration: "none" }}
    //                 >
    //                     <button className="button-15" variant="contained" onClick={() => { newInvoice(); }}>
    //                         Ruaj & i ri
    //                     </button>
    //                 </Link>
    //             </div>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <Link to="#"
    //                     style={{ width: "110px", textDecoration: "none" }}
    //                 >
    //                     <button className="button-15" variant="contained" onClick={() => { setShowEmailConfirmation(true); }}>
    //                         <FaEnvelope /> Email
    //                     </button>
    //                 </Link>
    //             </div>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <PrintContent details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyOutput" actiontype="true" link={link} />
    //             </div>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <PDFGenerator details={headerData} companyDetails={companiesData} articleDetails={articlePrintDetails} bankaccounts={bankAccounts} sasia="QtyOutput" actiontype="true" link={link} />
    //                 {/* <PDFGenerator data={data} /> */}

    //             </div>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <Link to="#"
    //                     style={{ width: "110px", textDecoration: "none" }}
    //                 >
    //                     <button className="button-15" variant="contained">
    //                         <FaTicketAlt /> Fiscal
    //                     </button>
    //                 </Link>
    //             </div>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <Link to="#"
    //                     style={{ width: "110px", textDecoration: "none" }}
    //                 >
    //                     <button className="button-15" variant="contained"
    //                         onClick={() => {
    //                             setShowDeleteTransactionID(true);
    //                         }}
    //                     >
    //                         <FaTrash /> Fshij
    //                     </button>
    //                 </Link>
    //             </div>
    //             <div style={{ marginLeft: "5px" }}>
    //                 <Link
    //                     to="/Sales/salesindetails"
    //                     style={{ width: "110px", textDecoration: "none" }}
    //                 >
    //                     <button className="button-15" variant="contained">
    //                         <FaTimes /> Mbyll
    //                     </button>
    //                 </Link>
    //             </div>
    //         </div>
    //     </div>
    //     <div className='header_paper2' style={{ display: "flex", justifyContent: "flex-end" }}></div>
    //     <Paper>
    //         <form onSubmit={handleSubmit} className='header_paper'>
    //             <Box
    //                 // component="form"
    //                 sx={{
    //                     "& .MuiTextField-root": { m: 1.3, width: handleTextFieldWidth },
    //                 }}
    //                 noValidate
    //                 autoComplete="off"
    //             >
    //                 {/* <p>Please fill in these fields first to proceed to the fields below</p> */}
    //                 <div className='SalesDetail__Container__Form'>
    //                     <div className="SalesDetail__Container__Form__div">
    //                         <div className="SalesDetail__Container__Form__div__1" style={{ marginTop: "13px" }}>
    //                             <div className="SalesDetail__Container__Form__div__2">
    //                                 <TextField
    //                                     className="SalesDetail__Container__Form__TextField__1"
    //                                     required
    //                                     id="invoiceNR"
    //                                     label="Numri Fatures"
    //                                     variant="outlined"
    //                                     type="text"
    //                                     name='InvoiceNr'
    //                                     value={"POS-" + currentYear + "-" + invoiceNR}
    //                                     onChange={event => setInvoiceNR(event.target.value)}
    //                                     disabled={form1IsDisabled}
    //                                     autoComplete="off"
    //                                 />

    //                                 <TextField
    //                                     className="SalesDetail__Container__Form__TextField__1"
    //                                     style={{ marginTop: "22px" }}
    //                                     id="id"
    //                                     select
    //                                     label="Tipi Transaksionit"
    //                                     defaultValue=""
    //                                     value={transactionsType}
    //                                     onChange={event => { setTransactionType(event.target.value); fetchLastInvoiceNr(); }}
    //                                     required
    //                                     disabled={form1IsDisabled}

    //                                 >
    //                                     {TranscationData.map((data) => (
    //                                         <MenuItem key={data.id} value={data.id}>{data.type}
    //                                         </MenuItem>
    //                                     ))}
    //                                 </TextField>
    //                                 <TextareaAutosize style={{ height: "70px", marginLeft: "10px" }}
    //                                     className="SalesDetail__Container__Form__TextField__1"
    //                                     id="Comment"
    //                                     label="Komenti"
    //                                     type="text"
    //                                     name='Comment'
    //                                     value={coment}
    //                                     onChange={event => setComent(event.target.value)}
    //                                     variant="outlined"
    //                                     disabled={form1IsDisabled}
    //                                     autoComplete="off"

    //                                 />
    //                             </div>
    //                             <div className="SalesDetail__Container__Form__TextField__date__Payment">
    //                                 <TextField
    //                                     className="SalesDetail__Container__Form__TextField__date"
    //                                     id="date"
    //                                     type="date"
    //                                     variant="outlined"
    //                                     name='Date'
    //                                     label="Data Faturës"
    //                                     value={date}
    //                                     onChange={(e) => { setDate(e.target.value); setPayDate(e.target.value); fetchLastInvoiceNr(); }}
    //                                     disabled={form1IsDisabled}
    //                                     InputLabelProps={{
    //                                         shrink: true,
    //                                     }}
    //                                 />
    //                                 <TextField
    //                                     className="SalesDetail__Container__Form__TextField__Payment"
    //                                     style={{ marginTop: "22px" }}
    //                                     id="PaymentType"
    //                                     select
    //                                     label="Përzgjedh tipin e arkëtimit"
    //                                     value={paymentOptions}
    //                                     onChange={event => {
    //                                         setPaymentOptions(event.target.value);
    //                                         calculateAndSetPaymentDate(event.target.value);
    //                                         fetchLastInvoiceNr();
    //                                     }}
    //                                 >
    //                                     <MenuItem key="0" value="0">Pagesa Behet më vonë</MenuItem>
    //                                     {cashRegister.map((data) => (
    //                                         <MenuItem key={data.AccountID} value={data.AccountID}>
    //                                             {data.AccountName}
    //                                         </MenuItem>
    //                                     ))}
    //                                 </TextField>
    //                                 {
    //                                     paymentOptions != 0 ? (
    //                                         <TextField
    //                                             className="SalesDetail__Container__Form__TextField__date"
    //                                             id="PayDate"
    //                                             type="date"
    //                                             variant="outlined"
    //                                             label="Data Arkëtimit"
    //                                             name='PayDate'
    //                                             value={payDate}
    //                                             // onChange={(e) => setDate(e.target.value)}
    //                                             onChange={event => { setPayDate(event.target.value); fetchLastInvoiceNr(); }}
    //                                             disabled={form1IsDisabled}
    //                                             InputLabelProps={{
    //                                                 shrink: true,
    //                                             }}
    //                                         />
    //                                     )
    //                                         :
    //                                         <TextField
    //                                             className="SalesDetail__Container__Form__TextField__date"
    //                                             id="PayDate"
    //                                             type="date"
    //                                             variant="outlined"
    //                                             label="Afati i pagesës"
    //                                             name='PayDate'
    //                                             value={payDate}
    //                                             // onChange={(e) => setDate(e.target.value)}
    //                                             onChange={event => { setPayDate(event.target.value); fetchLastInvoiceNr(); }}
    //                                             disabled={form1IsDisabled}
    //                                             InputLabelProps={{
    //                                                 shrink: true,
    //                                             }}
    //                                         />
    //                                 }
    //                             </div>
    //                         </div>

    //                     </div>
    //                     <div className="SalesDetail__Container__Form__div__4">
    //                         <div >
    //                             <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh Klientin</span>
    //                             <Select
    //                                 inputId="AccountID"
    //                                 className="SalesDetail__Container__Form__TextField__selectList"
    //                                 value={{ label: clientName, value: supplierID }}
    //                                 options={filteredClients.map((data) => ({
    //                                     label: data.AccountName,
    //                                     value: data.AccountID,
    //                                     AccountID: data.AccountID
    //                                 }))}
    //                                 onInputChange={handleInputChange}
    //                                 onChange={handleFilterClient}
    //                                 isClearable
    //                                 placeholder="Select"
    //                                 noOptionsMessage={() => 'No matching'}
    //                                 components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    //                                 styles={{
    //                                     control: (provided) => ({
    //                                         ...provided,
    //                                         height: "55px",
    //                                         marginLeft: "10px", marginRight: "10px"

    //                                     }),
    //                                     menu: (provided) => ({
    //                                         ...provided,
    //                                         backgroundColor: "#fff",
    //                                         zIndex: 9999,
    //                                         boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    //                                         borderRadius: "4px",
    //                                     }),
    //                                     menuList: (provided) => ({
    //                                         ...provided,
    //                                         maxHeight: "200px",
    //                                         overflowY: "auto",
    //                                     }),
    //                                     singleValue: (provided) => ({
    //                                         ...provided,
    //                                         color: "#000",
    //                                     }),
    //                                 }}
    //                             />
    //                         </div>
    //                         <div style={{ display: "flex", paddingTop: "23px" }}>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__IDNumber"
    //                                 id="outlined-basic"
    //                                 label="Numri Identifikues"
    //                                 variant="outlined"
    //                                 name="IdentificationNumber"
    //                                 value={clientsDetails.length > 0 ? clientsDetails[0].IdentificationNumber : ''}
    //                                 InputLabelProps={{
    //                                     shrink: true,
    //                                 }}
    //                                 readOnly
    //                                 autoComplete="off"
    //                             />
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__IDNumber"
    //                                 id="outlined-basic"
    //                                 label="Numri i TVSH-së"
    //                                 variant="outlined"
    //                                 name="VatNumber"
    //                                 value={clientsDetails.length > 0 ? clientsDetails[0].VATNumber : ''}
    //                                 InputLabelProps={{
    //                                     shrink: true,
    //                                 }}
    //                                 readOnly
    //                                 autoComplete="off"
    //                             />
    //                         </div>
    //                         <div>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__2"
    //                                 id="outlined-basic"
    //                                 label="Adresa"
    //                                 variant="outlined"
    //                                 name="Address"
    //                                 value={clientsDetails.length > 0 ? clientsDetails[0].Adress : ''}
    //                                 InputLabelProps={{
    //                                     shrink: true,
    //                                 }}
    //                                 readOnly
    //                                 autoComplete="off"
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </Box>
    //         </form>
    //         {openDetails ?
    //             <div className="SalesDetail__Container">
    //                 <div style={{ backgroundColor: "gainsboro " }}>
    //                     <hr></hr>
    //                     {showInserForm && (
    //                         <form onSubmit={handleNewSubmit} className='header_paper' onKeyPress={handleKeyPress}>
    //                             <Box
    //                                 // component="form"
    //                                 sx={{
    //                                     "& .MuiTextField-root": { m: 1.3, width: handleTextFieldWidth },
    //                                 }}
    //                                 noValidate
    //                                 autoComplete="off"
    //                             >
    //                                 <div className="SalesDetail__Container__Second__Form">
    //                                     <div className="SalesDetail__Container__Second__Form__Article">
    //                                         <span style={{ marginBottom: "-10px", color: "black", marginLeft: "10px", fontSize: "13px" }}>Përzgjedh artikullin</span>
    //                                         <Select
    //                                             className="SalesDetail__Container__Form__TextField__Article__SelectList"
    //                                             inputId="ArticleID"
    //                                             value={{ label: articlesName, value: articleID }}
    //                                             options={filteredArticles.map((data) => ({
    //                                                 label: data.ArticleName,
    //                                                 value: data.ArticleID,
    //                                                 ArticleID: data.ArticleID
    //                                             }))}
    //                                             onInputChange={handleInputChange}
    //                                             onChange={handleFilterArticles}
    //                                             isClearable
    //                                             placeholder="Select"
    //                                             noOptionsMessage={() => 'No matching'}
    //                                             components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    //                                             styles={{
    //                                                 control: (provided) => ({
    //                                                     ...provided,
    //                                                     height: "55px",
    //                                                     marginLeft: "10px",
    //                                                     marginRight: "12px",
    //                                                     backgroundColor: "transparent",
    //                                                     border: "darkgray solid 1px"
    //                                                 }),
    //                                                 menu: (provided) => ({
    //                                                     ...provided,
    //                                                     backgroundColor: "#fff",
    //                                                     zIndex: 9999,
    //                                                     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    //                                                     borderRadius: "4px",
    //                                                 }),
    //                                                 menuList: (provided) => ({
    //                                                     ...provided,
    //                                                     maxHeight: "200px",
    //                                                     overflowY: "auto",
    //                                                 }),
    //                                                 singleValue: (provided) => ({
    //                                                     ...provided,
    //                                                     color: "#000",
    //                                                 }),
    //                                             }}
    //                                         />
    //                                     </div>
    //                                     <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
    //                                         <div className="SalesDetail__Container__Second__Form__details">
    //                                             <TextField
    //                                                 className="SalesDetail__Container__Form__TextField__Output"
    //                                                 id="QtyOutput"
    //                                                 type="text"
    //                                                 variant="outlined"
    //                                                 label="Sasia"
    //                                                 name='QtyOutput'
    //                                                 value={typeof qtyOutput === 'number' ? qtyOutput.toFixed(4) : qtyOutput}
    //                                                 onChange={(e) => setQtyOutput(e.target.value)}
    //                                                 onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
    //                                                 disabled={form2IsDisabled}
    //                                             />
    //                                         </div>
    //                                         <div className="SalesDetail__Container__Second__Form__details">
    //                                             <TextField
    //                                                 className="SalesDetail__Container__Form__TextField__Output"
    //                                                 id="DiscountPercent"
    //                                                 type="text"
    //                                                 variant="outlined"
    //                                                 label="Rabati %"
    //                                                 name='DiscountPercent'
    //                                                 value={typeof discountPercent === 'number' ? discountPercent.toFixed(2) : discountPercent}
    //                                                 onChange={(e) => setDiscountPercent(e.target.value)}
    //                                                 onKeyUp={handleCalculatePriceWithRabat}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
    //                                                 disabled={form2IsDisabled}
    //                                             />
    //                                         </div>
    //                                         <div className="SalesDetail__Container__Second__Form__details">
    //                                             <TextField
    //                                                 className="SalesDetail__Container__Form__TextField__Output"
    //                                                 id="PriceWithoutVAT"
    //                                                 type="text"
    //                                                 variant="outlined"
    //                                                 label="Çmimi pa TVSH"
    //                                                 name="PriceWithoutVAT"
    //                                                 value={typeof priceWithoutVAT === 'number' ? priceWithoutVAT.toFixed(4) : priceWithoutVAT}
    //                                                 onChange={(e) => setPriceWithoutVAT(e.target.value)}
    //                                                 onKeyUp={handleCalculatePriceWithVAT}
    //                                                 disabled={form2IsDisabled}
    //                                                 InputLabelProps={{
    //                                                     shrink: true,
    //                                                 }}
    //                                             />
    //                                         </div>
    //                                         <div className="SalesDetail__Container__Second__Form__details">
    //                                             <TextField
    //                                                 className="SalesDetail__Container__Form__TextField__Output"
    //                                                 id="PriceWithVAT"
    //                                                 type="text"
    //                                                 variant="outlined"
    //                                                 label="Çmimi me TVSH"
    //                                                 name="PriceWithVAT"
    //                                                 value={typeof priceWithVAT === 'number' ? priceWithVAT.toFixed(4) : priceWithVAT}
    //                                                 onKeyUp={handleCalculatePriceWithoutVAT}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
    //                                                 onChange={(e) => setPriceWithVAT(e.target.value)}
    //                                                 disabled={form2IsDisabled}
    //                                                 InputLabelProps={{
    //                                                     shrink: true,
    //                                                 }}
    //                                             />
    //                                         </div>
    //                                         <div className='SalesDetail__Container__Second__Form__details'>
    //                                             <TextField
    //                                                 className="SalesDetail__Container__Form__TextField__Output"
    //                                                 id="Total"
    //                                                 label="Vlera me TVSH"
    //                                                 variant="outlined"
    //                                                 name='Total'
    //                                                 value={typeof total === 'number' ? total.toFixed(2) : total}
    //                                                 onChange={event => setTotal(event.target.value)}
    //                                                 onKeyUp={handleCalculatePrices}
    //                                                 InputLabelProps={{
    //                                                     shrink: true,
    //                                                 }}
    //                                                 disabled={form2IsDisabled}
    //                                             />
    //                                         </div>
    //                                         <div className="butonat">
    //                                             <div>
    //                                                 <Button type="submit" variant="success" disabled={form2IsDisabled} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                     <i class="bi bi-plus-lg"></i>
    //                                                 </Button>
    //                                             </div>
    //                                             <div>
    //                                                 <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                     <i class="bi bi-x-lg"></i>
    //                                                 </Button>
    //                                             </div>
    //                                         </div>
    //                                         {/* <div className="butonat">
    //                                             <button onClick={clearLine}
    //                                                 className="btn btn-primary float-end"
    //                                             >Clear</button>
    //                                         </div> */}

    //                                         <div className="responsive">
    //                                             <div className="responsive__butonat">
    //                                                 <div>
    //                                                     <Button type="submit" variant="success" disabled={form2IsDisabled} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                         <i class="bi bi-plus-lg"></i>
    //                                                     </Button>
    //                                                 </div>
    //                                                 <div>
    //                                                     <Button variant="danger" onClick={clearLine} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                         <i class="bi bi-x-lg"></i>
    //                                                     </Button>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </Box>
    //                         </form>
    //                     )}
    //                     {showEditForm && (
    //                         <div className="SalesDetail__Container">
    //                             <form onSubmit={handleSubmitDetailLine} className='header_paper' onKeyPress={handleKeyPress}>
    //                                 <Box
    //                                     // component="form"
    //                                     sx={{
    //                                         '& .MuiTextField-root': { m: 1.3, width: '41ch' },
    //                                     }}
    //                                     noValidate
    //                                     autoComplete="off"
    //                                 >
    //                                     {/* <p style={{ color: "red", fontSize: "16px", fontStyle: "italic", borderBottom: "1px dashed red" }}>Click on a line to edit the data</p> */}
    //                                     <div className="SalesDetail__Container__Second__Form">
    //                                         <div className="SalesDetail__Container__Second__Form__Article" style={{ paddingTop: "12px" }}>
    //                                             <TextField
    //                                                 className="SalesDetail__Container__Form__TextField__Article"
    //                                                 id="ArticleID"
    //                                                 select
    //                                                 label="Select Article"
    //                                                 name="ArticleID"
    //                                                 value={formDetailData.ArticleID}
    //                                                 autoComplete="off"
    //                                                 // onChange={handleInputChangeDetail}
    //                                                 onChange={(event) => {
    //                                                     handleInputChangeDetail(event); // Ndryshimi i selectedArticleID në ngjarjen onChange
    //                                                     fetchArticlesSalesPrice(event.target.value, "edit"); // Thirja e funksionit këtu
    //                                                 }}
    //                                             // onKeyUp={handleCalculatePriceWithoutVATEdit}
    //                                             >
    //                                                 {articles.map((data) => (
    //                                                     <MenuItem key={data.ArticleID} value={data.ArticleID}>{data.ArticleName}
    //                                                     </MenuItem>
    //                                                 ))}
    //                                             </TextField>
    //                                         </div>
    //                                         <div className="SalesDetail__Container__Second__Form__details__OtherInputs">
    //                                             <div className="SalesDetail__Container__Second__Form__details">
    //                                                 <TextField
    //                                                     className="SalesDetail__Container__Form__TextField__Output"
    //                                                     type="text"
    //                                                     id="QtyOutput"
    //                                                     name="QtyOutput"
    //                                                     label="Qty Output"
    //                                                     variant="outlined"
    //                                                     autoComplete="off"
    //                                                     value={formDetailData.QtyOutput}
    //                                                     onChange={handleInputChangeDetail}
    //                                                     onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
    //                                                 />
    //                                             </div>
    //                                             <div className="SalesDetail__Container__Second__Form__details">
    //                                                 <TextField
    //                                                     className="SalesDetail__Container__Form__TextField__Output"
    //                                                     id="DiscountPercent"
    //                                                     type="text"
    //                                                     variant="outlined"
    //                                                     label="Rabati %"
    //                                                     name='DiscountPercent'
    //                                                     value={typeof formDetailData.DiscountPercent === 'number' ? (formDetailData.DiscountPercent).toFixed(2) : formDetailData.DiscountPercent}
    //                                                     onChange={handleInputChangeDetail}
    //                                                     onKeyUp={() => handleCalculatePriceWithRabat("edit")}  // Shtoni këtë për të shkaktuar një funksion pas humbjes së fokusit nga kjo fushë
    //                                                 />
    //                                             </div>
    //                                             <div className="SalesDetail__Container__Second__Form__details">
    //                                                 <TextField
    //                                                     className="SalesDetail__Container__Form__TextField__Output"
    //                                                     id="PriceWithoutVAT"
    //                                                     type="text"
    //                                                     variant="outlined"
    //                                                     label="Price Without VAT"
    //                                                     name="PriceWithoutVAT"
    //                                                     autoComplete="off"
    //                                                     value={typeof formDetailData.PriceWithoutVAT === 'number' ? formDetailData.PriceWithoutVAT.toFixed(4) : formDetailData.PriceWithoutVAT}
    //                                                     onChange={handleInputChangeDetail}
    //                                                     onKeyUp={() => handleCalculatePriceWithVAT("edit")}
    //                                                 // disabled={form2IsDisabled}
    //                                                 />
    //                                             </div>
    //                                             <div className="SalesDetail__Container__Second__Form__details">
    //                                                 <TextField
    //                                                     className="SalesDetail__Container__Form__TextField__Output"
    //                                                     id="PriceWithVAT"
    //                                                     type="text"
    //                                                     variant="outlined"
    //                                                     label="Price With VAT"
    //                                                     name="PriceWithVAT"
    //                                                     autoComplete="off"
    //                                                     value={typeof formDetailData.PriceWithVAT === 'number' ? formDetailData.PriceWithVAT.toFixed(4) : formDetailData.PriceWithVAT}
    //                                                     onChange={handleInputChangeDetail}
    //                                                     onKeyUp={() => handleCalculatePriceWithoutVAT("edit")}
    //                                                 />
    //                                             </div>
    //                                             <div className='SalesDetail__Container__Second__Form__details'>
    //                                                 <TextField
    //                                                     className="SalesDetail__Container__Form__TextField__Output"
    //                                                     id="Total"
    //                                                     label="Total"
    //                                                     variant="outlined"
    //                                                     name="Total"
    //                                                     value={typeof formDetailData.Total === 'number' ? formDetailData.Total.toFixed(2) : formDetailData.Total}
    //                                                     onChange={handleInputChangeDetail}
    //                                                     onKeyUp={() => handleCalculatePrices("edit")}
    //                                                     InputLabelProps={{
    //                                                         shrink: true,
    //                                                     }}
    //                                                 />
    //                                             </div>

    //                                             <div className="butonat">
    //                                                 <div>
    //                                                     <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                         <i class="bi bi-check-lg"></i>
    //                                                     </Button>
    //                                                 </div>
    //                                                 <div>
    //                                                     <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                         <i class="bi bi-x-lg"></i>
    //                                                     </Button>
    //                                                 </div>
    //                                             </div>
    //                                             <div className="responsive">
    //                                                 <div className="responsive__butonat">
    //                                                     <div>
    //                                                         <Button type="submit" variant="success" style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                             <i class="bi bi-check-lg"></i>
    //                                                         </Button>
    //                                                     </div>
    //                                                     <div>
    //                                                         <Button variant="danger" onClick={clearEdit} style={{ borderRadius: "0", width: "45px", height: "45px" }}>
    //                                                             <i class="bi bi-x-lg"></i>
    //                                                         </Button>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </Box>
    //                             </form>
    //                         </div>
    //                     )}

    //                     <DataGridWithActions
    //                         columns={detailsData}
    //                         rows={rows}
    //                         handleEdit={handleEditDetails}
    //                         handleDelete={handleDelete}
    //                         showPayment={false}

    //                     />
    //                 </div>
    //                 <br></br>
    //                 <div className="responsive__Tablet">
    //                     <div className='SalesDetail__Container__Form'>
    //                         <div >
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__Amount18"
    //                                 // style={{ width: "100%" }}
    //                                 id="Amount18"
    //                                 label="Vlera 18%"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='Amount18'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].Amount18).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                             <br></br>
    //                             <br></br>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__VAT18"
    //                                 // style={{ width: "100%" }}
    //                                 id="VAT18"
    //                                 label="TVSH 18%"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='VAT18'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].VAT18).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                         </div>
    //                         <br></br>
    //                         <div>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__Amount8"
    //                                 // style={{ width: "100%" }}
    //                                 id="Amount8"
    //                                 label="Vlera 8%"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='Amount8'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].Amount8).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                             <br></br>
    //                             <br></br>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__VAT8"
    //                                 // style={{ width: "100%" }}
    //                                 id="VAT8"
    //                                 label="TVSH 8%"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='VAT8'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].VAT8).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                         </div>
    //                         <br></br>
    //                         <div>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__Amount0"
    //                                 // style={{ width: "100%" }}
    //                                 id="Amount0"
    //                                 label="Vlera 0%"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='Amount0'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].Amount0).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                             <br></br>
    //                             <br></br>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__VAT0"
    //                                 // style={{ width: "100%" }}
    //                                 id="VAT0"
    //                                 label="Vlera para zbritjes"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='VAT0'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].DiscountAmount + (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                         </div>
    //                         <br></br>
    //                         <div >
    //                             <TextField
    //                                 style={{ width: "81%" }}
    //                                 id="DiscountAmount"
    //                                 label="Zbritja në vlerë"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name="DiscountAmount"
    //                                 value={discountAmount}
    //                                 onChange={(e) => { setDiscountAmount(e.target.value); calculatePercent(e.target.value); }}
    //                                 InputProps={{
    //                                     endAdornment: (
    //                                         <InputAdornment position="end">
    //                                             <Tooltip title="Apliko Zbritjen" placement="right">
    //                                                 <IconButton type="submit"
    //                                                     onClick={constUpdateDiscountAmount}
    //                                                 >
    //                                                     <i class="bi bi-check-lg" style={{ color: "green" }}></i>

    //                                                 </IconButton>
    //                                             </Tooltip>
    //                                         </InputAdornment>
    //                                     ),
    //                                 }}
    //                             />
    //                             <br></br>
    //                             <br></br>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__TotalAmount"
    //                                 id="TotalAmount"
    //                                 label="Total pa TVSH"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='TotalAmount'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                         </div>
    //                         <br></br>
    //                         <div >
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__VATTotal"
    //                                 // style={{ width: "100%" }}
    //                                 id="VATTotal"
    //                                 label="Total TVSH"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='VATTotal'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].Total - (valuedetails[0].Amount18 + valuedetails[0].Amount8 + valuedetails[0].Amount0)).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                             <br></br>
    //                             <br></br>
    //                             <TextField
    //                                 className="SalesDetail__Container__Form__TextField__Total"
    //                                 // style={{ width: "100%" }}
    //                                 id="Totali"
    //                                 label="Për pagesë"
    //                                 variant="outlined"
    //                                 type="text"
    //                                 name='Totali'
    //                                 value={valuedetails.length > 0 ? (valuedetails[0].Total).toFixed(2) : ''}
    //                                 readOnly
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             : <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
    //                 <button className="button-15" variant="contained"
    //                     type="submit"
    //                     style={{
    //                         background: "#466F86",
    //                         width: "95%",
    //                         height: "55px",
    //                         alignContent: "center",
    //                         transition: "background-color 0.3s ease",
    //                         boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
    //                         color: "white",
    //                         border: "none"
    //                     }}
    //                     disabled={form1IsDisabled}
    //                     onClick={handleSubmit}
    //                     onMouseEnter={(e) => {
    //                         e.target.style.backgroundColor = "#6c7f8f";
    //                     }}
    //                     onMouseLeave={(e) => {
    //                         e.target.style.backgroundColor = "#7a95a6";
    //                     }}
    //                 >
    //                     <FaTable /> Hap detalet e faturës
    //                 </button>
    //             </div>
    //         }
    //         <br></br>
    //     </Paper >
    // </div >
  );
}
export default AddPos;
