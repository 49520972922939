import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/__button.scss'
import '../../Style/InsertClient.scss';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { TextareaAutosize } from '@mui/material';
import { Button } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';

function InsertExpences(props) {
    const headers = authHeader();
    const email = LoginUserDetailed();

    const [AccName, setAccName] = useState("");
    const [ledgerID, setLedgerID] = useState("");
    const [comment, setComment] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [ledger, setLedger] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const stateDate = {
            AccountName: AccName,
            LedgerID: ledgerID,
            Comment: comment,
            CreatedByUser: email,
            IsActive: isActive
        };
        axios.post(ApiLink.API_URL + 'Accounts/createExpence', stateDate, { headers })
            .then((response) => {
                handleSuccess("Account Added Successfully");
                setAccName("");
                setLedgerID("");
                setComment("");
                setIsActive(true);
                props.onHide();
                props.refreshData();
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const handleClose = () => {
        // Ky funksion do të përdoret për të mbyllur modalin
        props.onHide();
        setAccName("");
        setLedgerID("");
        setComment("");
        setIsActive(true);
    };

    useEffect(() => {
        axios.get(ApiLink.API_URL + 'ledger/class', { headers })
            .then((response) => {
                setLedger(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Krijo Klasë të re
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} className="InsertClient__Container">
                    <div className='InsertClient__Container__SecondInputs'>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                required
                                id="AccountName"
                                label="Emertimi i Klases"
                                variant="outlined"
                                type="text"
                                name='AccountName'
                                value={AccName}
                                onChange={event => setAccName(event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                required
                                id="LedgerID"
                                select
                                label="Përzgjedh llogarinë Kontabel"
                                defaultValue=""
                                // helperText="Please select expense"
                                value={ledgerID}
                                onChange={event => setLedgerID(event.target.value)}>
                                {ledger.map((led) => (
                                    <MenuItem key={led.LedgerID} value={led.LedgerID}>{led.LedgerNameEN}</MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__firstInput' >
                        <TextareaAutosize style={{ height: "100px" }}
                            className="InsertClient__Container__firstInput__AccName"
                            id="Comment"
                            label="Comment"
                            variant="outlined"
                            placeholder='Komenti'
                            type="text"
                            name='Comment'
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                            autoComplete="off"
                        />
                    </div>
                    <div className='InsertClient__Container__butonat'>
                        <div></div>
                        {/* <label className='InsertModal__Container__Form__Container2__checkbox-label'>
                            <input
                                type="checkbox"
                                checked={isActive}
                                onChange={(event) => setIsActive(event.target.checked)}
                                className='InsertModal__Container__Form__Container2__checkbox-input'
                            />
                            <span className='InsertModal__Container__Form__Container2__checkbox-custom'></span>
                            Active
                        </label> */}
                        <div className='InsertClient__Container__butonat__btn'>
                            <div className='InsertModal__Container__Form__Container2__save-button'>
                                <button
                                    type="submit"
                                    className="btn btn-success float-end"
                                    style={{ width: "120px" }}
                                >
                                    Ruaj & Mbyll
                                </button>
                            </div>
                            <Button variant="secondary" onClick={handleClose} style={{ width: "120px" }}>
                                Mbyll
                            </Button>
                        </div>

                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };

    return (
        <>
            {
                props.type === "text" ? (
                    <button className="button-68" onClick={() => setModalShow(true)}>
                        <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} /> Krijo Klasë të Shpënzimeve
                    </button>
                ) : props.type === "insert" ? (
                    <button className="button-15" variant="contained" onClick={() => setModalShow(true)}><i class="bi bi-subtract"></i>  Krijo Klasë të Shpënzimeve</button>
                ) : (
                    <AddBoxIcon style={{ height: "30px", color: "black" }} onClick={() => setModalShow(true)} />
                )
            }

            <InsertExpences
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}
