import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import TableActions from './TableActions';
import Chip from '@mui/material/Chip';
import {
  GridToolbar,
} from '@mui/x-data-grid-premium';

import { Modal, Button, Typography } from '@mui/material';
function DataGridWithActions({ columns, rows, handleEdit, handleDelete, showPayment, height }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleDeleteClick = (id) => {
    setDeleteItemId(id); // Vendos id e rreshtit që duhet të fshihet
    setShowDeleteConfirmation(true); // Hap modalin për konfirmim
  };

  const gridColumns = [];

  if (showPayment) {
    gridColumns.push({
      field: 'payments',
      headerName: 'Pagesa',
      renderCell: (params) => (
        <>
          {
            params.row.rowInfo.PaymentOptions === 0 ? (<Chip label="Pa Paguar" color="error" />) : (<Chip label="Paguar" color="success" />)
          }
        </>
      ),
      headerClassName: 'actions-header',
    });
  }

  gridColumns.push({
    field: 'actions',
    headerName: 'Veprimet',
    renderCell: (params) => (
      <TableActions
        back
        rowData={params.row.rowInfo}
        handleEdit={handleEdit}
        handleDelete={() => handleDeleteClick(params.row.deleteItemId)}
      />
    ),
    headerClassName: 'actions-header',
  });

  return (
    <Box
      sx={{
        height: height,
        width: '100%',
        border: "solid lightgray 1px",
        '& .super-app-theme--header': {
          backgroundColor: '#466F86', color: "white"
        },
      }}
    >

      <DataGrid
        rows={rows}
        columns={[
          ...columns,
          ...gridColumns
        ]}
        pageSize={15}
        // disableRowSelectionOnClick
        getRowClassName={(params) => params.row.className}
        initialState={{
          density: 'compact'
        }}
        slots={{
          toolbar: GridToolbar, // Përdorimi i CustomToolbar në vend të GridToolbar
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
      />
      <style jsx>{`
.actions-header {
  background-color: #466F86; /* Ngjyra e sfondit për titullin e kolonës "Actions" */
  color: #FFFFFF; /* Ngjyra e shkronjave e bardhë */
   
}

  .actions-cell {
    background-color: #466F86; /* Ngjyra e sfondit për qelizën e kolonës "Actions" */
  }
  .zero-balance-row {
    background-color: lightgray;
  }
  .non-zero-balance-row {
    /* Stili për rreshtat kur 'balance' nuk është 0 */
  }
`}</style>

      {/* Modal për konfirmim të fshirjes */}
      <Modal
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: 400, bgcolor: 'background.paper', borderRadius: '4px', boxShadow: 24, p: 4 }}>
          <Typography id="delete-modal-title" variant="h5">Konfirmim</Typography>
          <Typography id="delete-modal-description" variant="body1">A jeni të sigurt se dëshironi ta fshini këtë rresht</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" color="secondary" onClick={() => { handleDelete(deleteItemId); setShowDeleteConfirmation(false); }}>Po</Button>
            <Button variant="contained" onClick={() => setShowDeleteConfirmation(false)}>Jo</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}


export default DataGridWithActions;
