import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../Style/Checkbox.scss';
import { errorAlert, handleSuccess } from '../../Components/Alerts';
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import LoginUserDetailed from '../../Auth/LoginUserDetailed';
import '../../Style/__button.scss'
import '../../Style/InsertClient.scss';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import { TextareaAutosize } from '@mui/material';
import { Button } from "@mui/material";
import Select from 'react-select';
import AddBoxIcon from '@mui/icons-material/AddBox';

function InsertAccounts(props) {
    const headers = authHeader();
    const email = LoginUserDetailed();

    const [AccName, setAccName] = useState("");
    const [ledgerID, setLedgerID] = useState("");
    const [comment, setComment] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [ledger, setLedger] = useState([]);
    const [filteredLedgers, setFilteredLedgers] = useState([]);

    const [ledgerError, setLedgerError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        if (ledgerID == null || ledgerID == "") {
            setLedgerError("Fill ledger field");
            return;
        } else {
            setLedgerError("");
        }
        const stateDate = {
            AccountName: AccName,
            LedgerID: ledgerID,
            Comment: comment,
            CreatedByUser: email,
            IsActive: isActive
        };
        axios.post(ApiLink.API_URL + 'Accounts/createExpence', stateDate, { headers })
            .then((response) => {
                handleSuccess("Account Added Successfully");
                setAccName("");
                setLedgerID("");
                setComment("");
                setIsActive(true);
                props.onHide();
                props.refreshData();
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.status === 409) {
                    errorAlert(error.response.data);
                } else {
                    // errorAlert("An unknown error occurred while processing the request.");
                }
            });
    }

    const handleClose = () => {
        // Ky funksion do të përdoret për të mbyllur modalin
        props.onHide();
        setAccName("");
        setLedgerID("");
        setComment("");
        setIsActive(true);
    };

    useEffect(() => {
        axios.get(ApiLink.API_URL + 'ledger', { headers })
            .then((response) => {
                setLedger(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    // i filtron Ledgers
    useEffect(() => {
        // Kërko nëse ka diçka të shkruar, në të kundërt shfaq të gjitha llogaritë
        setFilteredLedgers(
            ledger.filter((data) =>
                data.LedgerNameEN.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, ledger]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        //setDebitLedgerID(inputValue);
        setSearchTerm(inputValue);
    };

    const [ledgerName, setLedgerName] = useState("");
    const handleFilterLedger = (selectedOption) => {
        if (selectedOption && selectedOption.LedgerID) {
            setLedgerID(selectedOption.LedgerID);
            setLedgerName(selectedOption.label);
        } else {
            setLedgerID("");
            setLedgerName("");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Krijo Llogari tjeter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} className="InsertClient__Container">
                    <div className='InsertClient__Container__SecondInputs'>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Emri i Llogarisë</span>
                            <TextField className='InsertClient__Container__firstInput__SameInput'
                                required
                                id="AccountName"
                                // label="Account Name"
                                variant="outlined"
                                type="text"
                                name='AccountName'
                                value={AccName}
                                onChange={event => setAccName(event.target.value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className="InsertClient__Container__SecondInputs__SameDiv">
                            <span style={{ marginBottom: "-10px", color: "black" }}>Përzgjedh Llogarinë kontabel</span>
                            <Select
                                inputId="LedgerID"
                                className="Transfers__Container__Format__SameInput"
                                value={{ label: ledgerName, value: ledgerID }}
                                options={filteredLedgers.map((data) => ({
                                    label: data.LedgerNameEN,
                                    value: data.LedgerID,
                                    LedgerID: data.LedgerID
                                }))}
                                onInputChange={handleInputChange}
                                onChange={handleFilterLedger}
                                isClearable
                                placeholder="Select"
                                noOptionsMessage={() => 'No matching'}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: "55px"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff",

                                        zIndex: 9999,
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#000",
                                    }),
                                }}
                            />
                            <span style={{ color: "red" }}>{ledgerError && `${ledgerError}`}</span>
                        </div>
                    </div>
                    <br></br>
                    <div className='InsertClient__Container__firstInput' >
                        <TextareaAutosize style={{ height: "100px" }}
                            className="InsertClient__Container__firstInput__AccName"
                            id="Comment"
                            label="Comment"
                            variant="outlined"
                            placeholder='Komenti'
                            type="text"
                            name='Comment'
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                            autoComplete="off"
                        />
                    </div>
                    <div className='InsertClient__Container__butonat'>
                        <div></div>
                        {/* <label className='InsertModal__Container__Form__Container2__checkbox-label'>
                            <input
                                type="checkbox"
                                checked={isActive}
                                onChange={(event) => setIsActive(event.target.checked)}
                                className='InsertModal__Container__Form__Container2__checkbox-input'
                            />
                            <span className='InsertModal__Container__Form__Container2__checkbox-custom'></span>
                            Active
                        </label> */}
                        <div className='InsertClient__Container__butonat__btn'>
                            <div className='InsertModal__Container__Form__Container2__save-button'>
                                <button
                                    type="submit"
                                    className="btn btn-success float-end"
                                    style={{ width: "120px" }}
                                >
                                    Ruaj & Mbyll
                                </button>
                            </div>
                            <Button variant="secondary" onClick={handleClose} style={{ width: "120px" }}>
                                Mbyll
                            </Button>
                        </div>

                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}


export default function InsertData(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const handleRefreshData = () => {
        props.fetchData(); // Thirrja e funksionit per refresh te dhenave
    };

    return (
        <>
            {
                props.type === "text" ? (
                    <button className="button-68" onClick={() => setModalShow(true)}>
                        <i class="bi bi-plus-circle">  </i>  <span style={{ marginLeft: "5px" }} />Krijo Llogari tjeter
                    </button>
                ) : props.type === "insert" ? (
                    <button className="button-15" variant="contained" onClick={() => setModalShow(true)}><i class="bi bi-person-dash-fill"></i>  Krijo Llogari tjeter</button>
                ) : (
                    <AddBoxIcon style={{ height: "30px", color: "black" }} onClick={() => setModalShow(true)} />
                )
            }

            <InsertAccounts
                show={modalShow}
                onHide={() => setModalShow(false)}
                refreshData={handleRefreshData}
            />
        </>
    );
}
