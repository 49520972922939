import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import InsertData from "./InsertLedgers";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { deleteAlert, errorAlert, updateAlert } from "../../Components/Alerts";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { AiFillEdit, AiOutlineDelete, AiOutlinePrinter, AiOutlineFilePdf } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
import { SiHyperledger } from "react-icons/si";
import { jsPDF } from "jspdf";
import "jspdf-autotable";



export default function States() {
    const [data, setData] = useState([]);

    const [role, setRole] = useState("");
    const headers = authHeader();


    const [formData, setFormData] = useState({
        LedgerID: "",
        LedgerNameEN: "",
        LedgerNameSQ: "",
        LedgerClassID: "",
        BilanceSide: "",
        FinancialStatements: "",
        // IsActive: false,

    });

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    //Kodi per shfaqjen e tabeles ne PDF
    const handleExportPDF = () => {
        const doc = new jsPDF();
        // Teksti i parë në fillim të dokumentit
        const text1 = "AccounTree";
        doc.text(text1, 10, 10); // 20 është pozicioni X, 10 është pozicioni Y

        // Teksti i dytë në fillim të dokumentit
        const text2 = "Llogaritë Kontabël";
        doc.text(text2, 10, 20); // 20 është pozicioni X, 20 është pozicioni Y

        // Teksti i tretë në fillim të dokumentit
        // const text3 = "Accounting is the process of recording financial transactions pertaining to a business.";
        // doc.text(text3, 10, 30); // 20 është pozicioni X, 30 është pozicioni Y

        // Paragrafi në fillim të dokumentit
        // const paragraph = "Ky është një paragraf në fillim të tabelës.";
        // doc.text(paragraph, 20, 40); // 20 është pozicioni X, 40 është pozicioni Y
        doc.autoTable({
            head: [["Ledger ID", "Ledger Name EN", "Ledger Class ID", "Bilance Side", "Financial Statements"]],
            body: filteredData.map((item) => [item.LedgerID, item.LedgerNameEN, item.LedgerClassID, item.BilanceSide, item.FinancialStatements]),
            startY: 30
        });
        doc.save("Ledgers-List.pdf");
    };
    //Kodi per shfaqjen e tabeles ne Print
    const tableRef = useRef();

    const handlePrintTable = () => {
        const table = tableRef.current;
        const tableClone = table.cloneNode(true);
        const printWindow = window.open('', 'PrintWindow', 'height=400,width=600');
        printWindow.document.write(tableClone.outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    // Kodi per Marrjen e informatave nga databaza
    // const fetchData = async () => {
    //     try {
    //         const headers = authHeader();
    //         const response = await axios.get(ApiLink.API_URL + 'ledger', { headers });
    //         setData(response.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'ledger', { headers });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async () => {
        try {
            const headers = authHeader();
            const response = await axios.delete(
                ApiLink.API_URL + 'ledger' + `/${deleteItemId}`, { headers }
            );
            setShowDeleteConfirmation(false);
            deleteAlert("Ledger Deleted Successfully");
            fetchData();
        } catch (error) {
            if (error.response.data) {
                deleteAlert("Ledger can not be deleted!!!");
            } else {
                console.log(error);
            }
        }
    };

    //Kodi per pegination ne tabel
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    //Kodi per shfaqjen e loadingut 
    const [loading, setLoading] = useState(true);

    //Kodi per shfaqjen e formes per editimin e te dhenave ne databaz 
    const handleEdit = (item) => {
        setFormData({
            LedgerID: item.LedgerID,
            LedgerNameEN: item.LedgerNameEN,
            LedgerNameSQ: item.LedgerNameSQ,
            LedgerClassID: item.LedgerClassID,
            BilanceSide: item.BilanceSide,
            FinancialStatements: item.FinancialStatements,
            // IsActive: item.IsActive,
        });
        setShowEditModal(true);
    };
    //Kodi per dizajnin e tabeles
    const styles = {
        tableHeader: {
            backgroundColor: '#466F86',
            color: 'white',

        },
        tableCell: {
            paddingTop: '1px',
            paddingBottom: '1px',

        },

    };

    //Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        const headers = authHeader();
        axios
            .put(ApiLink.API_URL + 'ledger', formData, { headers })
            .then((response) => {
                console.log(response.data);
                setShowEditModal(false); // Close the edit modal
                fetchData();
                updateAlert("Ledger updated successfully");
            })
            .catch((error) => {
                console.log(error);
                // alert("Failed to update Ledger");
                errorAlert("Failed to update Ledger")
            });
    };

    const filteredData = data.filter((item) => {
        return (
            item.LedgerNameEN.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const fetchRole = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AuthProva/getrole', { headers });

            if (response.data.UserRole && response.data.UserRole !== undefined) {
                setRole(response.data.UserRole);
            } else {
                errorAlert("Undefined Role.");
            }
        } catch (error) {
            console.error("Error fetching role:", error);
            // ... (pjesa tjetër e kodit)
        }
    };

    //Ruhen tdhanat 
    useEffect(() => {
        fetchData();
        fetchRole();
    }, []);

    return (
        <div className="Crud__Container">
            {/* Modal per konfirmin nese deshiron me fshi nje element */}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} style={{ marginTop: "150px" }}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Shfaqja  e modalit per editimin e te dhenave  */}
            {showEditModal && (
                <div className="edit-modal">
                    <form onSubmit={handleSubmit}>
                        <h3>Edit Ledger</h3>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">ID </span>
                            <input
                                className="form-control"
                                type="number"
                                id="LedgerID"
                                name="LedgerID"
                                value={formData.LedgerID}
                                onChange={handleInputChange}
                                disabled
                            />

                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Ledger Name EN</span>
                            <input
                                className="form-control"
                                type="text"
                                id="LedgerNameEN"
                                name="LedgerNameEN"
                                value={formData.LedgerNameEN}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Ledger Name SQ</span>
                            <input
                                className="form-control"
                                type="text"
                                id="LedgerNameSQ"
                                name="LedgerNameSQ"
                                value={formData.LedgerNameSQ}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text col-5 col-sm-5">Ledger Class ID</span>
                            <input
                                className="form-control"
                                type="text"
                                id="LedgerClassID"
                                name="LedgerClassID"
                                value={formData.LedgerClassID}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <label className="input-group-text col-5 col-sm-5" htmlFor="sideSelect">Balance Side  </label>
                            <select
                                id="sideSelect"
                                className="form-control"
                                name='BilanceSide'
                                value={formData.BilanceSide}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Balance Side</option>
                                <option >Debi</option>
                                <option >Kredi</option>
                            </select>
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text col-5 col-sm-5" htmlFor="FSSelect">Financial Statements</label>
                            <select
                                id="FSSelect"
                                className="form-control"
                                name='FinancialStatements'
                                value={formData.FinancialStatements}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Financial Statements</option>
                                <option >BG</option>
                                <option >PA</option>
                            </select>
                        </div>
                        <button type="submit" className="btn btn-success">Update</button>

                        <button
                            className="btn btn-secondary"
                            onClick={() => setShowEditModal(false)}
                        >
                            Cancel
                        </button>
                    </form>
                </div>
            )}
            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>Llogaritë Kontabël</h3>
                </div>

                {
                    role === "acctree" && (
                        <div>
                            <InsertData fetchData={fetchData} />
                        </div>
                    )
                }
            </div>
            <div className="first_paragraph">
                <p>Kjo listë paraqet skemen kontabel, si dhe specifikat për secilen llogari kontabël.</p>
            </div>

            {/* Tabela per shfaqjen e te dhenave  */}

            <div className="bordere">
                <div className="butonss">
                    {/* <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div>
                    <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}
                </div>

                <div className="searchboxx">
                    <div className="flexbox">
                        <div class="search">
                            <div>
                                <input
                                    type="text"
                                    placeholder="Search Ledgers"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}

                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <hr></hr>
            <Paper>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table ref={tableRef} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styles.tableHeader}><strong>Numri Llogarisë Kontabel</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Emri i Llogarisë Kontabel</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Numri i Klases</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Bilanci Debi/Kredi</strong></TableCell>
                                <TableCell sx={styles.tableHeader}><strong>Pasqyra Financiare</strong></TableCell>
                                {
                                    role === "acctree" && (
                                        <TableCell sx={styles.tableHeader}><strong>Actions</strong></TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <div className="spinner"></div>
                        ) : (
                            <TableBody>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.ArticleID}>
                                            <TableCell sx={styles.tableCell}>{item.LedgerID}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.LedgerNameEN}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.LedgerClassID}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.BilanceSide}</TableCell>
                                            <TableCell sx={styles.tableCell}>{item.FinancialStatements}</TableCell>
                                            {
                                                role === "acctree" && (
                                                    <TableCell sx={styles.tableCell}>
                                                        <Tooltip title="Edit" placement="left">
                                                            <IconButton>
                                                                <AiFillEdit style={{ color: '#466F86' }} onClick={() => handleEdit(item)}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" placement="right">
                                                            <IconButton>
                                                                <DeleteIcon style={{ color: '#962a38' }}
                                                                    onClick={() => {
                                                                        setShowDeleteConfirmation(true);
                                                                        setDeleteItemId(item.LedgerID);
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </div>
    );
}