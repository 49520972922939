const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const RentGridHeader = [
    { field: 'id', headerName: '#', width: 60, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'month', headerName: 'Muaji', width: 160, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'date', headerName: 'Data', width: 160, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    { field: 'accname', headerName: 'Qiradhenesi', width: 235, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    {
        field: 'neto', headerName: 'Neto', type: 'string', width: 160, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'tatimi', headerName: 'Tatimi 9%', type: 'string', width: 160, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
    {
        field: 'total', headerName: 'Total', type: 'string', width: 160, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
        valueFormatter: (value) => {
            if (!value) {
                return value;
            }
            return currencyFormatter.format(value);
        },
    },
]