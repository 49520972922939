import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import '../../Style/EditModal.scss';
import "../../Style/TableStyle.scss"
import '../../Style/__searchbar.scss'
import '../../Style/__button.scss'
import { ApiLink } from "../../APILink/ApiLink";
import authHeader from "../../Auth/authHeader";
import { SiHyperledger } from "react-icons/si";
// import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { errorAlert } from "../../Components/Alerts";

import DataGridComponent from "../../Components/DataGridComponent";
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});



export default function JournalHistory() {
    const [data, setData] = useState([]);
    const headers = authHeader();

    // const [searchTerm, setSearchTerm] = useState("");
    const [companiesData, setCompaniesData] = useState([]);
    const [logoPath, setLogoPath] = useState("");
    let number = 1;

    const columns = [
        { field: 'id', headerName: '#', width: 110, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'actionType', headerName: 'Tipi Veprimit', width: 150, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'ledger', headerName: 'Llogaria Kontabel', width: 210, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        { field: 'date', headerName: 'Data', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
        {
            field: 'debit', headerName: 'Debi', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },
        {
            field: 'credit', headerName: 'Kredi', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },
        {
            field: 'balance', headerName: 'Bilanci', type: 'string', width: 130, editable: false, headerClassName: 'super-app-theme--header', groupable: true,
            valueFormatter: (value) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },
        { field: 'comment', headerName: 'Komenti', type: 'string', width: 300, editable: false, headerClassName: 'super-app-theme--header', groupable: false, aggregable: false },
    ];

    const rows = data.map((item) => ({
        id: item.JournalID,
        actionType: item.ActionType,
        ledger: item.LedgerNameEN,
        date: item.Date,
        debit: item.Debit,
        credit: item.Credit,
        balance: item.Balance,
        comment: item.Comment,
        className: item.Balance === 0 ? 'zero-balance-row' : 'non-zero-balance-row', // Shto klasën CSS bazuar në vlerën e 'balance'
    }));


    // function formatDate(date) {
    //     if (!date) return '';

    //     // Parse the date string into a Date object if it's not already one
    //     const d = (date instanceof Date) ? date : new Date(date);

    //     // Format the date components
    //     const day = String(d.getDate()).padStart(2, '0');
    //     const month = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
    //     const year = d.getFullYear();

    //     return `${day}/${month}/${year}`;
    // }

    // const addLogo = (doc) => {
    //     const logoWidth = 30;
    //     const logoHeight = 30;
    //     const logoX = 10;
    //     const logoY = 5;
    //     doc.addImage(logoPath, 'JPEG', logoX, logoY, logoWidth, logoHeight);
    // };

    // const addSeparatorLine = (doc, lineY) => {
    //     const lineWidth = doc.internal.pageSize.getWidth() - 10;
    //     doc.line(10, lineY, 10 + lineWidth, lineY);
    // };

    // const addLeftText = (doc, text, lineY) => {
    //     doc.setFontSize(10);
    //     doc.text(text, 10, lineY + 10);
    // };

    // const addRightText = (doc, text, lineY) => {
    //     doc.setFontSize(10);
    //     doc.text(text, doc.internal.pageSize.getWidth() - 10, lineY + 10, { align: "right" });
    // };

    // const addCompanyDetails = (doc, companyData) => {
    //     doc.setFontSize(10);
    //     const companyDetails = `
    //         ${companyData.CompanyName}
    //         NUI/NF: ${companyData.IdentificationNumber}
    //         Business Number:${companyData.IdentificationNumber}
    //         Address: ${companyData.Address}
    //         Tel: ${companyData.PhoneNumbers}
    //         Email: ${companyData.Email}
    //     `;
    //     doc.text(companyDetails, doc.internal.pageSize.getWidth() - 10, 5, { align: "right" });
    // };

    // const addTable = (doc, data, lineY) => {
    //     doc.autoTable({
    //         head: [["#", " Action Type", "Ledger ID", " Date", "Debit", " Credit", "Balance", "Comment"]],
    //         body: data.map((item, index) => [
    //             (number + index),
    //             item.ActionType,
    //             item.LedgerNameEN,
    //             formatDate(item.Date),
    //             item.Debit,
    //             item.Credit,
    //             item.Balance,
    //             item.Comment
    //         ]),
    //         startY: lineY + 30,
    //         columnStyles: {
    //             2: { cellWidth: 'wrap' }, // Kolona 2, ku është "Invoice Number"
    //         },
    //     });
    // };
    const fetchData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Reports/journalHistory', { headers });
            setData(response.data);
            // setLoading(false);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompaniesData(response.data);
            // setLoading(false);
            setLogoPath(ApiLink.PHOTO_URL + response.data[0].CompanyLogo);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    useEffect(() => {
        fetchData();
        fetchCompaniesData();
    }, []);

    return (
        <div className="Crud__Container">

            <div className="header_flex">
                <div className="header_icon">
                    <SiHyperledger style={{}} className="header_icons" />
                    <h3>Ditari i Transaksioneve</h3>
                </div>
            </div>
            <div className="first_paragraph">
                <p>Ky format i Ditarit është i përshtatur për të vërifikuar transaksionet.</p>
            </div>


            <div className="bordere">
                <div className="butonss">
                    {/* <div>
                        <button className="button-15" onClick={handleExportPDF} variant="contained"><AiOutlineFilePdf /> PDF </button>
                    </div> */}
                    {/* <div className="butons15">
                        <button className="button-15" variant="contained" onClick={handlePrintTable}><AiOutlinePrinter /> Print</button>
                    </div> */}
                </div>
            </div>
            <DataGridComponent columns={columns} rows={rows} />
        </div>
    )
}