import '../Style/CompanyInfo.scss'
import React, { useState, useEffect } from "react"
import authHeader from "../Auth/authHeader";
import { ApiLink } from "../APILink/ApiLink";
import axios from "axios";
import { errorAlert, updateAlert } from '../Components/Alerts';
import TextField from '@mui/material/TextField';
import LoginUserDetailed from '../Auth/LoginUserDetailed';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillProfile } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { MdManageAccounts } from "react-icons/md";
import MenuItem from '@mui/material/MenuItem';


export default function CompanyInfo() {
    const [companyInfo, setCompanyInfo] = useState([]);
    const [city, setCity] = useState([]);
    const [state, setState] = useState([]);
    const headers = authHeader();
    const loginEmail = LoginUserDetailed();
    const [disabled, setDisabled] = useState(true);
    const [role, setRole] = useState("");

    const [formData, setFormData] = useState({
        CompanyID: "",
        CompanyName: "",
        IdentificationNumber: "",
        VATNumber: "",
        Address: "",
        CityID: "",
        StateID: "",
        PhoneNumbers: "",
        Email: "",
        EdiPass: "",
        ARBKPass: "",
        MailPass: "",
        SMTPClient: "",
        Comment: "",
        ModifiedByUser: "",
        VATDeclarant: "",
        FiscalYear: "",
        IsActive: "",
        CompanyLogo: "anonymous.png",
        LogoPath: ApiLink.PHOTO_URL
    })
    const handleEdit = (item) => {
        setFormData({
            CompanyID: item.CompanyID,
            CompanyName: item.CompanyName,
            IdentificationNumber: item.IdentificationNumber,
            VATNumber: item.VATNumber,
            Address: item.Address,
            CityID: item.CityID,
            StateID: item.StateID,
            PhoneNumbers: item.PhoneNumbers,
            EdiPass: item.EdiPass,
            ARBKPass: item.ARBKPass,
            MailPass: item.MailPass,
            SMTPClient: item.SMTPClient,
            Email: item.Email,
            Comment: item.Comment,
            ModifiedByUser: loginEmail,
            VATDeclarant: item.VATDeclarant,
            FiscalYear: item.FiscalYear,
            IsActive: item.IsActive,
            CompanyLogo: item.CompanyLogo
        });
        // setShowEditModal(true);
    };

    // Kodi per ruajtjen e te dhenave pas editimint 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    //Dergimi i kerkeses per editimin e te dhenave ne backend 
    const handleSubmit = (event) => {
        event.preventDefault();
        const headers = authHeader();
        axios
            .put(ApiLink.API_URL + 'Companies', formData, { headers })
            .then((response) => {
                console.log(response.data);
                fetchCompanyInfo();
                setDisabled(true);
                updateAlert("Company updated successfully")
            })
            .catch((error) => {
                console.log(error);
                errorAlert("Failed to update company");
            });
    };


    const activeInputs = () => {
        setDisabled(false);
    }

    const imageUpload = (e) => {
        e.preventDefault();
        // Kontrollo nëse files janë të definuara dhe ka një element në pozitën '0'
        if (e.target.files && e.target.files.length > 0) {
            const fileData = new FormData();
            fileData.append("file", e.target.files[0], e.target.files[0].name);

            axios.post(ApiLink.API_URL + 'Companies/SaveFile', fileData, { headers })
                .then((response) => {
                    setFormData({
                        ...formData,
                        CompanyLogo: response.data,
                    });
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const fetchCompanyInfo = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'PersonalData', { headers });
            setCompanyInfo(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    const fetchCityByState = async (stateID) => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Cities/getcitybystate', { headers, params: { stateid: stateID } });
            setCity(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);

            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };

    const fetchRole = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'AuthProva/getrole', { headers });

            if (response.data.UserRole && response.data.UserRole !== undefined) {
                setRole(response.data.UserRole);
            } else {
                errorAlert("Undefined Role.");
            }
        } catch (error) {
            console.error("Error fetching role:", error);
            // ... (pjesa tjetër e kodit)
        }
    };

    useEffect(() => {
        const headers = authHeader();
        axios.get(ApiLink.API_URL + 'states', { headers })
            .then((response) => {
                setState(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        fetchCompanyInfo();
        fetchRole();
    }, []);

    return (
        <div>
            <div>
                <div className="header_flexx">
                    <div className="header_icon">
                        <AiFillProfile style={{}} className="header_icons" />
                        <h3>Profili i Kompanisë</h3>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="header_paragraph">
                    <p>Profili i kompanisë: Përditësoni profilin e kompanisë sipas ndryshimeve të fundit.</p>
                </div>
                <hr></hr>
                <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: "5px" }}>
                    {disabled ? (
                        <>
                            {
                                (role === "acctree" || role === "admin") && (
                                    <div style={{ marginLeft: "5px" }}>
                                        <Link to="#"
                                            style={{ width: "110px", textDecoration: "none", color: "green" }}
                                        >
                                            <button
                                                type="submit"
                                                className="button-15"
                                                onClick={(e) => { activeInputs(e); handleEdit((companyInfo[0])); fetchCityByState(companyInfo[0].StateID); }}
                                            >
                                                Ndrysho
                                            </button>
                                        </Link>
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        <>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button type="submit" onClick={handleSubmit} className="button-15" variant="contained">
                                        {/* <FaTimes />  */}
                                        Update
                                    </button>
                                </Link>
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <Link
                                    to="#"
                                    style={{ width: "110px", textDecoration: "none" }}
                                >
                                    <button type="button" onClick={(e) => { setDisabled(true); fetchCompanyInfo(); }} className="button-15" variant="contained">
                                        {/* <FaTimes />  */}
                                        Close

                                    </button>
                                </Link>
                            </div>

                        </>
                    )}
                </div>
            </div>
            {
                companyInfo && companyInfo.map((companyInfo, index) => (
                    <div className='CompanyInfo__Container' key={index}>
                        {/* <form onSubmit={handleSubmit}> */}
                        <div className='CompanyInfo__Container__Logo'>
                            <div className='CompanyInfo__Container__Logo__logo'>
                                {companyInfo.CompanyLogo ? (
                                    <div>
                                        <img
                                            src={ApiLink.PHOTO_URL + companyInfo.CompanyLogo}
                                            alt="Logo"
                                            style={{ width: "80%", height: "80%" }}
                                            disabled={disabled}
                                        />
                                    </div>
                                ) : (
                                    <div className="p-2 w-50 bd-highlight">
                                        <p style={{ color: "red" }}>Shto Logon tek butoni "Ndrysho"</p>
                                    </div>
                                )}
                                {!disabled && (
                                    <div className="p-2 w-50 bd-highlight">
                                        <img
                                            style={{ width: "50px", height: "50px" }}
                                            src={ApiLink.PHOTO_URL + formData.CompanyLogo}
                                            alt="Logo"
                                        />
                                        <input className="m-2" type="file" onChange={imageUpload} />
                                    </div>
                                )}
                            </div>
                            <div className='CompanyInfo__Container__Logo__name'>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField
                                        className='CompanyInfo__Container__Logo__name__details__input'
                                        id="CompanyName"
                                        label="Emri i Kompanisë"
                                        variant="outlined"
                                        type="text"
                                        name='CompanyName'
                                        value={!disabled ? formData.CompanyName : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.CompanyName}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <br></br>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="Comment"
                                        label="Komenti"
                                        variant="outlined"
                                        type="text"
                                        name='Comment'
                                        value={!disabled ? formData.Comment : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.Comment}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='CompanyInfo__Container__Info'>
                            <div className='CompanyInfo__Container__Info__div'>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="IdentificationNumber"
                                        label="Numri Identifikues"
                                        variant="outlined"
                                        type="text"
                                        name='IdentificationNumber'
                                        value={!disabled ? formData.IdentificationNumber : ''}
                                        disabled

                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.IdentificationNumber}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="VATNumber"
                                        label="Numri i TVSH-së"
                                        variant="outlined"
                                        type="text"
                                        name='VATNumber'
                                        value={!disabled ? formData.VATNumber : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.VATNumber}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="PhoneNumbers"
                                        label="Numri i Telefonit"
                                        variant="outlined"
                                        type="text"
                                        name='PhoneNumbers'
                                        value={!disabled ? formData.PhoneNumbers : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.PhoneNumbers}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='CompanyInfo__Container__Info__div'>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="StateID"
                                        name="StateID"
                                        select
                                        label="Shteti"
                                        defaultValue=""
                                        value={!disabled ? formData.StateID : ''}
                                        disabled={disabled}
                                        onChange={(e) => { handleInputChange(e); fetchCityByState(e.target.value); }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.StateName}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    >
                                        {state.map((state) => (
                                            <MenuItem key={state.StateID} value={state.StateID}>{state.StateName}</MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="CityID"
                                        name="CityID"
                                        select
                                        label="Qyteti"
                                        defaultValue=""
                                        value={!disabled ? formData.CityID : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.CityName}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    >
                                        {city.map((city) => (
                                            <MenuItem key={city.CityID} value={city.CityID}>{city.CityName}</MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="Address"
                                        label="Adresa"
                                        variant="outlined"
                                        type="text"
                                        name='Address'
                                        value={!disabled ? formData.Address : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.Address}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='CompanyInfo__Container__Info__div'>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="Email"
                                        label="Email"
                                        variant="outlined"
                                        type="text"
                                        name='Email'
                                        value={!disabled ? formData.Email : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.Email}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="PhoneNumbers"
                                        label="Numri i Telefonit"
                                        variant="outlined"
                                        type="text"
                                        name='PhoneNumbers'
                                        value={!disabled ? formData.PhoneNumbers : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.PhoneNumbers}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField
                                        className='CompanyInfo__Container__Logo__name__details__input'
                                        id="MailPass"
                                        label="Fjalkalimi i Email-it"
                                        variant="outlined"
                                        type="password"  // Kjo është pjesa që e bën këtë fushë një fushë fjalekalimi
                                        name='MailPass'
                                        value={!disabled ? formData.MailPass : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel >******************</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                </div>
                            </div>
                            <div className='CompanyInfo__Container__Info__div'>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id="EdiPass"
                                        label="Fjalkalimi i sistemit të ATK-së"
                                        variant="outlined"
                                        type="text"
                                        name='EdiPass'
                                        defaultValue=""
                                        value={!disabled ? formData.EdiPass : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.EdiPass}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='CompanyInfo__Container__Logo__name__details'>
                                    <TextField className='CompanyInfo__Container__Logo__name__details__input'
                                        id=""
                                        label="Fjalkalimi i sistemit të ARBK-së"
                                        variant="outlined"
                                        type="text"
                                        name='ARBKPass'
                                        defaultValue=""
                                        value={!disabled ? formData.ARBKPass : ''}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {disabled && <InputLabel>{companyInfo.ARBKPass}</InputLabel>}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div >
    )
}