import React, { useState, useEffect, useRef } from "react";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import '../../Style/VatDeclaration.scss'
import { Month } from '../../OtherData/Month'
import FirstTable from "./VATDeclaration/FirstTable";
import axios from "axios";
import { ApiLink } from '../../APILink/ApiLink';
import authHeader from '../../Auth/authHeader';
import { errorAlert } from "../../Components/Alerts";
export default function VatDeclaration() {
    const [month, setMonth] = useState(0);
    const vitAktual = new Date().getFullYear();
    const headers = authHeader();

    const [companyName, setCompanyName] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [address, setAdress] = useState("");
    const [phone, setPhone] = useState("");

    const fetchCompaniesData = async () => {
        try {
            const response = await axios.get(ApiLink.API_URL + 'Companies/getCompanyDetails', { headers });
            setCompanyName(response.data[0].CompanyName);
            setIdNumber(response.data[0].IdentificationNumber);
            setAdress(response.data[0].Address);
            setPhone(response.data[0].PhoneNumbers);

            console.log(response.data);
        } catch (error) {
            errorAlert("Gabim në kërkesë:", error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                errorAlert(`Gabim në marrjen e të dhënave: ${error.response.data.errorMessage}`);
            } else if (error.message) {
                errorAlert(`Gabim: ${error.message}`);
            } else {
                errorAlert("Gabim i panjohur në kërkesë. Shih konsolën për detaje.");
            }
        }
    };
    useEffect(() => {
        fetchCompaniesData();
    }, []);
    return (
        <div style={{ paddingTop: "50px" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <TextField
                    className="selectField"
                    id=""
                    select
                    label="Select Month"
                    value={month}
                    // onChange={(event) => setMonth(event.target.value)}
                    onChange={event => { setMonth(event.target.value); }}

                    required
                >
                    {/* <MenuItem value="">
                        Select Month
                    </MenuItem> */}
                    {Month.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                            {vitAktual}{data.month}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className='VatDeclaration__Container'>
                <div className='VatDeclaration__Container__HeaderData'>
                    <div>
                        <h5><b>FORMULARI I DEKLARIMIT TË TVSH-SË</b></h5>
                    </div>
                    <hr></hr>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[1]</b></h5>
                            <h5>Periudha e Deklarimit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{month + "-" + vitAktual}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[3]</b></h5>
                            <h5>Numri Fiskal:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{idNumber}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[4]</b></h5>
                            <h5>Emri i biznesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{companyName}</h5>
                        </div>
                    </div>
                    <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[5]</b></h5>
                            <h5>Adresa e biznesit:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{address}</h5>
                        </div>
                    </div>
                    {/* <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5><b>[6]</b></h5>
                            <h5>Emri i personit kontaktues:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>Alban</h5>
                        </div>
                    </div> */}
                    {/* <div className='VatDeclaration__Container__HeaderData__info'>
                        <div style={{ display: "flex" }}>
                            <h5>Telefoni:</h5>
                        </div>
                        <div className='VatDeclaration__Container__HeaderData__borders'>
                            <h5>{phone}</h5>
                        </div>
                    </div> */}
                </div>
                <hr></hr>
                <div className='VatDeclaration__Container__SecondData_Table'>
                    <FirstTable month={month} />
                </div>

                <div className='VatDeclaration__Container__ThirdData_Table'>

                </div>

                <div className='VatDeclaration__Container__FourthData_Table'>

                </div>
            </div>
        </div>
    )
}